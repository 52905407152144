import { DocumentData } from "firebase/firestore";
import React from "react";
import { topHeaderCss, showPercentage, bottomHeaderCss } from "../../../utils";

type Props = {
  header: DocumentData;
  headers: DocumentData[];
  netSalesRate: number[];
  businessPlanCalcState: number[];
};

const BusinessPlanRateHeader: React.FC<Props> = ({
  // header,
  headers,
  netSalesRate,
  businessPlanCalcState,
}) => {
  // const inputRef = useRef<HTMLInputElement>(null);
  // const firestore = useFirestore();
  // const uid = getStoredClientUid();

  // const handleUpChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (isNaN(Number(e.target.value))) {
  //     return;
  //   }

  //   const dataRef = doc(
  //     firestore,
  //     "accounts",
  //     uid,
  //     "tables",
  //     String(header && header.NO_ID_FIELD)
  //   );

  //   header &&
  //     (await updateDoc(dataRef, {
  //       business_plan_up: Number(e.target.value),
  //     }).catch((error) => {
  //       throw new Error(error.message);
  //     }));
  // };

  // const handleRateChange = async ({
  //   e,
  //   index,
  // }: {
  //   e: React.ChangeEvent<HTMLInputElement>;
  //   index: number;
  // }) => {
  //   // 今の所、どこのデータとも連携していない（後日指示あるかもしれない）
  //   if (isNaN(Number(e.target.value))) {
  //     return;
  //   }

  //   const dataRef = doc(
  //     firestore,
  //     "accounts",
  //     uid,
  //     "tables",
  //     String(header && header.NO_ID_FIELD)
  //   );

  //   const editGrowth = [...header.business_plan_growth];
  //   editGrowth[index] = Number(e.target.value);

  //   header &&
  //     (await updateDoc(dataRef, {
  //       business_plan_growth: editGrowth,
  //     }).catch((error) => {
  //       throw new Error(error.message);
  //     }));
  // };

  // useEffect(() => {
  //   inputRef.current?.focus();
  // }, []);

  return (
    <thead
      className="sticky top-0 px-6px py-3px bg-white z-40
        pt-120px"
    >
      <tr className="h-36px">
        <th
          className={`sticky min-w-[12.5rem] w-200px left-0 before:border-y before:border-x
              before:border-r-c3333334d bg-white z-40 before:bg-c80b0f433 before:-z-10 ${topHeaderCss} text-18px text-center font-bold`}
          rowSpan={2}
        >
          {/* <div className="text-center">
            <input
              className="table_up_w shadow appearance-none before:border before:border-c3333334d rounded py-2px px-3px leading-tight
        text-right"
              defaultValue={header.business_plan_up}
              onChange={(e) => handleUpChange(e)}
              type="text"
            />
            %up
          </div> */}
        </th>
        {headers?.map(
          (_, index) =>
            index !== 0 && (
              <th
                className={`min-w-[8.75rem] w-140px p-4px text-16px text-right before:border-l before:border-y before:border-l-c3333334d before:border-b-c3333334d ${topHeaderCss} ${
                  headers.length === index + 1 && "before:border-r"
                }`}
                key={index}
              >
                {index === 1
                  ? "売上伸び率"
                  : `${showPercentage(netSalesRate[index])}%`}
              </th>
            )
        )}

        <th className="min-w-[37.5rem] w-600px"></th>
      </tr>
      <tr className="h-36px">
        <th
          className={`p-4px text-16px before:border-l before:border-b before:border-l-c3333334d ${bottomHeaderCss}`}
        >
          営業利益率
        </th>
        {headers?.map(
          (_, index) =>
            index !== 0 &&
            index !== 1 && (
              <th
                className={`p-4px text-16px text-right before:content-[''] before:border-l before:border-b before:border-l-c3333334d relative px-6px py-3px t   before:absolute before:bottom-0 before:left-0 before:w-full before:h-full ${
                  headers.length === index + 1 && "before:border-r"
                } before:-z-10`}
              >
                {showPercentage(businessPlanCalcState[index])}
                {/* <input
                  className="table_per_w shadow appearance-none before:border before:border-c3333334d rounded py-2px px-3px leading-tight
        focus:border-c1e88e5 focus:outline-none focus:shadow-outline text-right "
                  defaultValue={header.business_plan_growth[index]}
                  onChange={(e) => handleRateChange({ e, index })}
                  type="text"
                /> */}
                %
              </th>
            )
        )}

        <th></th>
      </tr>
      <tr>
        <th className="table_pt bg-white z-40" colSpan={8}></th>
      </tr>
    </thead>
  );
};

export default React.memo(BusinessPlanRateHeader);
