import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const BsPlansInputTotal = ({
  titleTotal,
  crossheadTotal,
  sectionTotal,
  bottomLine,
  isCrosshead,
  headers,
  noHeader,
  total,
  totalField,
  descriptions,
  unit,
}: {
  titleTotal?: string;
  crossheadTotal?: string;
  sectionTotal?: string;
  bottomLine?: boolean;
  isCrosshead?: boolean;
  headers?: DocumentData[];
  noHeader?: boolean;
  total: number[];
  totalField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px before:border-l   bg-white   ${
    bottomLine ? "before:border-b" : ""
  } ${noHeader && "before:border-t"} before:bg-c80b0f433
  font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-30px p-4px  bg-white  ${
    bottomLine && "before:border-b"
  } ${crossheadTotal && "before:border-l before:border-l-c3333334d"} ${
    noHeader && "before:border-t"
  } ${
    isCrosshead && !crossheadTotal && "before:border-b"
  } before:bg-c80b0f433 font-bold z-[31]`;

  const classCol3 = `${beforeWrapper} left-80px p-4px bg-white 
    before:border-b ${noHeader && "before:border-t"} before:bg-c80b0f433 ${
    sectionTotal && "before:border-l before:border-l-c3333334d"
  }`;

  const classCol4 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-r before:border-r-c3333334d ${
    noHeader && "before:border-t"
  } before:bg-c80b0f433`;

  const classCol5 = `${borderSettings} ${
    noHeader && "before:border-t"
  }   text-right font-bold`;

  const classCol7 = `${borderSettings} before:border-r  ${
    noHeader && "before:border-t"
  } bg-c80b0f433 text-left h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap  top_absolute`}>
          {crossheadTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: sectionTotal,
      readOnly: true,
      className: `${classCol3} `,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} `,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow.push({
      value: ShowTableData(total[tdIndex] / unit),
      expr: total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol5} ${
        header.isTotal ? "bg-cfffee2" : "bg-c80b0f433"
      }`,
    });
  });

  gridRow.push({
    value: totalField && descriptions[totalField],
    expr: totalField && descriptions[totalField],
    readOnly: false,
    className: `${classCol7}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
