import { getAuth, onAuthStateChanged, User } from "@firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useFirebaseApp } from "reactfire";

export const useFirebaseAuth = () => {
  const [user, setUser] = useState<User | null>(null);

  const app = useFirebaseApp();
  const auth = getAuth(app);

  const mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (mounted.current) {
          setUser(user);
        }
      } else {
        if (mounted.current) {
          setUser(null);
        }
      }
    });

    return () => {
      mounted.current = false;
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return {
    user,
    auth,
  };
};
