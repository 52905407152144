import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { PlansInputFieldsCalc } from "../../../types/plansInputFIeldsCalc";
import {
  BsPlansInputTable,
  CostPlansInputTable,
  PlPlansInputTable,
} from "../plansInput";

interface Props {
  url: string;
  calcState: PlansInputFieldsCalc;
}

export const PlansInputContainer: React.FC<Props> = ({ url, calcState }) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlPlansInputTable calcState={calcState} url={url} />
      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostPlansInputTable calcState={calcState} url={url} />
      {/* BS  */}
      <div className="mt-50px" id="bs"></div>
      <BsPlansInputTable calcState={calcState} url={url} />
    </InputLayout>
  );
};
