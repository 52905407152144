import { getAuth, signInWithCustomToken } from "firebase/auth";
import { doc, getDoc, setDoc, writeBatch } from "firebase/firestore";
import React, { memo, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { apiUrl, BASE_API_URL } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton, LightButton } from "../../components/atoms";
import { GuestLayout } from "../../components/layout";
import { pageConstant } from "../../constants/pageConstants";
import { queryConstants } from "../../constants/queryConstants";
import { firebaseFields } from "../../firebase";
import {
  clearStoredClientToken,
  clearStoredClientUid,
  clearStoredServerClientToken,
  clearStoredSpecialistToken,
  clearStoredToken,
  clearStoredUid,
  setStoredCategory,
  setStoredClientToken,
  setStoredClientUid,
  setStoredServerClientToken,
  setStoredSpecialistToken,
  setStoredToken,
  setStoredUid,
} from "../../sessionStorage/sessionStorage";

const Login = () => {
  const navigate = useNavigate();

  const firestore = useFirestore();

  const queryClient = useQueryClient();

  const [state, setState] = useState({
    email: "",
    password: "",
    isShowPassword: false,
  });

  const mutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      axiosInstance({
        url: `${BASE_API_URL}${apiUrl.LOGIN}`,
        method: "POST",
        data: { email, password },
      })
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await mutation.mutateAsync({
        email: state.email,
        password: state.password,
      });

      const category = response.data.authory.category;
      if (category === 1) {
        setStoredToken(response.data.token);
        setStoredUid(response.data.user.uid);
        clearStoredClientToken();
        clearStoredServerClientToken();
        clearStoredClientUid();
        setStoredCategory(response.data.authory.category);

        signInWithCustomToken(getAuth(), response.data.token)
          .then(async (result) => {
            toast.success("ログインが完了しました");
            const firebaseToken = await result.user.getIdToken();
            setStoredSpecialistToken(firebaseToken);
            queryClient.setQueriesData(
              queryConstants.SPECIALISTS,
              response.data
            );
            queryClient.setQueriesData(queryConstants.CLIENTS, undefined);

            navigate(`/${pageConstant.CLIENTS}`);
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } else {
        setStoredServerClientToken(response.data.token);
        setStoredClientUid(response.data.user.uid);
        clearStoredSpecialistToken();
        clearStoredToken();
        clearStoredUid();
        setStoredCategory(response.data.authory.category);

        signInWithCustomToken(getAuth(), response.data.token)
          .then(async (result) => {
            toast.success("ログインが完了しました");
            const firebaseToken = await result.user.getIdToken();
            setStoredClientToken(firebaseToken);

            queryClient.setQueriesData(queryConstants.SPECIALISTS, undefined);
            queryClient.setQueriesData(queryConstants.CLIENTS, response.data);

            const accountsDocRef = doc(
              firestore,
              firebaseFields.accounts,
              String(result.user.uid)
            );

            const docSnapAccount = await getDoc(accountsDocRef);

            if (docSnapAccount.exists()) {
              const existAccountsDocRef = doc(
                firestore,
                firebaseFields.accounts,
                String(result.user.uid),
                firebaseFields.collections,
                firebaseFields.headersId
              );

              const docSnap = await getDoc(existAccountsDocRef);

              if (docSnap.data()?.isDashboard) {
                location.href = `/${pageConstant.MANAGEMENT_DASHBOARD}`;
              } else if (docSnap.exists()) {
                location.href = `/${pageConstant.ACCOUNTING_PERIOD}`;
              } else {
                setDoc(accountsDocRef, {})
                  .then(() => {
                    const batch = writeBatch(firestore);

                    const tableRef = doc(
                      firestore,
                      firebaseFields.accounts,
                      String(result.user.uid),
                      firebaseFields.collections,
                      firebaseFields.headersId
                    );

                    batch.set(tableRef, { field: "headers" });

                    const clientRef = doc(
                      firestore,
                      firebaseFields.accounts,
                      String(result.user.uid),
                      "users",
                      String(result.user.uid)
                    );
                    batch.set(clientRef, {});

                    batch
                      .commit()
                      .then(() => {
                        location.href = `/${pageConstant.ACCOUNTING_PERIOD}`;
                      })
                      .catch((error) => {
                        toast.error("ログインできませんでした");
                        throw new Error(error.message);
                      });
                  })
                  .catch((error) => {
                    toast.error("ログインできませんでした");
                    throw new Error(error.message);
                  });
              }
            } else {
              setDoc(accountsDocRef, {})
                .then(() => {
                  const batch = writeBatch(firestore);

                  const clientRef = doc(
                    firestore,
                    firebaseFields.accounts,
                    String(result.user.uid),
                    "users",
                    String(result.user.uid)
                  );
                  batch.set(clientRef, {});

                  batch
                    .commit()
                    .then(() => {
                      const batch = writeBatch(firestore);

                      const tableRef = doc(
                        firestore,
                        firebaseFields.accounts,
                        String(result.user.uid),
                        firebaseFields.collections,
                        firebaseFields.headersId
                      );
                      batch.set(tableRef, { field: "headers" });

                      batch
                        .commit()
                        .then(() => {
                          location.href = `/${pageConstant.ACCOUNTING_PERIOD}`;
                        })
                        .catch((error) => {
                          toast.error("ログインできませんでした");
                          throw new Error(error.message);
                        });
                    })
                    .catch((error) => {
                      toast.error("ログインできませんでした");
                      throw new Error(error.message);
                    });
                })
                .catch((error) => {
                  toast.error("ログインできませんでした");
                  throw new Error(error.message);
                });
            }
          })
          .catch((error) => {
            toast.error("ログインできませんでした");
            throw new Error(error.message);
          });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error("ログインできませんでした");
      throw new Error(error.message);
    }
  };

  return (
    <GuestLayout>
      <div className="w-[450px]">
        <h1 className="text-30px font-bold text-center mb-25px">ログイン</h1>
        <form
          className="bg-white px-8px pt-6px pb-8px"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="mb-12px">
            <label className="block text-13px font-bold mb-8px" htmlFor="email">
              メールアドレス
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              onChange={(e) => setState({ ...state, email: e.target.value })}
              placeholder="sample@cloudplanner.com"
              type="email"
            />
          </div>
          <div className="relative mb-40px">
            <label className="block text-13px font-bold mb-8px" htmlFor="email">
              パスワード
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              onChange={(e) => setState({ ...state, password: e.target.value })}
              placeholder="パスワードを入力してください"
              type={state.isShowPassword ? "text" : "password"}
            />
            <span
              className={`absolute top-[42px] right-16px ${
                state.password.length === 0 && "text-c33333333"
              } cursor-pointer`}
              onClick={() =>
                setState({ ...state, isShowPassword: !state.isShowPassword })
              }
              role="presentation"
            >
              {state.isShowPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          <div className="m-auto">
            <DarkButton>ログイン</DarkButton>
          </div>
        </form>
        <p className="text-14px py-15px text-center">
          パスワードお忘れの方は
          <Link
            className="inline-block align-baseline font-bold text-14px text-c427ed1 underline hover:opacity-80"
            to={`/${pageConstant.PASSWORD_RESETTING}`}
          >
            こちら
          </Link>
        </p>
        <div className="m-auto">
          <LightButton>
            <Link to={`/${pageConstant.SIGNUP}`}>新規会員登録はこちら</Link>
          </LightButton>
        </div>
      </div>
    </GuestLayout>
  );
};

export default memo(Login);
