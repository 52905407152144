import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { ResultsOfTheCurrentTermContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";
import { useResultsOfTheCurrentTermInitialData } from "../../hooks/initialData";

export const ResultsOfTheCurrentTerm = () => {
  const resultsOfTheCurrentTermCalcState =
    useResultsOfTheCurrentTermInitialData();

  const url = pageConstant.RESULTS_OF_THE_CURRENT_TERM;
  const dataUrl = pageConstant.PLANS_INPUT_MONTHLY;

  if (!resultsOfTheCurrentTermCalcState) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <SubHeader title="今期実績（月次）" />
      <UnitChange />
      <ResultsOfTheCurrentTermContainer
        calcState={resultsOfTheCurrentTermCalcState}
        dataUrl={dataUrl}
        url={url}
      />
    </React.Fragment>
  );
};
