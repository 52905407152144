import { DocumentData } from "firebase/firestore";
import { add, cumsum, multiply, sum } from "mathjs";

type Props = {
  states: DocumentData[];
  ratio: number[];
  remins: number[];
  counts: number[];
};

export const calcByItemForPlansInputMonthlyExternalInjection = ({
  states,
  ratio,
  remins,
  counts,
}: Props) => {
  const byItem: number[][] = [];
  const byItemCumsum: number[][] = [];
  const monthlyTotalByItem: number[] = [];
  let monthlyTotal: number[] = [];
  let monthlyData: number[] = [0];
  states.map((state, stateIndex) => {
    const itemData: number[] = [];
    counts.map((count, countIndex) => {
      const data = multiply(remins[stateIndex], ratio[countIndex]);

      itemData.push(data);
    });
    if (stateIndex === 0) {
      monthlyData = itemData;
    } else {
      monthlyData = add(monthlyData, itemData);
    }
    byItem.push(itemData);
    byItemCumsum.push(cumsum(itemData) as number[]);
    monthlyTotalByItem.push(sum(itemData) as number);
    if (states.length === stateIndex + 1) {
      monthlyTotal = monthlyData;
    }
  });
  const monthlyTotalCumsum = cumsum(monthlyTotal) as number[];

  return {
    byItem,
    monthlyTotal,
    monthlyTotalByItem,
    byItemCumsum,
    monthlyTotalCumsum,
  };
};
