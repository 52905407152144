import { doc, Firestore, writeBatch } from "firebase/firestore";
import ReactDataSheet from "react-datasheet";
import { toast } from "react-toastify";
import { pageConstant } from "../../constants/pageConstants";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import { GridElement } from "../../types/gridElement";
import { fullTextToHalfText } from "../../utils/fullTextToHalfText";

export const HandleCellChange = async ({
  changes,
  url,
  firestore,
  grid,
  dataUrl,
  unit,
}: {
  changes: ReactDataSheet.CellRenderer<GridElement, number | string>;
  url: string;
  firestore: Firestore;
  grid: GridElement[][];
  dataUrl?: string;
  unit: number;
}) => {
  const uid = getStoredClientUid();

  // const updatedGrid = [...grid];

  // // データ更新後、すぐ表示する
  // changes.forEach(
  //   ({
  //     row,
  //     col,
  //     value,
  //   }: {
  //     cell: GridElement;
  //     row: number;
  //     col: number;
  //     value: string | null;
  //   }) => {
  //     updatedGrid[row][col] = {
  //       ...updatedGrid[row][col],
  //       value:
  //         value === undefined || value === null
  //           ? updatedGrid[row][col].value
  //           : String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  //     };
  //   }
  // );

  // await Promise.all(
  //   changes.map(
  //     async (
  //       change: ReactDataSheet.CellRenderer<GridElement, number | string>
  //     ) => {
  //       const dataRef = doc(
  //         firestore,
  //         "accounts",
  //         uid,
  //         "tables",
  //         String(change.cell.row.NO_ID_FIELD)
  //       );

  //       const newRow = change.cell.row;

  //       if (change.cell.key === "descriptions_calc") {
  //         await updateDoc(dataRef, {
  //           [`${change.cell.field as string}`]: change.value,
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (change.cell.key === "descriptions") {
  //         await updateDoc(dataRef, {
  //           descriptions: {
  //             ...newRow.descriptions,
  //             [`${url}_${newRow.field as string}`]: change.value,
  //           },
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (change.cell.key === `sales_plan_cost_price`) {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );
  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey = "cost_price";
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue),
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (change.cell.key === `sales_plan_model_number`) {
  //         const targetKey = "model_number";
  //         await updateDoc(dataRef, {
  //           [targetKey]: String(change.value),
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (change.cell.key === `sales_plan_price`) {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );
  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey = "price";
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue),
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (
  //         change.cell.url === `${dataUrl ? dataUrl : url}_cost_ratio`
  //       ) {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );
  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey =
  //           `${dataUrl ? dataUrl : url}_cost_ratio` +
  //           "." +
  //           `${change.cell.key}`;
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue),
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (
  //         change.cell.url === `sales_receipt` ||
  //         change.cell.url === `payment_on_purchase` ||
  //         change.cell.url === `net_sales_total`
  //       ) {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );
  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey = `${change.cell.url}` + "." + `${change.cell.key}`;
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue) * unit,
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else if (
  //         change.cell.url === `special_income` ||
  //         change.cell.url === `other_special_expenses` ||
  //         change.cell.url === `tax_payment` ||
  //         change.cell.url === `financial_arrangements` ||
  //         change.cell.url === `principal_payments`
  //       ) {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );
  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey =
  //           `${pageConstant.PLANS_INPUT_MONTHLY}` + "." + `${change.cell.key}`;
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue) * unit,
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       } else {
  //         const changeValue = fullTextToHalfText(
  //           change.value.replace(/,/g, "")
  //         );

  //         if (isNaN(Number(changeValue))) {
  //           toast.error("半角数字を入力してください");
  //           return;
  //         }

  //         const targetKey =
  //           `${dataUrl ? dataUrl : url}` + "." + `${change.cell.key}`;
  //         await updateDoc(dataRef, {
  //           [targetKey]: Number(changeValue) * unit,
  //         }).catch((error) => {
  //           throw new Error(error.message);
  //         });
  //       }
  //     }
  //   )
  // );

  // Firestoreのbatch処理
  const batch = writeBatch(firestore);

  for (const change of changes) {
    const dataRef = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      String(change.cell.row.NO_ID_FIELD)
    );

    const newRow = change.cell.row;

    const regex = /^y/;

    if (change.cell.key === "descriptions_calc") {
      batch.update(dataRef, {
        [`${change.cell.field as string}`]: change.value,
      });
    } else if (change.cell.key === "descriptions") {
      batch.update(dataRef, {
        descriptions: {
          ...newRow.descriptions,
          [`${url}_${newRow.field as string}`]: change.value,
        },
      });
    } else if (change.cell.key === `sales_plan_cost_price`) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));
      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey = "cost_price";
      batch.update(dataRef, {
        [targetKey]: Number(changeValue),
      });
    } else if (change.cell.key === `sales_plan_model_number`) {
      const targetKey = "model_number";
      batch.update(dataRef, {
        [targetKey]: String(change.value),
      });
    } else if (change.cell.key === `sales_plan_price`) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));
      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey = "price";
      batch.update(dataRef, {
        [targetKey]: Number(changeValue),
      });
    } else if (change.cell.url === `${dataUrl ? dataUrl : url}_cost_ratio`) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));
      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey =
        `${dataUrl ? dataUrl : url}_cost_ratio` + "." + `${change.cell.key}`;
      batch.update(dataRef, {
        [targetKey]: Number(changeValue),
      });
    } else if (
      change.cell.url === `sales_receipt` ||
      change.cell.url === `payment_on_purchase` ||
      change.cell.url === `net_sales_total`
    ) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));
      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey = `${change.cell.url}` + "." + `${change.cell.key}`;
      batch.update(dataRef, {
        [targetKey]: Number(changeValue) * unit,
      });
    } else if (
      change.cell.url === `special_income` ||
      change.cell.url === `other_special_expenses` ||
      change.cell.url === `tax_payment` ||
      change.cell.url === `financial_arrangements` ||
      change.cell.url === `principal_payments`
    ) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));
      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey =
        `${pageConstant.PLANS_INPUT_MONTHLY}` + "." + `${change.cell.key}`;
      batch.update(dataRef, {
        [targetKey]: Number(changeValue) * unit,
      });
    } else if (
      // 販売計画の中に、yから始まるkeyがある場合
      change.cell.url === `sales_plan` &&
      regex.test(change.cell.key)
    ) {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));

      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey =
        `${dataUrl ? dataUrl : url}` + "." + `${change.cell.key}`;
      batch.update(dataRef, {
        [targetKey]: Number(changeValue),
      });
    } else {
      const changeValue = fullTextToHalfText(change.value.replace(/,/g, ""));

      if (isNaN(Number(changeValue))) {
        toast.error("半角数字を入力してください");
        return;
      }

      const targetKey =
        `${dataUrl ? dataUrl : url}` + "." + `${change.cell.key}`;
      batch.update(dataRef, {
        [targetKey]: Number(changeValue) * unit,
      });
    }
  }

  try {
    await batch.commit();
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("error");
    }
  }

  // すべての更新が完了した後に、gridを更新
  grid.splice(0, grid.length, ...grid);
  // grid.splice(0, grid.length, ...updatedGrid);
};
