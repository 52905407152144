import React from "react";
import MenuPlan from "./MenuPlan";

interface Props {
  isPayment: boolean;
  planType?: number;
}

const BillingPlan: React.FC<Props> = ({ isPayment, planType }) => {
  const plan = planType ? planType : 9999;

  const entry = {
    title: "Entry",
    subtitle: "エントリー",
    price: "free",
    description: "サポートなし",
    contents: [
      "-", 
      "1",
      "O", 
      "-", 
      "-", 
    ],
    planType: 1,
  };

  const basic = {
    title: "Basic",
    subtitle: "ベーシック",
    price: "550",
    description: "メールサポート",
    contents: [
      "メールサポート", 
      "2",
      "O", 
      "-", 
      "-", 
    ],
    planType: 2,
  };

  const business = {
    title: "Basic",
    subtitle: "ビジネス",
    price: "1,375",
    description: "メールサポート",
    contents: [
      "メールサポート", 
      "5",
      "O", 
      "O", 
      "-", 
    ],
    planType: 3,
  };

  const pro = {
    title: "Pro",
    subtitle: "プロ",
    price: "3,300",
    description: "メール・電話サポート",
    contents: [
      "メール・電話サポート", 
      "10",
      "O", 
      "O", 
      "O", 
    ],
    planType: 4,
  };

  return (
    <div className="w-full mt-36px flex justify-between">
      <div className="w-[20%] mt-80px">
        <div className="h-75px"></div>
        <div className="text-15px font-bold h-75px bg-cf4f4f4 mt-2px flex justify-center items-center">サポート</div>
        <div className="text-15px font-bold h-75px bg-cf4f4f4 mt-2px flex justify-center items-center">クライアント企業数</div>
        <div className="text-15px font-bold h-75px bg-cf4f4f4 mt-2px flex justify-center items-center">経営シミュレーション機能</div>
        <div className="text-15px font-bold h-75px bg-cf4f4f4 mt-2px flex justify-center items-center">事業部別分析</div>
        <div className="text-15px font-bold h-75px bg-cf4f4f4 mt-2px flex justify-center items-center">データ出力機能（CSV）</div>

      </div>
      <MenuPlan isPayment={isPayment} isPlan={plan === 1} plan={entry} />
      <MenuPlan isPayment={isPayment} isPlan={plan === 2} plan={basic} />
      <MenuPlan isPayment={isPayment} isPlan={plan === 3} plan={business} />
      <MenuPlan isPayment={isPayment} isPlan={plan === 4} plan={pro} />
    </div>
  );
};

export default BillingPlan;
