import { DocumentData } from "firebase/firestore";
import { nengou } from "../../utils/date";

export const resultsInputHeader = (
  year: string | number,
  month: string | number,
  currentTerm: number
) => [
  {
    nengou: nengou(Number(year) - 9, month),
    period: `y${String(Number(year) - 9)}${String(month)}`,
    term: String(currentTerm),
  },
  {
    nengou: nengou(Number(year) - 8, month),
    period: `y${String(Number(year) - 8)}${String(month)}`,
    term: String(currentTerm + 1),
  },
  {
    nengou: nengou(Number(year) - 7, month),
    period: `y${String(Number(year) - 7)}${String(month)}`,
    term: String(currentTerm + 2),
  },
  {
    nengou: nengou(Number(year) - 6, month),
    period: `y${String(Number(year) - 6)}${String(month)}`,
    term: String(currentTerm + 3),
  },
  {
    nengou: nengou(Number(year) - 5, month),
    period: `y${String(Number(year) - 5)}${String(month)}`,
    term: String(currentTerm + 4),
  },
  {
    nengou: nengou(Number(year) - 4, month),
    period: `y${String(Number(year) - 4)}${String(month)}`,
    term: String(currentTerm + 5),
  },
  {
    nengou: nengou(Number(year) - 3, month),
    period: `y${String(Number(year) - 3)}${String(month)}`,
    term: String(currentTerm + 6),
  },
  {
    nengou: nengou(Number(year) - 2, month),
    period: `y${String(Number(year) - 2)}${String(month)}`,
    term: String(currentTerm + 7),
  },
  {
    nengou: nengou(Number(year) - 1, month),
    period: `y${String(Number(year) - 1)}${String(month)}`,
    term: String(currentTerm + 8),
  },

  {
    nengou: nengou(Number(year), month),
    period: `y${String(Number(year))}${String(month)}`,
    term: String(currentTerm + 9),
  },
];

// PLの初期データ
export const profitAndLossStatementData: DocumentData[] = [
  {
    field: "net_sales",
    item: "売上1",
    section: "",
    order: 1,
  },
  {
    field: "net_sales",
    item: "売上2",
    section: "",
    order: 2,
  },
  {
    field: "cost_of_sales",
    item: "期首商品棚卸高",
    section: "変動",
    order: 1,
  },
  {
    field: "cost_of_sales",
    item: "当期商品仕入高",
    section: "変動",
    order: 2,
  },
  {
    field: "cost_of_sales",
    item: "当期製品製造原価",
    section: "変動",
    order: 3,
  },
  {
    field: "cost_of_sales",
    item: "他勘定振替高(製)",
    section: "変動",
    order: 4,
  },
  {
    field: "cost_of_sales",
    item: "期末商品棚卸高",
    section: "変動",
    order: 99999,
  },
  {
    field: "personal_cost",
    item: "役員報酬",
    section: "固定",
    order: 1,
  },
  {
    field: "personal_cost",
    item: "役員賞与",
    section: "固定",
    order: 2,
  },
  {
    field: "personal_cost",
    item: "給与手当",
    section: "固定",
    order: 3,
  },
  {
    field: "personal_cost",
    item: "雑給",
    section: "固定",
    order: 4,
  },
  {
    field: "personal_cost",
    item: "賞与",
    section: "固定",
    order: 5,
  },
  {
    field: "personal_cost",
    item: "退職金",
    section: "固定",
    order: 6,
  },
  {
    field: "personal_cost",
    item: "法定福利費",
    section: "固定",
    order: 7,
  },
  {
    field: "personal_cost",
    item: "福利厚生費",
    section: "固定",
    order: 8,
  },
  {
    field: "personal_cost",
    item: "退職給付費用",
    section: "固定",
    order: 9,
  },
  {
    field: "personal_cost",
    item: "採用教育費",
    section: "固定",
    order: 10,
  },
  {
    field: "personal_cost",
    item: "研修費",
    section: "固定",
    order: 11,
  },
  {
    field: "selling_and_administrative",
    item: "減価償却費",
    section: "固定",
    order: 1,
  },
  {
    field: "selling_and_administrative",
    item: "地代家賃",
    section: "固定",
    order: 2,
  },
  {
    field: "selling_and_administrative",
    item: "荷造運賃",
    section: "固定",
    order: 3,
  },
  {
    field: "selling_and_administrative",
    item: "広告宣伝費",
    section: "固定",
    order: 4,
  },
  {
    field: "selling_and_administrative",
    item: "交際費",
    section: "固定",
    order: 5,
  },
  {
    field: "selling_and_administrative",
    item: "会議費",
    section: "固定",
    order: 6,
  },
  {
    field: "selling_and_administrative",
    item: "旅費交通費",
    section: "固定",
    order: 7,
  },
  {
    field: "selling_and_administrative",
    item: "通信費",
    section: "固定",
    order: 8,
  },
  {
    field: "selling_and_administrative",
    item: "販売手数料",
    section: "固定",
    order: 9,
  },
  {
    field: "selling_and_administrative",
    item: "販売促進費",
    section: "固定",
    order: 10,
  },
  {
    field: "selling_and_administrative",
    item: "消耗品費",
    section: "固定",
    order: 11,
  },
  {
    field: "selling_and_administrative",
    item: "事務用品費",
    section: "固定",
    order: 12,
  },
  {
    field: "selling_and_administrative",
    item: "修繕費",
    section: "固定",
    order: 13,
  },
  {
    field: "selling_and_administrative",
    item: "水道光熱費",
    section: "固定",
    order: 14,
  },
  {
    field: "selling_and_administrative",
    item: "新聞図書費",
    section: "固定",
    order: 15,
  },
  {
    field: "selling_and_administrative",
    item: "諸会費",
    section: "固定",
    order: 16,
  },
  {
    field: "selling_and_administrative",
    item: "支払手数料",
    section: "固定",
    order: 17,
  },
  {
    field: "selling_and_administrative",
    item: "車両費",
    section: "固定",
    order: 18,
  },
  {
    field: "selling_and_administrative",
    item: "外注費",
    section: "固定",
    order: 19,
  },
  {
    field: "selling_and_administrative",
    item: "賃借料",
    section: "固定",
    order: 20,
  },
  {
    field: "selling_and_administrative",
    item: "リース料",
    section: "固定",
    order: 21,
  },
  {
    field: "selling_and_administrative",
    item: "保険料",
    section: "固定",
    order: 22,
  },
  {
    field: "selling_and_administrative",
    item: "租税公課",
    section: "固定",
    order: 23,
  },
  {
    field: "selling_and_administrative",
    item: "支払報酬料",
    section: "固定",
    order: 24,
  },
  {
    field: "selling_and_administrative",
    item: "寄付金",
    section: "固定",
    order: 25,
  },
  {
    field: "selling_and_administrative",
    item: "燃料費",
    section: "固定",
    order: 26,
  },
  {
    field: "selling_and_administrative",
    item: "研究開発費",
    section: "固定",
    order: 27,
  },
  {
    field: "selling_and_administrative",
    item: "長期前払費用償却",
    section: "固定",
    order: 28,
  },
  {
    field: "selling_and_administrative",
    item: "繰延資産償却(販)",
    section: "固定",
    order: 29,
  },
  {
    field: "selling_and_administrative",
    item: "貸倒損失(販)",
    section: "固定",
    order: 30,
  },
  {
    field: "selling_and_administrative",
    item: "貸倒引当金繰入額(販)",
    section: "固定",
    order: 31,
  },
  {
    field: "selling_and_administrative",
    item: "雑費",
    section: "固定",
    order: 32,
  },
  {
    field: "selling_and_administrative",
    item: "管理諸費",
    section: "固定",
    order: 33,
  },
  {
    field: "non_operating_income",
    item: "受取利息",
    section: "固定",
    order: 1,
  },
  {
    field: "non_operating_income",
    item: "受取配当金",
    section: "固定",
    order: 2,
  },
  {
    field: "non_operating_income",
    item: "仕入割引",
    section: "固定",
    order: 3,
  },
  {
    field: "non_operating_income",
    item: "有価証券売却益",
    section: "固定",
    order: 4,
  },
  {
    field: "non_operating_income",
    item: "貸倒引当金戻入額",
    section: "固定",
    order: 5,
  },
  {
    field: "non_operating_income",
    item: "雑収入",
    section: "固定",
    order: 6,
  },
  {
    field: "non_operating_income",
    item: "為替差益",
    section: "固定",
    order: 7,
  },
  {
    field: "non_operating_expenses",
    item: "支払利息",
    section: "固定",
    order: 1,
  },
  {
    field: "non_operating_expenses",
    item: "割引料",
    section: "固定",
    order: 2,
  },
  {
    field: "non_operating_expenses",
    item: "手形売却損",
    section: "固定",
    order: 3,
  },
  {
    field: "non_operating_expenses",
    item: "貸倒損失(外)",
    section: "固定",
    order: 4,
  },
  {
    field: "non_operating_expenses",
    item: "売上割引",
    section: "固定",
    order: 5,
  },
  {
    field: "non_operating_expenses",
    item: "有価証券売却損",
    section: "固定",
    order: 6,
  },
  {
    field: "non_operating_expenses",
    item: "繰延資産償却(外)",
    section: "固定",
    order: 7,
  },
  {
    field: "non_operating_expenses",
    item: "貸倒引当金繰入額(外)",
    section: "固定",
    order: 8,
  },
  {
    field: "non_operating_expenses",
    item: "貸倒引当金繰入額(外)",
    section: "固定",
    order: 9,
  },
  {
    field: "non_operating_expenses",
    item: "雑損失",
    section: "固定",
    order: 10,
  },
  {
    field: "non_operating_expenses",
    item: "為替差損",
    section: "固定",
    order: 11,
  },
  {
    field: "special_benefits",
    item: "前期損益修正益",
    section: "固定",
    order: 1,
  },
  {
    field: "special_benefits",
    item: "固定資産売却益",
    section: "固定",
    order: 2,
  },
  {
    field: "special_benefits",
    item: "投資有価証券売却益",
    section: "固定",
    order: 3,
  },
  {
    field: "special_benefits",
    item: "貸倒引当金戻入益",
    section: "固定",
    order: 4,
  },
  {
    field: "special_losses",
    item: "前期損益修正損",
    section: "固定",
    order: 1,
  },
  {
    field: "special_losses",
    item: "固定資産売却損",
    section: "固定",
    order: 2,
  },
  {
    field: "special_losses",
    item: "固定資産除却損",
    section: "固定",
    is_non_cash_out: true,
    order: 3,
  },
  {
    field: "special_losses",
    item: "投資有価証券売却損",
    section: "固定",
    order: 4,
  },
  {
    field: "special_corporate_inhabitant_and_enterprise_taxeslosses",
    item: "法人税、住民税及び事業税",
    section: "",
    order: 1,
  },
];

// 原価の初期データ
export const costReportData: DocumentData[] = [
  {
    field: "material_fee",
    item: "期首材料棚卸高",
    section: "変動",
    order: 1,
  },
  {
    field: "material_fee",
    item: "材料仕入高",
    section: "変動",
    order: 2,
  },
  {
    field: "material_fee",
    item: "期末材料棚卸高",
    section: "変動",
    order: 99999,
  },
  {
    field: "labor_costs",
    item: "役員報酬",
    section: "固定",
    order: 1,
  },
  {
    field: "labor_costs",
    item: "給料手当",
    section: "固定",
    order: 2,
  },
  {
    field: "labor_costs",
    item: "雑給",
    section: "固定",
    order: 3,
  },
  {
    field: "labor_costs",
    item: "賞与",
    section: "固定",
    order: 4,
  },
  {
    field: "labor_costs",
    item: "退職金",
    section: "固定",
    order: 5,
  },
  {
    field: "labor_costs",
    item: "法定福利費",
    section: "固定",
    order: 6,
  },
  {
    field: "labor_costs",
    item: "福利厚生費",
    section: "固定",
    order: 7,
  },
  {
    field: "labor_costs",
    item: "退職給付費用",
    section: "固定",
    order: 8,
  },
  {
    field: "external_injection_processing_fee",
    item: "外注加工費",
    section: "変動",
    order: 1,
  },
  {
    field: "expenses",
    item: "減価償却費",
    section: "固定",
    order: 1,
  },
  {
    field: "expenses",
    item: "地代家賃",
    section: "固定",
    order: 2,
  },
  {
    field: "expenses",
    item: "動力費",
    section: "固定",
    order: 3,
  },
  {
    field: "expenses",
    item: "荷造運賃",
    section: "固定",
    order: 4,
  },
  {
    field: "expenses",
    item: "会議費",
    section: "固定",
    order: 5,
  },
  {
    field: "expenses",
    item: "旅費交通費",
    section: "固定",
    order: 6,
  },
  {
    field: "expenses",
    item: "通信費",
    section: "固定",
    order: 7,
  },
  {
    field: "expenses",
    item: "消耗品費",
    section: "固定",
    order: 8,
  },
  {
    field: "expenses",
    item: "事務用品費",
    section: "固定",
    order: 9,
  },
  {
    field: "expenses",
    item: "消耗工具費",
    section: "固定",
    order: 10,
  },
  {
    field: "expenses",
    item: "車両費",
    section: "固定",
    order: 11,
  },
  {
    field: "expenses",
    item: "水道光熱費",
    section: "固定",
    order: 12,
  },
  {
    field: "expenses",
    item: "新聞図書費",
    section: "固定",
    order: 13,
  },
  {
    field: "expenses",
    item: "諸会費",
    section: "固定",
    order: 14,
  },
  {
    field: "expenses",
    item: "賃借料",
    section: "固定",
    order: 15,
  },
  {
    field: "expenses",
    item: "租税公課",
    section: "固定",
    order: 16,
  },
  {
    field: "expenses",
    item: "保険料",
    section: "固定",
    order: 17,
  },
  {
    field: "expenses",
    item: "支払報酬料",
    section: "固定",
    order: 18,
  },
  {
    field: "expenses",
    item: "寄付金",
    section: "固定",
    order: 19,
  },
  {
    field: "expenses",
    item: "研究開発費",
    section: "固定",
    order: 20,
  },
  {
    field: "expenses",
    item: "燃料費",
    section: "固定",
    order: 21,
  },
  {
    field: "expenses",
    item: "保管料",
    section: "固定",
    order: 22,
  },
  {
    field: "expenses",
    item: "特許使用料",
    section: "固定",
    order: 23,
  },
  {
    field: "expenses",
    item: "棚卸減耗損",
    section: "固定",
    order: 24,
  },
  {
    field: "expenses",
    item: "雑費",
    section: "固定",
    order: 25,
  },
  {
    field: "inventory_of_work_in_process_at_the_beginning_of_the_period",
    item: "期首仕掛品棚卸高",
    section: "",
    order: 1,
  },
  {
    field: "inventory_of_work_in_process_at_the_end_of_the_period",
    item: "期末仕掛品棚卸高",
    section: "",
    order: 1,
  },
  {
    field: "inventory_of_work_in_process_at_the_beginning_of_the_period",
    item: "期首半製品棚卸高",
    section: "",
    order: 1,
  },
  {
    field: "inventory_of_work_in_process_at_the_end_of_the_period",
    item: "期末半製品棚卸高",
    section: "",
    order: 1,
  },
];

// BSの初期データ
export const balanceSheetData: DocumentData[] = [
  {
    field: "cash_equivalent",
    item: "現金",
    section: null,
    order: 1,
  },
  {
    field: "cash_equivalent",
    item: "小口現金",
    section: null,
    order: 2,
  },
  {
    field: "cash_equivalent",
    item: "当座預金",
    section: null,
    order: 3,
  },
  {
    field: "cash_equivalent",
    item: "普通預金",
    section: null,
    order: 4,
  },
  {
    field: "cash_equivalent",
    item: "定期預金",
    section: null,
    order: 5,
  },
  {
    field: "cash_equivalent",
    item: "通知預金",
    section: null,
    order: 6,
  },
  {
    field: "cash_equivalent",
    item: "定期積金",
    section: null,
    order: 7,
  },
  {
    field: "cash_equivalent",
    item: "別段預金",
    section: null,
    order: 8,
  },
  {
    field: "cash_equivalent",
    item: "郵便貯金",
    section: null,
    order: 9,
  },
  {
    field: "accounts_receivable",
    item: "受取手形",
    section: null,
    order: 1,
  },
  {
    field: "accounts_receivable",
    item: "不渡手形",
    section: null,
    order: 2,
  },
  {
    field: "accounts_receivable",
    item: "売掛金",
    section: null,
    order: 3,
  },
  {
    field: "accounts_receivable",
    item: "完成工事未収入金",
    section: null,
    order: 4,
  },
  {
    field: "current_assets",
    item: "有価証券",
    section: null,
    order: 1,
  },
  {
    field: "shed_unloading_assets",
    item: "商品",
    section: null,
    order: 1,
  },
  {
    field: "shed_unloading_assets",
    item: "製品",
    section: null,
    order: 2,
  },
  {
    field: "shed_unloading_assets",
    item: "副産物作業くず",
    section: null,
    order: 3,
  },
  {
    field: "shed_unloading_assets",
    item: "半製品",
    section: null,
    order: 4,
  },
  {
    field: "shed_unloading_assets",
    item: "原材料",
    section: null,
    order: 5,
  },
  {
    field: "shed_unloading_assets",
    item: "仕掛品",
    section: null,
    order: 6,
  },
  {
    field: "shed_unloading_assets",
    item: "貯蔵品",
    section: null,
    order: 7,
  },
  {
    field: "shed_unloading_assets",
    item: "未成工事支出金",
    section: null,
    order: 8,
  },
  {
    field: "quotidian_gold",
    item: "貸倒引当金(売)",
    section: null,
    order: 1,
  },
  {
    field: "other_current_assets",
    item: "前渡金",
    section: null,
    order: 1,
  },
  {
    field: "other_current_assets",
    item: "短期貸付金",
    section: null,
    order: 2,
  },
  {
    field: "other_current_assets",
    item: "立替金",
    section: null,
    order: 3,
  },
  {
    field: "other_current_assets",
    item: "前払費用",
    section: null,
    order: 4,
  },
  {
    field: "other_current_assets",
    item: "繰延税金資産(流)",
    section: null,
    order: 5,
  },
  {
    field: "other_current_assets",
    item: "未収収益",
    section: null,
    order: 6,
  },
  {
    field: "other_current_assets",
    item: "未収還付法人税等",
    section: null,
    order: 7,
  },
  {
    field: "other_current_assets",
    item: "役員貸付金",
    section: null,
    order: 8,
  },
  {
    field: "other_current_assets",
    item: "未収入金",
    section: null,
    order: 9,
  },
  {
    field: "other_current_assets",
    item: "仮払金",
    section: null,
    order: 10,
  },
  {
    field: "other_current_assets",
    item: "預け金",
    section: null,
    order: 11,
  },
  {
    field: "other_current_assets",
    item: "仮払消費税等",
    section: null,
    order: 12,
  },
  {
    field: "other_current_assets",
    item: "貸倒引当金(他)",
    section: null,
    order: 13,
  },
  {
    field: "tangible_fixed_assets",
    item: "建物",
    section: null,
    order: 1,
  },
  {
    field: "tangible_fixed_assets",
    item: "附属設備",
    section: null,
    order: 2,
  },
  {
    field: "tangible_fixed_assets",
    item: "構築物",
    section: null,
    order: 3,
  },
  {
    field: "tangible_fixed_assets",
    item: "機械装置",
    section: null,
    order: 4,
  },
  {
    field: "tangible_fixed_assets",
    item: "車両運搬具",
    section: null,
    order: 5,
  },
  {
    field: "tangible_fixed_assets",
    item: "工具器具備品",
    section: null,
    order: 6,
  },
  {
    field: "tangible_fixed_assets",
    item: "一括償却資産",
    section: null,
    order: 7,
  },
  {
    field: "tangible_fixed_assets",
    item: "減価償却累計額",
    section: null,
    order: 8,
  },
  {
    field: "tangible_fixed_assets",
    item: "土地",
    section: null,
    order: 9,
  },
  {
    field: "tangible_fixed_assets",
    item: "建設仮勘定",
    section: null,
    order: 10,
  },
  {
    field: "intangible_fixed_assets",
    item: "電話加入権",
    section: null,
    order: 1,
  },
  {
    field: "intangible_fixed_assets",
    item: "施設利用権",
    section: null,
    order: 2,
  },
  {
    field: "intangible_fixed_assets",
    item: "工業所有権",
    section: null,
    order: 3,
  },
  {
    field: "intangible_fixed_assets",
    item: "営業権",
    section: null,
    order: 4,
  },
  {
    field: "intangible_fixed_assets",
    item: "借地権",
    section: null,
    order: 5,
  },
  {
    field: "intangible_fixed_assets",
    item: "ソフトウェア",
    section: null,
    order: 6,
  },
  {
    field: "investments_and_other_assets",
    item: "長期貸付金",
    section: null,
    order: 1,
  },
  {
    field: "investments_and_other_assets",
    item: "投資有価証券",
    section: null,
    order: 2,
  },
  {
    field: "investments_and_other_assets",
    item: "関係会社株式",
    section: null,
    order: 3,
  },
  {
    field: "investments_and_other_assets",
    item: "出資金",
    section: null,
    order: 4,
  },
  {
    field: "investments_and_other_assets",
    item: "関係会社出資金",
    section: null,
    order: 5,
  },
  {
    field: "investments_and_other_assets",
    item: "敷金",
    section: null,
    order: 6,
  },
  {
    field: "investments_and_other_assets",
    item: "差入保証金",
    section: null,
    order: 7,
  },
  {
    field: "investments_and_other_assets",
    item: "長期固定性預金",
    section: null,
    order: 8,
  },
  {
    field: "investments_and_other_assets",
    item: "長期滞留債権",
    section: null,
    order: 9,
  },
  {
    field: "investments_and_other_assets",
    item: "長期前払費用",
    section: null,
    order: 10,
  },
  {
    field: "investments_and_other_assets",
    item: "前払年金費用",
    section: null,
    order: 11,
  },
  {
    field: "investments_and_other_assets",
    item: "繰延税金資産",
    section: null,
    order: 12,
  },
  {
    field: "investments_and_other_assets",
    item: "預託金",
    section: null,
    order: 13,
  },
  {
    field: "investments_and_other_assets",
    item: "貸倒引当金(投)",
    section: null,
    order: 14,
  },
  {
    field: "investments_and_other_assets",
    item: "保険積立金",
    section: null,
    order: 15,
  },
  {
    field: "deferred_asset",
    item: "創立費",
    section: null,
    order: 1,
  },
  {
    field: "deferred_asset",
    item: "権利金",
    section: null,
    order: 2,
  },
  {
    field: "deferred_asset",
    item: "開業費",
    section: null,
    order: 3,
  },
  {
    field: "deferred_asset",
    item: "開発費",
    section: null,
    order: 4,
  },
  {
    field: "payables",
    item: "支払手形",
    section: null,
    order: 1,
  },
  {
    field: "payables",
    item: "買掛金",
    section: null,
    order: 2,
  },
  {
    field: "payables",
    item: "工事未払金",
    section: null,
    order: 3,
  },
  {
    field: "short_term_borrowings",
    item: "短期借入金",
    section: null,
    order: 1,
  },
  {
    field: "unpaids",
    item: "未払金",
    section: null,
    order: 2,
  },
  {
    field: "unpaids",
    item: "未払費用",
    section: null,
    order: 3,
  },
  {
    field: "unpaids",
    item: "未払配当金",
    section: null,
    order: 4,
  },
  {
    field: "unpaids",
    item: "未払役員賞与",
    section: null,
    order: 5,
  },
  {
    field: "unpaids",
    item: "未払法人税等",
    section: null,
    order: 6,
  },
  {
    field: "unpaids",
    item: "未払事業税等",
    section: null,
    order: 7,
  },
  {
    field: "unpaids",
    item: "未払消費税等",
    section: null,
    order: 8,
  },
  {
    field: "current_liabilities",
    item: "短期役員借入金",
    section: null,
    order: 1,
  },
  {
    field: "current_liabilities",
    item: "繰延税金負債(流)",
    section: null,
    order: 2,
  },
  {
    field: "current_liabilities",
    item: "前受金",
    section: null,
    order: 3,
  },
  {
    field: "current_liabilities",
    item: "預り金",
    section: null,
    order: 4,
  },
  {
    field: "current_liabilities",
    item: "前受収益",
    section: null,
    order: 5,
  },
  {
    field: "current_liabilities",
    item: "仮受金",
    section: null,
    order: 6,
  },
  {
    field: "current_liabilities",
    item: "預り保証金",
    section: null,
    order: 7,
  },
  {
    field: "current_liabilities",
    item: "割引手形",
    section: null,
    order: 8,
  },
  {
    field: "current_liabilities",
    item: "裏書手形",
    section: null,
    order: 9,
  },
  {
    field: "current_liabilities",
    item: "仮受消費税等",
    section: null,
    order: 10,
  },
  {
    field: "current_liabilities",
    item: "賞与引当金",
    section: null,
    order: 11,
  },
  {
    field: "current_liabilities",
    item: "未成工事受入金",
    section: null,
    order: 12,
  },
  {
    field: "fixed_liabilities",
    item: "長期借入金",
    section: null,
    order: 1,
  },
  {
    field: "fixed_liabilities",
    item: "長期未払金",
    section: null,
    order: 2,
  },
  {
    field: "fixed_liabilities",
    item: "繰延税金負債",
    section: null,
    order: 3,
  },
  {
    field: "fixed_liabilities",
    item: "退職給付引当金",
    section: null,
    order: 4,
  },
  {
    field: "fixed_liabilities",
    item: "社債",
    section: null,
    order: 5,
  },
  {
    field: "capital",
    item: "資本金",
    section: null,
    order: 1,
  },
  {
    field: "capital_surplus",
    item: "資本準備金",
    section: null,
    order: 1,
  },
  {
    field: "capital_surplus",
    item: "資本金及び準備金減少差益",
    section: null,
    order: 2,
  },
  {
    field: "capital_surplus",
    item: "自己株式処分差額",
    section: null,
    order: 3,
  },
  {
    field: "retained_earnings",
    item: "利益準備金",
    section: null,
    order: 1,
  },
  {
    field: "other_retained_earnings",
    item: "別途積立金",
    section: null,
    order: 2,
  },
  {
    field: "other_retained_earnings",
    item: "繰越利益剰余金",
    section: null,
    order: 3,
  },
  {
    field: "treasury_stock",
    item: "自己株式",
    section: null,
    order: 3,
  },
  {
    field: "employees",
    item: "取締役数",
    section: null,
    order: 1,
  },
  {
    field: "employees",
    item: "正社員数",
    section: null,
    order: 2,
  },
  {
    field: "employees",
    item: "パート数",
    section: null,
    order: 3,
  },
  {
    field: "net_sales_total",
    item: "今期までの売上高",
    section: null,
    order: 1,
  },
  {
    field: "cost_of_sales_total",
    item: "今期までの売上原価",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の期首商品棚卸高",
    section: null,
    order: 11,
  },
  {
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の仕入高",
    section: null,
    order: 11,
  },
  {
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の合計",
    section: null,
    order: 12,
  },
  {
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の期末商品棚卸高",
    section: null,
    order: 99999,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の役員報酬",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の給料手当",
    section: null,
    order: 2,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の法定福利費",
    section: null,
    order: 3,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の退職金掛金",
    section: null,
    order: 4,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の福利厚生費",
    section: null,
    order: 5,
  },
  {
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画のその他人件費",
    section: null,
    order: 6,
  },
  {
    field: "profit_and_loss_plan_special_benefits",
    item: "損益計画の特別利益",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_special_losses",
    item: "損益計画の特別損失",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_corporate_inhabitant",
    item: "損益計画の法人税等",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_depreciation_cost",
    item: "損益計画の減価償却費",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_pay_back_loan",
    item: "損益計画の借入金返済額",
    section: null,
    order: 1,
  },
  {
    field: "profit_and_loss_plan_balance_of_loan",
    item: "損益計画の借入金残高",
    section: null,
    order: 1,
  },
  {
    field: "business_plan_depreciation_cost",
    item: "事業計画ページの減価償却費",
    section: null,
    order: 1,
  },
  {
    field: "business_plan_capital_investment",
    item: "事業計画ページの設備投資額",
    section: null,
    order: 1,
  },
  {
    field: "statement_of_cash_flows",
    item: "その他損益",
    section: null,
    order: 1,
  },
  {
    field: "statement_of_cash_flows",
    item: "その他の固定負債の増加減少額",
    section: null,
    order: 2,
  },
  {
    field: "statement_of_cash_flows",
    item: "利益処分による役員賞与の支払額",
    section: null,
    order: 3,
  },
  {
    field: "statement_of_cash_flows",
    item: "その他調整額",
    section: null,
    order: 4,
  },
  {
    field: "statement_of_cash_flows",
    item: "有価証券の購入売却額",
    section: null,
    order: 5,
  },
  {
    field: "statement_of_cash_flows",
    item: "土地の購入売却額",
    section: null,
    order: 6,
  },
  {
    field: "statement_of_cash_flows",
    item: "建設仮勘定の増加減少額",
    section: null,
    order: 7,
  },
  {
    field: "statement_of_cash_flows",
    item: "繰延資産の増加減少額",
    section: null,
    order: 8,
  },
  {
    field: "statement_of_cash_flows",
    item: "剰余金の配当の支払額",
    section: null,
    order: 9,
  },
];
