import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlPlansInputEstimatedInThisTermBody = ({
  headers,
  rows,
  title,
  totalByItem,
  url,
  unit,
  bodyField,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  title: string | null;
  totalByItem: number[];
  url: string;
  unit: number;
  bodyField: string;
}) => {
  const grid: GridElement[][] = [];
  {
    rows.length !== 0 &&
      rows.map((row: DocumentData, rowIndex: number) => {
        // className
        const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white top-0 font-bold align-text-top mt-12px before:border-l before:h-full`;

        const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-l before:border-l-c3333334d ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

        const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x before:border-x-c3333334d ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

        const classCol4 = `${borderSettings}  ${
          rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
        } text-right   ${
          rowIndex % 2 !== 0
            ? "bg-c2a4b770d before:border-l-c3333334d"
            : "before:border-l-c3333334d"
        } `;

        const classCol5 = `relative p-4px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
          rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
        } text-right   ${
          rowIndex % 2 !== 0
            ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
            : "before:bg-cfffee2 before:border-x"
        }`;

        const classCol6 = `${borderSettings} before:border-r ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }  ${
          rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } text-left  h-50px description`;

        // 行の値
        const gridRow = [];

        gridRow.push({
          value: rowIndex === 0 ? title : "",
          expr: rowIndex === 0 ? title : "",
          readOnly: true,
          className: classCol1,
          colSpan: 2,
          row: row,
          url: url,
          key: "",
        });

        gridRow.push(
          { value: row.item, readOnly: true, className: classCol2 },
          {
            value: row.section,
            readOnly: true,
            className: `${classCol3} border-x-c3333334d`,
          }
        );

        headers.map((header: DocumentData) => {
          // オブジェクトの中から該当する値を取得
          const resultsAndPlanTerm = row[url];

          type TypeOfResultObj = typeof resultsAndPlanTerm;
          const key: keyof TypeOfResultObj = header.period;
          const resultValue = row[url][key] ?? 0;

          gridRow.push({
            value:
              resultValue === null || !resultValue
                ? 0
                : ShowTableData(resultValue / unit),
            expr:
              resultValue === null ||
              !resultValue ||
              Number.isFinite(resultValue)
                ? 0
                : resultValue / unit,
            readOnly: false,
            className: classCol4,
            row: row,
            url: url,
            key: key as string,
            colSpan: 1,
          });
        });
        {
          gridRow.push({
            value: ShowTableData(totalByItem[rowIndex] / unit),
            expr: totalByItem[rowIndex] / unit,
            readOnly: true,
            className: classCol5,
            row: row,
            url: url,
            key: "total",
            colSpan: 1,
          });
        }

        gridRow.push({
          value: row?.descriptions[`${url}_${row?.field as string}`],
          expr: row?.descriptions[`${url}_${row?.field as string}`],
          readOnly: false,
          className: classCol6,
          row: row,
          url: url,
          field: `${bodyField}_${row?.field as string}`,
          key: "descriptions",
          colSpan: 1,
        });
        grid.push(gridRow);
      });
  }

  return grid;
};
