import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CurrentAndPreviousYearComparisonsHeader,
  CurrentAndPreviousYearComparisonsTableBlank,
  PlCurrentAndPreviousYearComparisonsBody,
  PlCurrentAndPreviousYearComparisonsGrandTotal,
  PlCurrentAndPreviousYearComparisonsSimpleCf,
  PlCurrentAndPreviousYearComparisonsTotal,
  PlCurrentAndPreviousYearComparisonsWithTax,
} from "../../../lib/datasheets/currentAndPreviousYearComparisons";
import { CurrentAndPreviousYearComparisonsFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  state: CurrentAndPreviousYearComparisonsFieldsCalc;
}

const PlCurrentAndPreviousYearComparisonsTable: React.FC<Props> = ({
  url,
  state,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlCurrentAndPreviousYearComparisonsBody({
    currentHeaders:
      resultsAndPlansDataState.headers.results_of_the_previous_period,
    title: "売上高",
    rows: resultsAndPlansDataState.net_sales,
    previousTotalByItem: state.net_sales_total_by_item_for_previous,
    currentTotalByItem: state.net_sales_total_by_item_for_current,
    subtract: state.net_sales_total_by_item_subtract,
    ratio: state.net_sales_total_by_item_ratio,
    reference: state.net_sales_total_by_item_reference,
    url: url,
    unit: unit,
  });
  grid.push(
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      previousTotal: state.net_sales_total_for_previous,
      currentTotal: state.net_sales_total_for_current,
      totalSubtract: state.net_sales_total_subtract,
      totalRatio: state.net_sales_total_ratio,
      totalReference: state.net_sales_total_reference,
      totalFixedValue: state.net_sales_total_fixed,
      previousRatio: null,
      currentRatio: null,
      referenceRatio: state.net_sales_total_reference_ratio,
      fixedRatio: state.net_sales_total_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_net_sales_total`,
      ratioField: `${url}_net_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),

    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "売上原価",
      rows: resultsAndPlansDataState.cost_of_sales,
      previousTotalByItem: state.cost_of_sales_total_by_item_for_previous,
      currentTotalByItem: state.cost_of_sales_total_by_item_for_current,
      subtract: state.cost_of_sales_total_by_item_subtract,
      ratio: state.cost_of_sales_total_by_item_ratio,
      reference: state.cost_of_sales_total_by_item_reference,
      url: url,
      unit: unit,
    }),

    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      previousTotal: state.cost_of_sales_total_for_previous,
      currentTotal: state.cost_of_sales_total_for_current,
      totalSubtract: state.cost_of_sales_total_subtract,
      totalRatio: state.cost_of_sales_total_ratio,
      totalReference: state.cost_of_sales_total_reference,
      previousRatio: state.cost_of_sales_ratio_for_previous,
      currentRatio: state.cost_of_sales_ratio_for_current,
      referenceRatio: state.cost_of_sales_total_reference_ratio,
      totalFixedValue: state.cost_of_sales_total_fixed,
      fixedRatio: state.cost_of_sales_total_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_cost_of_sales_total`,
      ratioField: `${url}_cost_of_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      previousTotal: state.gross_margin_for_previous,
      currentTotal: state.gross_margin_for_current,
      totalSubtract: state.gross_margin_subtract,
      totalRatio: state.gross_margin_ratio,
      totalReference: state.gross_margin_reference,
      previousRatio: state.gross_margin_ratio_for_previous,
      currentRatio: state.gross_margin_ratio_for_current,
      referenceRatio: state.gross_margin_reference_ratio,
      fixedValue: state.gross_margin_fixed,
      fixedRatio: state.gross_margin_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_cost_of_sales_total`,
      ratioField: `${url}_gross_margin_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "販売費及び一般管理費",
      rows: resultsAndPlansDataState.personal_cost,
      previousTotalByItem: state.personal_cost_total_by_item_for_previous,
      currentTotalByItem: state.personal_cost_total_by_item_for_current,
      subtract: state.personal_cost_total_by_item_subtract,
      ratio: state.personal_cost_total_by_item_ratio,
      reference: state.personal_cost_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上人件費比率",
      previousTotal: state.personal_cost_total_for_previous,
      currentTotal: state.personal_cost_total_for_current,
      totalSubtract: state.personal_cost_total_subtract,
      totalRatio: state.personal_cost_total_ratio,
      totalReference: state.personal_cost_total_reference,
      previousRatio: state.personal_cost_ratio_for_previous,
      currentRatio: state.personal_cost_ratio_for_current,
      referenceRatio: state.personal_cost_total_reference_ratio,
      totalFixedValue: state.personal_cost_total_fixed,
      fixedRatio: state.personal_cost_total_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_personal_cost_total`,
      ratioField: `${url}_personal_cost_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "",
      rows: resultsAndPlansDataState.selling_and_administrative,
      previousTotalByItem:
        state.selling_and_administrative_total_by_item_for_previous,
      currentTotalByItem:
        state.selling_and_administrative_total_by_item_for_current,
      subtract: state.selling_and_administrative_total_by_item_subtract,
      ratio: state.selling_and_administrative_total_by_item_ratio,
      reference: state.selling_and_administrative_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      previousTotal: state.selling_and_administrative_total_for_previous,
      currentTotal: state.selling_and_administrative_total_for_current,
      totalSubtract: state.selling_and_administrative_total_subtract,
      totalRatio: state.selling_and_administrative_total_ratio,
      totalReference: state.selling_and_administrative_total_reference,
      previousRatio: state.selling_and_administrative_ratio_for_previous,
      currentRatio: state.selling_and_administrative_ratio_for_current,
      referenceRatio: state.selling_and_administrative_total_reference_ratio,
      totalFixedValue: state.selling_and_administrative_total_fixed,
      fixedRatio: state.selling_and_administrative_total_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_selling_and_administrative_total`,
      ratioField: `${url}_selling_and_administrative_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      previousTotal: state.operating_income_for_previous,
      currentTotal: state.operating_income_for_current,
      totalSubtract: state.operating_income_subtract,
      totalRatio: state.operating_income_ratio,
      totalReference: state.operating_income_reference,
      previousRatio: state.operating_income_ratio_for_previous,
      currentRatio: state.operating_income_ratio_for_current,
      referenceRatio: state.operating_income_reference_ratio,
      fixedValue: state.operating_income_fixed,
      fixedRatio: state.operating_income_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_operating_income`,
      ratioField: `${url}_operating_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      previousTotal: state.operating_profits_before_amortization_for_previous,
      currentTotal: state.operating_profits_before_amortization_for_current,
      totalSubtract: state.operating_profits_before_amortization_subtract,
      totalRatio: state.operating_profits_before_amortization_ratio,
      totalReference: state.operating_profits_before_amortization_reference,
      previousRatio:
        state.operating_profits_before_amortization_ratio_for_previous,
      currentRatio:
        state.operating_profits_before_amortization_ratio_for_current,
      referenceRatio:
        state.operating_profits_before_amortization_reference_ratio,
      fixedValue: state.operating_profits_before_amortization_fixed,
      fixedRatio: state.operating_profits_before_amortization_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_operatingProfitsBeforeAmortization`,
      ratioField: `${url}_operatingProfitsBeforeAmortization_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "営業外収益",
      rows: resultsAndPlansDataState.non_operating_income,
      previousTotalByItem:
        state.non_operating_income_total_by_item_for_previous,
      currentTotalByItem: state.non_operating_income_total_by_item_for_current,
      subtract: state.non_operating_income_total_by_item_subtract,
      ratio: state.non_operating_income_total_by_item_ratio,
      reference: state.non_operating_income_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      previousTotal: state.non_operating_income_total_for_previous,
      currentTotal: state.non_operating_income_total_for_current,
      totalSubtract: state.non_operating_income_total_subtract,
      totalRatio: state.non_operating_income_total_ratio,
      totalReference: state.non_operating_income_total_reference,
      totalFixedValue: state.non_operating_income_total_fixed,
      previousRatio: null,
      currentRatio: null,
      referenceRatio: null,
      fixedRatio: null,
      bottomLine: true,
      totalField: `${url}_non_operating_income_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "営業外費用",
      rows: resultsAndPlansDataState.non_operating_expenses,
      previousTotalByItem:
        state.non_operating_expenses_total_by_item_for_previous,
      currentTotalByItem:
        state.non_operating_expenses_total_by_item_for_current,
      subtract: state.non_operating_expenses_total_by_item_subtract,
      ratio: state.non_operating_expenses_total_by_item_ratio,
      reference: state.non_operating_expenses_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      previousTotal: state.non_operating_expenses_total_for_previous,
      currentTotal: state.non_operating_expenses_total_for_current,
      totalSubtract: state.non_operating_expenses_total_subtract,
      totalRatio: state.non_operating_expenses_total_ratio,
      totalReference: state.non_operating_expenses_total_reference,
      totalFixedValue: state.non_operating_expenses_total_fixed,
      previousRatio: null,
      currentRatio: null,
      referenceRatio: null,
      fixedRatio: null,
      bottomLine: true,
      totalField: `${url}_non_operating_expenses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      previousTotal: state.current_earnings_for_previous,
      currentTotal: state.current_earnings_for_current,
      totalSubtract: state.current_earnings_subtract,
      totalRatio: state.current_earnings_ratio,
      totalReference: state.current_earnings_reference,
      previousRatio: state.current_earnings_ratio_for_previous,
      currentRatio: state.current_earnings_ratio_for_current,
      referenceRatio: state.current_earnings_reference_ratio,
      fixedValue: state.current_earnings_fixed,
      fixedRatio: state.current_earnings_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_current_earnings`,
      ratioField: `${url}_current_earnings_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      previousTotal: state.ebitda_for_previous,
      currentTotal: state.ebitda_for_current,
      totalSubtract: state.ebitda_subtract,
      totalRatio: state.ebitda_ratio,
      totalReference: state.ebitda_reference,
      previousRatio: state.ebitda_ratio_for_previous,
      currentRatio: state.ebitda_ratio_for_current,
      referenceRatio: state.ebitda_reference_ratio,
      fixedValue: state.ebitda_fixed,
      fixedRatio: state.ebitda_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_ebitda`,
      ratioField: `${url}_ebitda_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "特別利益",
      rows: resultsAndPlansDataState.special_benefits,
      previousTotalByItem: state.special_benefits_total_by_item_for_previous,
      currentTotalByItem: state.special_benefits_total_by_item_for_current,
      subtract: state.special_benefits_total_by_item_subtract,
      ratio: state.special_benefits_total_by_item_ratio,
      reference: state.special_benefits_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      previousTotal: state.special_benefits_total_for_previous,
      currentTotal: state.special_benefits_total_for_current,
      totalSubtract: state.special_benefits_total_subtract,
      totalRatio: state.special_benefits_total_ratio,
      totalReference: state.special_benefits_total_reference,
      totalFixedValue: state.special_benefits_total_fixed,
      previousRatio: null,
      currentRatio: null,
      referenceRatio: null,
      fixedRatio: null,
      bottomLine: true,
      totalField: `${url}_special_benefits_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsBody({
      currentHeaders:
        resultsAndPlansDataState.headers.results_of_the_previous_period,
      title: "特別損失",
      rows: resultsAndPlansDataState.special_losses,
      previousTotalByItem: state.special_losses_total_by_item_for_previous,
      currentTotalByItem: state.special_losses_total_by_item_for_current,
      subtract: state.special_losses_total_by_item_subtract,
      ratio: state.special_losses_total_by_item_ratio,
      reference: state.special_losses_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      previousTotal: state.special_losses_total_for_previous,
      currentTotal: state.special_losses_total_for_current,
      totalSubtract: state.special_losses_total_subtract,
      totalRatio: state.special_losses_total_ratio,
      totalReference: state.special_losses_total_reference,
      totalFixedValue: state.special_losses_total_fixed,
      previousRatio: null,
      currentRatio: null,
      referenceRatio: null,
      fixedRatio: null,
      bottomLine: true,
      totalField: `${url}_special_losses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      previousTotal: state.ebit_for_previous,
      currentTotal: state.ebit_for_current,
      totalSubtract: state.ebit_subtract,
      totalRatio: state.ebit_ratio,
      totalReference: state.ebit_reference,
      previousRatio: state.ebit_ratio_for_previous,
      currentRatio: state.ebit_ratio_for_current,
      referenceRatio: state.ebit_reference_ratio,
      fixedValue: state.ebit_fixed,
      fixedRatio: state.ebit_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_ebit`,
      ratioField: `${url}_ebit_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsWithTax({
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      previousTotalByItem:
        state.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_for_previous,
      currentTotalByItem:
        state.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_for_current,
      subtract:
        state.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_subtract,
      ratio:
        state.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_ratio,
      reference:
        state.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_reference,
      url: url,
      unit: unit,
    }),
    ...PlCurrentAndPreviousYearComparisonsGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      previousTotal: state.net_income_for_previous,
      currentTotal: state.net_income_for_current,
      totalSubtract: state.net_income_subtract,
      totalRatio: state.net_income_ratio,
      totalReference: state.net_income_reference,
      previousRatio: state.net_income_ratio_for_previous,
      currentRatio: state.net_income_ratio_for_current,
      referenceRatio: state.net_income_reference_ratio,
      fixedValue: state.net_income_fixed,
      fixedRatio: state.net_income_ratio_fixed,
      bottomLine: true,
      totalField: `${url}_net_income`,
      ratioField: `${url}_net_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CurrentAndPreviousYearComparisonsTableBlank({ bottomLine: true }),
    ...PlCurrentAndPreviousYearComparisonsSimpleCf({
      previousTotal1: state.simple_cf_row1_for_previous,
      currentTotal1: state.simple_cf_row1_for_current,
      totalSubtract1: state.simple_cf_row1_subtract,
      totalRatio1: state.simple_cf_row1_ratio,
      totalReference1: state.simple_cf_row1_reference,
      totalFixedValue1: state.simple_cf_row1_fixed,
      previousTotal2: state.simple_cf_row2_for_previous,
      currentTotal2: state.simple_cf_row2_for_current,
      totalSubtract2: state.simple_cf_row2_subtract,
      totalRatio2: state.simple_cf_row2_ratio,
      totalReference2: state.simple_cf_row2_reference,
      totalFixedValue2: state.simple_cf_row2_fixed,
      previousTotal3: state.simple_cf_row3_for_previous,
      currentTotal3: state.simple_cf_row3_for_current,
      totalSubtract3: state.simple_cf_row3_subtract,
      totalRatio3: state.simple_cf_row3_ratio,
      totalReference3: state.simple_cf_row3_reference,
      totalFixedValue3: state.simple_cf_row3_fixed,
      field1: "simple_cf_row1",
      field2: "simple_cf_row2",
      field3: "simple_cf_row3",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <CurrentAndPreviousYearComparisonsHeader
            currentHeaders={
              resultsAndPlansDataState.headers.results_of_the_current_term
            }
            previousHeaders={
              resultsAndPlansDataState.headers.results_of_the_previous_period
            }
            termCount={
              resultsAndPlansDataState.headers.results_of_the_current_term_count
            }
            title="■PL"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlCurrentAndPreviousYearComparisonsTable);
