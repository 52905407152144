import { AxiosResponse } from "axios";
import React, { memo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton } from "../../components/atoms";
import { SubHeader } from "../../components/header";
import { FormContents } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { queryConstants } from "../../constants/queryConstants";
import { typeOfBusinessSelected } from "../../data";
import { useSpecialists } from "../../hooks/useSpecialists";
import { getStoredSpecialistToken } from "../../sessionStorage/sessionStorage";
import { toggleStore } from "../../store/switchState";

const AccountsSpecialists = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const specialist = useSpecialists();

  const toggle = useRecoilValue(toggleStore);

  const [specialistState, setSpecialistState] = useState({
    ...specialist.specialist,
    phone1: String(
      specialist.specialist.phone1
        ? specialist.specialist.phone1
        : specialist.specialist.phone.substring(0, 3)
    ),
    phone2: String(
      specialist.specialist.phone2
        ? specialist.specialist.phone2
        : specialist.specialist.phone.substring(3, 7)
    ),
    phone3: String(
      specialist.specialist.phone3
        ? specialist.specialist.phone3
        : specialist.specialist.phone.substring(7, 11)
    ),
  });

  const accessToken = getStoredSpecialistToken();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: `${apiUrl.SPECIALISTS}/${specialistState.id}`,
        method: "PUT",
        data: {
          id: specialistState.id,
          company_name: specialistState.company_name,
          company_kana_name: specialistState.company_kana_name,
          type_of_business: specialistState.type_of_business,
          department_name: specialistState.department_name,
          position: specialistState.position,
          first_name: specialistState.first_name,
          last_name: specialistState.last_name,
          first_kana_name: specialistState.first_kana_name,
          last_kana_name: specialistState.last_kana_name,
          phone: `${specialistState.phone1}${specialistState.phone2}${specialistState.phone3}`,
          plan_type: specialistState.priceplan.plan_type,
          card_kind: specialistState.priceplan.card_kind,
          card_number: specialistState.priceplan.card_number,
          card_name: specialistState.priceplan.card_name,
          expire_month: specialistState.priceplan.expire_month,
          expire_year: specialistState.priceplan.expire_year,
          card_cvc_number: specialistState.priceplan.card_cvc_number,
          payment_count: specialistState.priceplan.payment_count,
        },
        headers: {
          accessToken: accessToken,
        },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      try {
        const { data }: AxiosResponse = await axiosInstance.get(
          `${apiUrl.USERS}/${specialist.user.uid}/edit`,
          {
            headers: { accessToken: accessToken },
            data: { id: specialist.user.uid },
          }
        );
        queryClient.setQueriesData(queryConstants.SPECIALISTS, data);

        toast.success("更新しました");
        setTimeout(() => {
          navigate(`/${pageConstant.CLIENTS}`);
        }, 3000);
      } catch (error) {
        toast.error("更新できませんでした");
        console.error(error);
        return;
      }
    } catch (error) {
      toast.error("更新できませんでした");
    }
  };

  return (
    <React.Fragment>
      <SubHeader title="アカウント" />
      <div
        className={`fixed ${
          toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
        } h-[calc(100vh-190px)] mt-150px top-0 overflow-x-scroll mb-16px`}
      >
        <div className="px-40px py-25px flex flex-col max-w-[932px] m-auto">
          <form className="" onSubmit={(e) => handleSubmit(e)}>
            <div className="bg-c3333330d px-40px py-25px flex flex-col max-w-[932px] m-auto">
              <FormContents
                htmlFor={"companyName"}
                required={true}
                title={"会社名・団体名"}
              >
                <input
                  className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="companyName"
                  onChange={(e) =>
                    setSpecialistState({
                      ...specialistState,
                      company_name: String(e.target.value),
                    })
                  }
                  placeholder="株式会社〇〇〇〇"
                  required
                  type="text"
                  value={specialistState.company_name}
                />
              </FormContents>
              <FormContents
                htmlFor={"companyKanaName"}
                required={true}
                title={"会社名・団体名（カナ）"}
              >
                <input
                  className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="companyKanaName"
                  onChange={(e) =>
                    setSpecialistState({
                      ...specialistState,
                      company_kana_name: String(e.target.value),
                    })
                  }
                  placeholder="カブシキガイシャ〇〇〇〇"
                  required
                  type="text"
                  value={specialistState.company_kana_name}
                />
              </FormContents>
              <FormContents
                htmlFor={"companyKanaName"}
                required={true}
                title={"業種"}
              >
                <select
                  className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="typeOfBusiness"
                  onChange={(e) =>
                    setSpecialistState({
                      ...specialistState,
                      type_of_business: Number(e.target.value),
                    })
                  }
                  placeholder="選択してください"
                  required
                  value={
                    specialistState.type_of_business === 0
                      ? ""
                      : specialistState.type_of_business
                  }
                >
                  <option disabled selected value="">
                    選択してください
                  </option>
                  {typeOfBusinessSelected.map((select, index) => (
                    <option key={index} value={select.value}>
                      {select.type}
                    </option>
                  ))}
                </select>
              </FormContents>
              <FormContents
                htmlFor={"departmentName"}
                required={false}
                title={"部署名"}
              >
                <input
                  className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="departmentName"
                  onChange={(e) =>
                    setSpecialistState({
                      ...specialistState,
                      department_name: String(e.target.value),
                    })
                  }
                  placeholder="営業部"
                  type="text"
                  value={specialistState.department_name}
                />
              </FormContents>
              <FormContents htmlFor={"position"} required={true} title={"役職"}>
                <input
                  className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="position"
                  onChange={(e) =>
                    setSpecialistState({
                      ...specialistState,
                      position: String(e.target.value),
                    })
                  }
                  placeholder="部長"
                  required
                  type="text"
                  value={specialistState.position}
                />
              </FormContents>
              <FormContents htmlFor={"name"} required={true} title={"氏名"}>
                <span className="w-8/12">
                  <input
                    className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="lastName"
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        last_name: String(e.target.value),
                      })
                    }
                    placeholder="姓"
                    required
                    type="text"
                    value={specialistState.last_name}
                  />
                  <input
                    className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        first_name: String(e.target.value),
                      })
                    }
                    placeholder="名"
                    required
                    type="text"
                    value={specialistState.first_name}
                  />
                </span>
              </FormContents>
              <FormContents
                htmlFor={"kanaName"}
                required={true}
                title={"氏名（カナ）"}
              >
                <span className="w-8/12">
                  <input
                    className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="lastKanaName"
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        last_kana_name: String(e.target.value),
                      })
                    }
                    placeholder="セイ"
                    required
                    type="text"
                    value={specialistState.last_kana_name}
                  />
                  <input
                    className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                    id="firstKanaName"
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        first_kana_name: String(e.target.value),
                      })
                    }
                    placeholder="メイ"
                    required
                    type="text"
                    value={specialistState.first_kana_name}
                  />
                </span>
              </FormContents>
              <FormContents
                htmlFor={"phone"}
                required={true}
                title={"携帯電話番号"}
              >
                <span className="w-[32.666%] flex justify-between mr-auto">
                  <input
                    autoComplete="off"
                    className="shadow appearance-none border border-c33333333 rounded w-[32%] float-left py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="phone1"
                    inputMode="text"
                    maxLength={3}
                    minLength={3}
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        phone1: String(e.target.value),
                      })
                    }
                    pattern="0[5789]0"
                    placeholder="000"
                    required
                    type="text"
                    value={specialistState.phone1}
                  />
                  <input
                    autoComplete="off"
                    className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="phone2"
                    inputMode="text"
                    maxLength={4}
                    minLength={4}
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        phone2: String(e.target.value),
                      })
                    }
                    pattern="[0-9]*"
                    placeholder="0000"
                    required
                    type="text"
                    value={specialistState.phone2}
                  />
                  <input
                    className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="phone3"
                    inputMode="text"
                    maxLength={4}
                    minLength={4}
                    name="phone3"
                    onChange={(e) =>
                      setSpecialistState({
                        ...specialistState,
                        phone3: String(e.target.value),
                      })
                    }
                    pattern="[0-9]*"
                    placeholder="0000"
                    required
                    type="text"
                    value={specialistState.phone3}
                  />
                </span>
              </FormContents>
            </div>
            <div className="w-[450px] m-auto py-30px">
              <DarkButton>更新する</DarkButton>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(AccountsSpecialists);
