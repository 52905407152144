import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DarkButton } from "../../components/atoms";
import { SubHeader } from "../../components/header";
import {
  BillingPlan,
  ConfirmPlanModal,
  BillingFrom,
  CouponFrom,
} from "../../components/molecules";
import { useSpecialists } from "../../hooks/useSpecialists";
import { updateModalIsOpenStore, signUpSpecialistStore } from "../../store";
import { toggleStore } from "../../store/switchState";
import { Link } from "react-router-dom";
import { pageConstant } from "../../constants/pageConstants";

const PlanChange = () => {
  const setUpdateIsOpen = useSetRecoilState(updateModalIsOpenStore);

  const specialist = useSpecialists();

  const toggle = useRecoilValue(toggleStore);

  const plans = [
    "エントリープラン",
    "ベーシックプラン",
    "ビジネスプラン",
    "プロプラン",
  ];

  const [signUpState, setSignUpState] = useRecoilState(signUpSpecialistStore);
  const [showCard, setShowCard] = useState(false);

  function card_narrow_down() {
    setShowCard(!showCard);
  }

  const handleClick = () => {
    if (specialist.specialist.priceplan.plan_type == signUpState.plan_type  || signUpState.plan_type == null) {
      return;
    }
    if (signUpState.plan_type === 1) {
      setUpdateIsOpen(true);
      return;
    }
    if (showCard) {
      setSignUpState({
        ...signUpState,
        has_card: true,
      });

      if (signUpState.card_number.length < 14) {
        toast.warn("正しいカード番号が入力されていません。");
        return;
      } else if (signUpState.card_kind === null) {
        toast.warn(
          "使用できない発行会社のカードもしくは、カード番号が間違っています。"
        );
        return;
      } else if (signUpState.card_name.length === 0) {
        toast.warn("カード名義が入力されていません。");
        return;
      } else if (
        signUpState.expire_month === 0 ||
        signUpState.expire_year === 0
      ) {
        toast.warn("有効期限が入力されていません。");
        return;
      } else if (signUpState.card_cvc_number.length === 0) {
        toast.warn("CVC番号が入力されていません。");
        return;
      // } else if (signUpState.company_name.length === 0) {
      } else{
        // toast.warn(
        //   "登録情報がリセットされました。お手数ですが、もう一度利用者情報の登録をお願いします。"
        // );
        setUpdateIsOpen(true);
        return;
      }
    } else {
      setUpdateIsOpen(true);
      return;
    }
  };
  useEffect(() => {
    if (
      signUpState.plan_type != 1 &&
      signUpState.plan_type != null &&
      specialist.specialist.priceplan.card_details == null
    ) {
      setShowCard(true);
    }
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpState]);

  return (
    <React.Fragment>
      <SubHeader title="プラン変更" />
      <div
        className={`fixed ${
          toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
        } h-[calc(100vh-190px)] mt-150px top-0 overflow-x-scroll mb-16px`}
      >
        <div className="bg-c3333330d px-40px py-25px flex flex-col max-w-[1100px] m-auto">
          <h2 className="text-24px font-bold">
            現在のプラン：
            {plans[specialist.specialist.priceplan.plan_type - 1]}
          </h2>
          <BillingPlan
            isPayment={true}
            planType={specialist.specialist.priceplan.plan_type}
          />

          {specialist.specialist.priceplan.card_details && (
            <div className="pt-30px">
              <div className="w-full flex p-4 bg-white rounded-lg shadow sm:p-8 py-15px px-15px pt-40">
                <div className="w-250px bg-c2bace5 rounded-lg relative bg-{`${window.location.origin}/img/CP_logo_yoko.svg`}">
                  <div className="">
                    <img
                      alt=""
                      src={`${window.location.origin}/img/empty-credit-card.png`}
                    />
                    <div className="absolute bottom-8px px-10px">
                      <p className="mb-2 align-bottom text-base text-white sm:text-lg">
                        ********
                        {specialist.specialist.priceplan.card_details.last4}
                      </p>
                      <h5 className="align-bottom text-3xl font-bold text-white dark:text-white">
                        {specialist.specialist.priceplan.card_name}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="pl-20px pt-5px">
                  <p className="mb-5 text-28px text-gray-500 sm:text-lg">
                    {specialist.specialist.priceplan.card_details.brand}
                  </p>
                  <p className="mb-5 text-base text-gray-500 sm:text-lg">
                    末尾が....
                    {specialist.specialist.priceplan.card_details.last4}
                    {specialist.specialist.priceplan.card_details.funding ==
                    "credit"
                      ? "のクレジットカード"
                      : "のデビットカード"}
                  </p>
                  <p>
                    <button
                      className="mt-20px text-c2bace5 font-bold"
                      onClick={card_narrow_down}
                    >
                      編集
                    </button>
                  </p>
                </div>
              </div>
            </div>
          )}

          {showCard && <BillingFrom />}

          <CouponFrom />

          <div className="w-[450px] m-auto py-30px">
            <DarkButton 
              disabled={(signUpState.plan_type ==null || signUpState.plan_type == specialist.specialist.priceplan.plan_type)}
              onClick={() => handleClick()}>
              確認する
            </DarkButton>
          </div>

          <div className="w-[450px] m-auto pt-0 pb-30px">
            <Link
              className="hover:opacity-80 block text-center leading-[50px] font-bold w-full h-60px py-2px px-4px rounded border focus:outline-none focus:shadow-outline undefined bg-c427ed1 border-c427ed1 text-white"
              to={`/${pageConstant.COMMERCIAL_TRANSACTION}`}
              target="_blink"
            >
              特定商取引法に基づく表記
            </Link>
          </div>
        </div>
      </div>
      <ConfirmPlanModal />
    </React.Fragment>
  );
};

export default memo(PlanChange);
