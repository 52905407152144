import { AxiosResponse } from "axios";
import React from "react";
import Modal from "react-modal";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { queryConstants } from "../../constants/queryConstants";
import { getStoredSpecialistToken } from "../../sessionStorage/sessionStorage";
import { deleteModalIsOpenStore } from "../../store";
import { ClientsData } from "../../types";

interface Props {
  id: number;
}

export const DeleteAccountingModal: React.FC<Props> = ({ id }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useRecoilState(
    deleteModalIsOpenStore
  );

  const queryClient = useQueryClient();

  const mutation = useMutation((id: number) =>
    axiosInstance({
      url: `${apiUrl.CLIENTS}/${id}`,
      method: "DELETE",
      headers: {
        accessToken: accessToken,
      },
      data: { id: id },
    })
  );
  mutation.error && toast.error("削除できませんでした");

  const accessToken = getStoredSpecialistToken();

  // 削除ボタン
  const handleDelete = async () => {
    try {
      await mutation.mutateAsync(id);

      try {
        const { data }: AxiosResponse<{ clients: ClientsData[] }> =
          await axiosInstance.get(apiUrl.CLIENTS, {
            headers: { accessToken: accessToken },
            data: {
              page: 1,
              per_page: 10,
              keyword: "",
            },
          });

        queryClient.setQueriesData(queryConstants.CLIENTS_LIST, data.clients);
      } catch (error) {
        console.error(error);
        return;
      }

      toast.success("削除しました");
      setDeleteModalIsOpen(false);
    } catch (error) {
      toast.error("削除できませんでした");
      setDeleteModalIsOpen(false);
    }
  };

  // キャンセルボタンの処理
  const handleCloseModal = () => {
    setDeleteModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={deleteModalIsOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 49,
        },
        content: {
          position: "relative",
          width: "50%",
          maxWidth: "30rem",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <button
        className="absolute right-20px top-20px font-bold hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset "
        onClick={handleCloseModal}
        type="button"
      >
        <span className="sr-only">Close menu</span>
        <svg
          aria-hidden="true"
          className="h-20px w-20px"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6l12 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </button>
      <div className="flex justify-center flex-col px-20px text-center">
        削除してもよろしいですか？
      </div>
      <div className="px-16px py-16px flex justify-center gap-50px">
        <button
          className="relative mt-12px px-12px py-6px text-white font-bold bg-red rounded mr-5px hover:opacity-80"
          onClick={() => handleDelete()}
        >
          削除
        </button>
        <button
          className="relative mt-12px px-12px py-6px text-c427ed1 border border-c427ed1 rounded"
          onClick={handleCloseModal}
          type="button"
        >
          戻る
        </button>
      </div>
    </Modal>
  );
};
