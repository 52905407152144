import { DocumentData } from "firebase/firestore";
import { CellData } from "../../types";

type Props = {
  headers: string[];
  field: string;
  state: DocumentData[];
};

export const totalCalcForSalesPlan = ({ headers, state, field }: Props) => {
  const total: number[] = [];

  headers.map((header: string) => {
    const headerKey = header;

    // 合計を計算
    const data = state.reduce(function (sum, data) {
      const cells: CellData = data[field];

      let value = cells[headerKey];
      if (!cells[headerKey]) {
        value = 0;
      }

      return sum + value;
    }, 0);

    total.push(data);
  });

  return total;
};
