import { DocumentData } from "firebase/firestore";
import { fiscalYear, nengou } from "../../utils/date";
import { fiscalPeriod } from "../../utils/date/fiscalPeriod";

export const plansInputMonthlyHeader = (
  year: string | number,
  month: string | number,
  currentTerm: string | number | null,
  headers?: DocumentData
) => {
  const counts = ["", "", "", "", "", "", "", "", "", "", "", ""];
  const headerData: DocumentData[] = [];
  counts.map((_, index) => {
    const fisicalPeriod = fiscalPeriod(year, month, index);
    const newYear = fiscalYear(year, month, index);

    currentTerm === null
      ? headerData.push({
          nengou: nengou(newYear, month),
          period: fisicalPeriod,
          term: headers?.plans_input_monthly[index].term,
        })
      : headerData.push({
          nengou: nengou(newYear, month),
          period: fisicalPeriod,
          term: String(currentTerm),
        });
  });

  return headerData;
};
