import React, { memo, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { ApplyButton } from "../../components/atoms";
import { useSpecialists } from "../../hooks/useSpecialists";
import { getStoredSpecialistToken } from "../../sessionStorage/sessionStorage";
import { signUpSpecialistStore } from "../../store";

const CouponFrom = () => {
  const [state, setState] = useRecoilState(signUpSpecialistStore);
  const [isDisabled, setIsDisabled] = useState(false);
  const accessToken = getStoredSpecialistToken();
  const specialist = useSpecialists();
  const mutation = useMutation(
    ({ coupon_code, uid }: { coupon_code: string; uid: string }) =>
      axiosInstance({
        url: `${apiUrl.SPECIALISTS}/${specialist.specialist.id}/verify/coupon`,
        headers: {
          accessToken: accessToken,
        },
        method: "POST",
        data: { coupon_code, uid },
      })
  );

  const handleClick = async () => {
    try {
      if (state.coupon_code === "" || state.coupon_code === null) {
        toast.error("クーポンを反映しました");
        return;
      }
      const response = await mutation.mutateAsync({
        coupon_code: state.coupon_code,
        uid: specialist?.user?.uid,
      });
      setIsDisabled(true);
      toast.success(response.data.message);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsDisabled(false);
      toast.error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  return (
    <div className="pt-30px">
      <div className="w-full p-4 bg-white rounded-lg shadow sm:p-8 py-15px px-15px pt-40">
        <h2 className="text-24px font-bold">クーポンを追加</h2>
        <div className="w-[500px] flex items-center my-15px">
          <div className="w-300px">
            <input
              className={`shadow appearance-none border border-c33333333 rounded w-300px py-12px leading-tight focus:outline-none focus:shadow-outline ${
                isDisabled ? "disable bg-c33333333" : ""
              }`}
              disabled={isDisabled}
              id="couponCode"
              inputMode="numeric"
              maxLength={19}
              minLength={15}
              onChange={(e) =>
                setState({
                  ...state,
                  coupon_code: String(e.target.value),
                })
              }
              placeholder="クーポン"
              required
              type="text"
              value={state.coupon_code}
            />
          </div>
          <ApplyButton
            disabled={isDisabled ? true : false}
            onClick={() => handleClick()}
          >
            反映
          </ApplyButton>
        </div>
        {specialist?.specialist?.priceplan?.coupon && (
          <p className="text-c427ed1 text-14px">
            <b>{specialist?.specialist?.priceplan?.coupon}</b>{" "}
            クーポンコードが正常に適用されました!
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(CouponFrom);
