import React from "react";
import { useRecoilState } from "recoil";
import {
  createProfitAndLossStatementStore,
  updateProfitAndLossStatementStore,
} from "../../store";

interface Props {
  item: string | null;
}

const TextInput: React.FC<Props> = ({ item }) => {
  const [createAccountTitleState, setCreateAccountTitleState] = useRecoilState(
    createProfitAndLossStatementStore
  );

  const [updateAccountTitleState, setUpdateAccountTitleState] = useRecoilState(
    updateProfitAndLossStatementStore
  );

  return (
    <div className="relative">
      <input
        className="shadow appearance-none border border-c3333334d rounded w-full py-2px px-3px h-40px leading-tight
        focus:border-c1e88e5
        focus:outline-none focus:shadow-outline"
        defaultValue={item ? item : ""}
        onChange={(e) =>
          item !== null
            ? setUpdateAccountTitleState({
                ...updateAccountTitleState,
                item: e.target.value,
              })
            : setCreateAccountTitleState({
                ...createAccountTitleState,
                name: e.target.value,
              })
        }
      />
    </div>
  );
};

export default TextInput;
