import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlPlansInputMonthlyGrandTotal = ({
  titleTotal,
  titleRatio,
  calcState,
  totalField,
  ratioField,
  isCurrentPlanMonthly,
  unit,
  descriptions,
}: {
  titleTotal: string;
  titleRatio: string | null;
  headers: DocumentData[];
  calcState: DocumentData;
  totalField: string;
  ratioField?: string;
  isCurrentPlanMonthly?: boolean;
  unit: number;
  descriptions: DocumentData;
}) => {
  const totalByItem = sum(calcState.monthly_total);
  const plansInputTotal = round(sum(calcState.plans_input_total));
  const reminingNumber = round(sum(calcState.remining_total));
  const yearlyTotal = round(sum(calcState.yearly_total));

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b  before:bg-c80b0f433 whitespace-nowrap font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-c80b0f433 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-x-c3333334d before:border-b before:bg-c80b0f433`;

  const classCol4 = `${borderSettings}  before:border-l before:border-l-c3333334d before:border-b text-right font-bold bg-c80b0f433`;

  const classCol5 = `relative p-4px before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 text-right font-bold before:bg-cfffee2`;

  const classCol6 = `${borderSettings} before:border-x  bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}  ${titleRatio && "before:border-b-c3333334d"}`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];

  calcState.monthly_total &&
    calcState.monthly_total.map((totalData: number) => {
      gridRow.push({
        value: isNaN(totalData)
          ? 0
          : isCurrentPlanMonthly
          ? ShowTableDataTriangle(totalData / unit)
          : ShowTableData(totalData / unit),
        expr: isNaN(totalData) ? 0 : totalData / unit,
        readOnly: true,
        className: `${classCol4} before:border-b-c3333334d`,
      });
    });
  // 年度貼付
  gridRow.push({
    value: isCurrentPlanMonthly
      ? ShowTableDataTriangle(plansInputTotal / unit)
      : ShowTableData(plansInputTotal / unit),
    expr: plansInputTotal / unit,
    readOnly: true,
    className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(totalByItem) ? 0 : ShowTableData(totalByItem / unit),
      expr: isNaN(totalByItem) ? 0 : totalByItem / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(calcState.current_term_total / unit),
      expr: calcState.current_term_total / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(reminingNumber / unit),
      expr: reminingNumber / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(yearlyTotal) ? 0 : ShowTableData(yearlyTotal / unit),
      expr: isNaN(yearlyTotal) ? 0 : yearlyTotal / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  gridRow.push({
    value: descriptions[totalField],
    expr: descriptions[totalField],
    readOnly: false,
    className: `${classCol6} ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          ({titleRatio})
        </div>
      ),
      readOnly: true,
      className: `${classCol1} before:border-b`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];
  calcState.monthly_ratio &&
    calcState.monthly_ratio.map((ratioData: number) => {
      gridRow2.push({
        value: `${showPercentage(ratioData)}%`,
        expr: showPercentage(ratioData),
        readOnly: true,
        className: classCol4,
      });
    });
  // 年度貼付
  gridRow2.push({
    value: `${showPercentage(calcState.plans_input_ratio)}%`,
    expr: showPercentage(calcState.plans_input_ratio),
    readOnly: true,
    className: `${classCol5} before:border-t-c3333334d`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.monthly_total_ratio)}%`,
      expr: showPercentage(calcState.monthly_total_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.current_term_ratio)}%`,
      expr: showPercentage(calcState.current_term_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.remining_ratio)}%`,
      expr: showPercentage(calcState.remining_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: "",
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol6}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
