import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { PlansInputContainer } from "../../components/planMaking/container/PlansInputContainer";
import { pageConstant } from "../../constants/pageConstants";
import { usePlansInputInitialData } from "../../hooks/initialData";

export const PlansInput = () => {
  const plansInputCalcState = usePlansInputInitialData();

  const url = pageConstant.PLANS_INPUT;

  if (!plansInputCalcState) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <SubHeader title="計画（年次）" />
      <UnitChange />
      <PlansInputContainer calcState={plansInputCalcState} url={url} />
    </React.Fragment>
  );
};
