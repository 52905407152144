import { add, cumsum, multiply, sum } from "mathjs";

type Props = {
  total: number[];
  ratio: number[][];
};

export const netSalesCalcByItemForPlansInputMonthly = ({
  total,
  ratio,
}: Props) => {
  const byItem: number[][] = [];
  const monthlyTotalByItem: number[] = [];
  const byItemCumsum: number[][] = [];

  let monthlyTotal: number[] = [];
  let monthlyData: number[] = [0];
  total.map((totalData, totalIndex) => {
    // 合計を売上構成比でかける
    const itemData = multiply(totalData, ratio[totalIndex]) as number[];
    if (totalIndex === 0) {
      monthlyData = itemData;
    } else {
      monthlyData = add(monthlyData, itemData);
    }

    byItem.push(itemData);
    // 累計で使用
    monthlyTotalByItem.push(sum(itemData) as number);
    byItemCumsum.push(cumsum(itemData) as number[]);
    if (total.length === totalIndex + 1) {
      monthlyTotal = monthlyData;
    }
  });

  const monthlyTotalCumsum = cumsum(monthlyTotal) as number[];

  return {
    byItem,
    monthlyTotal,
    monthlyTotalByItem,
    byItemCumsum,
    monthlyTotalCumsum,
  };
};
