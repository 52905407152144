import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../hooks/initialData";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";

export const UnitChange = () => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const dataRef = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      String(resultsAndPlansDataState.headers.NO_ID_FIELD)
    );

    await updateDoc(dataRef, {
      unit: e.target.value,
    }).catch((error) => {
      throw new Error(error.message);
    });
  };

  return (
    <div className="fixed right-0 h-16px top-110px  px-20px py-4px text-16px font-bold bg-white z-40">
      （単位：
      <select
        className="py-4px"
        name="unit"
        onChange={(e) => handleChange(e)}
        value={resultsAndPlansDataState.headers.unit}
      >
        <option value={1}>円</option>
        <option value={1000}>千円</option>
        <option value={1000000}>百万円</option>
      </select>
      、%）
    </div>
  );
};
