import { AxiosResponse } from "axios";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton } from "../../components/atoms";
import { GuestLayout } from "../../components/layout";
import { pageConstant } from "../../constants/pageConstants";

const SignUp = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: apiUrl.USERS,
        method: "POST",
        data: { email, category: 1 },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      window.open(`/${pageConstant.CHECK_EMAIL}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message.slice(-3) === "409") {
        toast.error(`既にメールアドレスが登録されています`);
      } else {
        toast.error("送信できませんでした");
      }
    }
  };

  return (
    <GuestLayout>
      <div className="w-[450px]">
        <h1 className="text-30px font-bold text-center mb-25px">
          ユーザー登録
        </h1>
        <form
          className="bg-white px-8px pt-6px pb-8px"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="mb-4px">
            <label className="block text-13px font-bold mb-8px" htmlFor="email">
              メールアドレス
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sample@cloudplanner.com"
              type="email"
            />
          </div>
          <p className="py-25px text-14px text-center">
            CloudPlannerの利用規約と個人情報の取扱いについて
            <br />
            同意の上、ご登録ください。
          </p>
          <div className="m-auto">
            <DarkButton>上記に同意して登録する</DarkButton>
            <p className="text-14px py-25px text-center">
              すでにアカウントをお持ちの方は
              <Link
                className="inline-block align-baseline font-bold text-14px text-c427ed1 underline hover:opacity-80"
                to={`/${pageConstant.LOGIN}`}
              >
                こちら
              </Link>
            </p>
          </div>
        </form>
      </div>
    </GuestLayout>
  );
};

export default memo(SignUp);
