import { DocumentData } from "firebase/firestore";
import { add, round, subtract, divide } from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import { showPercentage } from "../../utils";
import { totalCalc } from "../../utils/calc";

export const useManagementDashboardInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    cash_equivalent,
    accounts_receivable,
    current_assets,
    shed_unloading_assets,
    quotidian_gold,
    other_current_assets,
    tangible_fixed_assets,
    intangible_fixed_assets,
    investments_and_other_assets,
    deferred_asset,
    payables,
    short_term_borrowings,
    unpaids,
    current_liabilities,
    fixed_liabilities,
    capital,
    capital_surplus,
    retained_earnings,
    other_retained_earnings,
    treasury_stock,
    employees,
    depreciation_cost_data,
    end_inventories_data,
    short_term_borrowings_data,
    fixed_liabilities_data,
    corporate_bond_data,
  } = useSubjectItemsInitialData();

  const field = pageConstant.RESULTS_INPUT;

  try {
    // ゼロの配列
    const zeroArray: number[] = [];
    headers[field].map(() => {
      zeroArray.push(0);
    });

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCost: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: depreciation_cost_data,
    });

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });

    // 売上高合計
    const netSalesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: net_sales,
    });
    // const netSalesRatio: number[] = ratioCalc(netSalesTotal);

    // 期末製品棚卸高
    const endInventoriesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: end_inventories_data,
    });
    // 原価合計
    const costOfSalesTotal: number[] = subtract(
      subtract(
        totalCalc({
          headers: headers[field],
          field: field,
          state: cost_of_sales,
        }),
        endInventoriesTotal
      ),
      endInventoriesTotal
    );

    // 売上総利益
    const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);

    const personalCostTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: personal_cost,
    });

    const sellingAndAdministrativeTotal: number[] = add(
      personalCostTotal,
      totalCalc({
        headers: headers[field],
        field: field,
        state: selling_and_administrative,
      })
    );

    // 営業利益
    const operatingIncome: number[] = subtract(
      grossMargin,
      sellingAndAdministrativeTotal
    );

    // 償却前営業利益
    const operatingProfitsBeforeAmortization: number[] = add(
      operatingIncome,
      depreciationCost
    );

    const nonOperatingIncomeTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_income,
    });
    const nonOperatingExpensesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_expenses,
    });
    // 経常利益
    const currentEarnings: number[] = subtract(
      add(operatingIncome, nonOperatingIncomeTotal),
      nonOperatingExpensesTotal
    );

    // 償却前経常利益
    const ebitda: number[] = add(currentEarnings, depreciationCost);

    const specialBenefitsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_benefits,
    });
    const specialLossesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_losses,
    });
    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebit: number[] = subtract(
      add(currentEarnings, specialBenefitsTotal),
      specialLossesTotal
    );

    // 当期純利益
    const netIncome: number[] = subtract(
      ebit,
      corporateInhabitantAndEnterpriseTaxesT
    );

    // BS
    // 現預金計
    const cashEquivalentTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: cash_equivalent,
    });
    // 売上債権計
    const accountsReceivableTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: accounts_receivable,
    });
    // 当座資産 計
    const currentAssetsTotal: number[] = add(
      cashEquivalentTotal,
      add(
        accountsReceivableTotal,
        totalCalc({
          headers: headers[field],
          field: field,
          state: current_assets,
        })
      )
    );
    // 棚卸資産 計
    const shedUnloadingAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: shed_unloading_assets,
    });
    // 引当金 計
    const quotidianGoldTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: quotidian_gold,
    });
    // 流動資産
    const grossCurrentAssets: number[] = add(
      currentAssetsTotal,
      add(
        shedUnloadingAssetsTotal,
        add(
          quotidianGoldTotal,
          totalCalc({
            headers: headers[field],
            field: field,
            state: other_current_assets,
          })
        )
      )
    );
    // 有形固定資産
    const tangibleFixedAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: tangible_fixed_assets,
    });
    // 無形固定資産
    const intangibleFixedAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: intangible_fixed_assets,
    });
    // 投資その他の資産
    const investmentsAndOtherAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: investments_and_other_assets,
    });
    // 固定資産合計
    const grossFixedAssets: number[] = add(
      tangibleFixedAssetsTotal,
      add(intangibleFixedAssetsTotal, investmentsAndOtherAssetsTotal)
    );
    // 繰越資産
    const deferredAssetTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: deferred_asset,
    });
    // 総資産
    const netWorth: number[] = add(
      grossCurrentAssets,
      add(grossFixedAssets, deferredAssetTotal)
    );
    // 支払債務合計
    const payablesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: payables,
    });

    // 短期借入金合計
    const shortTermBorrowingsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: short_term_borrowings,
    });
    // 未払合計 支払い債務合計+短期借入金+未払合計+その他流動負債合計
    const unpaidsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: unpaids,
    });
    // 流動負債合計
    const currentLiabilitiesTotal: number[] = add(
      payablesTotal,
      add(
        shortTermBorrowingsTotal,
        add(
          unpaidsTotal,
          totalCalc({
            headers: headers[field],
            field: field,
            state: current_liabilities,
          })
        )
      )
    );
    // 固定負債合計
    const fixedLiabilitiesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: fixed_liabilities,
    });
    // 負債合計
    const liabilitiesTotal: number[] = add(
      currentLiabilitiesTotal,
      fixedLiabilitiesTotal
    );
    // 資本金
    const capitalTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: capital,
    });
    // 資本剰余金
    const capitalSurplusTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: capital_surplus,
    });
    // 利益剰余金
    const retainedEarningsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: [...retained_earnings, ...other_retained_earnings],
    });
    // その他の利益剰余金
    // const otherRetainedEarningsTotal: number[] = totalCalc({
    //   headers: headers[field],
    //   field: field,
    //   state: other_retained_earnings,
    // });
    // 自己株式
    const treasuryStockTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: treasury_stock,
    });
    // 株主資本合計 資本金合計+資本剰余金合計+利益剰余金合計+自己株式
    const shareholdersEquityTotal: number[] = add(
      capitalTotal,
      add(capitalSurplusTotal, add(retainedEarningsTotal, treasuryStockTotal))
    );

    // 負債・純資産合計
    const liabilitiesAndNetWorthTotal: number[] = add(
      shareholdersEquityTotal,
      liabilitiesTotal
    );

    // 短期借入金
    const shortTermBorrowingsSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: short_term_borrowings_data,
    });

    // 長期借入金
    const fixedLiabilitiesSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: fixed_liabilities_data,
    });

    // 社債
    const corporateBondSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: corporate_bond_data,
    });

    // 長期短期借入金･社債計（役員借入不含）
    const longTermBorrowingsTotal: number[] = add(
      shortTermBorrowingsSingle,
      add(fixedLiabilitiesSingle, corporateBondSingle)
    );

    // 社員数合計
    const employeesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: employees,
    });

    const unit = headers.unit;

    // 売上高と借入金の推移
    const netSalesAndDebtData = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          借入金: round(longTermBorrowingsTotal[index] / unit),
          parcentage: showPercentage(
            divide(
              longTermBorrowingsTotal[index],
              netSalesTotal[index]
            ) as number
          ),
        };
      }
    );

    // 売上高と借入金、有形固定資産の推移
    const netSalesDebtPropertyPlantAndEquipment = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          営業利益: round(operatingIncome[index] / unit),
          借入金: round(longTermBorrowingsTotal[index] / unit),
          有形固定資産: round(tangibleFixedAssetsTotal[index] / unit),
          parcentage: showPercentage(
            divide(
              longTermBorrowingsTotal[index],
              netSalesTotal[index]
            ) as number
          ),
        };
      }
    );

    // 売上高と営業利益の推移
    const netSalesAndOperatingIncome = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          営業利益: round(operatingIncome[index] / unit),
          営業利益率: showPercentage(
            divide(operatingIncome[index], netSalesTotal[index]) as number
          ),
        };
      }
    );

    // 財務基盤の健全性
    const soundnessOfFinancialBase = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          総資産: round(netWorth[index] / unit),
          純資産: round(shareholdersEquityTotal[index] / unit),
          自己資本比率: showPercentage(
            divide(shareholdersEquityTotal[index], netWorth[index]) as number
          ),
        };
      }
    );

    // 売上高と経常利益の推移
    const netSalesAndOrdinaryIncome = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          経常利益: round(currentEarnings[index] / unit),
          経常利益率: showPercentage(
            divide(currentEarnings[index], netSalesTotal[index]) as number
          ),
        };
      }
    );

    // 売上高と償却前営業利益・経常利益の推移
    const netSalesOperatingIncomeEtc = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          償却前営業利益: round(
            operatingProfitsBeforeAmortization[index] / unit
          ),
          償却前経常利益: round(ebitda[index] / unit),
          amortizationParcentage: showPercentage(
            divide(
              operatingProfitsBeforeAmortization[index],
              netSalesTotal[index]
            ) as number
          ),
          ebitdaParcentage: showPercentage(
            divide(ebitda[index], netSalesTotal[index]) as number
          ),
        };
      }
    );

    // 事業別売上推移
    const salesByBusinessSegment = headers[field].map(
      (header: DocumentData, index: number) => {
        const period = header.period;
        let base = {
          name: `${header.nengou}年${header.term}期`,
          売上高合計: round(netSalesTotal[index] / unit),
        };

        net_sales.map((data) => {
          const item = data.item as string;

          base = {
            ...base,
            [item]: data[field][period] ? data[field][period] / unit : 0,
          };
        });
        return base;
      }
    );

    // 安全性指標
    // 流動比率
    const currentRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          流動資産: round(grossCurrentAssets[index] / unit),
          流動負債: round(currentLiabilitiesTotal[index] / unit),
          流動比率: showPercentage(
            divide(
              grossCurrentAssets[index],
              currentLiabilitiesTotal[index]
            ) as number
          ),
        };
      }
    );

    // 当座比率
    const pawnRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          当座資産: round(currentAssetsTotal[index] / unit),
          流動負債: round(currentLiabilitiesTotal[index] / unit),
          当座比率: showPercentage(
            divide(
              currentAssetsTotal[index],
              currentLiabilitiesTotal[index]
            ) as number
          ),
        };
      }
    );

    // 固定比率
    const fixedRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          固定資産: round(grossFixedAssets[index] / unit),
          自己資本: round(shareholdersEquityTotal[index] / unit),
          固定比率: showPercentage(
            divide(
              grossFixedAssets[index],
              shareholdersEquityTotal[index]
            ) as number
          ),
        };
      }
    );

    // 有利子負債構成比率
    const interestBearingDebtRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          有利子負債: round(longTermBorrowingsTotal[index] / unit),
          総資本: round(liabilitiesAndNetWorthTotal[index] / unit),
          有利子負債比率: showPercentage(
            divide(
              longTermBorrowingsTotal[index],
              liabilitiesAndNetWorthTotal[index]
            ) as number
          ),
        };
      }
    );

    // 資産・資本効率
    // 売上債権回転期間
    const receivablesTurnover = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上債権: round(accountsReceivableTotal[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          売上債権回転期間:
            (divide(
              accountsReceivableTotal[index],
              netSalesTotal[index]
            ) as number) / 12,
        };
      }
    );

    // 棚卸資産回転期間
    const inventoryTurnover = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          棚卸資産: round(shedUnloadingAssetsTotal[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          棚卸資産回転期間:
            (divide(
              shedUnloadingAssetsTotal[index],
              netSalesTotal[index]
            ) as number) / 12,
        };
      }
    );

    // 固定資産回転率
    const fixedAssetsTurnover = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          固定資産: round(grossFixedAssets[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          固定資産回転率: showPercentage(
            (divide(grossFixedAssets[index], netSalesTotal[index]) as number) /
              12
          ),
        };
      }
    );

    // 総資本回転率
    const grossCapitalTurnover = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          総資産: round(netWorth[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          総資本回転率: showPercentage(
            (divide(netWorth[index], netSalesTotal[index]) as number) / 12
          ),
        };
      }
    );

    // 仕入債務回転期間
    const payablesTurnover = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          仕入債務: round(netWorth[index] / unit),
          売上原価: round(netSalesTotal[index] / unit),
          仕入債務回転期間:
            (divide(netWorth[index], netSalesTotal[index]) as number) / 12,
        };
      }
    );

    // 収益性比率
    // 売上高総利益率
    const grossMarginRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高総利益: round(grossMargin[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          売上高総利益率: showPercentage(
            divide(grossMargin[index], netSalesTotal[index]) as number
          ),
        };
      }
    );

    // 純利益率
    const netProfitRatio = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          純利益: round(netIncome[index] / unit),
          売上高: round(netSalesTotal[index] / unit),
          純利益率: showPercentage(
            divide(netIncome[index], netSalesTotal[index]) as number
          ),
        };
      }
    );

    // 自己資本利益率（ROE）
    const roe = headers[field].map((header: DocumentData, index: number) => {
      return {
        name: `${header.nengou}年${header.term}期`,
        純利益: round(netIncome[index] / unit),
        自己資本: round(shareholdersEquityTotal[index] / unit),
        ROE: showPercentage(
          divide(netIncome[index], shareholdersEquityTotal[index]) as number
        ),
      };
    });

    // 総資本事業利益率（ROA）
    const roa = headers[field].map((header: DocumentData, index: number) => {
      return {
        name: `${header.nengou}年${header.term}期`,
        営業利益: round(
          (add(
            operatingIncome[index],
            nonOperatingIncomeTotal[index]
          ) as number) / unit
        ),
        総資本: round(liabilitiesAndNetWorthTotal[index] / unit),
        ROA: showPercentage(
          divide(
            add(
              operatingIncome[index],
              nonOperatingIncomeTotal[index]
            ) as number,
            liabilitiesAndNetWorthTotal[index]
          ) as number
        ),
      };
    });

    // 成長性比率
    // 売上高増加率
    const percentageIncreaseInNetSales = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          売上高増加率:
            index === 0
              ? 0
              : showPercentage(
                  divide(
                    subtract(netSalesTotal[index], netSalesTotal[index - 1]),
                    netSalesTotal[index - 1]
                  ) as number
                ),
        };
      }
    );

    // 経常利益増加率
    const percentageIncreaseInCurrentEarnings = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          経常利益: round(currentEarnings[index] / unit),
          経常利益増加率:
            index === 0
              ? 0
              : showPercentage(
                  divide(
                    subtract(
                      currentEarnings[index],
                      currentEarnings[index - 1]
                    ),
                    currentEarnings[index - 1]
                  ) as number
                ),
        };
      }
    );

    // 1人1か月売上高
    const salesPerPersonPerMonth = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          従業員数: employeesTotal[index],
          parcentage: divide(
            netSalesTotal[index] / unit,
            12 * employeesTotal[index]
          ) as number,
        };
      }
    );

    // 1人1か月販管費
    const totalCostPerPersonPerMonth = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上原価販売管理費: round(
            sellingAndAdministrativeTotal[index] / unit
          ),
          従業員数: employeesTotal[index],
          parcentage: divide(
            sellingAndAdministrativeTotal[index] / unit,
            12 * employeesTotal[index]
          ) as number,
        };
      }
    );

    // 1人当り売上高（月）
    const netSalesPerEmployeePerMonth = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          月平均売上高: round(netSalesTotal[index] / 12 / unit),
          従業員数: employeesTotal[index],
          parcentage: divide(
            netSalesTotal[index] / 12 / unit,
            employeesTotal[index]
          ) as number,
        };
      }
    );

    // 1人当り売上高（年）
    const netSalesPerEmployeePerYear = headers[field].map(
      (header: DocumentData, index: number) => {
        return {
          name: `${header.nengou}年${header.term}期`,
          売上高: round(netSalesTotal[index] / unit),
          従業員数: employeesTotal[index],
          parcentage: divide(
            netSalesTotal[index] / unit,
            employeesTotal[index]
          ) as number,
        };
      }
    );

    const managementDashboardCalcState = {
      net_sales_and_debt_data: netSalesAndDebtData,
      net_sales_debt_property_plant_and_equipment:
        netSalesDebtPropertyPlantAndEquipment,
      net_sales_and_operating_income: netSalesAndOperatingIncome,
      soundness_of_financial_base: soundnessOfFinancialBase,
      net_sales_and_ordinary_income: netSalesAndOrdinaryIncome,
      net_sales_operating_income_etc: netSalesOperatingIncomeEtc,
      sales_by_business_segment: salesByBusinessSegment,
      current_ratio: currentRatio,
      pawn_ratio: pawnRatio,
      fixed_ratio: fixedRatio,
      interest_bearing_debt_ratio: interestBearingDebtRatio,
      receivables_turnover: receivablesTurnover,
      inventory_turnover: inventoryTurnover,
      fixed_assets_turnover: fixedAssetsTurnover,
      gross_capital_turnover: grossCapitalTurnover,
      payables_turnover: payablesTurnover,
      gross_margin_ratio: grossMarginRatio,
      net_profit_ratio: netProfitRatio,
      roe: roe,
      roa: roa,
      percentage_increase_in_net_sales: percentageIncreaseInNetSales,
      percentage_increase_in_current_earnings:
        percentageIncreaseInCurrentEarnings,
      sales_per_person_per_month: salesPerPersonPerMonth,
      total_cost_per_person_per_month: totalCostPerPersonPerMonth,
      net_sales_per_employee_per_month: netSalesPerEmployeePerMonth,
      net_sales_per_employee_per_year: netSalesPerEmployeePerYear,
    };

    return managementDashboardCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
