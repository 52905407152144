import { DocumentData } from "firebase/firestore";
import React from "react";

interface Props {
  headers: DocumentData[];
}

export const DepositsAndWithdrawHeader: React.FC<Props> = ({ headers }) => {
  return (
    <thead>
      <tr>
        <th className={`min-w-[7.5rem] w-120px`}></th>
        <th className={`min-w-[7.5rem] w-120px`}></th>
        <th className={`min-w-[8.75rem] w-140px`}></th>
        <th className={`sticky left-425px min-w-[2.8175rem] w-45px`}></th>
        <th className={`min-w-[10rem] w-160px`}></th>
        <th className={`min-w-[7.5rem] w-120px`}></th>
        <th className={`min-w-[7.5rem] w-120px`}></th>
        {headers?.map((header: DocumentData, index: number) => (
          <th className={`min-w-[7.5rem] w-120px`} key={index}></th>
        ))}
        <th className={`min-w-[7.5rem] w-120px`}></th>
        <th className={`min-w-[37.5rem] w-600px`}></th>
      </tr>
    </thead>
  );
};
