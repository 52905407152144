import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlEstimatedInThisTermTotal = ({
  titleTotal,
  titleRatio,
  headers,
  bottomLine,
  calcState,
  totalField,
  ratioField,
  unit,
  descriptions,
}: {
  titleTotal: string;
  titleRatio: string | null;
  headers: DocumentData[];
  bottomLine: boolean;
  calcState: DocumentData;
  totalField: string;
  ratioField?: string;
  unit: number;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pt-20px pl-4px bg-white before:border-l `;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-l-c3333334d 
         before:bg-c7fb28d33 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b  before:bg-c7fb28d33`;

  const classCol4 = `${borderSettings}  text-right font-bold bg-c7fb28d33`;

  const classCol5 = `relative p-4px before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10   text-right font-bold before:bg-cfffee2`;

  const classCol6 = `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${
        bottomLine && titleRatio === null
          ? "before:border-b"
          : titleRatio
          ? "before:border-b-c3333334d"
          : ""
      }`,
      colSpan: 2,
    },
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];

  headers &&
    headers.map((_, index: number) => {
      gridRow.push({
        value: ShowTableDataTriangle(calcState.monthly_total[index] / unit),
        expr: calcState.monthly_total[index] / unit,
        readOnly: true,
        className: `${classCol4} before:border-b ${
          titleRatio && "before:border-b-c3333334d"
        }`,
      });
    });
  // 見込み
  gridRow.push({
    value: ShowTableDataTriangle(calcState.yearly_total / unit),
    expr: calcState.yearly_total / unit,
    readOnly: true,
    className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
  });

  gridRow.push({
    value: descriptions[totalField],
    expr: descriptions[totalField],
    readOnly: false,
    className: `${classCol6}  ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${bottomLine && "before:border-b"}`,
      colSpan: 2,
    },
    {
      value: titleRatio,
      readOnly: true,
      className: `${classCol2} before:border-b text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];

  headers &&
    headers.map((_, tdIndex: number) => {
      gridRow2.push({
        value: `${showPercentage(calcState.monthly_ratio[tdIndex])}%`,
        expr: showPercentage(calcState.monthly_ratio[tdIndex]),
        readOnly: true,
        className: `${classCol4} before:border-t-c3333334d`,
      });
    });
  // 見込み
  gridRow2.push({
    value: `${showPercentage(calcState.yearly_ratio)}%`,
    expr: showPercentage(calcState.yearly_ratio),
    readOnly: true,
    className: `${classCol5} before:border-t-c3333334d`,
  });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol6}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
