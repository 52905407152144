import { DocumentData } from "firebase/firestore";
import { add, dotDivide, map, round, subtract, isNaN } from "mathjs";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import {
  arrayRatioCalc,
  calcByItemForPlansResultMonthly,
  ratioCalc,
  totalCalc,
  totalCalcByItem,
} from "../../utils/calc";

export const useResultsInputInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    cash_equivalent,
    accounts_receivable,
    current_assets,
    shed_unloading_assets,
    quotidian_gold,
    other_current_assets,
    tangible_fixed_assets,
    intangible_fixed_assets,
    investments_and_other_assets,
    deferred_asset,
    payables,
    short_term_borrowings,
    unpaids,
    current_liabilities,
    fixed_liabilities,
    capital,
    capital_surplus,
    retained_earnings,
    other_retained_earnings,
    treasury_stock,
    employees,
    depreciation_cost_data,
    // depreciation_cost_single_data,
    raw_material_inventories_at_the_end_of_the_period,
    end_inventories_data,
    depreciation_cost_of_cost_of_sales,
    short_term_borrowings_data,
    fixed_liabilities_data,
    corporate_bond_data,
    material_fee_fluctuate_data,
    labor_costs_fluctuate_data,
    external_injection_processing_fee_fluctuate_data,
    expenses_fluctuate_data,
    beginning_inventories_data,
    cost_of_sales_except_beginning_and_end_data,
    personal_cost_fluctuate_data,
    selling_and_administrative_fluctuate_data,
    labor_costs_fixed_data,
    expenses_fixed_data,
    personal_cost_fixed_data,
    selling_and_administrative_fixed_data,
    interest_expenses,
    non_cash_out,
  } = useSubjectItemsInitialData();

  const field = pageConstant.RESULTS_INPUT;

  const totalCalcMemo = useCallback(
    (state: DocumentData[]) =>
      totalCalc({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers, field]
  );

  try {
    // 項目別売上高合計
    const netSalesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: net_sales,
    });

    // 項目別売上原価合計
    const costOfSalesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: cost_of_sales,
    });

    // 項目別人件費合計
    const sellingAndAdministrativeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: selling_and_administrative,
    });

    // 項目別販売費及び一般管理費合計
    const personalCostTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: personal_cost,
    });

    // 項目別営業外収益合計
    const nonOperatingIncomeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: non_operating_income,
    });

    // 項目別営業外費用合計
    const nonOperatingExpensesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: non_operating_expenses,
    });

    // 項目別特別利益合計
    const specialBenefitsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_benefits,
    });

    // 項目別特別損失合計
    const specialLossesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_losses,
    });

    // 項目別法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });

    // 項目別材料費合計
    const materialFeeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: material_fee,
    });

    // 項目別労務費合計
    const laborCostsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: labor_costs,
    });

    // 項目別外注加工費合計
    const externalInjectionProcessingFeeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: external_injection_processing_fee,
    });

    // 項目別経費合計
    const expensesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: expenses,
    });

    // 項目別期首仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem =
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });

    // 項目別期末仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem =
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });

    // 項目別現預金合計
    const cashEquivalentTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: cash_equivalent,
    });

    // 項目別売上債権合計
    const accountsReceivableTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: accounts_receivable,
    });

    // 項目別当座資産合計
    const currentAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: current_assets,
    });

    // 項目別棚卸資産合計
    const shedUnloadingAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: shed_unloading_assets,
    });

    // 項目別引当金合計
    const quotidianGoldTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: quotidian_gold,
    });

    // 項目別その他流動資産合計
    const otherCurrentAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: other_current_assets,
    });

    // 項目別有形固定資産合計
    const tangibleFixedAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: tangible_fixed_assets,
    });

    // 項目別無形固定資産合計
    const intangibleFixedAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: intangible_fixed_assets,
    });

    // 項目別投資その他の資産合計
    const investmentsAndOtherAssetsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: investments_and_other_assets,
    });

    // 項目別繰延資産合計
    const deferredAssetTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: deferred_asset,
    });

    // 項目別支払債務合計
    const payablesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: payables,
    });

    // 項目別短期借入金合計
    const shortTermBorrowingsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: short_term_borrowings,
    });

    // 項目別未払合計
    const unpaidsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: unpaids,
    });

    // 項目別流動負債合計
    const currentLiabilitiesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: current_liabilities,
    });

    // 項目別固定負債合計
    const fixedLiabilitiesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: fixed_liabilities,
    });

    // 項目別資本金合計
    const capitalTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: capital,
    });

    // 項目別利益剰余金合計
    const retainedEarningsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: retained_earnings,
    });

    // 項目別その他の利益剰余金合計
    const otherRetainedEarningsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: other_retained_earnings,
    });

    // 項目別資本剰余金合計
    const capitalSurplusTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: capital_surplus,
    });

    // 項目別自己株式合計
    const treasuryStockTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: treasury_stock,
    });

    // 項目別社員数合計
    const employeesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: employees,
    });

    // ゼロの配列
    const zeroArray: number[] = [];
    headers[field].map(() => {
      zeroArray.push(0);
    });

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCost: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: depreciation_cost_data,
    });
    // 販売費及び一般管理費合計
    // const depreciationSingleCost: number[] = totalCalc({
    //   headers: headers[field],
    //   field: field,
    //   state: depreciation_cost_single_data,
    // });
    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });
    // 期末材料棚卸高
    const rawMaterialInventoriesAtTheEndOfThePeriodT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: raw_material_inventories_at_the_end_of_the_period,
    });

    // 期首仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodT: number[] =
      totalCalc({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });
    // 期末仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheEndOfThePeriodT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: inventory_of_work_in_process_at_the_end_of_the_period,
    });

    // 売上高合計
    const netSalesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: net_sales,
    });
    const netSalesRatio: number[] = ratioCalc(netSalesTotal);

    // 期末製品棚卸高
    const endInventoriesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: end_inventories_data,
    });
    // 原価合計
    const costOfSalesTotal: number[] = subtract(
      subtract(
        totalCalc({
          headers: headers[field],
          field: field,
          state: cost_of_sales,
        }),
        endInventoriesTotal
      ),
      endInventoriesTotal
    );
    const costOfSalesRatio: number[] = arrayRatioCalc({
      numerator: costOfSalesTotal,
      denominator: netSalesTotal,
    });
    // 売上総利益
    const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);
    const grossMarginRatio: number[] = arrayRatioCalc({
      numerator: grossMargin,
      denominator: netSalesTotal,
    });
    const personalCostTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: personal_cost,
    });
    const personalCostRatio: number[] = arrayRatioCalc({
      numerator: personalCostTotal,
      denominator: netSalesTotal,
    });
    const sellingAndAdministrativeTotal: number[] = add(
      personalCostTotal,
      totalCalc({
        headers: headers[field],
        field: field,
        state: selling_and_administrative,
      })
    );
    const sellingAndAdministrativeRatio: number[] = arrayRatioCalc({
      numerator: sellingAndAdministrativeTotal,
      denominator: netSalesTotal,
    });
    // 営業利益
    const operatingIncome: number[] = subtract(
      grossMargin,
      sellingAndAdministrativeTotal
    );
    const operatingIncomeRatio: number[] = arrayRatioCalc({
      numerator: operatingIncome,
      denominator: netSalesTotal,
    });
    // 償却前営業利益
    const operatingProfitsBeforeAmortization: number[] = add(
      operatingIncome,
      depreciationCost
    );
    const operatingProfitsBeforeAmortizationRatio: number[] = arrayRatioCalc({
      numerator: operatingProfitsBeforeAmortization,
      denominator: netSalesTotal,
    });
    const nonOperatingIncomeTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_income,
    });
    const nonOperatingExpensesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_expenses,
    });
    // 経常利益
    const currentEarnings: number[] = subtract(
      add(operatingIncome, nonOperatingIncomeTotal),
      nonOperatingExpensesTotal
    );
    const currentEarningsRatio: number[] = arrayRatioCalc({
      numerator: currentEarnings,
      denominator: netSalesTotal,
    });
    // 償却前経常利益
    const ebitda: number[] = add(currentEarnings, depreciationCost);
    const ebitdaRatio = arrayRatioCalc({
      numerator: ebitda,
      denominator: netSalesTotal,
    });
    const specialBenefitsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_benefits,
    });
    const specialLossesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_losses,
    });
    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebit: number[] = subtract(
      add(currentEarnings, specialBenefitsTotal),
      specialLossesTotal
    );
    const ebitRatio: number[] = arrayRatioCalc({
      numerator: ebit,
      denominator: netSalesTotal,
    });
    // 当期純利益
    const netIncome: number[] = subtract(
      ebit,
      corporateInhabitantAndEnterpriseTaxesT
    );
    const netIncomeRatio: number[] = arrayRatioCalc({
      numerator: netIncome,
      denominator: netSalesTotal,
    });

    // 原価
    // 材料費合計 期首材料棚卸高+その他材料費-期末材料棚卸高
    const materialFeeTotal: number[] = subtract(
      totalCalc({
        headers: headers[field],
        field: field,
        state: material_fee,
      }),
      add(
        rawMaterialInventoriesAtTheEndOfThePeriodT,
        rawMaterialInventoriesAtTheEndOfThePeriodT
      )
    );
    // 労務費合計
    const laborCostsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: labor_costs,
    });
    // 外注加工費合計
    const externalInjectionProcessingFeeTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: external_injection_processing_fee,
    });
    // 原価の経費にある減価償却費
    const depreciationCostOfSalesT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: depreciation_cost_of_cost_of_sales,
    });
    // 経費合計
    const expensesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: expenses,
    });
    // 当期製造費用
    const grossManufacturingCostForThePeriod: number[] = add(
      materialFeeTotal,
      add(
        laborCostsTotal,
        add(externalInjectionProcessingFeeTotal, expensesTotal)
      )
    );
    // 当期製品製造原価
    const CostOfProductsManufactured: number[] = subtract(
      add(
        grossManufacturingCostForThePeriod,

        inventoryOfWorkInProcessAtTheBeginningOfThePeriodT
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodT
    );

    // BS
    // 現預金計
    const cashEquivalentTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: cash_equivalent,
    });
    // 売上債権計
    const accountsReceivableTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: accounts_receivable,
    });
    // 当座資産 計
    const currentAssetsTotal: number[] = add(
      cashEquivalentTotal,
      add(
        accountsReceivableTotal,
        totalCalc({
          headers: headers[field],
          field: field,
          state: current_assets,
        })
      )
    );
    // 棚卸資産 計
    const shedUnloadingAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: shed_unloading_assets,
    });
    // 引当金 計
    const quotidianGoldTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: quotidian_gold,
    });
    // 流動資産
    const grossCurrentAssets: number[] = add(
      currentAssetsTotal,
      add(
        shedUnloadingAssetsTotal,
        add(
          quotidianGoldTotal,
          totalCalc({
            headers: headers[field],
            field: field,
            state: other_current_assets,
          })
        )
      )
    );
    // 有形固定資産
    const tangibleFixedAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: tangible_fixed_assets,
    });
    // 無形固定資産
    const intangibleFixedAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: intangible_fixed_assets,
    });
    // 投資その他の資産
    const investmentsAndOtherAssetsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: investments_and_other_assets,
    });
    // 固定資産合計
    const grossFixedAssets: number[] = add(
      tangibleFixedAssetsTotal,
      add(intangibleFixedAssetsTotal, investmentsAndOtherAssetsTotal)
    );
    // 繰越資産
    const deferredAssetTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: deferred_asset,
    });
    // 総資産
    const netWorth: number[] = add(
      grossCurrentAssets,
      add(grossFixedAssets, deferredAssetTotal)
    );
    // 支払債務合計
    const payablesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: payables,
    });

    // 短期借入金合計
    const shortTermBorrowingsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: short_term_borrowings,
    });
    // 未払合計 支払い債務合計+短期借入金+未払合計+その他流動負債合計
    const unpaidsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: unpaids,
    });

    // 流動負債合計
    const currentLiabilitiesTotal: number[] = add(
      payablesTotal,
      add(
        shortTermBorrowingsTotal,
        add(
          unpaidsTotal,
          totalCalc({
            headers: headers[field],
            field: field,
            state: current_liabilities,
          })
        )
      )
    );
    // 固定負債合計
    const fixedLiabilitiesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: fixed_liabilities,
    });
    // 負債合計
    const liabilitiesTotal: number[] = add(
      currentLiabilitiesTotal,
      fixedLiabilitiesTotal
    );
    // 資本金
    const capitalTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: capital,
    });
    // 資本剰余金
    const capitalSurplusTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: capital_surplus,
    });

    // 利益剰余金
    const retainedEarningsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: [...retained_earnings, ...other_retained_earnings],
    });
    // その他の利益剰余金
    const otherRetainedEarningsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: other_retained_earnings,
    });

    // 自己株式
    const treasuryStockTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: treasury_stock,
    });
    // 株主資本合計 資本金合計+資本剰余金合計+利益剰余金合計+自己株式
    const shareholdersEquityTotal: number[] = add(
      capitalTotal,
      add(capitalSurplusTotal, add(retainedEarningsTotal, treasuryStockTotal))
    );

    // 負債・純資産合計
    const liabilitiesAndNetWorthTotal: number[] = add(
      shareholdersEquityTotal,
      liabilitiesTotal
    );

    // 短期借入金

    const shortTermBorrowingsSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: short_term_borrowings_data,
    });
    // 長期借入金
    const fixedLiabilitiesSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: fixed_liabilities_data,
    });
    // 社債
    const corporateBondSingle: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: corporate_bond_data,
    });
    // 長期短期借入金･社債計（役員借入不含）
    const longTermBorrowingsTotal: number[] = add(
      shortTermBorrowingsSingle,
      add(fixedLiabilitiesSingle, corporateBondSingle)
    );
    // 社員数合計
    const employeesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: employees,
    });
    // 材料費（変動のみ）
    const materialFeeFluctuateDataTotal: number[] = subtract(
      totalCalc({
        headers: headers[field],
        field: field,
        state: material_fee_fluctuate_data,
      }),
      add(
        rawMaterialInventoriesAtTheEndOfThePeriodT,
        rawMaterialInventoriesAtTheEndOfThePeriodT
      )
    );
    // 労務費（変動のみ）
    const laborCostsFluctuateTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: labor_costs_fluctuate_data,
    });
    // 外注費（変動のみ）
    const externalInjectionProcessingFeeFluctuateTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: external_injection_processing_fee_fluctuate_data,
    });
    // 経費（変動のみ）
    const expensesFluctuateTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: expenses_fluctuate_data,
    });
    // 期首製品棚卸高
    const beginningInventoriesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: beginning_inventories_data,
    });

    // 仕入合計
    const stockingTotal: number[] = add(
      materialFeeFluctuateDataTotal,
      add(
        laborCostsFluctuateTotal,
        add(
          externalInjectionProcessingFeeFluctuateTotal,
          add(
            expensesFluctuateTotal,
            add(
              inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
              inventoryOfWorkInProcessAtTheEndOfThePeriodT
            )
          )
        )
      )
    );
    // 売上原価合計（期首期末以外）
    const costOfSalesExceptBeginningAndEndTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: cost_of_sales_except_beginning_and_end_data,
    });

    // 変動販管費（人件費）
    const personalCostFluctuateTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: personal_cost_fluctuate_data,
    });
    // 変動販管費（その他）
    const sellingAndAdministrativeFluctuateTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: selling_and_administrative_fluctuate_data,
    });
    // 変動販管費合計
    const sgaTotal: number[] = add(
      personalCostFluctuateTotal,
      sellingAndAdministrativeFluctuateTotal
    );

    // 労務費（固定のみ）
    const laborCostsFixedDataTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: labor_costs_fixed_data,
    });
    // 経費（固定のみ）
    const expensesFixedDataTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: expenses_fixed_data,
    });
    // 固定原価合計
    const fixedCostTotal: number[] = add(
      laborCostsFixedDataTotal,
      expensesFixedDataTotal
    );
    // 固定販管費（人件費）
    const personalCostFixedTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: personal_cost_fixed_data,
    });
    // 固定販管費（その他）
    const sellingAndAdministrativeFixedTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: selling_and_administrative_fixed_data,
    });
    // 固定販管費合計
    const sgaFixedTotal: number[] = add(
      personalCostFixedTotal,
      sellingAndAdministrativeFixedTotal
    );
    // 固定費計
    const fixedCostGrandTotal: number[] = add(fixedCostTotal, sgaFixedTotal);

    // 変動原価合計 変動売上原価合計+期首仕掛品棚卸高合計-期末仕掛品棚卸高合計-期末製品棚卸高
    const variableCostsTotal: number[] = subtract(
      add(
        materialFeeFluctuateDataTotal,
        add(
          personalCostFixedTotal,
          add(
            externalInjectionProcessingFeeFluctuateTotal,
            add(
              expensesFluctuateTotal,
              add(
                inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
                beginningInventoriesTotal
              )
            )
          )
        )
      ),
      add(inventoryOfWorkInProcessAtTheEndOfThePeriodT, endInventoriesTotal)
    );

    // 変動費計
    const variableCostsGrandTotal: number[] = add(variableCostsTotal, sgaTotal);

    // 変動費率
    const variableCostRatioT: number[] = arrayRatioCalc({
      numerator: variableCostsGrandTotal,
      denominator: netSalesTotal,
    });
    // 損益分岐点売上高
    const variableCostRatioNumber = dotDivide(
      variableCostsGrandTotal,
      netSalesTotal
    );

    // 限界利益
    const marginalProfit: number[] = subtract(
      netSalesTotal,
      variableCostsGrandTotal
    );

    // 営業利益
    const operatingIncomeT: number[] = subtract(
      marginalProfit,
      fixedCostGrandTotal
    );

    const newVariableCostRatioNumber = map(
      variableCostRatioNumber as number[],
      function (value) {
        if (isNaN(value) === true) {
          value = 0;
        }
        return value;
      }
    );
    const beforeBepNetSalesTotal = dotDivide(
      fixedCostGrandTotal,
      subtract(1, newVariableCostRatioNumber) as number[]
    );
    const bepNetSalesTotal = map(
      beforeBepNetSalesTotal as number[],
      function (value) {
        if (isNaN(value) === true) {
          value = 0;
        }
        return round(value);
      }
    );

    // 損益分岐点比率
    const bepNetSalesRatio: number[] = arrayRatioCalc({
      numerator: bepNetSalesTotal,
      denominator: netSalesTotal,
    });
    // 当期製造費用比率
    const grossManufacturingCostForThePeriodRatio: number[] = arrayRatioCalc({
      numerator: grossManufacturingCostForThePeriod,
      denominator: grossManufacturingCostForThePeriod,
    });
    // 材料費当期製造費用比率
    const materialFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: materialFeeTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    // 労務費当期製造費用比率
    const laborCostsTotalRatio: number[] = arrayRatioCalc({
      numerator: laborCostsTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    // 外注加工費当期製造費用比率
    const externalInjectionProcessingFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: externalInjectionProcessingFeeTotal,
      denominator: grossManufacturingCostForThePeriod,
    });

    // 経費当期製造費用比率
    const expensesTotalRatio: number[] = arrayRatioCalc({
      numerator: expensesTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    // 売上高材料費比率
    const netSalesMaterialFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: materialFeeTotal,
      denominator: netSalesTotal,
    });
    // 売上高労務費比率
    const netSalesLaborCostsTotalRatio: number[] = arrayRatioCalc({
      numerator: laborCostsTotal,
      denominator: netSalesTotal,
    });
    // 売上高外注費比率
    const netSalesExternalInjectionProcessingFeeTotalRatio: number[] =
      arrayRatioCalc({
        numerator: externalInjectionProcessingFeeTotal,
        denominator: netSalesTotal,
      });
    // 売上高経費比率
    const netSalesExpensesTotalRatio: number[] = arrayRatioCalc({
      numerator: expensesTotal,
      denominator: netSalesTotal,
    });
    // 売上債権回転日数 売上債権/(売上高/長期短期借入金･社債計（役員借入不含 )
    const turnoverDenominator = dotDivide(netSalesTotal, 365);

    const receivablesTurnover = arrayRatioCalc({
      numerator: accountsReceivableTotal,
      denominator: turnoverDenominator as number[],
    });

    // 棚卸資産回転日数 棚卸資産/(売上高/長期短期借入金･社債計（役員借入不含 )
    const inventoryTurnover = arrayRatioCalc({
      numerator: shedUnloadingAssetsTotal,
      denominator: turnoverDenominator as number[],
    });

    // 売上構成比
    const netSalesTotalByItemRatio = calcByItemForPlansResultMonthly({
      headers: headers[field],
      field: field,
      state: net_sales,
      netSalesTotalByItem: netSalesTotalByItem,
    });

    // 支払利息
    const interestExpenses: number[] = totalCalcMemo(interest_expenses);

    // キャッシュアウトしない費用
    const nonCashOutTotal: number[] = totalCalcMemo(non_cash_out);

    // 簡易CF
    const simpleCfRow1: number[] = add(
      currentEarnings,
      add(depreciationCost, nonCashOutTotal)
    );
    const simpleCfRow2: number[] = add(
      netIncome, // 当期純利益
      add(depreciationCost, nonCashOutTotal)
    );

    // 粗付加価値額
    const simpleCfRow3: number[] = add(
      ebit, // 税引き前当期純利益
      add(
        personalCostTotal, // 人件費合計
        add(
          laborCostsTotal, // 労務費合計
          add(
            depreciationCost, // 減価償却費
            add(
              nonCashOutTotal, // キャッシュアウトしない費用)
              interestExpenses // 支払利息
            )
          )
        )
      )
    );

    const resultsInputCalcState = {
      net_sales_total_by_item: netSalesTotalByItem,
      cost_of_sales_total_by_item: costOfSalesTotalByItem,
      selling_and_administrative_total_by_item:
        sellingAndAdministrativeTotalByItem,
      personal_cost_total_by_item: personalCostTotalByItem,
      non_operating_income_total_by_item: nonOperatingIncomeTotalByItem,
      non_operating_expenses_total_by_item: nonOperatingExpensesTotalByItem,
      special_benefits_total_by_item: specialBenefitsTotalByItem,
      special_losses_total_by_item: specialLossesTotalByItem,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item:
        corporateInhabitantAndEnterpriseTaxesTotalByItem,
      material_fee_total_by_item: materialFeeTotalByItem,
      labor_costs_total_by_item: laborCostsTotalByItem,
      external_injection_processing_fee_total_by_item:
        externalInjectionProcessingFeeTotalByItem,
      expenses_total_by_item: expensesTotalByItem,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem,
      cash_equivalent_total_by_item: cashEquivalentTotalByItem,
      accounts_receivable_total_by_item: accountsReceivableTotalByItem,
      current_assets_total_by_item: currentAssetsTotalByItem,
      shed_unloading_assets_total_by_item: shedUnloadingAssetsTotalByItem,
      quotidian_gold_total_by_item: quotidianGoldTotalByItem,
      other_current_assets_total_by_item: otherCurrentAssetsTotalByItem,
      tangible_fixed_assets_total_by_item: tangibleFixedAssetsTotalByItem,
      intangible_fixed_assets_total_by_item: intangibleFixedAssetsTotalByItem,
      investments_and_other_assets_total_by_item:
        investmentsAndOtherAssetsTotalByItem,
      deferred_asset_total_by_item: deferredAssetTotalByItem,
      payables_total_by_item: payablesTotalByItem,
      short_term_borrowings_total_by_item: shortTermBorrowingsTotalByItem,
      unpaids_total_by_item: unpaidsTotalByItem,
      current_liabilities_total_by_item: currentLiabilitiesTotalByItem,
      fixed_liabilities_total_by_item: fixedLiabilitiesTotalByItem,
      capital_total_by_item: capitalTotalByItem,
      capital_surplus_total_by_item: capitalSurplusTotalByItem,
      retained_earnings_total_by_item: retainedEarningsTotalByItem,
      other_retained_earnings_total_by_item: otherRetainedEarningsTotalByItem,
      treasury_stock_total_by_item: treasuryStockTotalByItem,
      employees_total_by_item: employeesTotalByItem,
      zero_array: zeroArray,
      net_sales_total: netSalesTotal,
      net_sales_ratio: netSalesRatio,
      cost_of_sales_total: costOfSalesTotal,
      cost_of_sales_ratio: costOfSalesRatio,
      gross_margin: grossMargin,
      gross_margin_ratio: grossMarginRatio,
      personal_cost_total: personalCostTotal,
      personal_cost_ratio: personalCostRatio,
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      selling_and_administrative_ratio: sellingAndAdministrativeRatio,
      operating_income: operatingIncome,
      operating_income_ratio: operatingIncomeRatio,
      operating_profits_before_amortization: operatingProfitsBeforeAmortization,
      operating_profits_before_amortization_ratio:
        operatingProfitsBeforeAmortizationRatio,
      non_operating_income_total: nonOperatingIncomeTotal,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      current_earnings: currentEarnings,
      current_earnings_ratio: currentEarningsRatio,
      ebitda: ebitda,
      ebitda_ratio: ebitdaRatio,
      special_benefits_total: specialBenefitsTotal,
      special_losses_total: specialLossesTotal,
      ebit: ebit,
      ebit_ratio: ebitRatio,
      net_income: netIncome,
      net_income_ratio: netIncomeRatio,
      simple_cf_row1: simpleCfRow1,
      simple_cf_row2: simpleCfRow2,
      simple_cf_row3: simpleCfRow3,
      material_fee_total: materialFeeTotal,
      labor_costs_total: laborCostsTotal,
      external_injection_processing_fee_total:
        externalInjectionProcessingFeeTotal,
      expenses_total: expensesTotal,
      gross_manufacturing_cost_for_the_period:
        grossManufacturingCostForThePeriod,
      cost_of_products_manufactured: CostOfProductsManufactured,
      cash_equivalent_total: cashEquivalentTotal,
      accounts_receivable_total: accountsReceivableTotal,
      current_assets_total: currentAssetsTotal,
      shed_unloading_assets_total: shedUnloadingAssetsTotal,
      quotidian_gold_total: quotidianGoldTotal,
      gross_current_assets: grossCurrentAssets,
      tangible_fixed_assets_total: tangibleFixedAssetsTotal,
      intangible_fixed_assets_total: intangibleFixedAssetsTotal,
      investments_and_other_assets_total: investmentsAndOtherAssetsTotal,
      gross_fixed_assets: grossFixedAssets,
      deferred_asset_total: deferredAssetTotal,
      net_worth: netWorth,
      payables_total: payablesTotal,
      short_term_borrowings_total: shortTermBorrowingsTotal,
      unpaids_total: unpaidsTotal,
      current_liabilities_total: currentLiabilitiesTotal,
      fixed_liabilities_total: fixedLiabilitiesTotal,
      liabilities_total: liabilitiesTotal,
      capital_total: capitalTotal,
      capital_surplus_total: capitalSurplusTotal,
      retained_earnings_total: retainedEarningsTotal,
      other_retained_earnings_total: otherRetainedEarningsTotal,
      treasury_stock_total: treasuryStockTotal,
      shareholders_equity_total: shareholdersEquityTotal,
      liabilities_and_net_worth_total: liabilitiesAndNetWorthTotal,
      long_term_borrowings_total: longTermBorrowingsTotal,
      employees_total: employeesTotal,
      material_fee_fluctuate_data_total: materialFeeFluctuateDataTotal,
      labor_costs_fluctuate_total: laborCostsFluctuateTotal,
      external_injection_processing_fee_fluctuate_total:
        externalInjectionProcessingFeeFluctuateTotal,
      expenses_fluctuate_total: expensesFluctuateTotal,
      inventory_of_work_in_process_at_the_beginning_of_the_period_t:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
      inventory_of_work_in_process_at_the_end_of_the_period_t:
        inventoryOfWorkInProcessAtTheEndOfThePeriodT,
      beginning_inventories_total: beginningInventoriesTotal,
      end_inventories_total: endInventoriesTotal,
      stocking_total: stockingTotal,
      cost_of_sales_except_beginning_and_end_total:
        costOfSalesExceptBeginningAndEndTotal,
      variable_costs_total: variableCostsTotal,
      personal_cost_fluctuate_total: personalCostFluctuateTotal,
      selling_and_administrative_fluctuate_total:
        sellingAndAdministrativeFluctuateTotal,
      sga_total: sgaTotal,
      variable_costs_grand_total: variableCostsGrandTotal,
      marginal_profit: marginalProfit,
      labor_costs_fixed_data_total: laborCostsFixedDataTotal,
      expenses_fixed_data_total: expensesFixedDataTotal,
      fixed_cost_total: fixedCostTotal,
      personal_cost_fixed_total: personalCostFixedTotal,
      selling_and_administrative_fixed_total:
        sellingAndAdministrativeFixedTotal,
      sga_fixed_total: sgaFixedTotal,
      fixed_cost_grand_total: fixedCostGrandTotal,
      operating_income_t: operatingIncomeT,
      variable_cost_ratio_t: variableCostRatioT,
      bep_net_sales_total: bepNetSalesTotal,
      bep_net_sales_ratio: bepNetSalesRatio,
      depreciation_cost: depreciationCost,
      material_fee_total_ratio: materialFeeTotalRatio,
      labor_costs_total_ratio: laborCostsTotalRatio,
      external_injection_processing_fee_total_ratio:
        externalInjectionProcessingFeeTotalRatio,
      expenses_total_ratio: expensesTotalRatio,
      net_sales_material_fee_total_ratio: netSalesMaterialFeeTotalRatio,
      gross_manufacturing_cost_for_the_period_ratio:
        grossManufacturingCostForThePeriodRatio,
      net_sales_labor_costs_total_ratio: netSalesLaborCostsTotalRatio,
      net_sales_external_injection_processing_fee_total_ratio:
        netSalesExternalInjectionProcessingFeeTotalRatio,
      net_sales_expenses_total_ratio: netSalesExpensesTotalRatio,
      raw_material_inventories_at_the_end_of_the_period:
        rawMaterialInventoriesAtTheEndOfThePeriodT,
      depreciation_cost_of_sales_t: depreciationCostOfSalesT,
      receivables_turnover: receivablesTurnover,
      inventory_turnover: inventoryTurnover,
      net_sales_total_by_item_ratio: netSalesTotalByItemRatio,
    };
    return resultsInputCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
