import { DocumentData } from "firebase/firestore";
import React from "react";

interface Props {
  headers: DocumentData[];
}

export const StatementOFCashFlowsHeader: React.FC<Props> = ({ headers }) => {
  const topCss =
    "relative px-6px py-3px top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full";

  const bottomCss =
    "relative before:content-[''] before:border-b-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-full";

  return (
    <thead className="sticky top-0 px-6px py-3px z-40 bg-white pt-120px">
      <tr>
        <th
          className={`sticky table_header_w w-380px  left-0 align-center text-center font-bold text-18px z-40 ${topCss} before:border-t before:border-x before:border-r-c3333334d      before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={4}
        >
          項目
        </th>

        {headers?.map(
          (header, index) =>
            index !== 0 && (
              <th
                className={`min-w-[7.5rem] w-120px bg-c2a4b770d ${topCss} before:border-t  ${
                  header.period === "total"
                    ? "bg-cfffee2 before:border-x text-center"
                    : "before:border-l before:border-l-c3333334d text-center"
                } before:border-b before:border-b-c3333334d `}
                key={index}
              >
                金額
              </th>
            )
        )}
        <th
          className={`min-w-[37.5rem] w-600px  before:border-collapse text-center align-middle bg-white z-30 ${topCss} before:border-l before:border-l-c3333334d before:border-x before:border-t before:border-b-2`}
          rowSpan={4}
        >
          備考
        </th>
      </tr>
      <tr>
        {headers?.map(
          (header, index) =>
            index !== 0 && (
              <th
                className={`w-120px text-left bg-c2a4b770d first-letter ${topCss} before:border-l before:border-l-c3333334d`}
                key={index}
              >
                {`
              ${header.nengou}`}
              </th>
            )
        )}
      </tr>
      <tr>
        {headers?.map(
          (header, index) =>
            index !== 0 && (
              <th
                className={`w-120px text-left bg-c2a4b770d first-letter ${topCss} before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
                key={index}
              >
                {`${header.period.substring(1, 5)}年
              ${header.period.substring(6, 7)}月`}
              </th>
            )
        )}
      </tr>
      <tr>
        {headers?.map(
          (header, index) =>
            index !== 0 && (
              <th
                className={`relative top-0 px-6px py-3px bg-c2a4b771a ${bottomCss} before:border-l before:border-l-c3333334d h-40px`}
                key={index}
              >
                {header.term}期
              </th>
            )
        )}
      </tr>
    </thead>
  );
};
