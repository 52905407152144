import { AxiosResponse } from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { memo, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { ConfirmTr, DarkButton, LightButton } from "../../components/atoms";
import { SignUpLayout } from "../../components/layout";
import { pageConstant } from "../../constants/pageConstants";
import { typeOfBusinessSelected } from "../../data";
import { getStoredToken } from "../../sessionStorage/sessionStorage";
import { signUpSpecialistStore, specialistsTokenStore } from "../../store";

const ConfirmSignUp = () => {
  const navigate = useNavigate();

  const signUpState = useRecoilValue(signUpSpecialistStore);

  const [accessToken, setAccessToken] = useRecoilState(specialistsTokenStore);

  const [state, setState] = useState({
    isShowPassword: false,
    isShowPasswordConfirmation: false,
  });

  const token = getStoredToken();

  // const plan = ["エントリー", "ベーシック", "ビジネス", "プロ"];

  // const cardKind =
  //   signUpState.card_kind === "VISA"
  //     ? `${window.location.origin}/img/visa.svg`
  //     : signUpState.card_kind === "Mastercard"
  //     ? `${window.location.origin}/img/mastercard.svg`
  //     : signUpState.card_kind === "JCB"
  //     ? `${window.location.origin}/img/jcb.svg`
  //     : signUpState.card_kind === "AmericanExpress"
  //     ? `${window.location.origin}/img/american_express.svg`
  //     : signUpState.card_kind === "DinersClub"
  //     ? `${window.location.origin}/img/diners_club.svg`
  //     : "";

  const handleSubmit = async () => {
    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: apiUrl.SPECIALISTS,
        method: "POST",
        data: {
          company_name: signUpState.company_name,
          company_kana_name: signUpState.company_kana_name,
          type_of_business: signUpState.type_of_business,
          department_name: signUpState.department_name,
          position: signUpState.position,
          first_name: signUpState.first_name,
          last_name: signUpState.last_name,
          first_kana_name: signUpState.first_kana_name,
          last_kana_name: signUpState.last_kana_name,
          phone: `${signUpState.phone1}${signUpState.phone2}${signUpState.phone3}`,
          plan_type: 1,
          // card_kind: signUpState.card_kind,
          // card_number: signUpState.card_number,
          // card_name: signUpState.card_name,
          // expire_month: signUpState.expire_month,
          // expire_year: signUpState.expire_year,
          // card_cvc_number: signUpState.card_cvc_number,
          payment_count: signUpState.payment_count,
          password: signUpState.password,
        },
        headers: {
          accessToken: accessToken,
        },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      toast.success("登録が完了しました");
      setTimeout(() => {
        navigate(`/${pageConstant.LOGIN}`);
      }, 3000);
    } catch (error) {
      toast.error("登録できませんでした");
    }
  };

  useEffect(() => {
    signUpState.company_name.length === 0 &&
      navigate(`/${pageConstant.SIGNUP_SPECIALIST}`);

    signInWithCustomToken(getAuth(), token)
      .then(async (result) => {
        const token = await result.user.getIdToken();

        setAccessToken(token);
      })
      .catch((error) => {
        throw new Error(error.message);
      });

    // ブラウザから離れようとすると、アラート表示
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SignUpLayout progress={3} title={"登録内容確認"}>
      <div className="w-[840px] m-auto">
        <form>
          <div>
            <h2 className="font-bold pt-10px">利用者情報</h2>
            <table className="w-full my-20px">
              <ConfirmTr title={"会社名・団体名"}>
                {signUpState.company_name}
              </ConfirmTr>
              <ConfirmTr title={"会社名・団体名（カナ）"}>
                {signUpState.company_kana_name}
              </ConfirmTr>
              <ConfirmTr title={"業種"}>
                {signUpState.type_of_business !== 0 &&
                  typeOfBusinessSelected[signUpState.type_of_business - 1].type}
              </ConfirmTr>
              <ConfirmTr title={"部署名"}>
                {signUpState.department_name}
              </ConfirmTr>
              <ConfirmTr title={"役職"}>{signUpState.position}</ConfirmTr>
              <ConfirmTr title={"氏名"}>
                {signUpState.first_name} {signUpState.last_name}
              </ConfirmTr>
              <ConfirmTr title={"氏名（カナ）"}>
                {signUpState.first_kana_name} {signUpState.last_kana_name}
              </ConfirmTr>
              <ConfirmTr title={"携帯電話番号"}>
                {signUpState.phone1}-{signUpState.phone2}-{signUpState.phone3}
              </ConfirmTr>
              <ConfirmTr title={"パスワード"}>
                {state.isShowPassword ? signUpState.password : "********"}
                <span
                  className={`absolute top-[24px] right-16px ${
                    signUpState.password.length === 0 && "text-c33333333"
                  }`}
                  onClick={() =>
                    setState({
                      ...state,
                      isShowPassword: !state.isShowPassword,
                    })
                  }
                  role="presentation"
                >
                  {state.isShowPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </ConfirmTr>
              <ConfirmTr title={"パスワード（確認）"}>
                {state.isShowPasswordConfirmation
                  ? signUpState.password
                  : "********"}
                <span
                  className={`absolute top-[24px] right-16px ${
                    signUpState.password.length === 0 && "text-c33333333"
                  }`}
                  onClick={() =>
                    setState({
                      ...state,
                      isShowPasswordConfirmation:
                        !state.isShowPasswordConfirmation,
                    })
                  }
                  role="presentation"
                >
                  {state.isShowPasswordConfirmation ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )}
                </span>
              </ConfirmTr>
            </table>
          </div>
          {/* <div>
            <h2 className="font-bold pt-10px">利用者情報</h2>
            <table className="w-full my-20px">
              <ConfirmTr title={"料金プラン"}>
                {signUpState.plan_type !== null &&
                  plan[signUpState.plan_type - 1]}
              </ConfirmTr>
            </table>
          </div> */}
          {/* <div>
            <h2 className="font-bold pt-10px">決済情報登録</h2>
            <table className="w-full my-20px">
              <ConfirmTr title={"カード番号"}>
                **** **** **** {signUpState.card_number.slice(-4)}
                <img
                  src={cardKind}
                  alt={
                    signUpState.card_kind === null ? "" : signUpState.card_kind
                  }
                  className="inline-block w-40px ml-12px"
                />
              </ConfirmTr>
              <ConfirmTr title={"カード名義"}>
                {signUpState.card_name}
              </ConfirmTr>
              <ConfirmTr title={"有効期限"}>
                {signUpState.expire_month}月 / {signUpState.expire_year}年
              </ConfirmTr>
              <ConfirmTr title={"CVC番号"}>***</ConfirmTr>
              <ConfirmTr title={"お支払い回数"}>一回払い</ConfirmTr>
            </table>
          </div> */}
        </form>
        <div className="flex gap-20px w-[580px] mx-auto pt-20px">
          <LightButton onClick={() => navigate(-1)}>前に戻る</LightButton>
          <DarkButton onClick={() => handleSubmit()}>
            上記の内容で登録する
          </DarkButton>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default memo(ConfirmSignUp);
