import { doc, DocumentData, Firestore, WriteBatch } from "firebase/firestore";
import {
  resultsInputHeader,
  plansInputHeader,
  plansInputMonthlyHeader,
  salesPlanHeaders,
} from "../data/firebaseModel";
import { profitAndLossPlanHeader } from "../data/firebaseModel/profit_and_loss_plan";
import { fiscalPeriod } from "../utils/date";

type UpdateType = {
  tableData: DocumentData[];
  headers: DocumentData;
  uid: string;
  firestore: Firestore;
  batch: WriteBatch;
};

export const version100 = ({
  tableData,
  headers,
  uid,
  firestore,
  batch,
}: UpdateType) => {
  const lastMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 0)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 1)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 2)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 3)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 4)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 5)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 6)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 7)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 8)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 9)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 10)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 11)]:
      null,
  };

  const thisMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 0)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 1)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 2)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 3)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 4)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 5)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 6)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 7)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 8)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 9)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 10)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 11)]: null,
  };

  const newHeaderDoc = doc(
    firestore,
    "accounts",
    uid,
    "tables",
    String(headers.NO_ID_FIELD)
  );
  batch.update(newHeaderDoc, {
    fiscal_year: headers.fiscal_year,
    fiscal_month: headers.fiscal_month,
    results_input: resultsInputHeader(
      headers.fiscal_year,
      headers.fiscal_month,
      headers.current_term
    ),
    plans_input: plansInputHeader(
      headers.fiscal_year,
      headers.fiscal_month,
      headers.current_term
    ),
    plans_input_monthly: plansInputMonthlyHeader(
      headers.fiscal_year,
      headers.fiscal_month,
      null,
      headers
    ),
    results_of_the_current_term: plansInputMonthlyHeader(
      headers.fiscal_year,
      headers.fiscal_month,
      null,
      headers
    ),
    results_of_the_previous_period: plansInputMonthlyHeader(
      Number(headers.fiscal_year) - 1,
      headers.fiscal_month,
      null,
      headers
    ),
    plans_input_estimated_in_this_term: plansInputMonthlyHeader(
      headers.fiscal_year,
      headers.fiscal_month,
      null,
      headers
    ),
    sales_plan: salesPlanHeaders(
      Number(headers.fiscal_year),
      headers.fiscal_month
    ),
    profit_and_loss_plan: profitAndLossPlanHeader(
      headers.fiscal_year,
      headers.fiscal_month
    ),
    updatedAt: new Date(),
  });

  tableData.map((data) => {
    if (data.field === "headers" || data.field === "descriptions") return;
    const updateDoc = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      data.NO_ID_FIELD
    );
    batch.set(updateDoc, {
      ...data,
      results_input: {
        ...data.results_input,
      },
      plans_input: {
        ...data.plans_input,
      },
      plans_input_monthly: {
        ...thisMonthlyData,
        ...data.plans_input_monthly,
      },
      results_of_the_current_term: {
        ...thisMonthlyData,
        ...data.results_of_the_current_term,
      },
      results_of_the_previous_period: {
        ...lastMonthlyData,
        ...data.results_of_the_previous_period,
      },
      plans_input_estimated_in_this_term: {
        ...thisMonthlyData,
        ...data.results_of_the_current_term,
      },
    });
  });
};
