import React from "react";

interface Props {
  children: React.ReactNode;
  title: string;
  htmlFor: string;
  required: boolean;
}

const FormContents: React.FC<Props> = ({
  children,
  title,
  htmlFor,
  required,
}) => {
  return (
    <div className="relative flex items-center justify-between my-15px">
      <label
        className={`block text-14px font-bold  w-4/12 ${
          required ? "before:content-['必須']" : "before:content-['任意']"
        } before:text-11px before:text-white ${
          required ? "before:bg-cd60b0b" : "before:bg-c33333380"
        } before:py-4px before:px-6px before:rounded before:mr-8px before:mt-2px`}
        htmlFor={htmlFor}
      >
        {title}
      </label>
      {children}
    </div>
  );
};

export default FormContents;
