import { doc, DocumentData, updateDoc } from "firebase/firestore";
import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useRecoilState } from "recoil";
import { TextInput } from "../../components/initialSettings";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import {
  updateModalIsOpenStore,
  updateProfitAndLossStatementStore,
} from "../../store";

interface Props {
  tab: number;
  headings: DocumentData[];
}

export const UpdateAccountModal: React.FC<Props> = ({ tab, headings }) => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const [updateAccountTitleState, setUpdateAccountTitleState] = useRecoilState(
    updateProfitAndLossStatementStore
  );
  const [updateModalIsOpen, setUpdateModalIsOpen] = useRecoilState(
    updateModalIsOpenStore
  );

  // 登録ボタン
  const handleCreate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (
      updateAccountTitleState.item === null ||
      !updateAccountTitleState.item
    ) {
      toast.warning("勘定科目細目を入力してください");
      return;
    }

    const docRef = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      String(updateAccountTitleState.NO_ID_FIELD)
    );

    await updateDoc(docRef, {
      field: updateAccountTitleState.field,
      item: updateAccountTitleState.item,
      section: updateAccountTitleState.section,
      is_non_cash_out: updateAccountTitleState.is_non_cash_out,
    })
      .then(() => {
        toast.success("勘定科目を更新しました");
        setUpdateAccountTitleState({});
        setUpdateModalIsOpen(false);
      })
      .catch((error) => {
        toast.error("更新できませんでした");
        throw new Error(error.message);
      });
  };

  // キャンセルボタンの処理
  const handleCloseModal = () => {
    setUpdateModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={updateModalIsOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 49,
        },
        content: {
          position: "relative",
          width: "50%",
          maxWidth: "30rem",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <button
        className="absolute right-20px top-20px font-bold hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset "
        onClick={handleCloseModal}
        type="button"
      >
        <span className="sr-only">Close menu</span>
        <svg
          aria-hidden="true"
          className="h-20px w-20px"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6l12 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </button>
      <div className="flex justify-center flex-col px-20px">
        <h3 className="text-24px font-bold text-center" id="modal-title">
          勘定科目編集
        </h3>
        <div>
          <div className="mt-20px">
            <div>勘定科目名</div>
            <select
              className="mt-4px w-full"
              defaultValue={updateAccountTitleState.field}
              onChange={(e) =>
                setUpdateAccountTitleState({
                  ...updateAccountTitleState,
                  field: e.target.value,
                })
              }
            >
              {headings.map((heading, index) => (
                <option key={index} value={heading.field}>
                  {heading.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-20px">
            <div>勘定科目細目</div>
            <div className="mt-4px">
              <TextInput item={updateAccountTitleState.item} />
            </div>
          </div>
          <div className="mt-20px">
            <div>固定変動区分</div>
            <select
              className="mt-4px w-full"
              defaultValue={updateAccountTitleState.section}
              onChange={(e) =>
                setUpdateAccountTitleState({
                  ...updateAccountTitleState,
                  section: e.target.value,
                })
              }
            >
              <option value={""}></option>
              <option value={"固定"}>固定</option>
              <option value={"変動"}>変動</option>
            </select>
          </div>
          {tab === 1 && (
            <div className="mt-20px">
              <label className="flex items-center">
                <input
                  checked={updateAccountTitleState.is_non_cash_out}
                  className="mr-16px w-20px h-20px"
                  onChange={(e) =>
                    setUpdateAccountTitleState({
                      ...updateAccountTitleState,
                      is_non_cash_out: e.target.checked,
                    })
                  }
                  type="checkbox"
                />
                <span className="leading-5">
                  キャッシュアウトしない費用の場合、 <br />
                  チェックを入れてください
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="px-16px py-16px flex justify-center gap-50px">
        <button
          className="relative mt-12px px-12px py-6px text-c427ed1 border border-c427ed1 rounded"
          onClick={handleCreate}
          type="button"
        >
          更新
        </button>
        <button
          className="relative mt-12px px-12px py-6px text-c427ed1 border border-c427ed1 rounded"
          onClick={handleCloseModal}
          type="button"
        >
          戻る
        </button>
      </div>
    </Modal>
  );
};
