import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlPlansInputMonthlyBody = ({
  headers,
  rows,
  title,
  calcState,
  url,
  bodyField,
  isCurrentPlanMonthly,
  unit,
  descriptions,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  title: string | null;
  calcState: DocumentData;
  url: string;
  bodyField?: string;
  isCurrentPlanMonthly?: boolean;
  unit: number;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];
  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white top-0 font-bold align-text-top mt-12px before:border-l before:h-full`;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-l before:border-l-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x before:border-x-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol4 = `${borderSettings}  ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right   ${
        rowIndex % 2 !== 0
          ? "bg-c2a4b770d before:border-l-c3333334d"
          : "before:border-l-c3333334d"
      } `;

      const classCol5 = `relative p-4px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right   ${
        rowIndex % 2 !== 0
          ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
          : "before:bg-cfffee2 before:border-x"
      }`;

      const classCol6 = `${borderSettings} before:border-r ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} text-left  h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: rowIndex === 0 ? title : "",
        expr: rowIndex === 0 ? title : "",
        readOnly: true,
        className: classCol1,
        colSpan: 2,

        row: row,
        url: url,
        key: "",
      });

      gridRow.push(
        { value: row.item, readOnly: true, className: classCol2 },
        {
          value: row.section,
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      headers.map((_, tdIndex: number) => {
        gridRow.push({
          value:
            calcState.by_item && isNaN(calcState.by_item[rowIndex][tdIndex])
              ? 0
              : calcState.by_item &&
                calcState.by_item !== null &&
                calcState.by_item[rowIndex].length !== 0 &&
                isCurrentPlanMonthly
              ? ShowTableDataTriangle(
                  calcState.by_item[rowIndex][tdIndex] / unit
                )
              : calcState.by_item !== null &&
                calcState.by_item[rowIndex].length !== 0
              ? ShowTableData(calcState.by_item[rowIndex][tdIndex] / unit)
              : 0,
          expr:
            calcState.by_item && isNaN(calcState.by_item[rowIndex][tdIndex])
              ? 0
              : calcState.by_item &&
                calcState.by_item !== null &&
                calcState.by_item[rowIndex].length !== 0 &&
                isCurrentPlanMonthly
              ? calcState.by_item[rowIndex][tdIndex] / unit
              : 0,
          readOnly: true,
          className: classCol4,
          colSpan: 1,
        });
      });

      // 年度貼付
      gridRow.push({
        value:
          isCurrentPlanMonthly && isNaN(calcState.plans_input[rowIndex])
            ? 0
            : isCurrentPlanMonthly
            ? ShowTableDataTriangle(calcState.plans_input[rowIndex] / unit)
            : ShowTableData(calcState.plans_input[rowIndex] / unit),
        expr:
          isCurrentPlanMonthly && isNaN(calcState.plans_input[rowIndex])
            ? 0
            : calcState.plans_input[rowIndex] / unit,
        readOnly: true,
        className: classCol5,
        colSpan: 1,
      });

      // 月次合計
      !isCurrentPlanMonthly &&
        gridRow.push({
          value:
            calcState.monthly_total_by_item !== null &&
            isNaN(calcState.monthly_total_by_item[rowIndex])
              ? 0
              : calcState.monthly_total_by_item !== null
              ? ShowTableData(calcState.monthly_total_by_item[rowIndex] / unit)
              : 0,
          expr:
            calcState.monthly_total_by_item !== null &&
            isNaN(calcState.monthly_total_by_item[rowIndex])
              ? 0
              : calcState.monthly_total_by_item !== null
              ? calcState.monthly_total_by_item[rowIndex] / unit
              : 0,
          readOnly: true,
          className: classCol5,
          colSpan: 1,
        });
      // 実績
      !isCurrentPlanMonthly &&
        gridRow.push({
          value: ShowTableData(calcState.current_term[rowIndex] / unit),
          expr: calcState.current_term[rowIndex] / unit,
          readOnly: true,
          className: classCol5,
          colSpan: 1,
        });
      // 残
      !isCurrentPlanMonthly &&
        gridRow.push({
          value: ShowTableData(calcState.remining[rowIndex] / unit),
          expr: calcState.remining[rowIndex] / unit,
          readOnly: true,
          className: classCol5,
          colSpan: 1,
        });
      // 年間計
      !isCurrentPlanMonthly &&
        gridRow.push({
          value: isNaN(calcState.yearly[rowIndex])
            ? 0
            : ShowTableData(calcState.yearly[rowIndex] / unit),
          expr: isNaN(calcState.yearly[rowIndex])
            ? 0
            : round(calcState.yearly[rowIndex]),
          readOnly: true,
          className: classCol5,
          colSpan: 1,
        });

      gridRow.push({
        value:
          isCurrentPlanMonthly && bodyField
            ? descriptions[`${bodyField}_${rowIndex}`]
            : row?.descriptions[`${url}_${row?.field as string}`],
        expr:
          isCurrentPlanMonthly && bodyField
            ? descriptions[`${bodyField}_${rowIndex}`]
            : row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol6,
        row: isCurrentPlanMonthly ? descriptions : row,
        url: url,
        key: isCurrentPlanMonthly ? "descriptions_calc" : "descriptions",
        colSpan: 1,
        field:
          isCurrentPlanMonthly && bodyField
            ? `${bodyField}_${rowIndex}`
            : `${url}_${row?.field as string}`,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
