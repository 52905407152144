import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const ProfitAndLossPlanBodyForCostOfSales = ({
  headers,
  rows,
  url,
  unit,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  url: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    rows?.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} p-4px left-0 bg-white before:border-l before:bg-c80b0f433 ${
        rows.length === rowIndex + 1 ? "before:border-b" : ""
      }`;

      const classCol2 = `${beforeWrapper} p-4px left-80px  bg-white before:border-l before:border-l-c3333334d before:border-b ${
        rows.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"} z-[32]`;

      const classCol3 = `${beforeWrapper} p-4px left-160px bg-white before:border-r before:border-r-c3333334d before:border-b ${
        rows.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol4 = `${borderSettings} p-4px before:border-l before:border-l-c3333334d before:border-b ${
        rows.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      } text-right ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol5 = `${borderSettings} p-4px before:border-x  before:border-l-c3333334d before:border-b ${
        rows.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      } text-left h-50px ${
        rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: "",
        readOnly: true,
        className: classCol1,
      });

      gridRow.push(
        {
          value: (
            <div
              className={`absolute top-20px left-4px whitespace-nowrap top_absolute`}
            >
              {row?.item.substring(5)}
            </div>
          ),
          readOnly: true,
          className: classCol2,
        },
        {
          value: "",
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      headers?.map((header: DocumentData, tdIndex: number) => {
        gridRow.push({
          value:
            row && tdIndex >= 2
              ? ""
              : row && row[url] && row[url][header.period]
              ? ShowTableData(row[url][header.period] / unit)
              : 0,
          expr:
            row && tdIndex >= 2
              ? ""
              : row && row[url] && row[url][header.period]
              ? row[url][header.period] / unit
              : 0,
          readOnly: tdIndex < 2 ? false : true,
          className: `${classCol4} before:border-l-c3333334d`,
          row: row,
          url: url,
          key: header.period,
          colSpan: 1,
        });
      });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol5,
        row: row,
        url: url,
        key: "descriptions",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
