import { DocumentData } from "firebase/firestore";
import * as React from "react";
import { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import {
  useBusinessPlanInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  AddedValue,
  BusinessPlanHeader,
  BusinessPlanNote,
  CapitalInvestment,
  CurrentEarnings,
  DepreciationCost,
  GrowthRate,
  NetSales,
  NonOperatingExpenses,
  OperatingIncome,
  PersonalCost,
  PersonalCostGrowthRate,
} from "../../../lib/datasheets/businessPlan";
import BusinessPlanRateHeader from "../../../lib/datasheets/businessPlan/BusinessPlanRateHeader";
import { GrossPersonalCost } from "../../../lib/datasheets/businessPlan/GrossPersonalCost";
import { GridElement } from "../../../types/gridElement";
import { Spinner } from "../../molecules";
import { SalesPlanLayout } from "../salesPlan";

interface Props {
  url: string;
  dataUrl: string;
  fetchUrl: string;
}

const BusinessPlanContainer: React.FC<Props> = ({ url, dataUrl }) => {
  const firestore = useFirestore();

  const businessPlanInitialData = useBusinessPlanInitialData();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  if (!businessPlanInitialData) {
    return <Spinner />;
  }

  const { businessPlanState, businessPlanCalcState } = businessPlanInitialData;

  const grid = NetSales({
    headers: businessPlanState.headers[dataUrl],
    rows: businessPlanState.net_sales_total_data,
    state: businessPlanCalcState.net_sales_total,
    descriptions: businessPlanState.descriptions,
    dataUrl: dataUrl,
    url: url,
    unit: unit,
  });

  grid.push(
    ...OperatingIncome({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.operating_income,
      descriptions: businessPlanState.descriptions,
      dataUrl: dataUrl,
      url: url,
      unit: unit,
    }),
    ...NonOperatingExpenses({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.non_operating_expenses_total,
      descriptions: businessPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...CurrentEarnings({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.current_earnings_for_business_plan,
      descriptions: businessPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...PersonalCost({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.personal_cost_total,
      descriptions: businessPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...DepreciationCost({
      headers: businessPlanState.headers[dataUrl],
      rows: businessPlanState.depreciation_cost_for_business_plan_data[0],
      dataUrl: url,
      unit: unit,
    }),
    ...AddedValue({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.added_value,
      descriptions: businessPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...GrowthRate({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.growth_rate,
      descriptions: businessPlanState.descriptions,
      url: url,
    }),
    ...CapitalInvestment({
      headers: businessPlanState.headers[dataUrl],
      rows: businessPlanState.capital_investment_for_business_plan_data[0],
      dataUrl: url,
      unit: unit,
    }),
    ...GrossPersonalCost({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.gross_personal_cost,
      descriptions: businessPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...PersonalCostGrowthRate({
      headers: businessPlanState.headers[dataUrl],
      state: businessPlanCalcState.personal_cost_growth_rate,
      descriptions: businessPlanState.descriptions,
      url: url,
    })
    // ...Note({
    //   headers: businessPlanState.headers[dataUrl],
    //   state: businessPlanCalcState.personal_cost_growth_rate,
    //   descriptions: businessPlanState.descriptions,
    //   url: url,
    // })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <SalesPlanLayout>
      <>
        <BusinessPlanRateHeader
          businessPlanCalcState={businessPlanCalcState.operating_profit_ratio}
          header={businessPlanState.headers}
          headers={businessPlanState.headers[dataUrl]}
          netSalesRate={businessPlanCalcState.net_sales_growth_rate}
        />
        <ReactDataSheet
          data={grid}
          dataRenderer={(cell) => cell.expr}
          onCellsChanged={HandleCellChanged}
          sheetRenderer={(props: {
            className: string | undefined;
            children: React.ReactElement<
              DocumentData,
              string | React.JSXElementConstructor<DocumentData>
            >;
          }) => (
            <table className={`${props.className}`}>
              <BusinessPlanHeader
                headers={businessPlanState.headers[dataUrl]}
              />

              <tbody className="text-14px">{props.children}</tbody>
            </table>
          )}
          valueRenderer={(cell) => cell.value}
        />
        <BusinessPlanNote descriptions={businessPlanState.descriptions} />
      </>
    </SalesPlanLayout>
  );
};

export default memo(BusinessPlanContainer);
