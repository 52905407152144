import { DocumentData } from "firebase/firestore";
import React from "react";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlBudgetControlBasisBodyWithTax = ({
  headers,
  rows,
  calcState,
  url,
  unit,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  calcState: DocumentData;
  url: string;
  unit: number;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const byItemData = singleOrCumsumToggle
    ? [
        calcState.current_by_item,
        calcState.previous_by_item,
        calcState.current_and_previous_difference_by_item,
        calcState.current_and_previous_difference_by_item_ratio,
        calcState.by_item,
        calcState.current_and_plan_difference_by_item,
        calcState.current_and_plan_difference_by_item_ratio,
      ]
    : [
        calcState.current_by_item_cumsum,
        calcState.previous_by_item_cumsum,
        calcState.current_and_previous_difference_by_item_cumsum,
        calcState.current_and_previous_difference_by_item_ratio_cumsum,
        calcState.plan_by_item_cumsum,
        calcState.current_and_plan_difference_by_item_cumsum,
        calcState.current_and_plan_difference_by_item_ratio_cumsum,
      ];

  const byItemTotalData = [
    calcState.current_by_item_total,
    calcState.previous_by_item_total,
    calcState.current_and_previous_difference_by_item_total,
    calcState.current_and_previous_difference_by_item_total_ratio,
    calcState.plans_input,
    calcState.current_and_plan_difference_by_item_total,
    calcState.current_and_plan_difference_by_item_total_ratio,
  ];

  const grid: GridElement[][] = [];
  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white top-0 font-bold align-text-top mt-12px before:border-l before:border-b before:h-full z-[31] `;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b  ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x before:border-x-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol4 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right   ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} `;

      const classCol5 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 before:border-r ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} text-left  h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: (
          <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
            法人税、住民税及び事業税
          </div>
        ),
        expr: "法人税、住民税及び事業税",
        readOnly: true,
        className: classCol1,
        row: row,
        url: url,
        key: "",
      });

      gridRow.push(
        { value: "", readOnly: true, className: classCol2 },
        {
          value: "",
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      headers.map((_, headerIndex: number) => {
        byItemData.map((data: number[][], dataIndex: number) => {
          gridRow.push({
            value:
              data !== null && (dataIndex === 3 || dataIndex === 6)
                ? `${showPercentage(data[rowIndex][headerIndex])}%`
                : data !== null
                ? ShowTableData(data[rowIndex][headerIndex] / unit)
                : "",
            expr:
              data !== null && (dataIndex === 3 || dataIndex === 6)
                ? `${showPercentage(data[rowIndex][headerIndex])}%`
                : data !== null
                ? data[rowIndex][headerIndex] / unit
                : "",
            readOnly: true,
            className: `${classCol4} ${
              dataIndex !== 0 && "before:border-l-c3333334d"
            }`,
            colSpan: 1,
          });
        });
      });

      // 合計
      singleOrCumsumToggle &&
        byItemTotalData.map((data: number[], dataIndex: number) => {
          gridRow.push({
            value:
              data !== null && (dataIndex === 3 || dataIndex === 6)
                ? `${showPercentage(data[rowIndex])}%`
                : data !== null
                ? ShowTableData(data[rowIndex] / unit)
                : "",
            expr:
              data !== null && (dataIndex === 3 || dataIndex === 6)
                ? `${showPercentage(data[rowIndex])}%`
                : data !== null
                ? data[rowIndex] / unit
                : "",
            readOnly: true,
            className: `${classCol4} before:bg-cfffee2 ${
              dataIndex !== 0 && "before:border-l-c3333334d"
            }`,
            colSpan: 1,
          });
        });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol5,
        row: row,
        url: url,
        key: "descriptions",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
