import { DocumentData, writeBatch } from "firebase/firestore";
import React from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useSetRecoilState } from "recoil";
import { useGetFirebaseData } from "../../firebase";
import { useHeaders } from "../../hooks";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import { switchLoadingState } from "../../store";
import { version100 } from "../../updates/version100";
import { version101 } from "../../updates/version101";
import { version102 } from "../../updates/version102";

type Props = {
  firebaseUpdateAt: Date;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

const UpdateButton: React.FC<Props> = ({ firebaseUpdateAt, setIsUpdate }) => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const { tableData } = useGetFirebaseData();

  const headers = useHeaders(tableData);

  const setIsLoading = useSetRecoilState(switchLoadingState);

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setIsLoading(true);

    // 最終更新日を取得
    const updateDate20230801 = new Date(2023, 7, 1);
    const updateDate20240415 = new Date(2024, 3, 15);
    const updateDate20240605 = new Date(2024, 5, 5);

    // firestoreのデータを更新する
    const batch = writeBatch(firestore);

    // 固定資産除却損
    const LossOnDisposalOfFixedAssets = tableData.filter(
      (inputData: DocumentData) =>
        inputData.field === "special_losses" &&
        inputData.item === "固定資産除却損"
    );

    const RetirementBenefitCosts = tableData.filter(
      (inputData: DocumentData) =>
        inputData.field === "labor_costs" && inputData.item === "職給付費用"
    );

    // 更新日を確認し、更新日が新しければ、更新する
    if (headers.fiscal_year && updateDate20230801 > firebaseUpdateAt) {
      version100({
        tableData,
        headers,
        uid,
        firestore,
        batch,
      });
      version101({
        headers,
        uid,
        LossOnDisposalOfFixedAssets: LossOnDisposalOfFixedAssets[0].NO_ID_FIELD,
        firestore,
        batch,
      });
      version102({
        headers,
        uid,
        RetirementBenefitCosts: RetirementBenefitCosts[0].NO_ID_FIELD,
        firestore,
        batch,
      });
    } else if (headers.fiscal_year && updateDate20240415 > firebaseUpdateAt) {
      version101({
        headers,
        uid,
        LossOnDisposalOfFixedAssets: LossOnDisposalOfFixedAssets[0].NO_ID_FIELD,
        firestore,
        batch,
      });
      version102({
        headers,
        uid,
        RetirementBenefitCosts: RetirementBenefitCosts[0].NO_ID_FIELD,
        firestore,
        batch,
      });
    } else if (headers.fiscal_year && updateDate20240605 > firebaseUpdateAt) {
      version102({
        headers,
        uid,
        RetirementBenefitCosts: RetirementBenefitCosts[0].NO_ID_FIELD,
        firestore,
        batch,
      });
    }

    await batch
      .commit()
      .then(() => {
        toast.success("データの更新が完了しました");
      })
      .catch((error) => {
        toast.error("更新できませんでした");
        throw new Error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setIsUpdate(false);
      });
  };

  return (
    <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center bg-c33333380 z-[9999]">
      <div className="flex flex-col justify-center items-center bg-white py-20px px-40px rounded">
        <p className="text-c1b2e48 mt-24px mb-16px">
          アップデートがありますので、更新ボタンをクリックしてください。
        </p>

        <button
          className="w-280px h-60px m-30px bg-c427ed1 text-white text-15px rounded hover:opacity-80"
          onClick={(e) => handleClick(e)}
        >
          更新
        </button>
      </div>
    </div>
  );
};

export default UpdateButton;
