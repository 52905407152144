import { divide } from "mathjs";

export const singleRatioCalc = ({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}) => {
  let divineValue = divide(numerator, denominator) as number;

  if (isNaN(divineValue) === true) {
    return (divineValue = 0);
  }

  // 小数点第一位まで表示
  return Number((divineValue * 100).toFixed(1));
};
