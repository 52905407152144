import { AxiosResponse } from "axios";
import React, { memo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { usePostalJp } from "use-postal-jp";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton } from "../../components/atoms";
import { SubHeader } from "../../components/header";
import { FormContents } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { queryConstants } from "../../constants/queryConstants";
import { industryClassification } from "../../data";
import { useClients } from "../../hooks";
import { getStoredClientToken } from "../../sessionStorage/sessionStorage";
import { toggleStore } from "../../store/switchState";

const AccountsClients = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const clients = useClients();

  const toggle = useRecoilValue(toggleStore);

  const [clientsState, setClientsState] = useState({
    ...clients.client,
    client_phone1: String(
      clients.client.client_phone1
        ? clients.client.client_phone1
        : clients.client.client_phone.substring(0, 3)
    ),
    client_phone2: String(
      clients.client.client_phone2
        ? clients.client.client_phone2
        : clients.client.client_phone.substring(3, 7)
    ),
    client_phone3: String(
      clients.client.client_phone3
        ? clients.client.client_phone3
        : clients.client.client_phone.substring(7, 11)
    ),
  });

  const [address] = usePostalJp(
    clientsState.client_postal_code,
    clientsState.client_postal_code.length >= 7
  );

  const largeClassification = industryClassification.filter(
    (data) => data.middle === "00"
  );

  const middleClassification = industryClassification.filter(
    (data) =>
      data.large === clientsState.client_industry_step_1 &&
      data.middle !== "00" &&
      data.minor === "000"
  );

  const minorClassification = industryClassification.filter(
    (data) =>
      data.large === clientsState.client_industry_step_1 &&
      data.middle === clientsState.client_industry_step_2 &&
      data.minor !== "000" &&
      data.sub === "0000"
  );

  const subClassification = industryClassification.filter(
    (data) =>
      data.large === clientsState.client_industry_step_1 &&
      data.middle === clientsState.client_industry_step_2 &&
      data.minor === clientsState.client_industry_step_3 &&
      data.sub !== "0000"
  );

  const handleClick = () => {
    address !== null &&
      setClientsState({
        ...clientsState,
        client_province: address.prefecture,
        client_address: `${address.address1}${address.address2}`,
      });
  };

  const accessToken = getStoredClientToken();

  const handleSubmit = async () => {
    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: `${apiUrl.CLIENTS}/${clientsState.id}`,
        method: "PUT",
        data: {
          id: clientsState.id,
          client_first_name: clientsState.client_first_name,
          client_last_name: clientsState.client_last_name,
          client_first_kana_name: clientsState.client_first_kana_name,
          client_last_kana_name: clientsState.client_last_kana_name,
          client_company: clientsState.client_company,
          client_postal_code: clientsState.client_postal_code,
          client_province: clientsState.client_province,
          client_address: clientsState.client_address,
          client_address_detail: clientsState.client_address_detail,
          client_position: clientsState.client_position,
          client_industry_step_1: clientsState.client_industry_step_1,
          client_industry_step_2: clientsState.client_industry_step_2,
          client_industry_step_3: clientsState.client_industry_step_3,
          client_industry_step_4: clientsState.client_industry_step_4,
          client_phone: `${clientsState.client_phone1}${clientsState.client_phone2}${clientsState.client_phone3}`,
        },
        headers: { accessToken: accessToken },
      });
      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      try {
        const { data }: AxiosResponse = await axiosInstance.get(
          `${apiUrl.USERS}/${clients.user.uid}/edit`,
          {
            headers: { accessToken: accessToken },
            data: { id: clients.user.uid },
          }
        );
        queryClient.setQueriesData(queryConstants.CLIENTS, data);

        toast.success("更新しました");
        setTimeout(() => {
          navigate(`/${pageConstant.MANAGEMENT_DASHBOARD}`);
        }, 3000);
      } catch (error) {
        toast.error("更新できませんでした");
        console.error(error);
        return;
      }
    } catch (error) {
      toast.error("更新できませんでした");
    }
  };

  return (
    <React.Fragment>
      <SubHeader title="アカウント" />
      <div
        className={`fixed ${
          toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
        } h-[calc(100vh-190px)] mt-150px top-0 overflow-x-scroll mb-16px`}
      >
        <div className="px-40px py-25px flex flex-col max-w-[932px] m-auto">
          <FormContents htmlFor={"name"} required={true} title={"氏名"}>
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastName"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_last_name: String(e.target.value),
                  })
                }
                placeholder="姓"
                required
                type="text"
                value={clientsState.client_last_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstName"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_first_name: String(e.target.value),
                  })
                }
                placeholder="名"
                required
                type="text"
                value={clientsState.client_first_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"kanaName"}
            required={true}
            title={"氏名（カナ）"}
          >
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastKanaName"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_last_kana_name: String(e.target.value),
                  })
                }
                placeholder="セイ"
                required
                type="text"
                value={clientsState.client_last_kana_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstKanaName"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_first_kana_name: String(e.target.value),
                  })
                }
                placeholder="メイ"
                required
                type="text"
                value={clientsState.client_first_kana_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"companyName"}
            required={true}
            title={"会社名・団体名"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="companyName"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_company: String(e.target.value),
                })
              }
              placeholder="株式会社〇〇〇〇"
              required
              type="text"
              value={clientsState.client_company}
            />
          </FormContents>
          <FormContents
            htmlFor={"postalCode"}
            required={true}
            title={"郵便番号"}
          >
            <span className="w-8/12 flex items-center gap-20px">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-4/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="postalCode"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_postal_code: String(e.target.value),
                  })
                }
                placeholder="1234567"
                required
                type="text"
                value={clientsState.client_postal_code}
              />
              <div>
                <a
                  className="text-14px text-c427ed1 underline"
                  href={"javascript:void(0)"}
                  onClick={() => handleClick()}
                >
                  郵便番号から住所を入力する
                </a>
              </div>
            </span>
          </FormContents>
          <FormContents htmlFor={"province"} required={true} title={"都道府県"}>
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-4/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="postalCode"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_province: String(e.target.value),
                  })
                }
                placeholder=""
                required
                type="text"
                value={clientsState.client_province}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"clientAddress"}
            required={true}
            title={"地名・番地"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientAddress"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_address: String(e.target.value),
                })
              }
              placeholder=""
              required
              type="text"
              value={clientsState.client_address}
            />
          </FormContents>
          <FormContents
            htmlFor={"clientAddressDetail"}
            required={false}
            title={"建物名・階・部屋番号"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientAddressDetail"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_address_detail: String(e.target.value),
                })
              }
              placeholder=""
              type="text"
              value={clientsState.client_address_detail}
            />
          </FormContents>
          <FormContents htmlFor={"position"} required={true} title={"役職"}>
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="position"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_position: String(e.target.value),
                })
              }
              placeholder="部長"
              required
              type="text"
              value={clientsState.client_position}
            />
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep1"}
            required={true}
            title={"業種（大分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep1"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_industry_step_1: String(e.target.value),
                })
              }
              placeholder="選択してください"
              required
              value={clientsState.client_industry_step_1}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {largeClassification.map((select, index) => (
                <option key={index} value={select.large}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep2"}
            required={true}
            title={"業種（中分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep2"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_industry_step_2: String(e.target.value),
                })
              }
              placeholder="選択してください"
              required
              value={clientsState.client_industry_step_2}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {middleClassification.map((select, index) => (
                <option key={index} value={select.middle}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep3"}
            required={false}
            title={"業種（小分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep3"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_industry_step_3: String(e.target.value),
                })
              }
              placeholder="選択してください"
              value={clientsState.client_industry_step_3}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {minorClassification.map((select, index) => (
                <option key={index} value={select.minor}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep4"}
            required={false}
            title={"業種（細分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep4"
              onChange={(e) =>
                setClientsState({
                  ...clientsState,
                  client_industry_step_4: String(e.target.value),
                })
              }
              placeholder="選択してください"
              value={clientsState.client_industry_step_4}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {subClassification.map((select, index) => (
                <option key={index} value={select.sub}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"phone"}
            required={true}
            title={"携帯電話番号"}
          >
            <span className="w-[32.666%] flex justify-between mr-auto">
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] float-left py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone1"
                inputMode="text"
                maxLength={3}
                minLength={3}
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_phone1: String(e.target.value),
                  })
                }
                pattern="0[5789]0"
                placeholder="000"
                required
                type="text"
                value={clientsState.client_phone1}
              />
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone2"
                inputMode="text"
                maxLength={4}
                minLength={4}
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_phone2: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={clientsState.client_phone2}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone3"
                inputMode="text"
                maxLength={4}
                minLength={4}
                name="phone3"
                onChange={(e) =>
                  setClientsState({
                    ...clientsState,
                    client_phone3: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={clientsState.client_phone3}
              />
            </span>
          </FormContents>
        </div>
        <div className="w-[450px] m-auto py-30px">
          <DarkButton onClick={handleSubmit}>更新する</DarkButton>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(AccountsClients);
