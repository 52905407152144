import React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { BusinessPlanLayout } from "../../components/planMaking/businessPlan";
import { BusinessPlanContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

const BusinessPlan = () => {
  const url = pageConstant.BUSINESS_PLAN;
  const dataUrl = pageConstant.PROFIT_AND_LOSS_PLAN;
  const fetchUrl = pageConstant.PLANS_INPUT;

  return (
    <React.Fragment>
      <SubHeader title="全社の事業計画" />
      <UnitChange />
      <BusinessPlanLayout>
        <div className="relative flex gap-12px">
          <BusinessPlanContainer
            dataUrl={dataUrl}
            fetchUrl={fetchUrl}
            url={url}
          />
        </div>
      </BusinessPlanLayout>
    </React.Fragment>
  );
};

export default BusinessPlan;
