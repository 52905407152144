import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { pageConstant } from "../../constants/pageConstants";
import { useClientsLists } from "../../hooks";
import { useSpecialists } from "../../hooks/useSpecialists";
import { toggleStore } from "../../store/switchState";

interface Props {
  title: string;
  isSpecialist?: boolean;
}

const SubHeader: React.FC<Props> = ({ title, isSpecialist }) => {
  const clients = useClientsLists("");
  const specialists = useSpecialists();

  const toggle = useRecoilValue(toggleStore);

  const planType: number | undefined =
    specialists !== null && specialists?.specialist?.priceplan?.plan_type;

  return (
    <div
      className={`fixed ${
        toggle ? "w-[calc(100%-220px)]" : "w-[calc(100%-30px)]"
      } h-50px flex items-center justify-between top-50px px-20px py-12px text-20px font-bold bg-c2a4b771a z-40`}
    >
      {title}
      {isSpecialist && (
        <div className="my-4px">
          {specialists !== null &&
          planType === 1 &&
          clients &&
          clients !== null &&
          clients.length >= 1 ? (
            <button
              className="bg-c427ed1 hover:opacity-80 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
              onClick={() =>
                toast.warning(
                  "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                )
              }
              type="submit"
            >
              <span>クライアント新規登録</span>
            </button>
          ) : specialists !== null &&
            planType === 2 &&
            clients &&
            clients !== null &&
            clients.length >= 2 ? (
            <button
              className="bg-c427ed1 hover:opacity-80 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
              onClick={() =>
                toast.warning(
                  "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                )
              }
              type="submit"
            >
              <span>クライアント新規登録</span>
            </button>
          ) : specialists !== null &&
            planType === 3 &&
            clients &&
            clients !== null &&
            clients.length >= 5 ? (
            <button
              className="bg-c427ed1 hover:opacity-80 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
              onClick={() =>
                toast.warning(
                  "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                )
              }
              type="submit"
            >
              <span>クライアント新規登録</span>
            </button>
          ) : specialists !== null &&
            planType === 4 &&
            clients &&
            clients !== null &&
            clients.length >= 10 ? (
            <button
              className="bg-c427ed1 hover:opacity-80 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
              onClick={() =>
                toast.warning("クライアントを登録できる上限を超えました。")
              }
              type="submit"
            >
              <span>クライアント新規登録</span>
            </button>
          ) : (
            <Link
              className="bg-c427ed1 hover:opacity-80 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
              to={`/${pageConstant.EMAIL_INVITATION}`}
              type="submit"
            >
              クライアント新規登録
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default SubHeader;
