import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const ProfitAndLossPlanBody = ({
  headers,
  title,
  states,
  url,
  rows,
  descriptions,
  isEven,
  isC,
  field,
  unit,
}: {
  headers: string[];
  title: string[];
  states: DocumentData;
  url: string;
  descriptions: DocumentData;
  // 背景その2
  isEven?: boolean;
  // 下に中項目が続く場合
  isC?: boolean;
  rows?: DocumentData[];
  field?: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    states?.map((state: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} p-4px left-0 bg-white before:border-l ${
        isEven ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
      } ${!isC && states.length === rowIndex + 1 && "before:border-b"} `;

      const classCol2 = `${beforeWrapper} p-4px left-80px  bg-white before:border-l before:border-l-c3333334d before:border-b ${
        states.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol3 = `${beforeWrapper} p-4px left-160px bg-white before:border-r before:border-r-c3333334d before:border-b ${
        states.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"} datasheet-masktitle`;

      const classCol4 = `${borderSettings} p-4px before:border-l before:border-l-c3333334d before:border-b ${
        states.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      } text-right ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol5 = `${borderSettings} p-4px before:border-x  before:border-l-c3333334d before:border-b ${
        states.length !== rowIndex + 1 ? "before:border-b-c3333334d" : ""
      } text-left h-50px ${
        rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: "",
        readOnly: true,
        className: classCol1,
      });

      gridRow.push(
        {
          value: (
            <div
              className={`absolute top-20px left-4px whitespace-nowrap top_absolute`}
            >
              {title[rowIndex]}
            </div>
          ),
          readOnly: true,
          className: classCol2,
        },
        {
          value: "",
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      headers.map((header: any, tdIndex: number) => {
        gridRow.push({
          value:
            tdIndex < 2 ? "" : ShowTableData(Number(state[tdIndex - 2]) / unit),
          expr: tdIndex < 2 ? "" : Number(state[tdIndex - 2] / unit),
          readOnly: true,
          row: rows && rows[rowIndex],
          url: field,
          key: header.period,
          className: `${classCol4} before:border-l-c3333334d`,
          colSpan: 1,
        });
      });

      gridRow.push({
        value: descriptions[`${url}_${field}_${rowIndex}`],
        expr: descriptions[`${url}_${field}_${rowIndex}`],
        readOnly: false,
        className: classCol5,
        url: url,
        field: `${url}_${field}_${rowIndex}`,
        key: "descriptions_calc",
        colSpan: 1,
        row: descriptions,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
