import { DocumentData } from "firebase/firestore";
import { CellData } from "../../types";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[] | null;
};

export const totalCalc = ({ headers, state, field }: Props) => {
  const total: number[] = [];

  headers?.map((header: DocumentData) => {
    const headerKey =
      headers[0].results === "fixed" ? headers[0].results : header.period;
    if (headerKey === "total") {
      return;
    }

    // 合計を計算
    const data =
      state !== null || (state !== null && state[0])
        ? state.reduce(function (sum, data) {
            const cells: CellData = data[field];

            const item = cells && cells[headerKey] ? cells[headerKey] : 0;

            return !cells || (cells && cells.fixed && cells.fixed === null)
              ? sum
              : sum + item;
          }, 0)
        : 0;

    isNaN(data) ? total.push(0) : total.push(data);
  });

  return total;
};
