import { DocumentData } from "firebase/firestore";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
};

export const latestData = ({ headers, state, field }: Props) => {
  const totalData: number[] = [];
  state.map((byItem) => {
    const lastTerm = headers[headers.length - 1].period;

    const total = byItem[field][lastTerm];

    totalData.push(total ? total : 0);
  });

  return totalData;
};
