import { DocumentData } from "firebase/firestore";
import {
  add,
  dotDivide,
  subtract,
  sum,
  dotMultiply,
  divide,
  cumsum,
} from "mathjs";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import {
  arrayRatioCalcWithoutRound,
  calcByItemForPlansInputMonthlyExternalInjection,
  calcByItemForPlansInputMonthlyLaborCosts,
  netSalesCalcByItemForPlansInputMonthly,
  ratioCalcForPlansInputMonthly,
  totalCalc,
  totalCalcByItem,
  totalCalcForBudgetControlBasis,
  totalRatioCalcForPlansInputMonthly,
} from "../../utils/calc";
import { calcByItemForPlansInputMonthly } from "../../utils/calc/calcByItemForPlansInputMonthly";
import { totalCalcByItemForPlansInputMonthly } from "../../utils/calc/totalCalcByItemForPlansInputMonthly";

export const usePlansInputMonthlyInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    depreciation_cost_of_cost_of_sales,
    cost_of_sales_except_beginning_and_end_data,
    interest_expenses,
    non_cash_out,
  } = useSubjectItemsInitialData();

  const resultsField = pageConstant.PLANS_INPUT_MONTHLY;
  const plansField = pageConstant.PLANS_INPUT;
  const previousField = pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD;

  const totalCalcByItemMemo = useCallback(
    ({ field, state }: { field: string; state: DocumentData[] }) =>
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers]
  );

  const totalCalcMemo = useCallback(
    ({ field, state }: { field: string; state: DocumentData[] }) =>
      totalCalc({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers]
  );

  try {
    // ゼロの配列
    const zeroArray: number[] = [];
    headers[previousField].map(() => {
      zeroArray.push(0);
    });

    // 売上高合計
    const netSalesTotalByItemCurrentTerm = totalCalcByItemMemo({
      field: resultsField,
      state: net_sales,
    }) as number[];

    const netSalesTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly({
      headers: headers[plansField],
      field: plansField,
      state: net_sales,
    }) as number[];
    const netSalesTotalByItemRemining = subtract(
      netSalesTotalByItemPlansInput,
      netSalesTotalByItemCurrentTerm
    ) as number[];
    // 月別売上比率
    const netSalesTotalByItemPreviousRatio = ratioCalcForPlansInputMonthly({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
    });
    const netSalesPreviousTotal = totalCalcMemo({
      field: previousField,
      state: net_sales,
    });

    // 売上高合計構成比率
    const netSalesTotalPreviousRatio: number[] =
      totalRatioCalcForPlansInputMonthly(netSalesPreviousTotal);

    const netSalesTotalByItemPlansInputMonthly =
      netSalesCalcByItemForPlansInputMonthly({
        total: netSalesTotalByItemRemining,
        ratio: netSalesTotalByItemPreviousRatio.ratioData,
      });
    const netSalesTotalByItemPlansInputYearly = subtract(
      netSalesTotalByItemPlansInput,
      netSalesTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const netSalesRatio = dotDivide(
      netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      sum(netSalesTotalByItemPlansInput) as number
    ) as number[];
    const netSalesTotalByItemCurrentTermTotal = sum(
      netSalesTotalByItemCurrentTerm
    ) as number;
    const netSalesTotalByItemPlansInputTotal = sum(
      netSalesTotalByItemPlansInput
    ) as number;
    const netSalesTotalByItemCurrentTermRatio = divide(
      sum(netSalesTotalByItemCurrentTermTotal) as number,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const netSalesTotalByItemPlansInputRatio = divide(
      netSalesTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const netSalesTotalByItemReminingTotal = sum(
      netSalesTotalByItemRemining
    ) as number;
    const netSalesTotalByItemReminingRatio = divide(
      netSalesTotalByItemReminingTotal,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const netSalesTotalByItemPlansInputYearlyTotal = sum(
      netSalesTotalByItemPlansInputYearly
    ) as number;

    const netSalesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: netSalesTotalByItemPlansInputMonthly.byItem,
      plansTotal: netSalesTotalByItemPlansInput,
      plansMonthlyTotal: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum: netSalesTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: net_sales,
    });

    const netSalesCurrentMonthlyRatio = dotDivide(
      netSalesForBudgetControl.currentMonthlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number[];

    const netSalesCurrentYearlyRatio = divide(
      netSalesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    // 前期年間
    const netSalesPreviousMonthlyRatio = dotDivide(
      netSalesForBudgetControl.previousMonthlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number[];

    const netSalesPreviousYearlyRatio = divide(
      netSalesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    // 累計用
    const netSalesRatioCumsum = dotDivide(
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      sum(netSalesTotalByItemPlansInput) as number
    ) as number[];

    const netSalesCurrentMonthlyRatioCumsum = dotDivide(
      netSalesForBudgetControl.currentMonthlyTotalCumsum,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number[];

    const netSalesPreviousMonthlyRatioCumsum = dotDivide(
      netSalesForBudgetControl.previousMonthlyTotalCumsum,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number[];

    const netSalesTotal = {
      current_term: netSalesTotalByItemCurrentTerm,
      current_term_total: netSalesTotalByItemCurrentTermTotal,
      current_term_ratio: netSalesTotalByItemCurrentTermRatio,
      plans_input: netSalesTotalByItemPlansInput,
      plans_input_total: netSalesTotalByItemPlansInputTotal,
      plans_input_ratio: netSalesTotalByItemPlansInputRatio,
      remining: netSalesTotalByItemRemining,
      remining_total: netSalesTotalByItemReminingTotal,
      remining_ratio: netSalesTotalByItemReminingRatio,
      by_item: netSalesTotalByItemPlansInputMonthly.byItem,
      monthly_total: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_ratio: netSalesRatio,
      monthly_total_ratio: sum(netSalesRatio) as number,
      monthly_total_by_item:
        netSalesTotalByItemPlansInputMonthly.monthlyTotalByItem,
      yearly: netSalesTotalByItemPlansInputYearly,
      yearly_total: netSalesTotalByItemPlansInputYearlyTotal,
      current_by_item: netSalesForBudgetControl.currentByItem,
      current_by_item_total: netSalesForBudgetControl.currentByItemTotal,
      current_monthly_total: netSalesForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: netSalesCurrentMonthlyRatio,
      current_yearly_total: netSalesForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: netSalesCurrentYearlyRatio,
      previous_by_item: netSalesForBudgetControl.previousByItem,
      previous_by_item_total: netSalesForBudgetControl.previousByItemTotal,
      previous_monthly_total: netSalesForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: netSalesPreviousMonthlyRatio,
      previous_yearly_total: netSalesForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: netSalesPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        netSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        netSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        netSalesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio: null,
      current_and_previous_difference_yearly_total_ratio:
        netSalesForBudgetControl.currentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        netSalesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        netSalesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        netSalesForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        netSalesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        netSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        netSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        netSalesForBudgetControl.currentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        netSalesForBudgetControl.currentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: netSalesTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: netSalesRatioCumsum,
      current_by_item_cumsum: netSalesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        netSalesForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: netSalesCurrentMonthlyRatioCumsum,
      previous_by_item_cumsum: netSalesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        netSalesForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: netSalesPreviousMonthlyRatioCumsum,
      current_and_previous_difference_by_item_cumsum:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        netSalesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        netSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        netSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        netSalesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        netSalesForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        netSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        netSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    const netSalesPreviousRatioCurrentTerm = dotDivide(
      netSalesTotalByItemCurrentTerm,
      netSalesTotalByItemPlansInput
    );
    const netSalesPreviousRatioCurrentTermTotal = dotDivide(
      netSalesTotalByItemCurrentTermTotal,
      netSalesTotalByItemPlansInputTotal
    );

    // 月別売上比率
    const netSalesPreviousRatio = {
      current_term: netSalesPreviousRatioCurrentTerm as number[],
      current_term_total: netSalesPreviousRatioCurrentTermTotal as number,
      by_item_ratio: netSalesTotalByItemPreviousRatio.ratioData as number[][],
      by_item_ratio_total:
        netSalesTotalByItemPreviousRatio.totalRatioData as number[],
      monthly_ratio: netSalesTotalPreviousRatio as number[],
      monthly_total_ratio: sum(netSalesTotalPreviousRatio) as number,
    };

    // 売上原価合計
    const costOfSalesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales,
    });
    costOfSalesTotalByItemCurrentTerm[0] = 0;
    costOfSalesTotalByItemCurrentTerm[
      costOfSalesTotalByItemCurrentTerm.length - 1
    ] = 0;

    // 計画（年次）の当期商品仕入れ高
    const netSalesCostRatioFunc = () => {
      const costRatioArray: number[][] = [];
      net_sales.map((netSale) => {
        const costRatioSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input_cost_ratio &&
            netSale.plans_input_cost_ratio[header.period] &&
            netSale.plans_input_cost_ratio[header.period] !== null
              ? netSale.plans_input_cost_ratio[header.period]
              : 0;

          costRatioSingleArray.push(data);
        });

        costRatioArray.push(costRatioSingleArray);
      });
      return costRatioArray;
    };
    const netSalesCostRatio = netSalesCostRatioFunc();

    const NetSalesSinglesFunc = () => {
      const netSalesArray: number[][] = [];
      net_sales.map((netSale) => {
        const netSalesSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input &&
            netSale.plans_input[header.period] &&
            netSale.plans_input[header.period] !== null
              ? netSale.plans_input[header.period]
              : 0;
          netSalesSingleArray.push(data);
        });

        netSalesArray.push(netSalesSingleArray);
      });
      return netSalesArray;
    };
    const netSalesSingles = NetSalesSinglesFunc();

    const netSalesMultipleCost =
      netSalesCostRatio[0][0] !== null
        ? (divide(
            dotMultiply(netSalesCostRatio, netSalesSingles),
            100
          ) as number[][])
        : null;

    const costOfPurchasedGoodsFunc = () => {
      let array: number[] = [];
      netSalesMultipleCost &&
        netSalesMultipleCost !== null &&
        netSalesMultipleCost.map((data, index) => {
          index === 0 ? (array = data) : (array = add(data, array));
        });
      return array;
    };

    const costOfSalesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: cost_of_sales,
      });
    costOfSalesTotalByItemPlansInput[0] = 0;
    costOfSalesTotalByItemPlansInput[1] = costOfPurchasedGoodsFunc()[0]
      ? costOfPurchasedGoodsFunc()[0]
      : 0;
    costOfSalesTotalByItemPlansInput[
      costOfSalesTotalByItemPlansInput.length - 1
    ] = 0;

    const costOfSalesTotalByItemRemining = subtract(
      costOfSalesTotalByItemPlansInput,
      costOfSalesTotalByItemCurrentTerm
    );

    const costOfSalesByItem = dotMultiply(
      sum(costOfSalesTotalByItemRemining),
      dotDivide(
        netSalesTotalByItemPlansInputMonthly.monthlyTotal,
        sum(netSalesTotalByItemRemining)
      )
    ) as number[];

    // 月間売上高合計/売上高残総合計
    const costOfSalesDenominator = dotDivide(
      netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      netSalesTotalByItemReminingTotal
    ) as number[];

    // 売上原価、個別の値 残個別合計x(月間売上高合計/売上高残総合計)
    const costOfSalesPlanByItem: number[][] = [];
    const costOfSalesPlanByItemCumsum: number[][] = [];
    const costOfSalesMonthlyTotalByItem: number[] = [];
    costOfSalesTotalByItemRemining.map((totalData, index) => {
      if (index === 0 || costOfSalesTotalByItemRemining.length === index + 1) {
        costOfSalesPlanByItem.push(zeroArray);
        costOfSalesMonthlyTotalByItem.push(0);
        costOfSalesPlanByItemCumsum.push(zeroArray);
      } else {
        const costOfSalesPlanByItemData = dotMultiply(
          totalData,
          costOfSalesDenominator
        ) as number[];
        costOfSalesPlanByItem.push(costOfSalesPlanByItemData);
        costOfSalesPlanByItemCumsum.push(
          cumsum(dotMultiply(totalData, costOfSalesDenominator)) as number[]
        );

        costOfSalesMonthlyTotalByItem.push(
          sum(costOfSalesPlanByItemData) as number
        );
      }
    });

    const costOfSalesTotalByItemPlansInputYearly = subtract(
      costOfSalesTotalByItemPlansInput,
      costOfSalesMonthlyTotalByItem
    );

    costOfSalesTotalByItemPlansInputYearly[0] = 0;
    costOfSalesTotalByItemPlansInputYearly[
      costOfSalesTotalByItemPlansInputYearly.length - 1
    ] = 0;
    const costOfSalesRatio: number[] = arrayRatioCalcWithoutRound({
      numerator: costOfSalesByItem,
      denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
    }) as number[];

    const costOfSalesTotalByItemCurrentTermTotal = sum(
      costOfSalesTotalByItemCurrentTerm
    );
    const costOfSalesTotalByItemCurrentTermRatio = divide(
      costOfSalesTotalByItemCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const costOfSalesTotalByItemPlansInputTotal = sum(
      costOfSalesTotalByItemPlansInput
    ) as number;

    const costOfSalesTotalByItemPlansInputRatio = divide(
      sum(costOfSalesTotalByItemPlansInputTotal) as number,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const costOfSalesTotalByItemReminingTotal = sum(
      costOfSalesTotalByItemRemining
    );
    const costOfSalesTotalByItemReminingRatio = divide(
      sum(costOfSalesTotalByItemReminingTotal),
      netSalesTotalByItemReminingTotal
    );
    const costOfSalesTotalByItemMonthlyTotalRatio = divide(
      sum(costOfSalesByItem),
      sum(netSalesTotalByItemPlansInputMonthly.monthlyTotalByItem)
    );

    const costOfSalesPlansMonthlyTotalCumsum = cumsum(
      costOfSalesByItem
    ) as number[];

    const costOfSalesTotalByItemPlansInputYearlyTotal = sum(
      costOfSalesTotalByItemPlansInputYearly
    );

    const costOfSalesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: costOfSalesPlanByItem,
      plansTotal: costOfSalesTotalByItemPlansInput,
      plansMonthlyTotal: costOfSalesByItem,
      plansByItemCumsum: costOfSalesPlanByItemCumsum,
      plansMonthlyTotalCumsum: costOfSalesPlansMonthlyTotalCumsum,
      states: cost_of_sales,
      isCostOfSales: true,
    });

    const costOfSalesCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            costOfSalesForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : costOfSalesForBudgetControl.currentMonthlyTotal;

    const costOfSalesPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            costOfSalesForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : costOfSalesForBudgetControl.currentMonthlyTotal;

    const costOfSalesCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      costOfSalesCurrentMonthlyRatio,
      costOfSalesPreviousMonthlyRatio
    ) as number[];

    const costOfSalesCurrentAndPlanDifferenceMonthlyRatio: number[] = subtract(
      costOfSalesCurrentMonthlyRatio,
      costOfSalesTotalByItemMonthlyTotalRatio
    ) as number[];

    const costOfSalesCurrentYearlyRatio = divide(
      costOfSalesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const costOfSalesPreviousYearlyRatio = divide(
      costOfSalesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const costOfSalesCurrentAndPreviousDifferenceYearlyRatio = subtract(
      costOfSalesCurrentYearlyRatio,
      costOfSalesPreviousYearlyRatio
    ) as number;

    const costOfSalesCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      costOfSalesForBudgetControl.currentYearlyTotal,
      costOfSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const costOfSalesCurrentAndPlanDifferenceYearlyTotal = subtract(
      costOfSalesForBudgetControl.currentYearlyTotal,
      costOfSalesTotalByItemPlansInputTotal
    ) as number;

    const costOfSalesCurrentAndPlanDifferenceYearlyRatio = subtract(
      costOfSalesCurrentYearlyRatio,
      costOfSalesTotalByItemPlansInputRatio
    ) as number;

    const costOfSalesCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      costOfSalesForBudgetControl.currentYearlyTotal as number,
      costOfSalesTotalByItemPlansInputTotal
    ) as number;

    // 累計用
    const costOfSalesRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            costOfSalesPlansMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : costOfSalesPlansMonthlyTotalCumsum;

    const costOfSalesCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            costOfSalesForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : costOfSalesForBudgetControl.currentMonthlyTotalCumsum;

    const costOfSalesPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            costOfSalesForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : costOfSalesForBudgetControl.previousMonthlyTotalCumsum;

    const costOfSalesCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      costOfSalesCurrentMonthlyRatioCumsum,
      costOfSalesPreviousMonthlyRatioCumsum
    ) as number[];

    const costOfSalesCurrentAndPlanDifferenceMonthlyRatioCumsum =
      costOfSalesRatioCumsum.length !== 0
        ? (subtract(
            costOfSalesCurrentMonthlyRatioCumsum,
            costOfSalesRatioCumsum
          ) as number[])
        : costOfSalesCurrentMonthlyRatioCumsum;

    // 売上原価（資金繰り予定表で使用）
    const paymentOnPurchasePreviousTotal = totalCalc({
      headers: headers[previousField],
      field: previousField,
      state: cost_of_sales_except_beginning_and_end_data,
    });
    const paymentOnPurchaseCurrentTotal = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales_except_beginning_and_end_data,
    });

    const costOfSalesTotal = {
      current_term: costOfSalesTotalByItemCurrentTerm,
      current_term_total: costOfSalesTotalByItemCurrentTermTotal,
      current_term_ratio: costOfSalesTotalByItemCurrentTermRatio,
      plans_input: costOfSalesTotalByItemPlansInput,
      plans_input_total: costOfSalesTotalByItemPlansInputTotal,
      plans_input_ratio: costOfSalesTotalByItemPlansInputRatio,
      remining: costOfSalesTotalByItemRemining,
      remining_total: costOfSalesTotalByItemReminingTotal,
      remining_ratio: costOfSalesTotalByItemReminingRatio,
      by_item: costOfSalesPlanByItem,
      monthly_total: costOfSalesByItem,
      monthly_ratio: costOfSalesRatio,
      monthly_total_ratio: costOfSalesTotalByItemMonthlyTotalRatio,
      monthly_total_by_item: costOfSalesMonthlyTotalByItem,
      yearly: costOfSalesTotalByItemPlansInputYearly,
      yearly_total: costOfSalesTotalByItemPlansInputYearlyTotal,
      current_by_item: costOfSalesForBudgetControl.currentByItem,
      current_by_item_total: costOfSalesForBudgetControl.currentByItemTotal,
      current_monthly_total: costOfSalesForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: costOfSalesCurrentMonthlyRatio,
      current_yearly_total: costOfSalesForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: costOfSalesCurrentYearlyRatio,
      previous_by_item: costOfSalesForBudgetControl.previousByItem,
      previous_by_item_total: costOfSalesForBudgetControl.previousByItemTotal,
      previous_monthly_total: costOfSalesForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: costOfSalesPreviousMonthlyRatio,
      previous_yearly_total: costOfSalesForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: costOfSalesPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        costOfSalesCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        costOfSalesCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        costOfSalesCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        costOfSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        costOfSalesCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        costOfSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        costOfSalesCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        costOfSalesCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        costOfSalesCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: costOfSalesPlanByItemCumsum,
      plan_monthly_total_cumsum: costOfSalesPlansMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: costOfSalesRatioCumsum,
      current_by_item_cumsum: costOfSalesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        costOfSalesForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: costOfSalesCurrentMonthlyRatioCumsum,
      previous_by_item_cumsum: costOfSalesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        costOfSalesForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: costOfSalesPreviousMonthlyRatioCumsum,
      current_and_previous_difference_by_item_cumsum:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        costOfSalesCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        costOfSalesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        costOfSalesForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        costOfSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        costOfSalesCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        costOfSalesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
      // 資金繰り予定表用
      payment_on_purchase_previous_total: paymentOnPurchasePreviousTotal,
      payment_on_purchase_current_total: paymentOnPurchaseCurrentTotal,
    };

    // 売上総利益
    const grossMarginMonthlyTotal = subtract(
      netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      costOfSalesByItem
    ) as number[];
    const grossMarginMonthlyRatio: number[] = arrayRatioCalcWithoutRound({
      numerator: grossMarginMonthlyTotal,
      denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
    }) as number[];
    const grossMarginCurrentTermTotal = subtract(
      netSalesTotalByItemCurrentTermTotal,
      costOfSalesTotalByItemCurrentTermTotal
    );
    const grossMarginCurrentTermRatio = divide(
      grossMarginCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const grossMarginPlansInputTotal = subtract(
      netSalesTotalByItemPlansInputTotal,
      costOfSalesTotalByItemPlansInputTotal
    );
    const grossMarginPlansInputRatio = divide(
      grossMarginPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const grossMarginReminingTotal = subtract(
      grossMarginPlansInputTotal,
      grossMarginCurrentTermTotal
    );

    const grossMarginReminingRatio =
      netSalesTotalByItemRemining.length !== 0
        ? (divide(
            grossMarginReminingTotal,
            sum(netSalesTotalByItemRemining)
          ) as number)
        : grossMarginReminingTotal;
    const grossMarginYearly = subtract(
      grossMarginPlansInputTotal,
      sum(grossMarginReminingTotal)
    ) as number;
    const grossMarginMonthlyTotalRatio = divide(
      sum(grossMarginMonthlyTotal),
      sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
    ) as number;

    const grossMarginYearlyTotal = sum(grossMarginYearly);

    const grossMarginCurrentMonthlyTotal =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (subtract(
            netSalesForBudgetControl.currentMonthlyTotal,
            costOfSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : zeroArray;

    const grossMarginCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            grossMarginCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : zeroArray;

    const grossMarginPreviousMonthlyTotal =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (subtract(
            netSalesForBudgetControl.previousMonthlyTotal,
            costOfSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : zeroArray;
    const grossMarginPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            grossMarginPreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : zeroArray;

    const grossMarginCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      grossMarginCurrentMonthlyTotal,
      grossMarginPreviousMonthlyTotal
    ) as number[];
    const grossMarginCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      grossMarginCurrentMonthlyRatio,
      grossMarginPreviousMonthlyRatio
    ) as number[];
    const grossMarginCurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      grossMarginCurrentMonthlyTotal,
      grossMarginPreviousMonthlyTotal
    ) as number[];

    const grossMarginCurrentAndPlanDifferenceMonthlyTotal =
      grossMarginMonthlyTotal.length !== 0
        ? (subtract(
            grossMarginCurrentMonthlyTotal,
            grossMarginMonthlyTotal
          ) as number[])
        : grossMarginCurrentMonthlyTotal;
    const grossMarginCurrentAndPlanDifferenceMonthlyRatio =
      grossMarginMonthlyRatio.length !== 0
        ? (subtract(
            grossMarginCurrentMonthlyRatio,
            grossMarginMonthlyRatio
          ) as number[])
        : grossMarginCurrentMonthlyRatio;

    const grossMarginCurrentAndPlanDifferenceMonthlyTotalRatio =
      grossMarginMonthlyTotal.length !== 0
        ? (dotDivide(
            grossMarginCurrentMonthlyTotal,
            grossMarginMonthlyTotal
          ) as number[])
        : grossMarginCurrentMonthlyTotal;

    const grossMarginCurrentYearlyTotal = subtract(
      netSalesForBudgetControl.currentYearlyTotal,
      costOfSalesForBudgetControl.currentYearlyTotal
    ) as number;
    const grossMarginCurrentYearlyRatio = dotDivide(
      grossMarginCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const grossMarginPreviousYearlyTotal = subtract(
      netSalesForBudgetControl.previousYearlyTotal,
      costOfSalesForBudgetControl.previousYearlyTotal
    ) as number;
    const grossMarginPreviousYearlyRatio = dotDivide(
      costOfSalesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const grossMarginCurrentAndPreviousDifferenceYearlyTotal = subtract(
      grossMarginCurrentYearlyTotal,
      grossMarginPreviousYearlyTotal
    ) as number;
    const grossMarginCurrentAndPreviousDifferenceYearlyRatio = subtract(
      grossMarginCurrentYearlyRatio,
      grossMarginPreviousYearlyRatio
    ) as number;

    const grossMarginCurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      grossMarginCurrentYearlyTotal,
      grossMarginPreviousYearlyTotal
    ) as number;

    const grossMarginCurrentAndPlanDifferenceYearlyTotal = subtract(
      grossMarginCurrentYearlyTotal,
      grossMarginPlansInputTotal
    ) as number;
    const grossMarginCurrentAndPlanDifferenceYearlyRatio = subtract(
      grossMarginCurrentYearlyRatio,
      grossMarginPlansInputRatio
    ) as number;

    const grossMarginCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      grossMarginCurrentYearlyTotal,
      grossMarginPlansInputTotal
    ) as number;

    // 累計用
    const grossMarginPlanMonthlyTotalCumsum = cumsum(
      grossMarginMonthlyTotal
    ) as number[];
    const grossMarginPlanMonthlyRatioCumsum: number[] = dotDivide(
      grossMarginPlanMonthlyTotalCumsum,
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
    ) as number[];

    const grossMarginCurrentMonthlyTotalCumsum = cumsum(
      grossMarginCurrentMonthlyTotal
    ) as number[];

    const grossMarginCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            grossMarginCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : grossMarginCurrentMonthlyTotalCumsum;

    const grossMarginPreviousMonthlyTotalCumsum = cumsum(
      grossMarginPreviousMonthlyTotal
    ) as number[];
    const grossMarginPreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            grossMarginPreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : grossMarginPreviousMonthlyTotalCumsum;

    const grossMarginCurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      grossMarginCurrentMonthlyTotalCumsum,
      grossMarginPreviousMonthlyTotalCumsum
    ) as number[];
    const grossMarginCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      grossMarginCurrentMonthlyRatioCumsum,
      grossMarginPreviousMonthlyRatioCumsum
    ) as number[];
    const grossMarginCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        grossMarginCurrentMonthlyTotalCumsum,
        grossMarginPreviousMonthlyTotalCumsum
      ) as number[];

    const grossMarginCurrentAndPlanDifferenceMonthlyTotalCumsum =
      grossMarginPlanMonthlyTotalCumsum.length !== 0
        ? (subtract(
            grossMarginCurrentMonthlyTotalCumsum,
            grossMarginPlanMonthlyTotalCumsum
          ) as number[])
        : grossMarginCurrentMonthlyTotalCumsum;
    const grossMarginCurrentAndPlanDifferenceMonthlyRatioCumsum =
      grossMarginPlanMonthlyRatioCumsum.length !== 0
        ? (subtract(
            grossMarginCurrentMonthlyRatioCumsum,
            grossMarginPlanMonthlyRatioCumsum
          ) as number[])
        : grossMarginCurrentMonthlyRatioCumsum;

    const grossMarginCurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      grossMarginPlanMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            grossMarginCurrentMonthlyTotalCumsum,
            grossMarginPlanMonthlyTotalCumsum
          ) as number[])
        : grossMarginCurrentMonthlyTotalCumsum;

    const grossMargin = {
      current_term_total: grossMarginCurrentTermTotal,
      current_term_ratio: grossMarginCurrentTermRatio,
      plans_input_total: grossMarginPlansInputTotal,
      plans_input_ratio: grossMarginPlansInputRatio,
      remining_total: grossMarginReminingTotal,
      remining_ratio: grossMarginReminingRatio,
      monthly_total: grossMarginMonthlyTotal,
      monthly_ratio: grossMarginMonthlyRatio,
      monthly_total_ratio: grossMarginMonthlyTotalRatio,
      yearly: grossMarginYearly,
      yearly_total: grossMarginYearlyTotal,
      current_monthly_total: grossMarginCurrentMonthlyTotal,
      current_monthly_ratio: grossMarginCurrentMonthlyRatio,
      current_yearly_total: grossMarginCurrentYearlyTotal,
      current_yearly_ratio: grossMarginCurrentYearlyRatio,
      previous_monthly_total: grossMarginPreviousMonthlyTotal,
      previous_monthly_ratio: grossMarginPreviousMonthlyRatio,
      previous_yearly_total: grossMarginPreviousYearlyTotal,
      previous_yearly_ratio: grossMarginPreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        grossMarginCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        grossMarginCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        grossMarginCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        grossMarginCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        grossMarginCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        grossMarginCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        grossMarginCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        grossMarginCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        grossMarginCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        grossMarginCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        grossMarginCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        grossMarginCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: grossMarginPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: grossMarginPlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: grossMarginCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: grossMarginCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: grossMarginPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: grossMarginPreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        grossMarginCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        grossMarginCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        grossMarginCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        grossMarginCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        grossMarginCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        grossMarginCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 人件費合計
    const personalCostTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: personal_cost,
    });
    const personalCostTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: personal_cost,
      });
    const personalCostTotalByItemRemining = subtract(
      personalCostTotalByItemPlansInput,
      personalCostTotalByItemCurrentTerm
    );
    const personalCostTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: personal_cost,
        ratio: netSalesTotalPreviousRatio,
        remins: personalCostTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const personalCostTotalByItemPlansInputYearly = subtract(
      personalCostTotalByItemPlansInput,
      personalCostTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const personalCostRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: personalCostTotalByItemPlansInputMonthly.monthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : personalCostTotalByItemPlansInputMonthly.monthlyTotal;
    const personalCostTotalByItemCurrentTermTotal = sum(
      personalCostTotalByItemCurrentTerm
    );
    const personalCostTotalByItemCurrentTermRatio = divide(
      personalCostTotalByItemCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const personalCostTotalByItemPlansInputTotal = sum(
      personalCostTotalByItemPlansInput
    ) as number;
    const personalCostTotalByItemPlansInputRatio = divide(
      personalCostTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const personalCostTotalByItemReminingTotal = subtract(
      personalCostTotalByItemPlansInputTotal,
      personalCostTotalByItemCurrentTermTotal
    );
    const personalCostTotalByItemReminingRatio = dotDivide(
      personalCostTotalByItemReminingTotal,
      sum(netSalesTotalByItemRemining)
    );
    const personalCostTotalMonthlyTotalRatio = divide(
      sum(personalCostTotalByItemPlansInputMonthly.monthlyTotal),
      sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
    );
    const personalCostTotalByItemPlansInputYearlyTotal = sum(
      personalCostTotalByItemPlansInputYearly
    );

    const personalCostForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: personalCostTotalByItemPlansInputMonthly.byItem,
      plansTotal: personalCostTotalByItemPlansInput,
      plansMonthlyTotal: personalCostTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum: personalCostTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        personalCostTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: personal_cost,
    });

    const personalCostCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            personalCostForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : personalCostForBudgetControl.currentMonthlyTotal;

    const personalCostPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            personalCostForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : personalCostForBudgetControl.previousMonthlyTotal;

    const personalCostCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      personalCostCurrentMonthlyRatio,
      personalCostPreviousMonthlyRatio
    ) as number[];

    const personalCostCurrentAndPlanDifferenceMonthlyRatio: number[] = subtract(
      personalCostCurrentMonthlyRatio,
      personalCostTotalMonthlyTotalRatio
    ) as number[];

    const personalCostCurrentYearlyRatio = divide(
      personalCostForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const personalCostPreviousYearlyRatio = divide(
      personalCostForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const personalCostCurrentAndPreviousDifferenceYearlyRatio = subtract(
      personalCostCurrentYearlyRatio,
      personalCostPreviousYearlyRatio
    ) as number;

    const personalCostCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      personalCostForBudgetControl.currentYearlyTotal,
      personalCostForBudgetControl.previousYearlyTotal
    ) as number;

    const personalCostCurrentAndPlanDifferenceYearlyTotal = subtract(
      personalCostForBudgetControl.currentYearlyTotal,
      personalCostTotalByItemPlansInputTotal
    ) as number;

    const personalCostCurrentAndPlanDifferenceYearlyRatio = subtract(
      personalCostCurrentYearlyRatio,
      personalCostTotalByItemPlansInputRatio
    ) as number;

    const personalCostCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      personalCostForBudgetControl.currentYearlyTotal,
      personalCostTotalByItemPlansInputTotal
    ) as number;

    // 累計用
    const personalCostRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            personalCostTotalByItemPlansInputMonthly.monthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : personalCostTotalByItemPlansInputMonthly.monthlyTotalCumsum;

    const personalCostCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            personalCostForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : personalCostForBudgetControl.currentMonthlyTotalCumsum;

    const personalCostPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            personalCostForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : personalCostForBudgetControl.previousMonthlyTotalCumsum;

    const personalCostCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      personalCostCurrentMonthlyRatioCumsum,
      personalCostPreviousMonthlyRatioCumsum
    ) as number[];

    const personalCostCurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      personalCostCurrentMonthlyRatioCumsum,
      personalCostRatioCumsum
    ) as number[];

    const personalCostTotal = {
      current_term: personalCostTotalByItemCurrentTerm,
      current_term_total: personalCostTotalByItemCurrentTermTotal,
      current_term_ratio: personalCostTotalByItemCurrentTermRatio,
      plans_input: personalCostTotalByItemPlansInput,
      plans_input_total: personalCostTotalByItemPlansInputTotal,
      plans_input_ratio: personalCostTotalByItemPlansInputRatio,
      remining: personalCostTotalByItemRemining,
      remining_total: personalCostTotalByItemReminingTotal,
      remining_ratio: personalCostTotalByItemReminingRatio,
      by_item: personalCostTotalByItemPlansInputMonthly.byItem,
      monthly_total: personalCostTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_ratio: personalCostRatio,
      monthly_total_by_item:
        personalCostTotalByItemPlansInputMonthly.monthlyTotalByItem,
      monthly_total_ratio: personalCostTotalMonthlyTotalRatio,
      yearly: personalCostTotalByItemPlansInputYearly,
      yearly_total: personalCostTotalByItemPlansInputYearlyTotal,
      current_by_item: personalCostForBudgetControl.currentByItem,
      current_by_item_total: personalCostForBudgetControl.currentByItemTotal,
      current_monthly_total: personalCostForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: personalCostCurrentMonthlyRatio,
      current_yearly_total: personalCostForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: personalCostCurrentYearlyRatio,
      previous_by_item: personalCostForBudgetControl.previousByItem,
      previous_by_item_total: personalCostForBudgetControl.previousByItemTotal,
      previous_monthly_total: personalCostForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: personalCostPreviousMonthlyRatio,
      previous_yearly_total: personalCostForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: personalCostPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        personalCostForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        personalCostCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        personalCostForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        personalCostForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        personalCostCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        personalCostCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        personalCostForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        personalCostForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        personalCostForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        personalCostForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        personalCostForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        personalCostCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        personalCostForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        personalCostCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        personalCostCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        personalCostCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum:
        personalCostTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        personalCostTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: personalCostRatioCumsum,
      current_by_item_cumsum: personalCostForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        personalCostForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: personalCostCurrentMonthlyRatioCumsum,
      previous_by_item_cumsum:
        personalCostForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        personalCostForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: personalCostPreviousMonthlyRatioCumsum,
      current_and_previous_difference_by_item_cumsum:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        personalCostForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        personalCostForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        personalCostCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        personalCostForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        personalCostForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        personalCostForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        personalCostForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        personalCostCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        personalCostForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 販売費及び一般管理費合計
    const sellingAndAdministrativeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: selling_and_administrative,
    });
    const sellingAndAdministrativeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: selling_and_administrative,
      });
    const sellingAndAdministrativeTotalByItemRemining = subtract(
      sellingAndAdministrativeTotalByItemPlansInput,
      sellingAndAdministrativeTotalByItemCurrentTerm
    );
    const sellingAndAdministrativeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: selling_and_administrative,
        ratio: netSalesTotalPreviousRatio,
        remins: sellingAndAdministrativeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal = add(
      personalCostTotalByItemPlansInputMonthly.monthlyTotal,
      sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotal
    );

    const sellingAndAdministrativeTotalByItemPlansInputYearly = subtract(
      sellingAndAdministrativeTotalByItemPlansInput,
      sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    sellingAndAdministrativeTotalByItemPlansInputYearly.push(
      ...personalCostTotalByItemPlansInputYearly
    );

    const sellingAndAdministrativeRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator:
              sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal;

    const sellingAndAdministrativeTotalByItemCurrentTermTotal = add(
      personalCostTotalByItemCurrentTermTotal,
      sum(sellingAndAdministrativeTotalByItemCurrentTerm)
    );
    const sellingAndAdministrativeTotalByItemCurrentTermRatio = divide(
      sellingAndAdministrativeTotalByItemCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const sellingAndAdministrativeTotalByItemPlansInputTotal = add(
      personalCostTotalByItemPlansInputTotal,
      sum(sellingAndAdministrativeTotalByItemPlansInput)
    ) as number;

    const sellingAndAdministrativeTotalByItemPlansInputRatio = divide(
      sellingAndAdministrativeTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const sellingAndAdministrativeTotalByItemReminingTotal = subtract(
      sellingAndAdministrativeTotalByItemPlansInputTotal,
      sellingAndAdministrativeTotalByItemCurrentTermTotal
    );
    const sellingAndAdministrativeTotalByItemReminingRatio = dotDivide(
      sellingAndAdministrativeTotalByItemReminingTotal,
      sum(netSalesTotalByItemRemining)
    );
    const sellingAndAdministrativeTotalMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : sum(sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal);
    const sellingAndAdministrativeTotalByItemPlansInputYearlyTotal = sum(
      sellingAndAdministrativeTotalByItemPlansInputYearly
    );

    const sellingAndAdministrativeForBudgetControl =
      totalCalcForBudgetControlBasis({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState: sellingAndAdministrativeTotalByItemPlansInputMonthly.byItem,
        plansTotal: sellingAndAdministrativeTotalByItemPlansInput,
        plansMonthlyTotal:
          sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal,
        plansByItemCumsum:
          sellingAndAdministrativeTotalByItemPlansInputMonthly.byItemCumsum,
        plansMonthlyTotalCumsum:
          sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        states: selling_and_administrative,
      });

    const sellingAndAdministrativeCurrentMonthlyTotal = add(
      personalCostForBudgetControl.currentMonthlyTotal,
      sellingAndAdministrativeForBudgetControl.currentMonthlyTotal
    ) as number[];
    const sellingAndAdministrativeCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            sellingAndAdministrativeCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : sellingAndAdministrativeCurrentMonthlyTotal;

    const sellingAndAdministrativePreviousMonthlyTotal = add(
      personalCostForBudgetControl.previousMonthlyTotal,
      sellingAndAdministrativeForBudgetControl.previousMonthlyTotal
    ) as number[];
    const sellingAndAdministrativePreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            sellingAndAdministrativePreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : sellingAndAdministrativePreviousMonthlyTotal;

    const sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotal =
      subtract(
        sellingAndAdministrativeCurrentMonthlyTotal,
        sellingAndAdministrativePreviousMonthlyTotal
      ) as number[];
    const sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        sellingAndAdministrativeCurrentMonthlyTotal,
        sellingAndAdministrativePreviousMonthlyTotal
      ) as number[];

    const sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotal =
      subtract(
        sellingAndAdministrativeCurrentMonthlyTotal,
        sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal
      ) as number[];
    const sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalRatio: number[] =
      dotDivide(
        sellingAndAdministrativeCurrentMonthlyTotal,
        sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal
      ) as number[];

    const sellingAndAdministrativeCurrentYearlyTotal = add(
      personalCostForBudgetControl.currentYearlyTotal,
      sellingAndAdministrativeForBudgetControl.currentYearlyTotal
    ) as number;
    const sellingAndAdministrativeCurrentYearlyRatio = divide(
      sellingAndAdministrativeCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const sellingAndAdministrativePreviousYearlyTotal = add(
      personalCostForBudgetControl.previousYearlyTotal,
      sellingAndAdministrativeForBudgetControl.previousYearlyTotal
    ) as number;
    const sellingAndAdministrativePreviousYearlyRatio = divide(
      sellingAndAdministrativePreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const sellingAndAdministrativeCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        sellingAndAdministrativeCurrentYearlyTotal,
        sellingAndAdministrativePreviousYearlyTotal
      ) as number;

    const sellingAndAdministrativeCurrentAndPlanDifferenceYearlyTotal =
      subtract(
        sellingAndAdministrativeCurrentYearlyTotal,
        sellingAndAdministrativeTotalByItemPlansInputTotal
      ) as number;

    const sellingAndAdministrativeCurrentAndPlanDifferenceYearlyTotalRatio =
      divide(
        sellingAndAdministrativeCurrentYearlyTotal,
        sellingAndAdministrativeTotalByItemPlansInputTotal
      ) as number;

    // 累計用
    const sellingAndAdministrativePlanMonthlyTotalCumsum = add(
      personalCostTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotalCumsum
    );
    const sellingAndAdministrativeCurrentMonthlyTotalCumsum = add(
      personalCostForBudgetControl.currentMonthlyTotalCumsum,
      sellingAndAdministrativeForBudgetControl.currentMonthlyTotalCumsum
    ) as number[];
    const sellingAndAdministrativeCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            sellingAndAdministrativeCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : sellingAndAdministrativeCurrentMonthlyTotalCumsum;

    const sellingAndAdministrativePreviousMonthlyTotalCumsum = add(
      personalCostForBudgetControl.previousMonthlyTotalCumsum,
      sellingAndAdministrativeForBudgetControl.previousMonthlyTotalCumsum
    ) as number[];
    const sellingAndAdministrativePreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            sellingAndAdministrativePreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : sellingAndAdministrativePreviousMonthlyTotalCumsum;

    const sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        sellingAndAdministrativeCurrentMonthlyTotalCumsum,
        sellingAndAdministrativePreviousMonthlyTotalCumsum
      ) as number[];
    const sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        sellingAndAdministrativeCurrentMonthlyTotalCumsum,
        sellingAndAdministrativePreviousMonthlyTotalCumsum
      ) as number[];

    const sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalCumsum =
      subtract(
        sellingAndAdministrativeCurrentMonthlyTotalCumsum,
        sellingAndAdministrativePlanMonthlyTotalCumsum
      ) as number[];
    const sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum: number[] =
      dotDivide(
        sellingAndAdministrativeCurrentMonthlyTotalCumsum,
        sellingAndAdministrativePlanMonthlyTotalCumsum
      ) as number[];

    const sellingAndAdministrativeRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            sellingAndAdministrativePlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : sellingAndAdministrativePlanMonthlyTotalCumsum;

    const sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyRatioCumsum =
      subtract(
        sellingAndAdministrativeCurrentMonthlyRatioCumsum,
        sellingAndAdministrativePreviousMonthlyRatioCumsum
      ) as number[];

    const sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyRatioCumsum =
      subtract(
        sellingAndAdministrativeCurrentMonthlyRatioCumsum,
        sellingAndAdministrativeRatioCumsum
      ) as number[];

    const sellingAndAdministrativeTotal = {
      current_term: sellingAndAdministrativeTotalByItemCurrentTerm,
      current_term_total: sellingAndAdministrativeTotalByItemCurrentTermTotal,
      current_term_ratio: sellingAndAdministrativeTotalByItemCurrentTermRatio,
      plans_input: sellingAndAdministrativeTotalByItemPlansInput,
      plans_input_total: sellingAndAdministrativeTotalByItemPlansInputTotal,
      plans_input_ratio: sellingAndAdministrativeTotalByItemPlansInputRatio,
      remining: sellingAndAdministrativeTotalByItemRemining,
      remining_total: sellingAndAdministrativeTotalByItemReminingTotal,
      remining_ratio: sellingAndAdministrativeTotalByItemReminingRatio,
      by_item: sellingAndAdministrativeTotalByItemPlansInputMonthly.byItem,
      monthly_total: sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal,
      monthly_ratio: sellingAndAdministrativeRatio,
      monthly_total_by_item:
        sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotalByItem,
      monthly_total_ratio: sellingAndAdministrativeTotalMonthlyTotalRatio,
      yearly: sellingAndAdministrativeTotalByItemPlansInputYearly,
      yearly_total: sellingAndAdministrativeTotalByItemPlansInputYearlyTotal,
      current_by_item: sellingAndAdministrativeForBudgetControl.currentByItem,
      current_by_item_total:
        sellingAndAdministrativeForBudgetControl.currentByItemTotal,
      current_monthly_total: sellingAndAdministrativeCurrentMonthlyTotal,
      current_monthly_ratio: sellingAndAdministrativeCurrentMonthlyRatio,
      current_yearly_total: sellingAndAdministrativeCurrentYearlyTotal,
      current_yearly_ratio: sellingAndAdministrativeCurrentYearlyRatio,
      previous_by_item: sellingAndAdministrativeForBudgetControl.previousByItem,
      previous_by_item_total:
        sellingAndAdministrativeForBudgetControl.previousByItemTotal,
      previous_monthly_total: sellingAndAdministrativePreviousMonthlyTotal,
      previous_monthly_ratio: sellingAndAdministrativePreviousMonthlyRatio,
      previous_yearly_total: sellingAndAdministrativePreviousYearlyTotal,
      previous_yearly_ratio: sellingAndAdministrativePreviousYearlyRatio,
      current_and_previous_difference_by_item:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio: null,
      current_and_previous_difference_yearly_total_ratio:
        sellingAndAdministrativeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        sellingAndAdministrativeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        sellingAndAdministrativeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum:
        sellingAndAdministrativeTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum: sellingAndAdministrativePlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: sellingAndAdministrativeRatioCumsum,
      current_by_item_cumsum:
        sellingAndAdministrativeForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        sellingAndAdministrativeCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum:
        sellingAndAdministrativeCurrentMonthlyRatioCumsum,
      previous_by_item_cumsum:
        sellingAndAdministrativeForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        sellingAndAdministrativePreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum:
        sellingAndAdministrativePreviousMonthlyRatioCumsum,
      current_and_previous_difference_by_item_cumsum:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        sellingAndAdministrativeForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        sellingAndAdministrativeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        sellingAndAdministrativeForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        sellingAndAdministrativeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 営業利益
    const operatingIncomeMonthlyTotal: number[] =
      grossMarginMonthlyTotal.length !== 0
        ? subtract(
            grossMarginMonthlyTotal,
            sellingAndAdministrativeTotalByItemPlansInputMonthlyTotal
          )
        : grossMarginMonthlyTotal;
    const operatingIncomeMonthlyRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: operatingIncomeMonthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : operatingIncomeMonthlyTotal;
    const operatingIncomeCurrentTermTotal = subtract(
      grossMarginCurrentTermTotal,
      sellingAndAdministrativeTotalByItemCurrentTermTotal
    );
    const operatingIncomeCurrentTermRatio = divide(
      operatingIncomeCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const operatingIncomePlansInputTotal = subtract(
      grossMarginPlansInputTotal,
      sellingAndAdministrativeTotalByItemPlansInputTotal
    );
    const operatingIncomePlansInputRatio = divide(
      operatingIncomePlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const operatingIncomeReminingTotal = subtract(
      operatingIncomePlansInputTotal,
      operatingIncomeCurrentTermTotal
    );
    const operatingIncomeReminingRatio = divide(
      operatingIncomeReminingTotal,
      sum(netSalesTotalByItemRemining)
    );
    const operatingIncomeYearly = subtract(
      operatingIncomePlansInputTotal,
      sum(operatingIncomeMonthlyTotal)
    );
    const operatingIncomeMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(operatingIncomeMonthlyTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : sum(operatingIncomeMonthlyTotal);
    const operatingIncomeYearlyTotal = sum(operatingIncomeYearly);

    const operatingIncomeCurrentMonthlyTotal = subtract(
      grossMarginCurrentMonthlyTotal,
      sellingAndAdministrativeCurrentMonthlyTotal
    ) as number[];
    const operatingIncomeCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingIncomeCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : operatingIncomeCurrentMonthlyTotal;

    const operatingIncomePreviousMonthlyTotal = subtract(
      grossMarginPreviousMonthlyTotal,
      sellingAndAdministrativePreviousMonthlyTotal
    ) as number[];
    const operatingIncomePreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingIncomePreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : operatingIncomePreviousMonthlyTotal;

    const operatingIncomeCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      operatingIncomeCurrentMonthlyTotal,
      operatingIncomePreviousMonthlyTotal
    ) as number[];
    const operatingIncomeCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      operatingIncomeCurrentMonthlyRatio,
      operatingIncomePreviousMonthlyRatio
    ) as number[];
    const operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        operatingIncomeCurrentMonthlyTotal,
        operatingIncomePreviousMonthlyTotal
      ) as number[];

    const operatingIncomeCurrentAndPlanDifferenceMonthlyTotal =
      operatingIncomeMonthlyTotal.length !== 0
        ? (subtract(
            operatingIncomeCurrentMonthlyTotal,
            operatingIncomeMonthlyTotal
          ) as number[])
        : operatingIncomeCurrentMonthlyTotal;
    const operatingIncomeCurrentAndPlanDifferenceMonthlyRatio =
      operatingIncomeMonthlyRatio.length !== 0
        ? (subtract(
            operatingIncomeCurrentMonthlyRatio,
            operatingIncomeMonthlyRatio
          ) as number[])
        : operatingIncomeCurrentMonthlyRatio;

    const operatingIncomeCurrentAndPlanDifferenceMonthlyTotalRatio =
      operatingIncomeMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingIncomeCurrentMonthlyTotal,
            operatingIncomeMonthlyTotal
          ) as number[])
        : operatingIncomeCurrentMonthlyTotal;

    const operatingIncomeCurrentYearlyTotal = subtract(
      grossMarginCurrentYearlyTotal,
      sellingAndAdministrativeCurrentYearlyTotal
    ) as number;
    const operatingIncomeCurrentYearlyRatio = dotDivide(
      operatingIncomeCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const operatingIncomePreviousYearlyTotal = subtract(
      grossMarginPreviousYearlyTotal,
      sellingAndAdministrativePreviousYearlyTotal
    ) as number;
    const operatingIncomePreviousYearlyRatio = dotDivide(
      operatingIncomePreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const operatingIncomeCurrentAndPreviousDifferenceYearlyTotal = subtract(
      operatingIncomeCurrentYearlyTotal,
      operatingIncomePreviousYearlyTotal
    ) as number;
    const operatingIncomeCurrentAndPreviousDifferenceYearlyRatio = subtract(
      operatingIncomeCurrentYearlyRatio,
      operatingIncomePreviousYearlyRatio
    ) as number;

    const operatingIncomeCurrentAndPreviousDifferenceYearlyTotalRatio =
      dotDivide(
        operatingIncomeCurrentYearlyTotal,
        operatingIncomePreviousYearlyTotal
      ) as number;

    const operatingIncomeCurrentAndPlanDifferenceYearlyTotal = subtract(
      operatingIncomeCurrentYearlyTotal,
      operatingIncomePlansInputTotal
    ) as number;
    const operatingIncomeCurrentAndPlanDifferenceYearlyRatio = subtract(
      operatingIncomeCurrentYearlyRatio,
      operatingIncomePlansInputRatio
    ) as number;

    const operatingIncomeCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      operatingIncomeCurrentYearlyTotal,
      operatingIncomePlansInputTotal
    ) as number;

    // 累計用
    const operatingIncomePlanMonthlyTotalCumsum = cumsum(
      operatingIncomeMonthlyTotal
    ) as number[];
    const operatingIncomePlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            operatingIncomePlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : operatingIncomePlanMonthlyTotalCumsum;

    const operatingIncomeCurrentMonthlyTotalCumsum = cumsum(
      operatingIncomeCurrentMonthlyTotal
    ) as number[];
    const operatingIncomeCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingIncomeCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : operatingIncomeCurrentMonthlyTotalCumsum;

    const operatingIncomePreviousMonthlyTotalCumsum = cumsum(
      operatingIncomePreviousMonthlyTotal
    ) as number[];
    const operatingIncomePreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingIncomePreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : operatingIncomePreviousMonthlyTotalCumsum;

    const operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        operatingIncomeCurrentMonthlyTotalCumsum,
        operatingIncomePreviousMonthlyTotalCumsum
      ) as number[];
    const operatingIncomeCurrentAndPreviousDifferenceMonthlyRatioCumsum =
      subtract(
        operatingIncomeCurrentMonthlyRatioCumsum,
        operatingIncomePreviousMonthlyRatioCumsum
      ) as number[];
    const operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        operatingIncomeCurrentMonthlyTotalCumsum,
        operatingIncomePreviousMonthlyTotalCumsum
      ) as number[];

    const operatingIncomeCurrentAndPlanDifferenceMonthlyTotalCumsum =
      operatingIncomePlanMonthlyTotalCumsum.length !== 0
        ? (subtract(
            operatingIncomeCurrentMonthlyTotalCumsum,
            operatingIncomePlanMonthlyTotalCumsum
          ) as number[])
        : operatingIncomeCurrentMonthlyTotalCumsum;
    const operatingIncomeCurrentAndPlanDifferenceMonthlyRatioCumsum =
      operatingIncomePlanMonthlyRatioCumsum.length !== 0
        ? (subtract(
            operatingIncomeCurrentMonthlyRatioCumsum,
            operatingIncomePlanMonthlyRatioCumsum
          ) as number[])
        : operatingIncomeCurrentMonthlyRatioCumsum;

    const operatingIncomeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      operatingIncomePlanMonthlyTotalCumsum.length !== 0
        ? (dotDivide(
            operatingIncomeCurrentMonthlyTotalCumsum,
            operatingIncomePlanMonthlyTotalCumsum
          ) as number[])
        : operatingIncomeCurrentMonthlyTotalCumsum;

    const operatingIncome = {
      current_term_total: operatingIncomeCurrentTermTotal,
      current_term_ratio: operatingIncomeCurrentTermRatio,
      plans_input_total: operatingIncomePlansInputTotal,
      plans_input_ratio: operatingIncomePlansInputRatio,
      remining_total: operatingIncomeReminingTotal,
      remining_ratio: operatingIncomeReminingRatio,
      monthly_total: operatingIncomeMonthlyTotal,
      monthly_ratio: operatingIncomeMonthlyRatio,
      monthly_total_ratio: operatingIncomeMonthlyTotalRatio,
      yearly: operatingIncomeYearly,
      yearly_total: operatingIncomeYearlyTotal,
      current_monthly_total: operatingIncomeCurrentMonthlyTotal,
      current_monthly_ratio: operatingIncomeCurrentMonthlyRatio,
      current_yearly_total: operatingIncomeCurrentYearlyTotal,
      current_yearly_ratio: operatingIncomeCurrentYearlyRatio,
      previous_monthly_total: operatingIncomePreviousMonthlyTotal,
      previous_monthly_ratio: operatingIncomePreviousMonthlyRatio,
      previous_yearly_total: operatingIncomePreviousYearlyTotal,
      previous_yearly_ratio: operatingIncomePreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        operatingIncomeCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        operatingIncomeCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        operatingIncomeCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        operatingIncomeCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        operatingIncomeCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        operatingIncomeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        operatingIncomeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        operatingIncomeCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        operatingIncomeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: operatingIncomePlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: operatingIncomePlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: operatingIncomeCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: operatingIncomeCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: operatingIncomePreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: operatingIncomePreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        operatingIncomeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        operatingIncomeCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        operatingIncomeCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        operatingIncomeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 営業外収益合計
    const nonOperatingIncomeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_income,
    });
    const nonOperatingIncomeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: non_operating_income,
      });
    const nonOperatingIncomeTotalByItemRemining = subtract(
      nonOperatingIncomeTotalByItemPlansInput,
      nonOperatingIncomeTotalByItemCurrentTerm
    );
    const nonOperatingIncomeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: non_operating_income,
        ratio: netSalesTotalPreviousRatio,
        remins: nonOperatingIncomeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const nonOperatingIncomeTotalByItemPlansInputYearly = subtract(
      nonOperatingIncomeTotalByItemPlansInput,
      nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const nonOperatingIncomeTotalByItemCurrentTermTotal = sum(
      nonOperatingIncomeTotalByItemCurrentTerm
    );
    const nonOperatingIncomeTotalByItemPlansInputTotal = sum(
      nonOperatingIncomeTotalByItemPlansInput
    ) as number;
    const nonOperatingIncomeTotalByItemReminingTotal = subtract(
      nonOperatingIncomeTotalByItemPlansInputTotal,
      nonOperatingIncomeTotalByItemCurrentTermTotal
    );
    const nonOperatingIncomeTotalByItemPlansInputYearlyTotal = sum(
      nonOperatingIncomeTotalByItemPlansInputYearly
    );

    const nonOperatingIncomeForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: nonOperatingIncomeTotalByItemPlansInputMonthly.byItem,
      plansTotal: nonOperatingIncomeTotalByItemPlansInput,
      plansMonthlyTotal:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum:
        nonOperatingIncomeTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: non_operating_income,
    });

    const nonOperatingIncomeCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingIncomeForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : nonOperatingIncomeForBudgetControl.currentMonthlyTotal;

    const nonOperatingIncomePreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingIncomeForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : nonOperatingIncomeForBudgetControl.previousMonthlyTotal;

    const nonOperatingIncomeCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      nonOperatingIncomeCurrentMonthlyRatio,
      nonOperatingIncomePreviousMonthlyRatio
    ) as number[];

    const nonOperatingIncomeCurrentYearlyRatio = divide(
      nonOperatingIncomeForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const nonOperatingIncomePreviousYearlyRatio = divide(
      nonOperatingIncomeForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const nonOperatingIncomeCurrentAndPreviousDifferenceYearlyRatio = subtract(
      nonOperatingIncomeCurrentYearlyRatio,
      nonOperatingIncomePreviousYearlyRatio
    ) as number;

    const nonOperatingIncomeCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        nonOperatingIncomeForBudgetControl.currentYearlyTotal,
        nonOperatingIncomeForBudgetControl.previousYearlyTotal
      ) as number;

    const nonOperatingIncomeCurrentAndPlanDifferenceYearlyTotal = subtract(
      nonOperatingIncomeForBudgetControl.currentYearlyTotal,
      nonOperatingIncomeTotalByItemPlansInputTotal
    ) as number;

    const nonOperatingIncomeCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      nonOperatingIncomeForBudgetControl.currentYearlyTotal,
      nonOperatingIncomeTotalByItemPlansInputTotal
    ) as number;

    const nonOperatingIncomeTotal = {
      current_term: nonOperatingIncomeTotalByItemCurrentTerm,
      current_term_total: nonOperatingIncomeTotalByItemCurrentTermTotal,
      current_term_ratio: null,
      plans_input: nonOperatingIncomeTotalByItemPlansInput,
      plans_input_total: nonOperatingIncomeTotalByItemPlansInputTotal,
      plans_input_ratio: null,
      remining: nonOperatingIncomeTotalByItemRemining,
      remining_total: nonOperatingIncomeTotalByItemReminingTotal,
      remining_ratio: null,
      by_item: nonOperatingIncomeTotalByItemPlansInputMonthly.byItem,
      monthly_total:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_ratio: null,
      monthly_total_by_item:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotalByItem,
      monthly_total_ratio: null,
      yearly: nonOperatingIncomeTotalByItemPlansInputYearly,
      yearly_total: nonOperatingIncomeTotalByItemPlansInputYearlyTotal,
      current_by_item: nonOperatingIncomeForBudgetControl.currentByItem,
      current_by_item_total:
        nonOperatingIncomeForBudgetControl.currentByItemTotal,
      current_monthly_total:
        nonOperatingIncomeForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: nonOperatingIncomeCurrentMonthlyRatio,
      current_yearly_total:
        nonOperatingIncomeForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: nonOperatingIncomeCurrentYearlyRatio,
      previous_by_item: nonOperatingIncomeForBudgetControl.previousByItem,
      previous_by_item_total:
        nonOperatingIncomeForBudgetControl.previousByItemTotal,
      previous_monthly_total:
        nonOperatingIncomeForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: nonOperatingIncomePreviousMonthlyRatio,
      previous_yearly_total:
        nonOperatingIncomeForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: nonOperatingIncomePreviousYearlyRatio,
      current_and_previous_difference_by_item:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        nonOperatingIncomeCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        nonOperatingIncomeCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        nonOperatingIncomeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        nonOperatingIncomeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        nonOperatingIncomeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum:
        nonOperatingIncomeTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        nonOperatingIncomeForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        nonOperatingIncomeForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        nonOperatingIncomeForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        nonOperatingIncomeForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        nonOperatingIncomeForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 営業外費用合計
    const nonOperatingExpensesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_expenses,
    });
    const nonOperatingExpensesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: non_operating_expenses,
      });
    const nonOperatingExpensesTotalByItemRemining = subtract(
      nonOperatingExpensesTotalByItemPlansInput,
      nonOperatingExpensesTotalByItemCurrentTerm
    );
    const nonOperatingExpensesTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: non_operating_expenses,
        ratio: netSalesTotalPreviousRatio,
        remins: nonOperatingExpensesTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const nonOperatingExpensesTotalByItemPlansInputYearly = subtract(
      nonOperatingExpensesTotalByItemPlansInput,
      nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const nonOperatingExpensesTotalByItemCurrentTermTotal = sum(
      nonOperatingExpensesTotalByItemCurrentTerm
    );
    const nonOperatingExpensesTotalByItemPlansInputTotal = sum(
      nonOperatingExpensesTotalByItemPlansInput
    ) as number;
    const nonOperatingExpensesTotalByItemReminingTotal = subtract(
      nonOperatingExpensesTotalByItemPlansInputTotal,
      nonOperatingExpensesTotalByItemCurrentTermTotal
    );
    const nonOperatingExpensesTotalByItemPlansInputYearlyTotal = sum(
      nonOperatingExpensesTotalByItemPlansInputYearly
    );

    const nonOperatingExpensesForBudgetControl = totalCalcForBudgetControlBasis(
      {
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState: nonOperatingExpensesTotalByItemPlansInputMonthly.byItem,
        plansTotal: nonOperatingExpensesTotalByItemPlansInput,
        plansMonthlyTotal:
          nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotal,
        plansByItemCumsum:
          nonOperatingExpensesTotalByItemPlansInputMonthly.byItemCumsum,
        plansMonthlyTotalCumsum:
          nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        states: non_operating_expenses,
      }
    );

    const nonOperatingExpensesCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingExpensesForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : nonOperatingExpensesForBudgetControl.currentMonthlyTotal;

    const nonOperatingExpensesPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingExpensesForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : nonOperatingExpensesForBudgetControl.previousMonthlyTotal;

    const nonOperatingExpensesCurrentAndPreviousDifferenceMonthlyRatio =
      subtract(
        nonOperatingExpensesCurrentMonthlyRatio,
        nonOperatingExpensesPreviousMonthlyRatio
      ) as number[];

    const nonOperatingExpensesCurrentYearlyRatio = divide(
      nonOperatingExpensesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const nonOperatingExpensesPreviousYearlyRatio = divide(
      nonOperatingExpensesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const nonOperatingExpensesCurrentAndPreviousDifferenceYearlyRatio =
      subtract(
        nonOperatingExpensesCurrentYearlyRatio,
        nonOperatingExpensesPreviousYearlyRatio
      ) as number;

    const nonOperatingExpensesCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        nonOperatingExpensesForBudgetControl.currentYearlyTotal,
        nonOperatingExpensesForBudgetControl.previousYearlyTotal
      ) as number;

    const nonOperatingExpensesCurrentAndPlanDifferenceYearlyTotal = subtract(
      nonOperatingExpensesForBudgetControl.currentYearlyTotal,
      nonOperatingExpensesTotalByItemPlansInputTotal
    ) as number;

    const nonOperatingExpensesCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      nonOperatingExpensesForBudgetControl.currentYearlyTotal,
      nonOperatingExpensesTotalByItemPlansInputTotal
    ) as number;

    const nonOperatingExpensesTotal = {
      current_term: nonOperatingExpensesTotalByItemCurrentTerm,
      current_term_total: nonOperatingExpensesTotalByItemCurrentTermTotal,
      current_term_ratio: null,
      plans_input: nonOperatingExpensesTotalByItemPlansInput,
      plans_input_total: nonOperatingExpensesTotalByItemPlansInputTotal,
      plans_input_ratio: null,
      remining: nonOperatingExpensesTotalByItemRemining,
      remining_total: nonOperatingExpensesTotalByItemReminingTotal,
      remining_ratio: null,
      by_item: nonOperatingExpensesTotalByItemPlansInputMonthly.byItem,
      monthly_total:
        nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_ratio: null,
      monthly_total_by_item:
        nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotalByItem,
      monthly_total_ratio: null,
      yearly: nonOperatingExpensesTotalByItemPlansInputYearly,
      yearly_total: nonOperatingExpensesTotalByItemPlansInputYearlyTotal,
      current_by_item: nonOperatingExpensesForBudgetControl.currentByItem,
      current_by_item_total:
        nonOperatingExpensesForBudgetControl.currentByItemTotal,
      current_monthly_total:
        nonOperatingExpensesForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: nonOperatingExpensesCurrentMonthlyRatio,
      current_yearly_total:
        nonOperatingExpensesForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: nonOperatingExpensesCurrentYearlyRatio,
      previous_by_item: nonOperatingExpensesForBudgetControl.previousByItem,
      previous_by_item_total:
        nonOperatingExpensesForBudgetControl.previousByItemTotal,
      previous_monthly_total:
        nonOperatingExpensesForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: nonOperatingExpensesPreviousMonthlyRatio,
      previous_yearly_total:
        nonOperatingExpensesForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: nonOperatingExpensesPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        nonOperatingExpensesCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        nonOperatingExpensesCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        nonOperatingExpensesCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        nonOperatingExpensesCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        nonOperatingExpensesCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum:
        nonOperatingExpensesTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        nonOperatingExpensesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        nonOperatingExpensesForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        nonOperatingExpensesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        nonOperatingExpensesForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        nonOperatingExpensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 経常利益
    const currentEarningsMonthlyTotal: number[] = subtract(
      operatingIncomeMonthlyTotal.length !== 0
        ? add(
            operatingIncomeMonthlyTotal,
            nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotal
          )
        : nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotal,
      nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotal
    );
    const currentEarningsMonthlyRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: currentEarningsMonthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : currentEarningsMonthlyTotal;
    const currentEarningsCurrentTermTotal = subtract(
      add(
        operatingIncomeCurrentTermTotal,
        nonOperatingIncomeTotalByItemCurrentTermTotal
      ),
      nonOperatingExpensesTotalByItemCurrentTermTotal
    );
    const currentEarningsCurrentTermRatio = divide(
      currentEarningsCurrentTermTotal as number,
      netSalesTotalByItemCurrentTermTotal as number
    ) as number;
    const currentEarningsPlansInputTotal = subtract(
      add(
        operatingIncomePlansInputTotal,
        nonOperatingIncomeTotalByItemPlansInputTotal
      ),
      nonOperatingExpensesTotalByItemPlansInputTotal
    ) as number;
    const currentEarningsPlansInputRatio = divide(
      currentEarningsPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const currentEarningsReminingTotal = subtract(
      currentEarningsPlansInputTotal,
      currentEarningsCurrentTermTotal
    );
    const currentEarningsReminingRatio = divide(
      currentEarningsReminingTotal,
      sum(netSalesTotalByItemRemining)
    );

    const currentEarningsYearly = subtract(
      add(
        sum(operatingIncomeYearly),
        sum(nonOperatingIncomeTotalByItemPlansInputYearly)
      ),
      sum(nonOperatingExpensesTotalByItemPlansInputYearly)
    );
    const currentEarningsMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(currentEarningsMonthlyTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : sum(currentEarningsMonthlyTotal);
    const currentEarningsYearlyTotal = sum(currentEarningsYearly);

    const currentEarningsCurrentMonthlyTotal = subtract(
      add(
        operatingIncomeCurrentMonthlyTotal,
        nonOperatingIncomeForBudgetControl.currentMonthlyTotal
      ),
      nonOperatingExpensesForBudgetControl.currentMonthlyTotal
    );
    const currentEarningsCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            currentEarningsCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : currentEarningsCurrentMonthlyTotal;

    const currentEarningsPreviousMonthlyTotal = subtract(
      add(
        operatingIncomePreviousMonthlyTotal,
        nonOperatingIncomeForBudgetControl.previousMonthlyTotal
      ),
      nonOperatingExpensesForBudgetControl.previousMonthlyTotal
    );
    const currentEarningsPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            currentEarningsPreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : currentEarningsPreviousMonthlyTotal;

    const currentEarningsCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      currentEarningsCurrentMonthlyTotal,
      currentEarningsPreviousMonthlyTotal
    ) as number[];
    const currentEarningsCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      currentEarningsCurrentMonthlyRatio,
      currentEarningsPreviousMonthlyRatio
    ) as number[];
    const currentEarningsCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        currentEarningsCurrentMonthlyTotal,
        currentEarningsPreviousMonthlyTotal
      ) as number[];

    const currentEarningsCurrentAndPlanDifferenceMonthlyTotal = subtract(
      currentEarningsCurrentMonthlyTotal,
      currentEarningsMonthlyTotal
    ) as number[];
    const currentEarningsCurrentAndPlanDifferenceMonthlyRatio = subtract(
      currentEarningsCurrentMonthlyRatio,
      currentEarningsMonthlyRatio
    ) as number[];

    const currentEarningsCurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      currentEarningsCurrentMonthlyTotal,
      currentEarningsMonthlyTotal
    ) as number[];

    const currentEarningsCurrentYearlyTotal = subtract(
      add(
        operatingIncomeCurrentYearlyTotal,
        nonOperatingIncomeForBudgetControl.currentYearlyTotal
      ),
      nonOperatingExpensesForBudgetControl.currentYearlyTotal
    );
    const currentEarningsCurrentYearlyRatio = dotDivide(
      currentEarningsCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const currentEarningsPreviousYearlyTotal = subtract(
      add(
        operatingIncomePreviousYearlyTotal,
        nonOperatingIncomeForBudgetControl.previousYearlyTotal
      ),
      nonOperatingExpensesForBudgetControl.previousYearlyTotal
    );
    const currentEarningsPreviousYearlyRatio = dotDivide(
      currentEarningsPreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const currentEarningsCurrentAndPreviousDifferenceYearlyTotal = subtract(
      currentEarningsCurrentYearlyTotal,
      currentEarningsPreviousYearlyTotal
    ) as number;
    const currentEarningsCurrentAndPreviousDifferenceYearlyRatio = subtract(
      currentEarningsCurrentYearlyRatio,
      currentEarningsPreviousYearlyRatio
    ) as number;

    const currentEarningsCurrentAndPreviousDifferenceYearlyTotalRatio =
      dotDivide(
        currentEarningsCurrentYearlyTotal,
        currentEarningsPreviousYearlyTotal
      ) as number;

    const currentEarningsCurrentAndPlanDifferenceYearlyTotal = subtract(
      currentEarningsCurrentYearlyTotal,
      currentEarningsPlansInputTotal
    ) as number;
    const currentEarningsCurrentAndPlanDifferenceYearlyRatio = subtract(
      currentEarningsCurrentYearlyRatio,
      currentEarningsPlansInputRatio
    ) as number;

    const currentEarningsCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      currentEarningsCurrentYearlyTotal,
      currentEarningsPlansInputTotal
    ) as number;

    // 累計用
    const currentEarningsPlanMonthlyTotalCumsum = cumsum(
      currentEarningsMonthlyTotal
    ) as number[];
    const currentEarningsPlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            currentEarningsPlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : currentEarningsPlanMonthlyTotalCumsum;

    const currentEarningsCurrentMonthlyTotalCumsum = cumsum(
      currentEarningsCurrentMonthlyTotal
    ) as number[];
    const currentEarningsCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            currentEarningsCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : currentEarningsCurrentMonthlyTotalCumsum;

    const currentEarningsPreviousMonthlyTotalCumsum = cumsum(
      currentEarningsPreviousMonthlyTotal
    ) as number[];
    const currentEarningsPreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            currentEarningsPreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : currentEarningsPreviousMonthlyTotalCumsum;

    const currentEarningsCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        currentEarningsCurrentMonthlyTotalCumsum,
        currentEarningsPreviousMonthlyTotalCumsum
      ) as number[];
    const currentEarningsCurrentAndPreviousDifferenceMonthlyRatioCumsum =
      subtract(
        currentEarningsCurrentMonthlyRatioCumsum,
        currentEarningsPreviousMonthlyRatioCumsum
      ) as number[];
    const currentEarningsCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        currentEarningsCurrentMonthlyTotalCumsum,
        currentEarningsPreviousMonthlyTotalCumsum
      ) as number[];

    const currentEarningsCurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      currentEarningsCurrentMonthlyTotalCumsum,
      currentEarningsPlanMonthlyTotalCumsum
    ) as number[];
    const currentEarningsCurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      currentEarningsCurrentMonthlyRatioCumsum,
      currentEarningsPlanMonthlyRatioCumsum
    ) as number[];

    const currentEarningsCurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        currentEarningsCurrentMonthlyTotalCumsum,
        currentEarningsPlanMonthlyTotalCumsum
      ) as number[];

    const currentEarnings = {
      current_term_total: currentEarningsCurrentTermTotal,
      current_term_ratio: currentEarningsCurrentTermRatio,
      plans_input_total: currentEarningsPlansInputTotal,
      plans_input_ratio: currentEarningsPlansInputRatio,
      remining_total: currentEarningsReminingTotal,
      remining_ratio: currentEarningsReminingRatio,
      monthly_total: currentEarningsMonthlyTotal,
      monthly_ratio: currentEarningsMonthlyRatio,
      monthly_total_ratio: currentEarningsMonthlyTotalRatio,
      yearly: currentEarningsYearly,
      yearly_total: currentEarningsYearlyTotal,
      current_monthly_total: currentEarningsCurrentMonthlyTotal,
      current_monthly_ratio: currentEarningsCurrentMonthlyRatio,
      current_yearly_total: currentEarningsCurrentYearlyTotal,
      current_yearly_ratio: currentEarningsCurrentYearlyRatio,
      previous_monthly_total: currentEarningsPreviousMonthlyTotal,
      previous_monthly_ratio: currentEarningsPreviousMonthlyRatio,
      previous_yearly_total: currentEarningsPreviousYearlyTotal,
      previous_yearly_ratio: currentEarningsPreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        currentEarningsCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        currentEarningsCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        currentEarningsCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        currentEarningsCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        currentEarningsCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        currentEarningsCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        currentEarningsCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        currentEarningsCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        currentEarningsCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        currentEarningsCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        currentEarningsCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        currentEarningsCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: currentEarningsPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: currentEarningsPlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: currentEarningsCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: currentEarningsCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: currentEarningsPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: currentEarningsPreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        currentEarningsCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        currentEarningsCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        currentEarningsCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        currentEarningsCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        currentEarningsCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        currentEarningsCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 特別利益合計
    const specialBenefitsTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: special_benefits,
    });
    const specialBenefitsTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: special_benefits,
      });
    const specialBenefitsTotalByItemRemining = subtract(
      specialBenefitsTotalByItemPlansInput,
      specialBenefitsTotalByItemCurrentTerm
    );
    const specialBenefitsTotalByItemPlansInputYearly =
      specialBenefitsTotalByItemPlansInput;
    const specialBenefitsTotalByItemCurrentTermTotal = sum(
      specialBenefitsTotalByItemCurrentTerm
    );
    const specialBenefitsTotalByItemPlansInputTotal = sum(
      specialBenefitsTotalByItemPlansInput
    ) as number;
    const specialBenefitsTotalByItemReminingTotal = subtract(
      specialBenefitsTotalByItemPlansInputTotal,
      specialBenefitsTotalByItemCurrentTermTotal
    );
    const specialBenefitsTotalByItemPlansInputYearlyTotal = sum(
      specialBenefitsTotalByItemPlansInputYearly
    );

    const specialBenefitsForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: null,
      plansTotal: specialBenefitsTotalByItemPlansInput,
      plansMonthlyTotal: null,
      plansByItemCumsum: null,
      plansMonthlyTotalCumsum: null,
      states: special_benefits,
    });

    const specialBenefitsCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            specialBenefitsForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : specialBenefitsForBudgetControl.currentMonthlyTotal;

    const specialBenefitsPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            specialBenefitsForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : specialBenefitsForBudgetControl.previousMonthlyTotal;

    const specialBenefitsCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      specialBenefitsCurrentMonthlyRatio,
      specialBenefitsPreviousMonthlyRatio
    ) as number[];

    const specialBenefitsCurrentYearlyRatio = divide(
      specialBenefitsForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const specialBenefitsPreviousYearlyRatio = divide(
      specialBenefitsForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const specialBenefitsCurrentAndPreviousDifferenceYearlyRatio = subtract(
      specialBenefitsCurrentYearlyRatio,
      specialBenefitsPreviousYearlyRatio
    ) as number;

    const specialBenefitsCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      specialBenefitsForBudgetControl.currentYearlyTotal,
      specialBenefitsForBudgetControl.previousYearlyTotal
    ) as number;

    const specialBenefitsCurrentAndPlanDifferenceYearlyTotal = subtract(
      specialBenefitsForBudgetControl.currentYearlyTotal,
      specialBenefitsTotalByItemPlansInputTotal
    ) as number;

    const specialBenefitsCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      specialBenefitsForBudgetControl.currentYearlyTotal,
      specialBenefitsTotalByItemPlansInputTotal
    ) as number;

    const specialBenefitsTotal = {
      current_term: specialBenefitsTotalByItemCurrentTerm,
      current_term_total: specialBenefitsTotalByItemCurrentTermTotal,
      current_term_ratio: null,
      plans_input: specialBenefitsTotalByItemPlansInput,
      plans_input_total: specialBenefitsTotalByItemPlansInputTotal,
      plans_input_ratio: null,
      remining: specialBenefitsTotalByItemRemining,
      remining_total: specialBenefitsTotalByItemReminingTotal,
      remining_ratio: null,
      by_item: null,
      monthly_total: null,
      monthly_ratio: null,
      monthly_total_by_item: null,
      monthly_total_ratio: null,
      yearly: specialBenefitsTotalByItemPlansInputYearly,
      yearly_total: specialBenefitsTotalByItemPlansInputYearlyTotal,
      current_by_item: specialBenefitsForBudgetControl.currentByItem,
      current_by_item_total: specialBenefitsForBudgetControl.currentByItemTotal,
      current_monthly_total:
        specialBenefitsForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: specialBenefitsCurrentMonthlyRatio,
      current_yearly_total: specialBenefitsForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: specialBenefitsCurrentYearlyRatio,
      previous_by_item: specialBenefitsForBudgetControl.previousByItem,
      previous_by_item_total:
        specialBenefitsForBudgetControl.previousByItemTotal,
      previous_monthly_total:
        specialBenefitsForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: specialBenefitsPreviousMonthlyRatio,
      previous_yearly_total:
        specialBenefitsForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: specialBenefitsPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        specialBenefitsCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        specialBenefitsCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        specialBenefitsCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        specialBenefitsCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        specialBenefitsCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        specialBenefitsForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        specialBenefitsForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        specialBenefitsForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        specialBenefitsForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        specialBenefitsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum:
        specialBenefitsForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum: null,
    };

    // 特別損失合計
    const specialLossesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: special_losses,
    });
    const specialLossesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: special_losses,
      });
    const specialLossesTotalByItemRemining = subtract(
      specialLossesTotalByItemPlansInput,
      specialLossesTotalByItemCurrentTerm
    );
    const specialLossesTotalByItemPlansInputYearly =
      specialLossesTotalByItemPlansInput;
    const specialLossesTotalByItemCurrentTermTotal = sum(
      specialLossesTotalByItemCurrentTerm
    );
    const specialLossesTotalByItemPlansInputTotal = sum(
      specialLossesTotalByItemPlansInput
    ) as number;
    const specialLossesTotalByItemReminingTotal = subtract(
      specialLossesTotalByItemPlansInputTotal,
      specialLossesTotalByItemCurrentTermTotal
    );
    const specialLossesTotalByItemPlansInputYearlyTotal = sum(
      specialLossesTotalByItemPlansInputYearly
    );

    const specialLossesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: null,
      plansTotal: specialLossesTotalByItemPlansInput,
      plansMonthlyTotal: null,
      plansByItemCumsum: null,
      plansMonthlyTotalCumsum: null,

      states: special_losses,
    });

    const specialLossesCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            specialLossesForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : specialLossesForBudgetControl.currentMonthlyTotal;

    const specialLossesPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            specialLossesForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : specialLossesForBudgetControl.previousMonthlyTotal;

    const specialLossesCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      specialLossesCurrentMonthlyRatio,
      specialLossesPreviousMonthlyRatio
    ) as number[];

    const specialLossesCurrentYearlyRatio = divide(
      specialLossesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const specialLossesPreviousYearlyRatio = divide(
      specialLossesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const specialLossesCurrentAndPreviousDifferenceYearlyRatio = subtract(
      specialLossesCurrentYearlyRatio,
      specialLossesPreviousYearlyRatio
    ) as number;

    const specialLossesCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      specialLossesForBudgetControl.currentYearlyTotal,
      specialLossesForBudgetControl.previousYearlyTotal
    ) as number;

    const specialLossesCurrentAndPlanDifferenceYearlyTotal = subtract(
      specialLossesForBudgetControl.currentYearlyTotal,
      specialLossesTotalByItemPlansInputTotal
    ) as number;

    const specialLossesCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      specialLossesForBudgetControl.currentYearlyTotal,
      specialLossesTotalByItemPlansInputTotal
    ) as number;

    const specialLossesTotal = {
      current_term: specialLossesTotalByItemCurrentTerm,
      current_term_total: specialLossesTotalByItemCurrentTermTotal,
      current_term_ratio: null,
      plans_input: specialLossesTotalByItemPlansInput,
      plans_input_total: specialLossesTotalByItemPlansInputTotal,
      plans_input_ratio: null,
      remining: specialLossesTotalByItemRemining,
      remining_total: specialLossesTotalByItemReminingTotal,
      remining_ratio: null,
      by_item: null,
      monthly_total: null,
      monthly_ratio: null,
      monthly_total_by_item: null,
      monthly_total_ratio: null,
      yearly: specialLossesTotalByItemPlansInputYearly,
      yearly_total: specialLossesTotalByItemPlansInputYearlyTotal,
      current_by_item: specialLossesForBudgetControl.currentByItem,
      current_by_item_total: specialLossesForBudgetControl.currentByItemTotal,
      current_monthly_total: specialLossesForBudgetControl.currentMonthlyTotal,
      current_monthly_ratio: specialLossesCurrentMonthlyRatio,
      current_yearly_total: specialLossesForBudgetControl.currentYearlyTotal,
      current_yearly_ratio: specialLossesCurrentYearlyRatio,
      previous_by_item: specialLossesForBudgetControl.previousByItem,
      previous_by_item_total: specialLossesForBudgetControl.previousByItemTotal,
      previous_monthly_total:
        specialLossesForBudgetControl.previousMonthlyTotal,
      previous_monthly_ratio: specialLossesPreviousMonthlyRatio,
      previous_yearly_total: specialLossesForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio: specialLossesPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        specialLossesForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        specialLossesCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        specialLossesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        specialLossesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        specialLossesCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        specialLossesCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        specialLossesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        specialLossesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        specialLossesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        specialLossesForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        specialLossesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        specialLossesCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        specialLossesCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum: specialLossesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        specialLossesForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        specialLossesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        specialLossesForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        specialLossesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        specialLossesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        specialLossesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        specialLossesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum:
        specialLossesForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum: null,
    };

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTerm =
      totalCalcByItem({
        headers: headers[resultsField],
        field: resultsField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalByItemRemining = subtract(
      corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput,
      corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTerm
    );
    const corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputYearly =
      corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput;
    const corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTermTotal =
      sum(corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTerm);
    const corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputTotal = sum(
      corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput
    ) as number;
    const corporateInhabitantAndEnterpriseTaxesTotalByItemReminingTotal =
      subtract(
        corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputTotal,
        corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTermTotal
      );
    const corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputYearlyTotal =
      sum(corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputYearly);

    const corporateInhabitantAndEnterpriseTaxesForBudgetControl =
      totalCalcForBudgetControlBasis({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState: null,
        plansTotal: corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput,
        plansMonthlyTotal: null,
        plansByItemCumsum: null,
        plansMonthlyTotalCumsum: null,
        states: special_corporate_inhabitant_and_enterprise_taxeslosses,
      });

    const corporateInhabitantAndEnterpriseTaxesCurrentYearlyRatio = divide(
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const corporateInhabitantAndEnterpriseTaxesPreviousYearlyRatio = divide(
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const corporateInhabitantAndEnterpriseTaxesCurrentAndPreviousDifferenceYearlyRatio =
      subtract(
        corporateInhabitantAndEnterpriseTaxesCurrentYearlyRatio,
        corporateInhabitantAndEnterpriseTaxesPreviousYearlyRatio
      ) as number;

    const corporateInhabitantAndEnterpriseTaxesCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentYearlyTotal,
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousYearlyTotal
      ) as number;

    const corporateInhabitantAndEnterpriseTaxesCurrentAndPlanDifferenceYearlyTotalRatio =
      divide(
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentYearlyTotal,
        corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputTotal
      ) as number;

    const corporateInhabitantAndEnterpriseTaxesTotal = {
      current_term: corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTerm,
      current_term_total:
        corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTermTotal,
      current_term_ratio: null,
      plans_input: corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInput,
      plans_input_total:
        corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputTotal,
      plans_input_ratio: null,
      remining: corporateInhabitantAndEnterpriseTaxesTotalByItemRemining,
      remining_total:
        corporateInhabitantAndEnterpriseTaxesTotalByItemReminingTotal,
      remining_ratio: null,
      by_item: null,
      monthly_total: null,
      monthly_ratio: null,
      monthly_total_by_item: null,
      monthly_total_ratio: null,
      yearly: corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputYearly,
      yearly_total:
        corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputYearlyTotal,
      current_by_item:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentByItem,
      current_by_item_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentByItemTotal,
      current_monthly_total: null,
      current_monthly_ratio: null,
      current_yearly_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentYearlyTotal,
      current_yearly_ratio:
        corporateInhabitantAndEnterpriseTaxesCurrentYearlyRatio,
      previous_by_item:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousByItem,
      previous_by_item_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousByItemTotal,
      previous_monthly_total: null,
      previous_monthly_ratio: null,
      previous_yearly_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio:
        corporateInhabitantAndEnterpriseTaxesPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total: null,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        corporateInhabitantAndEnterpriseTaxesCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        corporateInhabitantAndEnterpriseTaxesCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total: null,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total: null,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        corporateInhabitantAndEnterpriseTaxesCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum: null,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum: null,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum: null,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum: null,
      current_and_plan_difference_by_item_cumsum:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum: null,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum: null,
    };

    // 材料費合計
    const materialFeeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: material_fee,
    });
    materialFeeTotalByItemCurrentTerm[0] = 0;
    materialFeeTotalByItemCurrentTerm[
      materialFeeTotalByItemCurrentTerm.length - 1
    ] = 0;
    const materialFeeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: material_fee,
      });
    const materialFeeTotalByItemRemining = subtract(
      materialFeeTotalByItemPlansInput,
      materialFeeTotalByItemCurrentTerm
    );
    const materialFeeTotalByItemReminingData = [];
    materialFeeTotalByItemReminingData.push(...materialFeeTotalByItemRemining);
    materialFeeTotalByItemReminingData[0] = 0;
    materialFeeTotalByItemReminingData[
      materialFeeTotalByItemReminingData.length - 1
    ] = 0;
    const materialFeeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: material_fee,
        ratio: netSalesTotalPreviousRatio,
        remins: materialFeeTotalByItemReminingData,
        counts: headers.results_of_the_current_term_count,
      });

    const materialFeeTotalByItemPlansInputYearly = subtract(
      materialFeeTotalByItemPlansInput,
      materialFeeTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const materialFeeTotalByItemCurrentTermTotal = subtract(
      subtract(
        sum(materialFeeTotalByItemCurrentTerm),
        materialFeeTotalByItemCurrentTerm[
          materialFeeTotalByItemCurrentTerm.length - 1
        ]
      ),
      materialFeeTotalByItemCurrentTerm[
        materialFeeTotalByItemCurrentTerm.length - 1
      ]
    );
    const materialFeeTotalByItemPlansInputTotal = subtract(
      subtract(
        sum(materialFeeTotalByItemPlansInput),
        materialFeeTotalByItemPlansInput[
          materialFeeTotalByItemPlansInput.length - 1
        ]
      ),
      materialFeeTotalByItemPlansInput[
        materialFeeTotalByItemPlansInput.length - 1
      ]
    ) as number;
    const materialFeeTotalByItemReminingTotal = subtract(
      materialFeeTotalByItemPlansInputTotal,
      materialFeeTotalByItemCurrentTermTotal
    );
    const materialFeeTotalByItemPlansInputYearlyTotal = subtract(
      subtract(
        sum(materialFeeTotalByItemPlansInputYearly),
        materialFeeTotalByItemPlansInputYearly[
          materialFeeTotalByItemPlansInputYearly.length - 1
        ]
      ),
      materialFeeTotalByItemPlansInputYearly[
        materialFeeTotalByItemPlansInputYearly.length - 1
      ]
    );

    const materialFeeForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: null,
      plansTotal: materialFeeTotalByItemPlansInput,
      plansMonthlyTotal: null,
      plansByItemCumsum: null,
      plansMonthlyTotalCumsum: null,
      states: material_fee,
      isMaterialFee: true,
    });

    const materialFeeCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      materialFeeForBudgetControl.currentYearlyTotal,
      materialFeeForBudgetControl.previousYearlyTotal
    ) as number;

    const materialFeeCurrentAndPlanDifferenceYearlyTotal = subtract(
      materialFeeForBudgetControl.currentYearlyTotal,
      materialFeeTotalByItemPlansInputTotal
    ) as number;

    const materialFeeCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      materialFeeForBudgetControl.currentYearlyTotal,
      materialFeeTotalByItemPlansInputTotal
    ) as number;

    const materialFeeTotal = {
      current_term: materialFeeTotalByItemCurrentTerm,
      current_term_total: materialFeeTotalByItemCurrentTermTotal,
      plans_input: materialFeeTotalByItemPlansInput,
      plans_input_total: materialFeeTotalByItemPlansInputTotal,
      remining: materialFeeTotalByItemRemining,
      remining_total: materialFeeTotalByItemReminingTotal,
      by_item: materialFeeTotalByItemPlansInputMonthly.byItem,
      monthly_total: materialFeeTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_total_by_item:
        materialFeeTotalByItemPlansInputMonthly.monthlyTotalByItem,
      yearly: materialFeeTotalByItemPlansInputYearly,
      yearly_total: materialFeeTotalByItemPlansInputYearlyTotal,
      current_by_item: materialFeeForBudgetControl.currentByItem,
      current_by_item_total: materialFeeForBudgetControl.currentByItemTotal,
      current_monthly_total: materialFeeForBudgetControl.currentMonthlyTotal,
      current_yearly_total: materialFeeForBudgetControl.currentYearlyTotal,
      previous_by_item: materialFeeForBudgetControl.previousByItem,
      previous_by_item_total: materialFeeForBudgetControl.previousByItemTotal,
      previous_monthly_total: materialFeeForBudgetControl.previousMonthlyTotal,
      previous_yearly_total: materialFeeForBudgetControl.previousYearlyTotal,
      current_and_previous_difference_by_item:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        materialFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        materialFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        materialFeeForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        materialFeeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        materialFeeForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        materialFeeForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        materialFeeForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        materialFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        materialFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        materialFeeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        materialFeeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum: materialFeeForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        materialFeeForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum: materialFeeForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        materialFeeForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        materialFeeForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        materialFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        materialFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        materialFeeForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum:
        materialFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum: null,
    };

    // 労務費合計
    const laborCostsTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: labor_costs,
    });
    const laborCostsTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly(
      {
        headers: headers[plansField],
        field: plansField,
        state: labor_costs,
      }
    );
    const laborCostsTotalByItemRemining = subtract(
      laborCostsTotalByItemPlansInput,
      laborCostsTotalByItemCurrentTerm
    );
    const laborCostsTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthlyLaborCosts({
        states: labor_costs,
        remins: laborCostsTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const laborCostsTotalByItemPlansInputYearly = subtract(
      laborCostsTotalByItemPlansInput,
      laborCostsTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const laborCostsTotalByItemCurrentTermTotal = sum(
      laborCostsTotalByItemCurrentTerm
    );
    const laborCostsTotalByItemPlansInputTotal = sum(
      laborCostsTotalByItemPlansInput
    ) as number;
    const laborCostsTotalByItemReminingTotal = subtract(
      laborCostsTotalByItemPlansInputTotal,
      laborCostsTotalByItemCurrentTermTotal
    );
    const laborCostsTotalByItemPlansInputYearlyTotal = sum(
      laborCostsTotalByItemPlansInputYearly
    ) as number;

    const laborCostsForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: laborCostsTotalByItemPlansInputMonthly.byItem,
      plansTotal: laborCostsTotalByItemPlansInput,
      plansMonthlyTotal: laborCostsTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum: laborCostsTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        laborCostsTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: labor_costs,
    });

    const laborCostsCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      laborCostsForBudgetControl.currentYearlyTotal,
      laborCostsForBudgetControl.previousYearlyTotal
    ) as number;

    const laborCostsCurrentAndPlanDifferenceYearlyTotal = subtract(
      laborCostsForBudgetControl.currentYearlyTotal,
      laborCostsTotalByItemPlansInputTotal
    ) as number;

    const laborCostsCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      laborCostsForBudgetControl.currentYearlyTotal as number,
      laborCostsTotalByItemPlansInputTotal
    ) as number;

    const laborCostsTotal = {
      current_term: laborCostsTotalByItemCurrentTerm,
      current_term_total: laborCostsTotalByItemCurrentTermTotal,
      plans_input: laborCostsTotalByItemPlansInput,
      plans_input_total: laborCostsTotalByItemPlansInputTotal,
      remining: laborCostsTotalByItemRemining,
      remining_total: laborCostsTotalByItemReminingTotal,
      by_item: laborCostsTotalByItemPlansInputMonthly.byItem,
      monthly_total: laborCostsTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_total_by_item:
        laborCostsTotalByItemPlansInputMonthly.monthlyTotalByItem,
      yearly: laborCostsTotalByItemPlansInputYearly,
      yearly_total: laborCostsTotalByItemPlansInputYearlyTotal,
      current_by_item: laborCostsForBudgetControl.currentByItem,
      current_by_item_total: laborCostsForBudgetControl.currentByItemTotal,
      current_monthly_total: laborCostsForBudgetControl.currentMonthlyTotal,
      current_yearly_total: laborCostsForBudgetControl.currentYearlyTotal,
      previous_by_item: laborCostsForBudgetControl.previousByItem,
      previous_by_item_total: laborCostsForBudgetControl.previousByItemTotal,
      previous_monthly_total: laborCostsForBudgetControl.previousMonthlyTotal,
      previous_yearly_total: laborCostsForBudgetControl.previousYearlyTotal,
      current_and_previous_difference_by_item:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        laborCostsForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        laborCostsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        laborCostsForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        laborCostsCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItemRatio,
      current_and_plan_difference_by_item_total_ratio:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        laborCostsForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        laborCostsForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        laborCostsCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        laborCostsCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: laborCostsTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        laborCostsTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum: laborCostsForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        laborCostsForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum: laborCostsForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        laborCostsForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        laborCostsForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        laborCostsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        laborCostsForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        laborCostsForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        laborCostsForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        laborCostsForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 外注加工費合計
    const externalInjectionProcessingFeeTotalByItemCurrentTerm =
      totalCalcByItem({
        headers: headers[resultsField],
        field: resultsField,
        state: external_injection_processing_fee,
      });
    const externalInjectionProcessingFeeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: external_injection_processing_fee,
      });
    const externalInjectionProcessingFeeTotalByItemRemining = subtract(
      externalInjectionProcessingFeeTotalByItemPlansInput,
      externalInjectionProcessingFeeTotalByItemCurrentTerm
    );
    const externalInjectionProcessingFeeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthlyExternalInjection({
        states: external_injection_processing_fee,
        ratio: netSalesTotalPreviousRatio,
        remins: externalInjectionProcessingFeeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });
    const externalInjectionProcessingFeeTotalByItemPlansInputYearly = subtract(
      externalInjectionProcessingFeeTotalByItemPlansInput,
      externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const externalInjectionProcessingFeeTotalByItemCurrentTermTotal = sum(
      externalInjectionProcessingFeeTotalByItemCurrentTerm
    );
    const externalInjectionProcessingFeeTotalByItemPlansInputTotal = sum(
      externalInjectionProcessingFeeTotalByItemPlansInput
    ) as number;
    const externalInjectionProcessingFeeTotalByItemReminingTotal = subtract(
      externalInjectionProcessingFeeTotalByItemPlansInputTotal,
      externalInjectionProcessingFeeTotalByItemCurrentTermTotal
    );
    const externalInjectionProcessingFeeTotalByItemPlansInputYearlyTotal = sum(
      externalInjectionProcessingFeeTotalByItemPlansInputYearly
    ) as number;

    const externalInjectionProcessingFeeForBudgetControl =
      totalCalcForBudgetControlBasis({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState:
          externalInjectionProcessingFeeTotalByItemPlansInputMonthly.byItem,
        plansTotal: externalInjectionProcessingFeeTotalByItemPlansInput,
        plansMonthlyTotal:
          externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal,
        plansByItemCumsum:
          externalInjectionProcessingFeeTotalByItemPlansInputMonthly.byItemCumsum,
        plansMonthlyTotalCumsum:
          externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        states: external_injection_processing_fee,
      });

    const externalInjectionProcessingFeeCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
        externalInjectionProcessingFeeForBudgetControl.previousYearlyTotal
      ) as number;

    const externalInjectionProcessingFeeCurrentAndPlanDifferenceYearlyTotal =
      subtract(
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
        externalInjectionProcessingFeeTotalByItemPlansInputTotal
      ) as number;

    const externalInjectionProcessingFeeCurrentAndPlanDifferenceYearlyTotalRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal as number,
        externalInjectionProcessingFeeTotalByItemPlansInputTotal
      ) as number;

    const externalInjectionProcessingFeeTotal = {
      current_term: externalInjectionProcessingFeeTotalByItemCurrentTerm,
      current_term_total:
        externalInjectionProcessingFeeTotalByItemCurrentTermTotal,
      plans_input: externalInjectionProcessingFeeTotalByItemPlansInput,
      plans_input_total:
        externalInjectionProcessingFeeTotalByItemPlansInputTotal,
      remining: externalInjectionProcessingFeeTotalByItemRemining,
      remining_total: externalInjectionProcessingFeeTotalByItemReminingTotal,
      by_item:
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.byItem,
      monthly_total:
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_total_by_item:
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalByItem,
      yearly: externalInjectionProcessingFeeTotalByItemPlansInputYearly,
      yearly_total:
        externalInjectionProcessingFeeTotalByItemPlansInputYearlyTotal,
      current_by_item:
        externalInjectionProcessingFeeForBudgetControl.currentByItem,
      current_by_item_total:
        externalInjectionProcessingFeeForBudgetControl.currentByItemTotal,
      current_monthly_total:
        externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotal,
      current_yearly_total:
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
      previous_by_item:
        externalInjectionProcessingFeeForBudgetControl.previousByItem,
      previous_by_item_total:
        externalInjectionProcessingFeeForBudgetControl.previousByItemTotal,
      previous_monthly_total:
        externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotal,
      previous_yearly_total:
        externalInjectionProcessingFeeForBudgetControl.previousYearlyTotal,
      current_and_previous_difference_by_item:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        externalInjectionProcessingFeeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        externalInjectionProcessingFeeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        externalInjectionProcessingFeeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum:
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        externalInjectionProcessingFeeForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        externalInjectionProcessingFeeForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 経費合計
    const expensesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: expenses,
    });
    const expensesTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly({
      headers: headers[plansField],
      field: plansField,
      state: expenses,
    });
    const expensesTotalByItemRemining = subtract(
      expensesTotalByItemPlansInput,
      expensesTotalByItemCurrentTerm
    );
    const expensesTotalByItemPlansInputMonthly = calcByItemForPlansInputMonthly(
      {
        states: expenses,
        ratio: netSalesTotalPreviousRatio,
        remins: expensesTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      }
    );
    const expensesTotalByItemPlansInputYearly = subtract(
      expensesTotalByItemPlansInput,
      expensesTotalByItemPlansInputMonthly.monthlyTotalByItem
    );
    const expensesTotalByItemCurrentTermTotal = sum(
      expensesTotalByItemCurrentTerm
    );
    const expensesTotalByItemPlansInputTotal = sum(
      expensesTotalByItemPlansInput
    ) as number;
    const expensesTotalByItemReminingTotal = subtract(
      expensesTotalByItemPlansInputTotal,
      expensesTotalByItemCurrentTermTotal
    );
    const expensesTotalByItemPlansInputYearlyTotal = sum(
      expensesTotalByItemPlansInputYearly
    ) as number;

    const expensesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: expensesTotalByItemPlansInputMonthly.byItem,
      plansTotal: expensesTotalByItemPlansInput,
      plansMonthlyTotal: expensesTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum: expensesTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        expensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: expenses,
    });

    const expensesCurrentAndPreviousDifferenceYearlyTotalRatio = divide(
      expensesForBudgetControl.currentYearlyTotal,
      expensesForBudgetControl.previousYearlyTotal
    ) as number;

    const expensesCurrentAndPlanDifferenceYearlyTotal = subtract(
      expensesForBudgetControl.currentYearlyTotal,
      expensesTotalByItemPlansInputTotal
    ) as number;

    const expensesCurrentAndPlanDifferenceYearlyTotalRatio = divide(
      expensesForBudgetControl.currentYearlyTotal as number,
      expensesTotalByItemPlansInputTotal
    ) as number;

    const expensesTotal = {
      current_term: expensesTotalByItemCurrentTerm,
      current_term_total: expensesTotalByItemCurrentTermTotal,
      plans_input: expensesTotalByItemPlansInput,
      plans_input_total: expensesTotalByItemPlansInputTotal,
      remining: expensesTotalByItemRemining,
      remining_total: expensesTotalByItemReminingTotal,
      by_item: expensesTotalByItemPlansInputMonthly.byItem,
      monthly_total: expensesTotalByItemPlansInputMonthly.monthlyTotal,
      monthly_total_by_item:
        expensesTotalByItemPlansInputMonthly.monthlyTotalByItem,
      yearly: expensesTotalByItemPlansInputYearly,
      yearly_total: expensesTotalByItemPlansInputYearlyTotal,
      current_by_item: expensesForBudgetControl.currentByItem,
      current_by_item_total: expensesForBudgetControl.currentByItemTotal,
      current_monthly_total: expensesForBudgetControl.currentMonthlyTotal,
      current_yearly_total: expensesForBudgetControl.currentYearlyTotal,
      previous_by_item: expensesForBudgetControl.previousByItem,
      previous_by_item_total: expensesForBudgetControl.previousByItemTotal,
      previous_monthly_total: expensesForBudgetControl.previousMonthlyTotal,
      previous_yearly_total: expensesForBudgetControl.previousYearlyTotal,
      current_and_previous_difference_by_item:
        expensesForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        expensesForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        expensesForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        expensesForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total:
        expensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        expensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        expensesForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        expensesCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        expensesForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        expensesForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        expensesForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total:
        expensesForBudgetControl.currentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        expensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total:
        expensesCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        expensesCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: expensesTotalByItemPlansInputMonthly.byItemCumsum,
      plan_monthly_total_cumsum:
        expensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum: expensesForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum:
        expensesForBudgetControl.currentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum: expensesForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum:
        expensesForBudgetControl.previousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        expensesForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        expensesForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        expensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        expensesForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        expensesForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum:
        expensesForBudgetControl.currentAndPlanDifferenceByItemRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        expensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        expensesForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 期首仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm =
      totalCalcByItem({
        headers: headers[resultsField],
        field: resultsField,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });
    inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm[0] = 0;
    inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm[
      inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm.length -
        1
    ] = 0;
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemRemining =
      subtract(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm
      );

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputYearly =
      inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput;
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTermTotal =
      sum(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm
      );
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputTotal =
      sum(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput
      ) as number;
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemReminingTotal =
      subtract(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTermTotal
      );
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputYearlyTotal =
      sum(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputYearly
      );

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl =
      totalCalcForBudgetControlBasis({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState: null,
        plansTotal:
          inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput,
        plansMonthlyTotal: null,
        plansByItemCumsum: null,
        plansMonthlyTotalCumsum: null,
        states: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentYearlyRatio =
      divide(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentYearlyTotal,
        netSalesForBudgetControl.currentYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodPreviousYearlyRatio =
      divide(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousYearlyTotal,
        netSalesForBudgetControl.previousYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPreviousDifferenceYearlyRatio =
      subtract(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentYearlyRatio,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodPreviousYearlyRatio
      ) as number;

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentYearlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPlanDifferenceYearlyTotalRatio =
      divide(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentYearlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal = {
      current_term:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTerm,
      current_term_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemCurrentTermTotal,
      plans_input:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput,
      plans_input_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputTotal,
      remining:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemRemining,
      remining_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemReminingTotal,
      by_item: null,
      monthly_total: null,
      monthly_total_by_item: null,
      yearly:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputYearly,
      yearly_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInputYearlyTotal,
      current_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentByItem,
      current_by_item_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentByItemTotal,
      current_monthly_total: null,
      current_monthly_ratio: null,
      current_yearly_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentYearlyTotal,
      current_yearly_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentYearlyRatio,
      previous_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousByItem,
      previous_by_item_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousByItemTotal,
      previous_monthly_total: null,
      previous_monthly_ratio: null,
      previous_yearly_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total: null,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total: null,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total: null,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum: null,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum: null,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum: null,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 期末仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm =
      totalCalcByItem({
        headers: headers[resultsField],
        field: resultsField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });
    inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm[0] = 0;
    inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm[
      inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm.length -
        1
    ] = 0;
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemRemining =
      subtract(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput,
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm
      );
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputYearly =
      inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput;
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTermTotal =
      sum(inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm);
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputTotal =
      sum(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput
      ) as number;
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemReminingTotal =
      subtract(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputTotal,
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTermTotal
      );
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputYearlyTotal =
      sum(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputYearly
      );

    const inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl =
      totalCalcForBudgetControlBasis({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        plansState: null,
        plansTotal:
          inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput,
        plansMonthlyTotal: null,
        plansByItemCumsum: null,
        plansMonthlyTotalCumsum: null,
        states: inventory_of_work_in_process_at_the_end_of_the_period,
      });

    const inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentYearlyRatio =
      divide(
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentYearlyTotal,
        netSalesForBudgetControl.currentYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodPreviousYearlyRatio =
      divide(
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousYearlyTotal,
        netSalesForBudgetControl.previousYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPreviousDifferenceYearlyRatio =
      subtract(
        inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentYearlyRatio,
        inventoryOfWorkInProcessAtTheEndOfThePeriodPreviousYearlyRatio
      ) as number;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio =
      divide(
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentYearlyTotal,
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousYearlyTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPlanDifferenceYearlyTotalRatio =
      divide(
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentYearlyTotal,
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputTotal
      ) as number;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotal = {
      current_term:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm,
      current_term_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTermTotal,
      plans_input:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput,
      plans_input_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputTotal,
      remining: inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemRemining,
      reminingTotal:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemReminingTotal,
      by_item: null,
      monthly_total: null,
      monthly_total_by_item: null,
      yearly:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputYearly,
      yearly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInputYearlyTotal,
      current_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentByItem,
      current_by_item_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentByItemTotal,
      current_monthly_total: null,
      current_monthly_ratio: null,
      current_yearly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentYearlyTotal,
      current_yearly_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentYearlyRatio,
      previous_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousByItem,
      previous_by_item_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousByItemTotal,
      previous_monthly_total: null,
      previous_monthly_ratio: null,
      previous_yearly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousYearlyTotal,
      previous_yearly_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodPreviousYearlyRatio,
      current_and_previous_difference_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItem,
      current_and_previous_difference_by_item_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemTotal,
      current_and_previous_difference_by_item_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemRatio,
      current_and_previous_difference_by_item_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemTotalRatio,
      current_and_previous_difference_monthly_total: null,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceByItem,
      current_and_plan_difference_by_item_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemTotal,
      current_and_plan_difference_by_item_ratio: null,
      current_and_plan_difference_by_item_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemTotalRatio,
      current_and_plan_difference_monthly_total: null,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatio,
      current_and_plan_difference_yearly_total: null,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_by_item_cumsum: null,
      plan_monthly_total_cumsum: null,
      plan_monthly_ratio_cumsum: null,
      current_by_item_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentByItemCumsum,
      current_monthly_total_cumsum: null,
      current_monthly_ratio_cumsum: null,
      previous_by_item_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousByItemCumsum,
      previous_monthly_total_cumsum: null,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_by_item_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemCumsum,
      current_and_previous_difference_by_item_ratio_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceByItemRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum: null,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_by_item_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceByItemCumsum,
      current_and_plan_difference_by_item_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 当期製造費用
    const grossManufacturingCostForThePeriodMonthlyTotal: number[] = add(
      materialFeeTotalByItemPlansInputMonthly.monthlyTotal,
      add(
        laborCostsTotalByItemPlansInputMonthly.monthlyTotal,
        add(
          externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal,
          expensesTotalByItemPlansInputMonthly.monthlyTotal
        )
      )
    );
    const grossManufacturingCostForThePeriodCurrentTermTotal = add(
      materialFeeTotalByItemCurrentTermTotal,
      add(
        laborCostsTotalByItemCurrentTermTotal,
        add(
          externalInjectionProcessingFeeTotalByItemCurrentTermTotal,
          expensesTotalByItemCurrentTermTotal
        )
      )
    );
    const grossManufacturingCostForThePeriodPlansInputTotal = add(
      materialFeeTotalByItemPlansInputTotal,
      add(
        laborCostsTotalByItemPlansInputTotal,
        add(
          externalInjectionProcessingFeeTotalByItemPlansInputTotal,
          expensesTotalByItemPlansInputTotal
        )
      )
    );
    const grossManufacturingCostForThePeriodReminingTotal = subtract(
      grossManufacturingCostForThePeriodPlansInputTotal,
      grossManufacturingCostForThePeriodCurrentTermTotal
    );
    const grossManufacturingCostForThePeriodYearly = subtract(
      grossManufacturingCostForThePeriodPlansInputTotal,
      sum(grossManufacturingCostForThePeriodMonthlyTotal)
    );

    const grossManufacturingCostForThePeriodCurrentMonthlyTotal = add(
      materialFeeForBudgetControl.currentMonthlyTotal,
      add(
        laborCostsForBudgetControl.currentMonthlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotal,
          expensesForBudgetControl.currentMonthlyTotal
        )
      )
    ) as number[];

    const grossManufacturingCostForThePeriodPreviousMonthlyTotal = add(
      materialFeeForBudgetControl.previousMonthlyTotal,
      add(
        laborCostsForBudgetControl.previousMonthlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotal,
          expensesForBudgetControl.previousMonthlyTotal
        )
      )
    ) as number[];

    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotal =
      subtract(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        grossManufacturingCostForThePeriodPreviousMonthlyTotal
      ) as number[];

    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        grossManufacturingCostForThePeriodPreviousMonthlyTotal
      ) as number[];

    const grossManufacturingCostForThePeriodCurrentAndPlanDifferenceMonthlyTotal =
      subtract(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        grossManufacturingCostForThePeriodMonthlyTotal
      ) as number[];

    const grossManufacturingCostForThePeriodCurrentAndPlanDifferenceMonthlyTotalRatio =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        grossManufacturingCostForThePeriodMonthlyTotal
      ) as number[];

    const grossManufacturingCostForThePeriodCurrentYearlyTotal = add(
      materialFeeForBudgetControl.currentYearlyTotal,
      add(
        laborCostsForBudgetControl.currentYearlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
          expensesForBudgetControl.currentYearlyTotal
        )
      )
    ) as number;

    const grossManufacturingCostForThePeriodPreviousYearlyTotal = add(
      materialFeeForBudgetControl.previousYearlyTotal,
      add(
        laborCostsForBudgetControl.previousYearlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.previousYearlyTotal,
          expensesForBudgetControl.previousYearlyTotal
        )
      )
    ) as number;

    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceYearlyTotal =
      subtract(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        grossManufacturingCostForThePeriodPreviousYearlyTotal
      ) as number;

    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        grossManufacturingCostForThePeriodPreviousYearlyTotal
      ) as number;

    const grossManufacturingCostForThePeriodCurrentAndPlanDifferenceYearlyTotal =
      subtract(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        grossManufacturingCostForThePeriodPlansInputTotal
      ) as number;

    const grossManufacturingCostForThePeriodCurrentAndPlanDifferenceYearlyTotalRatio =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        grossManufacturingCostForThePeriodPlansInputTotal
      ) as number;

    // 累計用
    const grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum = cumsum(
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];
    const grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum = cumsum(
      grossManufacturingCostForThePeriodCurrentMonthlyTotal
    ) as number[];
    const grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum = cumsum(
      grossManufacturingCostForThePeriodPreviousMonthlyTotal
    ) as number[];

    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];
    const grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const grossManufacturingCostForThePeriodPlanCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const grossManufacturingCostForThePeriodPlanCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];

    const grossManufacturingCostForThePeriod = {
      current_term_total: grossManufacturingCostForThePeriodCurrentTermTotal,
      plans_input_total: grossManufacturingCostForThePeriodPlansInputTotal,
      remining_total: grossManufacturingCostForThePeriodReminingTotal,
      monthly_total: grossManufacturingCostForThePeriodMonthlyTotal,
      yearly_total: grossManufacturingCostForThePeriodYearly,
      current_monthly_total:
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
      current_monthly_ratio: null,
      current_yearly_total:
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
      current_yearly_ratio: null,
      previous_monthly_total:
        grossManufacturingCostForThePeriodPreviousMonthlyTotal,
      previous_monthly_ratio: null,
      previous_yearly_total:
        grossManufacturingCostForThePeriodPreviousYearlyTotal,
      previous_yearly_ratio: null,
      current_and_previous_difference_monthly_total:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        grossManufacturingCostForThePeriodCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        grossManufacturingCostForThePeriodCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio: null,
      current_and_previous_difference_yearly_total_ratio:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        grossManufacturingCostForThePeriodCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        grossManufacturingCostForThePeriodCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum:
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_monthly_total_cumsum:
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_monthly_total_cumsum:
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_cumsum:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        grossManufacturingCostForThePeriodCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        grossManufacturingCostForThePeriodPlanCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        grossManufacturingCostForThePeriodPlanCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
    };

    // 当期製品製造原価
    const costOfProductsManufacturedMonthlyTotal = subtract(
      add(grossManufacturingCostForThePeriodMonthlyTotal, 0),
      0
    ) as number[];
    const costOfProductsManufacturedCurrentTermTotal = subtract(
      add(grossManufacturingCostForThePeriodCurrentTermTotal, 0),
      0
    );
    const costOfProductsManufacturedPlansInputTotal = subtract(
      add(
        grossManufacturingCostForThePeriodPlansInputTotal,
        sum(
          inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemPlansInput
        )
      ),
      sum(inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemPlansInput)
    );

    const costOfProductsManufacturedReminingTotal = subtract(
      costOfProductsManufacturedPlansInputTotal,
      costOfProductsManufacturedCurrentTermTotal
    );

    const costOfProductsManufacturedYearly = subtract(
      costOfProductsManufacturedPlansInputTotal,
      sum(costOfProductsManufacturedMonthlyTotal)
    );

    const costOfProductsManufacturedCurrentMonthlyTotal = subtract(
      add(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentMonthlyTotal
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentMonthlyTotal
    ) as number[];

    const costOfProductsManufacturedPreviousMonthlyTotal = subtract(
      add(
        grossManufacturingCostForThePeriodPreviousMonthlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousMonthlyTotal
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousMonthlyTotal
    ) as number[];

    const costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotal =
      subtract(
        costOfProductsManufacturedCurrentMonthlyTotal,
        costOfProductsManufacturedPreviousMonthlyTotal
      ) as number[];

    const costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        costOfProductsManufacturedCurrentMonthlyTotal,
        costOfProductsManufacturedPreviousMonthlyTotal
      ) as number[];

    const costOfProductsManufacturedCurrentAndPlanDifferenceMonthlyTotal =
      subtract(
        costOfProductsManufacturedCurrentMonthlyTotal,
        costOfProductsManufacturedMonthlyTotal
      ) as number[];

    const costOfProductsManufacturedCurrentAndPlanDifferenceMonthlyTotalRatio =
      dotDivide(
        costOfProductsManufacturedCurrentMonthlyTotal,
        costOfProductsManufacturedMonthlyTotal
      ) as number[];

    const costOfProductsManufacturedCurrentYearlyTotal = subtract(
      add(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.currentYearlyTotal
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.currentYearlyTotal
    ) as number;

    const costOfProductsManufacturedPreviousYearlyTotal = subtract(
      add(
        grossManufacturingCostForThePeriodPreviousYearlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.previousYearlyTotal
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.previousYearlyTotal
    ) as number;

    const costOfProductsManufacturedCurrentAndPreviousDifferenceYearlyTotal =
      subtract(
        costOfProductsManufacturedCurrentYearlyTotal,
        costOfProductsManufacturedPreviousYearlyTotal
      ) as number;

    const costOfProductsManufacturedCurrentAndPreviousDifferenceYearlyTotalRatio =
      dotDivide(
        costOfProductsManufacturedCurrentYearlyTotal,
        costOfProductsManufacturedPreviousYearlyTotal
      ) as number;

    const costOfProductsManufacturedCurrentAndPlanDifferenceYearlyTotal =
      subtract(
        costOfProductsManufacturedCurrentYearlyTotal,
        costOfProductsManufacturedPlansInputTotal
      ) as number;

    const costOfProductsManufacturedCurrentAndPlanDifferenceYearlyTotalRatio =
      dotDivide(
        costOfProductsManufacturedCurrentYearlyTotal,
        costOfProductsManufacturedPlansInputTotal
      ) as number;

    // 累計用
    const costOfProductsManufacturedPlanMonthlyTotalCumsum = cumsum(
      costOfProductsManufacturedMonthlyTotal
    ) as number[];
    const costOfProductsManufacturedCurrentMonthlyTotalCumsum = cumsum(
      costOfProductsManufacturedCurrentMonthlyTotal
    ) as number[];
    const costOfProductsManufacturedPreviousMonthlyTotalCumsum = cumsum(
      costOfProductsManufacturedPreviousMonthlyTotal
    ) as number[];

    const costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        costOfProductsManufacturedCurrentMonthlyTotalCumsum,
        costOfProductsManufacturedPreviousMonthlyTotalCumsum
      ) as number[];
    const costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        costOfProductsManufacturedCurrentMonthlyTotalCumsum,
        costOfProductsManufacturedPreviousMonthlyTotalCumsum
      ) as number[];

    const costOfProductsManufacturedPlanCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        costOfProductsManufacturedCurrentMonthlyTotalCumsum,
        costOfProductsManufacturedPlanMonthlyTotalCumsum
      ) as number[];
    const costOfProductsManufacturedPlanCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        costOfProductsManufacturedCurrentMonthlyTotalCumsum,
        costOfProductsManufacturedPlanMonthlyTotalCumsum
      ) as number[];

    const costOfProductsManufactured = {
      current_term_total: costOfProductsManufacturedCurrentTermTotal,
      plans_input_total: costOfProductsManufacturedPlansInputTotal,
      remining_total: costOfProductsManufacturedReminingTotal,
      monthly_total: costOfProductsManufacturedMonthlyTotal,
      yearly_total: costOfProductsManufacturedYearly,
      current_monthly_total: costOfProductsManufacturedCurrentMonthlyTotal,
      current_monthly_ratio: null,
      current_yearly_total: costOfProductsManufacturedCurrentYearlyTotal,
      current_yearly_ratio: null,
      previous_monthly_total: costOfProductsManufacturedPreviousMonthlyTotal,
      previous_monthly_ratio: null,
      previous_yearly_total: costOfProductsManufacturedPreviousYearlyTotal,
      previous_yearly_ratio: null,
      current_and_previous_difference_monthly_total:
        costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio: null,
      current_and_previous_difference_monthly_total_ratio:
        costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        costOfProductsManufacturedCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio: null,
      current_and_plan_difference_monthly_total_ratio:
        costOfProductsManufacturedCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        costOfProductsManufacturedCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio: null,
      current_and_previous_difference_yearly_total_ratio:
        costOfProductsManufacturedCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        costOfProductsManufacturedCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio: null,
      current_and_plan_difference_yearly_total_ratio:
        costOfProductsManufacturedCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum:
        costOfProductsManufacturedPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: null,
      current_monthly_total_cumsum:
        costOfProductsManufacturedCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: null,
      previous_monthly_total_cumsum:
        costOfProductsManufacturedPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_cumsum:
        costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum: null,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        costOfProductsManufacturedCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        costOfProductsManufacturedPlanCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum: null,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        costOfProductsManufacturedPlanCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
    };

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCostDataCurrentTerm = add(
      sellingAndAdministrativeTotalByItemCurrentTerm[0],
      expensesTotalByItemCurrentTerm[0]
    );
    const depreciationCostDataPlansInput = add(
      sellingAndAdministrativeTotalByItemPlansInput[0],
      expensesTotalByItemPlansInput[0]
    );

    const depreciationCostDataReminingPlansInputMonthly = add(
      sellingAndAdministrativeTotalByItemPlansInputMonthly.byItem[0],
      expensesTotalByItemPlansInputMonthly.byItem[0]
    );

    const depreciationCostDataCurrentMonthlyTotal = add(
      sellingAndAdministrativeForBudgetControl.currentByItem[0],
      expensesForBudgetControl.currentByItem[0]
    );

    const depreciationCostDataPreviousMonthlyTotal = add(
      sellingAndAdministrativeForBudgetControl.previousByItem[0],
      expensesForBudgetControl.previousByItem[0]
    );

    const depreciationCostDataCurrentYearlyTotal = add(
      sellingAndAdministrativeForBudgetControl.currentByItemTotal[0],
      expensesForBudgetControl.currentByItemTotal[0]
    );

    const depreciationCostDataPreviousYearlyTotal = add(
      sellingAndAdministrativeForBudgetControl.previousByItemTotal[0],
      expensesForBudgetControl.previousByItemTotal[0]
    );

    // 累計用
    const depreciationCostDataReminingPlansInputMonthlyCumsum = cumsum(
      depreciationCostDataReminingPlansInputMonthly
    ) as number[];
    const depreciationCostDataCurrentMonthlyTotalCumsum = cumsum(
      depreciationCostDataCurrentMonthlyTotal
    ) as number[];
    const depreciationCostDataPreviousMonthlyTotalCumsum = cumsum(
      depreciationCostDataPreviousMonthlyTotal
    ) as number[];

    const depreciationCostTotal = {
      monthly_total: depreciationCostDataReminingPlansInputMonthly,
      plans_input_total: depreciationCostDataPlansInput,
      current_monthly_total: depreciationCostDataCurrentMonthlyTotal,
      current_yearly_total: depreciationCostDataCurrentYearlyTotal,
      previous_monthly_total: depreciationCostDataPreviousMonthlyTotal,
      previous_yearly_total: depreciationCostDataPreviousYearlyTotal,
      // 累計用
      plan_monthly_total_cumsum:
        depreciationCostDataReminingPlansInputMonthlyCumsum,
      current_monthly_total_cumsum:
        depreciationCostDataCurrentMonthlyTotalCumsum,
      previous_monthly_total_cumsum:
        depreciationCostDataPreviousMonthlyTotalCumsum,
    };

    // 償却前営業利益
    const operatingProfitsBeforeAmortizationTotal: number[] =
      operatingIncomeMonthlyTotal.length !== 0
        ? add(
            operatingIncomeMonthlyTotal,
            depreciationCostDataReminingPlansInputMonthly
          )
        : depreciationCostDataReminingPlansInputMonthly;
    const operatingProfitsBeforeAmortizationRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: operatingProfitsBeforeAmortizationTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : operatingProfitsBeforeAmortizationTotal;
    const operatingProfitsBeforeAmortizationCurrentTermTotal = add(
      operatingIncomeCurrentTermTotal,
      depreciationCostDataCurrentTerm
    );
    const operatingProfitsBeforeAmortizationCurrentTermRatio = divide(
      operatingProfitsBeforeAmortizationCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const operatingProfitsBeforeAmortizationPlansInputTotal = add(
      operatingIncomePlansInputTotal,
      depreciationCostDataPlansInput
    );
    const operatingProfitsBeforeAmortizationPlansInputRatio = divide(
      operatingProfitsBeforeAmortizationPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const operatingProfitsBeforeAmortizationReminingTotal = subtract(
      operatingProfitsBeforeAmortizationPlansInputTotal,
      operatingProfitsBeforeAmortizationCurrentTermTotal
    );
    const operatingProfitsBeforeAmortizationReminingRatio = divide(
      operatingProfitsBeforeAmortizationReminingTotal,
      netSalesTotalByItemReminingTotal
    );
    const operatingProfitsBeforeAmortizationYearly = subtract(
      operatingProfitsBeforeAmortizationPlansInputTotal,
      sum(operatingProfitsBeforeAmortizationTotal)
    );
    const operatingProfitsBeforeAmortizationYearlyTotal = sum(
      operatingProfitsBeforeAmortizationYearly
    );
    const operatingProfitsBeforeAmortizationMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(operatingProfitsBeforeAmortizationTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : operatingProfitsBeforeAmortizationTotal;

    const operatingProfitsBeforeAmortizationCurrentMonthlyTotal = add(
      operatingIncomeCurrentMonthlyTotal,
      depreciationCostDataCurrentMonthlyTotal
    ) as number[];
    const operatingProfitsBeforeAmortizationCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : operatingProfitsBeforeAmortizationCurrentMonthlyTotal;

    const operatingProfitsBeforeAmortizationPreviousMonthlyTotal = add(
      operatingIncomePreviousMonthlyTotal,
      depreciationCostDataPreviousMonthlyTotal
    ) as number[];
    const operatingProfitsBeforeAmortizationPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingProfitsBeforeAmortizationPreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : operatingProfitsBeforeAmortizationPreviousMonthlyTotal;

    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotal =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
        operatingProfitsBeforeAmortizationPreviousMonthlyTotal
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyRatio =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyRatio,
        operatingProfitsBeforeAmortizationPreviousMonthlyRatio
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalRatio =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
        operatingProfitsBeforeAmortizationPreviousMonthlyTotal
      ) as number[];

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotal =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
        operatingProfitsBeforeAmortizationTotal
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyRatio =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyRatio,
        operatingProfitsBeforeAmortizationRatio
      ) as number[];

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalRatio =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
        operatingProfitsBeforeAmortizationTotal
      ) as number[];

    const operatingProfitsBeforeAmortizationCurrentYearlyTotal = add(
      operatingIncomeCurrentYearlyTotal,
      depreciationCostDataCurrentYearlyTotal
    ) as number;
    const operatingProfitsBeforeAmortizationCurrentYearlyRatio = dotDivide(
      operatingProfitsBeforeAmortizationCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const operatingProfitsBeforeAmortizationPreviousYearlyTotal = add(
      operatingIncomePreviousYearlyTotal,
      depreciationCostDataPreviousYearlyTotal
    ) as number;
    const operatingProfitsBeforeAmortizationPreviousYearlyRatio = dotDivide(
      operatingProfitsBeforeAmortizationPreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyTotal =
      subtract(
        operatingProfitsBeforeAmortizationCurrentYearlyTotal,
        operatingProfitsBeforeAmortizationPreviousYearlyTotal
      ) as number;
    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyRatio =
      subtract(
        operatingProfitsBeforeAmortizationCurrentYearlyRatio,
        operatingProfitsBeforeAmortizationPreviousYearlyRatio
      ) as number;

    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyTotalRatio =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentYearlyTotal,
        operatingProfitsBeforeAmortizationPreviousYearlyTotal
      ) as number;

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyTotal =
      subtract(
        operatingProfitsBeforeAmortizationCurrentYearlyTotal,
        operatingProfitsBeforeAmortizationPlansInputTotal
      ) as number;
    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyRatio =
      subtract(
        operatingProfitsBeforeAmortizationCurrentYearlyRatio,
        operatingProfitsBeforeAmortizationPlansInputRatio
      ) as number;

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyTotalRatio =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentYearlyTotal,
        operatingProfitsBeforeAmortizationPlansInputTotal
      ) as number;

    // 累計用
    const operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum = cumsum(
      operatingProfitsBeforeAmortizationTotal
    ) as number[];
    const operatingProfitsBeforeAmortizationPlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum;

    const operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum = cumsum(
      operatingProfitsBeforeAmortizationCurrentMonthlyTotal
    ) as number[];
    const operatingProfitsBeforeAmortizationCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum;

    const operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum = cumsum(
      operatingProfitsBeforeAmortizationPreviousMonthlyTotal
    ) as number[];
    const operatingProfitsBeforeAmortizationPreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum;

    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalCumsum =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
        operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyRatioCumsum =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyRatioCumsum,
        operatingProfitsBeforeAmortizationPreviousMonthlyRatioCumsum
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
        operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum
      ) as number[];

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalCumsum =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
        operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum
      ) as number[];
    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyRatioCumsum =
      subtract(
        operatingProfitsBeforeAmortizationCurrentMonthlyRatioCumsum,
        operatingProfitsBeforeAmortizationPlanMonthlyRatioCumsum
      ) as number[];

    const operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
        operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum
      ) as number[];

    const operatingProfitsBeforeAmortization = {
      current_term_total: operatingProfitsBeforeAmortizationCurrentTermTotal,
      current_term_ratio: operatingProfitsBeforeAmortizationCurrentTermRatio,
      plans_input_total: operatingProfitsBeforeAmortizationPlansInputTotal,
      plans_input_ratio: operatingProfitsBeforeAmortizationPlansInputRatio,
      remining_total: operatingProfitsBeforeAmortizationReminingTotal,
      remining_ratio: operatingProfitsBeforeAmortizationReminingRatio,
      monthly_total: operatingProfitsBeforeAmortizationTotal,
      monthly_ratio: operatingProfitsBeforeAmortizationRatio,
      monthly_total_ratio: operatingProfitsBeforeAmortizationMonthlyTotalRatio,
      yearly_total: operatingProfitsBeforeAmortizationYearlyTotal,
      current_monthly_total:
        operatingProfitsBeforeAmortizationCurrentMonthlyTotal,
      current_monthly_ratio:
        operatingProfitsBeforeAmortizationCurrentMonthlyRatio,
      current_yearly_total:
        operatingProfitsBeforeAmortizationCurrentYearlyTotal,
      current_yearly_ratio:
        operatingProfitsBeforeAmortizationCurrentYearlyRatio,
      previous_monthly_total:
        operatingProfitsBeforeAmortizationPreviousMonthlyTotal,
      previous_monthly_ratio:
        operatingProfitsBeforeAmortizationPreviousMonthlyRatio,
      previous_yearly_total:
        operatingProfitsBeforeAmortizationPreviousYearlyTotal,
      previous_yearly_ratio:
        operatingProfitsBeforeAmortizationPreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum:
        operatingProfitsBeforeAmortizationPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum:
        operatingProfitsBeforeAmortizationPlanMonthlyRatioCumsum,
      current_monthly_total_cumsum:
        operatingProfitsBeforeAmortizationCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum:
        operatingProfitsBeforeAmortizationCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum:
        operatingProfitsBeforeAmortizationPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum:
        operatingProfitsBeforeAmortizationPreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        operatingProfitsBeforeAmortizationCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 償却前経常利益
    const ebitdaTotal: number[] = add(
      currentEarningsMonthlyTotal,
      depreciationCostDataReminingPlansInputMonthly
    );
    const ebitdaRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: ebitdaTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : ebitdaTotal;
    const ebitdaCurrentTermTotal = add(
      currentEarningsCurrentTermTotal,
      depreciationCostDataCurrentTerm
    );
    const ebitdaCurrentTermRatio = divide(
      ebitdaCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const ebitdaPlansInputTotal = add(
      currentEarningsPlansInputTotal,
      depreciationCostDataPlansInput
    );
    const ebitdaPlansInputRatio = divide(
      ebitdaPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const ebitdaReminingTotal = subtract(
      ebitdaPlansInputTotal,
      ebitdaCurrentTermTotal
    );
    const ebitdaReminingRatio = divide(
      ebitdaReminingTotal,
      netSalesTotalByItemReminingTotal
    );
    const ebitdaYearly = subtract(ebitdaPlansInputTotal, sum(ebitdaTotal));
    const ebitdaYearlyTotal = sum(ebitdaYearly);
    const ebitdaMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(ebitdaTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : ebitdaTotal;

    const ebitdaCurrentMonthlyTotal = add(
      currentEarningsCurrentMonthlyTotal,
      depreciationCostDataCurrentMonthlyTotal
    ) as number[];
    const ebitdaCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitdaCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : ebitdaCurrentMonthlyTotal;

    const ebitdaPreviousMonthlyTotal = add(
      currentEarningsPreviousMonthlyTotal,
      depreciationCostDataPreviousMonthlyTotal
    ) as number[];
    const ebitdaPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitdaPreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : ebitdaPreviousMonthlyTotal;

    const ebitdaCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      ebitdaCurrentMonthlyTotal,
      ebitdaPreviousMonthlyTotal
    ) as number[];
    const ebitdaCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      ebitdaCurrentMonthlyRatio,
      ebitdaPreviousMonthlyRatio
    ) as number[];
    const ebitdaCurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      ebitdaCurrentMonthlyTotal,
      ebitdaPreviousMonthlyTotal
    ) as number[];

    const ebitdaCurrentAndPlanDifferenceMonthlyTotal = subtract(
      ebitdaCurrentMonthlyTotal,
      ebitdaTotal
    ) as number[];
    const ebitdaCurrentAndPlanDifferenceMonthlyRatio = subtract(
      ebitdaCurrentMonthlyRatio,
      ebitdaRatio
    ) as number[];

    const ebitdaCurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      ebitdaCurrentMonthlyTotal,
      ebitdaTotal
    ) as number[];

    const ebitdaCurrentYearlyTotal = add(
      currentEarningsCurrentYearlyTotal,
      depreciationCostDataCurrentYearlyTotal
    ) as number;
    const ebitdaCurrentYearlyRatio = dotDivide(
      ebitdaCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const ebitdaPreviousYearlyTotal = add(
      currentEarningsPreviousYearlyTotal,
      depreciationCostDataPreviousYearlyTotal
    ) as number;
    const ebitdaPreviousYearlyRatio = dotDivide(
      ebitdaPreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const ebitdaCurrentAndPreviousDifferenceYearlyTotal = subtract(
      ebitdaCurrentYearlyTotal,
      ebitdaPreviousYearlyTotal
    ) as number;
    const ebitdaCurrentAndPreviousDifferenceYearlyRatio = subtract(
      ebitdaCurrentYearlyRatio,
      ebitdaPreviousYearlyRatio
    ) as number;

    const ebitdaCurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      ebitdaCurrentYearlyTotal,
      ebitdaPreviousYearlyTotal
    ) as number;

    const ebitdaCurrentAndPlanDifferenceYearlyTotal = subtract(
      ebitdaCurrentYearlyTotal,
      ebitdaPlansInputTotal
    ) as number;
    const ebitdaCurrentAndPlanDifferenceYearlyRatio = subtract(
      ebitdaCurrentYearlyRatio,
      ebitdaPlansInputRatio
    ) as number;

    const ebitdaCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      ebitdaCurrentYearlyTotal,
      ebitdaPlansInputTotal
    ) as number;

    // 累計用
    const ebitdaPlanMonthlyTotalCumsum = cumsum(ebitdaTotal) as number[];
    const ebitdaPlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            ebitdaPlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : ebitdaPlanMonthlyTotalCumsum;

    const ebitdaCurrentMonthlyTotalCumsum = cumsum(
      ebitdaCurrentMonthlyTotal
    ) as number[];
    const ebitdaCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitdaCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : ebitdaCurrentMonthlyTotalCumsum;

    const ebitdaPreviousMonthlyTotalCumsum = cumsum(
      ebitdaPreviousMonthlyTotal
    ) as number[];
    const ebitdaPreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitdaPreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : ebitdaPreviousMonthlyTotalCumsum;

    const ebitdaCurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      ebitdaCurrentMonthlyTotalCumsum,
      ebitdaPreviousMonthlyTotalCumsum
    ) as number[];
    const ebitdaCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      ebitdaCurrentMonthlyRatioCumsum,
      ebitdaPreviousMonthlyRatioCumsum
    ) as number[];
    const ebitdaCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum = dotDivide(
      ebitdaCurrentMonthlyTotalCumsum,
      ebitdaPreviousMonthlyTotalCumsum
    ) as number[];

    const ebitdaCurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      ebitdaCurrentMonthlyTotalCumsum,
      ebitdaPlanMonthlyTotalCumsum
    ) as number[];
    const ebitdaCurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      ebitdaCurrentMonthlyRatioCumsum,
      ebitdaPlanMonthlyRatioCumsum
    ) as number[];

    const ebitdaCurrentAndPlanDifferenceMonthlyTotalRatioCumsum = dotDivide(
      ebitdaCurrentMonthlyTotalCumsum,
      ebitdaPlanMonthlyTotalCumsum
    ) as number[];

    const ebitda = {
      current_term_total: ebitdaCurrentTermTotal,
      current_term_ratio: ebitdaCurrentTermRatio,
      plans_input_total: ebitdaPlansInputTotal,
      plans_input_ratio: ebitdaPlansInputRatio,
      remining_total: ebitdaReminingTotal,
      remining_ratio: ebitdaReminingRatio,
      monthly_total: ebitdaTotal,
      monthly_ratio: ebitdaRatio,
      monthly_total_ratio: ebitdaMonthlyTotalRatio,
      yearly_total: ebitdaYearlyTotal,
      current_monthly_total: ebitdaCurrentMonthlyTotal,
      current_monthly_ratio: ebitdaCurrentMonthlyRatio,
      current_yearly_total: ebitdaCurrentYearlyTotal,
      current_yearly_ratio: ebitdaCurrentYearlyRatio,
      previous_monthly_total: ebitdaPreviousMonthlyTotal,
      previous_monthly_ratio: ebitdaPreviousMonthlyRatio,
      previous_yearly_total: ebitdaPreviousYearlyTotal,
      previous_yearly_ratio: ebitdaPreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        ebitdaCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        ebitdaCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        ebitdaCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        ebitdaCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        ebitdaCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        ebitdaCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        ebitdaCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        ebitdaCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        ebitdaCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        ebitdaCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        ebitdaCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        ebitdaCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: ebitdaPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: ebitdaPlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: ebitdaCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: ebitdaCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: ebitdaPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: ebitdaPreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        ebitdaCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        ebitdaCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        ebitdaCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        ebitdaCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        ebitdaCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        ebitdaCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebitMonthlyTotal: number[] = subtract(
      add(currentEarningsMonthlyTotal, 0),
      0
    ) as number[];
    const ebitMonthlyRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: ebitMonthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : ebitMonthlyTotal;

    const ebitCurrentTermTotal = subtract(
      add(
        sum(currentEarningsCurrentTermTotal),
        sum(specialBenefitsTotalByItemCurrentTerm)
      ),
      sum(specialLossesTotalByItemCurrentTerm)
    );
    const ebitCurrentTermRatio = divide(
      ebitCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const ebitPlansInputTotal = subtract(
      add(
        sum(currentEarningsPlansInputTotal),
        sum(specialBenefitsTotalByItemPlansInput)
      ),
      sum(specialLossesTotalByItemPlansInput)
    );
    const ebitPlansInputRatio = divide(
      ebitPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const ebitReminingTotal = subtract(
      ebitPlansInputTotal,
      ebitCurrentTermTotal
    );
    const ebitReminingRatio = divide(
      sum(ebitReminingTotal),
      sum(netSalesTotalByItemRemining)
    );
    const ebitYearly = subtract(ebitPlansInputTotal, sum(ebitMonthlyTotal));
    const ebitYearlyTotal = sum(ebitYearly);
    const ebitMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(ebitMonthlyTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : sum(ebitMonthlyTotal);

    const ebitCurrentMonthlyTotal = subtract(
      add(
        currentEarningsCurrentMonthlyTotal,
        specialBenefitsForBudgetControl.currentMonthlyTotal
      ),
      specialLossesForBudgetControl.currentMonthlyTotal
    ) as number[];
    const ebitCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : ebitCurrentMonthlyTotal;

    const ebitPreviousMonthlyTotal = subtract(
      add(
        currentEarningsPreviousMonthlyTotal,
        specialBenefitsForBudgetControl.previousMonthlyTotal
      ),
      specialLossesForBudgetControl.previousMonthlyTotal
    ) as number[];
    const ebitPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitPreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : ebitPreviousMonthlyTotal;

    const ebitCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      ebitCurrentMonthlyTotal,
      ebitPreviousMonthlyTotal
    ) as number[];
    const ebitCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      ebitCurrentMonthlyRatio,
      ebitPreviousMonthlyRatio
    ) as number[];
    const ebitCurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      ebitCurrentMonthlyTotal,
      ebitPreviousMonthlyTotal
    ) as number[];

    const ebitCurrentAndPlanDifferenceMonthlyTotal = subtract(
      ebitCurrentMonthlyTotal,
      ebitMonthlyTotal
    ) as number[];
    const ebitCurrentAndPlanDifferenceMonthlyRatio = subtract(
      ebitCurrentMonthlyRatio,
      ebitMonthlyRatio
    ) as number[];

    const ebitCurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      ebitCurrentMonthlyTotal,
      ebitMonthlyTotal
    ) as number[];

    const ebitCurrentYearlyTotal = subtract(
      add(
        currentEarningsCurrentYearlyTotal,
        specialBenefitsForBudgetControl.currentYearlyTotal
      ),
      specialLossesForBudgetControl.currentYearlyTotal
    ) as number;
    const ebitCurrentYearlyRatio = divide(
      ebitCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const ebitPreviousYearlyTotal = subtract(
      add(
        currentEarningsPreviousYearlyTotal,
        specialBenefitsForBudgetControl.previousYearlyTotal
      ),
      specialLossesForBudgetControl.previousYearlyTotal
    ) as number;
    const ebitPreviousYearlyRatio = divide(
      ebitPreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const ebitCurrentAndPreviousDifferenceYearlyTotal = subtract(
      ebitCurrentYearlyTotal,
      ebitPreviousYearlyTotal
    ) as number;
    const ebitCurrentAndPreviousDifferenceYearlyRatio = subtract(
      ebitCurrentYearlyRatio,
      ebitPreviousYearlyRatio
    ) as number;

    const ebitCurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      ebitCurrentYearlyTotal,
      ebitPreviousYearlyTotal
    ) as number;

    const ebitCurrentAndPlanDifferenceYearlyTotal = subtract(
      ebitCurrentYearlyTotal,
      ebitPlansInputTotal
    ) as number;
    const ebitCurrentAndPlanDifferenceYearlyRatio = subtract(
      ebitCurrentYearlyRatio,
      ebitPlansInputRatio
    ) as number;

    const ebitCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      ebitCurrentYearlyTotal,
      ebitPlansInputTotal
    ) as number;

    // 累計用
    const ebitPlanMonthlyTotalCumsum = cumsum(ebitMonthlyTotal) as number[];
    const ebitPlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            ebitPlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : ebitPlanMonthlyTotalCumsum;

    const ebitCurrentMonthlyTotalCumsum = cumsum(
      ebitCurrentMonthlyTotal
    ) as number[];
    const ebitCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : ebitCurrentMonthlyTotalCumsum;

    const ebitPreviousMonthlyTotalCumsum = cumsum(
      ebitPreviousMonthlyTotal
    ) as number[];
    const ebitPreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            ebitPreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : ebitPreviousMonthlyTotalCumsum;

    const ebitCurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      ebitCurrentMonthlyTotalCumsum,
      ebitPreviousMonthlyTotalCumsum
    ) as number[];
    const ebitCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      ebitCurrentMonthlyRatioCumsum,
      ebitPreviousMonthlyRatioCumsum
    ) as number[];
    const ebitCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum = dotDivide(
      ebitCurrentMonthlyTotalCumsum,
      ebitPreviousMonthlyTotalCumsum
    ) as number[];

    const ebitCurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      ebitCurrentMonthlyTotalCumsum,
      ebitPlanMonthlyTotalCumsum
    ) as number[];
    const ebitCurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      ebitCurrentMonthlyRatioCumsum,
      ebitPlanMonthlyRatioCumsum
    ) as number[];

    const ebitCurrentAndPlanDifferenceMonthlyTotalRatioCumsum = dotDivide(
      ebitCurrentMonthlyTotalCumsum,
      ebitPlanMonthlyTotalCumsum
    ) as number[];

    const ebit = {
      current_term_total: ebitCurrentTermTotal,
      current_term_ratio: ebitCurrentTermRatio,
      plans_input_total: ebitPlansInputTotal,
      plans_input_ratio: ebitPlansInputRatio,
      remining_total: ebitReminingTotal,
      remining_ratio: ebitReminingRatio,
      monthly_total: ebitMonthlyTotal,
      monthly_ratio: ebitMonthlyRatio,
      monthly_total_ratio: ebitMonthlyTotalRatio,
      yearly_total: ebitYearlyTotal,
      current_monthly_total: ebitCurrentMonthlyTotal,
      current_monthly_ratio: ebitCurrentMonthlyRatio,
      current_yearly_total: ebitCurrentYearlyTotal,
      current_yearly_ratio: ebitCurrentYearlyRatio,
      previous_monthly_total: ebitPreviousMonthlyTotal,
      previous_monthly_ratio: ebitPreviousMonthlyRatio,
      previous_yearly_total: ebitPreviousYearlyTotal,
      previous_yearly_ratio: ebitPreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        ebitCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        ebitCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        ebitCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        ebitCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        ebitCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        ebitCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        ebitCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        ebitCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        ebitCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        ebitCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        ebitCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        ebitCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: ebitPlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: ebitPlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: ebitCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: ebitCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: ebitPreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: ebitPreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        ebitCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        ebitCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        ebitCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        ebitCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        ebitCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        ebitCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 当期純利益
    const netIncomeMonthlyTotal: number[] = subtract(
      ebitMonthlyTotal,
      0
    ) as number[];
    const netIncomeMonthlyRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: netIncomeMonthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : netIncomeMonthlyTotal;

    const netIncomeCurrentTermTotal = subtract(
      sum(ebitCurrentTermTotal),
      sum(corporateInhabitantAndEnterpriseTaxesTotalByItemCurrentTerm)
    );
    const netIncomeCurrentTermRatio = divide(
      netIncomeCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    );
    const netIncomePlansInputTotal = subtract(
      ebitPlansInputTotal,
      corporateInhabitantAndEnterpriseTaxesTotalByItemPlansInputTotal
    );
    const netIncomePlansInputRatio = divide(
      netIncomePlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const netIncomeReminingTotal = subtract(
      netIncomePlansInputTotal,
      netIncomeCurrentTermTotal
    );
    const netIncomeReminingRatio = divide(
      sum(netIncomeReminingTotal),
      sum(netSalesTotalByItemRemining)
    );
    const netIncomeYearly = subtract(
      netIncomePlansInputTotal,
      sum(netIncomeMonthlyTotal)
    );
    const netIncomeYearlyTotal = sum(netIncomeYearly);
    const netIncomeMonthlyTotalRatio =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? divide(
            sum(netIncomeMonthlyTotal),
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          )
        : netIncomeMonthlyTotal;

    const netIncomeCurrentMonthlyTotal =
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentMonthlyTotal
        .length !== 0
        ? (subtract(
            ebitCurrentMonthlyTotal,
            corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : ebitCurrentMonthlyTotal;
    const netIncomeCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            netIncomeCurrentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : netIncomeCurrentMonthlyTotal;

    const netIncomePreviousMonthlyTotal =
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousMonthlyTotal
        .length !== 0
        ? (subtract(
            ebitPreviousMonthlyTotal,
            corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : ebitPreviousMonthlyTotal;
    const netIncomePreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            netIncomePreviousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : netIncomePreviousMonthlyTotal;

    const netIncomeCurrentAndPreviousDifferenceMonthlyTotal = subtract(
      netIncomeCurrentMonthlyTotal,
      netIncomePreviousMonthlyTotal
    ) as number[];
    const netIncomeCurrentAndPreviousDifferenceMonthlyRatio = subtract(
      netIncomeCurrentMonthlyRatio,
      netIncomePreviousMonthlyRatio
    ) as number[];
    const netIncomeCurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      netIncomeCurrentMonthlyTotal,
      netIncomePreviousMonthlyTotal
    ) as number[];

    const netIncomeCurrentAndPlanDifferenceMonthlyTotal = subtract(
      netIncomeCurrentMonthlyTotal,
      netIncomeMonthlyTotal
    ) as number[];
    const netIncomeCurrentAndPlanDifferenceMonthlyRatio = subtract(
      netIncomeCurrentMonthlyRatio,
      netIncomeMonthlyRatio
    ) as number[];

    const netIncomeCurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      netIncomeCurrentMonthlyTotal,
      netIncomeMonthlyTotal
    ) as number[];

    const netIncomeCurrentYearlyTotal = subtract(
      ebitCurrentYearlyTotal,
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.currentYearlyTotal
    ) as number;
    const netIncomeCurrentYearlyRatio = divide(
      netIncomeCurrentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;

    const netIncomePreviousYearlyTotal = subtract(
      ebitPreviousYearlyTotal,
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.previousYearlyTotal
    ) as number;
    const netIncomePreviousYearlyRatio = divide(
      netIncomePreviousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    const netIncomeCurrentAndPreviousDifferenceYearlyTotal = subtract(
      netIncomeCurrentYearlyTotal,
      netIncomePreviousYearlyTotal
    ) as number;
    const netIncomeCurrentAndPreviousDifferenceYearlyRatio = subtract(
      netIncomeCurrentYearlyRatio,
      netIncomePreviousYearlyRatio
    ) as number;

    const netIncomeCurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      netIncomeCurrentYearlyTotal,
      netIncomePreviousYearlyTotal
    ) as number;

    const netIncomeCurrentAndPlanDifferenceYearlyTotal = subtract(
      netIncomeCurrentYearlyTotal,
      netIncomePlansInputTotal
    ) as number;
    const netIncomeCurrentAndPlanDifferenceYearlyRatio = subtract(
      netIncomeCurrentYearlyRatio,
      netIncomePlansInputRatio
    ) as number;

    const netIncomeCurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      netIncomeCurrentYearlyTotal,
      netIncomePlansInputTotal
    ) as number;

    // 累計用
    const netIncomePlanMonthlyTotalCumsum = cumsum(
      netIncomeMonthlyTotal
    ) as number[];
    const netIncomePlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            netIncomePlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : netIncomePlanMonthlyTotalCumsum;

    const netIncomeCurrentMonthlyTotalCumsum = cumsum(
      netIncomeCurrentMonthlyTotal
    ) as number[];
    const netIncomeCurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            netIncomeCurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : netIncomeCurrentMonthlyTotalCumsum;

    const netIncomePreviousMonthlyTotalCumsum = cumsum(
      netIncomePreviousMonthlyTotal
    ) as number[];
    const netIncomePreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            netIncomePreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : netIncomePreviousMonthlyTotalCumsum;

    const netIncomeCurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      netIncomeCurrentMonthlyTotalCumsum,
      netIncomePreviousMonthlyTotalCumsum
    ) as number[];
    const netIncomeCurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      netIncomeCurrentMonthlyRatioCumsum,
      netIncomePreviousMonthlyRatioCumsum
    ) as number[];
    const netIncomeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        netIncomeCurrentMonthlyTotalCumsum,
        netIncomePreviousMonthlyTotalCumsum
      ) as number[];

    const netIncomeCurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      netIncomeCurrentMonthlyTotalCumsum,
      netIncomePlanMonthlyTotalCumsum
    ) as number[];
    const netIncomeCurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      netIncomeCurrentMonthlyRatioCumsum,
      netIncomePlanMonthlyRatioCumsum
    ) as number[];

    const netIncomeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum = dotDivide(
      netIncomeCurrentMonthlyTotalCumsum,
      netIncomePlanMonthlyTotalCumsum
    ) as number[];

    const netIncome = {
      current_term_total: netIncomeCurrentTermTotal,
      current_term_ratio: netIncomeCurrentTermRatio,
      plans_input_total: netIncomePlansInputTotal,
      plans_input_ratio: netIncomePlansInputRatio,
      remining_total: netIncomeReminingTotal,
      remining_ratio: netIncomeReminingRatio,
      monthly_total: netIncomeMonthlyTotal,
      monthly_ratio: netIncomeMonthlyRatio,
      monthly_total_ratio: netIncomeMonthlyTotalRatio,
      yearly_total: netIncomeYearlyTotal,
      current_monthly_total: netIncomeCurrentMonthlyTotal,
      current_monthly_ratio: netIncomeCurrentMonthlyRatio,
      current_yearly_total: netIncomeCurrentYearlyTotal,
      current_yearly_ratio: netIncomeCurrentYearlyRatio,
      previous_monthly_total: netIncomePreviousMonthlyTotal,
      previous_monthly_ratio: netIncomePreviousMonthlyRatio,
      previous_yearly_total: netIncomePreviousYearlyTotal,
      previous_yearly_ratio: netIncomePreviousYearlyRatio,
      current_and_previous_difference_monthly_total:
        netIncomeCurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_ratio:
        netIncomeCurrentAndPreviousDifferenceMonthlyRatio,
      current_and_previous_difference_monthly_total_ratio:
        netIncomeCurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        netIncomeCurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_ratio:
        netIncomeCurrentAndPlanDifferenceMonthlyRatio,
      current_and_plan_difference_monthly_total_ratio:
        netIncomeCurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        netIncomeCurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_ratio:
        netIncomeCurrentAndPreviousDifferenceYearlyRatio,
      current_and_previous_difference_yearly_total_ratio:
        netIncomeCurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        netIncomeCurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_ratio:
        netIncomeCurrentAndPlanDifferenceYearlyRatio,
      current_and_plan_difference_yearly_total_ratio:
        netIncomeCurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: netIncomePlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: netIncomePlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: netIncomeCurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: netIncomeCurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: netIncomePreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: netIncomePreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        netIncomeCurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        netIncomeCurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        netIncomeCurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        netIncomeCurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        netIncomeCurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        netIncomeCurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 支払利息
    const interestExpensesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: interest_expenses,
    }) as number[];

    interestExpensesTotalByItemCurrentTerm[0] = 0;
    interestExpensesTotalByItemCurrentTerm[
      interestExpensesTotalByItemCurrentTerm.length - 1
    ] = 0;
    const interestExpensesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: interest_expenses,
      });
    const interestExpensesTotalByItemRemining = subtract(
      interestExpensesTotalByItemPlansInput,
      interestExpensesTotalByItemCurrentTerm
    );
    const interestExpensesTotalByItemReminingData = [];
    interestExpensesTotalByItemReminingData.push(
      ...interestExpensesTotalByItemRemining
    );
    interestExpensesTotalByItemReminingData[0] = 0;
    interestExpensesTotalByItemReminingData[
      interestExpensesTotalByItemReminingData.length - 1
    ] = 0;
    const interestExpensesTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: interest_expenses,
        ratio: netSalesTotalPreviousRatio,
        remins: interestExpensesTotalByItemReminingData,
        counts: headers.results_of_the_current_term_count,
      });
    const interestExpensesTotalByItemPlansInputTotal = subtract(
      subtract(
        sum(interestExpensesTotalByItemPlansInput),
        interestExpensesTotalByItemPlansInput[
          interestExpensesTotalByItemPlansInput.length - 1
        ]
      ),
      interestExpensesTotalByItemPlansInput[
        interestExpensesTotalByItemPlansInput.length - 1
      ]
    ) as number;

    const interestExpensesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: null,
      plansTotal: interestExpensesTotalByItemPlansInput,
      plansMonthlyTotal: null,
      plansByItemCumsum: null,
      plansMonthlyTotalCumsum: null,
      states: interest_expenses,
    });

    // キャッシュアウトしない費用
    const nonCashOutTotalByItemCurrentTerm =
      non_cash_out.length === 0
        ? [0]
        : (totalCalcByItem({
            headers: headers[resultsField],
            field: resultsField,
            state: non_cash_out,
          }) as number[]);
    nonCashOutTotalByItemCurrentTerm[0] = 0;
    nonCashOutTotalByItemCurrentTerm[
      nonCashOutTotalByItemCurrentTerm.length - 1
    ] = 0;
    const nonCashOutTotalByItemPlansInput =
      non_cash_out.length === 0
        ? [0]
        : totalCalcByItemForPlansInputMonthly({
            headers: headers[plansField],
            field: plansField,
            state: non_cash_out,
          });

    const nonCashOutTotalByItemRemining = subtract(
      nonCashOutTotalByItemPlansInput,
      nonCashOutTotalByItemCurrentTerm
    );
    const nonCashOutTotalByItemReminingData = [];
    nonCashOutTotalByItemReminingData.push(...nonCashOutTotalByItemRemining);
    nonCashOutTotalByItemReminingData[0] = 0;
    nonCashOutTotalByItemReminingData[
      nonCashOutTotalByItemReminingData.length - 1
    ] = 0;
    const nonCashOutTotalByItemPlansInputMonthly =
      non_cash_out.length === 0
        ? {
            monthlyTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          }
        : calcByItemForPlansInputMonthly({
            states: non_cash_out,
            ratio: netSalesTotalPreviousRatio,
            remins: nonCashOutTotalByItemReminingData,
            counts: headers.results_of_the_current_term_count,
          });
    const nonCashOutTotalByItemPlansInputTotal = subtract(
      subtract(
        sum(nonCashOutTotalByItemPlansInput),
        nonCashOutTotalByItemPlansInput[
          nonCashOutTotalByItemPlansInput.length - 1
        ]
      ),
      nonCashOutTotalByItemPlansInput[
        nonCashOutTotalByItemPlansInput.length - 1
      ]
    ) as number;

    const nonCashOutForBudgetControl =
      non_cash_out.length === 0
        ? {
            monthlyTotal: 0,
            currentMonthlyTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            previousMonthlyTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            currentYearlyTotal: 0,
            previousYearlyTotal: 0,
          }
        : totalCalcForBudgetControlBasis({
            headers: headers,
            resultsField: resultsField,
            previousField: previousField,
            plansState: null,
            plansTotal: nonCashOutTotalByItemPlansInput,
            plansMonthlyTotal: null,
            plansByItemCumsum: null,
            plansMonthlyTotalCumsum: null,
            states: non_cash_out,
          });

    // 簡易CF
    const simpleCfRow1MonthlyTotal = add(
      currentEarningsMonthlyTotal,
      add(
        depreciationCostDataReminingPlansInputMonthly,
        nonCashOutTotalByItemPlansInputMonthly.monthlyTotal
      )
    ) as number[];
    const simpleCfRow1TotalByItemCurrentTerm = add(
      currentEarningsCurrentTermTotal,
      add(depreciationCostDataCurrentTerm, nonCashOutTotalByItemCurrentTerm)
    );
    const simpleCfRow1TotalByItemPlansInput = add(
      currentEarningsPlansInputTotal,
      add(depreciationCostDataPlansInput, nonCashOutTotalByItemPlansInputTotal)
    );
    const simpleCfRow1TotalByItemRemining = subtract(
      simpleCfRow1TotalByItemPlansInput,
      simpleCfRow1TotalByItemCurrentTerm
    );
    const simpleCfRow1TotalByItemPlansInputYearly = subtract(
      simpleCfRow1TotalByItemPlansInput,
      sum(simpleCfRow1MonthlyTotal)
    ) as number;

    const simpleCfRow1CurrentMonthlyTotal = add(
      currentEarningsCurrentMonthlyTotal,
      add(
        depreciationCostDataCurrentMonthlyTotal,
        nonCashOutForBudgetControl.currentMonthlyTotal
      )
    ) as number[];

    const simpleCfRow1PreviousMonthlyTotal = add(
      currentEarningsPreviousMonthlyTotal,
      add(
        depreciationCostDataPreviousMonthlyTotal,
        nonCashOutForBudgetControl.previousMonthlyTotal
      )
    ) as number[];

    const simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotal = subtract(
      simpleCfRow1CurrentMonthlyTotal,
      simpleCfRow1PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow1CurrentMonthlyTotal,
      simpleCfRow1PreviousMonthlyTotal
    ) as number[];

    const simpleCfRow1CurrentAndPlanDifferenceMonthlyTotal = subtract(
      simpleCfRow1CurrentMonthlyTotal,
      simpleCfRow1MonthlyTotal
    ) as number[];

    const simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow1CurrentMonthlyTotal,
      simpleCfRow1MonthlyTotal
    ) as number[];

    const simpleCfRow1CurrentYearlyTotal = add(
      currentEarningsCurrentYearlyTotal,
      add(
        depreciationCostDataCurrentYearlyTotal,
        netSalesForBudgetControl.currentYearlyTotal
      )
    ) as number;

    const simpleCfRow1PreviousYearlyTotal = add(
      currentEarningsPreviousYearlyTotal,
      add(
        depreciationCostDataPreviousYearlyTotal,
        netSalesForBudgetControl.previousYearlyTotal
      )
    ) as number;

    const simpleCfRow1CurrentAndPreviousDifferenceYearlyTotal = subtract(
      simpleCfRow1CurrentYearlyTotal,
      simpleCfRow1PreviousYearlyTotal
    ) as number;

    const simpleCfRow1CurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow1CurrentYearlyTotal,
      simpleCfRow1PreviousYearlyTotal
    ) as number;

    const simpleCfRow1CurrentAndPlanDifferenceYearlyTotal = subtract(
      simpleCfRow1CurrentYearlyTotal,
      simpleCfRow1TotalByItemPlansInput
    ) as number;

    const simpleCfRow1CurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow1CurrentYearlyTotal,
      simpleCfRow1TotalByItemPlansInput
    ) as number;

    // 累計用
    const simpleCfRow1PlanMonthlyTotalCumsum = cumsum(
      simpleCfRow1MonthlyTotal
    ) as number[];
    const simpleCfRow1PlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            simpleCfRow1PlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : simpleCfRow1PlanMonthlyTotalCumsum;

    const simpleCfRow1CurrentMonthlyTotalCumsum = cumsum(
      simpleCfRow1CurrentMonthlyTotal
    ) as number[];
    const simpleCfRow1CurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow1CurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow1CurrentMonthlyTotalCumsum;

    const simpleCfRow1PreviousMonthlyTotalCumsum = cumsum(
      simpleCfRow1PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow1PreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow1PreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow1PreviousMonthlyTotalCumsum;

    const simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow1CurrentMonthlyTotalCumsum,
      simpleCfRow1PreviousMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow1CurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow1CurrentMonthlyRatioCumsum,
      simpleCfRow1PreviousMonthlyRatioCumsum
    ) as number[];
    const simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow1CurrentMonthlyTotalCumsum,
        simpleCfRow1PreviousMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow1CurrentMonthlyTotalCumsum,
      simpleCfRow1PlanMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow1CurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow1CurrentMonthlyRatioCumsum,
      simpleCfRow1PlanMonthlyRatioCumsum
    ) as number[];

    const simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow1CurrentMonthlyTotalCumsum,
        simpleCfRow1PlanMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow1Total = {
      current_term_total: simpleCfRow1TotalByItemCurrentTerm,
      plans_input_total: simpleCfRow1TotalByItemPlansInput,
      remining_total: simpleCfRow1TotalByItemRemining,
      monthly_total: simpleCfRow1MonthlyTotal,
      yearly_total: simpleCfRow1TotalByItemPlansInputYearly,
      current_monthly_total: simpleCfRow1CurrentMonthlyTotal,
      current_yearly_total: simpleCfRow1CurrentYearlyTotal,
      previous_monthly_total: simpleCfRow1PreviousMonthlyTotal,
      previous_yearly_total: simpleCfRow1PreviousYearlyTotal,
      current_and_previous_difference_monthly_total:
        simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        simpleCfRow1CurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        simpleCfRow1CurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        simpleCfRow1CurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        simpleCfRow1CurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        simpleCfRow1CurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: simpleCfRow1PlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: simpleCfRow1PlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: simpleCfRow1CurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: simpleCfRow1CurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: simpleCfRow1PreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: simpleCfRow1PreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        simpleCfRow1CurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        simpleCfRow1CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        simpleCfRow1CurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        simpleCfRow1CurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    const simpleCfRow2MonthlyTotal = add(
      netIncomeMonthlyTotal,
      add(
        depreciationCostDataReminingPlansInputMonthly,
        nonCashOutTotalByItemPlansInputMonthly.monthlyTotal
      )
    ) as number[];
    const simpleCfRow2TotalByItemCurrentTerm = add(
      netIncomeCurrentTermTotal,
      add(depreciationCostDataCurrentTerm, nonCashOutTotalByItemCurrentTerm)
    );
    const simpleCfRow2TotalByItemPlansInput = add(
      netIncomePlansInputTotal,
      add(depreciationCostDataPlansInput, nonCashOutTotalByItemPlansInputTotal)
    );
    const simpleCfRow2TotalByItemRemining = subtract(
      simpleCfRow2TotalByItemPlansInput,
      simpleCfRow2TotalByItemCurrentTerm
    );
    const simpleCfRow2TotalByItemPlansInputYearly = subtract(
      simpleCfRow2TotalByItemPlansInput,
      sum(simpleCfRow2MonthlyTotal) as number
    );

    const simpleCfRow2CurrentMonthlyTotal = add(
      netIncomeCurrentMonthlyTotal,
      add(
        depreciationCostDataCurrentMonthlyTotal,
        nonCashOutForBudgetControl.currentMonthlyTotal
      )
    ) as number[];

    const simpleCfRow2PreviousMonthlyTotal = add(
      netIncomePreviousMonthlyTotal,
      add(
        depreciationCostDataPreviousMonthlyTotal,
        nonCashOutForBudgetControl.previousMonthlyTotal
      )
    ) as number[];

    const simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotal = subtract(
      simpleCfRow2CurrentMonthlyTotal,
      simpleCfRow2PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow2CurrentMonthlyTotal,
      simpleCfRow2PreviousMonthlyTotal
    ) as number[];

    const simpleCfRow2CurrentAndPlanDifferenceMonthlyTotal = subtract(
      simpleCfRow2CurrentMonthlyTotal,
      simpleCfRow2MonthlyTotal
    ) as number[];

    const simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow2CurrentMonthlyTotal,
      simpleCfRow2MonthlyTotal
    ) as number[];

    const simpleCfRow2CurrentYearlyTotal = add(
      netIncomeCurrentYearlyTotal,
      add(
        depreciationCostDataCurrentYearlyTotal,
        netSalesForBudgetControl.currentYearlyTotal
      )
    ) as number;

    const simpleCfRow2PreviousYearlyTotal = add(
      netIncomePreviousYearlyTotal,
      add(
        depreciationCostDataPreviousYearlyTotal,
        netSalesForBudgetControl.previousYearlyTotal
      )
    ) as number;

    const simpleCfRow2CurrentAndPreviousDifferenceYearlyTotal = subtract(
      simpleCfRow2CurrentYearlyTotal,
      simpleCfRow2PreviousYearlyTotal
    ) as number;

    const simpleCfRow2CurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow2CurrentYearlyTotal,
      simpleCfRow2PreviousYearlyTotal
    ) as number;

    const simpleCfRow2CurrentAndPlanDifferenceYearlyTotal = subtract(
      simpleCfRow2CurrentYearlyTotal,
      simpleCfRow2TotalByItemPlansInput
    ) as number;

    const simpleCfRow2CurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow2CurrentYearlyTotal,
      simpleCfRow2TotalByItemPlansInput
    ) as number;

    // 累計用
    const simpleCfRow2PlanMonthlyTotalCumsum = cumsum(
      simpleCfRow2MonthlyTotal
    ) as number[];
    const simpleCfRow2PlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            simpleCfRow2PlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : simpleCfRow2PlanMonthlyTotalCumsum;

    const simpleCfRow2CurrentMonthlyTotalCumsum = cumsum(
      simpleCfRow2CurrentMonthlyTotal
    ) as number[];
    const simpleCfRow2CurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow2CurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow2CurrentMonthlyTotalCumsum;

    const simpleCfRow2PreviousMonthlyTotalCumsum = cumsum(
      simpleCfRow2PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow2PreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow2PreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow2PreviousMonthlyTotalCumsum;

    const simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow2CurrentMonthlyTotalCumsum,
      simpleCfRow2PreviousMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow2CurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow2CurrentMonthlyRatioCumsum,
      simpleCfRow2PreviousMonthlyRatioCumsum
    ) as number[];
    const simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow2CurrentMonthlyTotalCumsum,
        simpleCfRow2PreviousMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow2CurrentMonthlyTotalCumsum,
      simpleCfRow2PlanMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow2CurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow2CurrentMonthlyRatioCumsum,
      simpleCfRow2PlanMonthlyRatioCumsum
    ) as number[];

    const simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow2CurrentMonthlyTotalCumsum,
        simpleCfRow2PlanMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow2Total = {
      current_term_total: simpleCfRow2TotalByItemCurrentTerm,
      plans_input_total: simpleCfRow2TotalByItemPlansInput,
      remining_total: simpleCfRow2TotalByItemRemining,
      monthly_total: simpleCfRow2MonthlyTotal,
      yearly_total: simpleCfRow2TotalByItemPlansInputYearly,
      current_monthly_total: simpleCfRow2CurrentMonthlyTotal,
      current_yearly_total: simpleCfRow2CurrentYearlyTotal,
      previous_monthly_total: simpleCfRow2PreviousMonthlyTotal,
      previous_yearly_total: simpleCfRow2PreviousYearlyTotal,
      current_and_previous_difference_monthly_total:
        simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        simpleCfRow2CurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        simpleCfRow2CurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        simpleCfRow2CurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        simpleCfRow2CurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        simpleCfRow2CurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: simpleCfRow2PlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: simpleCfRow2PlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: simpleCfRow2CurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: simpleCfRow2CurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: simpleCfRow2PreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: simpleCfRow2PreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        simpleCfRow2CurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        simpleCfRow2CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        simpleCfRow2CurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        simpleCfRow2CurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 簡易CF 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息
    const simpleCfRow3MonthlyTotal = add(
      ebitMonthlyTotal, // 税引き前当期純利益
      add(
        personalCostTotalByItemPlansInputMonthly.monthlyTotal, // 人件費合計
        add(
          laborCostsTotalByItemPlansInputMonthly.monthlyTotal, // 労務費合計
          add(
            depreciationCostDataReminingPlansInputMonthly, // 減価償却費
            add(
              nonCashOutTotalByItemPlansInputMonthly.monthlyTotal, // キャッシュアウトしない費用)
              interestExpensesTotalByItemPlansInputMonthly.monthlyTotal // 支払利息
            )
          )
        )
      )
    ) as number[];

    const simpleCfRow3TotalByItemCurrentTerm = add(
      ebitCurrentTermTotal,
      add(
        personalCostTotalByItemCurrentTermTotal,
        add(
          laborCostsTotalByItemCurrentTermTotal,
          add(
            depreciationCostDataCurrentTerm,
            add(
              nonCashOutTotalByItemCurrentTerm[0],
              interestExpensesTotalByItemCurrentTerm[0]
            )
          )
        )
      )
    ) as number;
    const simpleCfRow3TotalByItemPlansInput = add(
      ebitPlansInputTotal,
      add(
        personalCostTotalByItemPlansInputTotal,
        add(
          laborCostsTotalByItemPlansInputTotal,
          add(
            depreciationCostDataPlansInput,
            add(
              nonCashOutTotalByItemPlansInputTotal,
              interestExpensesTotalByItemPlansInputTotal
            )
          )
        )
      )
    ) as number;
    const simpleCfRow3TotalByItemRemining = subtract(
      simpleCfRow3TotalByItemPlansInput,
      simpleCfRow3TotalByItemCurrentTerm
    );
    const simpleCfRow3TotalByItemPlansInputYearly = subtract(
      simpleCfRow3TotalByItemPlansInput,
      sum(simpleCfRow3MonthlyTotal)
    );

    const simpleCfRow3CurrentMonthlyTotal = add(
      ebitCurrentMonthlyTotal,
      add(
        personalCostForBudgetControl.currentMonthlyTotal,
        add(
          laborCostsForBudgetControl.currentMonthlyTotal,
          add(
            depreciationCostDataCurrentMonthlyTotal,
            add(
              nonCashOutForBudgetControl.currentMonthlyTotal,
              interestExpensesForBudgetControl.currentMonthlyTotal
            )
          )
        )
      )
    ) as number[];

    const simpleCfRow3PreviousMonthlyTotal = add(
      ebitPreviousMonthlyTotal,
      add(
        personalCostForBudgetControl.previousMonthlyTotal,
        add(
          laborCostsForBudgetControl.previousMonthlyTotal,
          add(
            depreciationCostDataPreviousMonthlyTotal,
            add(
              nonCashOutForBudgetControl.previousMonthlyTotal,
              interestExpensesForBudgetControl.previousMonthlyTotal
            )
          )
        )
      )
    ) as number[];

    const simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotal = subtract(
      simpleCfRow3CurrentMonthlyTotal,
      simpleCfRow3PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow3CurrentMonthlyTotal,
      simpleCfRow3PreviousMonthlyTotal
    ) as number[];

    const simpleCfRow3CurrentAndPlanDifferenceMonthlyTotal = subtract(
      simpleCfRow3CurrentMonthlyTotal,
      simpleCfRow3MonthlyTotal
    ) as number[];

    const simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalRatio = dotDivide(
      simpleCfRow3CurrentMonthlyTotal,
      simpleCfRow3MonthlyTotal
    ) as number[];

    const simpleCfRow3CurrentYearlyTotal = add(
      ebitCurrentYearlyTotal,
      add(
        personalCostForBudgetControl.currentYearlyTotal,
        add(
          laborCostsForBudgetControl.currentYearlyTotal,
          add(
            depreciationCostDataCurrentYearlyTotal,
            add(
              nonCashOutForBudgetControl.currentYearlyTotal,
              interestExpensesForBudgetControl.currentYearlyTotal
            )
          )
        )
      )
    ) as number;

    const simpleCfRow3PreviousYearlyTotal = add(
      ebitPreviousYearlyTotal,
      add(
        personalCostForBudgetControl.previousYearlyTotal,
        add(
          laborCostsForBudgetControl.previousYearlyTotal,
          add(
            depreciationCostDataPreviousYearlyTotal,
            add(
              nonCashOutForBudgetControl.previousYearlyTotal,
              interestExpensesForBudgetControl.previousYearlyTotal
            )
          )
        )
      )
    ) as number;

    const simpleCfRow3CurrentAndPreviousDifferenceYearlyTotal = subtract(
      simpleCfRow3CurrentYearlyTotal,
      simpleCfRow3PreviousYearlyTotal
    ) as number;

    const simpleCfRow3CurrentAndPreviousDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow3CurrentYearlyTotal,
      simpleCfRow3PreviousYearlyTotal
    ) as number;

    const simpleCfRow3CurrentAndPlanDifferenceYearlyTotal = subtract(
      simpleCfRow3CurrentYearlyTotal,
      simpleCfRow3TotalByItemPlansInput
    ) as number;

    const simpleCfRow3CurrentAndPlanDifferenceYearlyTotalRatio = dotDivide(
      simpleCfRow3CurrentYearlyTotal,
      simpleCfRow3TotalByItemPlansInput
    ) as number;

    // 累計用
    const simpleCfRow3PlanMonthlyTotalCumsum = cumsum(
      simpleCfRow3MonthlyTotal
    ) as number[];
    const simpleCfRow3PlanMonthlyRatioCumsum: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            simpleCfRow3PlanMonthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : simpleCfRow3PlanMonthlyTotalCumsum;

    const simpleCfRow3CurrentMonthlyTotalCumsum = cumsum(
      simpleCfRow3CurrentMonthlyTotal
    ) as number[];
    const simpleCfRow3CurrentMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow3CurrentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow3CurrentMonthlyTotalCumsum;

    const simpleCfRow3PreviousMonthlyTotalCumsum = cumsum(
      simpleCfRow3PreviousMonthlyTotal
    ) as number[];
    const simpleCfRow3PreviousMonthlyRatioCumsum: number[] =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            simpleCfRow3PreviousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : simpleCfRow3PreviousMonthlyTotalCumsum;

    const simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow3CurrentMonthlyTotalCumsum,
      simpleCfRow3PreviousMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow3CurrentAndPreviousDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow3CurrentMonthlyRatioCumsum,
      simpleCfRow3PreviousMonthlyRatioCumsum
    ) as number[];
    const simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow3CurrentMonthlyTotalCumsum,
        simpleCfRow3PreviousMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalCumsum = subtract(
      simpleCfRow3CurrentMonthlyTotalCumsum,
      simpleCfRow3PlanMonthlyTotalCumsum
    ) as number[];
    const simpleCfRow3CurrentAndPlanDifferenceMonthlyRatioCumsum = subtract(
      simpleCfRow3CurrentMonthlyRatioCumsum,
      simpleCfRow3PlanMonthlyRatioCumsum
    ) as number[];

    const simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalRatioCumsum =
      dotDivide(
        simpleCfRow3CurrentMonthlyTotalCumsum,
        simpleCfRow3PlanMonthlyTotalCumsum
      ) as number[];

    const simpleCfRow3Total = {
      current_term_total: simpleCfRow3TotalByItemCurrentTerm,
      plans_input_total: simpleCfRow3TotalByItemPlansInput,
      remining_total: simpleCfRow3TotalByItemRemining,
      monthly_total: simpleCfRow3MonthlyTotal,
      yearly_total: simpleCfRow3TotalByItemPlansInputYearly,
      current_monthly_total: simpleCfRow3CurrentMonthlyTotal,
      current_yearly_total: simpleCfRow3CurrentYearlyTotal,
      previous_monthly_total: simpleCfRow3PreviousMonthlyTotal,
      previous_yearly_total: simpleCfRow3PreviousYearlyTotal,
      current_and_previous_difference_monthly_total:
        simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotal,
      current_and_previous_difference_monthly_total_ratio:
        simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalRatio,
      current_and_plan_difference_monthly_total:
        simpleCfRow3CurrentAndPlanDifferenceMonthlyTotal,
      current_and_plan_difference_monthly_total_ratio:
        simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalRatio,
      current_and_previous_difference_yearly_total:
        simpleCfRow3CurrentAndPreviousDifferenceYearlyTotal,
      current_and_previous_difference_yearly_total_ratio:
        simpleCfRow3CurrentAndPreviousDifferenceYearlyTotalRatio,
      current_and_plan_difference_yearly_total:
        simpleCfRow3CurrentAndPlanDifferenceYearlyTotal,
      current_and_plan_difference_yearly_total_ratio:
        simpleCfRow3CurrentAndPlanDifferenceYearlyTotalRatio,
      // 累計用
      plan_monthly_total_cumsum: simpleCfRow3PlanMonthlyTotalCumsum,
      plan_monthly_ratio_cumsum: simpleCfRow3PlanMonthlyRatioCumsum,
      current_monthly_total_cumsum: simpleCfRow3CurrentMonthlyTotalCumsum,
      current_monthly_ratio_cumsum: simpleCfRow3CurrentMonthlyRatioCumsum,
      previous_monthly_total_cumsum: simpleCfRow3PreviousMonthlyTotalCumsum,
      previous_monthly_ratio_cumsum: simpleCfRow3PreviousMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_cumsum:
        simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalCumsum,
      current_and_previous_difference_monthly_ratio_cumsum:
        simpleCfRow3CurrentAndPreviousDifferenceMonthlyRatioCumsum,
      current_and_previous_difference_monthly_total_ratio_cumsum:
        simpleCfRow3CurrentAndPreviousDifferenceMonthlyTotalRatioCumsum,
      current_and_plan_difference_monthly_total_cumsum:
        simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalCumsum,
      current_and_plan_difference_monthly_ratio_cumsum:
        simpleCfRow3CurrentAndPlanDifferenceMonthlyRatioCumsum,
      current_and_plan_difference_monthly_total_ratio_cumsum:
        simpleCfRow3CurrentAndPlanDifferenceMonthlyTotalRatioCumsum,
    };

    // 売上構成比
    const saleCompositionRatioFunc = () => {
      const costRatioArray: number[][] = [];
      netSalesTotalByItemPlansInputMonthly.byItem.map((byItem) => {
        const netSalesRatio =
          netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
            ? (dotDivide(
                byItem,
                netSalesTotalByItemPlansInputMonthly.monthlyTotal
              ) as number[])
            : byItem;

        costRatioArray.push(netSalesRatio);
      });
      return costRatioArray;
    };
    const saleCompositionMonthlyRatio = saleCompositionRatioFunc();
    const saleCompositionMonthlyRatioTotal =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? (dotDivide(
            netSalesTotalByItemPlansInputMonthly.monthlyTotal,
            netSalesTotalByItemPlansInputMonthly.monthlyTotal
          ) as number[])
        : [];
    const salesCompositionByItemRatioTotal =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? (dotDivide(
            netSalesTotalByItemPlansInputMonthly.monthlyTotalByItem,
            sum(netSalesTotalByItemPlansInputMonthly.monthlyTotal)
          ) as number[])
        : [];
    const saleCompositionCurrentTermRatio = dotDivide(
      netSalesTotalByItemCurrentTerm,
      netSalesTotalByItemCurrentTermTotal
    ) as number[];
    const saleCompositionCurrentTermRatioTotal = divide(
      netSalesTotalByItemCurrentTermTotal,
      netSalesTotalByItemCurrentTermTotal
    ) as number;
    const saleCompositionPlansInputRatio = dotDivide(
      netSalesTotalByItemPlansInput,
      netSalesTotalByItemPlansInputTotal
    ) as number[];
    const saleCompositionPlansInputRatioTotal = divide(
      netSalesTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const saleCompositionMonthlyTotalRatio = divide(
      netSalesTotalByItemPlansInputYearlyTotal,
      netSalesTotalByItemPlansInputYearlyTotal
    ) as number;

    // 累計用
    const saleCompositionMonthlyRatioCumsum = cumsum(
      saleCompositionMonthlyRatio
    ) as number[];
    const saleCompositionCurrentTermRatioCumsum = cumsum(
      saleCompositionCurrentTermRatio
    ) as number[];
    const saleCompositionPlansInputRatioCumsum = cumsum(
      saleCompositionPlansInputRatio
    ) as number[];
    const saleCompositionMonthlyTotalRatioCumsum = cumsum(
      saleCompositionMonthlyTotalRatio
    ) as number[];

    const saleCompositionRatio = {
      by_item_ratio: saleCompositionMonthlyRatio,
      by_item_ratio_total: salesCompositionByItemRatioTotal,
      current_term_ratio: saleCompositionCurrentTermRatio,
      current_term_ratio_total: saleCompositionCurrentTermRatioTotal,
      plans_input_ratio: saleCompositionPlansInputRatio,
      plans_input_ratio_total: saleCompositionPlansInputRatioTotal,
      monthly_ratio: saleCompositionMonthlyTotalRatio,
      monthly_ratio_total: saleCompositionMonthlyRatioTotal,
      // 累計用
      by_item_ratio_cumsum: saleCompositionMonthlyRatioCumsum,
      current_term_ratio_cumsum: saleCompositionCurrentTermRatioCumsum,
      plans_input_ratio_cumsum: saleCompositionPlansInputRatioCumsum,
      monthly_ratio_cumsum: saleCompositionMonthlyTotalRatioCumsum,
    };

    // 原価構成比（当期製造費用）
    const grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatio =
      dotDivide(
        grossManufacturingCostForThePeriodMonthlyTotal,
        grossManufacturingCostForThePeriodMonthlyTotal
      ) as number[];
    const grossManufacturingCostForThePeriodPlansCostCompositionCurrentTermRatio =
      divide(
        grossManufacturingCostForThePeriodCurrentTermTotal,
        grossManufacturingCostForThePeriodCurrentTermTotal
      ) as number;
    const grossManufacturingCostForThePeriodPlansCostCompositionCurrentMonthlyRatio =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotal,
        grossManufacturingCostForThePeriodCurrentMonthlyTotal
      ) as number[];
    const grossManufacturingCostForThePeriodPlansCostCompositionPreviousMonthlyRatio =
      dotDivide(
        grossManufacturingCostForThePeriodPreviousMonthlyTotal,
        grossManufacturingCostForThePeriodPreviousMonthlyTotal
      ) as number[];
    const grossManufacturingCostForThePeriodPlansCostCompositionCurrentYearlyRatio =
      divide(
        grossManufacturingCostForThePeriodCurrentYearlyTotal,
        grossManufacturingCostForThePeriodCurrentYearlyTotal
      ) as number;
    const grossManufacturingCostForThePeriodPlansCostCompositionPreviousYearlyRatio =
      divide(
        grossManufacturingCostForThePeriodPreviousYearlyTotal,
        grossManufacturingCostForThePeriodPreviousYearlyTotal
      ) as number;

    // 累計用
    const grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatioCumsum =
      dotDivide(
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const grossManufacturingCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum =
      dotDivide(
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum
      ) as number[];
    const grossManufacturingCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum =
      dotDivide(
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const grossManufacturingCostForThePeriodPlansCostCompositionRatio = {
      by_item:
        grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatio,
      current_term_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionCurrentTermRatio,
      current_monthly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatioCumsum,
      current_monthly_ratio_cumsum:
        grossManufacturingCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        grossManufacturingCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum,
    };

    // 原価構成比（材料費）
    const materialFeeCostCompositionMonthlyRatio = dotDivide(
      materialFeeTotalByItemPlansInputMonthly.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];

    const materialFeeCostCompositionCurrentTermRatio = divide(
      materialFeeTotalByItemPlansInputTotal,
      grossManufacturingCostForThePeriodPlansInputTotal
    ) as number;
    const materialFeeCostCompositionCurrentMonthlyRatio = dotDivide(
      materialFeeForBudgetControl.currentMonthlyTotal,
      grossManufacturingCostForThePeriodCurrentMonthlyTotal
    ) as number[];
    const materialFeeCostCompositionPreviousMonthlyRatio = dotDivide(
      materialFeeForBudgetControl.previousMonthlyTotal,
      grossManufacturingCostForThePeriodPreviousMonthlyTotal
    ) as number[];
    const materialFeeCostCompositionCurrentYearlyRatio = divide(
      materialFeeForBudgetControl.currentYearlyTotal,
      grossManufacturingCostForThePeriodCurrentYearlyTotal
    ) as number;
    const materialFeeCostCompositionPreviousYearlyRatio = divide(
      materialFeeForBudgetControl.previousYearlyTotal,
      grossManufacturingCostForThePeriodPreviousYearlyTotal
    ) as number;

    // 累計用
    const materialFeeCostForThePeriodPlansCostCompositionMonthlyRatioCumsum =
      dotDivide(
        materialFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const materialFeeCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum =
      dotDivide(
        materialFeeForBudgetControl.currentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum
      ) as number[];
    const materialFeeCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum =
      dotDivide(
        materialFeeForBudgetControl.previousMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const materialFeeCostCompositionRatio = {
      by_item: materialFeeCostCompositionMonthlyRatio,
      current_term_ratio: materialFeeCostCompositionCurrentTermRatio,
      current_monthly_ratio: materialFeeCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio: materialFeeCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio: materialFeeCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio: materialFeeCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        materialFeeCostForThePeriodPlansCostCompositionMonthlyRatioCumsum,
      current_monthly_ratio_cumsum:
        materialFeeCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        materialFeeCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum,
    };
    // 原価構成比（労務費合計）
    const laborCostsCostCompositionMonthlyRatio = dotDivide(
      laborCostsTotalByItemPlansInputMonthly.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];
    const laborCostsCostCompositionCurrentTermRatio = divide(
      laborCostsTotalByItemPlansInputTotal,
      grossManufacturingCostForThePeriodPlansInputTotal
    ) as number;
    const laborCostsCostCompositionCurrentMonthlyRatio = dotDivide(
      laborCostsForBudgetControl.currentMonthlyTotal,
      grossManufacturingCostForThePeriodCurrentMonthlyTotal
    ) as number[];
    const laborCostsCostCompositionPreviousMonthlyRatio = dotDivide(
      laborCostsForBudgetControl.previousMonthlyTotal,
      grossManufacturingCostForThePeriodPreviousMonthlyTotal
    ) as number[];
    const laborCostsCostCompositionCurrentYearlyRatio = divide(
      laborCostsForBudgetControl.currentYearlyTotal,
      grossManufacturingCostForThePeriodCurrentYearlyTotal
    ) as number;
    const laborCostsCostCompositionPreviousYearlyRatio = divide(
      laborCostsForBudgetControl.previousYearlyTotal,
      grossManufacturingCostForThePeriodPreviousYearlyTotal
    ) as number;

    // 累計用
    const laborCostsForThePeriodPlansCostCompositionMonthlyRatioCumsum =
      dotDivide(
        laborCostsTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const laborCostsForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum =
      dotDivide(
        laborCostsForBudgetControl.currentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum
      ) as number[];
    const laborCostsForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum =
      dotDivide(
        laborCostsForBudgetControl.previousMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const laborCostsCostCompositionRatio = {
      by_item: laborCostsCostCompositionMonthlyRatio,
      current_term_ratio: laborCostsCostCompositionCurrentTermRatio,
      current_monthly_ratio: laborCostsCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio: laborCostsCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio: laborCostsCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio: laborCostsCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        laborCostsForThePeriodPlansCostCompositionMonthlyRatioCumsum,
      current_monthly_ratio_cumsum:
        laborCostsForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        laborCostsForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum,
    };

    // 原価構成比（外注加工費合計）
    const externalInjectionProcessingFeeCostCompositionMonthlyRatio = dotDivide(
      externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];
    const externalInjectionProcessingFeeCostCompositionCurrentTermRatio =
      divide(
        externalInjectionProcessingFeeTotalByItemPlansInputTotal,
        grossManufacturingCostForThePeriodPlansInputTotal
      ) as number;
    const externalInjectionProcessingFeeCostCompositionCurrentMonthlyRatio =
      dotDivide(
        externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotal,
        grossManufacturingCostForThePeriodCurrentMonthlyTotal
      ) as number[];
    const externalInjectionProcessingFeeCostCompositionPreviousMonthlyRatio =
      dotDivide(
        externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotal,
        grossManufacturingCostForThePeriodPreviousMonthlyTotal
      ) as number[];
    const externalInjectionProcessingFeeCostCompositionCurrentYearlyRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
        grossManufacturingCostForThePeriodCurrentYearlyTotal
      ) as number;
    const externalInjectionProcessingFeeCostCompositionPreviousYearlyRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.previousYearlyTotal,
        grossManufacturingCostForThePeriodPreviousYearlyTotal
      ) as number;

    // 累計用
    const externalInjectionProcessingFeeForThePeriodPlansCostCompositionMonthlyRatioCumsum =
      dotDivide(
        externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const externalInjectionProcessingFeeForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum =
      dotDivide(
        externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum
      ) as number[];
    const externalInjectionProcessingFeeForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum =
      dotDivide(
        externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const externalInjectionProcessingFeeCostCompositionRatio = {
      by_item: externalInjectionProcessingFeeCostCompositionMonthlyRatio,
      current_term_ratio:
        externalInjectionProcessingFeeCostCompositionCurrentTermRatio,
      current_monthly_ratio:
        externalInjectionProcessingFeeCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio:
        externalInjectionProcessingFeeCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        externalInjectionProcessingFeeCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio:
        externalInjectionProcessingFeeCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        externalInjectionProcessingFeeForThePeriodPlansCostCompositionMonthlyRatioCumsum,
      current_monthly_ratio_cumsum:
        externalInjectionProcessingFeeForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        externalInjectionProcessingFeeForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum,
    };
    // 原価構成比（経費合計）
    const expensesCostCompositionMonthlyRatio = dotDivide(
      expensesTotalByItemPlansInputMonthly.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];
    const expensesCostCompositionCurrentTermRatio = divide(
      expensesTotalByItemPlansInputTotal,
      grossManufacturingCostForThePeriodPlansInputTotal
    ) as number;
    const expensesCostCompositionCurrentMonthlyRatio = dotDivide(
      expensesForBudgetControl.currentMonthlyTotal,
      grossManufacturingCostForThePeriodCurrentMonthlyTotal
    ) as number[];
    const expensesCostCompositionPreviousMonthlyRatio = dotDivide(
      expensesForBudgetControl.previousMonthlyTotal,
      grossManufacturingCostForThePeriodPreviousMonthlyTotal
    ) as number[];
    const expensesCostCompositionCurrentYearlyRatio = divide(
      expensesForBudgetControl.currentYearlyTotal,
      grossManufacturingCostForThePeriodCurrentYearlyTotal
    ) as number;
    const expensesCostCompositionPreviousYearlyRatio = divide(
      expensesForBudgetControl.previousYearlyTotal,
      grossManufacturingCostForThePeriodPreviousYearlyTotal
    ) as number;

    // 累計用
    const expensesCostForThePeriodPlansCostCompositionMonthlyRatioCumsum =
      dotDivide(
        expensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
        grossManufacturingCostForThePeriodPlanMonthlyTotalCumsum
      ) as number[];
    const expensesCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum =
      dotDivide(
        expensesForBudgetControl.currentMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodCurrentMonthlyTotalCumsum
      ) as number[];
    const expensesCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum =
      dotDivide(
        expensesForBudgetControl.previousMonthlyTotalCumsum,
        grossManufacturingCostForThePeriodPreviousMonthlyTotalCumsum
      ) as number[];

    const expensesCostCompositionRatio = {
      by_item: expensesCostCompositionMonthlyRatio,
      current_term_ratio: expensesCostCompositionCurrentTermRatio,
      current_monthly_ratio: expensesCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio: expensesCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio: expensesCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio: expensesCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        expensesCostForThePeriodPlansCostCompositionMonthlyRatioCumsum,
      current_monthly_ratio_cumsum:
        expensesCostForThePeriodPlansCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        expensesCostForThePeriodPlansCostCompositionPreviousMonthlyRatioCumsum,
    };

    // 要素別原価率
    // 売上高材料費比率
    const netSalesMaterialFeeMonthlyTotalRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: materialFeeTotalByItemPlansInputMonthly.monthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : materialFeeTotalByItemPlansInputMonthly.monthlyTotal;

    const netSalesMaterialFeePlansInputRatio = divide(
      materialFeeTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const netSalesMaterialFeeCostCompositionCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            materialFeeForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : materialFeeForBudgetControl.currentMonthlyTotal;
    const netSalesMaterialFeeCostCompositionPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            materialFeeForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : materialFeeForBudgetControl.previousMonthlyTotal;
    const netSalesMaterialFeeCostCompositionCurrentYearlyRatio = divide(
      materialFeeForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;
    const netSalesMaterialFeeCostCompositionPreviousYearlyRatio = divide(
      materialFeeForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;

    // 累計用
    const netSalesMaterialFeeMonthlyTotalRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            materialFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : materialFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum;
    const netSalesMaterialFeeCostCompositionCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            materialFeeForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : materialFeeForBudgetControl.currentMonthlyTotalCumsum;
    const netSalesMaterialFeeCostCompositionPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            materialFeeForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : materialFeeForBudgetControl.previousMonthlyTotalCumsum;

    const netSalesMaterialFeeTotalRatio = {
      by_item: netSalesMaterialFeeMonthlyTotalRatio,
      plans_input_total: netSalesMaterialFeePlansInputRatio,
      current_monthly_ratio:
        netSalesMaterialFeeCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio:
        netSalesMaterialFeeCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        netSalesMaterialFeeCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio:
        netSalesMaterialFeeCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum: netSalesMaterialFeeMonthlyTotalRatioCumsum,
      current_monthly_ratio_cumsum:
        netSalesMaterialFeeCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        netSalesMaterialFeeCostCompositionPreviousMonthlyRatioCumsum,
    };

    // 売上高労務費比率
    const netSalesLaborCostsMonthlyTotalRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: laborCostsTotalByItemPlansInputMonthly.monthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : laborCostsTotalByItemPlansInputMonthly.monthlyTotal;
    const netSalesLaborCostsPlansInputRatio = divide(
      laborCostsTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const netSalesLaborCostsCostCompositionCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            laborCostsForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : laborCostsForBudgetControl.currentMonthlyTotal;
    const netSalesLaborCostsCostCompositionPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            laborCostsForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : laborCostsForBudgetControl.previousMonthlyTotal;
    const netSalesLaborCostsCostCompositionCurrentYearlyRatio = divide(
      laborCostsForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;
    const netSalesLaborCostsCostCompositionPreviousYearlyRatio = divide(
      laborCostsForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;
    // 累計用
    const netSalesLaborCostsMonthlyTotalRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            laborCostsTotalByItemPlansInputMonthly.monthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : laborCostsTotalByItemPlansInputMonthly.monthlyTotalCumsum;
    const netSalesLaborCostsCostCompositionCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            laborCostsForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : laborCostsForBudgetControl.currentMonthlyTotalCumsum;
    const netSalesLaborCostsCostCompositionPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            laborCostsForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : laborCostsForBudgetControl.previousMonthlyTotalCumsum;
    const netSalesLaborCostsTotalRatio = {
      by_item: netSalesLaborCostsMonthlyTotalRatio,
      plans_input_total: netSalesLaborCostsPlansInputRatio,
      current_monthly_ratio:
        netSalesLaborCostsCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio: netSalesLaborCostsCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        netSalesLaborCostsCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio:
        netSalesLaborCostsCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum: netSalesLaborCostsMonthlyTotalRatioCumsum,
      current_monthly_ratio_cumsum:
        netSalesLaborCostsCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        netSalesLaborCostsCostCompositionPreviousMonthlyRatioCumsum,
    };

    // 売上高外注費比率
    const netSalesExternalInjectionProcessingFeeMonthlyTotalRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator:
              externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotal;
    const netSalesExternalInjectionProcessingFeePlansInputRatio = divide(
      externalInjectionProcessingFeeTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    );
    const netSalesExternalInjectionProcessingFeeCostCompositionCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotal;
    const netSalesExternalInjectionProcessingFeeCostCompositionPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotal;
    const netSalesExternalInjectionProcessingFeeCostCompositionCurrentYearlyRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.currentYearlyTotal,
        netSalesForBudgetControl.currentYearlyTotal
      ) as number;
    const netSalesExternalInjectionProcessingFeeCostCompositionPreviousYearlyRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.previousYearlyTotal,
        netSalesForBudgetControl.previousYearlyTotal
      ) as number;
    // 累計用
    const netSalesExternalInjectionProcessingFeeMonthlyTotalRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : externalInjectionProcessingFeeTotalByItemPlansInputMonthly.monthlyTotalCumsum;
    const netSalesExternalInjectionProcessingFeeCostCompositionCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : externalInjectionProcessingFeeForBudgetControl.currentMonthlyTotalCumsum;
    const netSalesExternalInjectionProcessingFeeCostCompositionPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : externalInjectionProcessingFeeForBudgetControl.previousMonthlyTotalCumsum;
    const netSalesExternalInjectionProcessingFeeTotalRatio = {
      by_item: netSalesExternalInjectionProcessingFeeMonthlyTotalRatio,
      plans_input_total: netSalesExternalInjectionProcessingFeePlansInputRatio,
      current_monthly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum:
        netSalesExternalInjectionProcessingFeeMonthlyTotalRatioCumsum,
      current_monthly_ratio_cumsum:
        netSalesExternalInjectionProcessingFeeCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        netSalesExternalInjectionProcessingFeeCostCompositionPreviousMonthlyRatioCumsum,
    };
    // 売上高経費比率
    const netSalesExpensesMonthlyTotalRatio: number[] =
      netSalesTotalByItemPlansInputMonthly.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: expensesTotalByItemPlansInputMonthly.monthlyTotal,
            denominator: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
          })
        : expensesTotalByItemPlansInputMonthly.monthlyTotal;
    const netSalesExpensesPlansInputRatio = divide(
      expensesTotalByItemPlansInputTotal,
      netSalesTotalByItemPlansInputTotal
    ) as number;
    const netSalesExpensesCostCompositionCurrentMonthlyRatio =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            expensesForBudgetControl.currentMonthlyTotal,
            netSalesForBudgetControl.currentMonthlyTotal
          ) as number[])
        : expensesForBudgetControl.currentMonthlyTotal;
    const netSalesExpensesCostCompositionPreviousMonthlyRatio =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            expensesForBudgetControl.previousMonthlyTotal,
            netSalesForBudgetControl.previousMonthlyTotal
          ) as number[])
        : expensesForBudgetControl.previousMonthlyTotal;
    const netSalesExpensesCostCompositionCurrentYearlyRatio = divide(
      expensesForBudgetControl.currentYearlyTotal,
      netSalesForBudgetControl.currentYearlyTotal
    ) as number;
    const netSalesExpensesCostCompositionPreviousYearlyRatio = divide(
      expensesForBudgetControl.previousYearlyTotal,
      netSalesForBudgetControl.previousYearlyTotal
    ) as number;
    // 累計用
    const netSalesExpensesMonthlyTotalRatioCumsum =
      netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum.length !== 0
        ? (dotDivide(
            expensesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
            netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum
          ) as number[])
        : expensesTotalByItemPlansInputMonthly.monthlyTotalCumsum;
    const netSalesExpensesCostCompositionCurrentMonthlyRatioCumsum =
      netSalesForBudgetControl.currentMonthlyTotal.length !== 0
        ? (dotDivide(
            expensesForBudgetControl.currentMonthlyTotalCumsum,
            netSalesForBudgetControl.currentMonthlyTotalCumsum
          ) as number[])
        : expensesForBudgetControl.currentMonthlyTotalCumsum;
    const netSalesExpensesCostCompositionPreviousMonthlyRatioCumsum =
      netSalesForBudgetControl.previousMonthlyTotal.length !== 0
        ? (dotDivide(
            expensesForBudgetControl.previousMonthlyTotalCumsum,
            netSalesForBudgetControl.previousMonthlyTotalCumsum
          ) as number[])
        : expensesForBudgetControl.previousMonthlyTotalCumsum;
    const netSalesExpensesTotalRatio = {
      by_item: netSalesExpensesMonthlyTotalRatio,
      plans_input_total: netSalesExpensesPlansInputRatio,
      current_monthly_ratio: netSalesExpensesCostCompositionCurrentMonthlyRatio,
      current_yearly_ratio: netSalesExpensesCostCompositionCurrentYearlyRatio,
      previous_monthly_ratio:
        netSalesExpensesCostCompositionPreviousMonthlyRatio,
      previous_yearly_ratio: netSalesExpensesCostCompositionPreviousYearlyRatio,
      // 累計用
      by_item_cumsum: netSalesExpensesMonthlyTotalRatioCumsum,
      current_monthly_ratio_cumsum:
        netSalesExpensesCostCompositionCurrentMonthlyRatioCumsum,
      previous_monthly_ratio_cumsum:
        netSalesExpensesCostCompositionPreviousMonthlyRatioCumsum,
    };
    // 原価の経費にある減価償却費
    const depreciationCostOfCostOfSalesMonthlyTotalCumsum = cumsum(
      expensesTotalByItemPlansInputMonthly.byItem[0]
    ) as number[];

    const depreciationCostOfSalesT = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: null,
      plansTotal: null,
      plansMonthlyTotal: depreciationCostOfCostOfSalesMonthlyTotalCumsum,
      plansByItemCumsum: null,
      plansMonthlyTotalCumsum: null,
      states: depreciation_cost_of_cost_of_sales,
    });

    const netSalesDepreciationCostOfSales = {
      monthly_total: expensesTotalByItemPlansInputMonthly.byItem[0],
      plans_input_total: expensesTotalByItemPlansInput[0],
      current_monthly_total: depreciationCostOfSalesT.currentMonthlyTotal,
      current_yearly_total: depreciationCostOfSalesT.currentYearlyTotal,
      previous_monthly_total: depreciationCostOfSalesT.previousMonthlyTotal,
      previous_yearly_total: depreciationCostOfSalesT.previousYearlyTotal,
      // 累計用
      plan_monthly_total_cumsum:
        depreciationCostOfCostOfSalesMonthlyTotalCumsum,
      current_monthly_total_cumsum:
        depreciationCostOfSalesT.currentMonthlyTotalCumsum,
      previous_monthly_total_cumsum:
        depreciationCostOfSalesT.previousMonthlyTotalCumsum,
    };

    const plansInputMonthlyCalcState = {
      net_sales_total: netSalesTotal,
      cost_of_sales_total: costOfSalesTotal,
      gross_margin: grossMargin,
      personal_cost_total: personalCostTotal,
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      operating_income: operatingIncome,
      non_operating_income_total: nonOperatingIncomeTotal,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      current_earnings: currentEarnings,
      special_benefits_total: specialBenefitsTotal,
      special_losses_total: specialLossesTotal,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total:
        corporateInhabitantAndEnterpriseTaxesTotal,
      material_fee_total: materialFeeTotal,
      labor_costs_total: laborCostsTotal,
      external_injection_processing_fee_total:
        externalInjectionProcessingFeeTotal,
      expenses_total: expensesTotal,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal,
      inventory_of_work_in_process_at_the_end_of_the_period_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotal,
      gross_manufacturing_cost_for_the_period:
        grossManufacturingCostForThePeriod,
      cost_of_products_manufactured: costOfProductsManufactured,
      operating_profits_before_amortization: operatingProfitsBeforeAmortization,
      ebitda: ebitda,
      ebit: ebit,
      net_income: netIncome,
      simple_cf_row1_total: simpleCfRow1Total,
      simple_cf_row2_total: simpleCfRow2Total,
      simple_cf_row3_total: simpleCfRow3Total,
      sale_composition_ratio: saleCompositionRatio,
      gross_manufacturing_cost_for_the_period_plans_cost_composition_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionRatio,
      material_fee_cost_composition_ratio: materialFeeCostCompositionRatio,
      labor_costs_cost_composition_ratio: laborCostsCostCompositionRatio,
      external_injection_processing_fee_cost_composition_ratio:
        externalInjectionProcessingFeeCostCompositionRatio,
      expenses_cost_composition_ratio: expensesCostCompositionRatio,
      net_sales_material_fee_total_ratio: netSalesMaterialFeeTotalRatio,
      net_sales_labor_costs_total_ratio: netSalesLaborCostsTotalRatio,
      net_sales_external_injection_processing_fee_total_ratio:
        netSalesExternalInjectionProcessingFeeTotalRatio,
      net_sales_expenses_total_ratio: netSalesExpensesTotalRatio,
      net_sales_depreciation_cost_of_sales: netSalesDepreciationCostOfSales,
      net_sales_previous_ratio: netSalesPreviousRatio,
      depreciation_cost_total: depreciationCostTotal,
    };

    return plansInputMonthlyCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
