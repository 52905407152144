import { DocumentData } from "firebase/firestore";
import React from "react";
import { bottomHeaderCss, topHeaderCss } from "../../../utils";

interface Props {
  headers: DocumentData[];
}

const BusinessPlanRatioHeader: React.FC<Props> = ({ headers }) => {
  return (
    <thead
      className="sticky top-0 px-6px py-3px bg-white z-40
      pt-120px"
    >
      <tr className="h-36px text-center ">
        <th
          className={`sticky min-w-[12.5rem] w-200px left-0 bg-white before:border-r before:border-r-c3333334d z-30  ${topHeaderCss}`}
        ></th>
        {headers?.map(
          (_, index) =>
            index !== 0 && (
              <th
                className={`min-w-[8.75rem] w-140px bg-c2a4b770d ${topHeaderCss}  before:border-l ${
                  index !== 1 && "before:border-l-c3333334d"
                } before:border-y before:border-b-c3333334d  `}
                key={index}
              >
                {index === 1 ? "基準年度" : `${index - 1}年後`}
              </th>
            )
        )}

        <th
          className={`relative before:border-collapse min-w-[37.5rem]  w-600px bg-white ${topHeaderCss} before:border before:border-l-c3333334d before:border-r before:border-b-2`}
          rowSpan={2}
        >
          <div className="text-center">備考</div>
        </th>
      </tr>
      <tr className="h-36px">
        <th
          className={`sticky left-0 bg-white before:border-r before:border-r-c3333334d  ${bottomHeaderCss} z-40`}
        ></th>
        {headers?.map(
          (header, index) =>
            index !== 0 && (
              <th
                className={` bg-c2a4b770d ${bottomHeaderCss}  before:border-l ${
                  index !== 1 && "before:border-l-c3333334d"
                } before:border-2b `}
                key={index}
              >
                {header.nengou}年{header.period.substring(5, 7)}月期
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export default React.memo(BusinessPlanRatioHeader);
