import { DocumentData } from "firebase/firestore";
import { add } from "mathjs";
import * as React from "react";
import { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  useSalesPlanInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  SalesPlanBody,
  SalesPlanBodyBlank,
  SalesPlanTotal,
} from "../../../lib/datasheets/salesPlan";
import { deleteIdStore, deleteModalIsOpenStore } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { DeleteModal, Spinner } from "../../molecules";
import { SalesPlanHeader, SalesPlanLayout } from "../salesPlan";

interface Props {
  url: string;
}

const SalesPlanContainer: React.FC<Props> = ({ url }) => {
  const firestore = useFirestore();

  const setDeleteIsOpen = useSetRecoilState(deleteModalIsOpenStore);

  const salesPlanInitialData = useSalesPlanInitialData();

  const [deleteIdState, setDeleteIdState] = useRecoilState(deleteIdStore);

  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  if (!salesPlanInitialData) {
    return <Spinner />;
  }

  const { salesPlanState, salesPlanCalcState } = salesPlanInitialData;

  const grid: GridElement[][] = [];

  let salesGrandTotal = [0];
  let itemsGrandTotal = [0];

  salesPlanCalcState.sales_plan_items[0] &&
    salesPlanCalcState.sales_plan_items.map(
      (salesPlan: DocumentData, index: number) => {
        let itemsTotal = [0];

        if (salesPlan.length === 0) {
          itemsTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        } else {
          salesPlanCalcState.price_multiple_sales[index].map(
            (data, dataIndex) => {
              if (dataIndex === 0) {
                itemsTotal = data;
              } else {
                itemsTotal = add(itemsTotal, data);
              }
            }
          );
        }

        if (index === 0) {
          salesGrandTotal = salesPlanCalcState.sales_plan_total[index];
          itemsGrandTotal = itemsTotal;
        } else {
          salesGrandTotal = add(
            salesGrandTotal,
            salesPlanCalcState.sales_plan_total[index]
          );
          itemsGrandTotal = add(itemsGrandTotal, itemsTotal);
        }

        // 最新期間の空のオブジェクトを作成
        const blankTerms = salesPlanCalcState.latest_header_terms.reduce(
          (a: Record<string, number>, v: string) => ({ ...a, [v]: 0 }),
          {}
        );

        const blank = {
          field: salesPlanState.sales_plan_headlines[index],
          item: "",
          model_number: "",
          order: salesPlanCalcState.price_multiple_sales[index].length + 100,
          price: 0,
          sales_plan: blankTerms,
          descriptions: {},
        };

        grid.push(
          ...SalesPlanTotal({
            headers: salesPlanCalcState.latest_header_terms,
            item: salesPlanState.sales_plan_headlines[index],
            total: salesPlanCalcState.sales_plan_total[index],
            itemsTotal: itemsTotal,
            descriptions: salesPlanState.descriptions,
            url: url,
            unit: unit,
          })
        );

        grid.push(
          ...SalesPlanBody({
            headers: salesPlanCalcState.latest_header_terms,
            rows: salesPlan,
            totalByItem: salesPlanCalcState.sales_plan_total_by_item[index],
            priceMultipleSales: salesPlanCalcState.price_multiple_sales[index],
            url: url,
            setDeleteIdState: setDeleteIdState,
            setDeleteIsOpen: setDeleteIsOpen,
            unit: unit,
          })
        );

        grid.push(
          ...SalesPlanBodyBlank({
            headers: salesPlanCalcState.latest_header_terms,
            row: blank,
            url: url,
          })
        );
      }
    );

  grid.push(
    ...SalesPlanTotal({
      headers: salesPlanCalcState.latest_header_terms,
      title: "年度",
      total: salesGrandTotal,
      itemsTotal: itemsGrandTotal,
      descriptions: salesPlanState.descriptions,
      url: url,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <React.Fragment>
      <SalesPlanLayout>
        <ReactDataSheet
          data={grid}
          dataRenderer={(cell) => cell.expr}
          onCellsChanged={HandleCellChanged}
          sheetRenderer={(props: {
            className: string | undefined;
            children: React.ReactElement<
              DocumentData,
              string | React.JSXElementConstructor<DocumentData>
            >;
          }) => (
            <table className={`${props.className}`}>
              <SalesPlanHeader
                headerTerms={salesPlanCalcState.sales_plan_terms}
                headers={salesPlanCalcState.latest_header_terms}
                latestHeader={salesPlanCalcState.latest_header}
              />
              <tbody className="text-14px">{props.children}</tbody>
            </table>
          )}
          valueRenderer={(cell) => cell.value}
        />
      </SalesPlanLayout>
      <DeleteModal id={deleteIdState} />
    </React.Fragment>
  );
};

export default memo(SalesPlanContainer);
