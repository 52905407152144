import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const CostProfitAndLossPlanTotal = ({
  headers,
  state,
  descriptions,
  url,
  crossheadTotal,
  isCrossheadTotalBottom,
  isEven,
  isWhite,
  noHeader,
  unit,
}: {
  title?: string;
  headers: DocumentData[];
  state: DocumentData;
  descriptions: DocumentData;
  url: string;
  crossheadTotal?: string;
  isCrossheadTotalBottom?: boolean;
  isEven?: boolean; // 背景その2
  isWhite?: boolean; // 背景白
  noHeader?: boolean;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-bold bg-white before:border-l ${
    isCrossheadTotalBottom && "before:border-b"
  } ${isCrossheadTotalBottom && !crossheadTotal && "before:border-b"} ${
    isEven && !crossheadTotal ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } ${noHeader && "before:border-t"} z-30`;

  const classCol2 = `${beforeWrapper} left-80px p-4px before:border-b bg-white ${
    isEven ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } before:border-l before:border-l-c3333334d ${noHeader && "before:border-t"}`;

  const classCol3 = `${beforeWrapper} left-160px p-4px bg-white  before:border-b before:border-r before:border-r-c3333334d ${
    isEven ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } font-bold ${noHeader && "before:border-t"}`;

  const classCol4 = `${borderSettings} p-4px
            before:border-b  before:border-l before:border-l-c3333334d ${
              isWhite ? "bg-white" : isEven ? "bg-c7fb28d33" : "bg-c80b0f433"
            }
             text-right font-bold ${noHeader && "before:border-t"}`;

  const classCol5 = `${borderSettings} p-4px before:border-b before:border-x before:border-l-c3333334d ${
    isWhite ? "bg-white" : isEven ? "bg-c7fb28d33" : "bg-c80b0f433"
  } ${noHeader && "before:border-t"} h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: crossheadTotal ? (
        <div
          className={`absolute top-20px left-4px whitespace-nowrap top_absolute`}
        >
          {crossheadTotal}
        </div>
      ) : (
        ""
      ),
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}`,
    },
  ];

  headers?.map((header: DocumentData, tdIndex: number) => {
    gridRow.push({
      value: ShowTableData(state[tdIndex] / unit),
      expr: state[tdIndex] / unit,
      readOnly: true,
      className: `${classCol4} before:border-l-c3333334d`,
      key: header.period,
    });
  });

  gridRow.push({
    value: descriptions[`${url}`],
    readOnly: false,
    className: `${classCol5}`,
    field: `${url}`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
