import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { TaxInput } from "./TaxInput";
import {
  useCashFlowTableInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CashFlowTableTableBlank,
  SalesReceiptAndPaymentOnPurchase,
} from "../../../lib/datasheets/cashFlowTable";
import { GridElement } from "../../../types/gridElement";
import { Unit } from "../../atoms";
import { Spinner } from "../../molecules";
import { DepositsAndWithdrawHeader } from ".";

interface Props {
  url: string;
  dataUrl: string;
}

const DepositsAndWithdrawTable: React.FC<Props> = ({ url, dataUrl }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const cashFlowTableCalcState = useCashFlowTableInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  if (!cashFlowTableCalcState) {
    return <Spinner />;
  }

  const grid = CashFlowTableTableBlank({
    headers: resultsAndPlansDataState.headers[dataUrl],
    title: "",
    bottomLine: false,
  });

  grid.push(
    ...CashFlowTableTableBlank({
      headers: resultsAndPlansDataState.headers[dataUrl],
      title: "【売上入金／仕入出金】 ※消費税不含",
      bottomLine: true,
    }),
    ...SalesReceiptAndPaymentOnPurchase({
      headers: resultsAndPlansDataState.headers[dataUrl],
      salesReceipt: cashFlowTableCalcState.net_sales_total,
      paymentOnPurchase: cashFlowTableCalcState.cost_of_sales_total,
      unit: Number(resultsAndPlansDataState.headers.unit),
      salesReceiptRate: resultsAndPlansDataState.headers.sales_receipt,
      paymentOnPurchaseRate:
        resultsAndPlansDataState.headers.payment_on_purchase,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <React.Fragment>
      <Unit unit={String(unit)} />
      <div className="relative w-auto">
        <div className="w-160px fixed top-[213px] left-[676px] z-[51]">
          <TaxInput isInBorder={true} />
        </div>
      </div>
      <ReactDataSheet
        data={grid}
        dataRenderer={(cell) => cell.expr}
        onCellsChanged={HandleCellChanged}
        sheetRenderer={(props: {
          className: string | undefined;
          children: React.ReactElement<
            DocumentData,
            string | React.JSXElementConstructor<DocumentData>
          >;
        }) => (
          <table className={`${props.className}`}>
            <DepositsAndWithdrawHeader
              headers={resultsAndPlansDataState.headers[dataUrl]}
            />
            <tbody className="text-14px">{props.children}</tbody>
          </table>
        )}
        valueRenderer={(cell) => cell.value}
      />
    </React.Fragment>
  );
};

export default memo(DepositsAndWithdrawTable);
