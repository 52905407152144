import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlResultsOfThePreviousPeriodSimpleCf = ({
  headers,
  row1,
  row2,
  row3,
  field1,
  field2,
  field3,
  descriptions,
  unit,
}: {
  headers: DocumentData[];
  row1: number[];
  row2: number[];
  row3: number[];
  field1: string;
  field2: string;
  field3: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const totalByItem1 = sum(row1);
  const totalByItem2 = sum(row2);
  const totalByItem3 = sum(row3);

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white  before:bg-c80b0f433 before:border-l font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px  bg-white  before:bg-c80b0f433`;

  const classCol3 = `${beforeWrapper} left-220px p-4px  bg-white  before:bg-c80b0f433 `;

  const classCol4 = `${borderSettings} text-right bg-c80b0f433`;

  const classCol5 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  w-120px  text-right  before:bg-cfffee2`;

  const classCol6 = `${borderSettings} bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "簡易CF",
      readOnly: true,
      className: `${classCol1} before:border-y before:border-b-c3333334d before:border-r-c3333334d`,
      colSpan: 2,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          経常利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-y   before:border-b-c3333334d before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-y   before:border-b-c3333334d before:border-r  before:border-r-c3333334d`,
    },
  ];

  headers?.map((_, tdIndex) => {
    gridRow.push({
      value: isNaN(row1[tdIndex]) ? 0 : ShowTableData(row1[tdIndex] / unit),
      expr: isNaN(row1[tdIndex]) ? 0 : row1[tdIndex] / unit,
      readOnly: true,
      className: `${classCol4} before:border-t before:border-b-c3333334d`,
    });
  });

  gridRow.push({
    value: ShowTableData((totalByItem1 as number) / unit),
    expr: (totalByItem1 as number) / unit,
    readOnly: true,
    className: `${classCol5} before:border-t before:border-b-c3333334d`,
  });
  gridRow.push({
    value: field1 && descriptions[field1],
    expr: field1 && descriptions[field1],
    readOnly: false,
    className: `${classCol6} before:border-t before:border-r before:border-b-c3333334d`,
    field: field1,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} before:border-b`,
      colSpan: 2,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          当純利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-b before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r  before:border-r-c3333334d`,
    },
  ];

  headers?.map((_, tdIndex) => {
    gridRow2.push({
      value: isNaN(row2[tdIndex]) ? 0 : ShowTableData(row2[tdIndex] / unit),
      expr: isNaN(row2[tdIndex]) ? 0 : row2[tdIndex] / unit,
      readOnly: true,
      className: classCol4,
    });
  });

  gridRow2.push({
    value: ShowTableData((totalByItem2 as number) / unit),
    expr: (totalByItem2 as number) / unit,
    readOnly: true,
    className: `${classCol5}`,
  });
  gridRow2.push({
    value: field2 && descriptions[field2],
    expr: field2 && descriptions[field2],
    readOnly: false,
    className: `${classCol6} before:border-x  before:border-l-c3333334d`,
    field: field2,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow2);

  // 3行目の値
  const gridRow3: GridElement[] = [
    {
      value: "粗付加価値額*1)",
      readOnly: true,
      className: `${classCol1} before:border-b before:border-l whitespace-nowrap`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r before:border-r-c3333334d whitespace-nowrap`,
    },
  ];
  headers?.map((header, tdIndex) => {
    gridRow3.push({
      value: isNaN(row3[tdIndex]) ? 0 : ShowTableData(row3[tdIndex] / unit),
      expr: isNaN(row3[tdIndex]) ? 0 : row3[tdIndex] / unit,
      readOnly: true,
      className: classCol4,
    });
  });

  gridRow3.push({
    value: ShowTableData((totalByItem3 as number) / unit),
    expr: (totalByItem3 as number) / unit,
    readOnly: true,
    className: `${classCol5}`,
  });
  gridRow3.push({
    value: field3 && descriptions[field3],
    expr: field3 && descriptions[field3],
    readOnly: false,
    className: `${classCol6} before:border-x before:border-l-c3333334d`,
    field: field3,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow3);

  // 注釈
  const gridRow4: GridElement[] = [
    {
      value:
        "*1) 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white`,
      colSpan: 10,
    },
  ];

  grid.push(gridRow4);

  return grid;
};
