import React from "react";

interface Props {
  children: React.ReactNode;
  disabled: boolean;
  onClick?: () => void;
}

const ApplyButton: React.FC<Props> = ({ children, onClick, disabled }) => {
  return (
    <button
      className={`text-c427ed1 ml-10px hover:opacity-80 bg-white font-bold w-100px h-45px py-2px px-4px rounded border border-c427ed1 focus:outline-none focus:shadow-outline ${
        disabled ? "bg-c33333333" : ""
      }`}
      disabled={disabled}
      onClick={onClick}
      type="submit"
    >
      {children}
    </button>
  );
};

export default ApplyButton;
