import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import React, { Dispatch, SetStateAction } from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";

export const SalesPlanBody = ({
  headers,
  rows,
  totalByItem,
  url,
  priceMultipleSales,
  setDeleteIdState,
  setDeleteIsOpen,
  unit,
}: {
  headers: string[];
  rows: DocumentData;
  totalByItem: number[];
  url: string;
  priceMultipleSales: number[][];
  setDeleteIdState: Dispatch<SetStateAction<string>>;
  setDeleteIsOpen: Dispatch<SetStateAction<boolean>>;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    rows.map((row: DocumentData, index: number) => {
      // className
      const classCol1 = `${beforeWrapper} p-4px left-0 bg-white text-right before:border-b before:border-l ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } ${index % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol2 = `${beforeWrapper} p-4px left-200px bg-white text-right  before:border-b ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } before:border-l before:border-l-c3333334d ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      }`;

      const classCol3 = `${beforeWrapper} p-4px left-260px bg-white text-right  before:border-b ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } before:border-l before:border-l-c3333334d ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      }`;

      const classCol4 = `${beforeWrapper} p-4px left-320px bg-white text-right  before:border-b ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } before:border-x before:border-x-c3333334d ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      }`;

      const classCol5 = `sticky before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full p-4px before:border-b text-right ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } before:border-l  ${index % 2 !== 0 && "bg-c2a4b770d"}`;

      const classCol6 = `${borderSettings} p-4px before:border-b text-right ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      } before:border-x ${index % 2 !== 0 && "bg-c2a4b770d"}`;

      const classCol7 = `${borderSettings}  before:border-r ${
        rows.length !== index + 1 && "before:border-b-c3333334d"
      }  ${index % 2 !== 0 && "bg-c2a4b770d"} text-left  h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: "",
        component: (
          <div className="flex gap-4px justify-between items-center">
            <div className="flex-1">{row.item}</div>
            <button
              className="w-40px h-30px text-14px text-white font-bold bg-red rounded mr-5px hover:opacity-80"
              onClick={() => {
                setDeleteIsOpen(true);
                setDeleteIdState(row.NO_ID_FIELD);
              }}
            >
              削除
            </button>
          </div>
        ),
        forceComponent: true,
        readOnly: true,
        className: `${classCol1} datasheet-input`,
        row: row,
        url: "plans_input_monthly",
      });

      gridRow.push(
        {
          value: row.model_number,
          expr: row.model_number,
          readOnly: false,
          className: classCol2,
          row: row,
          url: url,
          key: "sales_plan_model_number",
        },
        {
          value: row.cost_price
            ? String(round(row.cost_price)).replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )
            : 0,
          expr: row.cost_price
            ? String(round(row.cost_price)).replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )
            : 0,
          readOnly: false,
          className: classCol3,
          row: row,
          url: url,
          key: "sales_plan_cost_price",
        },
        {
          value: String(round(row.price)).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1,"
          ),
          expr: String(round(row.price)).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1,"
          ),
          row: row,
          key: "sales_plan_price",
          readOnly: false,
          className: `${classCol4} border-x-c3333334d`,
        }
      );

      headers.map((header: string) => {
        // オブジェクトの中から該当する値を取得
        const resultsAndPlanTerm = row[url];

        type TypeOfResultObj = typeof resultsAndPlanTerm;
        const key: keyof TypeOfResultObj = header;
        const resultValue = row[url][key] ?? 0;

        // 3桁ごとに変換
        const newValue = String(resultValue).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1,"
        );

        gridRow.push({
          value: newValue !== "undefined" && newValue,
          expr: newValue !== "undefined" && newValue,
          readOnly: false,
          className: `${classCol5} before:border-l-c3333334d`,
          row: row,
          url: url,
          key: key as string,
          colSpan: 1,
        });
      });

      // 項目ごとの合計
      gridRow.push({
        value: String(totalByItem[index]).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1,"
        ),
        readOnly: true,
        className: classCol6,
        colSpan: 1,
      });

      // 空欄
      gridRow.push({
        value: "",
        readOnly: true,
        className: "",
        colSpan: 1,
      });

      // 販売数X個数
      headers.map((header: string, tdIndex: number) => {
        // オブジェクトの中から該当する値を取得

        const resultValue = priceMultipleSales[index][tdIndex];

        // 3桁ごとに変換
        const newValue = String(resultValue / unit).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1,"
        );

        gridRow.push({
          value: newValue !== "undefined" && newValue,
          expr: newValue !== "undefined" && newValue,
          readOnly: true,
          className:
            tdIndex === 0
              ? classCol5
              : `${classCol5} before:border-l-c3333334d`,
          row: row,
          url: url,
          colSpan: 1,
        });
      });
      // 項目ごとの合計
      gridRow.push({
        value: String(sum(priceMultipleSales[index]) / unit).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1,"
        ),
        readOnly: true,
        className: classCol6,
        colSpan: 1,
      });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol7,
        row: row,
        url: url,
        key: "descriptions",
        colSpan: 1,
      });

      grid.push(gridRow);
    });
  }

  return grid;
};
