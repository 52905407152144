import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { CurrentAndPreviousYearComparisonsFieldsCalc } from "../../../types";
import {
  CostCurrentAndPreviousYearComparisonsTable,
  PlCurrentAndPreviousYearComparisonsTable,
} from "../currentAndPreviousYearComparisons";

interface Props {
  url: string;
  state: CurrentAndPreviousYearComparisonsFieldsCalc;
}

export const CurrentAndPreviousYearComparisonsContainer: React.FC<Props> = ({
  url,
  state,
}) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlCurrentAndPreviousYearComparisonsTable state={state} url={url} />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostCurrentAndPreviousYearComparisonsTable state={state} url={url} />
    </InputLayout>
  );
};
