import { DocumentData } from "firebase/firestore";
import { atom } from "recoil";

// 損益計算書の初期設定
export const createProfitAndLossStatementStore = atom<DocumentData>({
  key: "create_profit_and_loss_statement_store",
  default: { field: null, name: null, section: null, is_non_cash_out: false },
});

export const updateProfitAndLossStatementStore = atom<DocumentData>({
  key: "update_profit_and_loss_statement_store",
  default: { field: null, name: null, section: null, is_non_cash_out: false },
});

export const createModalIsOpenStore = atom<boolean>({
  key: "create_modal_is_open_store",
  default: false,
});

export const updateModalIsOpenStore = atom<boolean>({
  key: "update_modal_is_open_store",
  default: false,
});

export const deleteModalIsOpenStore = atom<boolean>({
  key: "delete_modal_is_open_store",
  default: false,
});
