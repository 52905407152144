import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const CostBudgetControlBasisRatioElement = ({
  headerTitle,
  headers,
  rows,
  unit,
}: {
  headerTitle: string;
  rows: { title: string; calcState: DocumentData }[];
  headers: DocumentData[];
  unit: number;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 3,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 1 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-x  before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol2 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"} h-50px`;

    const gridRow: GridElement[] = [];
    gridRow.push({
      value: row.title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 3,
    });

    headers?.map((header, tdIndex) => {
      rowIndex % 2 === 0
        ? gridRow.push(
            {
              value: singleOrCumsumToggle
                ? row.calcState.current_monthly_total &&
                  ShowTableData(
                    round(row.calcState.current_monthly_total[tdIndex] / unit)
                  )
                : row.calcState.current_monthly_total_cumsum &&
                  ShowTableData(
                    round(
                      row.calcState.current_monthly_total_cumsum[tdIndex] / unit
                    )
                  ),
              expr: singleOrCumsumToggle
                ? row.calcState.current_monthly_total &&
                  round(row.calcState.current_monthly_total[tdIndex])
                : row.calcState.current_monthly_total_cumsum &&
                  round(row.calcState.current_monthly_total_cumsum[tdIndex]),
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              }`,
            },
            {
              value: singleOrCumsumToggle
                ? row.calcState.previous_monthly_total &&
                  ShowTableData(
                    round(row.calcState.previous_monthly_total[tdIndex] / unit)
                  )
                : row.calcState.previous_monthly_total_cumsum &&
                  ShowTableData(
                    round(
                      row.calcState.previous_monthly_total_cumsum[tdIndex] /
                        unit
                    )
                  ),
              expr: singleOrCumsumToggle
                ? row.calcState.previous_monthly_total &&
                  round(row.calcState.previous_monthly_total[tdIndex] / unit)
                : row.calcState.previous_monthly_total_cumsum &&
                  round(
                    row.calcState.previous_monthly_total_cumsum[tdIndex] / unit
                  ),
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: singleOrCumsumToggle
                ? row.calcState.monthly_total &&
                  ShowTableData(
                    round(row.calcState.monthly_total[tdIndex]) / unit
                  )
                : row.calcState.plan_monthly_total_cumsum
                ? ShowTableData(
                    round(
                      row.calcState.plan_monthly_total_cumsum[tdIndex] / unit
                    )
                  )
                : 0,
              expr: singleOrCumsumToggle
                ? row.calcState.monthly_total &&
                  round(row.calcState.monthly_total[tdIndex])
                : row.calcState.plan_monthly_total_cumsum
                ? round(row.calcState.plan_monthly_total_cumsum[tdIndex])
                : 0,
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            }
          )
        : gridRow.push(
            {
              value: `${showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.current_monthly_ratio[tdIndex]
                  : row.calcState.current_monthly_ratio_cumsum[tdIndex]
              )}%`,
              expr: showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.current_monthly_ratio[tdIndex]
                  : row.calcState.current_monthly_ratio_cumsum[tdIndex]
              ),
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              }`,
            },
            {
              value: `${showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.previous_monthly_ratio[tdIndex]
                  : row.calcState.previous_monthly_ratio_cumsum[tdIndex]
              )}%`,
              expr: showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.previous_monthly_ratio[tdIndex]
                  : row.calcState.previous_monthly_ratio_cumsum[tdIndex]
              ),
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: `${showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.by_item[tdIndex]
                  : row.calcState.by_item_cumsum[tdIndex]
              )}%`,
              expr: showPercentage(
                singleOrCumsumToggle
                  ? row.calcState.by_item[tdIndex]
                  : row.calcState.by_item_cumsum[tdIndex]
              ),
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            },
            {
              value: "",
              expr: "",
              readOnly: true,
              className: `${classCol2}  ${
                !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
              } before:border-l-c3333334d`,
            }
          );
    });

    rowIndex % 2 === 0 && singleOrCumsumToggle
      ? gridRow.push(
          {
            value: ShowTableData(row.calcState.current_yearly_total / unit),
            expr: ShowTableData(row.calcState.current_yearly_total / unit),
            readOnly: true,
            className: `${classCol2} before:bg-cfffee2`,
          },
          {
            value: ShowTableData(row.calcState.previous_yearly_total / unit),
            expr: row.calcState.previous_yearly_total / unit,
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: ShowTableData(row.calcState.plans_input_total / unit),
            expr: ShowTableData(row.calcState.plans_input_total / unit),
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d before:border-r`,
          }
        )
      : gridRow.push(
          {
            value: `${showPercentage(row.calcState.current_yearly_ratio)}%`,
            expr: `${showPercentage(row.calcState.current_yearly_ratio)}%`,
            readOnly: true,
            className: `${classCol2} before:bg-cfffee2`,
          },
          {
            value: `${showPercentage(row.calcState.previous_yearly_ratio)}%`,
            expr: `${showPercentage(row.calcState.previous_yearly_ratio)}%`,
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: `${showPercentage(row.calcState.plans_input_ratio)}%`,
            expr: `${showPercentage(row.calcState.plans_input_ratio)}%`,
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d`,
          },
          {
            value: "",
            expr: "",
            readOnly: true,
            className: `${classCol2} before:border-l-c3333334d before:border-r`,
          }
        );

    grid.push(gridRow);
  });

  return grid;
};
