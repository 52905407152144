import { DocumentData } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";

export const CostBudgetControlBasisRatioComponent = ({
  headerTitle,
  title,
  headers,
  rows,
}: {
  headerTitle?: string;
  title?: string;
  rows: { title: string; calcState: DocumentData }[];
  headers: DocumentData[];
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 3,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 2 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-x ${
      title && "before:border-l-c3333334d"
    } before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol2 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"} h-50px`;

    const gridRow: GridElement[] = [];
    gridRow.push({
      value: row.title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: title ? 1 : 3,
    });

    headers?.map((header, headerIndex) => {
      gridRow.push({
        value: `${showPercentage(
          singleOrCumsumToggle
            ? row.calcState.current_monthly_ratio[headerIndex]
            : row.calcState.current_monthly_ratio_cumsum[headerIndex]
        )}%`,
        expr: showPercentage(
          singleOrCumsumToggle
            ? row.calcState.current_monthly_ratio[headerIndex]
            : row.calcState.current_monthly_ratio_cumsum[headerIndex]
        ),
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } `,
      });
      gridRow.push({
        value: `${showPercentage(
          singleOrCumsumToggle
            ? row.calcState.previous_monthly_ratio[headerIndex]
            : row.calcState.previous_monthly_ratio_cumsum[headerIndex]
        )}%`,
        expr: singleOrCumsumToggle
          ? row.calcState.previous_monthly_ratio[headerIndex]
          : row.calcState.previous_monthly_ratio_cumsum[headerIndex],
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
      gridRow.push({
        value: `${showPercentage(
          singleOrCumsumToggle
            ? row.calcState.by_item[headerIndex]
            : row.calcState.by_item_cumsum[headerIndex]
        )}%`,
        expr: showPercentage(
          singleOrCumsumToggle
            ? row.calcState.by_item[headerIndex]
            : row.calcState.by_item_cumsum[headerIndex]
        ),
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } before:border-l-c3333334d`,
      });
    });

    // 合計
    singleOrCumsumToggle &&
      gridRow.push({
        value: `${showPercentage(row.calcState.current_yearly_ratio)}%`,
        expr: showPercentage(row.calcState.current_yearly_ratio),
        readOnly: true,
        className: `${classCol2} before:bg-cfffee2`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: `${showPercentage(row.calcState.previous_yearly_ratio)}%`,
        expr: showPercentage(row.calcState.previous_yearly_ratio),
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: `${showPercentage(row.calcState.current_term_ratio)}%`,
        expr: showPercentage(row.calcState.current_term_ratio),
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d`,
      });
    singleOrCumsumToggle &&
      gridRow.push({
        value: "",
        expr: "",
        readOnly: true,
        className: `${classCol2} before:border-l-c3333334d before:border-r`,
      });

    grid.push(gridRow);
  });

  return grid;
};
