import * as React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { PlansInputMonthlyContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

export const PlansInputMonthly = () => {
  const url = pageConstant.PLANS_INPUT_MONTHLY;

  return (
    <React.Fragment>
      <SubHeader title="今期計画（月次）" />
      <UnitChange />
      <PlansInputMonthlyContainer url={url} />
    </React.Fragment>
  );
};
