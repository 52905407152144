export const fiscalPeriod = (
  year: string | number,
  month: string | number,
  index: number
) => {
  if (Number(month) + index + 1 > 12) {
    year = Number(year) + 1;
    month = `0${String(Number(month) + index - 11)}`;
  } else if (Number(month) + index + 2 < 11) {
    month = `0${String(Number(month) + index + 1)}`;
  } else {
    month = Number(month) + index + 1;
  }

  return `y${String(Number(year))}${String(month)}`;
};
