import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const OperatingIncome = ({
  headers,
  state,
  descriptions,
  url,
  unit,
}: {
  headers: DocumentData[];
  state: DocumentData;
  descriptions: DocumentData;
  dataUrl: string;
  url: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-14px font-bold bg-white before:border-x before:border-r-c3333334d before:border-b before:bg-c2a4b770d`;

  const classCol2 = `${borderSettings} p-4px before:border-l before:border-l-c3333334d
        before:border-b text-14px  text-right before:bg-c2a4b770d`;

  const classCol3 = `${borderSettings} p-4px before:border-x before:border-l-c3333334d
        before:border-b before:bg-c2a4b770d text-14px h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: "②営業利益",
      readOnly: true,
      className: `${classCol1}`,
    },
  ];

  headers?.map((header: DocumentData, tdIndex: number) => {
    if (tdIndex === 0) return;

    gridRow.push({
      value: ShowTableData(state[tdIndex] / unit),
      expr: state[tdIndex] / unit,
      readOnly: true,
      className: `${classCol2}`,
    });
  });

  gridRow.push({
    value: descriptions[`${url}_operating_income`],
    readOnly: false,
    className: `${classCol3}`,
    field: `${url}_operating_income`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
