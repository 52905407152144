import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
  termCount: boolean[];
  isCostOfSales?: boolean;
  isPrevious?: boolean;
};

export const totalCalcByItemForComparisons = ({
  headers,
  state,
  field,
  termCount,
  isCostOfSales,
  isPrevious,
}: Props) => {
  const totalData: number[] = [];
  state.map((byItem) => {
    const item: number[] = [];

    headers.map((header: DocumentData, tdIndex: number) => {
      if (header.period === "total" || !termCount[tdIndex]) {
        return;
      }

      const term = header.period;

      if (byItem[field][term] === null) {
        item.push(0);
      } else if (isPrevious) {
        item.push(byItem[field][term]);
      } else if (
        (isCostOfSales &&
          (byItem.item === "期首商品棚卸高" ||
            byItem.item === "期末商品棚卸高")) ||
        byItem.item === "期首材料棚卸高" ||
        byItem.item === "期末材料棚卸高" ||
        !byItem[field] ||
        byItem[field][term] === null ||
        !byItem[field][term]
      ) {
        item.push(0);
      } else {
        item.push(byItem[field][term]);
      }
    });

    const total = sum(item);

    totalData.push(total);
  });

  return totalData;
};
