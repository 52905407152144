import { DocumentData } from "firebase/firestore";
import { sum, add } from "mathjs";

type Props = {
  headers: DocumentData[];
  states: DocumentData[];
  field: string;
  prospectData: number[][];
  prospectTotalData: number[];
  count: boolean[];
  unit: number;
  tax: number;
};

export const cashFlowTableDataCalc = ({
  headers,
  states,
  field,
  prospectData,
  count,
  unit,
  tax,
}: Props) => {
  const byItem: number[][] = [];
  const byItemTotal: number[] = [];
  let monthlyTotal: number[] = [];

  states.map((state, stateIndex) => {
    const values: number[] = [];
    headers.map((header: DocumentData, headerIndex: number) => {
      const data =
        count.length > headerIndex + 1
          ? (state[field][header.period] / unit) * tax // カウントがヘッダーのインデックスより大きい場合は、ヘッダーのインデックスに合わせる
          : !isNaN(prospectData[stateIndex][headerIndex])
          ? (prospectData[stateIndex][headerIndex] / unit) * tax
          : 0;
      values.push(data);
    });
    stateIndex === 0
      ? (monthlyTotal = values)
      : (monthlyTotal = add(monthlyTotal, values));
    byItem.push(values);

    byItemTotal.push(sum(values) as number);
  });

  return {
    byItem,
    byItemTotal,
    monthlyTotal,
    monthlyTotalTotal: sum(monthlyTotal) as number,
  };
};
