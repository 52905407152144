import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";

type Props = {
  headers: string[];
  field: string;
  state: DocumentData[];
};

export const totalCalcByItemForSalesPlan = ({
  headers,
  state,
  field,
}: Props) => {
  const totalData: number[] = [];
  state.map((byItem) => {
    const item: number[] = [0];

    headers.map((header: string) => {
      const term = header;

      if (!byItem[field][term]) {
        return;
      }
      const data = byItem[field][term];

      item.push(data);
    });

    const total = sum(item);

    totalData.push(total);
  });

  return totalData;
};
