import React from "react";

interface Props {
  progress: number;
}

const ProgressBar: React.FC<Props> = ({ progress }) => {
  return (
    <div className="w-[840px] flex justify-center gap-8px m-auto py-30px">
      <div className="w-full text-c427ed1 text-center border-t-2 border-t-c427ed1 py-16px">
        <span className="bg-c427ed1 text-white p-4px mr-12px">01</span>
        利用者情報登録
      </div>
      {/* <div
        className={`w-full ${
          progress !== 1
            ? "text-c427ed1 border-t-c427ed"
            : "text-c33333366 border-t-c33333366"
        } text-center border-t-2 py-16px`}
      >
        <span
          className={`${
            progress !== 1 ? "bg-c427ed1" : "bg-c33333366"
          } text-white p-4px mr-12px`}
        >
          02
        </span>
        料金プラン選択・決済情報登録
      </div> */}
      <div
        className={`w-full ${
          progress != 1
            ? "text-c427ed1 border-t-c427ed"
            : "text-c33333366 border-t-c33333366"
        } text-center border-t-2  py-16px`}
      >
        <span
          className={`${
            progress != 1 ? "bg-c427ed1" : "bg-c33333366"
          } text-white p-4px mr-12px`}
        >
          02
        </span>
        登録内容登録
      </div>
    </div>
  );
};

export default ProgressBar;
