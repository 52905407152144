import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";

export const CostCurrentAndPreviousYearComparisonsRatioComponent = ({
  headerTitle,
  title,
  rows,
  descriptions,
}: {
  headerTitle?: string;
  title?: string;
  rows: {
    title: string;
    previousValue: number;
    currentValue: number;
    reference: number;
    fixed: number;
    field: string;
  }[];
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: (
        <div className={`absolute top-0 left-4px whitespace-nowrap `}>
          {headerTitle}
        </div>
      ),
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32] h-30px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-80px p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-220px p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white before:border-b`,
    },
  ];
  grid.push(gridRow0);

  rows.map((row, index) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-l ${
      title && "before:border-l-c3333334d"
    }  ${rows.length !== index + 1 && "before:border-b-c3333334d"} ${
      index % 2 !== 0 && "before:bg-c2a4b770d"
    } z-[32]`;

    const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } text-right  ${index % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } text-right before:border-r-c3333334d ${
      index % 2 !== 0 && "before:bg-c2a4b770d"
    }`;

    const classCol4 = `relative p-4px before:border-b before:border-l before:border-l-c3333334d  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
      rows.length !== index + 1 && " before:border-b-c3333334d"
    } text-right   ${index % 2 !== 0 && "bg-c2a4b770d"}`;

    const classCol5 = `${borderSettings}  before:border-x before:border-b before:border-l-c3333334d ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } ${index % 2 !== 0 && "bg-c2a4b770d"} h-50px description`;

    const gridRow: GridElement[] = [];
    gridRow.push({
      value: (
        <div className={`absolute  left-4px whitespace-nowrap top_absolute`}>
          {row.title}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}`,
    });
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${classCol2}`,
    });
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${classCol3}`,
    });

    // 前期
    gridRow.push({
      value: `${row.previousValue}%`,
      readOnly: true,
      className: `${classCol4}`,
    });

    // 今期
    gridRow.push({
      value: `${row.currentValue}%`,
      readOnly: true,
      className: `${classCol4}`,
    });
    // 今年-前年
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${classCol4} `,
    });
    // 今年/前年
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${classCol4} `,
    });
    // 自動計算
    gridRow.push({
      value:
        isNaN(row.reference) || !isFinite(row.reference)
          ? ""
          : `${row.reference}%`,
      readOnly: true,
      className: `${classCol4} `,
    });
    // 修正後
    gridRow.push({
      value: isNaN(row.fixed) ? "0%" : `${row.fixed}%`,
      expr: isNaN(row.fixed) ? "0%" : `${row.fixed}%`,
      readOnly: true,
      className: `${classCol4} `,
    });
    gridRow.push({
      value: descriptions[row.field],
      expr: descriptions[row.field],
      readOnly: false,
      className: `${classCol5}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow);
  });

  return grid;
};
