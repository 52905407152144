import { pageConstant } from "../constants/pageConstants";

export const noHeaderSidebarMenus = [
  { title: "初期設定", accordion: null },
  {
    title: "決算期の設定",
    path: pageConstant.ACCOUNTING_PERIOD,
    accordion: null,
  },
];

export const noNetSalesSidebarMenus = [
  { title: "初期設定", accordion: null },
  {
    title: "決算期の設定",
    path: pageConstant.ACCOUNTING_PERIOD,
    accordion: null,
  },
  {
    title: "勘定科目の設定",
    path: pageConstant.ACCOUNT_TITLES,
    accordion: null,
  },
  { title: "KPIの設定", path: pageConstant.KPI, accordion: null },
];

export const sidebarMenus = [
  { title: "初期設定", accordion: null },
  {
    title: "決算期の設定",
    path: pageConstant.ACCOUNTING_PERIOD,
    accordion: null,
  },
  {
    title: "勘定科目の設定",
    path: pageConstant.ACCOUNT_TITLES,
    accordion: null,
  },
  { title: "KPIの設定", path: pageConstant.KPI, accordion: null },
  { title: "計画作成", accordion: null },
  {
    title: "PL/BS実績（年次）",
    path: pageConstant.RESULTS_INPUT,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
      { title: "BS", path: "#bs" },
      { title: "PL（個変分解）", path: "#variation" },
      { title: "粉飾の確認", path: "#wd" },
    ],
  },
  {
    title: "PL前期実績（月次）",
    path: pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "PL今期実績（月次）",
    path: pageConstant.RESULTS_OF_THE_CURRENT_TERM,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "PL今期・前期比較の確認",
    path: pageConstant.CURRENT_AND_PREVIOUS_YEAR_COMPARISONS,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "PL/BS計画",
    path: pageConstant.PLANS_INPUT,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
      { title: "BS", path: "#bs" },
    ],
  },
  {
    title: "PL今期計画（月次）",
    path: pageConstant.PLANS_INPUT_MONTHLY,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "今期見込み（月次）",
    path: pageConstant.PLANS_INPUT_ESTIMATED_IN_THIS_TERM,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  { title: "販売計画", path: pageConstant.SALES_PLAN, accordion: null },
  {
    title: "損益計画",
    path: pageConstant.PROFIT_AND_LOSS_PLAN,
    accordion: null,
  },
  { title: "事業計画", path: pageConstant.BUSINESS_PLAN, accordion: null },
  {
    title: "キャッシュフロー計算書",
    path: pageConstant.STATEMENT_OF_CASH_FLOWS,
    accordion: null,
  },
  { title: "予実管理", accordion: null },
  {
    title: "予実管理表（単月・累計）",
    path: pageConstant.BUDGET_CONTROL_BASIS,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "今期計画（月次）",
    path: pageConstant.CURRENT_PLAN_MONTHLY,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "今期見込み（月次）",
    path: pageConstant.ESTIMATED_IN_THIS_TERM,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  {
    title: "今期実績（月次）",
    path: pageConstant.CURRENT_RESULT_MONTHLY,
    accordion: [
      { title: "PL", path: "#pl" },
      { title: "原価", path: "#cost" },
    ],
  },
  { title: "経営シミュレーション", accordion: null },
  // {
  //   title: "PLシミュレーション",
  //   path: pageConstant.PL_SIMULATION,
  //   accordion: [
  //     { title: "PL", path: "#pl" },
  //     { title: "原価", path: "#cost" },
  //   ],
  // },
  {
    title: "資金繰り予定表",
    path: pageConstant.CASH_FLOW_TABLE,
    accordion: null,
  },
  {
    title: "ダッシュボード",
    path: pageConstant.MANAGEMENT_DASHBOARD,
    accordion: null,
  },
];
