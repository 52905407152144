import React, { memo, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { DarkButton } from "../../components/atoms";
import { SignUpLayout } from "../../components/layout";
import { FormContents } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { typeOfBusinessSelected } from "../../data";
import { setStoredToken } from "../../sessionStorage/sessionStorage";
import { signUpSpecialistStore } from "../../store";

const SignUpSpecialist = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [signUpState, setSignUpState] = useRecoilState(signUpSpecialistStore);

  const [state, setState] = useState({
    isShowPassword: false,
    isShowPasswordConfirmation: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (signUpState.password !== signUpState.password_confirmation) {
      toast.error("パスワードが一致していません");
      return;
    }
    navigate(`/${pageConstant.CONFIRM_SIGNUP}`);
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      setStoredToken(String(searchParams.get("token")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SignUpLayout progress={1} title={"利用者情報登録"}>
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div className="bg-c3333330d px-40px py-25px flex flex-col max-w-[932px] m-auto">
          <FormContents
            htmlFor={"companyName"}
            required={true}
            title={"会社名・団体名"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="companyName"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  company_name: String(e.target.value),
                })
              }
              placeholder="株式会社〇〇〇〇"
              required
              type="text"
              value={signUpState.company_name}
            />
          </FormContents>
          <FormContents
            htmlFor={"companyKanaName"}
            required={true}
            title={"会社名・団体名（カナ）"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="companyKanaName"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  company_kana_name: String(e.target.value),
                })
              }
              placeholder="カブシキガイシャ〇〇〇〇"
              required
              type="text"
              value={signUpState.company_kana_name}
            />
          </FormContents>
          <FormContents
            htmlFor={"companyKanaName"}
            required={true}
            title={"業種"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="typeOfBusiness"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  type_of_business: Number(e.target.value),
                })
              }
              placeholder="選択してください"
              required
              value={
                signUpState.type_of_business === 0
                  ? ""
                  : signUpState.type_of_business
              }
            >
              <option disabled selected value="">
                選択してください
              </option>
              {typeOfBusinessSelected.map((select, index) => (
                <option key={index} value={select.value}>
                  {select.type}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"departmentName"}
            required={false}
            title={"部署名"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="departmentName"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  department_name: String(e.target.value),
                })
              }
              placeholder="営業部"
              type="text"
              value={signUpState.department_name}
            />
          </FormContents>
          <FormContents htmlFor={"position"} required={true} title={"役職"}>
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="position"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  position: String(e.target.value),
                })
              }
              placeholder="部長"
              required
              type="text"
              value={signUpState.position}
            />
          </FormContents>
          <FormContents htmlFor={"name"} required={true} title={"氏名"}>
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    last_name: String(e.target.value),
                  })
                }
                placeholder="姓"
                required
                type="text"
                value={signUpState.last_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    first_name: String(e.target.value),
                  })
                }
                placeholder="名"
                required
                type="text"
                value={signUpState.first_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"kanaName"}
            required={true}
            title={"氏名（カナ）"}
          >
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastKanaName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    last_kana_name: String(e.target.value),
                  })
                }
                placeholder="セイ"
                required
                type="text"
                value={signUpState.last_kana_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstKanaName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    first_kana_name: String(e.target.value),
                  })
                }
                placeholder="メイ"
                required
                type="text"
                value={signUpState.first_kana_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"phone"}
            required={true}
            title={"携帯電話番号"}
          >
            <span className="w-[32.666%] flex justify-between mr-auto">
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] float-left py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone1"
                inputMode="text"
                maxLength={3}
                minLength={3}
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    phone1: String(e.target.value),
                  })
                }
                pattern="0[5789]0"
                placeholder="000"
                required
                type="text"
                value={signUpState.phone1}
              />
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone2"
                inputMode="text"
                maxLength={4}
                minLength={4}
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    phone2: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={signUpState.phone2}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone3"
                inputMode="text"
                maxLength={4}
                minLength={4}
                name="phone3"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    phone3: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={signUpState.phone3}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"password"}
            required={true}
            title={"パスワード"}
          >
            <input
              autoComplete="new-password"
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              minLength={8}
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  password: String(e.target.value),
                })
              }
              placeholder="半角英数字8文字以上"
              required
              type={state.isShowPassword ? "text" : "password"}
              value={signUpState.password}
            />
            <span
              className={`absolute top-[16px] right-16px ${
                signUpState.password.length === 0 && "text-c33333333"
              }`}
              onClick={() =>
                setState({ ...state, isShowPassword: !state.isShowPassword })
              }
              role="presentation"
            >
              {state.isShowPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </FormContents>
          <FormContents
            htmlFor={"passwordConfirmation"}
            required={true}
            title={"パスワード（確認）"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="passwordConfirmation"
              minLength={8}
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  password_confirmation: String(e.target.value),
                })
              }
              placeholder="パスワード再入力"
              required
              type={state.isShowPasswordConfirmation ? "text" : "password"}
              value={signUpState.password_confirmation}
            />
            <span
              className={`absolute top-[16px] right-16px ${
                signUpState.password_confirmation.length === 0 &&
                "text-c33333333"
              }`}
              onClick={() =>
                setState({
                  ...state,
                  isShowPasswordConfirmation: !state.isShowPasswordConfirmation,
                })
              }
              role="presentation"
            >
              {state.isShowPasswordConfirmation ? <FaEye /> : <FaEyeSlash />}
            </span>
          </FormContents>
        </div>
        <p className="text-center py-30px">
          CloudPlannerの利用規約と個人情報の取扱いについて
          <br />
          ご同意の上、ご登録ください。
        </p>
        <div className="w-[450px] m-auto">
          <DarkButton>上記に同意して次へ</DarkButton>
        </div>
      </form>
    </SignUpLayout>
  );
};

export default memo(SignUpSpecialist);
