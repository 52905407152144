import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[] | null;
};

export const totalCalcByItem = ({ headers, state, field }: Props) => {
  const totalData: number[] = [];
  state !== null &&
    (state.length === 0
      ? totalData.push(0)
      : state.map((byItem) => {
          const item: number[] = [];

          headers?.map((header: DocumentData) => {
            if (header.period === "total") {
              return;
            }
            const period =
              headers[0].results === "fixed"
                ? headers[0].results
                : header.period;

            const data =
              byItem &&
              byItem[field] &&
              byItem[field][period] &&
              byItem[field][period] !== null
                ? byItem[field][period]
                : 0;

            data !== null && item.push(data);
          });

          const total = sum(item);

          totalData.push(total);
        }));

  return totalData;
};
