import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CostCurrentAndPreviousYearComparisonsBody,
  CostCurrentAndPreviousYearComparisonsRatioComponent,
  CostCurrentAndPreviousYearComparisonsRatioElement,
  CostCurrentAndPreviousYearComparisonsTotal,
  CurrentAndPreviousYearComparisonsHeader,
  CurrentAndPreviousYearComparisonsTableBlank,
} from "../../../lib/datasheets/currentAndPreviousYearComparisons";
import { CurrentAndPreviousYearComparisonsFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  state: CurrentAndPreviousYearComparisonsFieldsCalc;
}

const CostCurrentAndPreviousYearComparisonsTable: React.FC<Props> = ({
  url,
  state,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit: number = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = CostCurrentAndPreviousYearComparisonsTotal({
    titleTotal: "材料費",
    previousTotal: state.material_fee_total_for_previous,
    currentTotal: state.material_fee_total_for_current,
    totalSubtract: state.material_fee_total_subtract,
    totalRatio: state.material_fee_total_ratio,
    totalReference: state.material_fee_total_reference,
    totalFixedValue: state.material_fee_total_fixed,
    bottomLine: false,
    totalField: `${url}_material_fee_total`,
    descriptions: resultsAndPlansDataState.descriptions,
    unit: unit,
  });
  grid.push(
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.material_fee,
      previousTotalByItem: state.material_fee_total_by_item_for_previous,
      currentTotalByItem: state.material_fee_total_by_item_for_current,
      subtract: state.material_fee_total_by_item_subtract,
      ratio: state.material_fee_total_by_item_ratio,
      reference: state.material_fee_total_by_item_reference,
      url: url,
      bottomLine: true,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "労務費",
      previousTotal: state.labor_costs_total_for_previous,
      currentTotal: state.labor_costs_total_for_current,
      totalSubtract: state.labor_costs_total_subtract,
      totalRatio: state.labor_costs_total_ratio,
      totalReference: state.labor_costs_total_reference,
      totalFixedValue: state.labor_costs_total_fixed,
      bottomLine: false,
      totalField: `${url}_labor_costs_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.labor_costs,
      previousTotalByItem: state.labor_costs_total_by_item_for_previous,
      currentTotalByItem: state.labor_costs_total_by_item_for_current,
      subtract: state.labor_costs_total_by_item_subtract,
      ratio: state.labor_costs_total_by_item_ratio,
      reference: state.labor_costs_total_by_item_reference,
      url: url,
      bottomLine: true,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "外注加工費",
      previousTotal: state.external_injection_processing_fee_total_for_previous,
      currentTotal: state.external_injection_processing_fee_total_for_current,
      totalSubtract: state.external_injection_processing_fee_total_subtract,
      totalRatio: state.external_injection_processing_fee_total_ratio,
      totalReference: state.external_injection_processing_fee_total_reference,
      totalFixedValue: state.external_injection_processing_fee_total_fixed,
      bottomLine: false,
      totalField: `${url}_external_injection_processing_fee_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      previousTotalByItem:
        state.external_injection_processing_fee_total_by_item_for_previous,
      currentTotalByItem:
        state.external_injection_processing_fee_total_by_item_for_current,
      subtract: state.external_injection_processing_fee_total_by_item_subtract,
      ratio: state.external_injection_processing_fee_total_by_item_ratio,
      reference:
        state.external_injection_processing_fee_total_by_item_reference,
      url: url,
      bottomLine: true,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "経費",
      previousTotal: state.expenses_total_for_previous,
      currentTotal: state.expenses_total_for_current,
      totalSubtract: state.expenses_total_subtract,
      totalRatio: state.expenses_total_ratio,
      totalReference: state.expenses_total_reference,
      totalFixedValue: state.expenses_total_fixed,
      bottomLine: false,
      totalField: `${url}_expenses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.expenses,
      previousTotalByItem: state.expenses_total_by_item_for_previous,
      currentTotalByItem: state.expenses_total_by_item_for_current,
      subtract: state.expenses_total_by_item_subtract,
      ratio: state.expenses_total_by_item_ratio,
      reference: state.expenses_total_by_item_reference,
      url: url,
      bottomLine: true,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "当期製造費用",
      previousTotal: state.gross_manufacturing_cost_for_the_period_for_previous,
      currentTotal: state.gross_manufacturing_cost_for_the_period_for_current,
      totalSubtract: state.gross_manufacturing_cost_for_the_period_subtract,
      totalRatio: state.gross_manufacturing_cost_for_the_period_ratio,
      totalReference: state.gross_manufacturing_cost_for_the_period_reference,
      totalFixedValue:
        state.gross_manufacturing_cost_for_the_period_total_fixed,
      bottomLine: false,
      totalField: `${url}_gross_manufacturing_cost_for_the_period`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      previousTotalByItem:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_for_previous,
      currentTotalByItem:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_for_current,
      subtract:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_subtract,
      ratio:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_ratio,
      reference:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_reference,
      url: url,
      bottomLine: false,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      previousTotalByItem:
        state.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_for_previous,
      currentTotalByItem:
        state.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_for_current,
      subtract:
        state.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_subtract,
      ratio:
        state.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_ratio,
      reference:
        state.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_reference,
      url: url,
      bottomLine: false,
      unit: unit,
    }),
    ...CostCurrentAndPreviousYearComparisonsTotal({
      titleTotal: "当期製品製造原価費用",
      previousTotal: state.cost_of_products_manufactured_for_previous,
      currentTotal: state.cost_of_products_manufactured_for_current,
      totalSubtract: state.cost_of_products_manufactured_subtract,
      totalRatio: state.cost_of_products_manufactured_ratio,
      totalReference: state.cost_of_products_manufactured_reference,
      totalFixedValue: state.cost_of_products_manufactured_fixed,
      bottomLine: true,
      totalField: `${url}_cost_of_products_manufactured`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CurrentAndPreviousYearComparisonsTableBlank({ bottomLine: false }),
    ...CostCurrentAndPreviousYearComparisonsRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          previousValue:
            state.gross_manufacturing_cost_for_the_period_ratio_for_previous,
          currentValue:
            state.gross_manufacturing_cost_for_the_period_ratio_for_current,
          reference:
            state.gross_manufacturing_cost_for_the_period_reference_ratio,
          fixed: state.gross_manufacturing_cost_for_the_period_ratio_fixed,
          field: `gross_manufacturing_cost_for_the_period_ratio`,
        },
        {
          title: "材料費",
          previousValue: state.material_fee_total_ratio_for_previous,
          currentValue: state.material_fee_total_ratio_for_current,
          reference: state.material_fee_total_reference_ratio,
          fixed: state.material_fee_ratio_fixed,
          field: `${url}_material_fee_total_ratio`,
        },
        {
          title: "労務費",
          previousValue: state.labor_costs_total_ratio_for_previous,
          currentValue: state.labor_costs_total_ratio_for_current,
          reference: state.labor_costs_total_reference_ratio,
          fixed: state.labor_costs_ratio_fixed,
          field: `${url}_labor_costs_total_ratio`,
        },
        {
          title: "外注加工費",
          previousValue:
            state.external_injection_processing_fee_total_ratio_for_previous,
          currentValue:
            state.external_injection_processing_fee_total_ratio_for_current,
          reference:
            state.external_injection_processing_fee_total_reference_ratio,
          fixed: state.external_injection_processing_fee_ratio_fixed,
          field: `${url}_external_injection_processing_fee_total_ratio`,
        },
        {
          title: "経費",
          previousValue: state.expenses_total_ratio_for_previous,
          currentValue: state.expenses_total_ratio_for_current,
          reference: state.expenses_total_reference_ratio,
          fixed: state.expenses_ratio_fixed,
          field: `${url}_expenses_total_ratio`,
        },
      ],
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CurrentAndPreviousYearComparisonsTableBlank({ bottomLine: false }),
    ...CostCurrentAndPreviousYearComparisonsRatioElement({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "材料費",
          previousValue: state.material_fee_total_for_previous,
          currentValue: state.material_fee_total_for_current,
          reference: state.material_fee_total_reference,
          fixed: state.material_fee_total_fixed,
          field: `${url}_net_sales_material_fee_total_element`,
        },
        {
          title: "(売上高材料費比率)",
          previousValue: state.net_sales_material_fee_total_ratio_for_previous,
          currentValue: state.net_sales_material_fee_total_ratio_for_current,
          reference: state.net_sales_material_fee_total_reference_ratio,
          fixed: state.net_sales_material_fee_total_ratio_fixed,
          field: `${url}_net_sales_material_fee_total_ratio`,
        },
        {
          title: "労務費",
          previousValue: state.labor_costs_total_for_previous,
          currentValue: state.labor_costs_total_for_current,
          reference: state.labor_costs_total_reference,
          fixed: state.labor_costs_total_fixed,
          field: `${url}_net_sales_labor_costs_total_element`,
        },
        {
          title: "(売上高労務費比率)",
          previousValue: state.net_sales_labor_costs_total_ratio_for_previous,
          currentValue: state.net_sales_labor_costs_total_ratio_for_current,
          reference: state.net_sales_labor_costs_total_reference_ratio,
          fixed: state.net_sales_labor_costs_total_ratio_fixed,
          field: `${url}_net_sales_labor_costs_total_ratio`,
        },
        {
          title: "外注加工費",
          previousValue:
            state.external_injection_processing_fee_total_for_previous,
          currentValue:
            state.external_injection_processing_fee_total_for_current,
          reference: state.external_injection_processing_fee_total_reference,
          fixed: state.external_injection_processing_fee_total_fixed,
          field: `${url}_net_sales_external_injection_processing_fee_total_element`,
        },
        {
          title: "(売上高外注費比率)",
          previousValue:
            state.net_sales_external_injection_processing_fee_total_ratio_for_previous,
          currentValue:
            state.net_sales_external_injection_processing_fee_total_ratio_for_current,
          reference:
            state.net_sales_external_injection_processing_fee_total_reference_ratio,
          fixed:
            state.net_sales_external_injection_processing_fee_total_ratio_fixed,
          field: `${url}_net_sales_external_injection_processing_fee_total_ratio`,
        },
        {
          title: "経費",
          previousValue: state.expenses_total_for_previous,
          currentValue: state.expenses_total_for_current,
          reference: state.expenses_total_reference,
          fixed: state.expenses_total_fixed,
          field: `${url}_net_sales_expenses_total_element`,
        },
        {
          title: "(売上高経費比率)",
          previousValue: state.net_sales_expenses_total_ratio_for_previous,
          currentValue: state.net_sales_expenses_total_ratio_for_current,
          reference: state.net_sales_expenses_total_reference_ratio,
          fixed: state.net_sales_expenses_total_ratio_fixed,
          field: `${url}_net_sales_expenses_total_ratio`,
        },
        {
          title: "減価償却費",
          previousValue: null,
          currentValue: state.depreciation_cost_of_sales_t_for_current,
          reference: state.depreciation_cost_of_sales_t_reference_ratio,
          fixed: state.depreciation_cost_of_sales_fixed,
          field: `${url}_net_sales_expenses_total_ratio`,
        },
      ],
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <CurrentAndPreviousYearComparisonsHeader
            currentHeaders={
              resultsAndPlansDataState.headers.results_of_the_current_term
            }
            previousHeaders={
              resultsAndPlansDataState.headers.results_of_the_previous_period
            }
            termCount={
              resultsAndPlansDataState.headers.results_of_the_current_term_count
            }
            title="■原価"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostCurrentAndPreviousYearComparisonsTable);
