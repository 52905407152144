import { AxiosResponse } from "axios";
import React, { memo, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton } from "../../components/atoms";
import { GuestLayout } from "../../components/layout";
import { pageConstant } from "../../constants/pageConstants";

const PasswordReset = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");

  const [state, setState] = useState({
    password: "",
    passwordConfirmation: "",
    isShowPassword: false,
    isShowPasswordConfirmation: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state.password !== state.passwordConfirmation) {
      toast.error("パスワードが一致していません");
      return;
    }

    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: `${apiUrl.PASSWORD_RESETS}/${oobCode}`,
        method: "PUT",
        data: { id: oobCode, password: state.password },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      navigate(`/${pageConstant.LOGIN}`);
    } catch (error) {
      toast.error("送信できませんでした");
    }
  };

  return (
    <GuestLayout>
      <div className="w-[450px]">
        <h1 className="text-30px font-bold text-center mb-25px">
          パスワード再設定
        </h1>
        <form
          className="bg-white px-8px pb-8px"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="relative mb-20px">
            <label
              className="block text-13px font-bold mb-8px"
              htmlFor="password"
            >
              パスワード
            </label>
            <input
              autoComplete="new-password"
              className="shadow appearance-none border border-c33333333 rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              minLength={8}
              onChange={(e) =>
                setState({
                  ...state,
                  password: String(e.target.value),
                })
              }
              placeholder="半角英数字8文字以上"
              required
              type={state.isShowPassword ? "text" : "password"}
              value={state.password}
            />
            <span
              className={`absolute top-[42px] right-16px ${
                state.password.length === 0 && "text-c33333333"
              }`}
              onClick={() =>
                setState({ ...state, isShowPassword: !state.isShowPassword })
              }
              role="presentation"
            >
              {state.isShowPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          <div className="relative mb-4px">
            <label
              className="block text-13px font-bold mb-8px"
              htmlFor="passwordConfirmation"
            >
              パスワード（確認）
            </label>
            <input
              autoComplete="new-password"
              className="shadow appearance-none border border-c33333333 rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="passwordConfirmation"
              minLength={8}
              onChange={(e) =>
                setState({
                  ...state,
                  passwordConfirmation: String(e.target.value),
                })
              }
              placeholder="パスワード再入力"
              required
              type={state.isShowPasswordConfirmation ? "text" : "password"}
              value={state.passwordConfirmation}
            />
            <span
              className={`absolute top-[42px] right-16px ${
                state.passwordConfirmation.length === 0 && "text-c33333333"
              }`}
              onClick={() =>
                setState({
                  ...state,
                  isShowPasswordConfirmation: !state.isShowPasswordConfirmation,
                })
              }
              role="presentation"
            >
              {state.isShowPasswordConfirmation ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          <div className="m-auto py-30px">
            <DarkButton>パスワードを再設定する</DarkButton>
          </div>
        </form>
      </div>
    </GuestLayout>
  );
};

export default memo(PasswordReset);
