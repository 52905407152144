import { DocumentData, Firestore, WriteBatch, doc } from "firebase/firestore";

type UpdateType = {
  headers: DocumentData;
  uid: string;
  LossOnDisposalOfFixedAssets: string;
  firestore: Firestore;
  batch: WriteBatch;
};

export const version101 = ({
  headers,
  uid,
  LossOnDisposalOfFixedAssets,
  firestore,
  batch,
}: UpdateType) => {
  // ヘッダーデータの更新
  const newHeaderDoc = doc(
    firestore,
    "accounts",
    uid,
    "tables",
    String(headers.NO_ID_FIELD)
  );

  batch.update(newHeaderDoc, {
    version: "1.0.1",
    updatedAt: new Date(),
  });

  // 固定資産除却損にis_non_cash_out を付与する
  const newSpecialLossesDoc = doc(
    firestore,
    "accounts",
    uid,
    "tables",
    String(LossOnDisposalOfFixedAssets)
  );

  batch.update(newSpecialLossesDoc, {
    is_non_cash_out: true,
  });
};
