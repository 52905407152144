import { DocumentData } from "firebase/firestore";
import { sum, add, subtract } from "mathjs";

type Props = {
  headers: DocumentData;
  resultsField: string;
  previousField: string;
  states: DocumentData[];
  isCostOfSales?: boolean;
  isMaterialFee?: boolean;
  resultsCount: number;
};

export const totalCalcForEstimatedInThisTerm = ({
  headers,
  states,
  resultsField,
  previousField,
  isCostOfSales,
  isMaterialFee,
  resultsCount,
}: Props) => {
  const byItem: number[][] = [];
  const byItemTotal: number[] = [];
  let monthlyTotal: number[] = [];
  let monthlyData: number[] = [0];

  states.map((state, stateIndex) => {
    const estimatedItemData: number[] = [];

    // 今期実績
    headers[resultsField].map((header: DocumentData, headerIndex: number) => {
      const resultTerm = header.period;

      if (
        (resultsCount > headerIndex && !state[resultsField][resultTerm]) ||
        (resultsCount <= headerIndex && !state[previousField][resultTerm]) ||
        ((isCostOfSales || isMaterialFee) && stateIndex === 0) ||
        ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1)
      ) {
        estimatedItemData.push(0);
      } else if (resultsCount > headerIndex) {
        estimatedItemData.push(state[resultsField][resultTerm]);
      } else {
        estimatedItemData.push(state[previousField][resultTerm]);
      }
    });

    if (stateIndex === 0) {
      monthlyData = estimatedItemData;
    } else {
      monthlyData = add(monthlyData, estimatedItemData);
    }

    byItem.push(estimatedItemData);

    // 売上原価の期首商品棚卸高、期末商品棚卸高と材料費の期首材料棚卸高、期末材料棚卸高の値を0にする
    if (
      ((isCostOfSales || isMaterialFee) && stateIndex === 0) ||
      ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1)
    ) {
      byItemTotal.push(0);
    } else {
      byItemTotal.push(sum(estimatedItemData) as number);
    }

    if ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1) {
      monthlyTotal = subtract(
        subtract(monthlyData, estimatedItemData),
        estimatedItemData
      ) as number[];
    } else if (states.length === stateIndex + 1) {
      monthlyTotal = monthlyData;
    }
  });

  // 今期年間
  const yearlyTotal = sum(byItemTotal) as number;

  return {
    byItem,
    byItemTotal,
    monthlyTotal,
    yearlyTotal,
  };
};
