import { DocumentData } from "firebase/firestore";
import * as React from "react";
import { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import {
  useStatementOfCashFlowsInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  StatementOfCashFlowsBody,
  StatementOFCashFlowsHeader,
  StatementOfCashFlowsTitle,
} from "../../../lib/datasheets/statementOfCashFlows";
import { GridElement } from "../../../types/gridElement";
import { Spinner } from "../../molecules";

interface Props {
  url: string;
  dataUrl: string;
}

const StatementOfCashFlowsContainer: React.FC<Props> = ({ url, dataUrl }) => {
  const firestore = useFirestore();

  const statementOfCashFlowsInitialData = useStatementOfCashFlowsInitialData();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  if (!statementOfCashFlowsInitialData) {
    return <Spinner />;
  }

  const { statementOfCashFlowsState, statementOfCashFlowsCalcState } =
    statementOfCashFlowsInitialData;

  const grid = StatementOfCashFlowsTitle({
    title: "Ⅰ 営業活動によるキャッシュ・フロー",
    headers: statementOfCashFlowsState.headers[dataUrl],
  });

  grid.push(
    ...StatementOfCashFlowsBody({
      headers: statementOfCashFlowsState.headers[dataUrl],
      rows: [
        {
          title: "（１）当期純利益（＋）",
          state: statementOfCashFlowsCalcState.net_income,
          field: "net_income",
          isSpace: false,
        },
        {
          title: "（２）非資金の費用項目",
          state: null,
          field: "non_wage_cost_yearly",
          isSpace: false,
        },
        {
          title: "１．減価償却費（＋）",
          state: statementOfCashFlowsCalcState.depreciation_cost,
          field: "depreciation_cost",
          isSpace: true,
        },
        {
          title: "２．諸引当金の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decrease_quotidian_gold,
          field: "increase_and_decrease_quotidian_gold",
          isSpace: true,
        },
        {
          title: "３．固定資産除却損（＋）・益（－）",
          state:
            statementOfCashFlowsCalcState.loss_on_disposal_of_fixed_assets_total,
          field: "loss_on_disposal_of_fixed_assets",
          isSpace: true,
        },
        {
          title: "４．その他損（＋）・益（－）",
          state: statementOfCashFlowsState.other_loss_on_disposal[0],
          field: "other_loss_on_disposal",
          isSpace: true,
        },
        {
          title: "（３）回収・支払サイト",
          state: null,
          field: "collect_and_pay",
          isSpace: false,
        },
        {
          title: "１．受取手形の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_notes_receivable,
          field: "increase_or_decrease_in_notes_receivable",
          isSpace: true,
        },
        {
          title: "２．売掛金の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_balance_receivable,
          field: "increase_or_decrease_in_balance_receivable",
          isSpace: true,
        },
        {
          title: "３．棚卸資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_shed_unloading_assets,
          field: "increase_or_decrease_in_shed_unloading_assets",
          isSpace: true,
        },
        {
          title: "４．その他の流動資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_current_assets,
          field: "increase_or_decrease_in_current_assets",
          isSpace: true,
        },
        {
          title: "５．支払手形の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_bills_payables,
          field: "increase_or_decrease_in_bills_payables",
          isSpace: true,
        },
        {
          title: "６．買掛金の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_or_decrease_in_accounts_payables,
          field: "increase_or_decrease_in_accounts_payables",
          isSpace: true,
        },
        {
          title: "７．前受金の増加（＋）・減少（－）額",
          state: statementOfCashFlowsCalcState.increase_and_decrease_advances,
          field: "increase_and_decrease_advances",
          isSpace: true,
        },
        {
          title: "８．その他の流動負債の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decrease_other_current_liabilities,
          field: "increase_and_decrease_other_current_liabilities",
          isSpace: true,
        },
        {
          title: "９．その他の固定負債の増加（＋）・減少（－）額",
          state: statementOfCashFlowsState.other_non_current_liabilities[0],
          field: "other_non_current_liabilities",
          isSpace: true,
        },
        {
          title: "１０．利益処分による役員賞与の支払（－）額",
          state:
            statementOfCashFlowsState
              .bonuses_paid_as_an_appropriation_of_profits[0],
          field: "bonuses_paid_as_an_appropriation_of_profits",
          isSpace: true,
        },
        {
          title: "（4）その他（調整差額）",
          state: statementOfCashFlowsCalcState.other_adjustments_yearly,
          field: "other_adjustments",
          isSpace: false,
        },
        {
          title: "（Ⅰの計）",
          state: statementOfCashFlowsCalcState.total_first,
          field: "total_first",
          isSpace: false,
          isFirstColor: true,
        },
      ],
      descriptions: statementOfCashFlowsState.descriptions,
      dataUrl: dataUrl,
      url: url,
      unit: unit,
    })
  );

  grid.push(
    ...StatementOfCashFlowsTitle({
      title: "Ⅱ 投資活動によるキャッシュ・フロー",
      headers: statementOfCashFlowsState.headers[dataUrl],
    })
  );

  grid.push(
    ...StatementOfCashFlowsBody({
      headers: statementOfCashFlowsState.headers[dataUrl],
      rows: [
        {
          title: "１．有価証券の購入（－）・売却（＋）額",
          state: statementOfCashFlowsState.securities_purchased_and_sold[0],
          field: "securities_purchased_and_sold",
          isSpace: true,
        },
        {
          title: "２．短期貸付金の貸付（－）・回収（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decrease_short_term_loans_recivable,
          field: "increase_and_decrease_short_term_loans_recivable",
          isSpace: true,
        },
        {
          title: "３．土地の購入（－）・売却（＋）額",
          state: statementOfCashFlowsState.land_purchase_sale_value[0],
          field: "land_purchase_sale_value",
          isSpace: true,
        },
        {
          title: "４．有形固定資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_tangible_fixed_assets_total,
          field: "increase_and_decreaset_tangible_fixed_assets_total",
          isSpace: true,
        },
        {
          title: "５．建設仮勘定の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsState
              .increase_or_decrease_in_construction_in_progress[0],
          field: "increase_or_decrease_in_construction_in_progress",
          isSpace: true,
        },
        {
          title: "６．無形固定資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_intangible_fixed_assets,
          field: "increase_and_decreaset_intangible_fixed_assets",
          isSpace: true,
        },
        {
          title: "７．投資有価証券の購入（－）・売却（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_investment_securities,
          field: "increase_and_decreaset_investment_securities",
          isSpace: true,
        },
        {
          title: "８．長期貸付金の貸付（－）・回収（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_long_term_loan,
          field: "increase_and_decreaset_long_term_loan",
          isSpace: true,
        },
        {
          title: "９．その他の固定資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_other_fixed_assets,
          field: "increase_and_decreaset_other_fixed_assets",
          isSpace: true,
        },
        {
          title: "１０．繰延資産の増加（－）・減少（＋）額",
          state:
            statementOfCashFlowsState
              .increase_or_decrease_in_deferred_assets[0],
          field: "increase_or_decrease_in_deferred_assets",
          isSpace: true,
        },
        {
          title: "（Ⅱの計）",
          state: statementOfCashFlowsCalcState.total_second,
          field: "total_second",
          isSpace: false,
          isFirstColor: true,
        },
        {
          title: "フリーキャッシュ・フロー（Ⅰ＋Ⅱ）",
          state: statementOfCashFlowsCalcState.total_first_and_second,
          field: "total_first_and_second",
          isSpace: false,
          isGrandTotal: true,
        },
      ],
      descriptions: statementOfCashFlowsState.descriptions,
      dataUrl: dataUrl,
      url: url,
      unit: unit,
    })
  );

  grid.push(
    ...StatementOfCashFlowsTitle({
      title: "Ⅲ 財務活動によるキャッシュ・フロー",
      headers: statementOfCashFlowsState.headers[dataUrl],
      isSecondColor: true,
    })
  );

  grid.push(
    ...StatementOfCashFlowsBody({
      headers: statementOfCashFlowsState.headers[dataUrl],
      rows: [
        {
          title: "１．短期借入金の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_short_term_borrowing,
          field: "increase_and_decreaset_short_term_borrowing",
          isSpace: true,
        },
        {
          title: "２．長期借入金の増加（＋）・減少（－）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_long_term_borrowing,
          field: "increase_and_decreaset_long_term_borrowing",
          isSpace: true,
        },
        {
          title: "３．社債の増加（＋）・返済（－）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_corporate_bond,
          field: "increase_and_decreaset_corporate_bond",
          isSpace: true,
        },
        {
          title: "４．増資（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_capital_increase,
          field: "increase_and_decreaset_capital_increase",
          isSpace: true,
        },
        {
          title: "５．自己株式の取得（－）・処分（＋）額",
          state:
            statementOfCashFlowsCalcState.increase_and_decreaset_treasury_stock,
          field: "increase_and_decreaset_treasury_stock",
          isSpace: true,
        },
        {
          title: "６．剰余金の配当の支払（－）額",
          state: statementOfCashFlowsState.payment_of_dividends_from_surplus[0],
          field: "payment_of_dividends_from_surplus",
          isSpace: true,
        },
        {
          title: "（Ⅲの計）",
          state: statementOfCashFlowsCalcState.total_third,
          field: "total_third",
          isSpace: false,
          isSecondColor: true,
        },
      ],
      descriptions: statementOfCashFlowsState.descriptions,
      dataUrl: dataUrl,
      url: url,
      unit: unit,
    })
  );

  grid.push(
    ...StatementOfCashFlowsBody({
      headers: statementOfCashFlowsState.headers[dataUrl],
      rows: [
        {
          title: "Ⅳ キャッシュの増加・減少額（Ⅰ＋Ⅱ＋Ⅲ）",
          state: statementOfCashFlowsCalcState.total_firstd_second_and_third,
          field: "total_firstd_second_and_third",
          isSpace: true,
        },
        {
          title: "Ⅴ キャッシュの期首残高",
          state: statementOfCashFlowsCalcState.balance_cash_equivalent_yearly,
          field: "balance_cash_equivalent_yearly",
          isSpace: true,
        },
        {
          title: "Ⅵ キャッシュの期末残高（Ⅳ＋Ⅴ）",
          state: statementOfCashFlowsCalcState.balance_end_of_cash_equivalent,
          field: "balance_end_of_cash_equivalent",
          isSpace: true,
        },
        {
          title: "検算（貸借対照表の現金及び預金）",
          state: statementOfCashFlowsCalcState.cash_equivalent_yearly,
          field: "cash_equivalent_yearly",
          isSpace: true,
        },
        // {
        //   title: null,
        //   state: statementOfCashFlowsCalcState.difference_data,
        //   field: "difference_data",
        //   isSpace: true,
        // },
      ],
      descriptions: statementOfCashFlowsState.descriptions,
      dataUrl: dataUrl,
      url: url,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: dataUrl,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <StatementOFCashFlowsHeader
            headers={statementOfCashFlowsState.headers[dataUrl]}
          />

          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(StatementOfCashFlowsContainer);
