import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { CurrentResultMonthlyFieldsCalc } from "../../../types/currentResultMonthlyFieldsCalc";
import {
  CostCurrentResultMonthlyTable,
  PlCurrentResultMonthlyTable,
} from "../currentResultMonthly";

interface Props {
  url: string;
  dataUrl: string;
  calcState: CurrentResultMonthlyFieldsCalc;
}

export const CurrentResultMonthlyContainer: React.FC<Props> = ({
  url,
  dataUrl,
  calcState,
}) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlCurrentResultMonthlyTable
        calcState={calcState}
        dataUrl={dataUrl}
        url={url}
      />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostCurrentResultMonthlyTable
        calcState={calcState}
        dataUrl={dataUrl}
        url={url}
      />
    </InputLayout>
  );
};
