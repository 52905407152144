import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  BsResultsAndPlansBody,
  BsResultsAndPlansTotal,
  ForVerification,
  ResultsAndPlanHeader,
  TableBlank,
} from "../../../lib/datasheets/resultsAndPlans";
import { ResultsInputFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: ResultsInputFieldsCalc;
}

const BsResultsAndPlansTable: React.FC<Props> = ({ url, calcState }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = BsResultsAndPlansTotal({
    titleTotal: "流動資産",
    bottomLine: false,
    isCrosshead: false,
    headers: resultsAndPlansDataState.headers[url],
    total: calcState.gross_current_assets,
    totalField: `${url}_gross_current_assets`,
    descriptions: resultsAndPlansDataState.descriptions,
    unit: unit,
  }).concat(
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.cash_equivalent,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "現預金 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cash_equivalent_total,
      totalField: `${url}_cash_equivalent_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.accounts_receivable,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "売上債権 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.accounts_receivable_total,
      totalField: `${url}_accounts_receivable_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.current_assets,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "当座資産 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.current_assets_total,
      totalField: `${url}_current_assets_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.shed_unloading_assets,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "棚卸資産 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.shed_unloading_assets_total,
      totalField: `${url}_shed_unloading_assets_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.quotidian_gold,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "引当金 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.quotidian_gold_total,
      totalField: `${url}_quotidian_gold_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.other_current_assets,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "固定資産",
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_fixed_assets,
      totalField: `${url}_gross_fixed_assets`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      crossheadTotal: "有形固定資産",
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.tangible_fixed_assets_total,
      totalField: `${url}_tangible_fixed_assets_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.tangible_fixed_assets,
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      crossheadTotal: "無形固定資産",
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.intangible_fixed_assets_total,
      totalField: `${url}_intangible_fixed_assets_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.intangible_fixed_assets,
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      crossheadTotal: "投資その他の資産",
      bottomLine: false,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.investments_and_other_assets_total,
      totalField: `${url}_investments_and_other_assets_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.investments_and_other_assets,
      bottomLine: true,
      isCrosshead: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "繰越資産",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.deferred_asset_total,
      totalField: `${url}_deferred_asset_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.deferred_asset,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "総資産",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_worth,
      totalField: `${url}_net_worth`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    TableBlank({
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "流動負債",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.current_liabilities_total,
      totalField: `${url}_current_liabilities_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.payables,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "支払債務 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.payables_total,
      totalField: `${url}_payables_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.short_term_borrowings,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.unpaids,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "未払 計",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.unpaids_total,
      totalField: `${url}_unpaids_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.current_liabilities,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "固定負債",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.fixed_liabilities_total,
      totalField: `${url}_fixed_liabilities_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.fixed_liabilities,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "負債合計",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.liabilities_total,
      totalField: `${url}_liabilities_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "株主資本",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.shareholders_equity_total,
      totalField: `${url}_shareholders_equity_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "（資本金）",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.capital_total,
      totalField: `${url}_capital_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.capital,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "（資本剰余金）",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.capital_surplus_total,
      totalField: `${url}_capital_surplus_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.capital_surplus,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "（利益剰余金）",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.retained_earnings_total,
      totalField: `${url}_retained_earnings_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.retained_earnings,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "その他の利益余剰金",
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.other_retained_earnings_total,
      totalField: `${url}_other_retained_earnings_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.other_retained_earnings,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.treasury_stock,
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      isSingle: true,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "純資産",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.shareholders_equity_total,
      totalField: `${url}_net_assets`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "負債・純資産合計",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.liabilities_and_net_worth_total,
      totalField: `${url}_liabilities_and_net_worth_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ForVerification({
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_worth,
    }),
    BsResultsAndPlansTotal({
      titleTotal: "長期短期借入金・社債計（役員借入不含）",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.long_term_borrowings_total,
      totalField: `${url}_long_term_borrowings_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    TableBlank({
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: false,
    }),
    TableBlank({
      title: "期末人員数の推移",
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
    }),
    BsResultsAndPlansBody({
      title: "社員数",
      rows: resultsAndPlansDataState.employees,
      bottomLine: false,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    BsResultsAndPlansTotal({
      sectionTotal: "合計",
      bottomLine: true,
      isCrosshead: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.employees_total,
      totalField: `${url}_employees_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    TableBlank({
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: false,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <ResultsAndPlanHeader
            headers={resultsAndPlansDataState.headers[url]}
            title="■BS"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(BsResultsAndPlansTable);
