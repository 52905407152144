import * as React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { PlansInputMonthlyContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

export const CurrentPlanMonthly = () => {
  const url = pageConstant.PLANS_INPUT_MONTHLY;
  const descUrl = pageConstant.CURRENT_PLAN_MONTHLY;

  return (
    <React.Fragment>
      <SubHeader title="今期計画（月次）" />
      <UnitChange />
      <PlansInputMonthlyContainer
        descUrl={descUrl}
        isCurrentPlanMonthly={true}
        url={url}
      />
    </React.Fragment>
  );
};
