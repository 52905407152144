import { DocumentData } from "firebase/firestore";
import React from "react";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlBudgetControlBasisSimplePl = ({
  headers,
  calcStateRows1,
  calcStateRows2,
  calcStateRows3,
  field1,
  field2,
  field3,
  descriptions,
  unit,
}: {
  headers: DocumentData[];
  calcStateRows1: DocumentData;
  calcStateRows2: DocumentData;
  calcStateRows3: DocumentData;
  field1: string;
  field2: string;
  field3: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const monthlyTotalData1 = singleOrCumsumToggle
    ? [
        calcStateRows1.current_monthly_total,
        calcStateRows1.previous_monthly_total,
        calcStateRows1.current_and_previous_difference_monthly_total,
        calcStateRows1.current_and_previous_difference_monthly_total_ratio,
        calcStateRows1.monthly_total,
        calcStateRows1.current_and_plan_difference_monthly_total,
        calcStateRows1.current_and_plan_difference_monthly_total_ratio,
      ]
    : [
        calcStateRows1.current_monthly_total_cumsum,
        calcStateRows1.previous_monthly_total_cumsum,
        calcStateRows1.current_and_previous_difference_monthly_total_cumsum,
        calcStateRows1.current_and_previous_difference_monthly_total_ratio_cumsum,
        calcStateRows1.plan_monthly_total_cumsum,
        calcStateRows1.current_and_plan_difference_monthly_total_cumsum,
        calcStateRows1.current_and_plan_difference_monthly_total_ratio_cumsum,
      ];

  const yearlyTotalData1 = [
    calcStateRows1.current_yearly_total,
    calcStateRows1.previous_yearly_total,
    calcStateRows1.current_and_previous_difference_yearly_total,
    calcStateRows1.current_and_previous_difference_yearly_total_ratio,
    calcStateRows1.plans_input_total,
    calcStateRows1.current_and_plan_difference_yearly_total,
    calcStateRows1.current_and_plan_difference_yearly_total_ratio,
  ];

  const monthlyTotalData2 = singleOrCumsumToggle
    ? [
        calcStateRows2.current_monthly_total,
        calcStateRows2.previous_monthly_total,
        calcStateRows2.current_and_previous_difference_monthly_total,
        calcStateRows2.current_and_previous_difference_monthly_total_ratio,
        calcStateRows2.monthly_total,
        calcStateRows2.current_and_plan_difference_monthly_total,
        calcStateRows2.current_and_plan_difference_monthly_total_ratio,
      ]
    : [
        calcStateRows2.current_monthly_total_cumsum,
        calcStateRows2.previous_monthly_total_cumsum,
        calcStateRows2.current_and_previous_difference_monthly_total_cumsum,
        calcStateRows2.current_and_previous_difference_monthly_total_ratio_cumsum,
        calcStateRows2.plan_monthly_total_cumsum,
        calcStateRows2.current_and_plan_difference_monthly_total_cumsum,
        calcStateRows2.current_and_plan_difference_monthly_total_ratio_cumsum,
      ];

  const yearlyTotalData2 = [
    calcStateRows2.current_yearly_total,
    calcStateRows2.previous_yearly_total,
    calcStateRows2.current_and_previous_difference_yearly_total,
    calcStateRows2.current_and_previous_difference_yearly_total_ratio,
    calcStateRows2.plans_input_total,
    calcStateRows2.current_and_plan_difference_yearly_total,
    calcStateRows2.current_and_plan_difference_yearly_total_ratio,
  ];

  const monthlyTotalData3 = singleOrCumsumToggle
    ? [
        calcStateRows3.current_monthly_total,
        calcStateRows3.previous_monthly_total,
        calcStateRows3.current_and_previous_difference_monthly_total,
        calcStateRows3.current_and_previous_difference_monthly_total_ratio,
        calcStateRows3.monthly_total,
        calcStateRows3.current_and_plan_difference_monthly_total,
        calcStateRows3.current_and_plan_difference_monthly_total_ratio,
      ]
    : [
        calcStateRows3.current_monthly_total_cumsum,
        calcStateRows3.previous_monthly_total_cumsum,
        calcStateRows3.current_and_previous_difference_monthly_total_cumsum,
        calcStateRows3.current_and_previous_difference_monthly_total_ratio_cumsum,
        calcStateRows3.plan_monthly_total_cumsum,
        calcStateRows3.current_and_plan_difference_monthly_total_cumsum,
        calcStateRows3.current_and_plan_difference_monthly_total_ratio_cumsum,
      ];

  const yearlyTotalData3 = [
    calcStateRows3.current_yearly_total,
    calcStateRows3.previous_yearly_total,
    calcStateRows3.current_and_previous_difference_yearly_total,
    calcStateRows3.current_and_previous_difference_yearly_total_ratio,
    calcStateRows3.plans_input_total,
    calcStateRows3.current_and_plan_difference_yearly_total,
    calcStateRows3.current_and_plan_difference_yearly_total_ratio,
  ];

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 w-80px min-w-[5rem] p-4px bg-white  before:bg-cfffee2 before:border-l font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} w-140px max-w-[8.75rem] left-80px p-4px  bg-white  before:bg-cfffee2`;

  const classCol3 = `${beforeWrapper} left-220px p-4px  bg-white  before:bg-cfffee2  z-[31]`;

  const classCol4 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10    text-right before:bg-cfffee2`;

  const classCol5 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 before:bg-cfffee2 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "簡易CF",
      readOnly: true,
      className: `${classCol1} before:border-y before:border-b-c3333334d before:border-r-c3333334d`,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          経常利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-y   before:border-b-c3333334d before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-y   before:border-b-c3333334d before:border-r  before:border-r-c3333334d`,
    },
  ];

  headers?.map((_, headerIndex: number) => {
    monthlyTotalData1.map((data: number[], dataIndex: number) => {
      gridRow.push({
        value:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? ShowTableData(data[headerIndex] / unit)
            : "",
        expr:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? data[headerIndex] / unit
            : "",
        readOnly: true,
        className: `${classCol4} before:border-y before:border-b-c3333334d before:border-l ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });
  });

  // 合計
  singleOrCumsumToggle &&
    yearlyTotalData1.map((data: number, dataIndex: number) => {
      gridRow.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? ShowTableData(data / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? data / unit
            : "",
        readOnly: true,
        className: `${classCol4} before:border-y before:border-b-c3333334d before:border-l ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        } `,
      });
    });

  gridRow.push({
    value: field1 && descriptions[field1],
    readOnly: false,
    className: `${classCol5} before:border-t before:border-x before:border-b-c3333334d`,
    field: field1,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} before:border-b`,
    },
    {
      value: (
        <div
          className={`absolute left-4px whitespace-nowrap top_absolute z-[32]`}
        >
          当純利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-b before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r  before:border-r-c3333334d`,
    },
  ];
  headers?.map((_, headerIndex: number) => {
    monthlyTotalData2.map((data: number[], dataIndex: number) => {
      gridRow2.push({
        value:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? ShowTableData(data[headerIndex] / unit)
            : "",
        expr:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? data[headerIndex] / unit
            : "",
        readOnly: true,
        className: `${classCol4} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });
  });
  // 合計
  singleOrCumsumToggle &&
    yearlyTotalData2.map((data: number, dataIndex: number) => {
      gridRow2.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? ShowTableData(data / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? data / unit
            : "",
        readOnly: true,
        className: `${classCol4} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });

  gridRow2.push({
    value: field2 && descriptions[field2],
    readOnly: false,
    className: `${classCol5} before:border-x `,
    field: field2,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow2);

  // 3行目の値
  const gridRow3: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          粗付加価値額*1)
        </div>
      ),
      readOnly: true,
      className: `${classCol1} before:border-b whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r before:border-r-c3333334d whitespace-nowrap`,
    },
  ];
  headers?.map((_, headerIndex: number) => {
    monthlyTotalData3.map((data: number[], dataIndex: number) => {
      gridRow3.push({
        value:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? ShowTableData(data[headerIndex] / unit)
            : "",
        expr:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null && isNaN(data[headerIndex])
            ? 0
            : data !== null
            ? data[headerIndex] / unit
            : "",
        readOnly: true,
        className: `${classCol4} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });
  });

  // 合計
  singleOrCumsumToggle &&
    yearlyTotalData3.map((data: number, dataIndex: number) => {
      gridRow3.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? ShowTableData(data / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? data / unit
            : "",
        readOnly: true,
        className: `${classCol4} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });

  gridRow3.push({
    value: field3 && descriptions[field3],
    readOnly: false,
    className: `${classCol5} before:border-x`,
    field: field3,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow3);

  // 注釈
  const gridRow4: GridElement[] = [
    {
      value:
        "*1) 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white`,
      colSpan: 10,
    },
  ];

  grid.push(gridRow4);

  return grid;
};
