/* eslint-disable no-undef */
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3000";
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const apiUrl = {
  // 士業ユーザーをFirebaseに登録し、メール送信
  // クライアントユーザーをFirebaseに登録し、メール送信
  USERS: "/users",
  // ユーザーを検証、パスワードリセット
  VERIFY: "/verify",
  // 士業ユーザーの利用者情報登録を完了する
  SPECIALISTS: "/specialists",
  // パスワード再設定リクエストメール送信
  PASSWORD_RESETS: "/password_resets",
  // クライアントユーザーの情報登録を完了
  CLIENTS: "/clients",
  // Client Invitation
  CLIENTS_INVITATION: "/clients_invitation",
  // ログイン
  LOGIN: "/login",
};
