import { AxiosResponse } from "axios";
import { getAuth, User } from "firebase/auth";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFirebaseApp } from "reactfire";
import { apiUrl } from "../api/apiConfig";
import { axiosInstance } from "../api/axiosConfig";
import { queryConstants } from "../constants/queryConstants";
import {
  getStoredCategory,
  getStoredClientToken,
  getStoredClientUid,
  setStoredClientToken,
} from "../sessionStorage/sessionStorage";

const GetClients = async () => {
  try {
    const app = useFirebaseApp();
    const auth = getAuth(app);

    const category = getStoredCategory();
    const uid = getStoredClientUid();

    // Firebaseのトークンをチェック
    category === 2 &&
      auth.onAuthStateChanged((user: User | null): void => {
        if (user) {
          user
            .getIdToken(true)
            .then((latestToken: string) => setStoredClientToken(latestToken))
            .catch((error) => {
              toast.error(`ログイン画面へ移動します: ${error}`);
              window.location.href = "/login";
            });
        } else {
          window.location.href = "/login";
        }
      });

    const accessToken = getStoredClientToken();

    if (!accessToken || category === 1) return null;

    const { data }: AxiosResponse = await axiosInstance.get(
      `${apiUrl.USERS}/${uid}/edit`,
      {
        headers: { accessToken: accessToken },
        data: { id: uid },
      }
    );

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 401) {
      toast.warning("一定時間操作がなかったので、ログイン画面へ移動します");
      setTimeout(() => {
        window.location.href = "/login";
      }, 500);
    }
    console.error(error);
    return;
  }
};

export const useClients = () => {
  const fallback = {};

  const { data: clients = fallback } = useQuery(
    queryConstants.CLIENTS,
    GetClients,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchInterval: 60 * 60 * 1000, // 60分後に再取得
    }
  );

  return clients;
};
