import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  PlansInputMonthlyHeader,
  PlansInputTableBlank,
  PlPlansInputMonthlyBody,
  PlPlansInputMonthlyCostRatio,
  PlPlansInputMonthlyGrandTotal,
  PlPlansInputMonthlySimpleCf,
  PlPlansInputMonthlyTotal,
  PlPlansInputMonthlyWithTax,
  PlPlansInputSalesCompositionRatio,
} from "../../../lib/datasheets/plansInputMonthly";
import { PlansInputMonthlyFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: PlansInputMonthlyFieldsCalc;
  descUrl?: string;
  isCurrentPlanMonthly?: boolean;
}

const PlPlansInputMonthlyTable: React.FC<Props> = ({
  url,
  calcState,
  descUrl,
  isCurrentPlanMonthly,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlPlansInputMonthlyBody({
    title: "売上高",
    headers: resultsAndPlansDataState.headers[url],
    rows: resultsAndPlansDataState.net_sales,
    calcState: calcState.net_sales_total,
    url: url,
    isCurrentPlanMonthly: isCurrentPlanMonthly,
    bodyField: `${descUrl}_net_sales_body`,
    unit: unit,
    descriptions: resultsAndPlansDataState.descriptions,
  });

  grid.push(
    ...PlPlansInputMonthlyTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
      calcState: calcState.net_sales_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_net_sales_total`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_net_sales_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "売上原価",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.cost_of_sales,
      calcState: calcState.cost_of_sales_total,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_cost_of_sales_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
      calcState: calcState.cost_of_sales_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_cost_of_sales_total`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_cost_of_sales_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.gross_margin,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_gross_margin`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_gross_margin_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "販売費及び一般管理費",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.personal_cost,
      calcState: calcState.personal_cost_total,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_personal_cost_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上高人件費比率",
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: false,
      calcState: calcState.personal_cost_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_personal_cost_total`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_net_sales_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.selling_and_administrative,
      calcState: calcState.selling_and_administrative_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      url: url,
      bodyField: `${descUrl}_selling_and_administrative_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
      calcState: calcState.selling_and_administrative_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_selling_and_administrative_total`,
      ratioField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_selling_and_administrative_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.operating_income,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_operating_income`,
      ratioField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_operating_income_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.operating_profits_before_amortization,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_operating_profits_before_amortization`,
      ratioField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_operating_profits_before_amortization_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "営業外収益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_income,
      calcState: calcState.non_operating_income_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      url: url,
      bodyField: `${descUrl}_non_operating_income_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: false,
      calcState: calcState.non_operating_income_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_non_operating_income_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "営業外費用",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_expenses,
      calcState: calcState.non_operating_expenses_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      url: url,
      bodyField: `${descUrl}_non_operating_expenses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
      calcState: calcState.non_operating_expenses_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_non_operating_expenses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.current_earnings,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${url}_current_earnings`,
      ratioField: `${url}_current_earnings_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.ebitda,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${url}_ebitda`,
      ratioField: `${url}_ebitda_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "特別利益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_benefits,
      calcState: calcState.special_benefits_total,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_special_benefits_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[url],
      bottomLine: true,
      calcState: calcState.special_benefits_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_special_benefits_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyBody({
      title: "特別損失",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_losses,
      calcState: calcState.special_losses_total,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_special_losses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.special_losses_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_special_losses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.ebit,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_ebit`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_ebit_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyWithTax({
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      calcState:
        calcState.special_corporate_inhabitant_and_enterprise_taxeslosses_total,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_special_corporate_inhabitant_and_enterprise_taxeslosses`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlPlansInputMonthlyGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.net_income,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_net_income`,
      ratioField: `${isCurrentPlanMonthly ? descUrl : url}_net_income_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlansInputTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlPlansInputMonthlySimpleCf({
      headers: resultsAndPlansDataState.headers[url],
      row1: calcState.simple_cf_row1_total,
      row2: calcState.simple_cf_row2_total,
      row3: calcState.simple_cf_row3_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      field1: `${isCurrentPlanMonthly ? descUrl : url}_simple_cf_row1`,
      field2: `${isCurrentPlanMonthly ? descUrl : url}_simple_cf_row2`,
      field3: `${isCurrentPlanMonthly ? descUrl : url}_simple_cf_row3`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlansInputTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCurrentPlanMonthly: isCurrentPlanMonthly,
    }),
    ...PlPlansInputMonthlyCostRatio({
      headerTitle: url === "plans_input_monthly" ? "原価率" : "月別売上比率",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.net_sales_previous_ratio,
      field: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
    }),
    ...PlansInputTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCurrentPlanMonthly: isCurrentPlanMonthly,
    }),
    ...PlPlansInputSalesCompositionRatio({
      headerTitle: "売上構成比",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.sale_composition_ratio,
      field: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <PlansInputMonthlyHeader
            headers={resultsAndPlansDataState.headers[url]}
            isCurrentPlanMonthly={isCurrentPlanMonthly}
            title="■PL"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlPlansInputMonthlyTable);
