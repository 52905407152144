import { DocumentData } from "firebase/firestore";

type Props = {
  headers: DocumentData;
  field: string;
  state: DocumentData[];
};

export const totalCalcByItemMonthlyTotalForProfitAndLossPlan = ({
  headers,
  state,
  field,
}: Props) => {
  const monthlyTotal: number[] = [];
  state.map((byItem) => {
    headers.map((header: DocumentData, tdIndex: number) => {
      if (
        (byItem.field === "net_sales_total" ||
          byItem.field === "cost_of_sales_total" ||
          byItem.field === "cost_of_sales" ||
          byItem.field === "profit_and_loss_plan_cost_of_sales") &&
        tdIndex >= 2
      ) {
        return;
      } else if (
        byItem.field === "net_sales_total" ||
        byItem.field === "cost_of_sales_total" ||
        byItem.field === "profit_and_loss_plan_cost_of_sales"
      ) {
        const data =
          byItem[field] && byItem[field][header.period]
            ? byItem[field][header.period]
            : 0;
        monthlyTotal.push(data);
      } else {
        const data = byItem[field] ? byItem[field][0][field][header.period] : 0;
        monthlyTotal.push(data);
      }
    });
  });

  return { monthlyTotal };
};
