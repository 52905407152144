import { DocumentData } from "firebase/firestore";
import { CellData } from "../../types";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
  termCount: boolean[];
  materialFee?: boolean;
};

export const totalCalcForComparisons = ({
  headers,
  state,
  field,
  termCount,
  materialFee,
}: Props) => {
  const total: number[] = [];

  headers.map((header: DocumentData, index) => {
    const headerKey = header.period;
    if (headerKey === "total" || !termCount[index]) {
      return;
    }

    // 合計を計算
    const data = state.reduce(function (sum, data) {
      const cells: CellData = data[field];
      if (
        field === "plans_input_monthly" &&
        materialFee &&
        (data.item === "期首材料棚卸高" || data.item === "期末材料棚卸高")
      ) {
        return sum + 0;
      }

      return cells ? sum + cells[headerKey] : sum;
    }, 0);

    total.push(data);
  });

  return total;
};
