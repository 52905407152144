import { nengou } from "../../utils/date";

export const profitAndLossPlanHeader = (
  year: string | number,
  month: string | number
) => [
  {
    nengou: nengou(Number(year) - 1, month),
    period: `y${String(Number(year) - 1)}${String(month)}`,
  },
  {
    nengou: nengou(Number(year), month),
    period: `y${String(Number(year))}${String(month)}`,
  },
  {
    nengou: nengou(Number(year) + 1, month),
    period: `y${String(Number(year) + 1)}${String(month)}`,
  },
  {
    nengou: nengou(Number(year) + 2, month),
    period: `y${String(Number(year) + 2)}${String(month)}`,
  },
  {
    nengou: nengou(Number(year) + 3, month),
    period: `y${String(Number(year) + 3)}${String(month)}`,
  },
];

export const profitAndLossPlanItems = [
  { field: "sales_plan_items", items: ["野菜", "果物", "海鮮", "その他"] },
];

export const profitAndLossPlanData = [
  {
    company_name: "狛江本店",
    field: "net_sales_total",
    profit_and_loss_plan: {
      y201903: 1264154,
      y202003: 8465166,
    },
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "cost_of_sales_total",
    profit_and_loss_plan: {
      y201903: 1264154,
      y202003: 8465166,
    },
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の期首商品棚卸高",
    profit_and_loss_plan: {
      y201903: 235,
      y202003: 253,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の仕入高",
    profit_and_loss_plan: {
      y201903: 3235,
      y202003: 2453,
    },
    order: 2,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の合計",
    profit_and_loss_plan: {
      y201903: 34235,
      y202003: 24553,
    },
    order: 3,
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_sales",
    item: "損益計画の期末商品棚卸高",
    profit_and_loss_plan: {
      y201903: 6433,
      y202003: 4657,
    },
    order: 4,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の役員報酬",
    profit_and_loss_plan: {
      y201803: 6433,
      y201903: 4657,
      y202003: 6433,
      y202103: 4657,
      y202203: 4657,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の給料手当",
    profit_and_loss_plan: {
      y201803: 68633,
      y201903: 46657,
      y202003: 16433,
      y202103: 34657,
      y202203: 94657,
    },
    order: 2,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の法定福利費",
    profit_and_loss_plan: {
      y201803: 62633,
      y201903: 47657,
      y202003: 11433,
      y202103: 44657,
      y202203: 84657,
    },
    order: 3,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の退職金掛金",
    profit_and_loss_plan: {
      y201803: 11633,
      y201903: 47657,
      y202003: 51433,
      y202103: 46657,
      y202203: 8657,
    },
    order: 4,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画の福利厚生費",
    profit_and_loss_plan: {
      y201803: 133,
      y201903: 457,
      y202003: 433,
      y202103: 657,
      y202203: 87,
    },
    order: 5,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_personal_cost",
    item: "損益計画のその他人件費",
    profit_and_loss_plan: {
      y201803: 13,
      y201903: 47,
      y202003: 43,
      y202103: 67,
      y202203: 7,
    },
    order: 6,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の広告宣伝費",
    profit_and_loss_plan: {
      y201803: 1335,
      y201903: 45327,
      y202003: 45233,
      y202103: 6257,
      y202203: 7532,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の水道光熱費",
    profit_and_loss_plan: {
      y201803: 135,
      y201903: 4527,
      y202003: 4233,
      y202103: 657,
      y202203: 732,
    },
    order: 2,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の水道光熱費",
    profit_and_loss_plan: {
      y201803: 135,
      y201903: 4527,
      y202003: 4233,
      y202103: 657,
      y202203: 732,
    },
    order: 3,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の消耗品費",
    profit_and_loss_plan: {
      y201803: 1315,
      y201903: 45127,
      y202003: 41233,
      y202103: 1657,
      y202203: 7132,
    },
    order: 4,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の賃借料",
    profit_and_loss_plan: {
      y201803: 15,
      y201903: 451,
      y202003: 433,
      y202103: 17,
      y202203: 72,
    },
    order: 5,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の地代家賃",
    profit_and_loss_plan: {
      y201803: 1522,
      y201903: 4521,
      y202003: 4223,
      y202103: 1227,
      y202203: 7442,
    },
    order: 6,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_cost_of_equipment",
    item: "損益計画の減価償却費",
    profit_and_loss_plan: {
      y201803: 231522,
      y201903: 534521,
      y202003: 334223,
      y202103: 751227,
      y202203: 547442,
    },
    order: 7,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_non_operating_expenses",
    item: "損益計画の支払利息･割引料",
    profit_and_loss_plan: {
      y201803: 2315,
      y201903: 5321,
      y202003: 33423,
      y202103: 7517,
      y202203: 5474,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_non_operating_expenses",
    item: "損益計画の雑損失",
    profit_and_loss_plan: {
      y201803: 52315,
      y201903: 25321,
      y202003: 133423,
      y202103: 75417,
      y202203: 56474,
    },
    order: 2,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_special_benefits",
    item: "損益計画の特別利益",
    profit_and_loss_plan: {
      y201803: 532,
      y201903: 7545,
      y202003: 573,
      y202103: 63,
      y202203: 1245,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_special_losses",
    item: "損益計画の特別損失",
    profit_and_loss_plan: {
      y201803: 252,
      y201903: 3745,
      y202003: 553,
      y202103: 563,
      y202203: 6145,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_corporate_inhabitant",
    item: "損益計画の法人税等",
    profit_and_loss_plan: {
      y201803: 4252,
      y201903: 43745,
      y202003: 53553,
      y202103: 5563,
      y202203: 65145,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_depreciation_cost",
    item: "損益計画の減価償却費合計",
    profit_and_loss_plan: {
      y202003: 53553,
      y202103: 551243,
      y202203: 65142145,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_pay_back_loan",
    item: "損益計画の借入金返済額合計",
    profit_and_loss_plan: {
      y202003: 33,
      y202103: 331243,
      y202203: 63145,
    },
    order: 1,
    descriptions: {},
  },
  {
    company_name: "狛江本店",
    field: "profit_and_loss_plan_balance_of_loan",
    item: "損益計画の借入金残高合計",
    profit_and_loss_plan: {
      y202003: 47,
      y202103: 754,
      y202203: 74,
    },
    order: 1,
    descriptions: {},
  },
];
