import {
  DocumentData,
  Firestore,
  WriteBatch,
  collection,
  doc,
} from "firebase/firestore";
import { fiscalPeriod } from "../utils/date";

type UpdateType = {
  headers: DocumentData;
  uid: string;
  RetirementBenefitCosts: string;
  firestore: Firestore;
  batch: WriteBatch;
};

export const version102 = ({
  headers,
  uid,
  RetirementBenefitCosts,
  firestore,
  batch,
}: UpdateType) => {
  // 初期データ
  const tablesRef = collection(firestore, "accounts", uid, "tables");

  // ヘッダーデータの更新
  const newHeaderDoc = doc(
    firestore,
    "accounts",
    uid,
    "tables",
    String(headers.NO_ID_FIELD)
  );

  batch.update(newHeaderDoc, {
    version: "1.0.2",
    updatedAt: new Date(),
  });

  const newId = doc(tablesRef).id;

  const newDoc = doc(firestore, "accounts", uid, "tables", newId);

  // 材料仕入高の追加
  const lastMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 0)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 1)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 2)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 3)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 4)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 5)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 6)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 7)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 8)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 9)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 10)]:
      null,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 11)]:
      null,
  };

  const thisMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 0)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 1)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 2)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 3)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 4)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 5)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 6)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 7)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 8)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 9)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 10)]: null,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 11)]: null,
  };

  batch.set(newDoc, {
    field: "material_fee",
    item: "材料仕入高",
    section: "変動",
    results_input: {},
    plans_input: {},
    plans_input_monthly: thisMonthlyData,
    results_of_the_current_term: thisMonthlyData,
    results_of_the_previous_period: lastMonthlyData,
    plans_input_estimated_in_this_term: thisMonthlyData,
    order: 2,
    descriptions: {},
    isDefault: true,
    createdAt: new Date(),
  });

  // 退職給付費用 名前の変更
  const retirementBenefitCostsDoc = doc(
    firestore,
    "accounts",
    uid,
    "tables",
    String(RetirementBenefitCosts)
  );

  batch.update(retirementBenefitCostsDoc, {
    item: "退職給付費用",
  });
};
