import { DocumentData } from "firebase/firestore";
import { abs, add, dotDivide, subtract, sum, transpose } from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { useGetFirebaseData } from "../../firebase";
import { Logout } from "../../lib/Logout";
import { sortData } from "../../utils";
import {
  calcByItemForProfitAndLossPlanMonthly,
  perValuesForSalesPlan,
  totalCalcByItemMonthlyTotalForProfitAndLossPlan,
} from "../../utils/calc";

export const useProfitAndLossPlanInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    descriptions,
  } = useSubjectItemsInitialData();

  const profitField = pageConstant.PROFIT_AND_LOSS_PLAN;
  const salesPlanField = pageConstant.SALES_PLAN;

  const { tableData } = useGetFirebaseData();

  try {
    // 今期までの売上高
    const netSalesTotalData = tableData.filter(
      (inputData) => inputData.field === "net_sales_total"
    );
    // 今期までの売上原価
    const costOfSalesTotalData = tableData.filter(
      (inputData) => inputData.field === "cost_of_sales_total"
    );
    // 今期までの期首商品棚卸高
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodData =
      tableData.filter(
        (inputData) =>
          inputData.field === "profit_and_loss_plan_cost_of_sales" &&
          inputData.item === "損益計画の期首商品棚卸高"
      );
    // 今期までの仕入高
    const parchaseAmountData = tableData.filter(
      (inputData) =>
        inputData.field === "profit_and_loss_plan_cost_of_sales" &&
        inputData.item === "損益計画の仕入高"
    );
    // 今期までの合計
    const totalAmountData = tableData.filter(
      (inputData) =>
        inputData.field === "profit_and_loss_plan_cost_of_sales" &&
        inputData.item === "損益計画の合計"
    );
    // 今期までの期末商品棚卸高
    const inventoryOfWorkInProcessAtTheEndOfThePeriodData = tableData.filter(
      (inputData) =>
        inputData.field === "profit_and_loss_plan_cost_of_sales" &&
        inputData.item === "損益計画の期末商品棚卸高"
    );

    const salesPlanHeadlines: string[] = [];
    net_sales.map((headlineData) => {
      salesPlanHeadlines.push(headlineData.item);
    });

    // 年度リスト
    const salesPlanTerms = headers.sales_plan.years;
    // 直近の年度の月リスト
    const headerTerms = headers.sales_plan;
    // 年間 販売数X売価
    const salesPlanTotal: number[] = [];
    // 毎年の合計
    const priceMultipleSales: number[][] = [];
    // 売上原価の年間総合計
    const costPlanTotal: number[] = [];
    // 売上原価の毎月の合計
    const priceMultipleSalesCost: number[][] = [];

    salesPlanHeadlines &&
      salesPlanHeadlines.map((item: string) => {
        // データの抽出およびorder順に並べ替え
        const data = tableData.filter((inputData) => inputData.field === item);
        const arrayRows = [...data];
        const newData = arrayRows.sort(function (
          prev: DocumentData,
          next: DocumentData
        ) {
          return prev.order - next.order;
        });

        const priceYearlyTotal: number[] = [];
        const costYearlyTotal: number[] = [];
        salesPlanTerms &&
          salesPlanTerms.map((salesPlanTerm: string, salesIndex: number) => {
            // 販売数X価格
            const multiplePriceData = perValuesForSalesPlan({
              headers: headerTerms.terms[salesIndex][salesPlanTerm],
              field: salesPlanField,
              state: newData,
              target: "price",
            });

            const multiPriceData = sum(multiplePriceData) as number;
            priceYearlyTotal.push(multiPriceData);

            // 販売数X原価
            const multipleCostPriceData = perValuesForSalesPlan({
              headers: headerTerms.terms[salesIndex][salesPlanTerm],
              field: salesPlanField,
              state: newData,
              target: "costPrice",
            });
            const multiCostPriceData = sum(multipleCostPriceData) as number;
            costYearlyTotal.push(multiCostPriceData);
          });
        priceMultipleSales.push(priceYearlyTotal);
        priceMultipleSalesCost.push(costYearlyTotal);
      });
    // 売上高の年間合計
    const transposePriceMultipleSales = transpose(priceMultipleSales);
    transposePriceMultipleSales.map((data) => {
      salesPlanTotal.push(sum(data));
    });

    // 売上原価の年間合計
    const transposePriceMultipleSalesCost = transpose(priceMultipleSalesCost);
    transposePriceMultipleSalesCost.map((data) => {
      costPlanTotal.push(sum(data) as number);
    });

    // 法人税等
    const corporateInhabitantData = sortData(
      "profit_and_loss_plan_corporate_inhabitant",
      tableData
    );
    // 損益計画の減価償却費
    const depreciationCostData = sortData(
      "profit_and_loss_plan_depreciation_cost",
      tableData
    );
    // 損益計画の借入金返済額
    const payBackLoanData = sortData(
      "profit_and_loss_plan_pay_back_loan",
      tableData
    );
    // 損益計画の借入金残高
    const balanceOfLoanData = sortData(
      "profit_and_loss_plan_balance_of_loan",
      tableData
    );
    // 事業計画ページの減価償却費
    const depreciationCostForBusinessPlanData = sortData(
      "business_plan_depreciation_cost",
      tableData
    );
    // 事業計画ページの設備投資額
    const capitalInvestmentForBusinessPlanData = sortData(
      "business_plan_capital_investment",
      tableData
    );
    const profitAndLossPlanState = {
      headers: headers,
      sales_plan_items: salesPlanHeadlines,
      net_sales_total_data: netSalesTotalData,
      cost_of_sales_total_data: costOfSalesTotalData,
      inventory_of_work_in_process_at_the_beginning_of_the_period_data:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodData,
      parchase_amount_data: parchaseAmountData,
      total_amount_data: totalAmountData,
      inventory_of_work_in_process_at_the_end_of_the_period_data:
        inventoryOfWorkInProcessAtTheEndOfThePeriodData,
      personal_cost_data: personal_cost,
      cost_of_equipment_data: selling_and_administrative,
      non_operating_income_data: non_operating_income,
      non_operating_expenses_data: non_operating_expenses,
      special_benefits_data: special_benefits,
      special_losses_data: special_losses,
      corporate_inhabitant_data: corporateInhabitantData,
      depreciation_cost_data: depreciationCostData,
      pay_back_loan_data: payBackLoanData,
      balance_of_loan_data: balanceOfLoanData,
      descriptions: descriptions,
      depreciation_cost_for_business_plan_data:
        depreciationCostForBusinessPlanData,
      capital_investment_for_business_plan_data:
        capitalInvestmentForBusinessPlanData,
    };

    // 売上高
    const netSalesMonthlyTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: netSalesTotalData,
      });
    // 売上原価
    const costOfSalesMonthlyTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: costOfSalesTotalData,
      });
    // 売上原価の詳細
    // 期首商品棚卸高
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: inventoryOfWorkInProcessAtTheBeginningOfThePeriodData,
      });
    // 仕入高
    const parchaseAmountTotal = totalCalcByItemMonthlyTotalForProfitAndLossPlan(
      {
        headers: headers[profitField],
        field: profitField,
        state: parchaseAmountData,
      }
    );
    // 合計
    const totalAmountTotal = totalCalcByItemMonthlyTotalForProfitAndLossPlan({
      headers: headers[profitField],
      field: profitField,
      state: totalAmountData,
    });
    // 期末商品棚卸高
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: inventoryOfWorkInProcessAtTheEndOfThePeriodData,
      });
    const netSalesResultsTotal = [
      netSalesMonthlyTotal.monthlyTotal[0]
        ? netSalesMonthlyTotal.monthlyTotal[0]
        : 0,
      netSalesMonthlyTotal.monthlyTotal[1]
        ? netSalesMonthlyTotal.monthlyTotal[1]
        : 0,
      salesPlanTotal[0] ? salesPlanTotal[0] : 0,
      salesPlanTotal[1] ? salesPlanTotal[1] : 0,
      salesPlanTotal[2] ? salesPlanTotal[2] : 0,
    ];
    const costOfSalesResultsTotal = [
      costOfSalesMonthlyTotal.monthlyTotal[0]
        ? costOfSalesMonthlyTotal.monthlyTotal[0]
        : 0,
      costOfSalesMonthlyTotal.monthlyTotal[1]
        ? costOfSalesMonthlyTotal.monthlyTotal[1]
        : 0,
      costPlanTotal[0] ? costPlanTotal[0] : 0,
      costPlanTotal[1] ? costPlanTotal[1] : 0,
      costPlanTotal[2] ? costPlanTotal[2] : 0,
    ];

    // 売上総利益
    const grossMargin = subtract(
      netSalesResultsTotal,
      costOfSalesResultsTotal
    ) as number[];
    // 人件費合計
    const personalCostTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: personal_cost,
    });

    // 物件費合計
    const costOfEquipmentTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: selling_and_administrative,
    });

    // 項目別販売費及び一般管理費合計
    const sellingAndAdministrativeTotal = add(
      personalCostTotal.totalData,
      costOfEquipmentTotal.totalData
    ) as number[];
    // 営業利益
    const operatingIncome: number[] =
      sellingAndAdministrativeTotal.length !== 0
        ? subtract(grossMargin, sellingAndAdministrativeTotal)
        : grossMargin;
    // 営業外費用合計
    const nonOperatingExpensesTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: non_operating_expenses,
    });
    // 営業外収益合計
    const nonOperatingIncomeTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: non_operating_income,
    });
    // 経常利益
    const currentEarnings: number[] =
      nonOperatingExpensesTotal.totalData.length !== 0 &&
      nonOperatingExpensesTotal.totalData.length !== 0
        ? subtract(
            nonOperatingIncomeTotal.totalData.length !== 0
              ? add(operatingIncome, nonOperatingIncomeTotal.totalData)
              : operatingIncome,
            nonOperatingExpensesTotal.totalData
          )
        : operatingIncome;
    // 特別利益合計
    const specialBenefitsTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: special_benefits,
    });
    // 特別損益合計
    const specialLossesTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: special_losses,
    });
    // 税引前当期純利益
    const ebit: number[] =
      specialLossesTotal.totalData.length !== 0
        ? subtract(
            specialBenefitsTotal.totalData.length !== 0
              ? add(currentEarnings, specialBenefitsTotal.totalData)
              : currentEarnings,
            specialLossesTotal.totalData
          )
        : currentEarnings;
    // 法人税等合計
    const corporateInhabitantTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: corporateInhabitantData,
    });
    // 当期純利益
    const netIncome: number[] =
      corporateInhabitantTotal.totalData.length !== 0
        ? subtract(ebit, corporateInhabitantTotal.totalData)
        : ebit;
    // 損益計画の減価償却費合計
    const depreciationCostTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: depreciationCostData,
    });
    // 損益計画の借入金返済額合計
    const payBackLoanTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: payBackLoanData,
    });
    // 損益計画の借入金残高合計
    const balanceOfLoanTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: balanceOfLoanData,
    });
    // 営業利益＋返済＋償却
    const loanTotal =
      depreciationCostTotal.totalData.length !== 0 &&
      payBackLoanTotal.totalData.length !== 0
        ? add(
            operatingIncome,
            add(depreciationCostTotal.totalData, payBackLoanTotal.totalData)
          )
        : depreciationCostTotal.totalData.length !== 0
        ? add(operatingIncome, depreciationCostTotal.totalData)
        : payBackLoanTotal.totalData.length !== 0
        ? add(operatingIncome, payBackLoanTotal.totalData)
        : operatingIncome;
    // 法定福利費
    const legalWelfareExpenseData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const legalWelfareExpenseTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: legalWelfareExpenseData,
    });
    // 法定福利費
    const retirementAnnuityData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const retirementAnnuityTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: retirementAnnuityData,
    });
    // 福利厚生費
    const welfareExpensesData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const welfareExpensesTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: welfareExpensesData,
    });
    // 給与支給総額 人件費-法定福利費-退職金掛金-福利厚生費
    const grossPersonalCost = subtract(
      personalCostTotal.totalData,
      add(
        legalWelfareExpenseTotal.totalData,
        add(retirementAnnuityTotal.totalData, welfareExpensesTotal.totalData)
      )
    );
    const businessPlanField = pageConstant.BUSINESS_PLAN;

    // 事業計画ページの経常利益
    const currentEarningsForBusinessPlan: number[] =
      nonOperatingExpensesTotal.totalData.length !== 0
        ? subtract(operatingIncome, nonOperatingExpensesTotal.totalData)
        : operatingIncome;
    // 事業計画ページの減価償却費
    const depreciationCostForBusinessPlanTotal =
      calcByItemForProfitAndLossPlanMonthly({
        headers: headers[profitField],
        field: businessPlanField,
        states: depreciationCostForBusinessPlanData,
      });
    // 事業計画ページの設備投資額
    const capitalInvestmentForBusinessPlanTotal =
      calcByItemForProfitAndLossPlanMonthly({
        headers: headers[profitField],
        field: businessPlanField,
        states: capitalInvestmentForBusinessPlanData,
      });
    // 事業計画ページの付加価値額
    const addedValue =
      personalCostTotal.totalData.length !== 0 &&
      depreciationCostForBusinessPlanTotal.totalData.length !== 0
        ? add(
            operatingIncome,
            add(
              personalCostTotal.totalData,
              depreciationCostForBusinessPlanTotal.totalData
            )
          )
        : personalCostTotal.totalData.length !== 0
        ? add(operatingIncome, personalCostTotal.totalData)
        : depreciationCostForBusinessPlanTotal.totalData.length !== 0
        ? add(operatingIncome, depreciationCostForBusinessPlanTotal.totalData)
        : operatingIncome;
    // 事業計画ページの伸び率
    const growthRate = dotDivide(
      subtract(addedValue, addedValue[1]),
      abs(addedValue[1])
    ) as number[];
    const editGrossPersonalCost: number[] = [];
    grossPersonalCost.map((_, index: number) => {
      if (index === 0) {
        editGrossPersonalCost.push(1);
      } else if (grossPersonalCost.length === index + 1) {
        return;
      }
      editGrossPersonalCost.push(grossPersonalCost[index]);
    });
    // 事業計画ページの伸び率
    const personalCostGrowthRate = dotDivide(
      subtract(grossPersonalCost, editGrossPersonalCost),
      abs(editGrossPersonalCost)
    ) as number[];
    // 事業計画ページの売上伸び率
    const bondNetSales = [
      ...netSalesMonthlyTotal.monthlyTotal,
      ...salesPlanTotal,
    ];
    const editNetSales: number[] = [];
    bondNetSales.map((_, index) => {
      if (index === 0) {
        editNetSales.push(1);
      } else if (bondNetSales.length === index + 1) {
        return;
      }
      editNetSales.push(bondNetSales[index]);
    });
    const netSalesGrowthRate =
      bondNetSales.length === editNetSales.length
        ? (dotDivide(
            subtract(bondNetSales, editNetSales),
            abs(editNetSales)
          ) as number[])
        : bondNetSales;

    const profitAndLossPlanCalcState = {
      net_sales_total: {
        results_total: netSalesMonthlyTotal.monthlyTotal,
        plans_by_item: priceMultipleSales,
        plans_total: salesPlanTotal,
      },
      cost_of_sales_total: {
        results_total: costOfSalesMonthlyTotal.monthlyTotal,
        plans_by_item: priceMultipleSalesCost,
        plans_total: costPlanTotal,
      },
      inventory_of_work_in_process_at_the_beginning_of_the_period_total: {
        total:
          inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal.monthlyTotal,
      },
      parchase_amount_total: {
        total: parchaseAmountTotal.monthlyTotal,
      },
      total_amount_total: {
        total: totalAmountTotal.monthlyTotal,
      },
      inventory_of_work_in_process_at_the_end_of_the_period_total: {
        total: inventoryOfWorkInProcessAtTheEndOfThePeriodTotal.monthlyTotal,
      },
      gross_margin: grossMargin,
      personal_cost_total: {
        by_item: personalCostTotal.byItem,
        total: personalCostTotal.totalData,
      },
      cost_of_equipment_total: {
        by_item: costOfEquipmentTotal.byItem,
        total: costOfEquipmentTotal.totalData,
      },
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      operating_income: operatingIncome,
      non_operating_expenses_total: {
        by_item: nonOperatingExpensesTotal.byItem,
        total: nonOperatingExpensesTotal.totalData,
      },
      non_operating_income_total: {
        by_item: nonOperatingIncomeTotal.byItem,
        total: nonOperatingIncomeTotal.totalData,
      },
      current_earnings: currentEarnings,
      special_benefits_total: {
        by_item: specialBenefitsTotal.byItem,
        total: specialBenefitsTotal.totalData,
      },
      special_losses_total: {
        by_item: specialLossesTotal.byItem,
        total: specialLossesTotal.totalData,
      },
      ebit: ebit,
      corporate_inhabitant_total: {
        by_item: corporateInhabitantTotal.byItem,
        total: corporateInhabitantTotal.totalData,
      },
      net_income: netIncome,
      depreciation_cost_total: {
        by_item: depreciationCostTotal.byItem,
        total: depreciationCostTotal.totalData,
      },
      pay_back_loan_total: {
        by_item: payBackLoanTotal.byItem,
        total: payBackLoanTotal.totalData,
      },
      balance_of_loan_total: {
        by_item: balanceOfLoanTotal.byItem,
        total: balanceOfLoanTotal.totalData,
      },
      loan_total: loanTotal,
      gross_personal_cost: grossPersonalCost,
      current_earnings_for_business_plan: currentEarningsForBusinessPlan,
      depreciation_cost_for_business_plan_total: {
        by_item: depreciationCostForBusinessPlanTotal.byItem,
        total: depreciationCostForBusinessPlanTotal.totalData,
      },
      capital_investment_for_business_plan_total: {
        by_item: capitalInvestmentForBusinessPlanTotal.byItem,
        total: capitalInvestmentForBusinessPlanTotal.totalData,
      },
      added_value: addedValue,
      growth_rate: growthRate,
      personal_cost_growth_rate: personalCostGrowthRate,
      net_sales_growth_rate: netSalesGrowthRate,
    };
    return { profitAndLossPlanState, profitAndLossPlanCalcState };
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
