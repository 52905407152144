import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PlPlansInputSalesCompositionRatio = ({
  headerTitle,
  title,
  rows,
  headers,
  calcState,
  isCurrentPlanMonthly,
}: {
  headerTitle?: string;
  title?: string;
  rows: DocumentData[];
  headers: DocumentData[];
  calcState: DocumentData;
  field: string;
  isCurrentPlanMonthly?: boolean;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 3 + (headers?.length ?? 0),
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white`,
      colSpan: 5,
    },
  ];
  grid.push(gridRow0);

  // className
  const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white before:border-l align-top`;

  const classCol2 = `${beforeWrapper} ${
    title ? "left-80px" : "left-0"
  }  p-4px bg-white before:border-b before:border-r before:border-r-c3333334d`;

  const classCol3 = `${borderSettings} text-right `;

  const classCol4 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 w-120px  text-right  before:bg-cfffee2 h-50px`;

  rows.map((row, rowIndex) => {
    const gridRow: GridElement[] = [];

    gridRow.push({
      value: rowIndex === 0 && title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 2,
    });

    gridRow.push({
      value: row.item,
      readOnly: true,
      className: `${classCol2} ${
        title && "before:border-l-c3333334d"
      } before:border-l ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      gridRow.push({
        value: `${showPercentage(calcState.by_item_ratio[rowIndex][tdIndex])}%`,
        readOnly: true,
        className: `${classCol3} ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    });
    // 年度貼付
    gridRow.push({
      value: `${showPercentage(calcState.plans_input_ratio[rowIndex])}%`,
      expr: showPercentage(calcState.plans_input_ratio[rowIndex]),
      readOnly: true,
      className: `${classCol4} ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }`,
    });
    // 月次貼付
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: `${showPercentage(calcState.by_item_ratio_total[rowIndex])}%`,
        expr: showPercentage(calcState.by_item_ratio_total[rowIndex]),
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    // 実績
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: `${showPercentage(calcState.current_term_ratio[rowIndex])}%`,
        expr: showPercentage(calcState.current_term_ratio[rowIndex]),
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });

    grid.push(gridRow);
  });

  const gridRow2: GridElement[] = [];

  gridRow2.push({
    value: "",
    readOnly: true,
    className: `${classCol1} before:border-b`,
    colSpan: 2,
  });

  gridRow2.push({
    value: "売上高合計",
    readOnly: true,
    className: `${classCol2}`,
    colSpan: title ? 2 : 4,
  });

  headers?.map((header, tdIndex) => {
    gridRow2.push({
      value: `${showPercentage(calcState.monthly_ratio_total[tdIndex])}%`,
      expr: showPercentage(calcState.monthly_ratio_total[tdIndex]),
      readOnly: true,
      className: `${classCol3}`,
    });
  });
  // 年度貼付
  gridRow2.push({
    value: `${showPercentage(calcState.plans_input_ratio_total)}%`,
    expr: showPercentage(calcState.plans_input_ratio_total),
    readOnly: true,
    className: `${classCol4}`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.monthly_ratio)}%`,
      expr: showPercentage(calcState.monthly_ratio),
      readOnly: true,
      className: `${classCol4}`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.current_term_ratio_total)}%`,
      expr: showPercentage(calcState.current_term_ratio_total),
      readOnly: true,
      className: `${classCol4}`,
    });

  grid.push(gridRow2);

  return grid;
};
