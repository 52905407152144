import { collection } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { pageConstant } from "../constants/pageConstants";
import { getStoredClientUid } from "../sessionStorage/sessionStorage";

export const useGetFirebaseData = () => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const navigate = useNavigate();

  // FirebaseのHeaderデータ取得
  // const headerDocRef = collection(firestore, "accounts");

  // const {
  //   status: headerStatus,
  //   data: headerData,
  //   error: headerError,
  // } = useFirestoreCollectionData(headerDocRef);

  // FirebaseのTableデータ取得
  const tableDocRef = collection(firestore, "accounts", uid, "tables");

  const {
    status: tableStatus,
    data: tableData,
    error: tableError,
  } = useFirestoreCollectionData(tableDocRef);

  // ログイン画面にリダイレクト
  useEffect(() => {
    if (tableError) {
      toast.warning(`Firestore data fetch error: ${tableError}`);
      console.error("Firestore data fetch error:", tableError);
      navigate(`/${pageConstant.LOGIN}`);
    }
  }, [tableError, navigate]);

  return { tableStatus, tableData };
};
