import React from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import AccountingSidebar from "./AccountingSidebar";
import { pageConstant } from "../../constants/pageConstants";
import { useClientsLists, useSpecialists } from "../../hooks/useSpecialists";
import { specialistsMenuToggleStore } from "../../store/specialistsStore";
import { toggleStore } from "../../store/switchState";

const Sidebar = () => {
  const url = useLocation();

  const clients = useClientsLists("");
  const specialists = useSpecialists();

  const [toggle, setToggle] = useRecoilState(toggleStore);
  const setSpecialistsMenuToggle = useSetRecoilState(
    specialistsMenuToggleStore
  );

  const planType: number | undefined =
    specialists !== null && specialists?.specialist?.priceplan?.plan_type;

  return (
    <aside
      aria-label="Sidebar"
      className={`${toggle ? "w-235px" : "w-30px"} 

      py-12px text-white bg-c1b2e48 text-14px ${
        (url.pathname === "/clients" ||
          url.pathname === "/client_invitation" ||
          url.pathname === "/complete_client_invitation" ||
          url.pathname === "/email_invitation" ||
          url.pathname === "/complete_email_invitation") &&
        "h-[100vh]"
      }`}
    >
      <div className="overflow-y-auto overflow-x-hidden py-4 px-3 bg-gray-50 rounded ">
        {toggle ? (
          <>
            <div className="flex justify-between items-center gap-8px w-200px mt-12px mb-20px mx-4px px-12px text-20px font-bold text-center">
              <a
                href={`${
                  url.pathname === "/clients" ||
                  url.pathname === "/client_invitation" ||
                  url.pathname === "/complete_client_invitation" ||
                  url.pathname === "/email_invitation" ||
                  url.pathname === "/complete_email_invitation" ||
                  url.pathname === "/accounts_specialists" ||
                  url.pathname === "/plan_change"
                    ? "/clients"
                    : url.pathname === "/accounting_period"
                    ? "/accounting_period"
                    : "/management_dashboard"
                }`}
              >
                <img
                  alt="CloudPlanner"
                  className="w-170px"
                  src={`${window.location.origin}/img/CP_logo_yoko.svg`}
                />
              </a>
              <button
                className="hovertext left-200px"
                data-hover="メニューを閉じる"
                onClick={() => {
                  setToggle(false), setSpecialistsMenuToggle(false);
                }}
              >
                <FaAngleDoubleLeft />
              </button>
            </div>

            <ul className="space-y-2">
              {url.pathname === "/clients" ||
              url.pathname === "/client_invitation" ||
              url.pathname === "/complete_client_invitation" ||
              url.pathname === "/email_invitation" ||
              url.pathname === "/complete_email_invitation" ||
              url.pathname === "/accounts_specialists" ||
              url.pathname === "/plan_change" ? (
                <React.Fragment>
                  <li
                    className={`mb-12px mt-20px px-12px text-c9dc6ff
                } `}
                  >
                    クライアント
                  </li>
                  <li
                    className={`mb-12px px-12px ${
                      url.pathname === "/clients" &&
                      "bg-c9dc6ff text-c1b2e48 py-2px"
                    }`}
                  >
                    {url.pathname === "/clients" ? (
                      <span>クライアント一覧</span>
                    ) : (
                      <Link
                        onClick={() => setSpecialistsMenuToggle(false)}
                        to={pageConstant.CLIENTS}
                      >
                        <span>クライアント一覧</span>
                      </Link>
                    )}
                  </li>
                  <li
                    className={`mb-12px px-12px ${
                      url.pathname === "/email_invitation" &&
                      "bg-c9dc6ff text-c1b2e48 py-2px"
                    }`}
                  >
                    {url.pathname === `/${pageConstant.EMAIL_INVITATION}` ? (
                      <span>クライアント新規登録</span>
                    ) : specialists !== null &&
                      planType === 1 &&
                      clients &&
                      clients !== null &&
                      clients.length >= 1 ? (
                      <button
                        onClick={() => {
                          setSpecialistsMenuToggle(false);
                          toast.warning(
                            "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                          );
                        }}
                      >
                        <span>クライアント新規登録</span>
                      </button>
                    ) : specialists !== null &&
                      planType === 2 &&
                      clients &&
                      clients !== null &&
                      clients.length >= 2 ? (
                      <button
                        onClick={() => {
                          setSpecialistsMenuToggle(false);
                          toast.warning(
                            "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                          );
                        }}
                      >
                        <span>クライアント新規登録</span>
                      </button>
                    ) : specialists !== null &&
                      planType === 3 &&
                      clients &&
                      clients !== null &&
                      clients.length >= 5 ? (
                      <button
                        onClick={() => {
                          setSpecialistsMenuToggle(false);
                          toast.warning(
                            "クライアントを登録できる上限を超えました。プランをアップグレードしてください"
                          );
                        }}
                      >
                        <span>クライアント新規登録</span>
                      </button>
                    ) : specialists !== null &&
                      planType === 4 &&
                      clients &&
                      clients !== null &&
                      clients.length >= 10 ? (
                      <button
                        onClick={() => {
                          setSpecialistsMenuToggle(false);
                          toast.warning(
                            "クライアントを登録できる上限を超えました。"
                          );
                        }}
                      >
                        <span>クライアント新規登録</span>
                      </button>
                    ) : (
                      <Link
                        onClick={() => setSpecialistsMenuToggle(false)}
                        to={`/${pageConstant.EMAIL_INVITATION}`}
                      >
                        <span>クライアント新規登録</span>
                      </Link>
                    )}
                  </li>
                </React.Fragment>
              ) : (
                <AccountingSidebar />
              )}
            </ul>
          </>
        ) : (
          <button
            className="hovertext top-25px left-6px"
            data-hover="メニューを開く"
            onClick={() => {
              setToggle(true), setSpecialistsMenuToggle(false);
            }}
          >
            <FaAngleDoubleRight className="text-20px" />
          </button>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
