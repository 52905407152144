import { DocumentData } from "firebase/firestore";
import { divide, sum, add, dotDivide, subtract, cumsum } from "mathjs";

type Props = {
  headers: DocumentData;
  resultsField: string;
  previousField: string;
  plansState: number[][] | null;
  plansTotal: number[] | null;
  plansMonthlyTotal: number[] | null;
  plansByItemCumsum: number[][] | null;
  plansMonthlyTotalCumsum: number[] | null;
  states: DocumentData[];
  isCostOfSales?: boolean;
  isMaterialFee?: boolean;
};

export const totalCalcForBudgetControlBasis = ({
  headers,
  states,
  resultsField,
  previousField,
  plansTotal,
  plansState,
  plansMonthlyTotal,
  plansByItemCumsum,
  plansMonthlyTotalCumsum,
  isCostOfSales,
  isMaterialFee,
}: Props) => {
  const currentByItem: number[][] = [];
  const currentByItemTotal: number[] = [];
  let currentMonthlyTotal: number[] = [];
  let currentMonthlyData: number[] = [0];

  const previousByItem: number[][] = [];
  const previousByItemTotal: number[] = [];
  let previousMonthlyTotal: number[] = [];
  let previousMonthlyData: number[] = [0];

  // 累計
  const currentByItemCumsum: number[][] = [];
  const previousByItemCumsum: number[][] = [];

  const currentAndPreviousDifferenceByItemCumsum: number[][] = [];
  const currentAndPreviousDifferenceByItemRatioCumsum: number[][] = [];

  const currentAndPreviousDifferenceByItem: number[][] = [];
  const currentAndPreviousDifferenceByItemRatio: number[][] = [];

  let currentAndPreviousDifferenceMonthlyData: number[] = [0];

  // 今期と計画の差異
  const currentAndPlanDifferenceByItem: number[][] = [];
  const currentAndPlanDifferenceByItemRatio: number[][] = [];

  // 今期と計画の差異（累計）
  const currentAndPlanDifferenceByItemCumsum: number[][] = [];
  const currentAndPlanDifferenceByItemRatioCumsum: number[][] = [];

  states.map((state, stateIndex) => {
    const currentItemData: number[] = [];
    const previousItemData: number[] = [];

    const currentAndPreviousDifferenceItemData: number[] = [];
    const currentAndPreviousDifferenceItemRatioData: number[] = [];

    // 今期実績
    headers[resultsField].map((header: DocumentData) => {
      const currentTerm = header.period;

      if (
        !state[resultsField][currentTerm] ||
        state[resultsField][currentTerm] === null
      ) {
        return currentItemData.push(0);
      }
      const currentData = state[resultsField][currentTerm];
      currentItemData.push(currentData);
    });

    // 前期実績
    headers[previousField].map((header: DocumentData, index: number) => {
      const previousTerm = header.period;

      let previousData = 0;
      if (!state[previousField][previousTerm]) {
        previousItemData.push(0);
      } else {
        previousData = state[previousField][previousTerm];
        previousItemData.push(previousData);
      }

      // 今期と前期の差異の項目
      const currentAndPreviousDifferenceData = subtract(
        currentItemData[index],
        previousData
      ) as number;

      currentAndPreviousDifferenceItemData.push(
        currentAndPreviousDifferenceData
      );
      const currentAndPreviousDifferenceRatioData = divide(
        currentItemData[index],
        previousData
      ) as number;
      currentAndPreviousDifferenceItemRatioData.push(
        currentAndPreviousDifferenceRatioData
      );
    });

    if (stateIndex === 0) {
      currentMonthlyData = currentItemData;
    } else {
      currentMonthlyData = add(currentMonthlyData, currentItemData);
    }

    currentByItem.push(currentItemData);
    currentByItemCumsum.push(cumsum(currentItemData) as number[]);

    // 売上原価の期首商品棚卸高、期末商品棚卸高と材料費の期首材料棚卸高、期末材料棚卸高の値を0にする
    if (
      ((isCostOfSales || isMaterialFee) && stateIndex === 0) ||
      ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1)
    ) {
      currentByItemTotal.push(0);
    } else {
      currentByItemTotal.push(sum(currentItemData) as number);
    }

    if ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1) {
      currentMonthlyTotal = subtract(
        subtract(currentMonthlyData, currentItemData),
        currentItemData
      ) as number[];
    } else if (states.length === stateIndex + 1) {
      currentMonthlyTotal = currentMonthlyData;
    }

    if (stateIndex === 0) {
      previousMonthlyData = previousItemData;
    } else {
      previousMonthlyData = add(previousMonthlyData, previousItemData);
    }

    previousByItem.push(previousItemData);
    previousByItemCumsum.push(cumsum(previousItemData) as number[]);

    if (
      ((isCostOfSales || isMaterialFee) && stateIndex === 0) ||
      ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1)
    ) {
      previousByItemTotal.push(0);
    } else {
      previousByItemTotal.push(sum(previousItemData) as number);
    }

    if (stateIndex === 0) {
      currentAndPreviousDifferenceMonthlyData =
        currentAndPreviousDifferenceItemData;
    } else {
      currentAndPreviousDifferenceMonthlyData = add(
        currentAndPreviousDifferenceMonthlyData,
        currentAndPreviousDifferenceItemData
      );
    }

    currentAndPreviousDifferenceByItemRatio.push(
      currentAndPreviousDifferenceItemRatioData
    );

    if ((isCostOfSales || isMaterialFee) && states.length === stateIndex + 1) {
      previousMonthlyTotal = subtract(
        subtract(previousMonthlyData, previousItemData),
        previousItemData
      ) as number[];
    } else if (states.length === stateIndex + 1) {
      previousMonthlyTotal = previousMonthlyData;
    }

    // 今期と前期の差異
    currentAndPreviousDifferenceByItem.push(
      currentAndPreviousDifferenceItemData
    );

    // 今期と前期の差異と比率（累計）
    currentAndPreviousDifferenceByItemCumsum.push(
      subtract(
        cumsum(currentItemData) as number[],
        cumsum(previousItemData) as number[]
      ) as number[]
    );
    currentAndPreviousDifferenceByItemRatioCumsum.push(
      dotDivide(
        cumsum(currentItemData) as number[],
        cumsum(previousItemData) as number[]
      ) as number[]
    );

    const currentAndPlansDifferenceData =
      plansState !== null && plansState[stateIndex].length !== 0
        ? (subtract(currentItemData, plansState[stateIndex]) as number[])
        : currentItemData;
    currentAndPlanDifferenceByItem.push(currentAndPlansDifferenceData);

    const currentAndPlanDifferenceRatioData =
      plansState !== null && plansState[stateIndex].length !== 0
        ? (dotDivide(currentItemData, plansState[stateIndex]) as number[])
        : currentItemData;
    currentAndPlanDifferenceByItemRatio.push(currentAndPlanDifferenceRatioData);

    //  累計
    currentAndPlanDifferenceByItemCumsum.push(
      cumsum(currentAndPlansDifferenceData) as number[]
    );

    const currentAndPlanDifferenceRatioDataCumsum =
      plansByItemCumsum !== null && plansByItemCumsum[stateIndex].length !== 0
        ? (dotDivide(
            cumsum(currentItemData) as number[],
            plansByItemCumsum[stateIndex]
          ) as number[])
        : (cumsum(currentItemData) as number[]);
    currentAndPlanDifferenceByItemRatioCumsum.push(
      currentAndPlanDifferenceRatioDataCumsum
    );
  });

  // 前年実績差異の合計
  const currentAndPreviousDifferenceByItemTotal = subtract(
    currentByItemTotal,
    previousByItemTotal
  ) as number[];

  // 今期年間
  const currentYearlyTotal = sum(currentByItemTotal) as number;

  // 前期年間
  const previousYearlyTotal = sum(previousByItemTotal) as number;

  // 今期と前期の差異年間
  const currentAndPreviousDifferenceMonthlyTotal = subtract(
    currentMonthlyTotal,
    previousMonthlyTotal
  ) as number[];
  const currentAndPreviousDifferenceYearlyTotal = sum(
    currentAndPreviousDifferenceByItemTotal
  ) as number;

  const currentAndPreviousDifferenceMonthlyTotalRatio = dotDivide(
    currentMonthlyTotal,
    previousMonthlyTotal
  ) as number[];

  const currentAndPreviousDifferenceByItemTotalRatio = dotDivide(
    currentByItemTotal,
    previousByItemTotal
  ) as number[];

  const currentAndPreviousDifferenceYearlyTotalRatio = divide(
    currentYearlyTotal,
    previousYearlyTotal
  ) as number;

  if (currentByItemTotal.length === 0) {
    currentByItemTotal.push(0);
  }

  const currentAndPlanDifferenceByItemTotal =
    plansTotal !== null
      ? (subtract(currentByItemTotal, plansTotal) as number[])
      : currentByItemTotal;

  const currentAndPlanDifferenceMonthlyTotal: number[] =
    plansMonthlyTotal !== null && plansMonthlyTotal.length !== 0
      ? (subtract(currentMonthlyTotal, plansMonthlyTotal) as number[])
      : currentMonthlyTotal;

  const currentAndPlanDifferenceByItemTotalRatio =
    plansTotal !== null && plansTotal.length !== 0
      ? (dotDivide(currentByItemTotal, plansTotal) as number[])
      : currentByItemTotal;

  const currentAndPlanDifferenceYearlyTotal = sum(
    currentAndPlanDifferenceByItemTotal
  ) as number;

  const currentAndPlanDifferenceMonthlyTotalRatio =
    plansMonthlyTotal !== null && plansMonthlyTotal.length !== 0
      ? (dotDivide(currentMonthlyTotal, plansMonthlyTotal) as number[])
      : plansMonthlyTotal;

  const currentAndPlanDifferenceYearlyTotalRatio =
    plansTotal !== null
      ? (divide(currentYearlyTotal, sum(plansTotal) as number) as number)
      : currentYearlyTotal;

  // 累計の計算
  const currentMonthlyTotalCumsum = cumsum(currentMonthlyTotal) as number[];
  const previousMonthlyTotalCumsum = cumsum(previousMonthlyTotal) as number[];
  const currentAndPreviousDifferenceMonthlyTotalCumsum = subtract(
    currentMonthlyTotalCumsum,
    previousMonthlyTotalCumsum
  ) as number[];
  const currentAndPreviousDifferenceMonthlyTotalRatioCumsum = dotDivide(
    currentMonthlyTotalCumsum,
    previousMonthlyTotalCumsum
  ) as number[];

  const currentAndPlanDifferenceMonthlyTotalCumsum =
    plansMonthlyTotalCumsum !== null && plansMonthlyTotalCumsum.length !== 0
      ? (subtract(
          currentMonthlyTotalCumsum,
          plansMonthlyTotalCumsum
        ) as number[])
      : currentMonthlyTotalCumsum;
  const currentAndPlanDifferenceMonthlyTotalRatioCumsum =
    plansMonthlyTotalCumsum != null && plansMonthlyTotalCumsum.length !== 0
      ? (dotDivide(
          currentMonthlyTotalCumsum,
          plansMonthlyTotalCumsum
        ) as number[])
      : currentMonthlyTotalCumsum;

  return {
    currentByItem,
    currentByItemTotal,
    currentMonthlyTotal,
    currentYearlyTotal,
    previousByItem,
    previousByItemTotal,
    previousMonthlyTotal,
    previousYearlyTotal,
    currentAndPreviousDifferenceByItem,
    currentAndPreviousDifferenceByItemTotal,
    currentAndPreviousDifferenceByItemRatio,
    currentAndPreviousDifferenceByItemTotalRatio,
    currentAndPreviousDifferenceMonthlyTotal,
    currentAndPreviousDifferenceMonthlyTotalRatio,
    currentAndPreviousDifferenceYearlyTotal,
    currentAndPreviousDifferenceYearlyTotalRatio,
    currentAndPlanDifferenceByItem,
    currentAndPlanDifferenceByItemTotal,
    currentAndPlanDifferenceByItemRatio,
    currentAndPlanDifferenceByItemTotalRatio,
    currentAndPlanDifferenceMonthlyTotal,
    currentAndPlanDifferenceMonthlyTotalRatio,
    currentAndPlanDifferenceYearlyTotal,
    currentAndPlanDifferenceYearlyTotalRatio,
    currentByItemCumsum,
    currentMonthlyTotalCumsum,
    previousByItemCumsum,
    previousMonthlyTotalCumsum,
    currentAndPreviousDifferenceByItemCumsum,
    currentAndPreviousDifferenceByItemRatioCumsum,
    currentAndPreviousDifferenceMonthlyTotalCumsum,
    currentAndPreviousDifferenceMonthlyTotalRatioCumsum,
    currentAndPlanDifferenceByItemCumsum,
    currentAndPlanDifferenceByItemRatioCumsum,
    currentAndPlanDifferenceMonthlyTotalCumsum,
    currentAndPlanDifferenceMonthlyTotalRatioCumsum,
  };
};
