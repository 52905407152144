import { deleteDoc, doc, DocumentData } from "firebase/firestore";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useSetRecoilState } from "recoil";
import { SubHeader } from "../../components/header";
import { AccountTabs, CsvImport } from "../../components/initialSettings";
import {
  CreateAccountModal,
  UpdateAccountModal,
} from "../../components/molecules";
import {
  AccountTitlesItems,
  balanceSheetAccountHeadings,
  costReportAccountHeadings,
  profitAndLossStatementAccountHeadings,
} from "../../data";
import {
  useResultsInputInitialData,
  useSubjectItemsInitialData,
} from "../../hooks/initialData";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import {
  createModalIsOpenStore,
  updateModalIsOpenStore,
  updateProfitAndLossStatementStore,
} from "../../store";

const AccountTitles = () => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const setUpdateAccountTitleState = useSetRecoilState(
    updateProfitAndLossStatementStore
  );

  const setCreateIsOpen = useSetRecoilState(createModalIsOpenStore);
  const setUpdateIsOpen = useSetRecoilState(updateModalIsOpenStore);

  useSubjectItemsInitialData();
  useResultsInputInitialData();

  const [tab, setTab] = useState(0);

  const { profitAndLossStatementItems, costReportItems, balanceSheetItems } =
    AccountTitlesItems();

  const accountTitlesData =
    tab === 0
      ? balanceSheetItems
      : tab === 1
      ? profitAndLossStatementItems
      : costReportItems;

  const accountingHeaders =
    tab === 0
      ? balanceSheetAccountHeadings
      : tab === 1
      ? profitAndLossStatementAccountHeadings
      : costReportAccountHeadings;

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    field: string
  ) => {
    e.preventDefault();

    const dataRef = doc(firestore, "accounts", uid, "tables", id);

    if (
      field === "net_sales" &&
      resultsAndPlansDataState.net_sales.length === 1
    ) {
      toast.warning("売上高は、1項目以上必要です");
      toast.warning("編集ボタンから勘定科目細目名の変更が可能です");
      return;
    }

    await deleteDoc(dataRef)
      .then(() => {
        toast.success("勘定科目を削除しました");
      })
      .catch((error) => {
        toast.error("削除できませんでした");
        throw new Error(error.message);
      });
  };

  return (
    <React.Fragment>
      <SubHeader title="勘定科目の設定" />
      <AccountTabs setTab={setTab} tab={tab} />
      <div className="fixed w-[calc(100vw-265px)]  flex justify-between mt-170px text-18px mx-16px ">
        <div>
          {tab === 0 && "貸借対照表の勘定科目一覧"}
          {tab === 1 && "損益計算書の勘定科目一覧"}
          {tab === 2 && "製造原価報告書の勘定科目一覧"}
        </div>

        <div className="flex gap-6px text-13px">
          {/* <button className="px-16px py-8px bg-c427ed1 text-white rounded">
            標準科目の割り当て
          </button> */}
          <CsvImport />
        </div>
      </div>
      <div className="fixed w-[calc(100vw-265px)] h-[calc(100vh-274px)] mt-220px top-0 overflow-x-scroll m-16px z-30">
        <div className="flex flex-col">
          <div className="w-full flex-grow">
            <table>
              <thead
                className="sticky top-0 px-6px py-3px border-b-2 border-x z-30
      pt-120px"
              >
                <tr>
                  <th className="min-w-[13.75rem] w-220px px-16px py-8px bg-c1b2e48 text-white text-14px text-left">
                    勘定科目名
                  </th>
                  <th
                    className={`w-[calc(100vw-380px)] px-16px py-8px border-collapse text-left  bg-c1b2e48 text-white text-14px`}
                  >
                    勘定科目細目
                  </th>
                  <th
                    className={`min-w-[5rem] w-80px px-16px py-8px border-collapse text-left  bg-c1b2e48 text-white text-14px`}
                  >
                    区分
                  </th>
                  <th className="min-w-[5rem] w-80px px-16px py-8px border-collapse   bg-c1b2e48 text-white text-12px"></th>
                  <th className="min-w-[5rem] w-80px px-16px py-8px border-collapse   bg-c1b2e48 text-white text-12px"></th>
                </tr>
              </thead>
              <tbody>
                {accountTitlesData.map((item: DocumentData, index: number) => (
                  <tr className="relative border-l" key={index}>
                    <td
                      className={`p-4px border border-r-c3333334d border-b-c3333334d text-left h-40px`}
                    >
                      {accountingHeaders.filter(
                        (data: DocumentData) => data.field === item?.field
                      )[0] &&
                        accountingHeaders.filter(
                          (data: DocumentData) => data.field === item?.field
                        )[0].name}
                    </td>
                    <td
                      className={`relative p-4px border border-x-c3333334d border-b-c3333334d text-left`}
                    >
                      {item.item}
                    </td>
                    <td
                      className={`relative p-4px border border-x-c3333334d border-b-c3333334d text-left`}
                    >
                      {item.section}
                    </td>
                    <td
                      className={`relative p-4px border border-x-c3333334d  border-b-c3333334d text-left`}
                    >
                      {!item.isDefault && (
                        <button
                          className="px-12px py-4px mx-auto bg-c1b2e48 text-white text-15px rounded flex  hover:opacity-80"
                          onClick={() => {
                            setUpdateAccountTitleState(item);
                            setUpdateIsOpen(true);
                          }}
                        >
                          編集
                        </button>
                      )}
                    </td>
                    <td
                      className={`relative p-4px border border-x-c3333334d  border-b-c3333334d text-left`}
                    >
                      {!item.isDefault && (
                        <button
                          className="px-12px py-4px mx-auto bg-red text-white text-15px rounded flex  hover:opacity-80"
                          onClick={(e) =>
                            handleDelete(e, item.NO_ID_FIELD, item.field)
                          }
                        >
                          削除
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <button
          className="fixed mt-12px px-12px py-6px bottom-12px ml-16px text-c427ed1 border border-c427ed1 rounded"
          onClick={() => setCreateIsOpen(true)}
        >
          <div className="inline-block -mb-2px mr-4px">
            <FaPlus />
          </div>
          勘定科目追加
        </button>
        {resultsAndPlansDataState.net_sales.length === 0 && (
          <div className="fixed mt-12px px-12px py-6px bottom-8px right-16px text-20px font-bold text-red tracking-wide">
            会計機能を使用するには、損益計算書の売上高を追加してください
          </div>
        )}
      </div>
      <UpdateAccountModal headings={accountingHeaders} tab={tab} />
      <CreateAccountModal
        data={accountTitlesData}
        headings={accountingHeaders}
        tab={tab}
      />
    </React.Fragment>
  );
};

export default AccountTitles;
