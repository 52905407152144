import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  PlResultsAndPlansBody,
  PlResultsAndPlansGrandTotal,
  PlResultsAndPlansTotal,
  PlResultsAndPlansWithTax,
  PlResultsInputRatio,
  PlSimpleCf,
  ResultsAndPlanHeader,
  TableBlank,
} from "../../../lib/datasheets/resultsAndPlans";
import { ResultsInputFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: ResultsInputFieldsCalc;
}

const PlResultsAndPlansTable: React.FC<Props> = ({ url, calcState }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlResultsAndPlansBody({
    title: "売上高",
    headers: resultsAndPlansDataState.headers[url],
    rows: resultsAndPlansDataState.net_sales,
    url: url,
    unit: unit,
  });
  grid.push(
    ...PlResultsAndPlansTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_sales_total,
      ratio: calcState.net_sales_ratio,
      totalField: `${url}_net_sales_total`,
      ratioField: `${url}_net_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  grid.push(
    ...PlResultsAndPlansBody({
      title: "売上原価",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.cost_of_sales,
      url: url,
      unit: unit,
    })
  );
  grid.push(
    ...PlResultsAndPlansTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cost_of_sales_total,
      ratio: calcState.cost_of_sales_ratio,
      totalField: `${url}_cost_of_sales_total`,
      ratioField: `${url}_cost_of_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );
  grid.push(
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_margin,
      ratio: calcState.gross_margin_ratio,
      totalField: `${url}_gross_margin`,
      ratioField: `${url}_gross_margin_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "販売費及び一般管理費",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.personal_cost,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上高人件費比率",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.personal_cost_total,
      ratio: calcState.personal_cost_ratio,
      totalField: `${url}_personal_cost_total`,
      ratioField: `${url}_personal_cost_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.selling_and_administrative,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.selling_and_administrative_total,
      ratio: calcState.selling_and_administrative_ratio,
      totalField: `${url}_selling_and_administrative_total`,
      ratioField: `${url}_selling_and_administrative_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_income,
      ratio: calcState.operating_income_ratio,
      totalField: `${url}_operating_income`,
      ratioField: `${url}_operating_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_profits_before_amortization,
      ratio: calcState.operating_profits_before_amortization_ratio,
      totalField: `${url}_operating_profits_before_amortization`,
      ratioField: `${url}_operating_profits_before_amortization_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "営業外収益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_income,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_income_total,
      totalField: `${url}_non_operating_income_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "営業外費用",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_expenses,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_expenses_total,
      totalField: `${url}_non_operating_expenses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.current_earnings,
      ratio: calcState.current_earnings_ratio,
      totalField: `${url}_current_earnings`,
      ratioField: `${url}_current_earnings_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebitda,
      ratio: calcState.ebitda_ratio,
      totalField: `${url}_ebitda`,
      ratioField: `${url}_ebitda_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "特別利益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_benefits,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_benefits_total,
      totalField: `${url}_special_benefits_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansBody({
      title: "特別損失",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_losses,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_losses_total,
      totalField: `${url}_special_losses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebit,
      ratio: calcState.ebit_ratio,
      totalField: `${url}_ebit`,
      ratioField: `${url}_ebit_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsAndPlansWithTax({
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      url: url,
      unit: unit,
    }),
    ...PlResultsAndPlansGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_income,
      ratio: calcState.net_income_ratio,
      totalField: `${url}_net_income`,
      ratioField: `${url}_net_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...TableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlSimpleCf({
      headers: resultsAndPlansDataState.headers[url],
      row1: calcState.simple_cf_row1,
      row2: calcState.simple_cf_row2,
      row3: calcState.simple_cf_row3,
      field1: `${url}_simple_cf_row1`,
      field2: `${url}_simple_cf_row2`,
      field3: `${url}_simple_cf_row3`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  grid.push(
    ...TableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlResultsInputRatio({
      headerTitle: "売上構成比",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_sales_total,
      field: url,
      calcState: calcState.net_sales_total_by_item_ratio,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <ResultsAndPlanHeader
            headers={resultsAndPlansDataState.headers[url]}
            title="■PL"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlResultsAndPlansTable);
