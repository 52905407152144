import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { DarkButton, LightButton } from "../../components/atoms";
import { GuestLayout } from "../../components/layout";

const VerifySuccess = () => {
  const navigate = useNavigate();

  return (
    <GuestLayout>
      <div className="w-[600px]">
        <img
          alt=""
          className="w-75px h-75px m-auto"
          src={`${window.location.origin}/img/mail.svg`}
        />
        <h2 className="text-30px font-bold text-center py-30px">
          メールをご確認ください
        </h2>
        <p className="text-14px text-center leading-6">
          パスワードリセットメールがあなたのメールアドレスに送信されました。
          <br />
          URLの有効期限は24時間です。
        </p>
        <div className="flex gap-20px pt-30px">
          <LightButton
            onClick={() => {
              window.close();
            }}
          >
            この画面を閉じる
          </LightButton>
          <DarkButton
            onClick={() => {
              navigate("/");
            }}
          >
            TOPへ戻る
          </DarkButton>
        </div>
      </div>
    </GuestLayout>
  );
};

export default memo(VerifySuccess);
