import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlEstimatedInThisTermBody = ({
  headers,
  rows,
  title,
  calcState,
  url,
  bodyField,
  unit,
  descriptions,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  title: string | null;
  calcState: DocumentData;
  url: string;
  bodyField?: string;
  unit: number;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];
  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white top-0 font-bold align-text-top mt-12px before:border-l before:h-full`;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-l before:border-l-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x before:border-x-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

      const classCol4 = `${borderSettings}  ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right   ${
        rowIndex % 2 !== 0
          ? "bg-c2a4b770d before:border-l-c3333334d"
          : "before:border-l-c3333334d"
      } `;

      const classCol5 = `relative p-4px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right   ${
        rowIndex % 2 !== 0
          ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
          : "before:bg-cfffee2 before:border-x"
      }`;

      const classCol6 = `${borderSettings} before:border-r ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} text-left  h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: rowIndex === 0 ? title : "",
        expr: rowIndex === 0 ? title : "",
        readOnly: true,
        className: classCol1,
        colSpan: 2,

        row: row,
        url: url,
        key: "",
      });

      gridRow.push(
        { value: row.item, readOnly: true, className: classCol2 },
        {
          value: row.section,
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      headers.map((_: any, tdIndex: number) => {
        gridRow.push({
          value: ShowTableDataTriangle(
            calcState.by_item[rowIndex][tdIndex] / unit
          ),
          expr: calcState.by_item[rowIndex][tdIndex] / unit,
          className: classCol4,
          colSpan: 1,
        });
      });

      // 見込み
      gridRow.push({
        value: ShowTableData(round(calcState.by_item_total[rowIndex]) / unit),
        expr: round(calcState.by_item_total[rowIndex]) / unit,
        readOnly: true,
        className: classCol5,
        colSpan: 1,
      });

      gridRow.push({
        value: bodyField
          ? descriptions[`${bodyField}_${rowIndex}`]
          : row?.descriptions[`${url}_${rowIndex}`],
        expr: bodyField
          ? descriptions[`${bodyField}_${rowIndex}`]
          : row?.descriptions[`${url}_${rowIndex}`],
        readOnly: false,
        className: classCol6,
        row: descriptions,
        url: url,
        field: `${bodyField}_${rowIndex}`,
        key: "descriptions_calc",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
