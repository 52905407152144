import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PlPlansInputResultsInputRatio = ({
  headerTitle,
  title,
  rows,
  headers,
  total,
  field,
  calcState,
  descriptions,
}: {
  headerTitle?: string;
  title?: string;
  rows: DocumentData[];
  headers: DocumentData[];
  total: number[];
  field: string;
  netSalesCalc?: DocumentData;
  calcState: DocumentData;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 1 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white ${
      rows.length === rowIndex + 1 && "before:border-b"
    } before:border-l align-top`;

    const classCol2 = `${beforeWrapper} ${
      title ? "left-80px" : "left-0"
    }  p-4px bg-white before:border-b before:border-x ${
      title && "before:border-l-c3333334d"
    } before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol3 = `${borderSettings} ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right `;

    const classCol5 = `${borderSettings}  before:border-x before:border-b before:border-l-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} h-50px description`;

    const gridRow: GridElement[] = [];

    gridRow.push({
      value: rowIndex === 0 && title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 2,
    });

    gridRow.push({
      value: row.item,
      readOnly: true,
      className: `${classCol2}`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      gridRow.push({
        value:
          row[field][header.period] !== 0 && total && total[tdIndex] !== 0
            ? `${showPercentage(calcState.byItemRatio[rowIndex][tdIndex])}%`
            : "0%",
        expr:
          row[field][header.period] !== 0 && total && total[tdIndex] !== 0
            ? showPercentage(calcState.byItemRatio[rowIndex][tdIndex])
            : 0,
        readOnly: true,
        className: `${classCol3} ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"}`,
      });
    });

    gridRow.push({
      value: descriptions[`${field}_results_${rowIndex}`],
      readOnly: false,
      className: `${classCol5}`,
      field: `${field}_results_${rowIndex}`,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow);
  });

  return grid;
};
