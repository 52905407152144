import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CashFlowTableBody = ({
  title,
  subTitle,
  itemTitles,
  rows,
  byItem,
  byItemTotal,
  key,
  descriptions,
  unit,
}: {
  title: string;
  subTitle: string;
  itemTitles?: string[];
  headers: DocumentData[];
  rows: DocumentData[];
  byItem: number[][];
  byItemTotal: number[];
  url: string;
  dataUrl: string;
  key: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 p-4px bg-white font-bold before:border-l`;

  const classCol2 = `${beforeWrapper} left-120px p-4px bg-white font-bold before:border-l before:border-l-c3333334d`;

  const classCol3 = `${beforeWrapper} left-240px p-4px bg-white before:border-b`;

  const classCol4 = `${beforeWrapper} left-380px p-4px bg-white  before:border-l before:border-l-c3333334d before:border-b`;

  const classCol5 = `${beforeWrapper} left-425px w-45px min-w-[2.8175rem] p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b`;

  const classCol6 = `${borderSettings} text-right font-bold bg-c2a4b770d`;

  const classCol7 = `${borderSettings} text-right font-bold`;

  const classCol8 = `relative p-4px bg-cfffee2 before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0  text-right font-bold focus:text-left`;

  const classCol9 = `description ${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;
  byItem?.map((items, itemsIndex) => {
    const gridRow: GridElement[] = [
      {
        value: itemsIndex === 0 && title,
        readOnly: true,
        className: `${classCol1} `,
      },
      {
        value: itemsIndex === 0 && subTitle,
        readOnly: true,
        className: `${classCol2}`,
      },
      {
        value: itemTitles
          ? itemTitles[itemsIndex]
          : key === "selling_and_administrative_body" && itemsIndex === 0
          ? "人件費合計"
          : rows[itemsIndex].item,
        readOnly: true,
        className: `${classCol3} before:border-l before:border-l-c3333334d  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol4}  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol5}  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
    ];

    // 前年度実績
    gridRow.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    items?.map((item: number) => {
      gridRow.push({
        value: isNaN(item) || !item ? 0 : ShowTableDataTriangle(item / unit),
        expr: isNaN(item) || !item ? 0 : item / unit,
        readOnly: true,
        className: `${classCol7} before:border-b  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      });
    });

    gridRow.push({
      value: isNaN(byItemTotal[itemsIndex])
        ? 0
        : ShowTableDataTriangle(byItemTotal[itemsIndex] / unit),
      expr: isNaN(byItemTotal[itemsIndex]) ? 0 : byItemTotal[itemsIndex] / unit,
      readOnly: true,
      className: `${classCol8} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow.push({
      value: descriptions[
        itemTitles ? `${key}${itemsIndex}` : `${key}_${rows[itemsIndex].item}`
      ]
        ? descriptions[
            itemTitles
              ? `${key}${itemsIndex}`
              : `${key}_${rows[itemsIndex].item}`
          ]
        : "",
      expr: descriptions[
        itemTitles ? `${key}${itemsIndex}` : `${key}_${rows[itemsIndex].item}`
      ]
        ? descriptions[
            itemTitles
              ? `${key}${itemsIndex}`
              : `${key}_${rows[itemsIndex].item}`
          ]
        : "",
      readOnly: false,
      className: `${classCol9}  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
      field: itemTitles
        ? `${key}${itemsIndex}`
        : `${key}_${rows[itemsIndex].item}`,
      key: "descriptions_calc",
      row: descriptions,
    });

    grid.push(gridRow);
  });

  return grid;
};
