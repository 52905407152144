/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, doc, DocumentData, writeBatch } from "firebase/firestore";
import React from "react";
import { useCSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useSetRecoilState } from "recoil";
import {
  useResultsInputInitialData,
  useSubjectItemsInitialData,
} from "../../hooks/initialData";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import { switchLoadingState } from "../../store/switchState";

export const CsvImport = () => {
  const firestore = useFirestore();

  const { CSVReader } = useCSVReader();

  const uid = getStoredClientUid();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const setIsLoading = useSetRecoilState(switchLoadingState);

  useResultsInputInitialData();

  return (
    <CSVReader
      onUploadAccepted={async (results: any) => {
        const tablesRef = collection(firestore, "accounts", uid, "tables");

        const batch = writeBatch(firestore);

        const data: DocumentData[] = [];
        results.data.map((value: string[]) => {
          const freeeTarget = value[2];
          const freeeCharacter = value[0];
          const yayoiTarget = value[3];
          const yayoiCharacter = value[4];

          const convertData =
            freeeCharacter.match("[区分行]") !== null
              ? freeeTarget === "現金・預金" &&
                freeeCharacter.match("現金") === null
                ? {
                    field: "cash_equivalent",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "売上債権" &&
                  freeeCharacter.match(/受取手形|不渡手形|売掛金/) === null
                ? {
                    field: "accounts_receivable",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "有価証券" &&
                  freeeCharacter.match(/有価証券/) === null
                ? {
                    field: "current_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "棚卸資産" &&
                  freeeCharacter.match(
                    /商品|製品|副産物作業くず|半製品|原材料|仕掛品|貯蔵品|未成工事支出金/
                  ) === null
                ? {
                    field: "shed_unloading_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "他流動資産" &&
                  freeeCharacter.match(
                    /前渡金|立替金|前払費用|繰延税金資産|未収収益|短期貸付金|未収還付法人税等|役員貸付金|未収入金|仮払金|預け金|仮払消費税等|貸倒引当金/
                  ) === null
                ? {
                    field: "other_current_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "有形固定資産" &&
                  freeeCharacter.match(
                    /建物|附属設備|構築物|機械装置|車両運搬具|工具器具備品|一括償却資産|減価償却累計額|土地|建設仮勘定/
                  ) === null
                ? {
                    field: "tangible_fixed_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "無形固定資産" &&
                  freeeCharacter.match(
                    /電話加入権|施設利用権|工業所有権|営業権|借地権|ソフトウェア/
                  ) === null
                ? {
                    field: "intangible_fixed_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "投資その他の資産" &&
                  freeeCharacter.match(
                    /投資有価証券|関係会社株式|出資金|関係会社出資金|敷金|差入保証金|長期貸付金|長期固定性預金|長期滞留債権|長期前払費用|前払年金費用|繰延税金資産|預託金|貸倒引当金(投)|保険積立金/
                  ) === null
                ? {
                    field: "investments_and_other_assets",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "繰延資産" &&
                  freeeCharacter.match(/創立費|権利金|開業費|開発費/) === null
                ? {
                    field: "deferred_asset",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "仕入債務" &&
                  freeeCharacter.match(/支払手形|買掛金|工事未払金/) === null
                ? {
                    field: "payables",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "他流動負債|仕入債務" &&
                  freeeCharacter.match(
                    /支払手形|買掛金|工事未払金|設備支払手形|短期借入金|役員借入金|未払金|未払費用|未払配当金|未払役員賞与|未払法人税等|未払事業税等|未払消費税等|繰延税金負債|前受金|預り金|前受収益|仮受金|預り保証金|割引手形|裏書手形|仮受消費税等|賞与引当金|未成工事受入金/
                  ) === null
                ? {
                    field: "current_liabilities",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "固定負債" &&
                  freeeCharacter.match(
                    /長期借入金|長期未払金|繰延税金負債|退職給付引当金/
                  ) === null
                ? {
                    field: "fixed_liabilities",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "資本金" &&
                  freeeCharacter.match(/資本金/) === null
                ? {
                    field: "capital",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "資本準備金|その他資本剰余金" &&
                  freeeCharacter.match(
                    /資本準備金|資本金及び準備金減少差益|自己株式処分差額/
                  ) === null
                ? {
                    field: "capital_surplus",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "利益準備金|繰越利益剰余金" &&
                  freeeCharacter.match(
                    /利益準備金|別途積立金|繰越利益剰余金/
                  ) === null
                ? {
                    field: "retained_earnings",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "自己株式" &&
                  freeeCharacter.match(/自己株式/) === null
                ? {
                    field: "treasury_stock",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "売上高" &&
                  freeeCharacter.match(/売上高/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "net_sales",
                    item: freeeCharacter,
                    section: null,
                  }
                : freeeTarget === "当期商品仕入|他勘定振替高(商)" &&
                  freeeCharacter.match(/仕入高/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "cost_of_sales",
                    item: freeeCharacter,
                    section: "変動",
                  }
                : freeeTarget === "販売管理費" &&
                  freeeCharacter.match(
                    /役員報酬|役員賞与|給料手当|雑給|賞与|退職金|法定福利費|福利厚生費|退職給付費用|採用教育費|研修費|法定福利費|福利厚生費|退職給付繰入額|採用教育費|外注費|広告宣伝費|交際費|会議費|旅費交通費|通信費|販売手数料|販売促進費|消耗品費|事務用品費|修繕費|水道光熱費|新聞図書費|諸会費|支払手数料|車両費|地代家賃|賃借料|リース料|保険料|租税公課|支払報酬料|寄付金|燃料費|研究開発費|減価償却費|長期前払費用償却|繰延資産償却|貸倒損失|貸倒繰入額|雑費|管理諸費/
                  ) === null
                ? {
                    field: "selling_and_administrative",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "資本剰余金|営業外収益" &&
                  freeeCharacter.match(
                    /受取利息|受取配当金|仕入割引|有価証券売却益|貸倒引当金戻入額|雑収入|為替差益/
                  ) === null
                ? {
                    field: "non_operating_income",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "営業外費用" &&
                  freeeCharacter.match(
                    /支払利息|割引料|手形売却損|貸倒損失|売上割引|有価証券売却損|繰延資産償却|貸倒引当金繰入額|雑損失|為替差損/
                  ) === null
                ? {
                    field: "non_operating_expenses",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "特別利益" &&
                  freeeCharacter.match(
                    /前期損益修正益|固定資産売却益|投資有価証券売却益|貸倒引当金戻入益/
                  ) === null
                ? {
                    field: "special_benefits",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "特別損失" &&
                  freeeCharacter.match(
                    /前期損益修正損|固定資産売却損|固定資産除却損|投資有価証券売却損/
                  ) === null
                ? {
                    field: "special_losses",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "当期原材料仕入高" &&
                  freeeCharacter.match(
                    /期首材料棚卸高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|期末材料棚卸高/
                  ) === null
                ? {
                    field: "material_fee",
                    item: freeeCharacter,
                    section: "変動",
                  }
                : freeeTarget === "労務費" &&
                  freeeCharacter.match(
                    /役員報酬|給料手当|雑給|賞与|退職金|法定福利費|福利厚生費|退職給付費用/
                  ) === null
                ? {
                    field: "labor_costs",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : freeeTarget === "製造経費" &&
                  freeeCharacter.match(
                    /減価償却費|外注加工費|動力費|荷造運賃|会議費|旅費交通費|通信費|消耗品費|事務用品費|消耗工具費|車両費|修繕費|水道光熱費|新聞図書費|諸会費|減価償却費|地代家賃|賃借料|租税公課|保険料|支払報酬料|寄付金|研究開発費|燃料費|保管料|特許使用料|棚卸減耗損|雑費/
                  ) === null
                ? {
                    field: "expenses",
                    item: freeeCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[現金･預金]" &&
                  yayoiCharacter.match(/現金/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "cash_equivalent",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[売上債権]" &&
                  yayoiCharacter.match(/受取手形|不渡手形|売掛金/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "accounts_receivable",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[有価証券]" &&
                  yayoiCharacter.match(/有価証券/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "current_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[棚卸資産]" &&
                  yayoiCharacter.match(
                    /商品|製品|副産物作業くず|半製品|原材料|仕掛品|貯蔵品|未成工事支出金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "shed_unloading_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[他流動資産]" &&
                  yayoiCharacter.match(
                    /前渡金|立替金|前払費用|繰延税金資産|未収収益|短期貸付金|未収還付法人税等|役員貸付金|未収入金|仮払金|預け金|仮払消費税等|貸倒引当金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "other_current_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[有形固定資産]" &&
                  yayoiCharacter.match(
                    /建物|附属設備|構築物|機械装置|車両運搬具|工具器具備品|一括償却資産|減価償却累計額|土地|建設仮勘定/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "tangible_fixed_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[無形固定資産]" &&
                  yayoiCharacter.match(
                    /電話加入権|施設利用権|工業所有権|営業権|借地権|ソフトウェア/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "intangible_fixed_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[投資その他の資産]" &&
                  yayoiCharacter.match(
                    /投資有価証券|関係会社株式|出資金|関係会社出資金|敷金|差入保証金|長期貸付金|長期固定性預金|長期滞留債権|長期前払費用|前払年金費用|繰延税金資産|預託金|貸倒引当金(投)|保険積立金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "investments_and_other_assets",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[繰延資産]" &&
                  yayoiCharacter.match(/創立費|権利金|開業費|開発費/) ===
                    null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "deferred_asset",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[仕入債務]" &&
                  yayoiCharacter.match(/支払手形|買掛金|工事未払金/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "payables",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[他流動負債]|[仕入債務]" &&
                  yayoiCharacter.match(
                    /支払手形|買掛金|工事未払金|設備支払手形|短期借入金|役員借入金|未払金|未払費用|未払配当金|未払役員賞与|未払法人税等|未払事業税等|未払消費税等|繰延税金負債|前受金|預り金|前受収益|仮受金|預り保証金|割引手形|裏書手形|仮受消費税等|賞与引当金|未成工事受入金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "current_liabilities",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[固定負債]" &&
                  yayoiCharacter.match(
                    /長期借入金|長期未払金|繰延税金負債|退職給付引当金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "fixed_liabilities",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[資本金]" &&
                  yayoiCharacter.match(/資本金/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "capital",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[資本剰余金]" &&
                  yayoiCharacter.match(
                    /その他資本剰余金|資本準備金|資本剰余金|資本金及び準備金減少差益|自己株式処分差額/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "capital_surplus",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[利益剰余金]" &&
                  yayoiCharacter.match(
                    /[利益準備金]|利益準備金|別途積立金|繰越利益剰余金/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "retained_earnings",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[自己株式]" &&
                  yayoiCharacter.match(/自己株式/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "treasury_stock",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[売上高]" &&
                  yayoiCharacter.match(/売上高/) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "net_sales",
                    item: yayoiCharacter,
                    section: null,
                  }
                : yayoiTarget === "[売上原価]" &&
                  yayoiCharacter.match(
                    /仕入高|[当期商品仕入]|[期首商品棚卸]|[期末商品棚卸]/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "cost_of_sales",
                    item: yayoiCharacter,
                    section: "変動",
                  }
                : yayoiTarget === "[販売管理費]" &&
                  yayoiCharacter.match(
                    /役員報酬|役員賞与|給料手当|雑給|賞与|退職金|法定福利費|福利厚生費|退職給付費用|採用教育費|研修費|法定福利費|福利厚生費|退職給付繰入額|採用教育費|外注費|広告宣伝費|交際費|会議費|旅費交通費|通信費|販売手数料|販売促進費|消耗品費|事務用品費|修繕費|水道光熱費|新聞図書費|諸会費|支払手数料|車両費|地代家賃|賃借料|リース料|保険料|租税公課|支払報酬料|寄付金|燃料費|研究開発費|減価償却費|長期前払費用償却|繰延資産償却|貸倒損失|貸倒繰入額|雑費|管理諸費/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "selling_and_administrative",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[営業外収益]" &&
                  yayoiCharacter.match(
                    /受取利息|受取配当金|仕入割引|有価証券売却益|貸倒引当金戻入額|雑収入|為替差益/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "non_operating_income",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[営業外費用]" &&
                  yayoiCharacter.match(
                    /支払利息|割引料|手形売却損|貸倒損失|売上割引|有価証券売却損|繰延資産償却|貸倒引当金繰入額|雑損失|為替差損/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "non_operating_expenses",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[特別利益]" &&
                  yayoiCharacter.match(
                    /前期損益修正益|固定資産売却益|投資有価証券売却益|貸倒引当金戻入益/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "special_benefits",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[特別損失]" &&
                  yayoiCharacter.match(
                    /前期損益修正損|固定資産売却損|固定資産除却損|投資有価証券売却損/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "special_losses",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : yayoiTarget === "[材料費]" &&
                  yayoiCharacter.match(
                    /[期首原材料棚卸]|[期末原材料棚卸]|期首材料棚卸高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|材料仕入高|期末材料棚卸高/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "material_fee",
                    item: yayoiCharacter,
                    section: "変動",
                  }
                : yayoiTarget === "[労務費]" &&
                  yayoiCharacter.match(
                    /役員報酬|給料手当|雑給|賞与|退職金|法定福利費|福利厚生費|退職給付費用/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "labor_costs",
                    item: yayoiCharacter,
                    section: "変動",
                  }
                : yayoiTarget === "[製造経費]" &&
                  yayoiCharacter.match(
                    /減価償却費|外注加工費|動力費|荷造運賃|会議費|旅費交通費|通信費|消耗品費|事務用品費|消耗工具費|車両費|修繕費|水道光熱費|新聞図書費|諸会費|減価償却費|地代家賃|賃借料|租税公課|保険料|支払報酬料|寄付金|研究開発費|燃料費|保管料|特許使用料|棚卸減耗損|雑費/
                  ) === null &&
                  yayoiCharacter.length !== 0
                ? {
                    field: "expenses",
                    item: yayoiCharacter,
                    section: "固定",
                  }
                : null
              : null;

          const newId = doc(tablesRef).id;

          const newDoc = doc(firestore, "accounts", uid, "tables", newId);

          convertData !== null &&
            batch.set(newDoc, {
              field: convertData.field,
              item: convertData.item,
              section: convertData.section,
              results_input: {
                [resultsAndPlansDataState.headers.results_input[0].period]:
                  null,
                [resultsAndPlansDataState.headers.results_input[1].period]:
                  null,
                [resultsAndPlansDataState.headers.results_input[2].period]:
                  null,
              },
              plans_input: {
                [resultsAndPlansDataState.headers.plans_input[0].period]: null,
                [resultsAndPlansDataState.headers.plans_input[1].period]: null,
                [resultsAndPlansDataState.headers.plans_input[2].period]: null,
                [resultsAndPlansDataState.headers.plans_input[3].period]: null,
                [resultsAndPlansDataState.headers.plans_input[4].period]: null,
              },
              plans_input_monthly: {
                [resultsAndPlansDataState.headers.plans_input_monthly[0]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[1]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[2]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[3]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[4]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[5]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[6]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[7]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[8]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[9]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[10]
                  .period]: null,
                [resultsAndPlansDataState.headers.plans_input_monthly[11]
                  .period]: null,
              },
              results_of_the_previous_period: {
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[0].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[1].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[2].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[3].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[4].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[5].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[6].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[7].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[8].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[9].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[10].period]: null,
                [resultsAndPlansDataState.headers
                  .results_of_the_previous_period[11].period]: null,
              },
              current_and_previous_year_comparisons: { fixed: null },
              order: data.length + 100,
              descriptions: {},
              isDefault: false,
              createdAt: new Date(),
            });
        });

        setIsLoading(true);

        await batch
          .commit()
          .then(() => {
            setIsLoading(false);
            toast.success("設定が完了しました");
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error("設定できませんでした");
            throw new Error(error.message);
          });
      }}
    >
      {({ getRootProps }: any) => (
        <button
          type="button"
          {...getRootProps()}
          className="px-16px py-8px bg-c427ed1 text-white rounded"
        >
          標準科目のインポート
        </button>
      )}
    </CSVReader>
  );
};
