import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const ProfitAndLossPlanTableBlank = ({
  headers,
  bottomLine,
}: {
  headers: DocumentData[];
  bottomLine?: boolean;
}) => {
  const grid: GridElement[][] = [];

  // 行の値
  const gridRow = [
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  left-0 p-4px bg-white ${
        bottomLine && "before:border-b"
      }  z-[31]`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} text-r left-160px p-4px bg-white ${
        bottomLine && "before:border-b"
      } z-[31]`,
      colSpan: 1,
    },
  ];
  headers?.map(() => {
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  });

  gridRow.push({
    value: "",
    readOnly: true,
    className: `${beforeWrapper} ${bottomLine && "before:border-b"} h-30px`,
    colSpan: 1,
  });

  grid.push(gridRow);

  return grid;
};
