import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { ResultsInputFieldsCalc } from "../../../types";
import {
  BsResultsAndPlansTable,
  CostResultsAndPlansTable,
  PlResultsAndPlansTable,
  VariationTable,
  WindowDressingTable,
} from "../resultsAndPlans";

interface Props {
  url: string;
  calcState: ResultsInputFieldsCalc;
}

export const PlanMakingContainer: React.FC<Props> = ({ url, calcState }) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlResultsAndPlansTable calcState={calcState} url={url} />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostResultsAndPlansTable calcState={calcState} url={url} />

      {/* BS  */}
      <div className="mt-50px" id="bs"></div>
      <BsResultsAndPlansTable calcState={calcState} url={url} />

      {/* Variation  */}
      <div className="mt-50px" id='variation'></div>
      <VariationTable calcState={calcState} url={url} />

      {/* Variation  */}
      <div className="mt-50px" id='wd'></div>
      <WindowDressingTable calcState={calcState} url={url} />
    </InputLayout>
  );
};
