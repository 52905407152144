import * as React from "react";
import { ResultsOfTheCurrentTermFIeldsCalc } from "../../../types/resultsOfTheCurrentTermFIeldsCalc";
import { InputLayout } from "../resultsInput";
import {
  CostResultsOfTheCurrentTermTable,
  PlResultsOfTheCurrentTermTable,
} from "../resultsOfTheCurrentTerm";

interface Props {
  url: string;
  dataUrl: string;
  calcState: ResultsOfTheCurrentTermFIeldsCalc;
}

export const ResultsOfTheCurrentTermContainer: React.FC<Props> = ({
  url,
  dataUrl,
  calcState,
}) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlResultsOfTheCurrentTermTable
        calcState={calcState}
        dataUrl={dataUrl}
        url={url}
      />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostResultsOfTheCurrentTermTable
        calcState={calcState}
        dataUrl={dataUrl}
        url={url}
      />
    </InputLayout>
  );
};
