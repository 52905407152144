import { dotDivide, map, round } from "mathjs";

export const arrayRatioCalc = ({
  numerator,
  denominator,
}: {
  numerator: number[];
  denominator: number[];
}) => {
  const divineValue = dotDivide(numerator, denominator);

  const newArray = map(divineValue as number[], function (value) {
    if (isNaN(value) === true || !isFinite(value)) {
      value = 0;
    }
    return round(value * 1000) / 10;
  });
  return newArray;
};
