import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  BsCurrentResultMonthlyBody,
  BsCurrentResultMonthlyTotal,
  CostCurrentResultMonthlyRatioComponent,
  CurrentResultMonthlyHeader,
  CurrentResultMonthlyTableBlank,
} from "../../../lib/datasheets/currentResultMonthly";
import { CostCurrentResultMonthlyRatioElement } from "../../../lib/datasheets/currentResultMonthly/CostCurrentResultMonthlyRatioElement";
import { CurrentResultMonthlyFieldsCalc } from "../../../types/currentResultMonthlyFieldsCalc";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  dataUrl: string;
  calcState: CurrentResultMonthlyFieldsCalc;
}

const CostCurrentResultMonthlyTable: React.FC<Props> = ({
  url,
  dataUrl,
  calcState,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = BsCurrentResultMonthlyTotal({
    titleTotal: "材料費",
    bottomLine: false,
    headers: resultsAndPlansDataState.headers[url],
    total: calcState.material_fee_total,
    totalField: `${dataUrl}_material_fee_total`,
    unit: unit,
    descriptions: resultsAndPlansDataState.descriptions,
  });
  grid.push(
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.material_fee,
      totalByItem: calcState.material_fee_total_by_item,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_material_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyTotal({
      titleTotal: "労務費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.labor_costs_total,
      totalField: `${dataUrl}_labor_costs_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.labor_costs,
      totalByItem: calcState.labor_costs_total_by_item,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_labor_costs_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyTotal({
      titleTotal: "外注加工費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.external_injection_processing_fee_total,
      totalField: `${dataUrl}_external_injection_processing_fee_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      totalByItem: calcState.external_injection_processing_fee_total_by_item,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_external_injection_processing_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyTotal({
      titleTotal: "経費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.expenses_total,
      totalField: `${dataUrl}_expenses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.expenses,
      totalByItem: calcState.expenses_total_by_item,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_expenses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyTotal({
      titleTotal: "当期製造費用",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_manufacturing_cost_for_the_period,
      totalField: `${dataUrl}_gross_manufacturing_cost_for_the_period`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      totalByItem:
        calcState.inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item,
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_inventory_of_work_in_process_at_the_beginning_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      totalByItem:
        calcState.inventory_of_work_in_process_at_the_end_of_the_period_total_by_item,
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_inventory_of_work_in_process_at_the_end_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...BsCurrentResultMonthlyTotal({
      titleTotal: "当期製品製造原価費用",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cost_of_products_manufactured,
      totalField: `${dataUrl}_cost_of_products_manufactured`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CurrentResultMonthlyTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...CostCurrentResultMonthlyRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          value: calcState.gross_manufacturing_cost_for_the_period_ratio,
          field: `${dataUrl}_gross_manufacturing_cost_for_the_period_ratio`,
          grandTotalRatio:
            calcState.gross_manufacturing_cost_for_the_period_total_ratio,
        },
        {
          title: "材料費",
          value: calcState.material_fee_total_ratio,
          field: `${dataUrl}_material_fee_total_ratio`,
          grandTotalRatio: calcState.material_fee_total_total_ratio,
        },
        {
          title: "労務費",
          value: calcState.labor_costs_total_ratio,
          field: `${dataUrl}_labor_costs_total_ratio`,
          grandTotalRatio: calcState.labor_costs_total_total_ratio,
        },
        {
          title: "外注加工費",
          value: calcState.external_injection_processing_fee_total_ratio,
          field: `${dataUrl}_external_injection_processing_fee_total_ratio`,
          grandTotalRatio:
            calcState.external_injection_processing_fee_total_total_ratio,
        },
        {
          title: "経費",
          value: calcState.expenses_total_ratio,
          field: `${dataUrl}_expenses_total_ratio`,
          grandTotalRatio: calcState.expenses_total_total_ratio,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CurrentResultMonthlyTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...CostCurrentResultMonthlyRatioElement({
      headerTitle: "要素別原価率",
      rows: [
        {
          title: "材料費",
          value: calcState.material_fee_total,
          field: `${dataUrl}_net_sales_material_fee_total_element`,
        },
        {
          title: "(売上高材料費比率)",
          value: calcState.net_sales_material_fee_total_ratio,
          field: `${dataUrl}_net_sales_material_fee_total_ratio`,
          grandTotalRatio: calcState.net_sales_material_fee_yearly_total_ratio,
        },
        {
          title: "労務費",
          value: calcState.labor_costs_total,
          field: `${dataUrl}_net_sales_labor_costs_total_element`,
        },
        {
          title: "(売上高労務費比率)",
          value: calcState.net_sales_labor_costs_total_ratio,
          field: `${dataUrl}_net_sales_labor_costs_total_ratio`,
          grandTotalRatio:
            calcState.net_sales_labor_costs_total_yearly_total_ratio,
        },
        {
          title: "外注加工費",
          value: calcState.external_injection_processing_fee_total,
          field: `${dataUrl}_net_sales_external_injection_processing_fee_total_element`,
        },
        {
          title: "(売上高外注費比率)",
          value:
            calcState.net_sales_external_injection_processing_fee_total_ratio,
          field: `${dataUrl}_net_sales_external_injection_processing_fee_total_ratio`,
          grandTotalRatio:
            calcState.net_sales_external_injection_processing_fee_total_yearly_total_ratio,
        },
        {
          title: "経費",
          value: calcState.expenses_total,
          field: `${dataUrl}_net_sales_expenses_total_element`,
          grandTotalRatio: calcState.expenses_total_total_ratio,
        },
        {
          title: "(売上高経費比率)",
          value: calcState.net_sales_expenses_total_ratio,
          field: `${dataUrl}_net_sales_expenses_total_ratio`,
          grandTotalRatio:
            calcState.net_sales_expenses_total_yearly_total_ratio,
        },
        {
          title: "減価償却費",
          value: calcState.depreciation_cost_of_sales_t,
          field: `${dataUrl}_depreciation_cost_of_sales_element`,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: dataUrl,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <CurrentResultMonthlyHeader
            headers={resultsAndPlansDataState.headers[url]}
            resultsCount={
              resultsAndPlansDataState.headers.results_of_the_current_term_count
            }
            title="■原価"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostCurrentResultMonthlyTable);
