import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { ResultsOfThePreviousPeriodFIeldsCalc } from "../../../types";
import {
  CostResultsOfThePreviousPeriodTable,
  PlResultsOfThePreviousPeriodTable,
} from "../resultsOfThePreviousPeriod";

interface Props {
  url: string;
  calcState: ResultsOfThePreviousPeriodFIeldsCalc;
}

export const ResultsOfThePreviousPeriodContainer: React.FC<Props> = ({
  url,
  calcState,
}) => {
  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlResultsOfThePreviousPeriodTable calcState={calcState} url={url} />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostResultsOfThePreviousPeriodTable calcState={calcState} url={url} />
    </InputLayout>
  );
};
