import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CostCurrentResultMonthlyRatioElement = ({
  headerTitle,
  title,
  headers,
  rows,
  unit,
  descriptions,
}: {
  headerTitle?: string;
  title?: string;
  rows: {
    title: string;
    value: number[];
    field: string;
    grandTotalRatio?: number;
  }[];
  headers: DocumentData[];
  unit: number;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 2 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  rows.map((row, index) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-x ${
      title && "before:border-l-c3333334d"
    } before:border-r-c3333334d ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } ${index % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol2 = `${borderSettings} ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } text-right `;

    const classCol3 = `relative p-4px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
      rows.length !== index + 1 && " before:border-b-c3333334d"
    } text-right   ${
      index % 2 !== 0
        ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
        : "before:bg-cfffee2 before:border-x"
    }`;

    const classCol4 = `${borderSettings}  before:border-x before:border-b before:border-l-c3333334d ${
      rows.length !== index + 1 && "before:border-b-c3333334d"
    } text-left ${index % 2 !== 0 && "bg-c2a4b770d"} h-50px description`;

    // 1行目の値
    const gridRow: GridElement[] = [];
    gridRow.push({
      value: row.title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      index % 2 === 0
        ? gridRow.push({
            value: ShowTableDataTriangle(row.value[tdIndex] / unit),
            expr: row.value[tdIndex] / unit,
            readOnly: true,
            className: `${classCol2}  ${
              !header.isTotal && index % 2 !== 0 && "bg-c2a4b770d"
            } ${header.isTotal && "bg-cfffee2"}`,
          })
        : gridRow.push({
            value: `${row.value[tdIndex]}%`,
            expr: row.value[tdIndex],
            readOnly: true,
            className: `${classCol2}  ${
              !header.isTotal && index % 2 !== 0 && "bg-c2a4b770d"
            } ${header.isTotal && "bg-cfffee2"}`,
          });
    });

    index % 2 === 0
      ? gridRow.push({
          value: ShowTableDataTriangle((sum(row.value) as number) / unit),
          expr: (sum(row.value) as number) / unit,
          readOnly: true,
          className: `${classCol3}`,
        })
      : gridRow.push({
          value: `${showPercentage(
            row.grandTotalRatio ? row.grandTotalRatio : 0
          )}%`,
          expr: showPercentage(row.grandTotalRatio ? row.grandTotalRatio : 0),
          readOnly: true,
          className: `${classCol3}`,
        });

    gridRow.push({
      value: descriptions[row.field],
      expr: descriptions[row.field],
      readOnly: false,
      className: `${classCol4}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow);
  });

  return grid;
};
