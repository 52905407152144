import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  PlResultsOfTheCurrentTermBody,
  PlResultsOfTheCurrentTermGrandTotal,
  PlResultsOfTheCurrentTermNetSalesCostRatio,
  PlResultsOfTheCurrentTermSimpleCf,
  PlResultsOfTheCurrentTermTotal,
  PlResultsOfTheCurrentTermWithTax,
  ResultsOfTheCurrentTermHeader,
  ResultsOfTheCurrentTermTableBlank,
} from "../../../lib/datasheets/resultsOfTheCurrentTerm";
import { PlResultsOfTheCurrentTermRatio } from "../../../lib/datasheets/resultsOfTheCurrentTerm/PlResultsOfTheCurrentTermRatio";
import { GridElement } from "../../../types/gridElement";
import { ResultsOfTheCurrentTermFIeldsCalc } from "../../../types/resultsOfTheCurrentTermFIeldsCalc";

interface Props {
  url: string;
  dataUrl: string;
  calcState: ResultsOfTheCurrentTermFIeldsCalc;
}

const PlResultsOfTheCurrentTermTable: React.FC<Props> = ({
  url,
  dataUrl,
  calcState,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlResultsOfTheCurrentTermBody({
    title: "売上高",
    headers: resultsAndPlansDataState.headers[url],
    rows: resultsAndPlansDataState.net_sales,
    totalByItem: calcState.net_sales_total_by_item,
    url: url,
    dataUrl: dataUrl,
    unit: unit,
  });
  grid.push(
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_sales_total,
      ratio: calcState.net_sales_ratio,
      totalField: `${url}_net_sales_total`,
      ratioField: `${url}_net_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  grid.push(
    ...PlResultsOfTheCurrentTermBody({
      title: "売上原価",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.cost_of_sales,
      totalByItem: calcState.cost_of_sales_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    })
  );
  grid.push(
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cost_of_sales_total,
      ratio: calcState.cost_of_sales_ratio,
      totalField: `${url}_cost_of_sales_total`,
      ratioField: `${url}_cost_of_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );
  grid.push(
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_margin,
      ratio: calcState.gross_margin_ratio,
      totalField: `${url}_gross_margin`,
      ratioField: `${url}_gross_margin_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "販売費及び一般管理費",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.personal_cost,
      totalByItem: calcState.personal_cost_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上高人件費比率",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.personal_cost_total,
      ratio: calcState.personal_cost_ratio,
      totalField: `${url}_personal_cost_total`,
      ratioField: `${url}_personal_cost_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.selling_and_administrative,
      totalByItem: calcState.selling_and_administrative_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.selling_and_administrative_total,
      ratio: calcState.selling_and_administrative_ratio,
      totalField: `${url}_selling_and_administrative_total`,
      ratioField: `${url}_selling_and_administrative_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_income,
      ratio: calcState.operating_income_ratio,
      totalField: `${url}_operating_income`,
      ratioField: `${url}_operating_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_profits_before_amortization,
      ratio: calcState.operating_profits_before_amortization_ratio,
      totalField: `${url}_operating_profits_before_amortization`,
      ratioField: `${url}_operating_profits_before_amortization_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "営業外収益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_income,
      totalByItem: calcState.non_operating_income_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_income_total,
      totalField: `${url}_non_operating_income_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "営業外費用",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_expenses,
      totalByItem: calcState.non_operating_expenses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_expenses_total,
      totalField: `${url}_non_operating_expenses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.current_earnings,
      ratio: calcState.current_earnings_ratio,
      totalField: `${url}_current_earnings`,
      ratioField: `${url}_current_earnings_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebitda,
      ratio: calcState.ebitda_ratio,
      totalField: `${url}_ebitda`,
      ratioField: `${url}_ebitda_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "特別利益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_benefits,
      totalByItem: calcState.special_benefits_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_benefits_total,
      totalField: `${url}_special_benefits_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermBody({
      title: "特別損失",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_losses,
      totalByItem: calcState.special_losses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_losses_total,
      totalField: `${url}_special_losses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebit,
      ratio: calcState.ebit_ratio,
      totalField: `${url}_ebit`,
      ratioField: `${url}_ebit_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermWithTax({
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      totalByItem:
        calcState.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${url}_special_losses_body`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlResultsOfTheCurrentTermGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_income,
      ratio: calcState.net_income_ratio,
      totalField: `${url}_net_income`,
      ratioField: `${url}_net_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...ResultsOfTheCurrentTermTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlResultsOfTheCurrentTermSimpleCf({
      headers: resultsAndPlansDataState.headers[url],
      row1: calcState.simple_cf_row1,
      row2: calcState.simple_cf_row2,
      row3: calcState.simple_cf_row3,
      field1: `${url}_simple_cf_row1`,
      field2: `${url}_simple_cf_row2`,
      field3: `${url}_simple_cf_row3`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  grid.push(
    ...ResultsOfTheCurrentTermTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlResultsOfTheCurrentTermNetSalesCostRatio({
      headerTitle: "月別売上比率",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      field: url,
      byItemRatio: calcState.net_sales_total_by_item_ratio.netSalesByItemRatio,
      monthlyTotalRatio: calcState.net_sales_ratio,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // grid.push(
  //   ...ResultsOfTheCurrentTermTableBlank({
  //     headers: resultsAndPlansDataState.headers[url],
  //   }),
  //   ...PlResultsOfTheCurrentTermCostRatioForEstimate({
  //     headerTitle: "月別売上比率",
  //     title: "売上高",
  //     rows: resultsAndPlansDataState.net_sales,
  //     headers: resultsAndPlansDataState.headers[url],
  //     byItemTotal: calcState.net_sales_total_by_item,
  //     monthlyTotal: calcState.net_sales_total,
  //     field: url,
  //     totalField: `${url}_net_sales_monthly_total_ratio`,
  //     descriptions: resultsAndPlansDataState.descriptions,
  //   })
  // );

  grid.push(
    ...ResultsOfTheCurrentTermTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlResultsOfTheCurrentTermRatio({
      headerTitle: "売上構成比",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      field: url,
      calcState: calcState.net_sales_total_by_item_ratio,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: url,
      dataUrl: dataUrl,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <ResultsOfTheCurrentTermHeader
            headers={resultsAndPlansDataState.headers[url]}
            resultsCount={
              resultsAndPlansDataState.headers.results_of_the_current_term_count
            }
            title="■PL"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlResultsOfTheCurrentTermTable);
