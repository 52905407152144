import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { ResultsOfThePreviousPeriodContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";
import { useResultsOfThePreviousPeriodInitialData } from "../../hooks/initialData";

export const ResultsOfThePreviousPeriod = () => {
  const resultsOfThePreviousPeriodCalcState =
    useResultsOfThePreviousPeriodInitialData();

  const url = pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD;

  if (!resultsOfThePreviousPeriodCalcState) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <SubHeader title="前期実績（月次）" />
      <UnitChange />
      <ResultsOfThePreviousPeriodContainer
        calcState={resultsOfThePreviousPeriodCalcState}
        url={url}
      />
    </React.Fragment>
  );
};
