import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const PlansInputForVarification = ({
  headers,
  total,
  unit,
}: {
  headers: DocumentData[];
  total: number[];
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px bg-white  before:border-l-0 before:border-b z-[32]`;

  const classCol2 = `relative p-4px  bg-white before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:border-b text-right`;

  const classCol3 = `relative p-4px  bg-white before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:border-b before:border-r-0`;

  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} left-0`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol1} left-30px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol1} left-80px`,
    },
    {
      value: (
        <div className="absolute top-6px right-6px whitespace-nowrap">
          ※純資産（検証用）
        </div>
      ),
      readOnly: true,
      className: `${classCol1} left-220px z-[32]`,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow.push({
      value:
        total &&
        String(total[tdIndex] / unit).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          "$1,"
        ),
      readOnly: true,
      className: classCol2,
    });
  });

  gridRow.push({
    value: "",
    readOnly: true,
    className: `${classCol3}`,
    field: "",
    key: "",
  });
  grid.push(gridRow);

  return grid;
};
