import React, { memo, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LpFooter, LpHeader } from "../components/header";
import { BillingPlan } from "../components/molecules";
import { pageConstant } from "../constants/pageConstants";

const Home = () => {
  const search = useLocation().search;

  useEffect(() => {
    const query = new URLSearchParams(search);
    const name = query.get("errMessage");
    name !== null && toast.error("一度使用したリンクなので無効です");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LpHeader />
      <section className="w-full overflow-x-hidden h-[52vw]">
        <div className="w-full before:content-[''] before:absolute bg-hero before:bottom-[60px] before:left-[-40vw] before:w-[150vw] before:h-[150vw] before:rounded-full before:-z-10 before:overflow-hidden ">
          <div className="flex py-20px gap-12px h-full">
            <div className="w-2/5 pl-40px pt-60px text-center">
              <div className="inline-block text-20px font-bold text-c173d96 space-x-0 text-center border-b-[8px] border-b-c80b0f466 ">
                経営コンサルタント向け
              </div>
              <h1 className="text-48px font-bold text-c173d96 text-center my-8px">
                無料から使える
                <br />
                経営計画策定ソフト
              </h1>
              <div className="flex justify-around">
                <div className="w-140px h-140px text-15px text-center text-c427ed1 bg-white rounded-full flex items-center justify-center">
                  補助金申請に
                  <br />
                  おける経営計画の
                  <br />
                  作成
                </div>
                <div className="w-140px h-140px text-15px text-center text-c427ed1 bg-white rounded-full flex items-center justify-center">
                  事業承継に
                  <br />
                  おける事業計画や
                  <br />
                  経営改善計画の
                  <br />
                  作成
                </div>
                <div className="w-140px h-140px text-15px text-center text-c427ed1 bg-white rounded-full flex items-center justify-center">
                  企業診断における
                  <br />
                  財務分析
                </div>
              </div>
              <Link to={`/${pageConstant.SIGNUP}`}>
                <button className="relative bg-cffd800 w-280px h-60px m-20px rounded z-20 shadow-cc7b421 after:content-['>'] after:float-right shadow-md hover:opacity-80 font-bold pr-20px">
                  無料ではじめる
                </button>
              </Link>
              <br />
              <Link to={``} className="inline-block text-12px text-c427ed1 mt-20px font-bold">
                令和元年度補正ものづくり補助金により作成
              </Link>
            </div>
            <div className="w-3/5">
              <img
                alt=""
                className="max-w-[120%]"
                src={`${window.location.origin}/img/mv.png`}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-120px" id="about">
        <div className="max-w-[1366px] mx-100px 2xl:mx-auto">
          <div className="w-full mt-36px">
            <div className="flex items-center bg-c173d96">
              <img
                alt=""
                className="max-w-[50%]"
                src={`${window.location.origin}/img/stock-photo-outlines-of-two-businessmen-working-late-in-office-228478342.jpg`}
              />
              <div className="flex-1">
                <p className="mt-20px text-white text-28px text-center">
                  <span className="text-c173d96 bg-white text-18px rounded relative p-3px">日本初</span> 経営コンサルタント向け<br />
                  クラウド型経営計画作成ソフト<br />
                  「CloudPlanner」
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-120px" id="achieved">
        <div className="max-w-[1366px] mx-100px 2xl:mx-auto">
          <h2 className="text-34px text-c173d96 font-bold text-center">
            実現できること
          </h2>
          <div className="w-full flex justify-center gap-10px pt-12px mt-36px text-center">

            <div className="w-[400px] bg-ce6effd h-[560px] text-center pt-20px">
              <span className="text-c427ed1 text-20px font-bold">01</span>
                <h3 className="text-28px font-bold h-[170px] mt-10px mb-30px flex items-center justify-center">
                補助金申請における<br />
                経営計画の作成
              </h3>
              <div className="text-center flex flex-col justify-center items-center">
                <img
                  alt=""
                  className="max-w-[60%]"
                  src={`${window.location.origin}/img/jitsugen_01.png`}
                />
              </div>
            </div>

            <div className="w-[400px] bg-ce6effd h-[560px] text-center pt-20px">
              <span className="text-c427ed1 text-20px font-bold">02</span>
                <h3 className="text-28px font-bold h-[170px] mt-10px mb-30px flex items-center justify-center">
                  事業承継における<br />
                  事業計画や<br />
                  経営改善計画の作成
                </h3>
              <div className="text-center flex flex-col justify-center items-center">
                <img
                  alt=""
                  className="max-w-[60%]"
                  src={`${window.location.origin}/img/jitsugen_02.png`}
                />
              </div>
            </div>

            <div className="w-[400px] bg-ce6effd h-[560px] text-center pt-20px">
              <span className="text-c427ed1 text-20px font-bold">03</span>
              <h3 className="text-28px font-bold h-[170px] mt-10px mb-30px flex items-center justify-center">
                企業診断における<br />
                財務分析
              </h3>
              <div className="text-center flex flex-col justify-center items-center">
                <img
                  alt=""
                  className="max-w-[60%]"
                  src={`${window.location.origin}/img/jitsugen_03.png`}
                />
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="mb-80px bg-cf4f4f4 py-60px" id="function">
        <div className="max-w-[1366px] mx-100px 2xl:mx-auto">
          <h2 className="text-34px text-c173d96 font-bold text-center mb-50px">機能</h2>

          <div className="w-full my-20px h-320px bg-white rounded p-20px">
            <div className="flex">
              <div className="flex-1 ml-60px mt-40px">
                <h3 className="text-c427ed1 text-28px font-bold">
                  自動で主要財務指標を算出
                </h3>
                <p className="mt-20px max-w-[400px]">
                  決算書の情報をインプットすれば、自動で主要な財務指標を算出することができます。
                  Freee、弥生会計からは、決算情報のインポートに対応していますので、転記作業が軽減されます。
                </p>
              </div>
              <img
                alt=""
                className="max-h-[280px] mr-40px"
                src={`${window.location.origin}/img/kinou_01.png`}
              />
            </div>
          </div>

          <div className="w-full my-20px h-320px bg-white rounded p-20px">
            <div className="flex">
              <div className="flex-1 ml-60px mt-40px">
                <h3 className="text-c427ed1 text-28px font-bold">
                  月次ベースでの売上計画の作成から<br />
                  予実管理までワンストップで実現
                </h3>
                <p className="mt-20px max-w-[400px]">
                  表計算ソフトで行なっていたような、売上計画の作成と予実管理がクラウドアプリで簡単に作成できます。
                  経営シミュレーション機能により、クライアントに説得力があるデータを瞬時に提示できます。
                </p>
              </div>
              <img
                alt=""
                className="max-h-[280px] mr-40px"
                src={`${window.location.origin}/img/kinou_02.png`}
              />
            </div>
          </div>

          <div className="w-full my-20px h-320px bg-white rounded p-20px">
            <div className="flex">
              <div className="flex-1 ml-60px mt-40px">
                <h3 className="text-c427ed1 text-28px font-bold">
                  ダッシュボードで<br />
                  分析結果を一覧表示
                </h3>
                <p className="mt-20px max-w-[400px]">
                  各種財務分析結果をグラフで表示でき、様々な切り口での分析を視覚的に可能にします。
                </p>
              </div>
              <img
                alt=""
                className="max-h-[280px] mr-40px"
                src={`${window.location.origin}/img/kinou_03.png`}
              />
            </div>
          </div>

        </div>
      </section>

      <section className="mb-120px" id="plan">
        <div className="max-w-[1366px] mx-100px 2xl:mx-auto">
          <h2 className="text-34px text-c173d96 font-bold text-center mb-60px">
            料金プラン
          </h2>
          <BillingPlan isPayment={false} />
        </div>
      </section>

      <section className="bg-cf2f7fe py-60px" id="case_studies">
        <div className="max-w-[720px] mb-100px mx-auto">
          <h2 className="text-34px text-c173d96 font-bold text-center mb-50px">導入事例</h2>
          <p className="text-center mb-50px">クラウドプランナーご利用いただいているコンサルタントのみなさまからの、<br />
          ご意見やご感想を紹介します。</p>

          <div className="w-full my-20px h-130px">
            <div className="flex justify-center items-center">
              <img
                alt=""
                className="w-100px h-100px mr-40px"
                src={`${window.location.origin}/img/jirei_01.png`}
              />
              <div className="relative bg-white rounded h-130px p-25px w-[580px]">
                <div className="absolute w-0 h-0 
                  border-t-[14px] border-t-transparent
                  border-r-[24px] border-r-blue-500
                  border-b-[14px] border-b-transparent
                  border-white left-[-20px] top-[25px]">
                </div>

                <h3 className="text-c427ed1 text-18px font-bold">
                  クラウドベースなのでデータの保護が安心
                </h3>
                <p className="text-14px mt-20px">
                  デバイスの盗難や紛失でもデータを失うことがなく、複数デバイスからアクセスもでき、とっても便利
                </p>

              </div>
            </div>
          </div>

          <div className="w-full my-20px h-130px">
            <div className="flex justify-center items-center">
              <img
                alt=""
                className="w-100px h-100px mr-40px"
                src={`${window.location.origin}/img/jirei_02.png`}
              />
              <div className="relative bg-white rounded h-130px p-25px w-[580px]">
                <div className="absolute w-0 h-0 
                  border-t-[14px] border-t-transparent
                  border-r-[24px] border-r-blue-500
                  border-b-[14px] border-b-transparent
                  border-white left-[-20px] top-[25px]">
                </div>

                <h3 className="text-c427ed1 text-18px font-bold">
                  お求めやすい料金体系でニーズに合わて気軽に導入可能
                </h3>
                <p className="text-14px mt-20px">
                  無料のエントリーから始めて、ニーズに合わせてグレードアップできるので使い勝手が良い
                </p>

              </div>
            </div>
          </div>

          <div className="w-full my-20px h-130px">
            <div className="flex justify-center items-center">
              <img
                alt=""
                className="w-100px h-100px mr-40px"
                src={`${window.location.origin}/img/jirei_03.png`}
              />
              <div className="relative bg-white rounded h-130px p-25px w-[580px]">
                <div className="absolute w-0 h-0 
                  border-t-[14px] border-t-transparent
                  border-r-[24px] border-r-blue-500
                  border-b-[14px] border-b-transparent
                  border-white left-[-20px] top-[25px]">
                </div>

                <h3 className="text-c427ed1 text-18px font-bold">
                  中小企業診断士による充実のサポート体制
                </h3>
                <p className="text-14px mt-20px">
                  専門的な質問に対しても的確なアドバイスを得られるので安心
                </p>

              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-cdedce1 pt-90px pb-90px bg-[url('../public/img/istockphoto-1282458414-1024x1024.jpg')] bg-right-top bg-no-repeat " id="banner">
        <div className="max-w-[1366px] mx-100px 2xl:mx-auto">
          <h2 className="text-34px text-white font-bold text-center leading-10">
            導入について不明な点はお気軽にお問合せください。
          </h2>
          <div className="w-full flex space-x-30px justify-center pt-60px">
            {/*<button className="w-300px h-60px bg-white border border-c427ed1 text-c427ed1 font-bold rounded">
              Cloud Plannerでできること
            </button>*/}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdLMXKyqzkXDzZxGAc7nsiwuIfMe7S3IXU1LpIHh0aBMkcIPw/viewform" target="_blink">
              <button className="w-300px h-60px bg-white border border-c427ed1 text-c427ed1 font-bold rounded">
                お問い合わせ
              </button>
            </a>
            {/*<button className="w-300px h-60px bg-white border border-c427ed1 text-c427ed1 font-bold">
              お問い合わせ
            </button>*/}
          </div>
        </div>
      </section>
      <LpFooter />
    </React.Fragment>
  );
};

export default memo(Home);
