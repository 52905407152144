import { abs } from "mathjs";
import React from "react";

export const ShowTableData = (value: number) => {
  return (
    <React.Fragment>
      {isNaN(value) ? (
        0
      ) : value < 0 ? (
        <span className="text-red">
          (
          {String(abs(Math.round(value))).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1,"
          )}
          )
        </span>
      ) : (
        String(Math.round(value)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      )}
    </React.Fragment>
  );
};
