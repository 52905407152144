import { DocumentData } from "firebase/firestore";
import { fiscalPeriod } from "../../utils/date/fiscalPeriod";

export const salesPlanHeaders = (
  year: string | number,
  month: string | number
) => {
  const counts = ["", "", "", "", "", "", "", "", "", "", "", ""];

  const yearKeys = [
    `y${String(Number(year) + 1)}${String(month)}`,
    `y${String(Number(year) + 2)}${String(month)}`,
    `y${String(Number(year) + 3)}${String(month)}`,
  ];

  const headerData: DocumentData[] = [];

  yearKeys.map((yearKey, keyIndex) => {
    const periodData: string[] = [];

    counts.map((_, index) => {
      // 前年度の月次
      const targetYear = Number(year) + keyIndex + 1;
      const yargetMonth = month;

      const fisicalPeriod = fiscalPeriod(targetYear, yargetMonth, index);

      periodData.push(fisicalPeriod);
    });

    headerData.push({ [yearKeys[keyIndex]]: periodData });
  });

  return {
    years: yearKeys,
    terms: headerData,
  };
};

export const salesPlanItems = [
  { field: "sales_plan_items", items: ["野菜", "果物", "海鮮", "その他"] },
];

export const salesPlanData = [
  {
    field: "その他",
    item: "プリン",
    model_number: "",
    price: 1200,
    sales: {
      y202104: 2,
      y202105: 2,
      y202106: 2,
      y202107: 7,
      y202108: 6,
      y202109: 7,
      y202110: 4,
      y202111: 9,
      y202112: 6,
      y202201: 7,
      y202202: 4,
      y202203: 9,
    },
    descriptions: {},
    createdAt: new Date(),
    order: 1,
  },
];
