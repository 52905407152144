import React from "react";

interface Props {
  tab: number;
  setTab: (arg0: number) => void;
}

const AccountTabs: React.FC<Props> = ({ tab, setTab }) => {
  const tabs = [
    { title: "貸借対照表", path: "#bs" },
    { title: "損益計算書", path: "#pl" },
    { title: "製造原価報告書", path: "#report" },
  ];

  const handleClick = (index: number) => {
    setTab(index);
  };

  const active = "color-c427ed1 before:border-b-c427ed1";

  return (
    <div className="fixed w-full h-50px font-bold text-center text-c33333380 bg-c2a4b770d mt-100px px-20px">
      <ul className="flex flex-wrap h-full -mb-px gap-4px">
        {tabs.map((item, index) => (
          <li
            className={`flex items-center h-full px-8px ${
              tab === index && active
            } before:border-b-2`}
            key={index}
          >
            <button
              className={`inline-block p-4 rounded-t-lg before:border-b-2 before:border-transparent ${
                tab === index ? "text-c427ed1" : "hover:opacity-80"
              }`}
              onClick={() => handleClick(index)}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountTabs;
