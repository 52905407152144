import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { ShowTableData } from "../../../lib";
import { Unit } from "../../atoms";

export const CurrentRatio = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const managementDashboardState = useManagementDashboardInitialData();

  const states = managementDashboardState?.current_ratio;
  const unit = String(resultsAndPlansDataState.headers.unit);

  return (
    <div className="mb-20px p-30px bg-white rounded">
      <h2 className="font-bold">■流動比率</h2>
      <div className="flex">
        <div>
          <Unit unit={unit} />

          <table className="border">
            <thead className=" top-0 px-6px py-3px z-40 bg-c2a4b771a pt-120px border-b-2 text-center">
              <tr>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px`}
                >
                  決算期
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px border-l border-l-c3333334d`}
                >
                  流動資産
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  流動負債
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  流動比率
                </th>
              </tr>
            </thead>
            <tbody>
              {states.map((data: DocumentData, index: number) => (
                <tr
                  className={`border-b ${
                    states.length - 1 !== index && "border-b-c3333334d"
                  }`}
                  key={index}
                >
                  <th className={`p-4px text-right font-bold text-18px`}>
                    {data.name}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["流動資産"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["流動負債"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {`${
                      states && isFinite(states[index]["流動比率"])
                        ? states[index]["流動比率"]
                        : ""
                    }
                  %`}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col">
          <ResponsiveContainer height={300} width={states.length * 80 + 60}>
            <ComposedChart
              data={states}
              margin={{
                top: 20,
                right: 0,
                bottom: 20,
                left: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="name"
                interval={0}
                padding={{ right: 30, left: 30 }}
                scale="point"
                tick={{ fontSize: 12 }}
              />
              <YAxis
                domain={[0, (dataMax: number) => round(dataMax * 1.1)]}
                orientation="left"
                scale={"linear"}
                width={100}
                yAxisId={1}
              />
              <Tooltip />
              <Legend />
              <Bar barSize={40} dataKey="流動資産" fill="#9dc6ff" yAxisId={1} />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={300} width={states.length * 90 + 120}>
            <ComposedChart
              data={states}
              margin={{
                top: 20,
                right: 0,
                bottom: 20,
                left: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="name"
                interval={0}
                padding={{ right: 30, left: 30 }}
                scale="point"
                tick={{ fontSize: 12 }}
              />
              <YAxis
                domain={[0, (dataMax: number) => round(dataMax * 1.1)]}
                orientation="left"
                scale={"linear"}
                width={100}
                yAxisId={1}
              />
              <YAxis
                orientation="right"
                scale={"linear"}
                tickFormatter={(tick) => {
                  return `${tick}%`;
                }}
                width={100}
                yAxisId={2}
              />
              <Tooltip />
              <Legend />
              <Bar
                barSize={40}
                dataKey="流動負債"
                fill="#2a4b77cc"
                yAxisId={1}
              />
              <Line
                dataKey="流動比率"
                stroke="#295898"
                type="linear"
                yAxisId={2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
