import { round, divide, sum } from "mathjs";

export const totalRatioCalc = (state: number[]) => {
  const ratioResult: number[] = [];

  state.map((data: number | number[]) => {
    if (sum(state) !== 0) {
      const ratio = round(divide(Number(data), Number(sum(state))) * 1000) / 10;
      ratioResult.push(ratio);
    } else {
      ratioResult.push(0);
    }
  });

  return ratioResult;
};
