import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlPlansInputMonthlyTotal = ({
  titleTotal,
  titleRatio,
  headers,
  bottomLine,
  calcState,
  totalField,
  ratioField,
  isCurrentPlanMonthly,
  unit,
  descriptions,
}: {
  titleTotal: string;
  titleRatio: string | null;
  headers: DocumentData[];
  bottomLine: boolean;
  calcState: DocumentData;
  totalField: string;
  ratioField?: string;
  isCurrentPlanMonthly?: boolean;
  unit: number;
  descriptions: DocumentData;
}) => {
  const totalByItem = sum(calcState.monthly_total);
  const totalYearly = sum(calcState.yearly);
  const reminingNumber = round(sum(calcState.remining_total));

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pt-20px pl-4px bg-white before:border-l `;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-l-c3333334d 
         before:bg-c7fb28d33 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b  before:bg-c7fb28d33`;

  const classCol4 = `${borderSettings}  text-right font-bold bg-c7fb28d33`;

  const classCol5 = `relative p-4px before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10   text-right font-bold before:bg-cfffee2`;

  const classCol6 = `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${
        bottomLine && titleRatio === null
          ? "before:border-b"
          : titleRatio
          ? "before:border-b-c3333334d"
          : ""
      }`,
      colSpan: 2,
    },
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];

  headers &&
    headers.map((_, index: number) => {
      gridRow.push({
        value:
          calcState.monthly_total !== null &&
          isNaN(calcState.monthly_total[index])
            ? 0
            : calcState.monthly_total !== null &&
              calcState.monthly_total.length !== 0 &&
              isCurrentPlanMonthly
            ? ShowTableDataTriangle(calcState.monthly_total[index] / unit)
            : calcState.monthly_total !== null &&
              calcState.monthly_total.length !== 0
            ? ShowTableData(round(calcState.monthly_total[index]) / unit)
            : 0,
        expr:
          calcState.monthly_total !== null &&
          isNaN(calcState.monthly_total[index])
            ? 0
            : calcState.monthly_total !== null &&
              calcState.monthly_total.length !== 0 &&
              isCurrentPlanMonthly
            ? calcState.monthly_total[index] / unit
            : calcState.monthly_total !== null &&
              calcState.monthly_total.length !== 0
            ? round(calcState.monthly_total[index]) / unit
            : 0,
        readOnly: true,
        className: `${classCol4} before:border-b ${
          titleRatio && "before:border-b-c3333334d"
        }`,
      });
    });
  // 年度貼付
  gridRow.push({
    value: isCurrentPlanMonthly
      ? ShowTableDataTriangle(calcState.plans_input_total / unit)
      : ShowTableData(calcState.plans_input_total / unit),
    expr: calcState.plans_input_total / unit,
    readOnly: true,
    className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow.push({
      value:
        totalByItem !== null && isNaN(totalByItem)
          ? 0
          : totalByItem !== null
          ? ShowTableData(totalByItem / unit)
          : 0,
      expr:
        totalByItem !== null && isNaN(totalByItem)
          ? 0
          : totalByItem !== null
          ? totalByItem / unit
          : 0,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(calcState.current_term_total / unit),
      expr: calcState.current_term_total / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(reminingNumber / unit),
      expr: reminingNumber / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(totalYearly) ? 0 : ShowTableData(totalYearly / unit),
      expr: isNaN(totalYearly) ? 0 : totalYearly / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });

  gridRow.push({
    value: descriptions[totalField],
    expr: descriptions[totalField],
    readOnly: false,
    className: `${classCol6}  ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${bottomLine && "before:border-b"}`,
      colSpan: 2,
    },
    {
      value: titleRatio,
      readOnly: true,
      className: `${classCol2} before:border-b text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];

  headers &&
    headers.map((_, tdIndex: number) => {
      gridRow2.push({
        value:
          calcState.monthly_ratio !== null
            ? `${showPercentage(calcState.monthly_ratio[tdIndex])}%`
            : "",
        expr:
          calcState.monthly_ratio !== null
            ? showPercentage(calcState.monthly_ratio[tdIndex])
            : "",
        readOnly: true,
        className: `${classCol4} before:border-t-c3333334d`,
      });
    });
  // 年度貼付
  gridRow2.push({
    value: `${showPercentage(calcState.plans_input_ratio)}%`,
    expr: showPercentage(calcState.plans_input_ratio),
    readOnly: true,
    className: `${classCol5} before:border-t-c3333334d`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.monthly_total_ratio)}%`,
      expr: showPercentage(calcState.monthly_total_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.current_term_ratio)}%`,
      expr: showPercentage(calcState.current_term_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: `${showPercentage(calcState.remining_ratio)}%`,
      expr: showPercentage(calcState.remining_ratio),
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: "",
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol6}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
