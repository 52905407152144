export const nengou = (year: string | number, month: string | number) => {
  const date = new Date(Number(year), Number(month) - 1, 1);

  return date
    .toLocaleDateString("ja-JP-u-ca-japanese", {
      era: "narrow",
      year: "numeric",
    })
    .slice(0, -1);
};
