import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { PlansInputFieldsCalc } from "../../../types/plansInputFIeldsCalc";
import { beforeWrapper, borderSettings } from "../../../utils";

interface Container {
  title: string;
  unit: string;
  value: number[];
  field: string;
}

export const PlansInputWindowDressing = ({
  headers,
  field,
  state,
  descriptions,
  unit,
}: {
  headers: DocumentData[];
  field: string;
  state: PlansInputFieldsCalc;
  descriptions: DocumentData;
  unit: number;
}) => {
  const rows1 = [
    {
      title: "売上高",
      unit: "円",
      value: state.net_sales_total,
      field: `${field}_embellish_net_sales_total`,
    },
    {
      title: "売上原価",
      unit: "円",
      value: state.cost_of_sales_total,
      field: `${field}_embellish_cost_of_sales_total`,
    },
    {
      title: "原価率",
      unit: "％",
      value: state.cost_of_sales_ratio,
      field: `${field}_embellish_cost_of_sales_ratio`,
    },
    {
      title: "売上総利益",
      unit: "円",
      value: state.gross_margin,
      field: `${field}_embellish_gross_margin`,
    },
  ];
  const rows2 = [
    {
      title: "売上債権",
      unit: "円",
      value: state.accounts_receivable_total,
      field: `${field}_embellish_accounts_receivable_total`,
    },
    {
      title: "売上債権回転日数",
      unit: "日",
      value: state.receivables_turnover,
      field: `${field}_receivables_turnover`,
    },
    {
      title: "棚卸資産",
      unit: "円",
      value: state.shed_unloading_assets_total,
      field: `${field}_embellish_shed_unloading_assets_total`,
    },
    {
      title: "棚卸資産回転日数",
      unit: "日",
      value: state.inventory_turnover,
      field: `${field}_inventory_turnover`,
    },
  ];
  const containers = [rows1, rows2];

  const grid: GridElement[][] = [];

  const gridRow0_1: GridElement[] = [
    {
      value: "粉飾の確認",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white `,
      colSpan: 1 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0_1);

  const gridRow0_2: GridElement[] = [
    {
      value: (
        <div className={`absolute top-0 left-4px whitespace-nowrap`}>
          ①原価率低下＋売上債権増加⇒架空売上
          <br />
          ②原価率低下＋棚卸資産増加⇒架空在庫
        </div>
      ),
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-14px h-40px z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 1 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0_2);

  {
    containers.map((container: Container[], index: number) =>
      container.map((row: Container, rowIndex: number) => {
        const classCol1 = `${beforeWrapper} left-0 bg-white before:border-l p-4px before:bg-c80b0f433 font-bold ${
          index === 0 && rowIndex === 0 && "before:border-t"
        } ${rows1.length === rowIndex + 1 && "before:border-b"}`;

        const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-l ${
          container.length !== rowIndex + 1 && "before:border-b-c3333334d"
        } before:border-l-c3333334d ${
          rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } ${index === 0 && rowIndex === 0 && "before:border-t"}`;

        const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x ${
          container.length !== rowIndex + 1 && "before:border-b-c3333334d"
        } before:border-x-c3333334d text-center ${
          rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } ${index === 0 && rowIndex === 0 && "before:border-t"}`;

        const classCol4 = `${borderSettings} ${
          container.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }  text-right ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} ${
          index === 0 && rowIndex === 0 && "before:border-t"
        }`;

        const classCol5 = `${borderSettings} before:border-r ${
          container.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }  ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} ${
          index === 0 && rowIndex === 0 && "before:border-t"
        } h-50px description`;

        const gridRow: GridElement[] = [
          {
            value: rowIndex === 0 && (index === 0 ? "PL" : "BS"),
            readOnly: true,
            className: `${classCol1}`,
            colSpan: 2,
          },
          {
            value: row.title,
            readOnly: true,
            className: `${classCol2}`,
          },
          {
            value: row.unit,
            readOnly: true,
            className: `${classCol3}`,
          },
        ];

        headers?.map((header, tdIndex) => {
          gridRow.push({
            value:
              index === 0 && rowIndex === 2
                ? `${row.value[tdIndex]}%`
                : index === 1 && (rowIndex === 1 || rowIndex === 3)
                ? `${
                    row.value[tdIndex] && isNaN(row.value[tdIndex])
                      ? 0
                      : `${round(row.value[tdIndex] / 10) / 10}`
                  }`
                : String(row.value[tdIndex] / unit).replace(
                    /(\d)(?=(\d\d\d)+(?!\d))/g,
                    "$1,"
                  ),
            readOnly: true,
            className: `${classCol4} ${header.isTotal ? "bg-cfffee2" : ""}`,
          });
        });
        gridRow.push({
          value: descriptions[`${field}_${index}${rowIndex}`],
          readOnly: false,
          className: `${classCol5}`,
          field: `${field}_${index}${rowIndex}`,
          key: "descriptions_calc",
          row: descriptions,
        });

        grid.push(gridRow);
      })
    );
  }

  return grid;
};
