import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { CurrentAndPreviousYearComparisonsContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";
import { useCurrentAndPreviousYearComparisonsInitialData } from "../../hooks/initialData";

export const CurrentAndPreviousYearComparisons = () => {
  const currentAndPreviousYearComparisonsCalcState =
    useCurrentAndPreviousYearComparisonsInitialData();

  if (!currentAndPreviousYearComparisonsCalcState) {
    return <Spinner />;
  }

  const url = pageConstant.CURRENT_AND_PREVIOUS_YEAR_COMPARISONS;

  return (
    <React.Fragment>
      <SubHeader title="今期直近までと前期の比較" />
      <UnitChange />
      <CurrentAndPreviousYearComparisonsContainer
        state={currentAndPreviousYearComparisonsCalcState}
        url={url}
      />
    </React.Fragment>
  );
};
