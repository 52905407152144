import React from "react";
import { useRecoilValue } from "recoil";
import { toggleStore } from "../../store/switchState";
import { Header } from "../header";
import { Sidebar } from "../sidebar";

interface Props {
  children: React.ReactNode;
}

const Dashboard: React.FC<Props> = ({ children }) => {
  const toggle = useRecoilValue(toggleStore);

  return (
    <div className="flex">
      <div className="h-[100vh] bg-c1b2e48">
        <Sidebar />
      </div>
      <div
        className={`${
          toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
        } flex-1 overflow-auto`}
      >
        <Header />
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
