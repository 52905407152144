import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlPlansInputWithTax = ({
  headers,
  rows,
  url,
  bodyField,
  unit,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  totalByItem: number[];
  url: string;
  bodyField?: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b z-[32]`;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white  before:border-b 
         font-bold`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-b  before:border-x-c3333334d`;

      const classCol4 = `${borderSettings} before:border-l before:border-l-c3333334d before:border-b  text-right before:border-x-c3333334d }`;

      const classCol6 = `${borderSettings} before:border-x before:border-b before:border-l-c3333334d h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push(
        {
          value:
            rowIndex === 0 ? (
              <div
                className={`absolute left-4px whitespace-nowrap top_absolute`}
              >
                法人税、住民税及び事業税
              </div>
            ) : (
              ""
            ),
          expr: rowIndex === 0 ? "法人税、住民税及び事業税" : "",
          readOnly: true,
          className: classCol1,
          colSpan: 2,
          row: row,
          url: url,
          key: "",
        },
        {
          value: "",
          expr: "",
          readOnly: true,
          className: classCol2,
        },
        { value: "", expr: "", readOnly: true, className: classCol3 }
      );

      headers.map((header: DocumentData) => {
        // オブジェクトの中から該当する値を取得

        const resultsAndPlanTerm = row[url];

        type TypeOfResultObj = typeof resultsAndPlanTerm;
        const key: keyof TypeOfResultObj = header.period;
        const resultValue = row[url][key] ?? 0;

        gridRow.push({
          value: !isNaN(resultValue) ? ShowTableData(resultValue / unit) : 0,
          expr: !isNaN(resultValue) ? resultValue / unit : 0,
          readOnly: false,
          className: classCol4,
          row: row,
          url: url,
          key: key as string,
          colSpan: 1,
        });
      });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol6,
        row: row,
        url: url,
        field: `${bodyField}_${row?.field as string}`,
        key: "descriptions",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
