import { DocumentData } from "firebase/firestore";
import { abs, add, divide, dotDivide, subtract, sum, transpose } from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { useGetFirebaseData } from "../../firebase";
import { Logout } from "../../lib/Logout";
import { sortData } from "../../utils";
import {
  calcByItemForProfitAndLossPlanMonthly,
  perValuesForSalesPlan,
  totalCalcByItemMonthlyTotalForProfitAndLossPlan,
} from "../../utils/calc";

export const useBusinessPlanInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_expenses,
    descriptions,
  } = useSubjectItemsInitialData();

  const profitField = pageConstant.PROFIT_AND_LOSS_PLAN;
  const salesPlanField = pageConstant.SALES_PLAN;

  const { tableData } = useGetFirebaseData();

  try {
    // 今期までの売上高
    const netSalesTotalData = tableData.filter(
      (inputData) => inputData.field === "net_sales_total"
    );
    // 今期までの売上原価
    const costOfSalesTotalData = tableData.filter(
      (inputData) => inputData.field === "cost_of_sales_total"
    );

    const salesPlanHeadlines: string[] = [];
    net_sales.map((headlineData) => {
      salesPlanHeadlines.push(headlineData.item);
    });

    // 事業計画ページの減価償却費
    const depreciationCostForBusinessPlanData = sortData(
      "business_plan_depreciation_cost",
      tableData
    );
    // 事業計画ページの設備投資額
    const capitalInvestmentForBusinessPlanData = sortData(
      "business_plan_capital_investment",
      tableData
    );
    const businessPlanState = {
      headers: headers,
      net_sales_total_data: netSalesTotalData,
      descriptions: descriptions,
      depreciation_cost_for_business_plan_data:
        depreciationCostForBusinessPlanData,
      capital_investment_for_business_plan_data:
        capitalInvestmentForBusinessPlanData,
    };

    // 基準年度
    const targetYear = `y${headers.fiscal_year}${headers.fiscal_month}`;

    // 事業計画のUP内容
    // const businessPlanUp = headers.business_plan_up;
    // 事業計画のUP内容が削除になったので、0を代入
    const businessPlanUp = 0;

    // 基準年度の売上高
    const netSalesTotalTargetData =
      netSalesTotalData[0]?.profit_and_loss_plan?.[`${targetYear}`] ?? 0;
    const netSalesTotal =
      netSalesTotalTargetData +
      (netSalesTotalTargetData * businessPlanUp) / 100;

    // 年度リスト
    const salesPlanTerms = headers.sales_plan.years;
    // 直近の年度の月リスト
    const headerTerms = headers.sales_plan;
    // 年間 販売数X売価
    const salesPlanTotal: number[] = [netSalesTotal];
    // 毎年の合計
    const priceMultipleSales: number[][] = [];
    // 売上原価の年間総合計
    const costPlanTotal: number[] = [];
    // 売上原価の毎月の合計
    const priceMultipleSalesCost: number[][] = [];

    salesPlanHeadlines &&
      salesPlanHeadlines.map((item: string) => {
        // データの抽出およびorder順に並べ替え
        const data = tableData.filter((inputData) => inputData.field === item);
        const arrayRows = [...data];
        const newData = arrayRows.sort(function (
          prev: DocumentData,
          next: DocumentData
        ) {
          return prev.order - next.order;
        });

        const priceYearlyTotal: number[] = [];
        const costYearlyTotal: number[] = [];
        salesPlanTerms &&
          salesPlanTerms.map((salesPlanTerm: string, salesIndex: number) => {
            // 販売数X価格
            const multiplePriceData = perValuesForSalesPlan({
              headers: headerTerms.terms[salesIndex][salesPlanTerm],
              field: salesPlanField,
              state: newData,
              target: "price",
            });

            const multiPriceData = sum(multiplePriceData) as number;
            priceYearlyTotal.push(multiPriceData);

            // 販売数X原価
            const multipleCostPriceData = perValuesForSalesPlan({
              headers: headerTerms.terms[salesIndex][salesPlanTerm],
              field: salesPlanField,
              state: newData,
              target: "costPrice",
            });
            const multiCostPriceData = sum(multipleCostPriceData) as number;
            costYearlyTotal.push(multiCostPriceData);
          });
        priceMultipleSales.push(priceYearlyTotal);
        priceMultipleSalesCost.push(costYearlyTotal);
      });

    // 売上高の年間合計
    const transposePriceMultipleSales = transpose(priceMultipleSales);
    transposePriceMultipleSales.map((data) => {
      salesPlanTotal.push(sum(data));
    });

    // 売上原価の年間合計
    const transposePriceMultipleSalesCost = transpose(priceMultipleSalesCost);
    transposePriceMultipleSalesCost.map((data) => {
      costPlanTotal.push(sum(data) as number);
    });

    // 売上高
    const netSalesMonthlyTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: netSalesTotalData,
      });
    // 売上原価
    const costOfSalesMonthlyTotal =
      totalCalcByItemMonthlyTotalForProfitAndLossPlan({
        headers: headers[profitField],
        field: profitField,
        state: costOfSalesTotalData,
      });

    const netSalesResultsTotal = [
      netSalesMonthlyTotal.monthlyTotal[0]
        ? netSalesMonthlyTotal.monthlyTotal[0]
        : 0,
      netSalesMonthlyTotal.monthlyTotal[1]
        ? netSalesMonthlyTotal.monthlyTotal[1]
        : 0,
      salesPlanTotal[0] ? salesPlanTotal[0] : 0,
      salesPlanTotal[1] ? salesPlanTotal[1] : 0,
      salesPlanTotal[2] ? salesPlanTotal[2] : 0,
    ];
    const costOfSalesResultsTotal = [
      costOfSalesMonthlyTotal.monthlyTotal[0]
        ? costOfSalesMonthlyTotal.monthlyTotal[0]
        : 0,
      costOfSalesMonthlyTotal.monthlyTotal[1]
        ? costOfSalesMonthlyTotal.monthlyTotal[1]
        : 0,
      costPlanTotal[0] ? costPlanTotal[0] : 0,
      costPlanTotal[1] ? costPlanTotal[1] : 0,
      costPlanTotal[2] ? costPlanTotal[2] : 0,
    ];

    // 売上総利益
    const grossMargin = subtract(
      netSalesResultsTotal,
      costOfSalesResultsTotal
    ) as number[];
    // 人件費合計
    const personalCostTotalTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: personal_cost,
    });
    const personalCostTotal = [
      personalCostTotalTotal.totalData[0],
      personalCostTotalTotal.totalData[1] +
        (personalCostTotalTotal.totalData[1] * businessPlanUp) / 100,
      ...personalCostTotalTotal.totalData.slice(2),
    ];

    // 物件費合計
    const costOfEquipmentTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: selling_and_administrative,
    });

    // 項目別販売費及び一般管理費合計
    const sellingAndAdministrativeTotal = add(
      personalCostTotal,
      costOfEquipmentTotal.totalData
    ) as number[];
    // 営業利益
    const operatingIncome: number[] =
      sellingAndAdministrativeTotal.length !== 0
        ? [
            subtract(grossMargin[0], sellingAndAdministrativeTotal[0]),
            subtract(grossMargin[1], sellingAndAdministrativeTotal[1]) +
              (subtract(grossMargin[1], sellingAndAdministrativeTotal[1]) *
                businessPlanUp) /
                100,
            ...subtract(
              grossMargin.slice(2),
              sellingAndAdministrativeTotal.slice(2)
            ),
          ]
        : [
            grossMargin[0],
            grossMargin[1] + (grossMargin[1] * businessPlanUp) / 100,
            ...grossMargin.slice(2),
          ];

    // 営業外費用合計
    const nonOperatingExpensesTotalTotal =
      calcByItemForProfitAndLossPlanMonthly({
        headers: headers[profitField],
        field: profitField,
        states: non_operating_expenses,
      });
    const nonOperatingExpensesTotal = [
      nonOperatingExpensesTotalTotal.totalData[0],
      nonOperatingExpensesTotalTotal.totalData[1] +
        (nonOperatingExpensesTotalTotal.totalData[1] * businessPlanUp) / 100,
      ...nonOperatingExpensesTotalTotal.totalData.slice(2),
    ];

    // 法定福利費
    const legalWelfareExpenseData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const legalWelfareExpenseTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: legalWelfareExpenseData,
    });
    // 法定福利費
    const retirementAnnuityData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const retirementAnnuityTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: retirementAnnuityData,
    });
    // 福利厚生費
    const welfareExpensesData = tableData.filter(
      (inputData) => inputData.field === "profit_and_loss_plan_personal_cost"
    );
    const welfareExpensesTotal = calcByItemForProfitAndLossPlanMonthly({
      headers: headers[profitField],
      field: profitField,
      states: welfareExpensesData,
    });
    // 給与支給総額 人件費-法定福利費-退職金掛金-福利厚生費
    const grossPersonalCost = subtract(
      personalCostTotal,
      add(
        legalWelfareExpenseTotal.totalData,
        add(retirementAnnuityTotal.totalData, welfareExpensesTotal.totalData)
      )
    );
    const businessPlanField = pageConstant.BUSINESS_PLAN;

    // 事業計画ページの経常利益
    const currentEarningsForBusinessPlan: number[] =
      nonOperatingExpensesTotal.length !== 0
        ? subtract(operatingIncome, nonOperatingExpensesTotal)
        : operatingIncome;
    // 事業計画ページの減価償却費
    const depreciationCostForBusinessPlanTotal =
      calcByItemForProfitAndLossPlanMonthly({
        headers: headers[profitField],
        field: businessPlanField,
        states: depreciationCostForBusinessPlanData,
      });
    // 事業計画ページの付加価値額
    const addedValue =
      personalCostTotal.length !== 0 &&
      depreciationCostForBusinessPlanTotal.totalData.length !== 0
        ? add(
            operatingIncome,
            add(
              personalCostTotal,
              depreciationCostForBusinessPlanTotal.totalData
            )
          )
        : personalCostTotal.length !== 0
        ? add(operatingIncome, personalCostTotal)
        : depreciationCostForBusinessPlanTotal.totalData.length !== 0
        ? add(operatingIncome, depreciationCostForBusinessPlanTotal.totalData)
        : operatingIncome;
    // 事業計画ページの伸び率
    const growthRate = dotDivide(
      subtract(addedValue, addedValue[1]),
      abs(addedValue[1])
    ) as number[];
    const editGrossPersonalCost: number[] = [];
    grossPersonalCost.map((_, index: number) => {
      if (index === 0) {
        editGrossPersonalCost.push(1);
      } else if (grossPersonalCost.length === index + 1) {
        return;
      }
      editGrossPersonalCost.push(grossPersonalCost[index]);
    });
    // 事業計画ページの伸び率（下の方）
    const personalCostGrowthRate = dotDivide(
      subtract(grossPersonalCost, editGrossPersonalCost),
      abs(editGrossPersonalCost)
    ) as number[];

    // 事業計画ページの売上伸び率
    const netSalesGrowthRate = [
      0,
      0,
      isNaN(divide(salesPlanTotal[1] - salesPlanTotal[0], salesPlanTotal[0])) ||
      !isFinite(
        divide(salesPlanTotal[1] - salesPlanTotal[0], salesPlanTotal[0])
      )
        ? 0
        : divide(salesPlanTotal[1] - salesPlanTotal[0], salesPlanTotal[0]),
      isNaN(divide(salesPlanTotal[2] - salesPlanTotal[1], salesPlanTotal[1])) ||
      !isFinite(
        divide(salesPlanTotal[2] - salesPlanTotal[1], salesPlanTotal[1])
      )
        ? 0
        : divide(salesPlanTotal[2] - salesPlanTotal[1], salesPlanTotal[1]),
      isNaN(divide(salesPlanTotal[3] - salesPlanTotal[2], salesPlanTotal[2])) ||
      !isFinite(
        divide(salesPlanTotal[3] - salesPlanTotal[2], salesPlanTotal[2])
      )
        ? 0
        : divide(salesPlanTotal[3] - salesPlanTotal[2], salesPlanTotal[2]),
    ];

    // 事業計画ページの営業利益率
    const operatingProfitRatio =
      operatingIncome.length === 5 && salesPlanTotal.length === 4
        ? [
            0,
            0,
            isNaN(divide(operatingIncome[2], salesPlanTotal[1])) ||
            !isFinite(divide(operatingIncome[2], salesPlanTotal[1]))
              ? 0
              : divide(operatingIncome[2], salesPlanTotal[1]),
            isNaN(divide(operatingIncome[3], salesPlanTotal[2])) ||
            !isFinite(divide(operatingIncome[3], salesPlanTotal[2]))
              ? 0
              : divide(operatingIncome[3], salesPlanTotal[2]),
            isNaN(divide(operatingIncome[4], salesPlanTotal[3])) ||
            !isFinite(divide(operatingIncome[4], salesPlanTotal[3]))
              ? 0
              : divide(operatingIncome[4], salesPlanTotal[3]),
          ]
        : [0, 0, 0, 0, 0];

    const businessPlanCalcState = {
      net_sales_total: {
        results_total: netSalesMonthlyTotal.monthlyTotal,
        plans_by_item: priceMultipleSales,
        plans_total: salesPlanTotal,
      },
      personal_cost_total: personalCostTotal,
      operating_income: operatingIncome,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      gross_personal_cost: grossPersonalCost,
      current_earnings_for_business_plan: currentEarningsForBusinessPlan,
      added_value: addedValue,
      growth_rate: growthRate,
      personal_cost_growth_rate: personalCostGrowthRate,
      net_sales_growth_rate: netSalesGrowthRate,
      operating_profit_ratio: operatingProfitRatio,
    };
    return { businessPlanState, businessPlanCalcState };
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
