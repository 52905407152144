import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import {
  usePlansInputMonthlyInitialData,
  useResultsOfTheCurrentTermInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CurrentResultMonthlyHeader,
  CurrentResultMonthlyTableBlank,
  PlCurrentResultMonthlyBody,
  PlCurrentResultMonthlyCostRatioForEstimate,
  PlCurrentResultMonthlyGrandTotal,
  PlCurrentResultMonthlyRatio,
  PlCurrentResultMonthlySimpleCf,
  PlCurrentResultMonthlyTotal,
  PlCurrentResultMonthlyWithTax,
} from "../../../lib/datasheets/currentResultMonthly";
import { CurrentResultMonthlyFieldsCalc } from "../../../types/currentResultMonthlyFieldsCalc";
import { GridElement } from "../../../types/gridElement";
import { Spinner } from "../../molecules";

interface Props {
  url: string;
  dataUrl: string;
  calcState: CurrentResultMonthlyFieldsCalc;
}

const PlCurrentResultMonthlyTable: React.FC<Props> = ({
  url,
  dataUrl,
  calcState,
}) => {
  const firestore = useFirestore();

  const plansInputMonthlyCalcState = usePlansInputMonthlyInitialData();
  const resultsOfTheCurrentTermCalcState =
    useResultsOfTheCurrentTermInitialData();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  if (!plansInputMonthlyCalcState || !resultsOfTheCurrentTermCalcState) {
    return <Spinner />;
  }

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlCurrentResultMonthlyBody({
    title: "売上高",
    headers: resultsAndPlansDataState.headers[url],
    rows: resultsAndPlansDataState.net_sales,
    totalByItem: calcState.net_sales_total_by_item,
    url: url,
    dataUrl: dataUrl,
    bodyField: `${dataUrl}_net_sales_body`,
    unit: unit,
    descriptions: resultsAndPlansDataState.descriptions,
  });
  grid.push(
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_sales_total,
      ratio: calcState.net_sales_ratio,
      totalField: `${dataUrl}_net_sales_total`,
      ratioField: `${dataUrl}_net_sales_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  grid.push(
    ...PlCurrentResultMonthlyBody({
      title: "売上原価",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.cost_of_sales,
      totalByItem: calcState.cost_of_sales_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_cost_of_sales_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );
  grid.push(
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cost_of_sales_total,
      ratio: calcState.cost_of_sales_ratio,
      totalField: `${dataUrl}_cost_of_sales_total`,
      ratioField: `${dataUrl}_cost_of_sales_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );
  grid.push(
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_margin,
      ratio: calcState.gross_margin_ratio,
      totalField: `${dataUrl}_gross_margin`,
      ratioField: `${dataUrl}_gross_margin_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "販売費及び一般管理費",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.personal_cost,
      totalByItem: calcState.personal_cost_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_personal_cost_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上高人件費比率",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.personal_cost_total,
      ratio: calcState.personal_cost_ratio,
      totalField: `${dataUrl}_personal_cost_total`,
      ratioField: `${dataUrl}_personal_cost_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.selling_and_administrative,
      totalByItem: calcState.selling_and_administrative_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_selling_and_administrative_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.selling_and_administrative_total,
      ratio: calcState.selling_and_administrative_ratio,
      totalField: `${dataUrl}_selling_and_administrative_total`,
      ratioField: `${dataUrl}_selling_and_administrative_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_income,
      ratio: calcState.operating_income_ratio,
      totalField: `${dataUrl}_operating_income`,
      ratioField: `${dataUrl}_operating_income_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.operating_profits_before_amortization,
      ratio: calcState.operating_profits_before_amortization_ratio,
      totalField: `${dataUrl}_operating_profits_before_amortization`,
      ratioField: `${dataUrl}_operating_profits_before_amortization_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "営業外収益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_income,
      totalByItem: calcState.non_operating_income_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_non_operating_income_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_income_total,
      totalField: `${dataUrl}_non_operating_income_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "営業外費用",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.non_operating_expenses,
      totalByItem: calcState.non_operating_expenses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_non_operating_expenses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.non_operating_expenses_total,
      totalField: `${dataUrl}_non_operating_expenses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.current_earnings,
      ratio: calcState.current_earnings_ratio,
      totalField: `${dataUrl}_current_earnings`,
      ratioField: `${dataUrl}_current_earnings_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebitda,
      ratio: calcState.ebitda_ratio,
      totalField: `${dataUrl}_ebitda`,
      ratioField: `${dataUrl}_ebitda_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "特別利益",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_benefits,
      totalByItem: calcState.special_benefits_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_special_benefits_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_benefits_total,
      totalField: `${dataUrl}_special_benefits_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyBody({
      title: "特別損失",
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_losses,
      totalByItem: calcState.special_losses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_special_losses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.special_losses_total,
      totalField: `${dataUrl}_special_losses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.ebit,
      ratio: calcState.ebit_ratio,
      totalField: `${dataUrl}_ebit`,
      ratioField: `${dataUrl}_ebit_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyWithTax({
      headers: resultsAndPlansDataState.headers[url],
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      totalByItem:
        calcState.special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item,
      url: url,
      dataUrl: dataUrl,
      bodyField: `${dataUrl}_special_losses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlCurrentResultMonthlyGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.net_income,
      ratio: calcState.net_income_ratio,
      totalField: `${dataUrl}_net_income`,
      ratioField: `${dataUrl}_net_income_ratio`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CurrentResultMonthlyTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlCurrentResultMonthlySimpleCf({
      headers: resultsAndPlansDataState.headers[url],
      row1: calcState.simple_cf_row1,
      row2: calcState.simple_cf_row2,
      row3: calcState.simple_cf_row3,
      field1: `${dataUrl}_simple_cf_row1`,
      field2: `${dataUrl}_simple_cf_row2`,
      field3: `${dataUrl}_simple_cf_row3`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  grid.push(
    ...CurrentResultMonthlyTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlCurrentResultMonthlyCostRatioForEstimate({
      headerTitle: "月別売上比率",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      byItemTotal: resultsOfTheCurrentTermCalcState.net_sales_total_by_item,
      monthlyTotal: resultsOfTheCurrentTermCalcState.net_sales_total,
      estimateCalc: plansInputMonthlyCalcState.net_sales_total,
      field: url,
      totalField: `${dataUrl}_net_sales_monthly_total_ratio`,
      resultsCount:
        resultsAndPlansDataState.headers.results_of_the_current_term_count,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  grid.push(
    ...CurrentResultMonthlyTableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlCurrentResultMonthlyRatio({
      headerTitle: "売上構成比",
      title: "売上高",
      rows: resultsAndPlansDataState.net_sales,
      headers: resultsAndPlansDataState.headers[url],
      field: dataUrl,
      calcState: calcState.net_sales_total_by_item_ratio,
      descriptions: resultsAndPlansDataState.descriptions,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: dataUrl,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <CurrentResultMonthlyHeader
            headers={resultsAndPlansDataState.headers[url]}
            resultsCount={
              resultsAndPlansDataState.headers.results_of_the_current_term_count
            }
            title="■PL"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlCurrentResultMonthlyTable);
