export const profitAndLossStatementAccountHeadings = [
  { field: "net_sales", name: "売上高" },
  { field: "cost_of_sales", name: "売上原価" },
  { field: "personal_cost", name: "人件費" },
  { field: "selling_and_administrative", name: "その他販管費" },
  { field: "non_operating_income", name: "営業外収益" },
  { field: "non_operating_expenses", name: "営業外費用" },
  { field: "special_benefits", name: "特別利益" },
  { field: "special_losses", name: "特別損失" },
  // {
  //   field: "special_corporate_inhabitant_and_enterprise_taxeslosses",
  //   name: "法人税、住民税及び事業税",
  // },
];

export const costReportAccountHeadings = [
  { field: "material_fee", name: "材料費" },
  { field: "labor_costs", name: "労務費" },
  { field: "external_injection_processing_fee", name: "外注加工費" },
  { field: "expenses", name: "経費" },
  {
    field: "inventory_of_work_in_process_at_the_beginning_of_the_period",
    name: "期首仕掛品棚卸高",
  },
  {
    field: "inventory_of_work_in_process_at_the_end_of_the_period",
    name: "期末仕掛品棚卸高",
  },
];

export const balanceSheetAccountHeadings = [
  {
    field: "cash_equivalent",
    name: "現金・預金",
  },
  {
    field: "accounts_receivable",
    name: "売上債権",
  },
  {
    field: "current_assets",
    name: "有価証券",
  },
  {
    field: "shed_unloading_assets",
    name: "棚卸資産",
  },
  {
    field: "quotidian_gold",
    name: "貸倒引当金",
  },
  {
    field: "other_current_assets",
    name: "その他流動資産",
  },
  {
    field: "tangible_fixed_assets",
    name: "有形固定資産",
  },
  {
    field: "intangible_fixed_assets",
    name: "無形固定資産",
  },
  {
    field: "investments_and_other_assets",
    name: "投資その他",
  },
  {
    field: "deferred_asset",
    name: "繰延資産",
  },
  {
    field: "payables",
    name: "支払債務",
  },
  {
    field: "unpaids",
    name: "未払",
  },
  {
    field: "short_term_borrowings",
    name: "短期借入金",
  },
  {
    field: "current_liabilities",
    name: "その他流動負債",
  },
  {
    field: "fixed_liabilities",
    name: "固定負債",
  },
  {
    field: "capital",
    name: "資本金",
  },
  {
    field: "capital_surplus",
    name: "資本剰余金",
  },
  {
    field: "retained_earnings",
    name: "利益剰余金",
  },
  {
    field: "other_retained_earnings",
    name: "項目別その他の利益剰余金",
  },
  {
    field: "treasury_stock",
    name: "自己株式",
  },
];
