import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CostPlansInputMonthlyTotal = ({
  titleTotal,
  crossheadTotal,
  sectionTotal,
  bottomLine,
  isCrosshead,
  isWhite,
  headers,
  noHeader,
  calcState,
  totalField,
  isCurrentPlanMonthly,
  unit,
  descriptions,
}: {
  titleTotal?: string;
  headers: DocumentData[];
  crossheadTotal?: string;
  sectionTotal?: string;
  bottomLine?: boolean;
  isCrosshead?: boolean;
  calcState: DocumentData;
  isWhite?: boolean;
  noHeader?: boolean;
  totalField?: string;
  isCurrentPlanMonthly?: boolean;
  unit: number;
  descriptions: DocumentData;
}) => {
  const totalByItem = sum(calcState.monthly_total);
  const reminingNumber = round(sum(calcState.remining_total));

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px before:border-l   bg-white   ${
    bottomLine ? "before:border-b" : ""
  } ${noHeader && "before:border-t"} ${
    isWhite ? "before:bg-transparent" : "before:bg-c80b0f433"
  } font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-30px p-4px  bg-white  ${
    bottomLine && "before:border-b"
  } ${crossheadTotal && "before:border-l before:border-l-c3333334d"} ${
    noHeader && "before:border-t"
  } ${isCrosshead && !crossheadTotal && "before:border-b"} ${
    isWhite ? "bg-transparent" : "before:bg-c80b0f433"
  } font-bold z-[31]`;

  const classCol3 = `${beforeWrapper} left-80px p-4px bg-white 
             before:border-b ${noHeader && "before:border-t"} ${
    isWhite ? "before:bg-transparent" : "before:bg-c80b0f433"
  } ${sectionTotal && "before:border-l before:border-l-c3333334d"}`;

  const classCol4 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-r before:border-r-c3333334d ${
    noHeader && "before:border-t"
  } ${isWhite ? "before:bg-transparent" : "before:bg-c80b0f433"}`;

  const classCol5 = `${borderSettings} ${
    noHeader && "before:border-t"
  }   text-right font-bold`;

  const classCol6 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  w-120px  text-right  before:bg-cfffee2`;

  const classCol7 = `${borderSettings} before:border-r  ${
    noHeader && "before:border-t"
  } ${
    isWhite ? "bg-transparent" : "bg-c80b0f433"
  } text-left h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap  top_absolute`}>
          {crossheadTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: sectionTotal,
      readOnly: true,
      className: `${classCol3} `,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} `,
    },
  ];

  headers &&
    headers.map((header, index) => {
      gridRow.push({
        value:
          calcState.monthly_total !== null && isCurrentPlanMonthly
            ? ShowTableDataTriangle(calcState.monthly_total[index] / unit)
            : calcState.monthly_total !== null
            ? ShowTableData(calcState.monthly_total[index] / unit)
            : 0,
        expr:
          calcState.monthly_total !== null && isCurrentPlanMonthly
            ? calcState.monthly_total[index] / unit
            : calcState.monthly_total !== null
            ? calcState.monthly_total[index] / unit
            : 0,
        readOnly: true,
        className: `${classCol5} ${
          isWhite
            ? "bf-white"
            : header.isTotal
            ? "bg-cfffee2"
            : !header.isTotal && isWhite
            ? "bg-transparent"
            : "bg-c80b0f433"
        }`,
      });
    });

  // 年度貼付
  gridRow.push({
    value: isNaN(calcState.plans_input_total)
      ? 0
      : isCurrentPlanMonthly
      ? ShowTableDataTriangle(calcState.plans_input_total / unit)
      : ShowTableData(calcState.plans_input_total / unit),
    expr: isNaN(calcState.plans_input_total)
      ? 0
      : isCurrentPlanMonthly
      ? calcState.plans_input_total / unit
      : calcState.plans_input_total / unit,
    readOnly: true,
    className: classCol6,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow.push({
      value:
        totalByItem !== null ? ShowTableData(round(totalByItem) / unit) : "",
      expr: totalByItem !== null ? totalByItem / unit : "",
      readOnly: true,
      className: classCol6,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(calcState.current_term_total / unit),
      expr: calcState.current_term_total / unit,
      readOnly: true,
      className: classCol6,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(reminingNumber / unit),
      expr: reminingNumber / unit,
      readOnly: true,
      className: classCol6,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(calcState.yearly_total)
        ? 0
        : ShowTableData(calcState.yearly_total / unit),
      expr: isNaN(calcState.yearly_total) ? 0 : calcState.yearly_total / unit,
      readOnly: true,
      className: classCol6,
    });

  gridRow.push({
    value: totalField && descriptions[totalField],
    expr: totalField && descriptions[totalField],
    readOnly: false,
    className: `${classCol7}`,
    field: totalField && totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
