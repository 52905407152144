import { DocumentData } from "firebase/firestore";
import React from "react";

interface Props {
  title?: string;
  headers: DocumentData[];
  count: number;
}

export const EstimatedInThisTermHeader: React.FC<Props> = ({
  headers,
  title,
  count,
}) => {
  const topCss =
    "relative px-6px py-3px top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full";

  const bottomCss =
    "relative before:content-[''] before:border-b-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-full";

  return (
    <thead className="sticky top-0 px-6px py-3px z-40 bg-white pt-120px">
      <tr>
        <th
          className={`sticky w-30px min-w-[1.875rem] left-0 align-bottom text-left font-bold text-18px z-40 ${topCss} 
            before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={3}
        >
          <div className="absolute bottom-0 whitespace-nowrap">{title}</div>
        </th>
        <th
          className={`sticky left-30px w-45px min-w-[3.125rem] align-bottom text-left font-bold text-18px z-30 ${topCss} 
            before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={3}
        ></th>
        <th
          className={`sticky left-80px w-140px min-w-[8.75rem] align-bottom text-left font-bold text-18px z-40 ${topCss} 
            before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={3}
        ></th>
        <th
          className={`sticky left-220px min-w-[3.125rem] w-45px ${topCss} before:border-t
            before:border-b-2 before:border-x
            before:border-r-c3333334d font-bold 
            z-40 before:bg-white before:-z-10`}
          rowSpan={3}
        >
          固定
          <br />
          変動
          <br />
          区分
        </th>
        {headers?.map((header: DocumentData, index: number) => (
          <th
            className={`min-w-[7.5rem] w-120px bg-c2a4b770d ${topCss} before:border-t  ${
              header.period === "total"
                ? "bg-cfffee2 before:border-x text-center"
                : "before:border-l before:border-l-c3333334d text-left "
            }`}
            key={index}
          >
            {`${header.nengou}年`}
          </th>
        ))}
        <th
          className={`min-w-[7.5rem] w-120px bg-c2a4b770d ${topCss} before:border-t bg-cfffee2 before:border-x text-center`}
        >
          {`${headers && headers[headers?.length - 1].nengou}年`}
        </th>
        <th
          className={`min-w-[37.5rem] w-600px  before:border-collapse text-center align-middle bg-white z-30 ${topCss} before:border-l before:border-l-c3333334d before:border-x before:border-t before:border-b-2`}
          rowSpan={3}
        >
          備考
        </th>
      </tr>
      <tr>
        {headers?.map((header: DocumentData, index: number) => (
          <th
            className={`w-120px text-left bg-c2a4b770d first-letter ${topCss} before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
            key={index}
          >
            {`
              ${header.period.substring(5, 7)}月`}
          </th>
        ))}
        <th
          className={`w-120px text-left bg-c2a4b770d first-letter ${topCss} before:border-b before:border-b-c3333334d bg-cfffee2 before:border-x text-centerbg-cfffee2 text-center`}
        >
          合計
        </th>
      </tr>
      <tr>
        {headers?.map((header: DocumentData, tdIndex: number) => (
          <th
            className={`relative top-0 px-6px py-3px bg-c2a4b771a ${bottomCss} before:border-l before:border-l-c3333334d h-40px`}
            key={tdIndex}
          >
            {count && count > tdIndex ? "実績" : "見込み"}
          </th>
        ))}
        <th
          className={`top-0 px-6px py-3px bg-c2a4b771a ${bottomCss} bg-cfffee2 before:border-x text-center2`}
        >
          見込み
        </th>
      </tr>
    </thead>
  );
};
