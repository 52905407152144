import { DocumentData } from "firebase/firestore";
import { sum, add } from "mathjs";

type Props = {
  value: number[];
  rate: DocumentData;
  unit: number;
  tax: number;
};

export const salesReceiptAndPaymentOnPurchaseCalc = ({
  value,
  rate,
  unit,
  tax,
}: Props) => {
  const thisMonth: number[] = [];
  const nextMonth: number[] = [];
  const twoMonthLater: number[] = [];
  const thisMonthWithTax: number[] = [];
  const nextMonthWithTax: number[] = [];
  const twoMonthLaterWithTax: number[] = [];

  // 今月
  value.map((data: number, dataIndex: number) => {
    if (dataIndex === 0 || dataIndex === 1) {
      return;
    } else {
      const newData = ((data / unit) * rate.this_month) / 100;
      thisMonth.push(newData);
      thisMonthWithTax.push(newData * tax);
    }
  });

  // 翌月
  value.map((data: number, dataIndex: number) => {
    if (dataIndex === 0 || value.length - 1 === dataIndex) {
      return;
    } else {
      const newData = ((data / unit) * rate.next_month) / 100;
      nextMonth.push(newData);
      nextMonthWithTax.push(newData * tax);
    }
  });

  // 翌々月
  value.map((data: number, dataIndex: number) => {
    if (value.length - 2 === dataIndex || value.length - 1 === dataIndex) {
      return;
    } else {
      const newData = ((data / unit) * rate.two_month_later) / 100;
      twoMonthLater.push(newData);
      twoMonthLaterWithTax.push(newData * tax);
    }
  });

  // 計
  const monthlyTotal: number[] = add(
    thisMonthWithTax,
    add(nextMonthWithTax, twoMonthLaterWithTax)
  ) as number[];

  return {
    thisMonth,
    nextMonth,
    twoMonthLater,
    thisMonthTotal: sum(thisMonth) as number,
    nextMonthTotal: sum(nextMonth) as number,
    twoMonthLaterTotal: sum(twoMonthLater) as number,
    thisMonthWithTax,
    nextMonthWithTax,
    twoMonthLaterWithTax,
    thisMonthTotalWithTax: sum(thisMonthWithTax) as number,
    nextMonthTotalWithTax: sum(nextMonthWithTax) as number,
    twoMonthLaterTotalWithTax: sum(twoMonthLaterWithTax) as number,
    monthlyTotal,
    monthlyTotalTotal: sum(monthlyTotal) as number,
  };
};
