import { atom } from "recoil";

// Firebaseのデータが更新されるトリガーの役割
export const switchState = atom<boolean>({
  key: "switch",
  default: true,
});

export const switchAfterGetDataState = atom<boolean>({
  key: "switch_after_get_data_state",
  default: true,
});

// ローディング
export const switchLoadingState = atom<boolean>({
  key: "switch_loading_state",
  default: false,
});

// サイドバーのオンオフ
export const toggleStore = atom<boolean>({
  key: "stoggle_state",
  default: true,
});
