import {
  addDoc,
  collection,
  doc,
  DocumentData,
  setDoc,
} from "firebase/firestore";
import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useRecoilState } from "recoil";
import { TextInput } from "../../components/initialSettings";
import { firebaseFields } from "../../firebase";
import { useSubjectItemsInitialData } from "../../hooks/initialData";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import {
  createModalIsOpenStore,
  createProfitAndLossStatementStore,
} from "../../store";

interface Props {
  tab: number;
  data: DocumentData[];
  headings: DocumentData[];
}

export const CreateAccountModal: React.FC<Props> = ({
  tab,
  data,
  headings,
}) => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const [createAccountTitleState, setCreateAccountTitleState] = useRecoilState(
    createProfitAndLossStatementStore
  );
  const [createModalIsOpen, setCreateModalIsOpen] = useRecoilState(
    createModalIsOpenStore
  );

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  // 登録ボタン
  const handleCreate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (createAccountTitleState.name === null) {
      toast.warning("勘定科目を入力してください");
      return;
    }

    const tablesRef = collection(firestore, "accounts", uid, "tables");

    await addDoc(tablesRef, {
      field:
        createAccountTitleState.field !== null
          ? createAccountTitleState.field
          : headings[0].field,
      item: createAccountTitleState.name,
      section: createAccountTitleState.section
        ? createAccountTitleState.section
        : null,
      is_non_cash_out: createAccountTitleState.isNonCashOut ?? false,
      results_input: {
        [resultsAndPlansDataState.headers.results_input[0].period]: 0,
        [resultsAndPlansDataState.headers.results_input[1].period]: 0,
        [resultsAndPlansDataState.headers.results_input[2].period]: 0,
      },
      plans_input: {
        [resultsAndPlansDataState.headers.plans_input[0].period]: 0,
        [resultsAndPlansDataState.headers.plans_input[1].period]: 0,
        [resultsAndPlansDataState.headers.plans_input[2].period]: 0,
        [resultsAndPlansDataState.headers.plans_input[3].period]: 0,
        [resultsAndPlansDataState.headers.plans_input[4].period]: 0,
      },
      plans_input_monthly: {
        [resultsAndPlansDataState.headers.plans_input_monthly[0].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[1].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[2].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[3].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[4].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[5].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[6].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[7].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[8].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[9].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[10].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[11].period]: 0,
      },
      results_of_the_previous_period: {
        [resultsAndPlansDataState.headers.results_of_the_previous_period[0]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[1]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[2]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[3]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[4]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[5]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[6]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[7]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[8]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[9]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[10]
          .period]: 0,
        [resultsAndPlansDataState.headers.results_of_the_previous_period[11]
          .period]: 0,
      },
      results_of_the_current_term: {
        [resultsAndPlansDataState.headers.plans_input_monthly[0].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[1].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[2].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[3].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[4].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[5].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[6].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[7].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[8].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[9].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[10].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[11].period]: 0,
      },
      plans_input_estimated_in_this_term: {
        [resultsAndPlansDataState.headers.plans_input_monthly[0].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[1].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[2].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[3].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[4].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[5].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[6].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[7].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[8].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[9].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[10].period]: 0,
        [resultsAndPlansDataState.headers.plans_input_monthly[11].period]: 0,
      },
      current_and_previous_year_comparisons: { fixed: null },
      order: data.length + 100,
      descriptions: {},
      isDefault: false,
      createdAt: new Date(),
    })
      .then(() => {
        if (createAccountTitleState.field === "net_sales") {
          const tableRef = doc(
            firestore,
            firebaseFields.accounts,
            String(uid),
            firebaseFields.collections,
            firebaseFields.headersId
          );
          setDoc(tableRef, { isDashboard: true }, { merge: true })
            .then(() => {
              toast.success("勘定科目を追加しました");
              setCreateAccountTitleState({
                name: null,
                field: null,
                section: null,
              });
              setCreateModalIsOpen(false);
            })
            .catch((error) => {
              toast.error("追加できませんでした");
              throw new Error(error.message);
            });
        } else {
          toast.success("勘定科目を追加しました");
          setCreateAccountTitleState({
            name: null,
            field: null,
            section: null,
          });
          setCreateModalIsOpen(false);
        }
      })
      .catch((error) => {
        toast.error("追加できませんでした");
        throw new Error(error.message);
      });
  };

  // キャンセルボタンの処理
  const handleCloseModal = () => {
    setCreateModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={createModalIsOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 49,
        },
        content: {
          position: "relative",
          width: "50%",
          maxWidth: "30rem",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <button
        className="absolute right-20px top-20px font-bold hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset "
        onClick={handleCloseModal}
        type="button"
      >
        <span className="sr-only">Close menu</span>
        <svg
          aria-hidden="true"
          className="h-20px w-20px"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6l12 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </button>
      <div className="flex justify-center flex-col px-20px">
        <h3 className="text-24px font-bold text-center" id="modal-title">
          勘定科目登録
        </h3>
        <div>
          <div className="mt-20px">
            <div>勘定科目名</div>
            <select
              className="mt-4px w-full"
              onChange={(e) =>
                setCreateAccountTitleState({
                  ...createAccountTitleState,
                  field: e.target.value,
                })
              }
            >
              {headings.map((heading, index) => (
                <option key={index} value={heading.field}>
                  {heading.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-20px">
            <div>勘定科目細目</div>
            <div className="mt-4px">
              <TextInput item={null} />
            </div>
          </div>
          <div className="mt-20px">
            <div>固定変動区分</div>
            <select
              className="mt-4px w-full"
              defaultValue={createAccountTitleState.section}
              onChange={(e) =>
                setCreateAccountTitleState({
                  ...createAccountTitleState,
                  section: e.target.value,
                })
              }
            >
              <option value={""}></option>
              <option value={"固定"}>固定</option>
              <option value={"変動"}>変動</option>
            </select>
          </div>
          {tab === 1 && (
            <div className="mt-20px">
              <label className="flex items-center">
                <input
                  checked={createAccountTitleState.isCashOut}
                  className="mr-16px w-20px h-20px"
                  onChange={(e) =>
                    setCreateAccountTitleState({
                      ...createAccountTitleState,
                      isNonCashOut: e.target.checked,
                    })
                  }
                  type="checkbox"
                />
                <span className="leading-5">
                  キャッシュアウトしない費用の場合、 <br />
                  チェックを入れてください
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="px-16px py-16px flex justify-center gap-50px">
        <button
          className="relative mt-12px px-12px py-6px text-c427ed1 border border-c427ed1 rounded"
          onClick={handleCreate}
          type="button"
        >
          登録
        </button>
        <button
          className="relative mt-12px px-12px py-6px text-c427ed1 border border-c427ed1 rounded"
          onClick={handleCloseModal}
          type="button"
        >
          戻る
        </button>
      </div>
    </Modal>
  );
};
