import { DocumentData } from "firebase/firestore";
import * as React from "react";
import { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import {
  useProfitAndLossPlanInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CostProfitAndLossPlanBody,
  CostProfitAndLossPlanGrandTotal,
  CostProfitAndLossPlanTotal,
  ProfitAndLossPlanBody,
  ProfitAndLossPlanBodyForCostOfSales,
  ProfitAndLossPlanGrandTotal,
  ProfitAndLossPlanTableBlank,
  ProfitAndLossPlanTotal,
} from "../../../lib/datasheets/profitAndLossPlan";
import { GridElement } from "../../../types/gridElement";
import { Spinner } from "../../molecules";
import { ProfitAndLossPlanHeader } from "../profitAndLossPlan";
import { SalesPlanLayout } from "../salesPlan";

interface Props {
  url: string;
}

const ProfitAndLossPlanContainer: React.FC<Props> = ({ url }) => {
  const firestore = useFirestore();

  const profitAndLossPlanInitialData = useProfitAndLossPlanInitialData();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  if (!profitAndLossPlanInitialData) {
    return <Spinner />;
  }

  const { profitAndLossPlanState, profitAndLossPlanCalcState } =
    profitAndLossPlanInitialData;

  const grid = ProfitAndLossPlanTotal({
    title: "売上高",
    headers: profitAndLossPlanState.headers[url],
    rows: profitAndLossPlanState.net_sales_total_data,
    state: profitAndLossPlanCalcState.net_sales_total,
    descriptions: profitAndLossPlanState.descriptions,
    url: url,
    totalField: "net_sales_total",
    unit: unit,
  });
  grid.push(
    ...ProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      title: profitAndLossPlanState.sales_plan_items,
      states: profitAndLossPlanCalcState.net_sales_total.plans_by_item,
      rows: profitAndLossPlanState.net_sales_total_data,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "net_sales_total",
      unit: unit,
    }),
    ...ProfitAndLossPlanBodyForCostOfSales({
      headers: profitAndLossPlanState.headers[url],
      rows: [
        profitAndLossPlanState
          .inventory_of_work_in_process_at_the_beginning_of_the_period_data[0],
        profitAndLossPlanState.parchase_amount_data[0],
        profitAndLossPlanState.total_amount_data[0],
        profitAndLossPlanState
          .inventory_of_work_in_process_at_the_end_of_the_period_data[0],
      ],
      url: url,
      unit: unit,
    }),
    ...ProfitAndLossPlanTotal({
      title: "売上原価",
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.cost_of_sales_total_data,
      state: profitAndLossPlanCalcState.cost_of_sales_total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isEven: true,
      totalField: "cost_of_sales_total",
      unit: unit,
    }),
    ...ProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      title: profitAndLossPlanState.sales_plan_items,
      states: profitAndLossPlanCalcState.cost_of_sales_total.plans_by_item,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isEven: true,
      field: "cost_of_sales_total",
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "売上総利益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.gross_margin,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.personal_cost_data,
      states: profitAndLossPlanCalcState.personal_cost_total.by_item,
      url: url,
      isFullName: true,
      unit: unit,
    }),
    ...CostProfitAndLossPlanTotal({
      crossheadTotal: "人件費",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.personal_cost_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isEven: false,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.cost_of_equipment_data,
      states: profitAndLossPlanCalcState.cost_of_equipment_total.by_item,
      url: url,
      isFullName: true,
      unit: unit,
    }),
    ...CostProfitAndLossPlanTotal({
      crossheadTotal: "物件費",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.cost_of_equipment_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      isEven: false,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "販売費及び一般管理費",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.selling_and_administrative_total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isEven: true,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "営業利益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.operating_income,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isBody: true,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.non_operating_income_data,
      states: profitAndLossPlanCalcState.non_operating_income_total.by_item,
      url: url,
      isFullName: true,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      crossheadTotal: "営業外収益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.non_operating_income_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      isBody: true,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.non_operating_expenses_data,
      states: profitAndLossPlanCalcState.non_operating_expenses_total.by_item,
      url: url,
      isFullName: true,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      crossheadTotal: "営業外費用",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.non_operating_expenses_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      isEven: true,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "経常利益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.current_earnings,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      unit: unit,
    }),
    // TODO: 見直し
    ...CostProfitAndLossPlanBody({
      title: "特別利益",
      headers: profitAndLossPlanState.headers[url],
      rows: [profitAndLossPlanState.special_benefits_data[0]],
      states: [profitAndLossPlanCalcState.special_benefits_total.total],
      isWhite: true,
      url: url,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      title: "特別損失",
      headers: profitAndLossPlanState.headers[url],
      rows: [profitAndLossPlanState.special_losses_data[0]],
      states: [profitAndLossPlanCalcState.special_losses_total.total],
      isWhite: true,
      url: url,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "税引前当期純利益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.ebit,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      unit: unit,
    }),
    ...CostProfitAndLossPlanBody({
      crossheadTotal: "法人税等",
      headers: profitAndLossPlanState.headers[url],
      rows: profitAndLossPlanState.corporate_inhabitant_data,
      states: profitAndLossPlanCalcState.corporate_inhabitant_total.by_item,
      isWhite: true,
      url: url,
      unit: unit,
    }),
    ...ProfitAndLossPlanGrandTotal({
      title: "当期純利益",
      headers: profitAndLossPlanState.headers[url],
      state: profitAndLossPlanCalcState.net_income,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      isCrossheadTotalBottom: true,
      unit: unit,
    }),
    ...ProfitAndLossPlanTableBlank({
      headers: profitAndLossPlanState.headers[url],
      bottomLine: true,
    }),
    ...CostProfitAndLossPlanGrandTotal({
      title: "減価償却費",
      headers: profitAndLossPlanState.headers[url],
      row: profitAndLossPlanState.depreciation_cost_data[0],
      states: profitAndLossPlanCalcState.depreciation_cost_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "depreciationCost",
      unit: unit,
    }),
    ...CostProfitAndLossPlanGrandTotal({
      title: "借入金返済額",
      headers: profitAndLossPlanState.headers[url],
      row: profitAndLossPlanState.pay_back_loan_data[0],
      states: profitAndLossPlanCalcState.pay_back_loan_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "loanRepayment",
      unit: unit,
    }),
    ...CostProfitAndLossPlanGrandTotal({
      title: "営業利益＋返済＋償却",
      headers: profitAndLossPlanState.headers[url],
      states: profitAndLossPlanCalcState.loan_total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "operatingIncome",
      unit: unit,
    }),
    ...ProfitAndLossPlanTableBlank({
      headers: profitAndLossPlanState.headers[url],
      bottomLine: true,
    }),
    ...CostProfitAndLossPlanGrandTotal({
      title: "借入金残高",
      headers: profitAndLossPlanState.headers[url],
      row: profitAndLossPlanState.balance_of_loan_data[0],
      states: profitAndLossPlanCalcState.balance_of_loan_total.total,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "borrowedMoneyResidualHigh",
      unit: unit,
    }),
    ...ProfitAndLossPlanTableBlank({
      headers: profitAndLossPlanState.headers[url],
      bottomLine: true,
    }),
    ...CostProfitAndLossPlanGrandTotal({
      title: "給与支給総額",
      headers: profitAndLossPlanState.headers[url],
      states: profitAndLossPlanCalcState.gross_personal_cost,
      descriptions: profitAndLossPlanState.descriptions,
      url: url,
      field: "totalAmountGiven",
      unit: unit,
    }),
    ...ProfitAndLossPlanTableBlank({
      headers: profitAndLossPlanState.headers[url],
      bottomLine: false,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <SalesPlanLayout>
      <ReactDataSheet
        data={grid}
        dataRenderer={(cell) => cell.expr}
        onCellsChanged={HandleCellChanged}
        sheetRenderer={(props: {
          className: string | undefined;
          children: React.ReactElement<
            DocumentData,
            string | React.JSXElementConstructor<DocumentData>
          >;
        }) => (
          <table className={`${props.className}`}>
            <ProfitAndLossPlanHeader
              headers={profitAndLossPlanState.headers[url]}
            />
            <tbody className="text-14px">{props.children}</tbody>
          </table>
        )}
        valueRenderer={(cell) => cell.value}
      />
    </SalesPlanLayout>
  );
};

export default memo(ProfitAndLossPlanContainer);
