import * as React from "react";
import { useRecoilValue } from "recoil";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { toggleStore } from "../../../store/switchState";
import {
  CurrentRatio,
  FixedAssetsTurnover,
  FixedRatio,
  GrossCapitalTurnover,
  GrossMarginRatio,
  InterestBearingDebtRatio,
  NetProfitRatio,
  NetSalesAndDebt,
  NetSalesAndOperatingIncome,
  NetSalesAndOrdinaryIncome,
  NetSalesDebtPropertyPlantAndEquipment,
  NetSalesOperatingIncomeEtc,
  NetSalesPerEmployeePerMonth,
  NetSalesPerEmployeePerYear,
  PawnRatio,
  PayablesTurnover,
  PercentageIncreaseInCurrentEarnings,
  PercentageIncreaseInNetSales,
  ReceivablesTurnover,
  Roa,
  Roe,
  SalesByBusinessSegment,
  SalesPerPersonPerMonth,
  SoundnessOfFinancialBase,
  TotalCostPerPersonPerMonth,
} from "../managementDashboard";
import { InventoryTurnover } from "../managementDashboard/InventoryTurnover";

export const ManagementDashboardContainer = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const toggle = useRecoilValue(toggleStore);

  const checkKpis = resultsAndPlansDataState.headers?.show_kpi;

  return (
    <div
      className={`fixed  ${
        toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
      }  h-[calc(100vh-140px)] top-0 overflow-x-scroll mt-[100px] p-20px bg-c2a4b770d z-30`}
    >
      <div className="flex flex-col">
        <div className="w-full flex-grow scroll-x">
          {checkKpis?.kpi101 && <NetSalesAndDebt />}
          {checkKpis?.kpi102 && <NetSalesDebtPropertyPlantAndEquipment />}
          {checkKpis?.kpi103 && <NetSalesAndOperatingIncome />}
          {checkKpis?.kpi104 && <SoundnessOfFinancialBase />}
          {checkKpis?.kpi105 && <NetSalesAndOrdinaryIncome />}
          {checkKpis?.kpi106 && <NetSalesOperatingIncomeEtc />}
          {checkKpis?.kpi107 && <SalesByBusinessSegment />}
          {/* ここからオプション */}
          {checkKpis?.kpi201 && <CurrentRatio />}
          {checkKpis?.kpi202 && <PawnRatio />}
          {checkKpis?.kpi203 && <FixedRatio />}
          {checkKpis?.kpi204 && <InterestBearingDebtRatio />}
          {checkKpis?.kpi301 && <ReceivablesTurnover />}
          {checkKpis?.kpi302 && <InventoryTurnover />}
          {checkKpis?.kpi303 && <FixedAssetsTurnover />}
          {checkKpis?.kpi304 && <GrossCapitalTurnover />}
          {checkKpis?.kpi305 && <PayablesTurnover />}
          {checkKpis?.kpi401 && <GrossMarginRatio />}
          {checkKpis?.kpi402 && <NetProfitRatio />}
          {checkKpis?.kpi403 && <Roe />}
          {checkKpis?.kpi404 && <Roa />}
          {checkKpis?.kpi501 && <PercentageIncreaseInNetSales />}
          {checkKpis?.kpi502 && <PercentageIncreaseInCurrentEarnings />}
          {checkKpis?.kpi503 && <SalesPerPersonPerMonth />}
          {checkKpis?.kpi504 && <TotalCostPerPersonPerMonth />}
          {checkKpis?.kpi505 && <NetSalesPerEmployeePerMonth />}
          {checkKpis?.kpi506 && <NetSalesPerEmployeePerYear />}
        </div>
      </div>
    </div>
  );
};
