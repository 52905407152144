import { DocumentData } from "firebase/firestore";
import {
  add,
  dotDivide,
  subtract,
  sum,
  dotMultiply,
  divide,
  cumsum,
} from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import {
  arrayRatioCalcWithoutRound,
  ratioCalcForPlansInputMonthly,
  totalCalc,
  totalCalcByItem,
  totalRatioCalcForPlansInputMonthly,
} from "../../utils/calc";
import { totalCalcForEstimatedInThisTerm } from "../../utils/calc/estimatedInThisTerm";
import { totalCalcByItemForPlansInputMonthly } from "../../utils/calc/totalCalcByItemForPlansInputMonthly";

export const useEstimatedInThisTermInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    depreciation_cost_of_cost_of_sales,
    non_cash_out,
    interest_expenses,
  } = useSubjectItemsInitialData();

  const resultsField = pageConstant.PLANS_INPUT_MONTHLY;
  const plansField = pageConstant.PLANS_INPUT;
  const previousField = pageConstant.PLANS_INPUT_ESTIMATED_IN_THIS_TERM;

  try {
    const resultsCount = headers.results_of_the_current_term_count?.filter(
      function (x: boolean) {
        return x;
      }
    ).length;

    // ゼロの配列
    const zeroArray: number[] = [];
    headers[previousField].map(() => {
      zeroArray.push(0);
    });

    // 売上高合計
    const netSalesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: net_sales,
    }) as number[];

    const netSalesTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly({
      headers: headers[plansField],
      field: plansField,
      state: net_sales,
    }) as number[];
    const netSalesTotalByItemRemining = subtract(
      netSalesTotalByItemPlansInput,
      netSalesTotalByItemCurrentTerm
    ) as number[];
    // 月別売上比率
    const netSalesTotalByItemPreviousRatio = ratioCalcForPlansInputMonthly({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
    });
    const netSalesPreviousTotal: number[] = totalCalc({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
    });

    // 売上高合計構成比率
    const netSalesTotalPreviousRatio: number[] =
      totalRatioCalcForPlansInputMonthly(netSalesPreviousTotal);

    const netSalesTotalByItemCurrentTermTotal = sum(
      netSalesTotalByItemCurrentTerm
    ) as number;
    const netSalesTotalByItemPlansInputTotal = sum(
      netSalesTotalByItemPlansInput
    ) as number;

    const netSalesTotalByItemReminingTotal = sum(
      netSalesTotalByItemRemining
    ) as number;

    const netSalesForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: net_sales,
      resultsCount: resultsCount,
    });

    const netSalesmonthlyRatio = dotDivide(
      netSalesForBudgetControl.monthlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number[];

    const netSalesYearlyRatio = divide(
      netSalesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const netSalesTotal = {
      by_item: netSalesForBudgetControl.byItem,
      by_item_total: netSalesForBudgetControl.byItemTotal,
      monthly_total: netSalesForBudgetControl.monthlyTotal,
      monthly_ratio: netSalesmonthlyRatio,
      yearly_total: netSalesForBudgetControl.yearlyTotal,
      yearly_ratio: netSalesYearlyRatio,
    };

    const netSalesPreviousRatioCurrentTerm = dotDivide(
      netSalesTotalByItemCurrentTerm,
      netSalesTotalByItemPlansInput
    );
    const netSalesPreviousRatioCurrentTermTotal = dotDivide(
      netSalesTotalByItemCurrentTermTotal,
      netSalesTotalByItemPlansInputTotal
    );

    // 月別売上比率
    const netSalesPreviousRatio = {
      current_term: netSalesPreviousRatioCurrentTerm as number[],
      current_term_total: netSalesPreviousRatioCurrentTermTotal as number,
      by_item_ratio: netSalesTotalByItemPreviousRatio.ratioData as number[][],
      by_item_ratio_total:
        netSalesTotalByItemPreviousRatio.totalRatioData as number[],
      monthly_ratio: netSalesTotalPreviousRatio as number[],
      monthly_total_ratio: sum(netSalesTotalPreviousRatio) as number,
    };

    // 売上原価合計
    const costOfSalesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales,
    });
    costOfSalesTotalByItemCurrentTerm[0] = 0;
    costOfSalesTotalByItemCurrentTerm[
      costOfSalesTotalByItemCurrentTerm.length - 1
    ] = 0;

    // 計画（年次）の当期商品仕入れ高
    const netSalesCostRatioFunc = () => {
      const costRatioArray: number[][] = [];
      net_sales.map((netSale) => {
        const costRatioSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input_cost_ratio &&
            netSale.plans_input_cost_ratio[header.period] &&
            netSale.plans_input_cost_ratio[header.period] !== null
              ? netSale.plans_input_cost_ratio[header.period]
              : 0;

          costRatioSingleArray.push(data);
        });

        costRatioArray.push(costRatioSingleArray);
      });
      return costRatioArray;
    };
    const netSalesCostRatio = netSalesCostRatioFunc();

    const NetSalesSinglesFunc = () => {
      const netSalesArray: number[][] = [];
      net_sales.map((netSale) => {
        const netSalesSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input &&
            netSale.plans_input[header.period] &&
            netSale.plans_input[header.period] !== null
              ? netSale.plans_input[header.period]
              : 0;
          netSalesSingleArray.push(data);
        });

        netSalesArray.push(netSalesSingleArray);
      });
      return netSalesArray;
    };
    const netSalesSingles = NetSalesSinglesFunc();

    const netSalesMultipleCost =
      netSalesCostRatio[0][0] !== null
        ? (divide(
            dotMultiply(netSalesCostRatio, netSalesSingles),
            100
          ) as number[][])
        : null;

    const costOfPurchasedGoodsFunc = () => {
      let array: number[] = [];
      netSalesMultipleCost &&
        netSalesMultipleCost !== null &&
        netSalesMultipleCost.map((data, index) => {
          index === 0 ? (array = data) : (array = add(data, array));
        });
      return array;
    };

    const costOfSalesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: cost_of_sales,
      });
    costOfSalesTotalByItemPlansInput[0] = 0;
    costOfSalesTotalByItemPlansInput[1] = costOfPurchasedGoodsFunc()[0]
      ? costOfPurchasedGoodsFunc()[0]
      : 0;
    costOfSalesTotalByItemPlansInput[
      costOfSalesTotalByItemPlansInput.length - 1
    ] = 0;

    const costOfSalesTotalByItemRemining = subtract(
      costOfSalesTotalByItemPlansInput,
      costOfSalesTotalByItemCurrentTerm
    );

    const costOfSalesByItem = dotMultiply(
      sum(costOfSalesTotalByItemRemining),
      dotDivide(
        netSalesForBudgetControl.monthlyTotal,
        sum(netSalesTotalByItemRemining)
      )
    ) as number[];

    const costOfSalesTotalByItemPlansInputYearly = subtract(
      costOfSalesTotalByItemPlansInput,
      sum(costOfSalesByItem)
    );
    costOfSalesTotalByItemPlansInputYearly[0] = 0;
    costOfSalesTotalByItemPlansInputYearly[
      costOfSalesTotalByItemPlansInputYearly.length - 1
    ] = 0;

    // 月間売上高合計/売上高残総合計
    const costOfSalesDenominator = dotDivide(
      netSalesForBudgetControl.monthlyTotal,
      netSalesTotalByItemReminingTotal
    ) as number[];

    // 売上原価、個別の値 残個別合計x(月間売上高合計/売上高残総合計)
    const costOfSalesPlanByItem: number[][] = [];
    const costOfSalesPlanByItemCumsum: number[][] = [];
    const costOfSalesMonthlyTotalByItem: number[] = [];
    costOfSalesTotalByItemRemining.map((totalData, index) => {
      if (index === 0 || costOfSalesTotalByItemRemining.length === index + 1) {
        costOfSalesPlanByItem.push(zeroArray);
        costOfSalesMonthlyTotalByItem.push(0);
        costOfSalesPlanByItemCumsum.push(zeroArray);
      } else {
        const costOfSalesPlanByItemData = dotMultiply(
          totalData,
          costOfSalesDenominator
        ) as number[];
        costOfSalesPlanByItem.push(costOfSalesPlanByItemData);
        costOfSalesPlanByItemCumsum.push(
          cumsum(dotMultiply(totalData, costOfSalesDenominator)) as number[]
        );

        costOfSalesMonthlyTotalByItem.push(
          sum(costOfSalesPlanByItemData) as number
        );
      }
    });

    const costOfSalesForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: cost_of_sales,
      isCostOfSales: true,
      resultsCount: resultsCount,
    });

    const costOfSalesMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            costOfSalesForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : costOfSalesForBudgetControl.monthlyTotal;

    const costOfSalesYearlyRatio = divide(
      costOfSalesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const costOfSalesTotal = {
      by_item: costOfSalesForBudgetControl.byItem,
      by_item_total: costOfSalesForBudgetControl.byItemTotal,
      monthly_total: costOfSalesForBudgetControl.monthlyTotal,
      monthly_ratio: costOfSalesMonthlyRatio,
      yearly_total: costOfSalesForBudgetControl.yearlyTotal,
      yearly_ratio: costOfSalesYearlyRatio,
    };

    // 売上総利益

    const grossMarginMonthlyTotal =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (subtract(
            netSalesForBudgetControl.monthlyTotal,
            costOfSalesForBudgetControl.monthlyTotal
          ) as number[])
        : costOfSalesForBudgetControl.monthlyTotal;
    const grossMarginMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            grossMarginMonthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : grossMarginMonthlyTotal;

    const grossMarginYearlyTotal = subtract(
      netSalesForBudgetControl.yearlyTotal,
      costOfSalesForBudgetControl.yearlyTotal
    ) as number;
    const grossMarginYearlyRatio = dotDivide(
      costOfSalesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const grossMargin = {
      monthly_total: grossMarginMonthlyTotal,
      monthly_ratio: grossMarginMonthlyRatio,
      yearly_total: grossMarginYearlyTotal,
      yearly_ratio: grossMarginYearlyRatio,
    };

    // 人件費合計
    const personalCostForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: personal_cost,
      resultsCount: resultsCount,
    });

    const personalCostMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            personalCostForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : personalCostForBudgetControl.monthlyTotal;

    const personalCostYearlyRatio = divide(
      personalCostForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const personalCostTotal = {
      by_item: personalCostForBudgetControl.byItem,
      by_item_total: personalCostForBudgetControl.byItemTotal,
      monthly_total: personalCostForBudgetControl.monthlyTotal,
      monthly_ratio: personalCostMonthlyRatio,
      yearly_total: personalCostForBudgetControl.yearlyTotal,
      yearly_ratio: personalCostYearlyRatio,
    };

    // 販売費及び一般管理費合計
    const sellingAndAdministrativeForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: selling_and_administrative,
        resultsCount: resultsCount,
      });

    const sellingAndAdministrativeMonthlyTotal = add(
      personalCostForBudgetControl.monthlyTotal,
      sellingAndAdministrativeForBudgetControl.monthlyTotal
    ) as number[];
    const sellingAndAdministrativeMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            sellingAndAdministrativeMonthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : sellingAndAdministrativeMonthlyTotal;

    const sellingAndAdministrativeYearlyTotal = add(
      personalCostForBudgetControl.yearlyTotal,
      sellingAndAdministrativeForBudgetControl.yearlyTotal
    ) as number;
    const sellingAndAdministrativeYearlyRatio = divide(
      sellingAndAdministrativeYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const sellingAndAdministrativeTotal = {
      by_item: sellingAndAdministrativeForBudgetControl.byItem,
      by_item_total: sellingAndAdministrativeForBudgetControl.byItemTotal,
      monthly_total: sellingAndAdministrativeForBudgetControl.monthlyTotal,
      monthly_ratio: sellingAndAdministrativeMonthlyRatio,
      yearly_total: sellingAndAdministrativeForBudgetControl.yearlyTotal,
      yearly_ratio: sellingAndAdministrativeYearlyRatio,
    };

    // 営業利益
    const operatingIncomeMonthlyTotal: number[] =
      grossMarginMonthlyTotal.length !== 0
        ? subtract(
            grossMarginMonthlyTotal,
            sellingAndAdministrativeForBudgetControl.monthlyTotal
          )
        : grossMarginMonthlyTotal;

    const operatingIncomeMonthlyRatio: number[] =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: operatingIncomeMonthlyTotal,
            denominator: netSalesForBudgetControl.monthlyTotal,
          })
        : operatingIncomeMonthlyTotal;

    const operatingIncomeYearlyTotal = sum(operatingIncomeMonthlyTotal);

    const operatingIncomeYearlyRatio = dotDivide(
      operatingIncomeYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const operatingIncome = {
      monthly_total: operatingIncomeMonthlyTotal,
      monthly_ratio: operatingIncomeMonthlyRatio,
      yearly_total: operatingIncomeYearlyTotal,
      yearly_ratio: operatingIncomeYearlyRatio,
    };

    // 営業外収益合計
    const nonOperatingIncomeForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: non_operating_income,
      resultsCount: resultsCount,
    });

    const nonOperatingIncomeMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingIncomeForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : nonOperatingIncomeForBudgetControl.monthlyTotal;

    const nonOperatingIncomeYearlyRatio = divide(
      nonOperatingIncomeForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const nonOperatingIncomeTotal = {
      by_item: nonOperatingIncomeForBudgetControl.byItem,
      by_item_total: nonOperatingIncomeForBudgetControl.byItemTotal,
      monthly_total: nonOperatingIncomeForBudgetControl.monthlyTotal,
      monthly_ratio: nonOperatingIncomeMonthlyRatio,
      yearly_total: nonOperatingIncomeForBudgetControl.yearlyTotal,
      yearly_ratio: nonOperatingIncomeYearlyRatio,
    };

    // 営業外費用合計
    const nonOperatingExpensesForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: non_operating_expenses,
        resultsCount: resultsCount,
      });

    const nonOperatingExpensesMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            nonOperatingExpensesForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : nonOperatingExpensesForBudgetControl.monthlyTotal;

    const nonOperatingExpensesYearlyRatio = divide(
      nonOperatingExpensesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const nonOperatingExpensesTotal = {
      by_item: nonOperatingExpensesForBudgetControl.byItem,
      by_item_total: nonOperatingExpensesForBudgetControl.byItemTotal,
      monthly_total: nonOperatingExpensesForBudgetControl.monthlyTotal,
      monthly_ratio: nonOperatingExpensesMonthlyRatio,
      yearly_total: nonOperatingExpensesForBudgetControl.yearlyTotal,
      yearly_ratio: nonOperatingExpensesYearlyRatio,
    };

    // 経常利益
    const currentEarningsMonthlyTotal: number[] = subtract(
      operatingIncomeMonthlyTotal.length !== 0
        ? add(
            operatingIncomeMonthlyTotal,
            nonOperatingIncomeForBudgetControl.monthlyTotal
          )
        : nonOperatingIncomeForBudgetControl.monthlyTotal,
      nonOperatingExpensesForBudgetControl.monthlyTotal
    );
    const currentEarningsMonthlyRatio: number[] =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: currentEarningsMonthlyTotal,
            denominator: netSalesForBudgetControl.monthlyTotal,
          })
        : currentEarningsMonthlyTotal;

    const currentEarningsYearlyTotal = sum(currentEarningsMonthlyTotal);

    const currentEarningsYearlyRatio = dotDivide(
      currentEarningsYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const currentEarnings = {
      monthly_total: currentEarningsMonthlyTotal,
      monthly_ratio: currentEarningsMonthlyRatio,
      yearly_total: currentEarningsYearlyTotal,
      yearly_ratio: currentEarningsYearlyRatio,
    };

    // 特別利益合計
    const specialBenefitsForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: special_benefits,
      resultsCount: resultsCount,
    });

    const specialBenefitsMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            specialBenefitsForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : specialBenefitsForBudgetControl.monthlyTotal;

    const specialBenefitsYearlyRatio = divide(
      specialBenefitsForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const specialBenefitsTotal = {
      by_item: specialBenefitsForBudgetControl.byItem,
      by_item_total: specialBenefitsForBudgetControl.byItemTotal,
      monthly_total: specialBenefitsForBudgetControl.monthlyTotal,
      monthly_ratio: specialBenefitsMonthlyRatio,
      yearly_total: specialBenefitsForBudgetControl.yearlyTotal,
      yearly_ratio: specialBenefitsYearlyRatio,
    };

    // 特別損失合計
    const specialLossesForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: special_losses,
      resultsCount: resultsCount,
    });

    const specialLossesMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            specialLossesForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : specialLossesForBudgetControl.monthlyTotal;

    const specialLossesYearlyRatio = divide(
      specialLossesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const specialLossesTotal = {
      by_item: specialLossesForBudgetControl.byItem,
      by_item_total: specialLossesForBudgetControl.byItemTotal,
      monthly_total: specialLossesForBudgetControl.monthlyTotal,
      monthly_ratio: specialLossesMonthlyRatio,
      yearly_total: specialLossesForBudgetControl.yearlyTotal,
      yearly_ratio: specialLossesYearlyRatio,
    };

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: special_corporate_inhabitant_and_enterprise_taxeslosses,
        resultsCount: resultsCount,
      });

    const corporateInhabitantAndEnterpriseTaxesTotal = {
      by_item: corporateInhabitantAndEnterpriseTaxesForBudgetControl.byItem[0],
      by_item_total:
        corporateInhabitantAndEnterpriseTaxesForBudgetControl.byItemTotal,
      monthly_total: null,
      monthly_ratio: null,
      yearly_total: null,
      yearly_ratio: null,
    };

    // 材料費合計
    const materialFeeForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: material_fee,
      isMaterialFee: true,
      resultsCount: resultsCount,
    });

    const materialFeeTotal = {
      by_item: materialFeeForBudgetControl.byItem,
      by_item_total: materialFeeForBudgetControl.byItemTotal,
      monthly_total: materialFeeForBudgetControl.monthlyTotal,
      yearly_total: materialFeeForBudgetControl.yearlyTotal,
    };

    // 労務費合計
    const laborCostsForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: labor_costs,
      resultsCount: resultsCount,
    });

    const laborCostsTotal = {
      by_item: laborCostsForBudgetControl.byItem,
      by_item_total: laborCostsForBudgetControl.byItemTotal,
      monthly_total: laborCostsForBudgetControl.monthlyTotal,
      yearly_total: laborCostsForBudgetControl.yearlyTotal,
    };

    // 外注加工費合計
    const externalInjectionProcessingFeeForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: external_injection_processing_fee,
        resultsCount: resultsCount,
      });

    const externalInjectionProcessingFeeTotal = {
      by_item: externalInjectionProcessingFeeForBudgetControl.byItem,
      by_item_total: externalInjectionProcessingFeeForBudgetControl.byItemTotal,
      monthly_total:
        externalInjectionProcessingFeeForBudgetControl.monthlyTotal,
      yearly_total: externalInjectionProcessingFeeForBudgetControl.yearlyTotal,
    };

    // 経費合計
    const expensesForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: expenses,
      resultsCount: resultsCount,
    });

    const expensesTotal = {
      by_item: expensesForBudgetControl.byItem,
      by_item_total: expensesForBudgetControl.byItemTotal,
      monthly_total: expensesForBudgetControl.monthlyTotal,
      yearly_total: expensesForBudgetControl.yearlyTotal,
    };

    // 期首仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: inventory_of_work_in_process_at_the_beginning_of_the_period,
        resultsCount: resultsCount,
      });

    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal = {
      by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.byItem,
      by_item_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.byItemTotal,
      monthly_total: null,
      yearly_total: null,
    };

    // 期末仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm =
      totalCalcByItem({
        headers: headers[resultsField],
        field: resultsField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });
    inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm[0] = 0;
    inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm[
      inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemCurrentTerm.length -
        1
    ] = 0;

    const inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl =
      totalCalcForEstimatedInThisTerm({
        headers: headers,
        resultsField: resultsField,
        previousField: previousField,
        states: inventory_of_work_in_process_at_the_end_of_the_period,
        resultsCount: resultsCount,
      });

    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotal = {
      by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.byItem,
      by_item_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.byItemTotal,
      monthly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.monthlyTotal,
      yearly_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.yearlyTotal,
    };

    // 当期製造費用
    const grossManufacturingCostForThePeriodMonthlyTotal: number[] = add(
      materialFeeForBudgetControl.monthlyTotal,
      add(
        laborCostsForBudgetControl.monthlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.monthlyTotal,
          expensesForBudgetControl.monthlyTotal
        )
      )
    );

    const grossManufacturingCostForThePeriodYearlyTotal = add(
      materialFeeForBudgetControl.yearlyTotal,
      add(
        laborCostsForBudgetControl.yearlyTotal,
        add(
          externalInjectionProcessingFeeForBudgetControl.yearlyTotal,
          expensesForBudgetControl.yearlyTotal
        )
      )
    ) as number;

    const grossManufacturingCostForThePeriod = {
      monthly_total: grossManufacturingCostForThePeriodMonthlyTotal,
      monthly_ratio: null,
      yearly_total: grossManufacturingCostForThePeriodYearlyTotal,
      yearly_ratio: null,
    };

    // 当期製品製造原価
    const costOfProductsManufacturedMonthlyTotal = subtract(
      add(grossManufacturingCostForThePeriodMonthlyTotal, 0),
      0
    ) as number[];

    const costOfProductsManufacturedYearlyTotal = subtract(
      add(
        grossManufacturingCostForThePeriodYearlyTotal,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodForBudgetControl.yearlyTotal
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodForBudgetControl.yearlyTotal
    ) as number;

    const costOfProductsManufactured = {
      monthly_total: costOfProductsManufacturedMonthlyTotal,
      monthly_ratio: null,
      yearly_total: costOfProductsManufacturedYearlyTotal,
      yearly_ratio: null,
    };

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCostMonthlyTotal = add(
      sellingAndAdministrativeForBudgetControl.byItem[0],
      expensesForBudgetControl.byItem[0]
    );

    const depreciationCostYearlyTotal = add(
      sellingAndAdministrativeForBudgetControl.byItemTotal[0],
      expensesForBudgetControl.byItemTotal[0]
    );

    const depreciationCostTotal = {
      monthly_total: depreciationCostMonthlyTotal,
      yearly_total: depreciationCostYearlyTotal,
    };

    // 償却前営業利益
    const operatingProfitsBeforeAmortizationTotal: number[] =
      operatingIncomeMonthlyTotal.length !== 0
        ? add(operatingIncomeMonthlyTotal, depreciationCostMonthlyTotal)
        : depreciationCostMonthlyTotal;

    const operatingProfitsBeforeAmortizationRatio: number[] =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? arrayRatioCalcWithoutRound({
            numerator: operatingProfitsBeforeAmortizationTotal,
            denominator: netSalesForBudgetControl.monthlyTotal,
          })
        : operatingProfitsBeforeAmortizationTotal;

    const operatingProfitsBeforeAmortizationYearlyTotal = add(
      operatingIncomeYearlyTotal,
      depreciationCostYearlyTotal
    ) as number;

    const operatingProfitsBeforeAmortizationYearlyRatio = dotDivide(
      operatingProfitsBeforeAmortizationYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const operatingProfitsBeforeAmortization = {
      monthly_total: operatingProfitsBeforeAmortizationTotal,
      monthly_ratio: operatingProfitsBeforeAmortizationRatio,
      yearly_total: operatingProfitsBeforeAmortizationYearlyTotal,
      yearly_ratio: operatingProfitsBeforeAmortizationYearlyRatio,
    };

    // 償却前経常利益
    const ebitdaMonthlyTotal = add(
      currentEarningsMonthlyTotal,
      depreciationCostMonthlyTotal
    ) as number[];
    const ebitdaMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            ebitdaMonthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : ebitdaMonthlyTotal;

    const ebitdaYearlyTotal = add(
      currentEarningsYearlyTotal,
      depreciationCostYearlyTotal
    ) as number;
    const ebitdaYearlyRatio = dotDivide(
      ebitdaYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const ebitda = {
      monthly_total: ebitdaMonthlyTotal,
      monthly_ratio: ebitdaMonthlyRatio,
      yearly_total: ebitdaYearlyTotal,
      yearly_ratio: ebitdaYearlyRatio,
    };

    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebitMonthlyTotal = subtract(
      add(
        currentEarningsMonthlyTotal,
        specialBenefitsForBudgetControl.monthlyTotal
      ),
      specialLossesForBudgetControl.monthlyTotal
    ) as number[];
    const ebitMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            ebitMonthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : ebitMonthlyTotal;

    const ebitYearlyTotal = subtract(
      add(
        currentEarningsYearlyTotal,
        specialBenefitsForBudgetControl.yearlyTotal
      ),
      specialLossesForBudgetControl.yearlyTotal
    ) as number;
    const ebitYearlyRatio = divide(
      ebitYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const ebit = {
      monthly_total: ebitMonthlyTotal,
      monthly_ratio: ebitMonthlyRatio,
      yearly_total: ebitYearlyTotal,
      yearly_ratio: ebitYearlyRatio,
    };

    // 当期純利益
    const netIncomeMonthlyTotal =
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.monthlyTotal
        .length !== 0
        ? (subtract(
            ebitMonthlyTotal,
            corporateInhabitantAndEnterpriseTaxesForBudgetControl.monthlyTotal
          ) as number[])
        : ebitMonthlyTotal;
    const netIncomeMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            netIncomeMonthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : netIncomeMonthlyTotal;

    const netIncomeYearlyTotal = subtract(
      ebitYearlyTotal,
      corporateInhabitantAndEnterpriseTaxesForBudgetControl.yearlyTotal
    ) as number;
    const netIncomeYearlyRatio = divide(
      netIncomeYearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const netIncome = {
      monthly_total: netIncomeMonthlyTotal,
      monthly_ratio: netIncomeMonthlyRatio,
      yearly_total: netIncomeYearlyTotal,
      yearly_ratio: netIncomeYearlyRatio,
    };

    // 支払利息
    const interestExpensesForBudgetControl = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: interest_expenses,
      resultsCount: resultsCount,
    });

    // キャッシュアウトしない費用
    const nonCashOutForBudgetControl =
      non_cash_out.length === 0
        ? {
            monthlyTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            yearlyTotal: 0,
          }
        : totalCalcForEstimatedInThisTerm({
            headers: headers,
            resultsField: resultsField,
            previousField: previousField,
            states: non_cash_out,
            resultsCount: resultsCount,
          });

    // 簡易CF
    const simpleCfRow1MonthlyTotal = add(
      currentEarningsMonthlyTotal,
      add(depreciationCostMonthlyTotal, nonCashOutForBudgetControl.monthlyTotal)
    ) as number[];

    const simpleCfRow1YearlyTotal = add(
      currentEarningsYearlyTotal,
      add(depreciationCostYearlyTotal, nonCashOutForBudgetControl.yearlyTotal)
    ) as number;

    const simpleCfRow1Total = {
      monthly_total: simpleCfRow1MonthlyTotal,
      yearly_total: simpleCfRow1YearlyTotal,
    };

    const simpleCfRow2MonthlyTotal = add(
      netIncomeMonthlyTotal,
      add(depreciationCostMonthlyTotal, nonCashOutForBudgetControl.monthlyTotal)
    ) as number[];

    const simpleCfRow2YearlyTotal = add(
      netIncomeYearlyTotal,
      add(depreciationCostYearlyTotal, nonCashOutForBudgetControl.yearlyTotal)
    ) as number;

    const simpleCfRow2Total = {
      monthly_total: simpleCfRow2MonthlyTotal,
      yearly_total: simpleCfRow2YearlyTotal,
    };

    // 簡易CF 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息
    const simpleCfRow3MonthlyTotal = add(
      personalCostForBudgetControl.monthlyTotal,
      add(
        ebitMonthlyTotal,
        add(
          laborCostsForBudgetControl.monthlyTotal,
          add(
            depreciationCostMonthlyTotal,
            add(
              nonCashOutForBudgetControl.monthlyTotal,
              interestExpensesForBudgetControl.monthlyTotal
            )
          )
        )
      )
    ) as number[];

    const simpleCfRow3YearlyTotal = add(
      ebitYearlyTotal,
      add(
        personalCostForBudgetControl.yearlyTotal,
        add(
          laborCostsForBudgetControl.yearlyTotal,
          add(
            depreciationCostYearlyTotal,
            add(
              nonCashOutForBudgetControl.yearlyTotal,
              interestExpensesForBudgetControl.yearlyTotal
            )
          )
        )
      )
    ) as number;

    const simpleCfRow3Total = {
      monthly_total: simpleCfRow3MonthlyTotal,
      yearly_total: simpleCfRow3YearlyTotal,
    };

    // 売上構成比
    const saleCompositionRatioFunc = () => {
      const costRatioArray: number[][] = [];
      netSalesForBudgetControl.byItem.map((byItem) => {
        const netSalesRatio =
          netSalesForBudgetControl.monthlyTotal.length !== 0
            ? (dotDivide(
                byItem,
                netSalesForBudgetControl.monthlyTotal
              ) as number[])
            : byItem;

        costRatioArray.push(netSalesRatio);
      });
      return costRatioArray;
    };
    const saleCompositionMonthlyRatio = saleCompositionRatioFunc();
    const saleCompositionMonthlyRatioTotal = dotDivide(
      netSalesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number[];
    const salesCompositionByItemRatioTotal =
      netSalesForBudgetControl.byItemTotal.length !== 0
        ? (dotDivide(
            netSalesForBudgetControl.byItemTotal,
            sum(netSalesForBudgetControl.byItemTotal)
          ) as number[])
        : [];
    const saleCompositionMonthlyTotalRatio = divide(
      netSalesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const saleCompositionRatio = {
      by_item_ratio: saleCompositionMonthlyRatio,
      by_item_ratio_total: salesCompositionByItemRatioTotal,
      monthly_ratio: saleCompositionMonthlyTotalRatio,
      monthly_ratio_total: saleCompositionMonthlyRatioTotal,
    };

    // 原価構成比（当期製造費用）
    const grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatio =
      dotDivide(
        grossManufacturingCostForThePeriodMonthlyTotal,
        grossManufacturingCostForThePeriodMonthlyTotal
      ) as number[];

    const grossManufacturingCostForThePeriodPlansCostCompositionYearlyRatio =
      divide(
        grossManufacturingCostForThePeriodYearlyTotal,
        grossManufacturingCostForThePeriodYearlyTotal
      ) as number;

    const grossManufacturingCostForThePeriodPlansCostCompositionRatio = {
      monthly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionMonthlyRatio,
      yearly_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionYearlyRatio,
    };

    // 原価構成比（材料費）
    const materialFeeCostCompositionMonthlyRatio = dotDivide(
      materialFeeForBudgetControl.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];

    const materialFeeCostCompositionYearlyRatio = divide(
      materialFeeForBudgetControl.yearlyTotal,
      grossManufacturingCostForThePeriodYearlyTotal
    ) as number;

    const materialFeeCostCompositionRatio = {
      monthly_ratio: materialFeeCostCompositionMonthlyRatio,
      yearly_ratio: materialFeeCostCompositionYearlyRatio,
    };

    // 原価構成比（労務費合計）
    const laborCostsCostCompositionMonthlyRatio = dotDivide(
      laborCostsForBudgetControl.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];

    const laborCostsCostCompositionYearlyRatio = divide(
      laborCostsForBudgetControl.yearlyTotal,
      grossManufacturingCostForThePeriodYearlyTotal
    ) as number;

    const laborCostsCostCompositionRatio = {
      monthly_ratio: laborCostsCostCompositionMonthlyRatio,
      yearly_ratio: laborCostsCostCompositionYearlyRatio,
    };

    // 原価構成比（外注加工費合計）
    const externalInjectionProcessingFeeCostCompositionMonthlyRatio = dotDivide(
      externalInjectionProcessingFeeForBudgetControl.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];

    const externalInjectionProcessingFeeCostCompositionYearlyRatio = divide(
      externalInjectionProcessingFeeForBudgetControl.yearlyTotal,
      grossManufacturingCostForThePeriodYearlyTotal
    ) as number;

    const externalInjectionProcessingFeeCostCompositionRatio = {
      monthly_ratio: externalInjectionProcessingFeeCostCompositionMonthlyRatio,
      yearly_ratio: externalInjectionProcessingFeeCostCompositionYearlyRatio,
    };
    // 原価構成比（経費合計）
    const expensesCostCompositionMonthlyRatio = dotDivide(
      expensesForBudgetControl.monthlyTotal,
      grossManufacturingCostForThePeriodMonthlyTotal
    ) as number[];

    const expensesCostCompositionYearlyRatio = divide(
      expensesForBudgetControl.yearlyTotal,
      grossManufacturingCostForThePeriodYearlyTotal
    ) as number;

    const expensesCostCompositionRatio = {
      monthly_ratio: expensesCostCompositionMonthlyRatio,
      yearly_ratio: expensesCostCompositionYearlyRatio,
    };

    // 要素別原価率
    // 売上高材料費比率
    const netSalesMaterialFeeCostCompositionMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            materialFeeForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : materialFeeForBudgetControl.monthlyTotal;

    const netSalesMaterialFeeCostCompositionYearlyRatio = divide(
      materialFeeForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const netSalesMaterialFeeTotalRatio = {
      monthly_ratio: netSalesMaterialFeeCostCompositionMonthlyRatio,
      yearly_ratio: netSalesMaterialFeeCostCompositionYearlyRatio,
    };

    // 売上高労務費比率
    const netSalesLaborCostsCostCompositionMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            laborCostsForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : laborCostsForBudgetControl.monthlyTotal;

    const netSalesLaborCostsCostCompositionYearlyRatio = divide(
      laborCostsForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const netSalesLaborCostsTotalRatio = {
      monthly_ratio: netSalesLaborCostsCostCompositionMonthlyRatio,
      yearly_ratio: netSalesLaborCostsCostCompositionYearlyRatio,
    };

    // 売上高外注費比率
    const netSalesExternalInjectionProcessingFeeCostCompositionMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            externalInjectionProcessingFeeForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : externalInjectionProcessingFeeForBudgetControl.monthlyTotal;

    const netSalesExternalInjectionProcessingFeeCostCompositionYearlyRatio =
      divide(
        externalInjectionProcessingFeeForBudgetControl.yearlyTotal,
        netSalesForBudgetControl.yearlyTotal
      ) as number;

    const netSalesExternalInjectionProcessingFeeTotalRatio = {
      monthly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionMonthlyRatio,
      yearly_ratio:
        netSalesExternalInjectionProcessingFeeCostCompositionYearlyRatio,
    };

    // 売上高経費比率
    const netSalesExpensesCostCompositionMonthlyRatio =
      netSalesForBudgetControl.monthlyTotal.length !== 0
        ? (dotDivide(
            expensesForBudgetControl.monthlyTotal,
            netSalesForBudgetControl.monthlyTotal
          ) as number[])
        : expensesForBudgetControl.monthlyTotal;

    const netSalesExpensesCostCompositionYearlyRatio = divide(
      expensesForBudgetControl.yearlyTotal,
      netSalesForBudgetControl.yearlyTotal
    ) as number;

    const netSalesExpensesTotalRatio = {
      monthly_ratio: netSalesExpensesCostCompositionMonthlyRatio,
      yearly_ratio: netSalesExpensesCostCompositionYearlyRatio,
    };
    // 原価の経費にある減価償却費
    const depreciationCostOfSalesT = totalCalcForEstimatedInThisTerm({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      states: depreciation_cost_of_cost_of_sales,
      resultsCount: resultsCount,
    });

    const netSalesDepreciationCostOfSales = {
      monthly_total: depreciationCostOfSalesT.monthlyTotal,
      yearly_total: depreciationCostOfSalesT.yearlyTotal,
    };

    const estimatedInThisTermCalcState = {
      net_sales_total: netSalesTotal,
      cost_of_sales_total: costOfSalesTotal,
      gross_margin: grossMargin,
      personal_cost_total: personalCostTotal,
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      operating_income: operatingIncome,
      non_operating_income_total: nonOperatingIncomeTotal,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      current_earnings: currentEarnings,
      special_benefits_total: specialBenefitsTotal,
      special_losses_total: specialLossesTotal,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total:
        corporateInhabitantAndEnterpriseTaxesTotal,
      material_fee_total: materialFeeTotal,
      labor_costs_total: laborCostsTotal,
      external_injection_processing_fee_total:
        externalInjectionProcessingFeeTotal,
      expenses_total: expensesTotal,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotal,
      inventory_of_work_in_process_at_the_end_of_the_period_total:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotal,
      gross_manufacturing_cost_for_the_period:
        grossManufacturingCostForThePeriod,
      cost_of_products_manufactured: costOfProductsManufactured,
      operating_profits_before_amortization: operatingProfitsBeforeAmortization,
      ebitda: ebitda,
      ebit: ebit,
      net_income: netIncome,
      simple_cf_row1_total: simpleCfRow1Total,
      simple_cf_row2_total: simpleCfRow2Total,
      simple_cf_row3_total: simpleCfRow3Total,
      sale_composition_ratio: saleCompositionRatio,
      gross_manufacturing_cost_for_the_period_plans_cost_composition_ratio:
        grossManufacturingCostForThePeriodPlansCostCompositionRatio,
      material_fee_cost_composition_ratio: materialFeeCostCompositionRatio,
      labor_costs_cost_composition_ratio: laborCostsCostCompositionRatio,
      external_injection_processing_fee_cost_composition_ratio:
        externalInjectionProcessingFeeCostCompositionRatio,
      expenses_cost_composition_ratio: expensesCostCompositionRatio,
      net_sales_material_fee_total_ratio: netSalesMaterialFeeTotalRatio,
      net_sales_labor_costs_total_ratio: netSalesLaborCostsTotalRatio,
      net_sales_external_injection_processing_fee_total_ratio:
        netSalesExternalInjectionProcessingFeeTotalRatio,
      net_sales_expenses_total_ratio: netSalesExpensesTotalRatio,
      net_sales_depreciation_cost_of_sales: netSalesDepreciationCostOfSales,
      net_sales_previous_ratio: netSalesPreviousRatio,
      depreciation_cost_total: depreciationCostTotal,
    };

    return estimatedInThisTermCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
