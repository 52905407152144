import { DocumentData } from "firebase/firestore";
import React from "react";
import { useRecoilState } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { bottomHeaderCss, topHeaderCss } from "../../../utils";

interface Props {
  headers: DocumentData[];
}

const BudgetControlBasisHeader: React.FC<Props> = ({ headers }) => {
  const [singleOrCumsumToggle, setSingleOrCumsumToggle] =
    useRecoilState(singleOrCumsumState);

  const handleChange = () => {
    setSingleOrCumsumToggle(!singleOrCumsumToggle);
  };

  return (
    <thead className="sticky top-0 px-6px py-3px bg-white pt-120px z-40">
      <tr>
        <th
          className={`${topHeaderCss} w-220px min-w-[13.75rem] sticky left-0 budget-control-header-width bg-white shrink-0 before:bg-ce7effd z-40 before:border-l before:border-t before:border-b-2 before:-z-10`}
          colSpan={2}
          rowSpan={3}
        >
          <div className="text-center">
            {headers && headers[headers.length - 1].nengou}年
            {headers && headers[headers.length - 1].term.slice(3, 5)}
          </div>
          <div className="text-center">
            <select
              name="singleOrCumsum"
              onChange={() => handleChange()}
              value={singleOrCumsumToggle ? "単月" : "累計"}
            >
              <option value="単月">単月</option>
              <option value="累計">累計</option>
            </select>
          </div>
        </th>
        <th
          className={`sticky left-220px min-w-[3.125rem] w-45px bg-white
             ${topHeaderCss} before:border-x before:border-t before:border-b-2 before:border-l-c3333334d z-40`}
          rowSpan={3}
        >
          <div className="font-bold ">
            固定
            <br />
            変動
            <br />
            区分
          </div>
        </th>
        {headers?.map((header: DocumentData, index: number) => (
          <React.Fragment key={index}>
            <th
              className={`min-w-[52.5rem] w-840px ${topHeaderCss} before:border-t before:border-l`}
              colSpan={7}
            >
              <div className="text-center">
                {singleOrCumsumToggle
                  ? `${header.nengou}年${header && header.period.slice(-2)}月`
                  : `${headers && headers[0].period.slice(-2)}月 〜 ${
                      header && header.period.slice(-2)
                    }月 （${index + 1}ヶ月）`}
              </div>
            </th>
          </React.Fragment>
        ))}
        {singleOrCumsumToggle && (
          <th
            className={`min-w-[52.5rem] w-840px ${topHeaderCss} before:border-t before:border-l `}
            colSpan={7}
          >
            <div className="text-center">
              {headers && headers[headers.length - 1].nengou}年
              {headers && headers[headers.length - 1].term.slice(3, 5)} 合計
            </div>
          </th>
        )}

        <th
          className={`min-w-[37.5rem] w-600px align-middle bg-white ${topHeaderCss} before:border before:border-b-2`}
          rowSpan={3}
        >
          <div className="text-center">備考</div>
        </th>
      </tr>
      <tr>
        {headers?.map((header: DocumentData, index: number) => (
          <React.Fragment key={index}>
            <th
              className={`min-w-[7.5rem] w-120px
              ${topHeaderCss} before:border-l`}
            ></th>
            <th
              className={`min-w-[22.5rem] w-360px ${topHeaderCss} before:border-t before:border-l before:border-t-c3333334d before:border-l-c3333334d bg-c2a4b770d`}
              colSpan={3}
            >
              <div className="text-center">前年同月実績</div>
            </th>

            <th
              className={`min-w-[22.5rem] w-360px ${topHeaderCss} before:border-t before:border-t-c3333334d
                before:border-l before:border-l-c3333334d bg-c2a4b770d`}
              colSpan={3}
            >
              <div className="text-center">計画</div>
            </th>
          </React.Fragment>
        ))}
        {singleOrCumsumToggle && (
          <React.Fragment>
            <th
              className={`min-w-[7.5rem] w-120px text-center
              relative px-6px py-3px top-0  before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:border-l  before:-z-10`}
            >
              合計
            </th>
            <th
              className={`min-w-[22.5rem] w-360px ${topHeaderCss} before:border-t before:border-l before:border-t-c3333334d before:border-l-c3333334d bg-c2a4b770d`}
              colSpan={3}
            >
              <div className="text-center">前年実績</div>
            </th>

            <th
              className={`min-w-[22.5rem] w-360px ${topHeaderCss} before:border-t before:border-t-c3333334d
                before:border-l before:border-l-c3333334d bg-c2a4b770d`}
              colSpan={3}
            >
              <div className="text-center">計画</div>
            </th>
          </React.Fragment>
        )}
      </tr>
      <tr>
        {headers?.map((header: DocumentData, index: number) => (
          <React.Fragment key={index}>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px before:border-l`}
            ></th>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b770d before:border-l before:border-l-c3333334d`}
            ></th>
            <th
              className={`${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">差額</div>
            </th>
            <th
              className={` min-w-[7.5rem] w-120px ${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">比率</div>
            </th>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b770d before:border-l before:border-l-c3333334d `}
            ></th>
            <th
              className={`${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">差額</div>
            </th>
            <th
              className={` min-w-[7.5rem] w-120px ${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">比率</div>
            </th>
          </React.Fragment>
        ))}
        {singleOrCumsumToggle && (
          <React.Fragment>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px font-bold before:border-l `}
            ></th>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b770d before:border-l before:border-l-c3333334d`}
            ></th>
            <th
              className={`${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">差額</div>
            </th>
            <th
              className={` min-w-[7.5rem] w-120px ${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">比率</div>
            </th>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b770d before:border-l before:border-l-c3333334d `}
            ></th>
            <th
              className={`${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">差額</div>
            </th>
            <th
              className={` min-w-[7.5rem] w-120px ${bottomHeaderCss} before:border-l before:border-l-c3333334d before:border-t before:border-t-c3333334d`}
            >
              <div className="text-center">比率</div>
            </th>
          </React.Fragment>
        )}
      </tr>
    </thead>
  );
};

export default BudgetControlBasisHeader;
