import { useMemo } from "react";
import { useGetFirebaseData } from "../../firebase";
import { useHeaders } from "../useHeaders";
import { useSortedData } from "../useSortedData";

export const useSubjectItemsInitialData = () => {
  const { tableData } = useGetFirebaseData();

  const headers = useHeaders(tableData);

  const netSales = useSortedData("net_sales", tableData);

  const costOfSales = useSortedData("cost_of_sales", tableData);

  const personalCost = useSortedData("personal_cost", tableData);

  const sellingAndAdministrative = useSortedData(
    "selling_and_administrative",
    tableData
  );

  const nonOperatingIncome = useSortedData("non_operating_income", tableData);

  const nonOperatingExpenses = useSortedData(
    "non_operating_expenses",
    tableData
  );

  const specialBenefits = useSortedData("special_benefits", tableData);

  const specialLosses = useSortedData("special_losses", tableData);

  const corporateInhabitantAndEnterpriseTaxeslosses = useSortedData(
    "special_corporate_inhabitant_and_enterprise_taxeslosses",
    tableData
  );

  const salesRatioByMonth = useSortedData("sales_ratio_by_Month", tableData);

  const materialFee = useSortedData("material_fee", tableData);

  const laborCosts = useSortedData("labor_costs", tableData);

  const externalInjectionProcessingFee = useSortedData(
    "external_injection_processing_fee",
    tableData
  );

  const expenses = useSortedData("expenses", tableData);

  const inventoryOfWorkInProcessAtTheBeginningOfThePeriod = useSortedData(
    "inventory_of_work_in_process_at_the_beginning_of_the_period",
    tableData
  );

  const inventoryOfWorkInProcessAtTheEndOfThePeriod = useSortedData(
    "inventory_of_work_in_process_at_the_end_of_the_period",
    tableData
  );

  const cashEquivalent = useSortedData("cash_equivalent", tableData);

  const accountsReceivable = useSortedData("accounts_receivable", tableData);

  const currentAssets = useSortedData("current_assets", tableData);

  const shedUnloadingAssets = useSortedData("shed_unloading_assets", tableData);

  const quotidianGold = useSortedData("quotidian_gold", tableData);

  const otherCurrentAssets = useSortedData("other_current_assets", tableData);

  const tangibleFixedAssets = useSortedData("tangible_fixed_assets", tableData);

  const intangibleFixedAssets = useSortedData(
    "intangible_fixed_assets",
    tableData
  );

  const investmentsAndOtherAssets = useSortedData(
    "investments_and_other_assets",
    tableData
  );

  const deferredAsset = useSortedData("deferred_asset", tableData);

  const payables = useSortedData("payables", tableData);

  const shortTermBorrowings = useSortedData("short_term_borrowings", tableData);

  const unpaids = useSortedData("unpaids", tableData);

  const currentLiabilities = useSortedData("current_liabilities", tableData);

  const fixedLiabilities = useSortedData("fixed_liabilities", tableData);

  const capital = useSortedData("capital", tableData);

  const capitalSurplus = useSortedData("capital_surplus", tableData);

  const retainedEarnings = useSortedData("retained_earnings", tableData);

  const otherRetainedEarnings = useSortedData(
    "other_retained_earnings",
    tableData
  );

  const treasuryStock = useSortedData("treasury_stock", tableData);

  const employees = useSortedData("employees", tableData);

  // 特別収益
  const specialIncome = useSortedData("special_income", tableData);

  // 特別費用他
  const otherSpecialExpenses = useSortedData(
    "other_special_expenses",
    tableData
  );

  // 納税
  const taxPayment = useSortedData("tax_payment", tableData);

  // 資金調達
  const financialArrangements = useSortedData(
    "financial_arrangements",
    tableData
  );

  // 元金返済
  const principalPayments = useSortedData("principal_payments", tableData);

  // 販売費及び一般管理費と経費の減価償却費
  const depreciationCostData = useMemo(() => {
    return tableData.filter((resultInput) => resultInput.item === "減価償却費");
  }, [tableData]);

  // 販売費及び一般管理費
  const depreciationCostSingleData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "selling_and_administrative" &&
        resultInput.item === "減価償却費"
    );
  }, [tableData]);

  // 期末材料棚卸高
  const rawMaterialInventoriesAtTheEndOfThePeriod = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "material_fee" &&
        resultInput.item === "期末材料棚卸高"
    );
  }, [tableData]);

  // 期末製品棚卸高
  const endInventoriesData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "cost_of_sales" &&
        resultInput.item === "期末商品棚卸高"
    );
  }, [tableData]);

  // 原価の経費にある減価償却費
  const depreciationCostOfCostOfSales = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "expenses" && resultInput.item === "減価償却費"
    );
  }, [tableData]);

  // 短期借入金
  const shortTermBorrowingsData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "short_term_borrowings" &&
        resultInput.item === "短期借入金"
    );
  }, [tableData]);

  // 長期借入金
  const fixedLiabilitiesData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "fixed_liabilities" &&
        resultInput.item === "長期借入金"
    );
  }, [tableData]);

  // 社債
  const corporateBondData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "fixed_liabilities" && resultInput.item === "社債"
    );
  }, [tableData]);

  // 材料費（変動のみ）
  const materialFeeFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "material_fee" && resultInput.section === "変動"
    );
  }, [tableData]);

  // 労務費（変動のみ）
  const laborCostsFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "labor_costs" && resultInput.section === "変動"
    );
  }, [tableData]);

  // 外注費（変動のみ）
  const externalInjectionProcessingFeeFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "external_injection_processing_fee" &&
        resultInput.section === "変動"
    );
  }, [tableData]);

  // 経費（変動のみ）
  const expensesFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "expenses" && resultInput.section === "変動"
    );
  }, [tableData]);

  // 期首製品棚卸高
  const beginningInventoriesData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "cost_of_sales" &&
        resultInput.item === "期首商品棚卸高"
    );
  }, [tableData]);

  // 売上原価合計（期首期末以外）
  const costOfSalesExceptBeginningAndEndData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "cost_of_sales" &&
        resultInput.item !== "期首商品棚卸高" &&
        resultInput.item !== "期末商品棚卸高"
    );
  }, [tableData]);

  // 変動販管費（人件費）
  const personalCostFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "personal_cost" && resultInput.section === "変動"
    );
  }, [tableData]);

  // 変動販管費（その他）
  const sellingAndAdministrativeFluctuateData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "selling_and_administrative" &&
        resultInput.section === "変動"
    );
  }, [tableData]);

  // 労務費（固定のみ）
  const laborCostsFixedData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "labor_costs" && resultInput.section === "固定"
    );
  }, [tableData]);

  // 経費（固定のみ）
  const expensesFixedData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "expenses" && resultInput.section === "固定"
    );
  }, [tableData]);

  // 固定販管費（人件費）
  const personalCostFixedData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "personal_cost" && resultInput.section === "固定"
    );
  }, [tableData]);

  // 固定販管費（その他）
  const sellingAndAdministrativeFixedData = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "selling_and_administrative" &&
        resultInput.section === "固定"
    );
  }, [tableData]);

  // 支払利息
  const interestExpenses = useMemo(() => {
    return tableData.filter(
      (fieldsData) =>
        fieldsData.field === "non_operating_expenses" &&
        fieldsData.item === "支払利息"
    );
  }, [tableData]);

  // 修正後の経費の減価償却費 修正後の経費の一番初めの値を抽出
  const depreciationCostOfSalesTFixed = useMemo(() => {
    return tableData.filter(
      (resultInput) =>
        resultInput.field === "expenses" && resultInput.item === "減価償却費"
    );
  }, [tableData]);

  // キャッシュアウトしない費用（減価償却費以外）
  const nonCashOut = useMemo(() => {
    return tableData.filter((resultInput) => resultInput.is_non_cash_out);
  }, [tableData]);

  const descriptions = useMemo(() => {
    const descriptionsData = tableData.filter(
      (inputData) => inputData.field === "descriptions"
    );
    return descriptionsData[0];
  }, [tableData]);

  const subjectItemsDataState = {
    headers: headers,
    net_sales: netSales,
    cost_of_sales: costOfSales,
    personal_cost: personalCost,
    selling_and_administrative: sellingAndAdministrative,
    non_operating_income: nonOperatingIncome,
    non_operating_expenses: nonOperatingExpenses,
    special_benefits: specialBenefits,
    special_losses: specialLosses,
    special_corporate_inhabitant_and_enterprise_taxeslosses:
      corporateInhabitantAndEnterpriseTaxeslosses,
    sales_ratio_by_Month: salesRatioByMonth,
    material_fee: materialFee,
    labor_costs: laborCosts,
    external_injection_processing_fee: externalInjectionProcessingFee,
    expenses: expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period:
      inventoryOfWorkInProcessAtTheBeginningOfThePeriod,
    inventory_of_work_in_process_at_the_end_of_the_period:
      inventoryOfWorkInProcessAtTheEndOfThePeriod,
    cash_equivalent: cashEquivalent,
    accounts_receivable: accountsReceivable,
    current_assets: currentAssets,
    shed_unloading_assets: shedUnloadingAssets,
    quotidian_gold: quotidianGold,
    other_current_assets: otherCurrentAssets,
    tangible_fixed_assets: tangibleFixedAssets,
    intangible_fixed_assets: intangibleFixedAssets,
    investments_and_other_assets: investmentsAndOtherAssets,
    deferred_asset: deferredAsset,
    payables: payables,
    short_term_borrowings: shortTermBorrowings,
    unpaids: unpaids,
    current_liabilities: currentLiabilities,
    fixed_liabilities: fixedLiabilities,
    capital: capital,
    capital_surplus: capitalSurplus,
    retained_earnings: retainedEarnings,
    other_retained_earnings: otherRetainedEarnings,
    treasury_stock: treasuryStock,
    employees: employees,
    special_income: specialIncome,
    other_special_expenses: otherSpecialExpenses,
    tax_payment: taxPayment,
    financial_arrangements: financialArrangements,
    principal_payments: principalPayments,
    depreciation_cost_data: depreciationCostData,
    depreciation_cost_single_data: depreciationCostSingleData,
    raw_material_inventories_at_the_end_of_the_period:
      rawMaterialInventoriesAtTheEndOfThePeriod,
    end_inventories_data: endInventoriesData,
    depreciation_cost_of_cost_of_sales: depreciationCostOfCostOfSales,
    short_term_borrowings_data: shortTermBorrowingsData,
    fixed_liabilities_data: fixedLiabilitiesData,
    corporate_bond_data: corporateBondData,
    material_fee_fluctuate_data: materialFeeFluctuateData,
    labor_costs_fluctuate_data: laborCostsFluctuateData,
    external_injection_processing_fee_fluctuate_data:
      externalInjectionProcessingFeeFluctuateData,
    expenses_fluctuate_data: expensesFluctuateData,
    beginning_inventories_data: beginningInventoriesData,
    cost_of_sales_except_beginning_and_end_data:
      costOfSalesExceptBeginningAndEndData,
    personal_cost_fluctuate_data: personalCostFluctuateData,
    selling_and_administrative_fluctuate_data:
      sellingAndAdministrativeFluctuateData,
    labor_costs_fixed_data: laborCostsFixedData,
    expenses_fixed_data: expensesFixedData,
    personal_cost_fixed_data: personalCostFixedData,
    selling_and_administrative_fixed_data: sellingAndAdministrativeFixedData,
    interest_expenses: interestExpenses,
    depreciation_cost_of_sales_t_fixed: depreciationCostOfSalesTFixed,
    non_cash_out: nonCashOut,
    descriptions: descriptions,
  };

  return subjectItemsDataState;
};
