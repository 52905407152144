import React from "react";
import { Link } from "react-router-dom";
import { pageConstant } from "../../constants/pageConstants";

const LpWithLoginHeader = () => {
  return (
    <div className="w-full py-16px">
      <nav className="w-full relative flex items-center justify-between gap-12px px-20px mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <Link className="flex items-center space-x-2 text-2xl" to={"/"}>
            <span>
              <img
                className="w-200px"
                src={`${window.location.origin}/img/CP_logo_bl.svg`}
              />
            </span>
          </Link>
        </div>

        <div className="text-center justify-end ml-auto">
          <ul className="items-center justify-end flex-1 list-none flex gap-16px">
            <li className="mr-3  hover:opacity-80">
              <Link to={`/${pageConstant.LOGIN}`}>ログイン</Link>
            </li>
          </ul>
        </div>
        <div className="mr-3 space-x-4 flex gap-16px">
          <Link to={`/${pageConstant.SIGNUP}`}>
            <button className="bg-white py-4px px-16px rounded-md border text-c427ed1 border-c427ed1 hover:opacity-80">
              新規登録
            </button>
          </Link>

          {/*<button className="text-white bg-c427ed1 py-4px px-16px vertical-middle rounded-md border border-c427ed1 hover:opacity-80">
            お問い合わせ
          </button>*/}
        </div>
      </nav>
    </div>
  );
};

export default LpWithLoginHeader;
