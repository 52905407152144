import { FirebaseError } from "firebase/app";
import { User } from "firebase/auth";
import React, { memo, useEffect, useState } from "react";
import { FaUserAlt, FaRegCommentAlt } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageConstant } from "../../constants/pageConstants";
import { useClients, useFirebaseAuth } from "../../hooks";
import { useSpecialists } from "../../hooks/useSpecialists";
import { Logout } from "../../lib/Logout";
import {
  getStoredCategory,
  setStoredClientToken,
  setStoredSpecialistToken,
} from "../../sessionStorage/sessionStorage";
import { specialistsMenuToggleStore } from "../../store/specialistsStore";
import { toggleStore } from "../../store/switchState";

export const Header = memo(() => {
  const category = getStoredCategory();

  const specialists = useSpecialists();

  const clients = useClients();

  const { auth } = useFirebaseAuth();

  const queryClient = useQueryClient();

  const [name, setName] = useState("");

  const [toggle, setToggle] = useRecoilState(specialistsMenuToggleStore);

  const menuToggle = useRecoilValue(toggleStore);

  const handleClick = () => {
    Logout(queryClient, "success", "ログアウトしました");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      auth.onAuthStateChanged((user: User | null): void => {
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              const currentTime = new Date().getTime() / 1000; // 現在の時刻を秒単位で取得
              const tokenExpirationTime =
                new Date(idTokenResult.expirationTime).getTime() / 1000; // トークンの有効期限をミリ秒単位で取得

              if (tokenExpirationTime - currentTime < 300) {
                // トークンの有効期限が5分以内の場合、トークンを更新
                user
                  .getIdToken(true)
                  .then((latestToken: string) => {
                    return category === 1
                      ? setStoredSpecialistToken(latestToken)
                      : setStoredClientToken(latestToken);
                  })
                  .catch((error: FirebaseError) => {
                    toast.error(`ログイン画面へ移動します: ${error}`);
                    window.location.href = "/login";
                  });
              }
            })
            .catch((error: FirebaseError) => {
              toast.error(`ログイン画面へ移動します: ${error}`);
              window.location.href = "/login";
            });
        } else {
          window.location.href = "/login";
        }
      });

      setTimeout(() => {
        clients !== null && clients.client
          ? setName(clients.client.client_company)
          : specialists !== null && specialists.specialist
          ? setName(specialists.specialist.company_name)
          : setName("");
      }, 1000);
    }, 5 * 60 * 1000); // 5分おきに更新する

    return () => clearInterval(interval);
  }, [auth, category, clients, specialists]);

  return (
    <div
      className={`px-20px py-12px flex justify-between ${
        menuToggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
      } h-50px bg-white fixed z-50`}
    >
      <div className="text-20px font-bold">{name}</div>
      <div>
        <div className="flex gap-8px items-center">
          { (specialists?.specialist?.priceplan?.plan_type !== 1) ?
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdLMXKyqzkXDzZxGAc7nsiwuIfMe7S3IXU1LpIHh0aBMkcIPw/viewform" target="_blink">
            <button className="bg-c427ed1 rounded-md border text-white text-13px border-c427ed1 hover:opacity-90 leading-7 h-30px w-100px flex justify-center">
              <FaRegCommentAlt className="float-left mt-7px mr-5px" />サポート
            </button>
          </a>
          : ''}

          <div>
            {specialists !== null && Object.keys(specialists).length !== 0
              ? `${specialists.specialist.last_name} 
          ${specialists.specialist.first_name}(${specialists.user.email})`
              : clients !== null && Object.keys(clients).length !== 0
              ? `${clients.client.client_last_name} 
          ${clients.client.client_first_name}(${clients.user.email})`
              : ""}
          </div>
          <button
            className="w-30px h-30px bg-c427ed133 p-6px rounded-full flex justify-center items-center hover:opacity-80"
            onClick={() => {
              setToggle(!toggle),
                setTimeout(() => {
                  setToggle(false);
                }, 4500);
            }}
          >
            <FaUserAlt className="text-c427ed1" />
          </button>
        </div>
        <div
          className={`${
            !toggle && "hidden"
          } mt-4px px-8px py-4px leading-8 bg-ce7effd border border-c33333333 rounded shadow-outline inline-block float-right`}
        >
          <ul>
            <li className="hover:opacity-80">
              {category === 1 ? (
                <Link
                  onClick={() => setToggle(false)}
                  to={`/${pageConstant.ACCOUNTS_SPECIALISTS}`}
                >
                  アカウント
                </Link>
              ) : (
                <Link
                  onClick={() => setToggle(false)}
                  to={`/${pageConstant.ACCOUNTS_CLIENTS}`}
                >
                  アカウント
                </Link>
              )}
            </li>
            {category === 1 && (
              <li className="hover:opacity-80">
                <Link
                  onClick={() => setToggle(false)}
                  to={`/${pageConstant.PLAN_CHANGE}`}
                >
                  プラン変更
                </Link>
              </li>
            )}
            <li className="hover:opacity-80">
              <button onClick={() => handleClick()}>ログアウト</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

Header.displayName = "Header";
