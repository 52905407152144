import React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { SalesPlanContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

const SalesPlan = () => {
  const url = pageConstant.SALES_PLAN;

  return (
    <React.Fragment>
      <SubHeader title="販売計画" />
      <UnitChange />
      <SalesPlanContainer url={url} />
    </React.Fragment>
  );
};

export default SalesPlan;
