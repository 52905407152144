export const pageConstant = {
  // LP
  HOME: "",
  // ユーザー登録
  SIGNUP: "signup",
  // メールをご確認ください
  CHECK_EMAIL: "check_email",
  // 利用者情報登録
  SIGNUP_SPECIALIST: "signup_specialist",
  // 料金プラン選択・決済情報登録
  SIGNUP_PAYMENT: "signup_payment",
  // 登録内容確認
  CONFIRM_SIGNUP: "confirm_signup",
  // VERIFY_SUCCESS
  VERIFY_SUCCESS: "verify_success",
  // ログイン
  LOGIN: "login",
  // 特定商取引法に基づく表記
  COMMERCIAL_TRANSACTION: "commercial_transaction",
  // パスワード再設定
  PASSWORD_RESET: "password_reset",
  // パスワード再設定
  PASSWORD_RESETTING: "password_resetting",
  // クライアント一覧
  CLIENTS: "clients",
  // クライアント招待メール送信
  EMAIL_INVITATION: "email_invitation",
  // CLIENT_INVITATION
  CLIENT_INVITATION: "client_invitation",
  // クライアント招待メール送信完了画面
  COMPLETE_EMAIL_INVITATION: "complete_email_invitation",
  // COMPLETE_CLIENT_INVITATION
  COMPLETE_CLIENT_INVITATION: "complete_client_invitation",
  // クライアント新規登録
  SIGNUP_CLIENT: "signup_client",
  // クライアント登録内容確認
  CONFIRM_SIGNUP_CLIENT: "confirm_signup_client",
  // 利用規約
  TERMS_OF_SERVICE: "terms_of_service",

  // 決算期の設定
  ACCOUNTING_PERIOD: "accounting_period",
  ACCOUNT_TITLES: "account_titles",
  KPI: "kpi",

  // 実績（年次）
  RESULTS_INPUT: "results_input",
  // PL前期実績（月次）
  RESULTS_OF_THE_PREVIOUS_PERIOD: "results_of_the_previous_period",
  // PL今期実績（月次）
  RESULTS_OF_THE_CURRENT_TERM: "results_of_the_current_term",
  // PL今期・前期比較の確認
  CURRENT_AND_PREVIOUS_YEAR_COMPARISONS:
    "current_and_previous_year_comparisons",
  // PL/BS計画
  PLANS_INPUT: "plans_input",
  // PL/BS今期計画（月次）
  PLANS_INPUT_MONTHLY: "plans_input_monthly",
  // 今期見込み（月次）
  PLANS_INPUT_ESTIMATED_IN_THIS_TERM: "plans_input_estimated_in_this_term",
  // 販売計画
  SALES_PLAN: "sales_plan",
  // 損益計画
  PROFIT_AND_LOSS_PLAN: "profit_and_loss_plan",
  // 事業計画
  BUSINESS_PLAN: "business_plan",
  // キャッシュフロー計算書
  STATEMENT_OF_CASH_FLOWS: "statement_of_cash_flows",

  // 予実管理表（単月）
  BUDGET_CONTROL_BASIS: "budget_control_basis",
  // 予実管理表（累計）
  BUDGET_CONTROL_TOTAL: "budget_control_total",
  // 今期計画（月次）
  CURRENT_PLAN_MONTHLY: "current_plan_monthly",
  // 今期見込み（月次）
  ESTIMATED_IN_THIS_TERM: "estimated_in_this_term",
  // 今期実績（月次）
  CURRENT_RESULT_MONTHLY: "current_result_monthly",
  // PLシミュレーション
  PL_SIMULATION: "pl_simulation",
  // 資金繰り予定表
  CASH_FLOW_TABLE: "cash_flow_table",
  // ダッシュボード
  MANAGEMENT_DASHBOARD: "management_dashboard",
  // 士業アカウント
  ACCOUNTS_SPECIALISTS: "accounts_specialists",
  // クライアントアカウント
  ACCOUNTS_CLIENTS: "accounts_clients",
  // プラン変更
  PLAN_CHANGE: "plan_change",

  _404: "*",
};
