import React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { ProfitAndLossPlanContainer } from "../../components/planMaking/container";
import { InputLayout } from "../../components/planMaking/resultsInput";
import { pageConstant } from "../../constants/pageConstants";

const ProfitAndLossPlan = () => {
  const url = pageConstant.PROFIT_AND_LOSS_PLAN;

  return (
    <React.Fragment>
      <SubHeader title="損益計画" />
      <UnitChange />
      <InputLayout>
        <ProfitAndLossPlanContainer url={url} />
      </InputLayout>
    </React.Fragment>
  );
};

export default ProfitAndLossPlan;
