export const industryClassification = [
  {
    large: "A",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "農業，林業",
  },
  {
    large: "A",
    middle: "01",
    minor: "000",
    sub: "0000",
    item: "農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "010",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（01農業）",
  },
  {
    large: "A",
    middle: "01",
    minor: "010",
    sub: "0100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "A",
    middle: "01",
    minor: "010",
    sub: "0109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0000",
    item: "耕種農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0111",
    item: "米作農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0112",
    item: "米作以外の穀作農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0113",
    item: "野菜作農業（きのこ類の栽培を含む）",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0114",
    item: "果樹作農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0115",
    item: "花き作農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0116",
    item: "工芸農作物農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0117",
    item: "ばれいしょ・かんしょ作農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "011",
    sub: "0119",
    item: "その他の耕種農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0000",
    item: "畜産農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0121",
    item: "酪農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0122",
    item: "肉用牛生産業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0123",
    item: "養豚業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0124",
    item: "養鶏業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0125",
    item: "畜産類似業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0126",
    item: "養蚕農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "012",
    sub: "0129",
    item: "その他の畜産農業",
  },
  {
    large: "A",
    middle: "01",
    minor: "013",
    sub: "0000",
    item: "農業サービス業（園芸サービス業を除く）",
  },
  {
    large: "A",
    middle: "01",
    minor: "013",
    sub: "0131",
    item: "穀作サービス業",
  },
  {
    large: "A",
    middle: "01",
    minor: "013",
    sub: "0132",
    item: "野菜作・果樹作サービス業",
  },
  {
    large: "A",
    middle: "01",
    minor: "013",
    sub: "0133",
    item: "穀作，野菜作・果樹作以外の耕種サービス業",
  },
  {
    large: "A",
    middle: "01",
    minor: "013",
    sub: "0134",
    item: "畜産サービス業（獣医業を除く）",
  },
  {
    large: "A",
    middle: "01",
    minor: "014",
    sub: "0000",
    item: "園芸サービス業",
  },
  {
    large: "A",
    middle: "01",
    minor: "014",
    sub: "0141",
    item: "園芸サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "000",
    sub: "0000",
    item: "林業",
  },
  {
    large: "A",
    middle: "02",
    minor: "020",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（02林業）",
  },
  {
    large: "A",
    middle: "02",
    minor: "020",
    sub: "0200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "A",
    middle: "02",
    minor: "020",
    sub: "0209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "A",
    middle: "02",
    minor: "021",
    sub: "0000",
    item: "育林業",
  },
  {
    large: "A",
    middle: "02",
    minor: "021",
    sub: "0211",
    item: "育林業",
  },
  {
    large: "A",
    middle: "02",
    minor: "022",
    sub: "0000",
    item: "素材生産業",
  },
  {
    large: "A",
    middle: "02",
    minor: "022",
    sub: "0221",
    item: "素材生産業",
  },
  {
    large: "A",
    middle: "02",
    minor: "023",
    sub: "0000",
    item: "特用林産物生産業（きのこ類の栽培を除く）",
  },
  {
    large: "A",
    middle: "02",
    minor: "023",
    sub: "0231",
    item: "製薪炭業",
  },
  {
    large: "A",
    middle: "02",
    minor: "023",
    sub: "0239",
    item: "その他の特用林産物生産業（きのこ類の栽培を除く）",
  },
  {
    large: "A",
    middle: "02",
    minor: "024",
    sub: "0000",
    item: "林業サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "024",
    sub: "0241",
    item: "育林サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "024",
    sub: "0242",
    item: "素材生産サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "024",
    sub: "0243",
    item: "山林種苗生産サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "024",
    sub: "0249",
    item: "その他の林業サービス業",
  },
  {
    large: "A",
    middle: "02",
    minor: "029",
    sub: "0000",
    item: "その他の林業",
  },
  {
    large: "A",
    middle: "02",
    minor: "029",
    sub: "0299",
    item: "その他の林業",
  },
  {
    large: "B",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "000",
    sub: "0000",
    item: "漁業（水産養殖業を除く）",
  },
  {
    large: "B",
    middle: "03",
    minor: "030",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（03漁業）",
  },
  {
    large: "B",
    middle: "03",
    minor: "030",
    sub: "0300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "B",
    middle: "03",
    minor: "030",
    sub: "0309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0000",
    item: "海面漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0311",
    item: "底びき網漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0312",
    item: "まき網漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0313",
    item: "刺網漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0314",
    item: "釣・はえ縄漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0315",
    item: "定置網漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0316",
    item: "地びき網・船びき網漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0317",
    item: "採貝・採藻業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0318",
    item: "捕鯨業",
  },
  {
    large: "B",
    middle: "03",
    minor: "031",
    sub: "0319",
    item: "その他の海面漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "032",
    sub: "0000",
    item: "内水面漁業",
  },
  {
    large: "B",
    middle: "03",
    minor: "032",
    sub: "0321",
    item: "内水面漁業",
  },
  {
    large: "B",
    middle: "04",
    minor: "000",
    sub: "0000",
    item: "水産養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "040",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（04水産養殖業）",
  },
  {
    large: "B",
    middle: "04",
    minor: "040",
    sub: "0400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "B",
    middle: "04",
    minor: "040",
    sub: "0409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0000",
    item: "海面養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0411",
    item: "魚類養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0412",
    item: "貝類養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0413",
    item: "藻類養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0414",
    item: "真珠養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0415",
    item: "種苗養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "041",
    sub: "0419",
    item: "その他の海面養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "042",
    sub: "0000",
    item: "内水面養殖業",
  },
  {
    large: "B",
    middle: "04",
    minor: "042",
    sub: "0421",
    item: "内水面養殖業",
  },
  {
    large: "C",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "鉱業，採石業，砂利採取業",
  },
  {
    large: "C",
    middle: "05",
    minor: "000",
    sub: "0000",
    item: "鉱業，採石業，砂利採取業",
  },
  {
    large: "C",
    middle: "05",
    minor: "050",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（05鉱業，採石業，砂利採取業）",
  },
  {
    large: "C",
    middle: "05",
    minor: "050",
    sub: "0500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "C",
    middle: "05",
    minor: "050",
    sub: "0509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "C",
    middle: "05",
    minor: "051",
    sub: "0000",
    item: "金属鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "051",
    sub: "0511",
    item: "金・銀鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "051",
    sub: "0512",
    item: "鉛・亜鉛鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "051",
    sub: "0513",
    item: "鉄鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "051",
    sub: "0519",
    item: "その他の金属鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "052",
    sub: "0000",
    item: "石炭・亜炭鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "052",
    sub: "0521",
    item: "石炭鉱業（石炭選別業を含む）",
  },
  {
    large: "C",
    middle: "05",
    minor: "052",
    sub: "0522",
    item: "亜炭鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "053",
    sub: "0000",
    item: "原油・天然ガス鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "053",
    sub: "0531",
    item: "原油鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "053",
    sub: "0532",
    item: "天然ガス鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0000",
    item: "採石業，砂・砂利・玉石採取業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0541",
    item: "花こう岩・同類似岩石採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0542",
    item: "石英粗面岩・同類似岩石採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0543",
    item: "安山岩・同類似岩石採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0544",
    item: "大理石採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0545",
    item: "ぎょう灰岩採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0546",
    item: "砂岩採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0547",
    item: "粘板岩採石業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0548",
    item: "砂・砂利・玉石採取業",
  },
  {
    large: "C",
    middle: "05",
    minor: "054",
    sub: "0549",
    item: "その他の採石業，砂・砂利・玉石採取業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0000",
    item: "窯業原料用鉱物鉱業（耐火物・陶磁器・ガラス・セメント原料用に限る）",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0551",
    item: "耐火粘土鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0552",
    item: "ろう石鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0553",
    item: "ドロマイト鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0554",
    item: "長石鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0555",
    item: "けい石鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0556",
    item: "天然けい砂鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0557",
    item: "石灰石鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "055",
    sub: "0559",
    item: "その他の窯業原料用鉱物鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0000",
    item: "その他の鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0591",
    item: "酸性白土鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0592",
    item: "ベントナイト鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0593",
    item: "けいそう土鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0594",
    item: "滑石鉱業",
  },
  {
    large: "C",
    middle: "05",
    minor: "059",
    sub: "0599",
    item: "他に分類されない鉱業",
  },
  {
    large: "D",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "建設業",
  },
  {
    large: "D",
    middle: "06",
    minor: "000",
    sub: "0000",
    item: "総合工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "060",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（06総合工事業）",
  },
  {
    large: "D",
    middle: "06",
    minor: "060",
    sub: "0600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "D",
    middle: "06",
    minor: "060",
    sub: "0609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "D",
    middle: "06",
    minor: "061",
    sub: "0000",
    item: "一般土木建築工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "061",
    sub: "0611",
    item: "一般土木建築工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "062",
    sub: "0000",
    item: "土木工事業（舗装工事業を除く）",
  },
  {
    large: "D",
    middle: "06",
    minor: "062",
    sub: "0621",
    item: "土木工事業(別掲を除く)",
  },
  {
    large: "D",
    middle: "06",
    minor: "062",
    sub: "0622",
    item: "造園工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "062",
    sub: "0623",
    item: "しゅんせつ工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "063",
    sub: "0000",
    item: "舗装工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "063",
    sub: "0631",
    item: "舗装工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "064",
    sub: "0000",
    item: "建築工事業(木造建築工事業を除く)",
  },
  {
    large: "D",
    middle: "06",
    minor: "064",
    sub: "0641",
    item: "建築工事業(木造建築工事業を除く)",
  },
  {
    large: "D",
    middle: "06",
    minor: "065",
    sub: "0000",
    item: "木造建築工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "065",
    sub: "0651",
    item: "木造建築工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "066",
    sub: "0000",
    item: "建築リフォーム工事業",
  },
  {
    large: "D",
    middle: "06",
    minor: "066",
    sub: "0661",
    item: "建築リフォーム工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "000",
    sub: "0000",
    item: "職別工事業(設備工事業を除く)",
  },
  {
    large: "D",
    middle: "07",
    minor: "070",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（07職別工事業）",
  },
  {
    large: "D",
    middle: "07",
    minor: "070",
    sub: "0700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "D",
    middle: "07",
    minor: "070",
    sub: "0709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "D",
    middle: "07",
    minor: "071",
    sub: "0000",
    item: "大工工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "071",
    sub: "0711",
    item: "大工工事業(型枠大工工事業を除く)",
  },
  {
    large: "D",
    middle: "07",
    minor: "071",
    sub: "0712",
    item: "型枠大工工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "072",
    sub: "0000",
    item: "とび・土工・コンクリート工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "072",
    sub: "0721",
    item: "とび工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "072",
    sub: "0722",
    item: "土工・コンクリート工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "072",
    sub: "0723",
    item: "特殊コンクリート工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "073",
    sub: "0000",
    item: "鉄骨・鉄筋工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "073",
    sub: "0731",
    item: "鉄骨工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "073",
    sub: "0732",
    item: "鉄筋工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "074",
    sub: "0000",
    item: "石工・れんが・タイル・ブロック工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "074",
    sub: "0741",
    item: "石工工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "074",
    sub: "0742",
    item: "れんが工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "074",
    sub: "0743",
    item: "タイル工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "074",
    sub: "0744",
    item: "コンクリートブロック工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "075",
    sub: "0000",
    item: "左官工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "075",
    sub: "0751",
    item: "左官工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "076",
    sub: "0000",
    item: "板金・金物工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "076",
    sub: "0761",
    item: "金属製屋根工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "076",
    sub: "0762",
    item: "板金工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "076",
    sub: "0763",
    item: "建築金物工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "077",
    sub: "0000",
    item: "塗装工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "077",
    sub: "0771",
    item: "塗装工事業（道路標示・区画線工事業を除く）",
  },
  {
    large: "D",
    middle: "07",
    minor: "077",
    sub: "0772",
    item: "道路標示・区画線工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "078",
    sub: "0000",
    item: "床・内装工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "078",
    sub: "0781",
    item: "床工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "078",
    sub: "0782",
    item: "内装工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0000",
    item: "その他の職別工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0791",
    item: "ガラス工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0792",
    item: "金属製建具工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0793",
    item: "木製建具工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0794",
    item: "屋根工事業（金属製屋根工事業を除く）",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0795",
    item: "防水工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0796",
    item: "はつり・解体工事業",
  },
  {
    large: "D",
    middle: "07",
    minor: "079",
    sub: "0799",
    item: "他に分類されない職別工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "000",
    sub: "0000",
    item: "設備工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "080",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（08設備工事業）",
  },
  {
    large: "D",
    middle: "08",
    minor: "080",
    sub: "0800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "D",
    middle: "08",
    minor: "080",
    sub: "0809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "D",
    middle: "08",
    minor: "081",
    sub: "0000",
    item: "電気工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "081",
    sub: "0811",
    item: "一般電気工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "081",
    sub: "0812",
    item: "電気配線工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "082",
    sub: "0000",
    item: "電気通信・信号装置工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "082",
    sub: "0821",
    item: "電気通信工事業（有線テレビジョン放送設備設置工事業を除く）",
  },
  {
    large: "D",
    middle: "08",
    minor: "082",
    sub: "0822",
    item: "有線テレビジョン放送設備設置工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "082",
    sub: "0823",
    item: "信号装置工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "083",
    sub: "0000",
    item: "管工事業（さく井工事業を除く）",
  },
  {
    large: "D",
    middle: "08",
    minor: "083",
    sub: "0831",
    item: "一般管工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "083",
    sub: "0832",
    item: "冷暖房設備工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "083",
    sub: "0833",
    item: "給排水・衛生設備工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "083",
    sub: "0839",
    item: "その他の管工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "084",
    sub: "0000",
    item: "機械器具設置工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "084",
    sub: "0841",
    item: "機械器具設置工事業（昇降設備工事業を除く）",
  },
  {
    large: "D",
    middle: "08",
    minor: "084",
    sub: "0842",
    item: "昇降設備工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "089",
    sub: "0000",
    item: "その他の設備工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "089",
    sub: "0891",
    item: "築炉工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "089",
    sub: "0892",
    item: "熱絶縁工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "089",
    sub: "0893",
    item: "道路標識設置工事業",
  },
  {
    large: "D",
    middle: "08",
    minor: "089",
    sub: "0894",
    item: "さく井工事業",
  },
  {
    large: "E",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "000",
    sub: "0000",
    item: "食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "090",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（09食料品製造業）",
  },
  {
    large: "E",
    middle: "09",
    minor: "090",
    sub: "0900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "09",
    minor: "090",
    sub: "0909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0000",
    item: "畜産食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0911",
    item: "部分肉・冷凍肉製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0912",
    item: "肉加工品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0913",
    item: "処理牛乳・乳飲料製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0914",
    item: "乳製品製造業（処理牛乳，乳飲料を除く）",
  },
  {
    large: "E",
    middle: "09",
    minor: "091",
    sub: "0919",
    item: "その他の畜産食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0000",
    item: "水産食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0921",
    item: "水産缶詰・瓶詰製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0922",
    item: "海藻加工業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0923",
    item: "水産練製品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0924",
    item: "塩干・塩蔵品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0925",
    item: "冷凍水産物製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0926",
    item: "冷凍水産食品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "092",
    sub: "0929",
    item: "その他の水産食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "093",
    sub: "0000",
    item: "野菜缶詰・果実缶詰・農産保存食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "093",
    sub: "0931",
    item: "野菜缶詰・果実缶詰・農産保存食料品製造業（野菜漬物を除く）",
  },
  {
    large: "E",
    middle: "09",
    minor: "093",
    sub: "0932",
    item: "野菜漬物製造業（缶詰，瓶詰，つぼ詰を除く）",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0000",
    item: "調味料製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0941",
    item: "味そ製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0942",
    item: "しょう油・食用アミノ酸製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0943",
    item: "ソース製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0944",
    item: "食酢製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "094",
    sub: "0949",
    item: "その他の調味料製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "095",
    sub: "0000",
    item: "糖類製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "095",
    sub: "0951",
    item: "砂糖製造業（砂糖精製業を除く）",
  },
  {
    large: "E",
    middle: "09",
    minor: "095",
    sub: "0952",
    item: "砂糖精製業",
  },
  {
    large: "E",
    middle: "09",
    minor: "095",
    sub: "0953",
    item: "ぶどう糖・水あめ・異性化糖製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "096",
    sub: "0000",
    item: "精穀・製粉業",
  },
  {
    large: "E",
    middle: "09",
    minor: "096",
    sub: "0961",
    item: "精米・精麦業",
  },
  {
    large: "E",
    middle: "09",
    minor: "096",
    sub: "0962",
    item: "小麦粉製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "096",
    sub: "0969",
    item: "その他の精穀・製粉業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0000",
    item: "パン・菓子製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0971",
    item: "パン製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0972",
    item: "生菓子製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0973",
    item: "ビスケット類・干菓子製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0974",
    item: "米菓製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "097",
    sub: "0979",
    item: "その他のパン・菓子製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "098",
    sub: "0000",
    item: "動植物油脂製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "098",
    sub: "0981",
    item: "動植物油脂製造業（食用油脂加工業を除く）",
  },
  {
    large: "E",
    middle: "09",
    minor: "098",
    sub: "0982",
    item: "食用油脂加工業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0000",
    item: "その他の食料品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0991",
    item: "でんぷん製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0992",
    item: "めん類製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0993",
    item: "豆腐・油揚製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0994",
    item: "あん類製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0995",
    item: "冷凍調理食品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0996",
    item: "そう（惣）菜製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0997",
    item: "すし・弁当・調理パン製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0998",
    item: "レトルト食品製造業",
  },
  {
    large: "E",
    middle: "09",
    minor: "099",
    sub: "0999",
    item: "他に分類されない食料品製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "000",
    sub: "0000",
    item: "飲料・たばこ・飼料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "100",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（10飲料・たばこ・飼料製造業）",
  },
  {
    large: "E",
    middle: "10",
    minor: "100",
    sub: "1000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "10",
    minor: "100",
    sub: "1009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "10",
    minor: "101",
    sub: "0000",
    item: "清涼飲料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "101",
    sub: "1011",
    item: "清涼飲料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "102",
    sub: "0000",
    item: "酒類製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "102",
    sub: "1021",
    item: "果実酒製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "102",
    sub: "1022",
    item: "ビール類製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "102",
    sub: "1023",
    item: "清酒製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "102",
    sub: "1024",
    item: "蒸留酒・混成酒製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "103",
    sub: "0000",
    item: "茶・コーヒー製造業（清涼飲料を除く）",
  },
  {
    large: "E",
    middle: "10",
    minor: "103",
    sub: "1031",
    item: "製茶業",
  },
  {
    large: "E",
    middle: "10",
    minor: "103",
    sub: "1032",
    item: "コーヒー製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "104",
    sub: "0000",
    item: "製氷業",
  },
  {
    large: "E",
    middle: "10",
    minor: "104",
    sub: "1041",
    item: "製氷業",
  },
  {
    large: "E",
    middle: "10",
    minor: "105",
    sub: "0000",
    item: "たばこ製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "105",
    sub: "1051",
    item: "たばこ製造業（葉たばこ処理業を除く)",
  },
  {
    large: "E",
    middle: "10",
    minor: "105",
    sub: "1052",
    item: "葉たばこ処理業",
  },
  {
    large: "E",
    middle: "10",
    minor: "106",
    sub: "0000",
    item: "飼料・有機質肥料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "106",
    sub: "1061",
    item: "配合飼料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "106",
    sub: "1062",
    item: "単体飼料製造業",
  },
  {
    large: "E",
    middle: "10",
    minor: "106",
    sub: "1063",
    item: "有機質肥料製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "000",
    sub: "0000",
    item: "繊維工業",
  },
  {
    large: "E",
    middle: "11",
    minor: "110",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（11繊維工業）",
  },
  {
    large: "E",
    middle: "11",
    minor: "110",
    sub: "1100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "11",
    minor: "110",
    sub: "1109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "0000",
    item: "製糸業，紡績業，化学繊維・ねん糸等製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1111",
    item: "製糸業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1112",
    item: "化学繊維製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1113",
    item: "炭素繊維製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1114",
    item: "綿紡績業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1115",
    item: "化学繊維紡績業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1116",
    item: "毛紡績業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1117",
    item: "ねん糸製造業（かさ高加工糸を除く）",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1118",
    item: "かさ高加工糸製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "111",
    sub: "1119",
    item: "その他の紡績業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "0000",
    item: "織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1121",
    item: "綿・スフ織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1122",
    item: "絹・人絹織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1123",
    item: "毛織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1124",
    item: "麻織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1125",
    item: "細幅織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "112",
    sub: "1129",
    item: "その他の織物業",
  },
  {
    large: "E",
    middle: "11",
    minor: "113",
    sub: "0000",
    item: "ニット生地製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "113",
    sub: "1131",
    item: "丸編ニット生地製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "113",
    sub: "1132",
    item: "たて編ニット生地製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "113",
    sub: "1133",
    item: "横編ニット生地製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "0000",
    item: "染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1141",
    item: "綿・スフ・麻織物機械染色業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1142",
    item: "絹・人絹織物機械染色業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1143",
    item: "毛織物機械染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1144",
    item: "織物整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1145",
    item: "織物手加工染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1146",
    item: "綿状繊維・糸染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1147",
    item: "ニット・レース染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "114",
    sub: "1148",
    item: "繊維雑品染色整理業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "0000",
    item: "綱・網・レース・繊維粗製品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1151",
    item: "綱製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1152",
    item: "漁網製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1153",
    item: "網地製造業（漁網を除く）",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1154",
    item: "レース製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1155",
    item: "組ひも製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1156",
    item: "整毛業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1157",
    item: "フェルト・不織布製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1158",
    item: "上塗りした織物・防水した織物製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "115",
    sub: "1159",
    item: "その他の繊維粗製品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "0000",
    item: "外衣・シャツ製造業（和式を除く）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1161",
    item: "織物製成人男子・少年服製造業（不織布製及びレース製を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1162",
    item: "織物製成人女子・少女服製造業（不織布製及びレース製を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1163",
    item: "織物製乳幼児服製造業（不織布製及びレース製を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1164",
    item: "織物製シャツ製造業（不織布製及びレース製を含み、下着を除く）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1165",
    item: "織物製事務用・作業用・衛生用・スポーツ用衣服・学校服製造業（不織布製及びレース製を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1166",
    item: "ニット製外衣製造業（アウターシャツ類，セーター類などを除く）",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1167",
    item: "ニット製アウターシャツ類製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1168",
    item: "セーター類製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "116",
    sub: "1169",
    item: "その他の外衣・シャツ製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "117",
    sub: "0000",
    item: "下着類製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "117",
    sub: "1171",
    item: "織物製下着製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "117",
    sub: "1172",
    item: "ニット製下着製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "117",
    sub: "1173",
    item: "織物製・ニット製寝着類製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "117",
    sub: "1174",
    item: "補整着製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "0000",
    item: "和装製品・その他の衣服・繊維製身の回り品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1181",
    item: "和装製品製造業（足袋を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1182",
    item: "ネクタイ製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1183",
    item: "スカーフ・マフラー・ハンカチーフ製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1184",
    item: "靴下製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1185",
    item: "手袋製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1186",
    item: "帽子製造業（帽体を含む）",
  },
  {
    large: "E",
    middle: "11",
    minor: "118",
    sub: "1189",
    item: "他に分類されない衣服・繊維製身の回り品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "0000",
    item: "その他の繊維製品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1191",
    item: "寝具製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1192",
    item: "毛布製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1193",
    item: "じゅうたん・その他の繊維製床敷物製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1194",
    item: "帆布製品製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1195",
    item: "繊維製袋製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1196",
    item: "刺しゅう業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1197",
    item: "タオル製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1198",
    item: "繊維製衛生材料製造業",
  },
  {
    large: "E",
    middle: "11",
    minor: "119",
    sub: "1199",
    item: "他に分類されない繊維製品製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "000",
    sub: "0000",
    item: "木材・木製品製造業（家具を除く）",
  },
  {
    large: "E",
    middle: "12",
    minor: "120",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（12木材・木製品製造業）",
  },
  {
    large: "E",
    middle: "12",
    minor: "120",
    sub: "1200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "12",
    minor: "120",
    sub: "1209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "12",
    minor: "121",
    sub: "0000",
    item: "製材業，木製品製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "121",
    sub: "1211",
    item: "一般製材業",
  },
  {
    large: "E",
    middle: "12",
    minor: "121",
    sub: "1212",
    item: "単板（ベニヤ）製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "121",
    sub: "1213",
    item: "木材チップ製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "121",
    sub: "1219",
    item: "その他の特殊製材業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "0000",
    item: "造作材・合板・建築用組立材料製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1221",
    item: "造作材製造業（建具を除く）",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1222",
    item: "合板製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1223",
    item: "集成材製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1224",
    item: "建築用木製組立材料製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1225",
    item: "パーティクルボード製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1226",
    item: "繊維板製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1227",
    item: "銘木製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "122",
    sub: "1228",
    item: "床板製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "123",
    sub: "0000",
    item: "木製容器製造業（竹，とうを含む）",
  },
  {
    large: "E",
    middle: "12",
    minor: "123",
    sub: "1231",
    item: "竹・とう・きりゅう等容器製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "123",
    sub: "1232",
    item: "木箱製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "123",
    sub: "1233",
    item: "たる・おけ製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "129",
    sub: "0000",
    item: "その他の木製品製造業(竹，とうを含む)",
  },
  {
    large: "E",
    middle: "12",
    minor: "129",
    sub: "1291",
    item: "木材薬品処理業",
  },
  {
    large: "E",
    middle: "12",
    minor: "129",
    sub: "1292",
    item: "コルク加工基礎資材・コルク製品製造業",
  },
  {
    large: "E",
    middle: "12",
    minor: "129",
    sub: "1299",
    item: "他に分類されない木製品製造業(竹，とうを含む)",
  },
  {
    large: "E",
    middle: "13",
    minor: "000",
    sub: "0000",
    item: "家具・装備品製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "130",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（13家具・装備品製造業）",
  },
  {
    large: "E",
    middle: "13",
    minor: "130",
    sub: "1300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "13",
    minor: "130",
    sub: "1309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "13",
    minor: "131",
    sub: "0000",
    item: "家具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "131",
    sub: "1311",
    item: "木製家具製造業（漆塗りを除く）",
  },
  {
    large: "E",
    middle: "13",
    minor: "131",
    sub: "1312",
    item: "金属製家具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "131",
    sub: "1313",
    item: "マットレス・組スプリング製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "132",
    sub: "0000",
    item: "宗教用具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "132",
    sub: "1321",
    item: "宗教用具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "133",
    sub: "0000",
    item: "建具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "133",
    sub: "1331",
    item: "建具製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "139",
    sub: "0000",
    item: "その他の家具・装備品製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "139",
    sub: "1391",
    item: "事務所用・店舗用装備品製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "139",
    sub: "1392",
    item: "窓用・扉用日よけ，日本びょうぶ等製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "139",
    sub: "1393",
    item: "鏡縁・額縁製造業",
  },
  {
    large: "E",
    middle: "13",
    minor: "139",
    sub: "1399",
    item: "他に分類されない家具・装備品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "000",
    sub: "0000",
    item: "パルプ・紙・紙加工品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "140",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（14パルプ・紙・紙加工品製造業）",
  },
  {
    large: "E",
    middle: "14",
    minor: "140",
    sub: "1400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "14",
    minor: "140",
    sub: "1409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "14",
    minor: "141",
    sub: "0000",
    item: "パルプ製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "141",
    sub: "1411",
    item: "パルプ製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "142",
    sub: "0000",
    item: "紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "142",
    sub: "1421",
    item: "洋紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "142",
    sub: "1422",
    item: "板紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "142",
    sub: "1423",
    item: "機械すき和紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "142",
    sub: "1424",
    item: "手すき和紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "143",
    sub: "0000",
    item: "加工紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "143",
    sub: "1431",
    item: "塗工紙製造業（印刷用紙を除く）",
  },
  {
    large: "E",
    middle: "14",
    minor: "143",
    sub: "1432",
    item: "段ボール製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "143",
    sub: "1433",
    item: "壁紙・ふすま紙製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "144",
    sub: "0000",
    item: "紙製品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "144",
    sub: "1441",
    item: "事務用・学用紙製品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "144",
    sub: "1442",
    item: "日用紙製品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "144",
    sub: "1449",
    item: "その他の紙製品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "145",
    sub: "0000",
    item: "紙製容器製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "145",
    sub: "1451",
    item: "重包装紙袋製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "145",
    sub: "1452",
    item: "角底紙袋製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "145",
    sub: "1453",
    item: "段ボール箱製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "145",
    sub: "1454",
    item: "紙器製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "149",
    sub: "0000",
    item: "その他のパルプ・紙・紙加工品製造業",
  },
  {
    large: "E",
    middle: "14",
    minor: "149",
    sub: "1499",
    item: "その他のパルプ・紙・紙加工品製造業",
  },
  {
    large: "E",
    middle: "15",
    minor: "000",
    sub: "0000",
    item: "印刷・同関連業",
  },
  {
    large: "E",
    middle: "15",
    minor: "150",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（15印刷・同関連業）",
  },
  {
    large: "E",
    middle: "15",
    minor: "150",
    sub: "1500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "15",
    minor: "150",
    sub: "1509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "15",
    minor: "151",
    sub: "0000",
    item: "印刷業",
  },
  {
    large: "E",
    middle: "15",
    minor: "151",
    sub: "1511",
    item: "オフセット印刷業（紙に対するもの）",
  },
  {
    large: "E",
    middle: "15",
    minor: "151",
    sub: "1512",
    item: "オフセット印刷以外の印刷業（紙に対するもの）",
  },
  {
    large: "E",
    middle: "15",
    minor: "151",
    sub: "1513",
    item: "紙以外の印刷業",
  },
  {
    large: "E",
    middle: "15",
    minor: "152",
    sub: "0000",
    item: "製版業",
  },
  {
    large: "E",
    middle: "15",
    minor: "152",
    sub: "1521",
    item: "製版業",
  },
  {
    large: "E",
    middle: "15",
    minor: "153",
    sub: "0000",
    item: "製本業，印刷物加工業",
  },
  {
    large: "E",
    middle: "15",
    minor: "153",
    sub: "1531",
    item: "製本業",
  },
  {
    large: "E",
    middle: "15",
    minor: "153",
    sub: "1532",
    item: "印刷物加工業",
  },
  {
    large: "E",
    middle: "15",
    minor: "159",
    sub: "0000",
    item: "印刷関連サービス業",
  },
  {
    large: "E",
    middle: "15",
    minor: "159",
    sub: "1591",
    item: "印刷関連サービス業",
  },
  {
    large: "E",
    middle: "16",
    minor: "000",
    sub: "0000",
    item: "化学工業",
  },
  {
    large: "E",
    middle: "16",
    minor: "160",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（16化学工業）",
  },
  {
    large: "E",
    middle: "16",
    minor: "160",
    sub: "1600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "16",
    minor: "160",
    sub: "1609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "16",
    minor: "161",
    sub: "0000",
    item: "化学肥料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "161",
    sub: "1611",
    item: "窒素質・りん酸質肥料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "161",
    sub: "1612",
    item: "複合肥料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "161",
    sub: "1619",
    item: "その他の化学肥料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "0000",
    item: "無機化学工業製品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "1621",
    item: "ソーダ工業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "1622",
    item: "無機顔料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "1623",
    item: "圧縮ガス・液化ガス製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "1624",
    item: "塩製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "162",
    sub: "1629",
    item: "その他の無機化学工業製品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "0000",
    item: "有機化学工業製品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1631",
    item: "石油化学系基礎製品製造業（一貫して生産される誘導品を含む）",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1632",
    item: "脂肪族系中間物製造業（脂肪族系溶剤を含む）",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1633",
    item: "発酵工業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1634",
    item: "環式中間物・合成染料・有機顔料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1635",
    item: "プラスチック製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1636",
    item: "合成ゴム製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "163",
    sub: "1639",
    item: "その他の有機化学工業製品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "0000",
    item: "油脂加工製品・石けん・合成洗剤・界面活性剤・塗料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1641",
    item: "脂肪酸・硬化油・グリセリン製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1642",
    item: "石けん・合成洗剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1643",
    item: "界面活性剤製造業（石けん，合成洗剤を除く）",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1644",
    item: "塗料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1645",
    item: "印刷インキ製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1646",
    item: "洗浄剤・磨用剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "164",
    sub: "1647",
    item: "ろうそく製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "0000",
    item: "医薬品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "1651",
    item: "医薬品原薬製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "1652",
    item: "医薬品製剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "1653",
    item: "生物学的製剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "1654",
    item: "生薬・漢方製剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "165",
    sub: "1655",
    item: "動物用医薬品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "166",
    sub: "0000",
    item: "化粧品・歯磨・その他の化粧用調整品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "166",
    sub: "1661",
    item: "仕上用・皮膚用化粧品製造業（香水，オーデコロンを含む）",
  },
  {
    large: "E",
    middle: "16",
    minor: "166",
    sub: "1662",
    item: "頭髪用化粧品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "166",
    sub: "1669",
    item: "その他の化粧品・歯磨・化粧用調整品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "0000",
    item: "その他の化学工業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1691",
    item: "火薬類製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1692",
    item: "農薬製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1693",
    item: "香料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1694",
    item: "ゼラチン・接着剤製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1695",
    item: "写真感光材料製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1696",
    item: "天然樹脂製品・木材化学製品製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1697",
    item: "試薬製造業",
  },
  {
    large: "E",
    middle: "16",
    minor: "169",
    sub: "1699",
    item: "他に分類されない化学工業製品製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "000",
    sub: "0000",
    item: "石油製品・石炭製品製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "170",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（17石油製品・石炭製品製造業）",
  },
  {
    large: "E",
    middle: "17",
    minor: "170",
    sub: "1700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "17",
    minor: "170",
    sub: "1709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "17",
    minor: "171",
    sub: "0000",
    item: "石油精製業",
  },
  {
    large: "E",
    middle: "17",
    minor: "171",
    sub: "1711",
    item: "石油精製業",
  },
  {
    large: "E",
    middle: "17",
    minor: "172",
    sub: "0000",
    item: "潤滑油・グリース製造業（石油精製業によらないもの）",
  },
  {
    large: "E",
    middle: "17",
    minor: "172",
    sub: "1721",
    item: "潤滑油・グリース製造業（石油精製業によらないもの）",
  },
  {
    large: "E",
    middle: "17",
    minor: "173",
    sub: "0000",
    item: "コークス製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "173",
    sub: "1731",
    item: "コークス製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "174",
    sub: "0000",
    item: "舗装材料製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "174",
    sub: "1741",
    item: "舗装材料製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "179",
    sub: "0000",
    item: "その他の石油製品・石炭製品製造業",
  },
  {
    large: "E",
    middle: "17",
    minor: "179",
    sub: "1799",
    item: "その他の石油製品・石炭製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "000",
    sub: "0000",
    item: "プラスチック製品製造業（別掲を除く）",
  },
  {
    large: "E",
    middle: "18",
    minor: "180",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（18プラスチック製品製造業）",
  },
  {
    large: "E",
    middle: "18",
    minor: "180",
    sub: "1800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "18",
    minor: "180",
    sub: "1809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "0000",
    item: "プラスチック板・棒・管・継手・異形押出製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "1811",
    item: "プラスチック板・棒製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "1812",
    item: "プラスチック管製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "1813",
    item: "プラスチック継手製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "1814",
    item: "プラスチック異形押出製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "181",
    sub: "1815",
    item: "プラスチック板・棒・管・継手・異形押出製品加工業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "0000",
    item: "プラスチックフィルム・シート・床材・合成皮革製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "1821",
    item: "プラスチックフィルム製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "1822",
    item: "プラスチックシート製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "1823",
    item: "プラスチック床材製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "1824",
    item: "合成皮革製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "182",
    sub: "1825",
    item: "プラスチックフィルム・シート・床材・合成皮革加工業",
  },
  {
    large: "E",
    middle: "18",
    minor: "183",
    sub: "0000",
    item: "工業用プラスチック製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "183",
    sub: "1831",
    item: "電気機械器具用プラスチック製品製造業（加工業を除く）",
  },
  {
    large: "E",
    middle: "18",
    minor: "183",
    sub: "1832",
    item: "輸送機械器具用プラスチック製品製造業（加工業を除く）",
  },
  {
    large: "E",
    middle: "18",
    minor: "183",
    sub: "1833",
    item: "その他の工業用プラスチック製品製造業（加工業を除く）",
  },
  {
    large: "E",
    middle: "18",
    minor: "183",
    sub: "1834",
    item: "工業用プラスチック製品加工業",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "0000",
    item: "発泡・強化プラスチック製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "1841",
    item: "軟質プラスチック発泡製品製造業（半硬質性を含む）",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "1842",
    item: "硬質プラスチック発泡製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "1843",
    item: "強化プラスチック製板・棒・管・継手製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "1844",
    item: "強化プラスチック製容器・浴槽等製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "184",
    sub: "1845",
    item: "発泡・強化プラスチック製品加工業",
  },
  {
    large: "E",
    middle: "18",
    minor: "185",
    sub: "0000",
    item: "プラスチック成形材料製造業（廃プラスチックを含む）",
  },
  {
    large: "E",
    middle: "18",
    minor: "185",
    sub: "1851",
    item: "プラスチック成形材料製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "185",
    sub: "1852",
    item: "廃プラスチック製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "189",
    sub: "0000",
    item: "その他のプラスチック製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "189",
    sub: "1891",
    item: "プラスチック製日用雑貨・食卓用品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "189",
    sub: "1892",
    item: "プラスチック製容器製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "189",
    sub: "1897",
    item: "他に分類されないプラスチック製品製造業",
  },
  {
    large: "E",
    middle: "18",
    minor: "189",
    sub: "1898",
    item: "他に分類されないプラスチック製品加工業",
  },
  {
    large: "E",
    middle: "19",
    minor: "000",
    sub: "0000",
    item: "ゴム製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "190",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（19ゴム製品製造業）",
  },
  {
    large: "E",
    middle: "19",
    minor: "190",
    sub: "1900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "19",
    minor: "190",
    sub: "1909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "19",
    minor: "191",
    sub: "0000",
    item: "タイヤ・チューブ製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "191",
    sub: "1911",
    item: "自動車タイヤ・チューブ製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "191",
    sub: "1919",
    item: "その他のタイヤ・チューブ製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "192",
    sub: "0000",
    item: "ゴム製・プラスチック製履物・同附属品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "192",
    sub: "1921",
    item: "ゴム製履物・同附属品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "192",
    sub: "1922",
    item: "プラスチック製履物・同附属品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "193",
    sub: "0000",
    item: "ゴムベルト・ゴムホース・工業用ゴム製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "193",
    sub: "1931",
    item: "ゴムベルト製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "193",
    sub: "1932",
    item: "ゴムホース製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "193",
    sub: "1933",
    item: "工業用ゴム製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "0000",
    item: "その他のゴム製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1991",
    item: "ゴム引布・同製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1992",
    item: "医療・衛生用ゴム製品製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1993",
    item: "ゴム練生地製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1994",
    item: "更生タイヤ製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1995",
    item: "再生ゴム製造業",
  },
  {
    large: "E",
    middle: "19",
    minor: "199",
    sub: "1999",
    item: "他に分類されないゴム製品製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "000",
    sub: "0000",
    item: "なめし革・同製品・毛皮製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "200",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（20なめし革・同製品・毛皮製造業）",
  },
  {
    large: "E",
    middle: "20",
    minor: "200",
    sub: "2000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "20",
    minor: "200",
    sub: "2009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "20",
    minor: "201",
    sub: "0000",
    item: "なめし革製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "201",
    sub: "2011",
    item: "なめし革製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "202",
    sub: "0000",
    item: "工業用革製品製造業（手袋を除く）",
  },
  {
    large: "E",
    middle: "20",
    minor: "202",
    sub: "2021",
    item: "工業用革製品製造業（手袋を除く）",
  },
  {
    large: "E",
    middle: "20",
    minor: "203",
    sub: "0000",
    item: "革製履物用材料・同附属品製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "203",
    sub: "2031",
    item: "革製履物用材料・同附属品製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "204",
    sub: "0000",
    item: "革製履物製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "204",
    sub: "2041",
    item: "革製履物製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "205",
    sub: "0000",
    item: "革製手袋製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "205",
    sub: "2051",
    item: "革製手袋製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "206",
    sub: "0000",
    item: "かばん製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "206",
    sub: "2061",
    item: "かばん製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "207",
    sub: "0000",
    item: "袋物製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "207",
    sub: "2071",
    item: "袋物製造業（ハンドバッグを除く）",
  },
  {
    large: "E",
    middle: "20",
    minor: "207",
    sub: "2072",
    item: "ハンドバッグ製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "208",
    sub: "0000",
    item: "毛皮製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "208",
    sub: "2081",
    item: "毛皮製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "209",
    sub: "0000",
    item: "その他のなめし革製品製造業",
  },
  {
    large: "E",
    middle: "20",
    minor: "209",
    sub: "2099",
    item: "その他のなめし革製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "000",
    sub: "0000",
    item: "窯業・土石製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "210",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（21窯業・土石製品製造業）",
  },
  {
    large: "E",
    middle: "21",
    minor: "210",
    sub: "2100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "21",
    minor: "210",
    sub: "2109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "0000",
    item: "ガラス・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2111",
    item: "板ガラス製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2112",
    item: "板ガラス加工業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2113",
    item: "ガラス製加工素材製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2114",
    item: "ガラス容器製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2115",
    item: "理化学用・医療用ガラス器具製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2116",
    item: "卓上用・ちゅう房用ガラス器具製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2117",
    item: "ガラス繊維・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "211",
    sub: "2119",
    item: "その他のガラス・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "212",
    sub: "0000",
    item: "セメント・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "212",
    sub: "2121",
    item: "セメント製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "212",
    sub: "2122",
    item: "生コンクリート製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "212",
    sub: "2123",
    item: "コンクリート製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "212",
    sub: "2129",
    item: "その他のセメント製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "213",
    sub: "0000",
    item: "建設用粘土製品製造業（陶磁器製を除く)",
  },
  {
    large: "E",
    middle: "21",
    minor: "213",
    sub: "2131",
    item: "粘土かわら製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "213",
    sub: "2132",
    item: "普通れんが製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "213",
    sub: "2139",
    item: "その他の建設用粘土製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "0000",
    item: "陶磁器・同関連製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2141",
    item: "衛生陶器製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2142",
    item: "食卓用・ちゅう房用陶磁器製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2143",
    item: "陶磁器製置物製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2144",
    item: "電気用陶磁器製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2145",
    item: "理化学用・工業用陶磁器製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2146",
    item: "陶磁器製タイル製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2147",
    item: "陶磁器絵付業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2148",
    item: "陶磁器用はい（坏）土製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "214",
    sub: "2149",
    item: "その他の陶磁器・同関連製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "215",
    sub: "0000",
    item: "耐火物製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "215",
    sub: "2151",
    item: "耐火れんが製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "215",
    sub: "2152",
    item: "不定形耐火物製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "215",
    sub: "2159",
    item: "その他の耐火物製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "216",
    sub: "0000",
    item: "炭素・黒鉛製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "216",
    sub: "2161",
    item: "炭素質電極製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "216",
    sub: "2169",
    item: "その他の炭素・黒鉛製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "217",
    sub: "0000",
    item: "研磨材・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "217",
    sub: "2171",
    item: "研磨材製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "217",
    sub: "2172",
    item: "研削と石製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "217",
    sub: "2173",
    item: "研磨布紙製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "217",
    sub: "2179",
    item: "その他の研磨材・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "0000",
    item: "骨材・石工品等製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2181",
    item: "砕石製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2182",
    item: "再生骨材製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2183",
    item: "人工骨材製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2184",
    item: "石工品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2185",
    item: "けいそう土・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "218",
    sub: "2186",
    item: "鉱物・土石粉砕等処理業",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "0000",
    item: "その他の窯業・土石製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "2191",
    item: "ロックウール・同製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "2192",
    item: "石こう（膏）製品製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "2193",
    item: "石灰製造業",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "2194",
    item: "鋳型製造業（中子を含む）",
  },
  {
    large: "E",
    middle: "21",
    minor: "219",
    sub: "2199",
    item: "他に分類されない窯業・土石製品製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "000",
    sub: "0000",
    item: "鉄鋼業",
  },
  {
    large: "E",
    middle: "22",
    minor: "220",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（22鉄鋼業）",
  },
  {
    large: "E",
    middle: "22",
    minor: "220",
    sub: "2200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "22",
    minor: "220",
    sub: "2209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "22",
    minor: "221",
    sub: "0000",
    item: "製鉄業",
  },
  {
    large: "E",
    middle: "22",
    minor: "221",
    sub: "2211",
    item: "高炉による製鉄業",
  },
  {
    large: "E",
    middle: "22",
    minor: "221",
    sub: "2212",
    item: "高炉によらない製鉄業",
  },
  {
    large: "E",
    middle: "22",
    minor: "221",
    sub: "2213",
    item: "フェロアロイ製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "222",
    sub: "0000",
    item: "製鋼・製鋼圧延業",
  },
  {
    large: "E",
    middle: "22",
    minor: "222",
    sub: "2221",
    item: "製鋼・製鋼圧延業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "0000",
    item: "製鋼を行わない鋼材製造業（表面処理鋼材を除く）",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2231",
    item: "熱間圧延業（鋼管，伸鉄を除く）",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2232",
    item: "冷間圧延業（鋼管，伸鉄を除く）",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2233",
    item: "冷間ロール成型形鋼製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2234",
    item: "鋼管製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2235",
    item: "伸鉄業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2236",
    item: "磨棒鋼製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2237",
    item: "引抜鋼管製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2238",
    item: "伸線業",
  },
  {
    large: "E",
    middle: "22",
    minor: "223",
    sub: "2239",
    item: "その他の製鋼を行わない鋼材製造業（表面処理鋼材を除く)",
  },
  {
    large: "E",
    middle: "22",
    minor: "224",
    sub: "0000",
    item: "表面処理鋼材製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "224",
    sub: "2241",
    item: "亜鉛鉄板製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "224",
    sub: "2249",
    item: "その他の表面処理鋼材製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "0000",
    item: "鉄素形材製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "2251",
    item: "銑鉄鋳物製造業（鋳鉄管，可鍛鋳鉄を除く）",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "2252",
    item: "可鍛鋳鉄製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "2253",
    item: "鋳鋼製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "2254",
    item: "鍛工品製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "225",
    sub: "2255",
    item: "鍛鋼製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "229",
    sub: "0000",
    item: "その他の鉄鋼業",
  },
  {
    large: "E",
    middle: "22",
    minor: "229",
    sub: "2291",
    item: "鉄鋼シャースリット業",
  },
  {
    large: "E",
    middle: "22",
    minor: "229",
    sub: "2292",
    item: "鉄スクラップ加工処理業",
  },
  {
    large: "E",
    middle: "22",
    minor: "229",
    sub: "2293",
    item: "鋳鉄管製造業",
  },
  {
    large: "E",
    middle: "22",
    minor: "229",
    sub: "2299",
    item: "他に分類されない鉄鋼業",
  },
  {
    large: "E",
    middle: "23",
    minor: "000",
    sub: "0000",
    item: "非鉄金属製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "230",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（23非鉄金属製造業）",
  },
  {
    large: "E",
    middle: "23",
    minor: "230",
    sub: "2300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "23",
    minor: "230",
    sub: "2309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "23",
    minor: "231",
    sub: "0000",
    item: "非鉄金属第1次製錬・精製業",
  },
  {
    large: "E",
    middle: "23",
    minor: "231",
    sub: "2311",
    item: "銅第1次製錬・精製業",
  },
  {
    large: "E",
    middle: "23",
    minor: "231",
    sub: "2312",
    item: "亜鉛第1次製錬・精製業",
  },
  {
    large: "E",
    middle: "23",
    minor: "231",
    sub: "2319",
    item: "その他の非鉄金属第1次製錬・精製業",
  },
  {
    large: "E",
    middle: "23",
    minor: "232",
    sub: "0000",
    item: "非鉄金属第2次製錬・精製業（非鉄金属合金製造業を含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "232",
    sub: "2321",
    item: "鉛第2次製錬・精製業（鉛合金製造業を含む)",
  },
  {
    large: "E",
    middle: "23",
    minor: "232",
    sub: "2322",
    item: "アルミニウム第2次製錬・精製業（アルミニウム合金製造業を含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "232",
    sub: "2329",
    item: "その他の非鉄金属第2次製錬・精製業（非鉄金属合金製造業を含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "233",
    sub: "0000",
    item: "非鉄金属・同合金圧延業（抽伸，押出しを含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "233",
    sub: "2331",
    item: "伸銅品製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "233",
    sub: "2332",
    item: "アルミニウム・同合金圧延業（抽伸，押出しを含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "233",
    sub: "2339",
    item: "その他の非鉄金属・同合金圧延業（抽伸，押出しを含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "234",
    sub: "0000",
    item: "電線・ケーブル製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "234",
    sub: "2341",
    item: "電線・ケーブル製造業（光ファイバケーブルを除く）",
  },
  {
    large: "E",
    middle: "23",
    minor: "234",
    sub: "2342",
    item: "光ファイバケーブル製造業（通信複合ケーブルを含む）",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "0000",
    item: "非鉄金属素形材製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "2351",
    item: "銅・同合金鋳物製造業（ダイカストを除く）",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "2352",
    item: "非鉄金属鋳物製造業（銅・同合金鋳物及びダイカストを除く）",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "2353",
    item: "アルミニウム・同合金ダイカスト製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "2354",
    item: "非鉄金属ダイカスト製造業（アルミニウム・同合金ダイカストを除く）",
  },
  {
    large: "E",
    middle: "23",
    minor: "235",
    sub: "2355",
    item: "非鉄金属鍛造品製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "239",
    sub: "0000",
    item: "その他の非鉄金属製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "239",
    sub: "2391",
    item: "核燃料製造業",
  },
  {
    large: "E",
    middle: "23",
    minor: "239",
    sub: "2399",
    item: "他に分類されない非鉄金属製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "000",
    sub: "0000",
    item: "金属製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "240",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（24金属製品製造業）",
  },
  {
    large: "E",
    middle: "24",
    minor: "240",
    sub: "2400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "24",
    minor: "240",
    sub: "2409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "24",
    minor: "241",
    sub: "0000",
    item: "ブリキ缶・その他のめっき板等製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "241",
    sub: "2411",
    item: "ブリキ缶・その他のめっき板等製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "0000",
    item: "洋食器・刃物・手道具・金物類製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2421",
    item: "洋食器製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2422",
    item: "機械刃物製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2423",
    item: "利器工匠具・手道具製造業（やすり，のこぎり，食卓用刃物を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2424",
    item: "作業工具製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2425",
    item: "手引のこぎり・のこ刃製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2426",
    item: "農業用器具製造業（農業用機械を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "242",
    sub: "2429",
    item: "その他の金物類製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "243",
    sub: "0000",
    item: '"暖房・調理等装置',
  },
  {
    large: "E",
    middle: "24",
    minor: "243",
    sub: "2431",
    item: "配管工事用附属品製造業（バルブ，コックを除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "243",
    sub: "2432",
    item: "ガス機器・石油機器製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "243",
    sub: "2433",
    item: "温風・温水暖房装置製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "243",
    sub: "2439",
    item: "その他の暖房・調理装置製造業（電気機械器具，ガス機器，石油機器を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "0000",
    item: "建設用・建築用金属製品製造業（製缶板金業を含む)",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2441",
    item: "鉄骨製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2442",
    item: "建設用金属製品製造業（鉄骨を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2443",
    item: "金属製サッシ・ドア製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2444",
    item: "鉄骨系プレハブ住宅製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2445",
    item: "建築用金属製品製造業（サッシ，ドア，建築用金物を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "244",
    sub: "2446",
    item: "製缶板金業",
  },
  {
    large: "E",
    middle: "24",
    minor: "245",
    sub: "0000",
    item: "金属素形材製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "245",
    sub: "2451",
    item: "アルミニウム・同合金プレス製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "245",
    sub: "2452",
    item: "金属プレス製品製造業（アルミニウム・同合金を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "245",
    sub: "2453",
    item: "粉末や金製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "0000",
    item: "金属被覆・彫刻業，熱処理業（ほうろう鉄器を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2461",
    item: "金属製品塗装業",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2462",
    item: "溶融めっき業（表面処理鋼材製造業を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2463",
    item: "金属彫刻業",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2464",
    item: "電気めっき業（表面処理鋼材製造業を除く）",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2465",
    item: "金属熱処理業",
  },
  {
    large: "E",
    middle: "24",
    minor: "246",
    sub: "2469",
    item: "その他の金属表面処理業",
  },
  {
    large: "E",
    middle: "24",
    minor: "247",
    sub: "0000",
    item: "金属線製品製造業（ねじ類を除く)",
  },
  {
    large: "E",
    middle: "24",
    minor: "247",
    sub: "2471",
    item: "くぎ製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "247",
    sub: "2479",
    item: "その他の金属線製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "248",
    sub: "0000",
    item: "ボルト・ナット・リベット・小ねじ・木ねじ等製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "248",
    sub: "2481",
    item: "ボルト・ナット・リベット・小ねじ・木ねじ等製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "249",
    sub: "0000",
    item: "その他の金属製品製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "249",
    sub: "2491",
    item: "金庫製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "249",
    sub: "2492",
    item: "金属製スプリング製造業",
  },
  {
    large: "E",
    middle: "24",
    minor: "249",
    sub: "2499",
    item: "他に分類されない金属製品製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "000",
    sub: "0000",
    item: "はん用機械器具製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "250",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（25はん用機械器具製造業）",
  },
  {
    large: "E",
    middle: "25",
    minor: "250",
    sub: "2500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "25",
    minor: "250",
    sub: "2509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "25",
    minor: "251",
    sub: "0000",
    item: "ボイラ・原動機製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "251",
    sub: "2511",
    item: "ボイラ製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "251",
    sub: "2512",
    item: "蒸気機関・タービン・水力タービン製造業（舶用を除く）",
  },
  {
    large: "E",
    middle: "25",
    minor: "251",
    sub: "2513",
    item: "はん用内燃機関製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "251",
    sub: "2519",
    item: "その他の原動機製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "252",
    sub: "0000",
    item: "ポンプ・圧縮機器製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "252",
    sub: "2521",
    item: "ポンプ・同装置製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "252",
    sub: "2522",
    item: "空気圧縮機・ガス圧縮機・送風機製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "252",
    sub: "2523",
    item: "油圧・空圧機器製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "0000",
    item: "一般産業用機械・装置製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "2531",
    item: "動力伝導装置製造業（玉軸受，ころ軸受を除く）",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "2532",
    item: "エレベータ・エスカレータ製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "2533",
    item: "物流運搬設備製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "2534",
    item: "工業窯炉製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "253",
    sub: "2535",
    item: "冷凍機・温湿調整装置製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "0000",
    item: "その他のはん用機械・同部分品製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2591",
    item: "消火器具・消火装置製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2592",
    item: "弁・同附属品製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2593",
    item: "パイプ加工・パイプ附属品加工業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2594",
    item: "玉軸受・ころ軸受製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2595",
    item: "ピストンリング製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2596",
    item: "他に分類されないはん用機械・装置製造業",
  },
  {
    large: "E",
    middle: "25",
    minor: "259",
    sub: "2599",
    item: "各種機械・同部分品製造修理業（注文製造・修理）",
  },
  {
    large: "E",
    middle: "26",
    minor: "000",
    sub: "0000",
    item: "生産用機械器具製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "260",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（26生産用機械器具製造業）",
  },
  {
    large: "E",
    middle: "26",
    minor: "260",
    sub: "2600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "26",
    minor: "260",
    sub: "2609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "26",
    minor: "261",
    sub: "0000",
    item: "農業用機械製造業（農業用器具を除く）",
  },
  {
    large: "E",
    middle: "26",
    minor: "261",
    sub: "2611",
    item: "農業用機械製造業（農業用器具を除く）",
  },
  {
    large: "E",
    middle: "26",
    minor: "262",
    sub: "0000",
    item: "建設機械・鉱山機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "262",
    sub: "2621",
    item: "建設機械・鉱山機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "0000",
    item: "繊維機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "2631",
    item: "化学繊維機械・紡績機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "2632",
    item: "製織機械・編組機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "2633",
    item: "染色整理仕上機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "2634",
    item: "繊維機械部分品・取付具・附属品製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "263",
    sub: "2635",
    item: "縫製機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "0000",
    item: "生活関連産業用機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "2641",
    item: "食品機械・同装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "2642",
    item: "木材加工機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "2643",
    item: "パルプ装置・製紙機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "2644",
    item: "印刷・製本・紙工機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "264",
    sub: "2645",
    item: "包装・荷造機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "265",
    sub: "0000",
    item: "基礎素材産業用機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "265",
    sub: "2651",
    item: "鋳造装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "265",
    sub: "2652",
    item: "化学機械・同装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "265",
    sub: "2653",
    item: "プラスチック加工機械・同附属装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "266",
    sub: "0000",
    item: "金属加工機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "266",
    sub: "2661",
    item: "金属工作機械製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "266",
    sub: "2662",
    item: "金属加工機械製造業（金属工作機械を除く）",
  },
  {
    large: "E",
    middle: "26",
    minor: "266",
    sub: "2663",
    item: "金属工作機械用・金属加工機械用部分品・附属品製造業（機械工具，金型を除く）",
  },
  {
    large: "E",
    middle: "26",
    minor: "266",
    sub: "2664",
    item: "機械工具製造業（粉末や金業を除く）",
  },
  {
    large: "E",
    middle: "26",
    minor: "267",
    sub: "0000",
    item: "半導体・フラットパネルディスプレイ製造装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "267",
    sub: "2671",
    item: "半導体製造装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "267",
    sub: "2672",
    item: "フラットパネルディスプレイ製造装置製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "0000",
    item: "その他の生産用機械・同部分品製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "2691",
    item: "金属用金型・同部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "2692",
    item: "非金属用金型・同部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "2693",
    item: "真空装置・真空機器製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "2694",
    item: "ロボット製造業",
  },
  {
    large: "E",
    middle: "26",
    minor: "269",
    sub: "2699",
    item: "他に分類されない生産用機械・同部分品製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "000",
    sub: "0000",
    item: "業務用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "270",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（27業務用機械器具製造業）",
  },
  {
    large: "E",
    middle: "27",
    minor: "270",
    sub: "2700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "27",
    minor: "270",
    sub: "2709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "27",
    minor: "271",
    sub: "0000",
    item: "事務用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "271",
    sub: "2711",
    item: "複写機製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "271",
    sub: "2719",
    item: "その他の事務用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "272",
    sub: "0000",
    item: "サービス用・娯楽用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "272",
    sub: "2721",
    item: "サービス用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "272",
    sub: "2722",
    item: "娯楽用機械製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "272",
    sub: "2723",
    item: "自動販売機製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "272",
    sub: "2729",
    item: "その他のサービス用・娯楽用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "0000",
    item: "計量器・測定器・分析機器・試験機・測量機械器具・理化学機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2731",
    item: "体積計製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2732",
    item: "はかり製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2733",
    item: "圧力計・流量計・液面計等製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2734",
    item: "精密測定器製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2735",
    item: "分析機器製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2736",
    item: "試験機製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2737",
    item: "測量機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2738",
    item: "理化学機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "273",
    sub: "2739",
    item: "その他の計量器・測定器・分析機器・試験機・測量機械器具・理化学機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "274",
    sub: "0000",
    item: "医療用機械器具・医療用品製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "274",
    sub: "2741",
    item: "医療用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "274",
    sub: "2742",
    item: "歯科用機械器具製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "274",
    sub: "2743",
    item: "医療用品製造業（動物用医療機械器具を含む）",
  },
  {
    large: "E",
    middle: "27",
    minor: "274",
    sub: "2744",
    item: "歯科材料製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "275",
    sub: "0000",
    item: "光学機械器具・レンズ製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "275",
    sub: "2751",
    item: "顕微鏡・望遠鏡等製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "275",
    sub: "2752",
    item: "写真機・映画用機械・同附属品製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "275",
    sub: "2753",
    item: "光学機械用レンズ・プリズム製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "276",
    sub: "0000",
    item: "武器製造業",
  },
  {
    large: "E",
    middle: "27",
    minor: "276",
    sub: "2761",
    item: "武器製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "000",
    sub: "0000",
    item: "電子部品・デバイス・電子回路製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "280",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（28電子部品・デバイス・電子回路製造業）",
  },
  {
    large: "E",
    middle: "28",
    minor: "280",
    sub: "2800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "28",
    minor: "280",
    sub: "2809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "0000",
    item: "電子デバイス製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "2811",
    item: "電子管製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "2812",
    item: "光電変換素子製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "2813",
    item: "半導体素子製造業（光電変換素子を除く）",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "2814",
    item: "集積回路製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "281",
    sub: "2815",
    item: "液晶パネル・フラットパネル製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "282",
    sub: "0000",
    item: "電子部品製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "282",
    sub: "2821",
    item: "抵抗器・コンデンサ・変成器・複合部品製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "282",
    sub: "2822",
    item: "音響部品・磁気ヘッド・小形モータ製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "282",
    sub: "2823",
    item: "コネクタ・スイッチ・リレー製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "283",
    sub: "0000",
    item: "記録メディア製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "283",
    sub: "2831",
    item: "半導体メモリメディア製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "283",
    sub: "2832",
    item: "光ディスク・磁気ディスク・磁気テープ製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "284",
    sub: "0000",
    item: "電子回路製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "284",
    sub: "2841",
    item: "電子回路基板製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "284",
    sub: "2842",
    item: "電子回路実装基板製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "285",
    sub: "0000",
    item: "ユニット部品製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "285",
    sub: "2851",
    item: "電源ユニット・高周波ユニット・コントロールユニット製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "285",
    sub: "2859",
    item: "その他のユニット部品製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "289",
    sub: "0000",
    item: "その他の電子部品・デバイス・電子回路製造業",
  },
  {
    large: "E",
    middle: "28",
    minor: "289",
    sub: "2899",
    item: "その他の電子部品・デバイス・電子回路製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "000",
    sub: "0000",
    item: "電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "290",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（29電気機械器具製造業）",
  },
  {
    large: "E",
    middle: "29",
    minor: "290",
    sub: "2900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "29",
    minor: "290",
    sub: "2909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "0000",
    item: "発電用・送電用・配電用電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "2911",
    item: "発電機・電動機・その他の回転電気機械製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "2912",
    item: "変圧器類製造業（電子機器用を除く)",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "2913",
    item: "電力開閉装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "2914",
    item: "配電盤・電力制御装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "291",
    sub: "2915",
    item: "配線器具・配線附属品製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "292",
    sub: "0000",
    item: "産業用電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "292",
    sub: "2921",
    item: "電気溶接機製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "292",
    sub: "2922",
    item: "内燃機関電装品製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "292",
    sub: "2929",
    item: "その他の産業用電気機械器具製造業（車両用，船舶用を含む）",
  },
  {
    large: "E",
    middle: "29",
    minor: "293",
    sub: "0000",
    item: "民生用電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "293",
    sub: "2931",
    item: "ちゅう房機器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "293",
    sub: "2932",
    item: "空調・住宅関連機器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "293",
    sub: "2933",
    item: "衣料衛生関連機器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "293",
    sub: "2939",
    item: "その他の民生用電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "294",
    sub: "0000",
    item: "電球・電気照明器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "294",
    sub: "2941",
    item: "電球製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "294",
    sub: "2942",
    item: "電気照明器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "295",
    sub: "0000",
    item: "電池製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "295",
    sub: "2951",
    item: "蓄電池製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "295",
    sub: "2952",
    item: "一次電池（乾電池，湿電池）製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "296",
    sub: "0000",
    item: "電子応用装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "296",
    sub: "2961",
    item: "X線装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "296",
    sub: "2962",
    item: "医療用電子応用装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "296",
    sub: "2969",
    item: "その他の電子応用装置製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "297",
    sub: "0000",
    item: "電気計測器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "297",
    sub: "2971",
    item: "電気計測器製造業（別掲を除く）",
  },
  {
    large: "E",
    middle: "29",
    minor: "297",
    sub: "2972",
    item: "工業計器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "297",
    sub: "2973",
    item: "医療用計測器製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "299",
    sub: "0000",
    item: "その他の電気機械器具製造業",
  },
  {
    large: "E",
    middle: "29",
    minor: "299",
    sub: "2999",
    item: "その他の電気機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "000",
    sub: "0000",
    item: "情報通信機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "300",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（30情報通信機械器具製造業）",
  },
  {
    large: "E",
    middle: "30",
    minor: "300",
    sub: "3000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "30",
    minor: "300",
    sub: "3009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "0000",
    item: "通信機械器具・同関連機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3011",
    item: "有線通信機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3012",
    item: "携帯電話機・PHS電話機製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3013",
    item: "無線通信機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3014",
    item: "ラジオ受信機・テレビジョン受信機製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3015",
    item: "交通信号保安装置製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "301",
    sub: "3019",
    item: "その他の通信機械器具・同関連機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "302",
    sub: "0000",
    item: "映像・音響機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "302",
    sub: "3021",
    item: "ビデオ機器製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "302",
    sub: "3022",
    item: "デジタルカメラ製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "302",
    sub: "3023",
    item: "電気音響機械器具製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "0000",
    item: "電子計算機・同附属装置製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3031",
    item: "電子計算機製造業（パーソナルコンピュータを除く）",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3032",
    item: "パーソナルコンピュータ製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3033",
    item: "外部記憶装置製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3034",
    item: "印刷装置製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3035",
    item: "表示装置製造業",
  },
  {
    large: "E",
    middle: "30",
    minor: "303",
    sub: "3039",
    item: "その他の附属装置製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "000",
    sub: "0000",
    item: "輸送用機械器具製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "310",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（31輸送用機械器具製造業）",
  },
  {
    large: "E",
    middle: "31",
    minor: "310",
    sub: "3100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "31",
    minor: "310",
    sub: "3109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "31",
    minor: "311",
    sub: "0000",
    item: "自動車・同附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "311",
    sub: "3111",
    item: "自動車製造業（二輪自動車を含む）",
  },
  {
    large: "E",
    middle: "31",
    minor: "311",
    sub: "3112",
    item: "自動車車体・附随車製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "311",
    sub: "3113",
    item: "自動車部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "312",
    sub: "0000",
    item: "鉄道車両・同部分品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "312",
    sub: "3121",
    item: "鉄道車両製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "312",
    sub: "3122",
    item: "鉄道車両用部分品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "313",
    sub: "0000",
    item: "船舶製造・修理業，舶用機関製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "313",
    sub: "3131",
    item: "船舶製造・修理業",
  },
  {
    large: "E",
    middle: "31",
    minor: "313",
    sub: "3132",
    item: "船体ブロック製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "313",
    sub: "3133",
    item: "舟艇製造・修理業",
  },
  {
    large: "E",
    middle: "31",
    minor: "313",
    sub: "3134",
    item: "舶用機関製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "314",
    sub: "0000",
    item: "航空機・同附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "314",
    sub: "3141",
    item: "航空機製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "314",
    sub: "3142",
    item: "航空機用原動機製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "314",
    sub: "3149",
    item: "その他の航空機部分品・補助装置製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "315",
    sub: "0000",
    item: "産業用運搬車両・同部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "315",
    sub: "3151",
    item: "フォークリフトトラック・同部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "315",
    sub: "3159",
    item: "その他の産業用運搬車両・同部分品・附属品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "319",
    sub: "0000",
    item: "その他の輸送用機械器具製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "319",
    sub: "3191",
    item: "自転車・同部分品製造業",
  },
  {
    large: "E",
    middle: "31",
    minor: "319",
    sub: "3199",
    item: "他に分類されない輸送用機械器具製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "000",
    sub: "0000",
    item: "その他の製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "320",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（32その他の製造業）",
  },
  {
    large: "E",
    middle: "32",
    minor: "320",
    sub: "3200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "E",
    middle: "32",
    minor: "320",
    sub: "3209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "E",
    middle: "32",
    minor: "321",
    sub: "0000",
    item: "貴金属・宝石製品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "321",
    sub: "3211",
    item: "貴金属・宝石製装身具（ジュエリー）製品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "321",
    sub: "3212",
    item: "貴金属・宝石製装身具（ジュエリー）附属品・同材料加工業",
  },
  {
    large: "E",
    middle: "32",
    minor: "321",
    sub: "3219",
    item: "その他の貴金属製品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "0000",
    item: "装身具・装飾品・ボタン・同関連品製造業（貴金属・宝石製を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "3221",
    item: "装身具・装飾品製造業（貴金属・宝石製を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "3222",
    item: "造花・装飾用羽毛製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "3223",
    item: "ボタン製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "3224",
    item: "針・ピン・ホック・スナップ・同関連品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "322",
    sub: "3229",
    item: "その他の装身具・装飾品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "323",
    sub: "0000",
    item: "時計・同部分品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "323",
    sub: "3231",
    item: "時計・同部分品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "324",
    sub: "0000",
    item: "楽器製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "324",
    sub: "3241",
    item: "ピアノ製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "324",
    sub: "3249",
    item: "その他の楽器・楽器部品・同材料製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "325",
    sub: "0000",
    item: "がん具・運動用具製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "325",
    sub: "3251",
    item: "娯楽用具・がん具製造業（人形を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "325",
    sub: "3252",
    item: "人形製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "325",
    sub: "3253",
    item: "運動用具製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "326",
    sub: "0000",
    item: "ペン・鉛筆・絵画用品・その他の事務用品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "326",
    sub: "3261",
    item: "万年筆・ペン類・鉛筆製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "326",
    sub: "3262",
    item: "毛筆・絵画用品製造業（鉛筆を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "326",
    sub: "3269",
    item: "その他の事務用品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "327",
    sub: "0000",
    item: "漆器製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "327",
    sub: "3271",
    item: "漆器製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "0000",
    item: "畳等生活雑貨製品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3281",
    item: "麦わら・パナマ類帽子・わら工品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3282",
    item: "畳製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3283",
    item: "うちわ・扇子・ちょうちん製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3284",
    item: "ほうき・ブラシ製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3285",
    item: "喫煙用具製造業（貴金属・宝石製を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "328",
    sub: "3289",
    item: "その他の生活雑貨製品製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "0000",
    item: "他に分類されない製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3291",
    item: "煙火製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3292",
    item: "看板・標識機製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3293",
    item: "パレット製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3294",
    item: "モデル・模型製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3295",
    item: "工業用模型製造業",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3296",
    item: "情報記録物製造業（新聞，書籍等の印刷物を除く）",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3297",
    item: "眼鏡製造業（枠を含む）",
  },
  {
    large: "E",
    middle: "32",
    minor: "329",
    sub: "3299",
    item: "他に分類されないその他の製造業",
  },
  {
    large: "F",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "電気・ガス・熱供給・水道業",
  },
  {
    large: "F",
    middle: "33",
    minor: "000",
    sub: "0000",
    item: "電気業",
  },
  {
    large: "F",
    middle: "33",
    minor: "330",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（33電気業）",
  },
  {
    large: "F",
    middle: "33",
    minor: "330",
    sub: "3300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "F",
    middle: "33",
    minor: "330",
    sub: "3309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "F",
    middle: "33",
    minor: "331",
    sub: "0000",
    item: "電気業",
  },
  {
    large: "F",
    middle: "33",
    minor: "331",
    sub: "3311",
    item: "発電所",
  },
  {
    large: "F",
    middle: "33",
    minor: "331",
    sub: "3312",
    item: "変電所",
  },
  {
    large: "F",
    middle: "34",
    minor: "000",
    sub: "0000",
    item: "ガス業",
  },
  {
    large: "F",
    middle: "34",
    minor: "340",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（34ガス業）",
  },
  {
    large: "F",
    middle: "34",
    minor: "340",
    sub: "3400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "F",
    middle: "34",
    minor: "340",
    sub: "3409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "F",
    middle: "34",
    minor: "341",
    sub: "0000",
    item: "ガス業",
  },
  {
    large: "F",
    middle: "34",
    minor: "341",
    sub: "3411",
    item: "ガス製造工場",
  },
  {
    large: "F",
    middle: "34",
    minor: "341",
    sub: "3412",
    item: "ガス供給所",
  },
  {
    large: "F",
    middle: "35",
    minor: "000",
    sub: "0000",
    item: "熱供給業",
  },
  {
    large: "F",
    middle: "35",
    minor: "350",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（35熱供給業）",
  },
  {
    large: "F",
    middle: "35",
    minor: "350",
    sub: "3500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "F",
    middle: "35",
    minor: "350",
    sub: "3509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "F",
    middle: "35",
    minor: "351",
    sub: "0000",
    item: "熱供給業",
  },
  {
    large: "F",
    middle: "35",
    minor: "351",
    sub: "3511",
    item: "熱供給業",
  },
  {
    large: "F",
    middle: "36",
    minor: "000",
    sub: "0000",
    item: "水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "360",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（36水道業）",
  },
  {
    large: "F",
    middle: "36",
    minor: "360",
    sub: "3600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "F",
    middle: "36",
    minor: "360",
    sub: "3609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "F",
    middle: "36",
    minor: "361",
    sub: "0000",
    item: "上水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "361",
    sub: "3611",
    item: "上水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "362",
    sub: "0000",
    item: "工業用水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "362",
    sub: "3621",
    item: "工業用水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "363",
    sub: "0000",
    item: "下水道業",
  },
  {
    large: "F",
    middle: "36",
    minor: "363",
    sub: "3631",
    item: "下水道処理施設維持管理業",
  },
  {
    large: "F",
    middle: "36",
    minor: "363",
    sub: "3632",
    item: "下水道管路施設維持管理業",
  },
  {
    large: "G",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "情報通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "000",
    sub: "0000",
    item: "通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "370",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（37通信業）",
  },
  {
    large: "G",
    middle: "37",
    minor: "370",
    sub: "3700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "G",
    middle: "37",
    minor: "370",
    sub: "3709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "G",
    middle: "37",
    minor: "371",
    sub: "0000",
    item: "固定電気通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "371",
    sub: "3711",
    item: "地域電気通信業（有線放送電話業を除く）",
  },
  {
    large: "G",
    middle: "37",
    minor: "371",
    sub: "3712",
    item: "長距離電気通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "371",
    sub: "3713",
    item: "有線放送電話業",
  },
  {
    large: "G",
    middle: "37",
    minor: "371",
    sub: "3719",
    item: "その他の固定電気通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "372",
    sub: "0000",
    item: "移動電気通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "372",
    sub: "3721",
    item: "移動電気通信業",
  },
  {
    large: "G",
    middle: "37",
    minor: "373",
    sub: "0000",
    item: "電気通信に附帯するサービス業",
  },
  {
    large: "G",
    middle: "37",
    minor: "373",
    sub: "3731",
    item: "電気通信に附帯するサービス業",
  },
  {
    large: "G",
    middle: "38",
    minor: "000",
    sub: "0000",
    item: "放送業",
  },
  {
    large: "G",
    middle: "38",
    minor: "380",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（38放送業）",
  },
  {
    large: "G",
    middle: "38",
    minor: "380",
    sub: "3800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "G",
    middle: "38",
    minor: "380",
    sub: "3809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "G",
    middle: "38",
    minor: "381",
    sub: "0000",
    item: "公共放送業（有線放送業を除く）",
  },
  {
    large: "G",
    middle: "38",
    minor: "381",
    sub: "3811",
    item: "公共放送業（有線放送業を除く）",
  },
  {
    large: "G",
    middle: "38",
    minor: "382",
    sub: "0000",
    item: "民間放送業（有線放送業を除く）",
  },
  {
    large: "G",
    middle: "38",
    minor: "382",
    sub: "3821",
    item: "テレビジョン放送業（衛星放送業を除く）",
  },
  {
    large: "G",
    middle: "38",
    minor: "382",
    sub: "3822",
    item: "ラジオ放送業（衛星放送業を除く）",
  },
  {
    large: "G",
    middle: "38",
    minor: "382",
    sub: "3823",
    item: "衛星放送業",
  },
  {
    large: "G",
    middle: "38",
    minor: "382",
    sub: "3829",
    item: "その他の民間放送業",
  },
  {
    large: "G",
    middle: "38",
    minor: "383",
    sub: "0000",
    item: "有線放送業",
  },
  {
    large: "G",
    middle: "38",
    minor: "383",
    sub: "3831",
    item: "有線テレビジョン放送業",
  },
  {
    large: "G",
    middle: "38",
    minor: "383",
    sub: "3832",
    item: "有線ラジオ放送業",
  },
  {
    large: "G",
    middle: "39",
    minor: "000",
    sub: "0000",
    item: "情報サービス業",
  },
  {
    large: "G",
    middle: "39",
    minor: "390",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（39情報サービス業）",
  },
  {
    large: "G",
    middle: "39",
    minor: "390",
    sub: "3900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "G",
    middle: "39",
    minor: "390",
    sub: "3909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "G",
    middle: "39",
    minor: "391",
    sub: "0000",
    item: "ソフトウェア業",
  },
  {
    large: "G",
    middle: "39",
    minor: "391",
    sub: "3911",
    item: "受託開発ソフトウェア業",
  },
  {
    large: "G",
    middle: "39",
    minor: "391",
    sub: "3912",
    item: "組込みソフトウェア業",
  },
  {
    large: "G",
    middle: "39",
    minor: "391",
    sub: "3913",
    item: "パッケージソフトウェア業",
  },
  {
    large: "G",
    middle: "39",
    minor: "391",
    sub: "3914",
    item: "ゲームソフトウェア業",
  },
  {
    large: "G",
    middle: "39",
    minor: "392",
    sub: "0000",
    item: "情報処理・提供サービス業",
  },
  {
    large: "G",
    middle: "39",
    minor: "392",
    sub: "3921",
    item: "情報処理サービス業",
  },
  {
    large: "G",
    middle: "39",
    minor: "392",
    sub: "3922",
    item: "情報提供サービス業",
  },
  {
    large: "G",
    middle: "39",
    minor: "392",
    sub: "3923",
    item: "市場調査・世論調査・社会調査業",
  },
  {
    large: "G",
    middle: "39",
    minor: "392",
    sub: "3929",
    item: "その他の情報処理・提供サービス業",
  },
  {
    large: "G",
    middle: "40",
    minor: "000",
    sub: "0000",
    item: "インターネット附随サービス業",
  },
  {
    large: "G",
    middle: "40",
    minor: "400",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（40インターネット附随サービス業）",
  },
  {
    large: "G",
    middle: "40",
    minor: "400",
    sub: "4000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "G",
    middle: "40",
    minor: "400",
    sub: "4009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "G",
    middle: "40",
    minor: "401",
    sub: "0000",
    item: "インターネット附随サービス業",
  },
  {
    large: "G",
    middle: "40",
    minor: "401",
    sub: "4011",
    item: "ポータルサイト・サーバ運営業",
  },
  {
    large: "G",
    middle: "40",
    minor: "401",
    sub: "4012",
    item: "アプリケーション・サービス・コンテンツ・プロバイダ",
  },
  {
    large: "G",
    middle: "40",
    minor: "401",
    sub: "4013",
    item: "インターネット利用サポート業",
  },
  {
    large: "G",
    middle: "41",
    minor: "000",
    sub: "0000",
    item: "映像・音声・文字情報制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "410",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（41映像・音声・文字情報制作業）",
  },
  {
    large: "G",
    middle: "41",
    minor: "410",
    sub: "4100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "G",
    middle: "41",
    minor: "410",
    sub: "4109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "G",
    middle: "41",
    minor: "411",
    sub: "0000",
    item: "映像情報制作・配給業",
  },
  {
    large: "G",
    middle: "41",
    minor: "411",
    sub: "4111",
    item: "映画・ビデオ制作業（テレビジョン番組制作業，アニメーション制作業を除く）",
  },
  {
    large: "G",
    middle: "41",
    minor: "411",
    sub: "4112",
    item: "テレビジョン番組制作業（アニメーション制作業を除く）",
  },
  {
    large: "G",
    middle: "41",
    minor: "411",
    sub: "4113",
    item: "アニメーション制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "411",
    sub: "4114",
    item: "映画・ビデオ・テレビジョン番組配給業",
  },
  {
    large: "G",
    middle: "41",
    minor: "412",
    sub: "0000",
    item: "音声情報制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "412",
    sub: "4121",
    item: "レコード制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "412",
    sub: "4122",
    item: "ラジオ番組制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "413",
    sub: "0000",
    item: "新聞業",
  },
  {
    large: "G",
    middle: "41",
    minor: "413",
    sub: "4131",
    item: "新聞業",
  },
  {
    large: "G",
    middle: "41",
    minor: "414",
    sub: "0000",
    item: "出版業",
  },
  {
    large: "G",
    middle: "41",
    minor: "414",
    sub: "4141",
    item: "出版業",
  },
  {
    large: "G",
    middle: "41",
    minor: "415",
    sub: "0000",
    item: "広告制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "415",
    sub: "4151",
    item: "広告制作業",
  },
  {
    large: "G",
    middle: "41",
    minor: "416",
    sub: "0000",
    item: "映像・音声・文字情報制作に附帯するサービス業",
  },
  {
    large: "G",
    middle: "41",
    minor: "416",
    sub: "4161",
    item: "ニュース供給業",
  },
  {
    large: "G",
    middle: "41",
    minor: "416",
    sub: "4169",
    item: "その他の映像・音声・文字情報制作に附帯するサービス業",
  },
  {
    large: "H",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "運輸業，郵便業",
  },
  {
    large: "H",
    middle: "42",
    minor: "000",
    sub: "0000",
    item: "鉄道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "420",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（42鉄道業）",
  },
  {
    large: "H",
    middle: "42",
    minor: "420",
    sub: "4200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "42",
    minor: "420",
    sub: "4209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "0000",
    item: "鉄道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4211",
    item: "普通鉄道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4212",
    item: "軌道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4213",
    item: "地下鉄道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4214",
    item: "モノレール鉄道業（地下鉄道業を除く）",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4215",
    item: "案内軌条式鉄道業（地下鉄道業を除く）",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4216",
    item: "鋼索鉄道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4217",
    item: "索道業",
  },
  {
    large: "H",
    middle: "42",
    minor: "421",
    sub: "4219",
    item: "その他の鉄道業",
  },
  {
    large: "H",
    middle: "43",
    minor: "000",
    sub: "0000",
    item: "道路旅客運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "430",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（43道路旅客運送業）",
  },
  {
    large: "H",
    middle: "43",
    minor: "430",
    sub: "4300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "43",
    minor: "430",
    sub: "4309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "43",
    minor: "431",
    sub: "0000",
    item: "一般乗合旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "431",
    sub: "4311",
    item: "一般乗合旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "432",
    sub: "0000",
    item: "一般乗用旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "432",
    sub: "4321",
    item: "一般乗用旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "433",
    sub: "0000",
    item: "一般貸切旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "433",
    sub: "4331",
    item: "一般貸切旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "439",
    sub: "0000",
    item: "その他の道路旅客運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "439",
    sub: "4391",
    item: "特定旅客自動車運送業",
  },
  {
    large: "H",
    middle: "43",
    minor: "439",
    sub: "4399",
    item: "他に分類されない道路旅客運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "000",
    sub: "0000",
    item: "道路貨物運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "440",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（44道路貨物運送業）",
  },
  {
    large: "H",
    middle: "44",
    minor: "440",
    sub: "4400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "44",
    minor: "440",
    sub: "4409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "44",
    minor: "441",
    sub: "0000",
    item: "一般貨物自動車運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "441",
    sub: "4411",
    item: "一般貨物自動車運送業（特別積合せ貨物運送業を除く）",
  },
  {
    large: "H",
    middle: "44",
    minor: "441",
    sub: "4412",
    item: "特別積合せ貨物運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "442",
    sub: "0000",
    item: "特定貨物自動車運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "442",
    sub: "4421",
    item: "特定貨物自動車運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "443",
    sub: "0000",
    item: "貨物軽自動車運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "443",
    sub: "4431",
    item: "貨物軽自動車運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "444",
    sub: "0000",
    item: "集配利用運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "444",
    sub: "4441",
    item: "集配利用運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "449",
    sub: "0000",
    item: "その他の道路貨物運送業",
  },
  {
    large: "H",
    middle: "44",
    minor: "449",
    sub: "4499",
    item: "その他の道路貨物運送業",
  },
  {
    large: "H",
    middle: "45",
    minor: "000",
    sub: "0000",
    item: "水運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "450",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（45水運業）",
  },
  {
    large: "H",
    middle: "45",
    minor: "450",
    sub: "4500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "45",
    minor: "450",
    sub: "4509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "45",
    minor: "451",
    sub: "0000",
    item: "外航海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "451",
    sub: "4511",
    item: "外航旅客海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "451",
    sub: "4512",
    item: "外航貨物海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "452",
    sub: "0000",
    item: "沿海海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "452",
    sub: "4521",
    item: "沿海旅客海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "452",
    sub: "4522",
    item: "沿海貨物海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "453",
    sub: "0000",
    item: "内陸水運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "453",
    sub: "4531",
    item: "港湾旅客海運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "453",
    sub: "4532",
    item: "河川水運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "453",
    sub: "4533",
    item: "湖沼水運業",
  },
  {
    large: "H",
    middle: "45",
    minor: "454",
    sub: "0000",
    item: "船舶貸渡業",
  },
  {
    large: "H",
    middle: "45",
    minor: "454",
    sub: "4541",
    item: "船舶貸渡業（内航船舶貸渡業を除く）",
  },
  {
    large: "H",
    middle: "45",
    minor: "454",
    sub: "4542",
    item: "内航船舶貸渡業",
  },
  {
    large: "H",
    middle: "46",
    minor: "000",
    sub: "0000",
    item: "航空運輸業",
  },
  {
    large: "H",
    middle: "46",
    minor: "460",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（46航空運輸業）",
  },
  {
    large: "H",
    middle: "46",
    minor: "460",
    sub: "4600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "46",
    minor: "460",
    sub: "4609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "46",
    minor: "461",
    sub: "0000",
    item: "航空運送業",
  },
  {
    large: "H",
    middle: "46",
    minor: "461",
    sub: "4611",
    item: "航空運送業",
  },
  {
    large: "H",
    middle: "46",
    minor: "462",
    sub: "0000",
    item: "航空機使用業（航空運送業を除く）",
  },
  {
    large: "H",
    middle: "46",
    minor: "462",
    sub: "4621",
    item: "航空機使用業（航空運送業を除く）",
  },
  {
    large: "H",
    middle: "47",
    minor: "000",
    sub: "0000",
    item: "倉庫業",
  },
  {
    large: "H",
    middle: "47",
    minor: "470",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（47倉庫業）",
  },
  {
    large: "H",
    middle: "47",
    minor: "470",
    sub: "4700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "47",
    minor: "470",
    sub: "4709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "47",
    minor: "471",
    sub: "0000",
    item: "倉庫業（冷蔵倉庫業を除く）",
  },
  {
    large: "H",
    middle: "47",
    minor: "471",
    sub: "4711",
    item: "倉庫業（冷蔵倉庫業を除く）",
  },
  {
    large: "H",
    middle: "47",
    minor: "472",
    sub: "0000",
    item: "冷蔵倉庫業",
  },
  {
    large: "H",
    middle: "47",
    minor: "472",
    sub: "4721",
    item: "冷蔵倉庫業",
  },
  {
    large: "H",
    middle: "48",
    minor: "000",
    sub: "0000",
    item: "運輸に附帯するサービス業",
  },
  {
    large: "H",
    middle: "48",
    minor: "480",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（48運輸に附帯するサービス業）",
  },
  {
    large: "H",
    middle: "48",
    minor: "480",
    sub: "4800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "H",
    middle: "48",
    minor: "480",
    sub: "4809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "48",
    minor: "481",
    sub: "0000",
    item: "港湾運送業",
  },
  {
    large: "H",
    middle: "48",
    minor: "481",
    sub: "4811",
    item: "港湾運送業",
  },
  {
    large: "H",
    middle: "48",
    minor: "482",
    sub: "0000",
    item: "貨物運送取扱業（集配利用運送業を除く）",
  },
  {
    large: "H",
    middle: "48",
    minor: "482",
    sub: "4821",
    item: "利用運送業（集配利用運送業を除く）",
  },
  {
    large: "H",
    middle: "48",
    minor: "482",
    sub: "4822",
    item: "運送取次業",
  },
  {
    large: "H",
    middle: "48",
    minor: "483",
    sub: "0000",
    item: "運送代理店",
  },
  {
    large: "H",
    middle: "48",
    minor: "483",
    sub: "4831",
    item: "運送代理店",
  },
  {
    large: "H",
    middle: "48",
    minor: "484",
    sub: "0000",
    item: "こん包業",
  },
  {
    large: "H",
    middle: "48",
    minor: "484",
    sub: "4841",
    item: "こん包業（組立こん包業を除く）",
  },
  {
    large: "H",
    middle: "48",
    minor: "484",
    sub: "4842",
    item: "組立こん包業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "0000",
    item: "運輸施設提供業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4851",
    item: "鉄道施設提供業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4852",
    item: "道路運送固定施設業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4853",
    item: "自動車ターミナル業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4854",
    item: "貨物荷扱固定施設業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4855",
    item: "桟橋泊きょ業",
  },
  {
    large: "H",
    middle: "48",
    minor: "485",
    sub: "4856",
    item: "飛行場業",
  },
  {
    large: "H",
    middle: "48",
    minor: "489",
    sub: "0000",
    item: "その他の運輸に附帯するサービス業",
  },
  {
    large: "H",
    middle: "48",
    minor: "489",
    sub: "4891",
    item: "海運仲立業",
  },
  {
    large: "H",
    middle: "48",
    minor: "489",
    sub: "4899",
    item: "他に分類されない運輸に附帯するサービス業",
  },
  {
    large: "H",
    middle: "49",
    minor: "000",
    sub: "0000",
    item: "郵便業（信書便事業を含む）",
  },
  {
    large: "H",
    middle: "49",
    minor: "490",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（49郵便業）",
  },
  {
    large: "H",
    middle: "49",
    minor: "490",
    sub: "4901",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "H",
    middle: "49",
    minor: "491",
    sub: "0000",
    item: "郵便業（信書便事業を含む）",
  },
  {
    large: "H",
    middle: "49",
    minor: "491",
    sub: "4911",
    item: "郵便業（信書便事業を含む）",
  },
  {
    large: "I",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "卸売業，小売業",
  },
  {
    large: "I",
    middle: "50",
    minor: "000",
    sub: "0000",
    item: "各種商品卸売業",
  },
  {
    large: "I",
    middle: "50",
    minor: "500",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（50各種商品卸売業）",
  },
  {
    large: "I",
    middle: "50",
    minor: "500",
    sub: "5000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "50",
    minor: "500",
    sub: "5008",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "50",
    minor: "500",
    sub: "5009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "50",
    minor: "501",
    sub: "0000",
    item: "各種商品卸売業",
  },
  {
    large: "I",
    middle: "50",
    minor: "501",
    sub: "5011",
    item: "各種商品卸売業（従業者が常時100人以上のもの）",
  },
  {
    large: "I",
    middle: "50",
    minor: "501",
    sub: "5019",
    item: "その他の各種商品卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "000",
    sub: "0000",
    item: "繊維・衣服等卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "510",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（51繊維・衣服等卸売業）",
  },
  {
    large: "I",
    middle: "51",
    minor: "510",
    sub: "5100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "51",
    minor: "510",
    sub: "5108",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "51",
    minor: "510",
    sub: "5109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "51",
    minor: "511",
    sub: "0000",
    item: "繊維品卸売業（衣服，身の回り品を除く）",
  },
  {
    large: "I",
    middle: "51",
    minor: "511",
    sub: "5111",
    item: "繊維原料卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "511",
    sub: "5112",
    item: "糸卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "511",
    sub: "5113",
    item: "織物卸売業（室内装飾繊維品を除く）",
  },
  {
    large: "I",
    middle: "51",
    minor: "512",
    sub: "0000",
    item: "衣服卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "512",
    sub: "5121",
    item: "男子服卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "512",
    sub: "5122",
    item: "婦人・子供服卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "512",
    sub: "5123",
    item: "下着類卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "512",
    sub: "5129",
    item: "その他の衣服卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "513",
    sub: "0000",
    item: "身の回り品卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "513",
    sub: "5131",
    item: "寝具類卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "513",
    sub: "5132",
    item: "靴・履物卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "513",
    sub: "5133",
    item: "かばん・袋物卸売業",
  },
  {
    large: "I",
    middle: "51",
    minor: "513",
    sub: "5139",
    item: "その他の身の回り品卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "000",
    sub: "0000",
    item: "飲食料品卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "520",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（52飲食料品卸売業）",
  },
  {
    large: "I",
    middle: "52",
    minor: "520",
    sub: "5200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "52",
    minor: "520",
    sub: "5208",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "52",
    minor: "520",
    sub: "5209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "0000",
    item: "農畜産物・水産物卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5211",
    item: "米麦卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5212",
    item: "雑穀・豆類卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5213",
    item: "野菜卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5214",
    item: "果実卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5215",
    item: "食肉卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5216",
    item: "生鮮魚介卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "521",
    sub: "5219",
    item: "その他の農畜産物・水産物卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "0000",
    item: "食料・飲料卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5221",
    item: "砂糖・味そ・しょう油卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5222",
    item: "酒類卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5223",
    item: "乾物卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5224",
    item: "菓子・パン類卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5225",
    item: "飲料卸売業（別掲を除く）",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5226",
    item: "茶類卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5227",
    item: "牛乳・乳製品卸売業",
  },
  {
    large: "I",
    middle: "52",
    minor: "522",
    sub: "5229",
    item: "その他の食料・飲料卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "000",
    sub: "0000",
    item: "建築材料，鉱物・金属材料等卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "530",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（53建築材料，鉱物・金属材料等卸売業）",
  },
  {
    large: "I",
    middle: "53",
    minor: "530",
    sub: "5300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "53",
    minor: "530",
    sub: "5308",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "53",
    minor: "530",
    sub: "5309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "0000",
    item: "建築材料卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "5311",
    item: "木材・竹材卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "5312",
    item: "セメント卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "5313",
    item: "板ガラス卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "5314",
    item: "建築用金属製品卸売業（建築用金物を除く）",
  },
  {
    large: "I",
    middle: "53",
    minor: "531",
    sub: "5319",
    item: "その他の建築材料卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "532",
    sub: "0000",
    item: "化学製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "532",
    sub: "5321",
    item: "塗料卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "532",
    sub: "5322",
    item: "プラスチック卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "532",
    sub: "5329",
    item: "その他の化学製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "533",
    sub: "0000",
    item: "石油・鉱物卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "533",
    sub: "5331",
    item: "石油卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "533",
    sub: "5332",
    item: "鉱物卸売業（石油を除く）",
  },
  {
    large: "I",
    middle: "53",
    minor: "534",
    sub: "0000",
    item: "鉄鋼製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "534",
    sub: "5341",
    item: "鉄鋼粗製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "534",
    sub: "5342",
    item: "鉄鋼一次製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "534",
    sub: "5349",
    item: "その他の鉄鋼製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "535",
    sub: "0000",
    item: "非鉄金属卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "535",
    sub: "5351",
    item: "非鉄金属地金卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "535",
    sub: "5352",
    item: "非鉄金属製品卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "0000",
    item: "再生資源卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "5361",
    item: "空瓶・空缶等空容器卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "5362",
    item: "鉄スクラップ卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "5363",
    item: "非鉄金属スクラップ卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "5364",
    item: "古紙卸売業",
  },
  {
    large: "I",
    middle: "53",
    minor: "536",
    sub: "5369",
    item: "その他の再生資源卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "000",
    sub: "0000",
    item: "機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "540",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（54機械器具卸売業）",
  },
  {
    large: "I",
    middle: "54",
    minor: "540",
    sub: "5400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "54",
    minor: "540",
    sub: "5408",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "54",
    minor: "540",
    sub: "5409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "0000",
    item: "産業機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "5411",
    item: "農業用機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "5412",
    item: "建設機械・鉱山機械卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "5413",
    item: "金属加工機械卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "5414",
    item: "事務用機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "541",
    sub: "5419",
    item: "その他の産業機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "542",
    sub: "0000",
    item: "自動車卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "542",
    sub: "5421",
    item: "自動車卸売業（二輪自動車を含む）",
  },
  {
    large: "I",
    middle: "54",
    minor: "542",
    sub: "5422",
    item: "自動車部分品・附属品卸売業（中古品を除く）",
  },
  {
    large: "I",
    middle: "54",
    minor: "542",
    sub: "5423",
    item: "自動車中古部品卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "543",
    sub: "0000",
    item: "電気機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "543",
    sub: "5431",
    item: "家庭用電気機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "543",
    sub: "5432",
    item: "電気機械器具卸売業（家庭用電気機械器具を除く）",
  },
  {
    large: "I",
    middle: "54",
    minor: "549",
    sub: "0000",
    item: "その他の機械器具卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "549",
    sub: "5491",
    item: "輸送用機械器具卸売業（自動車を除く）",
  },
  {
    large: "I",
    middle: "54",
    minor: "549",
    sub: "5492",
    item: "計量器・理化学機械器具・光学機械器具等卸売業",
  },
  {
    large: "I",
    middle: "54",
    minor: "549",
    sub: "5493",
    item: "医療用機械器具卸売業（歯科用機械器具を含む）",
  },
  {
    large: "I",
    middle: "55",
    minor: "000",
    sub: "0000",
    item: "その他の卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "550",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（55その他の卸売業）",
  },
  {
    large: "I",
    middle: "55",
    minor: "550",
    sub: "5500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "55",
    minor: "550",
    sub: "5508",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "55",
    minor: "550",
    sub: "5509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "0000",
    item: "家具・建具・じゅう器等卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5511",
    item: "家具・建具卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5512",
    item: "荒物卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5513",
    item: "畳卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5514",
    item: "室内装飾繊維品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5515",
    item: "陶磁器・ガラス器卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "551",
    sub: "5519",
    item: "その他のじゅう器卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "552",
    sub: "0000",
    item: "医薬品・化粧品等卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "552",
    sub: "5521",
    item: "医薬品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "552",
    sub: "5522",
    item: "医療用品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "552",
    sub: "5523",
    item: "化粧品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "552",
    sub: "5524",
    item: "合成洗剤卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "553",
    sub: "0000",
    item: "紙・紙製品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "553",
    sub: "5531",
    item: "紙卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "553",
    sub: "5532",
    item: "紙製品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "0000",
    item: "他に分類されない卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5591",
    item: "金物卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5592",
    item: "肥料・飼料卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5593",
    item: "スポーツ用品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5594",
    item: "娯楽用品・がん具卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5595",
    item: "たばこ卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5596",
    item: "ジュエリー製品卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5597",
    item: "書籍・雑誌卸売業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5598",
    item: "代理商，仲立業",
  },
  {
    large: "I",
    middle: "55",
    minor: "559",
    sub: "5599",
    item: "他に分類されないその他の卸売業",
  },
  {
    large: "I",
    middle: "56",
    minor: "000",
    sub: "0000",
    item: "各種商品小売業",
  },
  {
    large: "I",
    middle: "56",
    minor: "560",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（56各種商品小売業）",
  },
  {
    large: "I",
    middle: "56",
    minor: "560",
    sub: "5600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "56",
    minor: "560",
    sub: "5608",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "56",
    minor: "560",
    sub: "5609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "56",
    minor: "561",
    sub: "0000",
    item: "百貨店，総合スーパー",
  },
  {
    large: "I",
    middle: "56",
    minor: "561",
    sub: "5611",
    item: "百貨店，総合スーパー",
  },
  {
    large: "I",
    middle: "56",
    minor: "569",
    sub: "0000",
    item: "その他の各種商品小売業（従業者が常時50人未満のもの）",
  },
  {
    large: "I",
    middle: "56",
    minor: "569",
    sub: "5699",
    item: "その他の各種商品小売業（従業者が常時50人未満のもの）",
  },
  {
    large: "I",
    middle: "57",
    minor: "000",
    sub: "0000",
    item: "織物・衣服・身の回り品小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "570",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（57織物・衣服・身の回り品小売業）",
  },
  {
    large: "I",
    middle: "57",
    minor: "570",
    sub: "5700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "57",
    minor: "570",
    sub: "5708",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "57",
    minor: "570",
    sub: "5709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "57",
    minor: "571",
    sub: "0000",
    item: "呉服・服地・寝具小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "571",
    sub: "5711",
    item: "呉服・服地小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "571",
    sub: "5712",
    item: "寝具小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "572",
    sub: "0000",
    item: "男子服小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "572",
    sub: "5721",
    item: "男子服小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "573",
    sub: "0000",
    item: "婦人・子供服小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "573",
    sub: "5731",
    item: "婦人服小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "573",
    sub: "5732",
    item: "子供服小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "574",
    sub: "0000",
    item: "靴・履物小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "574",
    sub: "5741",
    item: "靴小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "574",
    sub: "5742",
    item: "履物小売業（靴を除く）",
  },
  {
    large: "I",
    middle: "57",
    minor: "579",
    sub: "0000",
    item: "その他の織物・衣服・身の回り品小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "579",
    sub: "5791",
    item: "かばん・袋物小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "579",
    sub: "5792",
    item: "下着類小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "579",
    sub: "5793",
    item: "洋品雑貨・小間物小売業",
  },
  {
    large: "I",
    middle: "57",
    minor: "579",
    sub: "5799",
    item: "他に分類されない織物・衣服・身の回り品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "000",
    sub: "0000",
    item: "飲食料品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "580",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（58飲食料品小売業）",
  },
  {
    large: "I",
    middle: "58",
    minor: "580",
    sub: "5800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "58",
    minor: "580",
    sub: "5808",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "58",
    minor: "580",
    sub: "5809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "58",
    minor: "581",
    sub: "0000",
    item: "各種食料品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "581",
    sub: "5811",
    item: "各種食料品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "582",
    sub: "0000",
    item: "野菜・果実小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "582",
    sub: "5821",
    item: "野菜小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "582",
    sub: "5822",
    item: "果実小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "583",
    sub: "0000",
    item: "食肉小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "583",
    sub: "5831",
    item: "食肉小売業（卵，鳥肉を除く）",
  },
  {
    large: "I",
    middle: "58",
    minor: "583",
    sub: "5832",
    item: "卵・鳥肉小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "584",
    sub: "0000",
    item: "鮮魚小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "584",
    sub: "5841",
    item: "鮮魚小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "585",
    sub: "0000",
    item: "酒小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "585",
    sub: "5851",
    item: "酒小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "586",
    sub: "0000",
    item: "菓子・パン小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "586",
    sub: "5861",
    item: "菓子小売業（製造小売）",
  },
  {
    large: "I",
    middle: "58",
    minor: "586",
    sub: "5862",
    item: "菓子小売業（製造小売でないもの）",
  },
  {
    large: "I",
    middle: "58",
    minor: "586",
    sub: "5863",
    item: "パン小売業（製造小売）",
  },
  {
    large: "I",
    middle: "58",
    minor: "586",
    sub: "5864",
    item: "パン小売業（製造小売でないもの）",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "0000",
    item: "その他の飲食料品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5891",
    item: "コンビニエンスストア（飲食料品を中心とするものに限る）",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5892",
    item: "牛乳小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5893",
    item: "飲料小売業（別掲を除く）",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5894",
    item: "茶類小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5895",
    item: "料理品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5896",
    item: "米穀類小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5897",
    item: "豆腐・かまぼこ等加工食品小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5898",
    item: "乾物小売業",
  },
  {
    large: "I",
    middle: "58",
    minor: "589",
    sub: "5899",
    item: "他に分類されない飲食料品小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "000",
    sub: "0000",
    item: "機械器具小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "590",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（59機械器具小売業）",
  },
  {
    large: "I",
    middle: "59",
    minor: "590",
    sub: "5900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "59",
    minor: "590",
    sub: "5908",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "59",
    minor: "590",
    sub: "5909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "59",
    minor: "591",
    sub: "0000",
    item: "自動車小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "591",
    sub: "5911",
    item: "自動車（新車）小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "591",
    sub: "5912",
    item: "中古自動車小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "591",
    sub: "5913",
    item: "自動車部分品・附属品小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "591",
    sub: "5914",
    item: "二輪自動車小売業（原動機付自転車を含む）",
  },
  {
    large: "I",
    middle: "59",
    minor: "592",
    sub: "0000",
    item: "自転車小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "592",
    sub: "5921",
    item: "自転車小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "593",
    sub: "0000",
    item: "機械器具小売業（自動車，自転車を除く）",
  },
  {
    large: "I",
    middle: "59",
    minor: "593",
    sub: "5931",
    item: "電気機械器具小売業（中古品を除く）",
  },
  {
    large: "I",
    middle: "59",
    minor: "593",
    sub: "5932",
    item: "電気事務機械器具小売業（中古品を除く）",
  },
  {
    large: "I",
    middle: "59",
    minor: "593",
    sub: "5933",
    item: "中古電気製品小売業",
  },
  {
    large: "I",
    middle: "59",
    minor: "593",
    sub: "5939",
    item: "その他の機械器具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "000",
    sub: "0000",
    item: "その他の小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "600",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（60その他の小売業）",
  },
  {
    large: "I",
    middle: "60",
    minor: "600",
    sub: "6000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "60",
    minor: "600",
    sub: "6008",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "60",
    minor: "600",
    sub: "6009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "60",
    minor: "601",
    sub: "0000",
    item: "家具・建具・畳小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "601",
    sub: "6011",
    item: "家具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "601",
    sub: "6012",
    item: "建具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "601",
    sub: "6013",
    item: "畳小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "601",
    sub: "6014",
    item: "宗教用具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "602",
    sub: "0000",
    item: "じゅう器小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "602",
    sub: "6021",
    item: "金物小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "602",
    sub: "6022",
    item: "荒物小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "602",
    sub: "6023",
    item: "陶磁器・ガラス器小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "602",
    sub: "6029",
    item: "他に分類されないじゅう器小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "603",
    sub: "0000",
    item: "医薬品・化粧品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "603",
    sub: "6031",
    item: "ドラッグストア",
  },
  {
    large: "I",
    middle: "60",
    minor: "603",
    sub: "6032",
    item: "医薬品小売業（調剤薬局を除く）",
  },
  {
    large: "I",
    middle: "60",
    minor: "603",
    sub: "6033",
    item: "調剤薬局",
  },
  {
    large: "I",
    middle: "60",
    minor: "603",
    sub: "6034",
    item: "化粧品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "604",
    sub: "0000",
    item: "農耕用品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "604",
    sub: "6041",
    item: "農業用機械器具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "604",
    sub: "6042",
    item: "苗・種子小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "604",
    sub: "6043",
    item: "肥料・飼料小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "605",
    sub: "0000",
    item: "燃料小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "605",
    sub: "6051",
    item: "ガソリンスタンド",
  },
  {
    large: "I",
    middle: "60",
    minor: "605",
    sub: "6052",
    item: "燃料小売業（ガソリンスタンドを除く）",
  },
  {
    large: "I",
    middle: "60",
    minor: "606",
    sub: "0000",
    item: "書籍・文房具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "606",
    sub: "6061",
    item: "書籍・雑誌小売業（古本を除く）",
  },
  {
    large: "I",
    middle: "60",
    minor: "606",
    sub: "6062",
    item: "古本小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "606",
    sub: "6063",
    item: "新聞小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "606",
    sub: "6064",
    item: "紙・文房具小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "607",
    sub: "0000",
    item: "スポーツ用品・がん具・娯楽用品・楽器小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "607",
    sub: "6071",
    item: "スポーツ用品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "607",
    sub: "6072",
    item: "がん具・娯楽用品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "607",
    sub: "6073",
    item: "楽器小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "608",
    sub: "0000",
    item: "写真機・時計・眼鏡小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "608",
    sub: "6081",
    item: "写真機・写真材料小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "608",
    sub: "6082",
    item: "時計・眼鏡・光学機械小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "0000",
    item: "他に分類されない小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6091",
    item: "ホームセンター",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6092",
    item: "たばこ・喫煙具専門小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6093",
    item: "花・植木小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6094",
    item: "建築材料小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6095",
    item: "ジュエリー製品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6096",
    item: "ペット・ペット用品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6097",
    item: "骨とう品小売業",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6098",
    item: "中古品小売業（骨とう品を除く）",
  },
  {
    large: "I",
    middle: "60",
    minor: "609",
    sub: "6099",
    item: "他に分類されないその他の小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "000",
    sub: "0000",
    item: "無店舗小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "610",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（61無店舗小売業）",
  },
  {
    large: "I",
    middle: "61",
    minor: "610",
    sub: "6100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "I",
    middle: "61",
    minor: "610",
    sub: "6108",
    item: "自家用倉庫",
  },
  {
    large: "I",
    middle: "61",
    minor: "610",
    sub: "6109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "0000",
    item: "通信販売・訪問販売小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "6111",
    item: "無店舗小売業（各種商品小売）",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "6112",
    item: "無店舗小売業（織物・衣服・身の回り品小売）",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "6113",
    item: "無店舗小売業（飲食料品小売）",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "6114",
    item: "無店舗小売業（機械器具小売）",
  },
  {
    large: "I",
    middle: "61",
    minor: "611",
    sub: "6119",
    item: "無店舗小売業（その他の小売）",
  },
  {
    large: "I",
    middle: "61",
    minor: "612",
    sub: "0000",
    item: "自動販売機による小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "612",
    sub: "6121",
    item: "自動販売機による小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "619",
    sub: "0000",
    item: "その他の無店舗小売業",
  },
  {
    large: "I",
    middle: "61",
    minor: "619",
    sub: "6199",
    item: "その他の無店舗小売業",
  },
  {
    large: "J",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "金融業，保険業",
  },
  {
    large: "J",
    middle: "62",
    minor: "000",
    sub: "0000",
    item: "銀行業",
  },
  {
    large: "J",
    middle: "62",
    minor: "620",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（62銀行業）",
  },
  {
    large: "J",
    middle: "62",
    minor: "620",
    sub: "6200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "62",
    minor: "620",
    sub: "6209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "62",
    minor: "621",
    sub: "0000",
    item: "中央銀行",
  },
  {
    large: "J",
    middle: "62",
    minor: "621",
    sub: "6211",
    item: "中央銀行",
  },
  {
    large: "J",
    middle: "62",
    minor: "622",
    sub: "0000",
    item: "銀行（中央銀行を除く）",
  },
  {
    large: "J",
    middle: "62",
    minor: "622",
    sub: "6221",
    item: "普通銀行",
  },
  {
    large: "J",
    middle: "62",
    minor: "622",
    sub: "6222",
    item: "郵便貯金銀行",
  },
  {
    large: "J",
    middle: "62",
    minor: "622",
    sub: "6223",
    item: "信託銀行",
  },
  {
    large: "J",
    middle: "62",
    minor: "622",
    sub: "6229",
    item: "その他の銀行",
  },
  {
    large: "J",
    middle: "63",
    minor: "000",
    sub: "0000",
    item: "協同組織金融業",
  },
  {
    large: "J",
    middle: "63",
    minor: "630",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（63協同組織金融業）",
  },
  {
    large: "J",
    middle: "63",
    minor: "630",
    sub: "6300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "63",
    minor: "630",
    sub: "6309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "63",
    minor: "631",
    sub: "0000",
    item: "中小企業等金融業",
  },
  {
    large: "J",
    middle: "63",
    minor: "631",
    sub: "6311",
    item: "信用金庫・同連合会",
  },
  {
    large: "J",
    middle: "63",
    minor: "631",
    sub: "6312",
    item: "信用協同組合・同連合会",
  },
  {
    large: "J",
    middle: "63",
    minor: "631",
    sub: "6313",
    item: "商工組合中央金庫",
  },
  {
    large: "J",
    middle: "63",
    minor: "631",
    sub: "6314",
    item: "労働金庫・同連合会",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "0000",
    item: "農林水産金融業",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "6321",
    item: "農林中央金庫",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "6322",
    item: "信用農業協同組合連合会",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "6323",
    item: "信用漁業協同組合連合会，信用水産加工業協同組合連合会",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "6324",
    item: "農業協同組合",
  },
  {
    large: "J",
    middle: "63",
    minor: "632",
    sub: "6325",
    item: "漁業協同組合，水産加工業協同組合",
  },
  {
    large: "J",
    middle: "64",
    minor: "000",
    sub: "0000",
    item: "貸金業，クレジットカード業等非預金信用機関",
  },
  {
    large: "J",
    middle: "64",
    minor: "640",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（64貸金業，クレジットカード業等非預金信用機関）",
  },
  {
    large: "J",
    middle: "64",
    minor: "640",
    sub: "6400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "64",
    minor: "640",
    sub: "6409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "64",
    minor: "641",
    sub: "0000",
    item: "貸金業",
  },
  {
    large: "J",
    middle: "64",
    minor: "641",
    sub: "6411",
    item: "消費者向け貸金業",
  },
  {
    large: "J",
    middle: "64",
    minor: "641",
    sub: "6412",
    item: "事業者向け貸金業",
  },
  {
    large: "J",
    middle: "64",
    minor: "642",
    sub: "0000",
    item: "質屋",
  },
  {
    large: "J",
    middle: "64",
    minor: "642",
    sub: "6421",
    item: "質屋",
  },
  {
    large: "J",
    middle: "64",
    minor: "643",
    sub: "0000",
    item: "クレジットカード業，割賦金融業",
  },
  {
    large: "J",
    middle: "64",
    minor: "643",
    sub: "6431",
    item: "クレジットカード業",
  },
  {
    large: "J",
    middle: "64",
    minor: "643",
    sub: "6432",
    item: "割賦金融業",
  },
  {
    large: "J",
    middle: "64",
    minor: "649",
    sub: "0000",
    item: "その他の非預金信用機関",
  },
  {
    large: "J",
    middle: "64",
    minor: "649",
    sub: "6491",
    item: "政府関係金融機関",
  },
  {
    large: "J",
    middle: "64",
    minor: "649",
    sub: "6492",
    item: "住宅専門金融業",
  },
  {
    large: "J",
    middle: "64",
    minor: "649",
    sub: "6493",
    item: "証券金融業",
  },
  {
    large: "J",
    middle: "64",
    minor: "649",
    sub: "6499",
    item: "他に分類されない非預金信用機関",
  },
  {
    large: "J",
    middle: "65",
    minor: "000",
    sub: "0000",
    item: "金融商品取引業，商品先物取引業",
  },
  {
    large: "J",
    middle: "65",
    minor: "650",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（65金融商品取引業，商品先物取引業）",
  },
  {
    large: "J",
    middle: "65",
    minor: "650",
    sub: "6500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "65",
    minor: "650",
    sub: "6509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "65",
    minor: "651",
    sub: "0000",
    item: "金融商品取引業",
  },
  {
    large: "J",
    middle: "65",
    minor: "651",
    sub: "6511",
    item: "金融商品取引業（投資助言・代理業・運用業，補助的金融商品取引業を除く）",
  },
  {
    large: "J",
    middle: "65",
    minor: "651",
    sub: "6512",
    item: "投資助言・代理業",
  },
  {
    large: "J",
    middle: "65",
    minor: "651",
    sub: "6513",
    item: "投資運用業",
  },
  {
    large: "J",
    middle: "65",
    minor: "651",
    sub: "6514",
    item: "補助的金融商品取引業",
  },
  {
    large: "J",
    middle: "65",
    minor: "652",
    sub: "0000",
    item: "商品先物取引業，商品投資顧問業",
  },
  {
    large: "J",
    middle: "65",
    minor: "652",
    sub: "6521",
    item: "商品先物取引業",
  },
  {
    large: "J",
    middle: "65",
    minor: "652",
    sub: "6522",
    item: "商品投資顧問業",
  },
  {
    large: "J",
    middle: "65",
    minor: "652",
    sub: "6529",
    item: "その他の商品先物取引業，商品投資顧問業",
  },
  {
    large: "J",
    middle: "66",
    minor: "000",
    sub: "0000",
    item: "補助的金融業等",
  },
  {
    large: "J",
    middle: "66",
    minor: "660",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（66補助的金融業等）",
  },
  {
    large: "J",
    middle: "66",
    minor: "660",
    sub: "6600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "66",
    minor: "660",
    sub: "6609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "0000",
    item: "補助的金融業，金融附帯業",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6611",
    item: "短資業",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6612",
    item: "手形交換所",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6613",
    item: "両替業",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6614",
    item: "信用保証機関",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6615",
    item: "信用保証再保険機関",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6616",
    item: "預・貯金等保険機関",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6617",
    item: "金融商品取引所",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6618",
    item: "商品取引所",
  },
  {
    large: "J",
    middle: "66",
    minor: "661",
    sub: "6619",
    item: "その他の補助的金融業，金融附帯業",
  },
  {
    large: "J",
    middle: "66",
    minor: "662",
    sub: "0000",
    item: "信託業",
  },
  {
    large: "J",
    middle: "66",
    minor: "662",
    sub: "6621",
    item: "運用型信託業",
  },
  {
    large: "J",
    middle: "66",
    minor: "662",
    sub: "6622",
    item: "管理型信託業",
  },
  {
    large: "J",
    middle: "66",
    minor: "663",
    sub: "0000",
    item: "金融代理業",
  },
  {
    large: "J",
    middle: "66",
    minor: "663",
    sub: "6631",
    item: "金融商品仲介業",
  },
  {
    large: "J",
    middle: "66",
    minor: "663",
    sub: "6632",
    item: "信託契約代理業",
  },
  {
    large: "J",
    middle: "66",
    minor: "663",
    sub: "6639",
    item: "その他の金融代理業",
  },
  {
    large: "J",
    middle: "67",
    minor: "000",
    sub: "0000",
    item: "保険業（保険媒介代理業，保険サービス業を含む）",
  },
  {
    large: "J",
    middle: "67",
    minor: "670",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（67保険業）",
  },
  {
    large: "J",
    middle: "67",
    minor: "670",
    sub: "6700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "J",
    middle: "67",
    minor: "670",
    sub: "6709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "J",
    middle: "67",
    minor: "671",
    sub: "0000",
    item: "生命保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "671",
    sub: "6711",
    item: "生命保険業（郵便保険業，生命保険再保険業を除く）",
  },
  {
    large: "J",
    middle: "67",
    minor: "671",
    sub: "6712",
    item: "郵便保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "671",
    sub: "6713",
    item: "生命保険再保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "671",
    sub: "6719",
    item: "その他の生命保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "672",
    sub: "0000",
    item: "損害保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "672",
    sub: "6721",
    item: "損害保険業（損害保険再保険業を除く）",
  },
  {
    large: "J",
    middle: "67",
    minor: "672",
    sub: "6722",
    item: "損害保険再保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "672",
    sub: "6729",
    item: "その他の損害保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "673",
    sub: "0000",
    item: "共済事業，少額短期保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "673",
    sub: "6731",
    item: "共済事業（各種災害補償法によるもの）",
  },
  {
    large: "J",
    middle: "67",
    minor: "673",
    sub: "6732",
    item: "共済事業（各種協同組合法等によるもの）",
  },
  {
    large: "J",
    middle: "67",
    minor: "673",
    sub: "6733",
    item: "少額短期保険業",
  },
  {
    large: "J",
    middle: "67",
    minor: "674",
    sub: "0000",
    item: "保険媒介代理業",
  },
  {
    large: "J",
    middle: "67",
    minor: "674",
    sub: "6741",
    item: "生命保険媒介業",
  },
  {
    large: "J",
    middle: "67",
    minor: "674",
    sub: "6742",
    item: "損害保険代理業",
  },
  {
    large: "J",
    middle: "67",
    minor: "674",
    sub: "6743",
    item: "共済事業媒介代理業・少額短期保険代理業",
  },
  {
    large: "J",
    middle: "67",
    minor: "675",
    sub: "0000",
    item: "保険サービス業",
  },
  {
    large: "J",
    middle: "67",
    minor: "675",
    sub: "6751",
    item: "保険料率算出団体",
  },
  {
    large: "J",
    middle: "67",
    minor: "675",
    sub: "6752",
    item: "損害査定業",
  },
  {
    large: "J",
    middle: "67",
    minor: "675",
    sub: "6759",
    item: "その他の保険サービス業",
  },
  {
    large: "K",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "不動産業，物品賃貸業",
  },
  {
    large: "K",
    middle: "68",
    minor: "000",
    sub: "0000",
    item: "不動産取引業",
  },
  {
    large: "K",
    middle: "68",
    minor: "680",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（68不動産取引業）",
  },
  {
    large: "K",
    middle: "68",
    minor: "680",
    sub: "6800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "K",
    middle: "68",
    minor: "680",
    sub: "6809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "K",
    middle: "68",
    minor: "681",
    sub: "0000",
    item: "建物売買業，土地売買業",
  },
  {
    large: "K",
    middle: "68",
    minor: "681",
    sub: "6811",
    item: "建物売買業",
  },
  {
    large: "K",
    middle: "68",
    minor: "681",
    sub: "6812",
    item: "土地売買業",
  },
  {
    large: "K",
    middle: "68",
    minor: "682",
    sub: "0000",
    item: "不動産代理業・仲介業",
  },
  {
    large: "K",
    middle: "68",
    minor: "682",
    sub: "6821",
    item: "不動産代理業・仲介業",
  },
  {
    large: "K",
    middle: "69",
    minor: "000",
    sub: "0000",
    item: "不動産賃貸業・管理業",
  },
  {
    large: "K",
    middle: "69",
    minor: "690",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（69不動産賃貸業・管理業）",
  },
  {
    large: "K",
    middle: "69",
    minor: "690",
    sub: "6900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "K",
    middle: "69",
    minor: "690",
    sub: "6909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "K",
    middle: "69",
    minor: "691",
    sub: "0000",
    item: "不動産賃貸業（貸家業，貸間業を除く）",
  },
  {
    large: "K",
    middle: "69",
    minor: "691",
    sub: "6911",
    item: "貸事務所業",
  },
  {
    large: "K",
    middle: "69",
    minor: "691",
    sub: "6912",
    item: "土地賃貸業",
  },
  {
    large: "K",
    middle: "69",
    minor: "691",
    sub: "6919",
    item: "その他の不動産賃貸業",
  },
  {
    large: "K",
    middle: "69",
    minor: "692",
    sub: "0000",
    item: "貸家業，貸間業",
  },
  {
    large: "K",
    middle: "69",
    minor: "692",
    sub: "6921",
    item: "貸家業",
  },
  {
    large: "K",
    middle: "69",
    minor: "692",
    sub: "6922",
    item: "貸間業",
  },
  {
    large: "K",
    middle: "69",
    minor: "693",
    sub: "0000",
    item: "駐車場業",
  },
  {
    large: "K",
    middle: "69",
    minor: "693",
    sub: "6931",
    item: "駐車場業",
  },
  {
    large: "K",
    middle: "69",
    minor: "694",
    sub: "0000",
    item: "不動産管理業",
  },
  {
    large: "K",
    middle: "69",
    minor: "694",
    sub: "6941",
    item: "不動産管理業",
  },
  {
    large: "K",
    middle: "70",
    minor: "000",
    sub: "0000",
    item: "物品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "700",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（70物品賃貸業）",
  },
  {
    large: "K",
    middle: "70",
    minor: "700",
    sub: "7000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "K",
    middle: "70",
    minor: "700",
    sub: "7009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "K",
    middle: "70",
    minor: "701",
    sub: "0000",
    item: "各種物品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "701",
    sub: "7011",
    item: "総合リース業",
  },
  {
    large: "K",
    middle: "70",
    minor: "701",
    sub: "7019",
    item: "その他の各種物品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "702",
    sub: "0000",
    item: "産業用機械器具賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "702",
    sub: "7021",
    item: "産業用機械器具賃貸業（建設機械器具を除く）",
  },
  {
    large: "K",
    middle: "70",
    minor: "702",
    sub: "7022",
    item: "建設機械器具賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "703",
    sub: "0000",
    item: "事務用機械器具賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "703",
    sub: "7031",
    item: "事務用機械器具賃貸業（電子計算機を除く）",
  },
  {
    large: "K",
    middle: "70",
    minor: "703",
    sub: "7032",
    item: "電子計算機・同関連機器賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "704",
    sub: "0000",
    item: "自動車賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "704",
    sub: "7041",
    item: "自動車賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "705",
    sub: "0000",
    item: "スポーツ・娯楽用品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "705",
    sub: "7051",
    item: "スポーツ・娯楽用品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "709",
    sub: "0000",
    item: "その他の物品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "709",
    sub: "7091",
    item: "映画・演劇用品賃貸業",
  },
  {
    large: "K",
    middle: "70",
    minor: "709",
    sub: "7092",
    item: "音楽・映像記録物賃貸業（別掲を除く）",
  },
  {
    large: "K",
    middle: "70",
    minor: "709",
    sub: "7093",
    item: "貸衣しょう業（別掲を除く）",
  },
  {
    large: "K",
    middle: "70",
    minor: "709",
    sub: "7099",
    item: "他に分類されない物品賃貸業",
  },
  {
    large: "L",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "学術研究，専門・技術サービス業",
  },
  {
    large: "L",
    middle: "71",
    minor: "000",
    sub: "0000",
    item: "学術・開発研究機関",
  },
  {
    large: "L",
    middle: "71",
    minor: "710",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（71学術・開発研究機関）",
  },
  {
    large: "L",
    middle: "71",
    minor: "710",
    sub: "7101",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "L",
    middle: "71",
    minor: "711",
    sub: "0000",
    item: "自然科学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "711",
    sub: "7111",
    item: "理学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "711",
    sub: "7112",
    item: "工学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "711",
    sub: "7113",
    item: "農学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "711",
    sub: "7114",
    item: "医学・薬学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "712",
    sub: "0000",
    item: "人文・社会科学研究所",
  },
  {
    large: "L",
    middle: "71",
    minor: "712",
    sub: "7121",
    item: "人文・社会科学研究所",
  },
  {
    large: "L",
    middle: "72",
    minor: "000",
    sub: "0000",
    item: "専門サービス業（他に分類されないもの）",
  },
  {
    large: "L",
    middle: "72",
    minor: "720",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（72専門サービス業）",
  },
  {
    large: "L",
    middle: "72",
    minor: "720",
    sub: "7201",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "L",
    middle: "72",
    minor: "721",
    sub: "0000",
    item: "法律事務所，特許事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "721",
    sub: "7211",
    item: "法律事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "721",
    sub: "7212",
    item: "特許事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "722",
    sub: "0000",
    item: "公証人役場，司法書士事務所，土地家屋調査士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "722",
    sub: "7221",
    item: "公証人役場，司法書士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "722",
    sub: "7222",
    item: "土地家屋調査士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "723",
    sub: "0000",
    item: "行政書士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "723",
    sub: "7231",
    item: "行政書士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "724",
    sub: "0000",
    item: "公認会計士事務所，税理士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "724",
    sub: "7241",
    item: "公認会計士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "724",
    sub: "7242",
    item: "税理士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "725",
    sub: "0000",
    item: "社会保険労務士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "725",
    sub: "7251",
    item: "社会保険労務士事務所",
  },
  {
    large: "L",
    middle: "72",
    minor: "726",
    sub: "0000",
    item: "デザイン業",
  },
  {
    large: "L",
    middle: "72",
    minor: "726",
    sub: "7261",
    item: "デザイン業",
  },
  {
    large: "L",
    middle: "72",
    minor: "727",
    sub: "0000",
    item: "著述・芸術家業",
  },
  {
    large: "L",
    middle: "72",
    minor: "727",
    sub: "7271",
    item: "著述家業",
  },
  {
    large: "L",
    middle: "72",
    minor: "727",
    sub: "7272",
    item: "芸術家業",
  },
  {
    large: "L",
    middle: "72",
    minor: "728",
    sub: "0000",
    item: "経営コンサルタント業，純粋持株会社",
  },
  {
    large: "L",
    middle: "72",
    minor: "728",
    sub: "7281",
    item: "経営コンサルタント業",
  },
  {
    large: "L",
    middle: "72",
    minor: "728",
    sub: "7282",
    item: "純粋持株会社",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "0000",
    item: "その他の専門サービス業",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "7291",
    item: "興信所",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "7292",
    item: "翻訳業（著述家業を除く）",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "7293",
    item: "通訳業，通訳案内業",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "7294",
    item: "不動産鑑定業",
  },
  {
    large: "L",
    middle: "72",
    minor: "729",
    sub: "7299",
    item: "他に分類されない専門サービス業",
  },
  {
    large: "L",
    middle: "73",
    minor: "000",
    sub: "0000",
    item: "広告業",
  },
  {
    large: "L",
    middle: "73",
    minor: "730",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（73広告業）",
  },
  {
    large: "L",
    middle: "73",
    minor: "730",
    sub: "7300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "L",
    middle: "73",
    minor: "730",
    sub: "7309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "L",
    middle: "73",
    minor: "731",
    sub: "0000",
    item: "広告業",
  },
  {
    large: "L",
    middle: "73",
    minor: "731",
    sub: "7311",
    item: "広告業",
  },
  {
    large: "L",
    middle: "74",
    minor: "000",
    sub: "0000",
    item: "技術サービス業（他に分類されないもの）",
  },
  {
    large: "L",
    middle: "74",
    minor: "740",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（74技術サービス業）",
  },
  {
    large: "L",
    middle: "74",
    minor: "740",
    sub: "7401",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "L",
    middle: "74",
    minor: "741",
    sub: "0000",
    item: "獣医業",
  },
  {
    large: "L",
    middle: "74",
    minor: "741",
    sub: "7411",
    item: "獣医業",
  },
  {
    large: "L",
    middle: "74",
    minor: "742",
    sub: "0000",
    item: "土木建築サービス業",
  },
  {
    large: "L",
    middle: "74",
    minor: "742",
    sub: "7421",
    item: "建築設計業",
  },
  {
    large: "L",
    middle: "74",
    minor: "742",
    sub: "7422",
    item: "測量業",
  },
  {
    large: "L",
    middle: "74",
    minor: "742",
    sub: "7429",
    item: "その他の土木建築サービス業",
  },
  {
    large: "L",
    middle: "74",
    minor: "743",
    sub: "0000",
    item: "機械設計業",
  },
  {
    large: "L",
    middle: "74",
    minor: "743",
    sub: "7431",
    item: "機械設計業",
  },
  {
    large: "L",
    middle: "74",
    minor: "744",
    sub: "0000",
    item: "商品・非破壊検査業",
  },
  {
    large: "L",
    middle: "74",
    minor: "744",
    sub: "7441",
    item: "商品検査業",
  },
  {
    large: "L",
    middle: "74",
    minor: "744",
    sub: "7442",
    item: "非破壊検査業",
  },
  {
    large: "L",
    middle: "74",
    minor: "745",
    sub: "0000",
    item: "計量証明業",
  },
  {
    large: "L",
    middle: "74",
    minor: "745",
    sub: "7451",
    item: "一般計量証明業",
  },
  {
    large: "L",
    middle: "74",
    minor: "745",
    sub: "7452",
    item: "環境計量証明業",
  },
  {
    large: "L",
    middle: "74",
    minor: "745",
    sub: "7459",
    item: "その他の計量証明業",
  },
  {
    large: "L",
    middle: "74",
    minor: "746",
    sub: "0000",
    item: "写真業",
  },
  {
    large: "L",
    middle: "74",
    minor: "746",
    sub: "7461",
    item: "写真業（商業写真業を除く）",
  },
  {
    large: "L",
    middle: "74",
    minor: "746",
    sub: "7462",
    item: "商業写真業",
  },
  {
    large: "L",
    middle: "74",
    minor: "749",
    sub: "0000",
    item: "その他の技術サービス業",
  },
  {
    large: "L",
    middle: "74",
    minor: "749",
    sub: "7499",
    item: "その他の技術サービス業",
  },
  {
    large: "M",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "宿泊業，飲食サービス業",
  },
  {
    large: "M",
    middle: "75",
    minor: "000",
    sub: "0000",
    item: "宿泊業",
  },
  {
    large: "M",
    middle: "75",
    minor: "750",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（75宿泊業）",
  },
  {
    large: "M",
    middle: "75",
    minor: "750",
    sub: "7500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "M",
    middle: "75",
    minor: "750",
    sub: "7509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "M",
    middle: "75",
    minor: "751",
    sub: "0000",
    item: "旅館，ホテル",
  },
  {
    large: "M",
    middle: "75",
    minor: "751",
    sub: "7511",
    item: "旅館，ホテル",
  },
  {
    large: "M",
    middle: "75",
    minor: "752",
    sub: "0000",
    item: "簡易宿所",
  },
  {
    large: "M",
    middle: "75",
    minor: "752",
    sub: "7521",
    item: "簡易宿所",
  },
  {
    large: "M",
    middle: "75",
    minor: "753",
    sub: "0000",
    item: "下宿業",
  },
  {
    large: "M",
    middle: "75",
    minor: "753",
    sub: "7531",
    item: "下宿業",
  },
  {
    large: "M",
    middle: "75",
    minor: "759",
    sub: "0000",
    item: "その他の宿泊業",
  },
  {
    large: "M",
    middle: "75",
    minor: "759",
    sub: "7591",
    item: "会社・団体の宿泊所",
  },
  {
    large: "M",
    middle: "75",
    minor: "759",
    sub: "7592",
    item: "リゾートクラブ",
  },
  {
    large: "M",
    middle: "75",
    minor: "759",
    sub: "7599",
    item: "他に分類されない宿泊業",
  },
  {
    large: "M",
    middle: "76",
    minor: "000",
    sub: "0000",
    item: "飲食店",
  },
  {
    large: "M",
    middle: "76",
    minor: "760",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（76飲食店）",
  },
  {
    large: "M",
    middle: "76",
    minor: "760",
    sub: "7600",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "M",
    middle: "76",
    minor: "760",
    sub: "7609",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "M",
    middle: "76",
    minor: "761",
    sub: "0000",
    item: "食堂，レストラン（専門料理店を除く）",
  },
  {
    large: "M",
    middle: "76",
    minor: "761",
    sub: "7611",
    item: "食堂，レストラン（専門料理店を除く）",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "0000",
    item: "専門料理店",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7621",
    item: "日本料理店",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7622",
    item: "料亭",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7623",
    item: "中華料理店",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7624",
    item: "ラーメン店",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7625",
    item: "焼肉店",
  },
  {
    large: "M",
    middle: "76",
    minor: "762",
    sub: "7629",
    item: "その他の専門料理店",
  },
  {
    large: "M",
    middle: "76",
    minor: "763",
    sub: "0000",
    item: "そば・うどん店",
  },
  {
    large: "M",
    middle: "76",
    minor: "763",
    sub: "7631",
    item: "そば・うどん店",
  },
  {
    large: "M",
    middle: "76",
    minor: "764",
    sub: "0000",
    item: "すし店",
  },
  {
    large: "M",
    middle: "76",
    minor: "764",
    sub: "7641",
    item: "すし店",
  },
  {
    large: "M",
    middle: "76",
    minor: "765",
    sub: "0000",
    item: "酒場，ビヤホール",
  },
  {
    large: "M",
    middle: "76",
    minor: "765",
    sub: "7651",
    item: "酒場，ビヤホール",
  },
  {
    large: "M",
    middle: "76",
    minor: "766",
    sub: "0000",
    item: "バー，キャバレー，ナイトクラブ",
  },
  {
    large: "M",
    middle: "76",
    minor: "766",
    sub: "7661",
    item: "バー，キャバレー，ナイトクラブ",
  },
  {
    large: "M",
    middle: "76",
    minor: "767",
    sub: "0000",
    item: "喫茶店",
  },
  {
    large: "M",
    middle: "76",
    minor: "767",
    sub: "7671",
    item: "喫茶店",
  },
  {
    large: "M",
    middle: "76",
    minor: "769",
    sub: "0000",
    item: "その他の飲食店",
  },
  {
    large: "M",
    middle: "76",
    minor: "769",
    sub: "7691",
    item: "ハンバーガー店",
  },
  {
    large: "M",
    middle: "76",
    minor: "769",
    sub: "7692",
    item: "お好み焼・焼きそば・たこ焼店",
  },
  {
    large: "M",
    middle: "76",
    minor: "769",
    sub: "7699",
    item: "他に分類されない飲食店",
  },
  {
    large: "M",
    middle: "77",
    minor: "000",
    sub: "0000",
    item: "持ち帰り・配達飲食サービス業",
  },
  {
    large: "M",
    middle: "77",
    minor: "770",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（77持ち帰り・配達飲食サービス業）",
  },
  {
    large: "M",
    middle: "77",
    minor: "770",
    sub: "7700",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "M",
    middle: "77",
    minor: "770",
    sub: "7709",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "M",
    middle: "77",
    minor: "771",
    sub: "0000",
    item: "持ち帰り飲食サービス業",
  },
  {
    large: "M",
    middle: "77",
    minor: "771",
    sub: "7711",
    item: "持ち帰り飲食サービス業",
  },
  {
    large: "M",
    middle: "77",
    minor: "772",
    sub: "0000",
    item: "配達飲食サービス業",
  },
  {
    large: "M",
    middle: "77",
    minor: "772",
    sub: "7721",
    item: "配達飲食サービス業",
  },
  {
    large: "N",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "生活関連サービス業，娯楽業",
  },
  {
    large: "N",
    middle: "78",
    minor: "000",
    sub: "0000",
    item: "洗濯・理容・美容・浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "780",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（78洗濯・理容・美容・浴場業）",
  },
  {
    large: "N",
    middle: "78",
    minor: "780",
    sub: "7800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "N",
    middle: "78",
    minor: "780",
    sub: "7809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "N",
    middle: "78",
    minor: "781",
    sub: "0000",
    item: "洗濯業",
  },
  {
    large: "N",
    middle: "78",
    minor: "781",
    sub: "7811",
    item: "普通洗濯業",
  },
  {
    large: "N",
    middle: "78",
    minor: "781",
    sub: "7812",
    item: "洗濯物取次業",
  },
  {
    large: "N",
    middle: "78",
    minor: "781",
    sub: "7813",
    item: "リネンサプライ業",
  },
  {
    large: "N",
    middle: "78",
    minor: "782",
    sub: "0000",
    item: "理容業",
  },
  {
    large: "N",
    middle: "78",
    minor: "782",
    sub: "7821",
    item: "理容業",
  },
  {
    large: "N",
    middle: "78",
    minor: "783",
    sub: "0000",
    item: "美容業",
  },
  {
    large: "N",
    middle: "78",
    minor: "783",
    sub: "7831",
    item: "美容業",
  },
  {
    large: "N",
    middle: "78",
    minor: "784",
    sub: "0000",
    item: "一般公衆浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "784",
    sub: "7841",
    item: "一般公衆浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "785",
    sub: "0000",
    item: "その他の公衆浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "785",
    sub: "7851",
    item: "その他の公衆浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "0000",
    item: "その他の洗濯・理容・美容・浴場業",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "7891",
    item: "洗張・染物業",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "7892",
    item: "エステティック業",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "7893",
    item: "リラクゼーション業(手技を用いるもの)",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "7894",
    item: "ネイルサービス業",
  },
  {
    large: "N",
    middle: "78",
    minor: "789",
    sub: "7899",
    item: "他に分類されない洗濯・理容・美容・浴場業",
  },
  {
    large: "N",
    middle: "79",
    minor: "000",
    sub: "0000",
    item: "その他の生活関連サービス業",
  },
  {
    large: "N",
    middle: "79",
    minor: "790",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（79その他の生活関連サービス業）",
  },
  {
    large: "N",
    middle: "79",
    minor: "790",
    sub: "7900",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "N",
    middle: "79",
    minor: "790",
    sub: "7909",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "N",
    middle: "79",
    minor: "791",
    sub: "0000",
    item: "旅行業",
  },
  {
    large: "N",
    middle: "79",
    minor: "791",
    sub: "7911",
    item: "旅行業(旅行業者代理業を除く)",
  },
  {
    large: "N",
    middle: "79",
    minor: "791",
    sub: "7912",
    item: "旅行業者代理業",
  },
  {
    large: "N",
    middle: "79",
    minor: "792",
    sub: "0000",
    item: "家事サービス業",
  },
  {
    large: "N",
    middle: "79",
    minor: "792",
    sub: "7921",
    item: "家事サービス業（住込みのもの）",
  },
  {
    large: "N",
    middle: "79",
    minor: "792",
    sub: "7922",
    item: "家事サービス業（住込みでないもの）",
  },
  {
    large: "N",
    middle: "79",
    minor: "793",
    sub: "0000",
    item: "衣服裁縫修理業",
  },
  {
    large: "N",
    middle: "79",
    minor: "793",
    sub: "7931",
    item: "衣服裁縫修理業",
  },
  {
    large: "N",
    middle: "79",
    minor: "794",
    sub: "0000",
    item: "物品預り業",
  },
  {
    large: "N",
    middle: "79",
    minor: "794",
    sub: "7941",
    item: "物品預り業",
  },
  {
    large: "N",
    middle: "79",
    minor: "795",
    sub: "0000",
    item: "火葬・墓地管理業",
  },
  {
    large: "N",
    middle: "79",
    minor: "795",
    sub: "7951",
    item: "火葬業",
  },
  {
    large: "N",
    middle: "79",
    minor: "795",
    sub: "7952",
    item: "墓地管理業",
  },
  {
    large: "N",
    middle: "79",
    minor: "796",
    sub: "0000",
    item: "冠婚葬祭業",
  },
  {
    large: "N",
    middle: "79",
    minor: "796",
    sub: "7961",
    item: "葬儀業",
  },
  {
    large: "N",
    middle: "79",
    minor: "796",
    sub: "7962",
    item: "結婚式場業",
  },
  {
    large: "N",
    middle: "79",
    minor: "796",
    sub: "7963",
    item: "冠婚葬祭互助会",
  },
  {
    large: "N",
    middle: "79",
    minor: "799",
    sub: "0000",
    item: "他に分類されない生活関連サービス業",
  },
  {
    large: "N",
    middle: "79",
    minor: "799",
    sub: "7991",
    item: "食品賃加工業",
  },
  {
    large: "N",
    middle: "79",
    minor: "799",
    sub: "7992",
    item: "結婚相談業，結婚式場紹介業",
  },
  {
    large: "N",
    middle: "79",
    minor: "799",
    sub: "7993",
    item: "写真プリント，現像・焼付業",
  },
  {
    large: "N",
    middle: "79",
    minor: "799",
    sub: "7999",
    item: "他に分類されないその他の生活関連サービス業",
  },
  {
    large: "N",
    middle: "80",
    minor: "000",
    sub: "0000",
    item: "娯楽業",
  },
  {
    large: "N",
    middle: "80",
    minor: "800",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（80娯楽業）",
  },
  {
    large: "N",
    middle: "80",
    minor: "800",
    sub: "8000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "N",
    middle: "80",
    minor: "800",
    sub: "8009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "N",
    middle: "80",
    minor: "801",
    sub: "0000",
    item: "映画館",
  },
  {
    large: "N",
    middle: "80",
    minor: "801",
    sub: "8011",
    item: "映画館",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "0000",
    item: "興行場（別掲を除く），興行団",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "8021",
    item: "劇場",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "8022",
    item: "興行場",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "8023",
    item: "劇団",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "8024",
    item: "楽団，舞踏団",
  },
  {
    large: "N",
    middle: "80",
    minor: "802",
    sub: "8025",
    item: "演芸・スポーツ等興行団",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "0000",
    item: "競輪・競馬等の競走場，競技団",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8031",
    item: "競輪場",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8032",
    item: "競馬場",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8033",
    item: "自動車・モータボートの競走場",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8034",
    item: "競輪競技団",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8035",
    item: "競馬競技団",
  },
  {
    large: "N",
    middle: "80",
    minor: "803",
    sub: "8036",
    item: "自動車・モータボートの競技団",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "0000",
    item: "スポーツ施設提供業",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8041",
    item: "スポーツ施設提供業（別掲を除く）",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8042",
    item: "体育館",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8043",
    item: "ゴルフ場",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8044",
    item: "ゴルフ練習場",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8045",
    item: "ボウリング場",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8046",
    item: "テニス場",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8047",
    item: "バッティング・テニス練習場",
  },
  {
    large: "N",
    middle: "80",
    minor: "804",
    sub: "8048",
    item: "フィットネスクラブ",
  },
  {
    large: "N",
    middle: "80",
    minor: "805",
    sub: "0000",
    item: "公園，遊園地",
  },
  {
    large: "N",
    middle: "80",
    minor: "805",
    sub: "8051",
    item: "公園",
  },
  {
    large: "N",
    middle: "80",
    minor: "805",
    sub: "8052",
    item: "遊園地（テーマパークを除く）",
  },
  {
    large: "N",
    middle: "80",
    minor: "805",
    sub: "8053",
    item: "テーマパーク",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "0000",
    item: "遊戯場",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8061",
    item: "ビリヤード場",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8062",
    item: "囲碁・将棋所",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8063",
    item: "マージャンクラブ",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8064",
    item: "パチンコホール",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8065",
    item: "ゲームセンター",
  },
  {
    large: "N",
    middle: "80",
    minor: "806",
    sub: "8069",
    item: "その他の遊戯場",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "0000",
    item: "その他の娯楽業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8091",
    item: "ダンスホール",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8092",
    item: "マリーナ業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8093",
    item: "遊漁船業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8094",
    item: "芸ぎ業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8095",
    item: "カラオケボックス業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8096",
    item: "娯楽に附帯するサービス業",
  },
  {
    large: "N",
    middle: "80",
    minor: "809",
    sub: "8099",
    item: "他に分類されない娯楽業",
  },
  {
    large: "O",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "教育，学習支援業",
  },
  {
    large: "O",
    middle: "81",
    minor: "000",
    sub: "0000",
    item: "学校教育",
  },
  {
    large: "O",
    middle: "81",
    minor: "810",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（81学校教育）",
  },
  {
    large: "O",
    middle: "81",
    minor: "810",
    sub: "8101",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "O",
    middle: "81",
    minor: "811",
    sub: "0000",
    item: "幼稚園",
  },
  {
    large: "O",
    middle: "81",
    minor: "811",
    sub: "8111",
    item: "幼稚園",
  },
  {
    large: "O",
    middle: "81",
    minor: "812",
    sub: "0000",
    item: "小学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "812",
    sub: "8121",
    item: "小学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "813",
    sub: "0000",
    item: "中学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "813",
    sub: "8131",
    item: "中学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "814",
    sub: "0000",
    item: "高等学校，中等教育学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "814",
    sub: "8141",
    item: "高等学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "814",
    sub: "8142",
    item: "中等教育学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "815",
    sub: "0000",
    item: "特別支援学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "815",
    sub: "8151",
    item: "特別支援学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "816",
    sub: "0000",
    item: "高等教育機関",
  },
  {
    large: "O",
    middle: "81",
    minor: "816",
    sub: "8161",
    item: "大学",
  },
  {
    large: "O",
    middle: "81",
    minor: "816",
    sub: "8162",
    item: "短期大学",
  },
  {
    large: "O",
    middle: "81",
    minor: "816",
    sub: "8163",
    item: "高等専門学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "817",
    sub: "0000",
    item: "専修学校，各種学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "817",
    sub: "8171",
    item: "専修学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "817",
    sub: "8172",
    item: "各種学校",
  },
  {
    large: "O",
    middle: "81",
    minor: "818",
    sub: "0000",
    item: "学校教育支援機関",
  },
  {
    large: "O",
    middle: "81",
    minor: "818",
    sub: "8181",
    item: "学校教育支援機関",
  },
  {
    large: "O",
    middle: "81",
    minor: "819",
    sub: "0000",
    item: "幼保連携型認定こども園",
  },
  {
    large: "O",
    middle: "81",
    minor: "819",
    sub: "8191",
    item: "幼保連携型認定こども園",
  },
  {
    large: "O",
    middle: "82",
    minor: "000",
    sub: "0000",
    item: "その他の教育，学習支援業",
  },
  {
    large: "O",
    middle: "82",
    minor: "820",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（82その他の教育，学習支援業）",
  },
  {
    large: "O",
    middle: "82",
    minor: "820",
    sub: "8200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "O",
    middle: "82",
    minor: "820",
    sub: "8209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "0000",
    item: "社会教育",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8211",
    item: "公民館",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8212",
    item: "図書館",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8213",
    item: "博物館，美術館",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8214",
    item: "動物園，植物園，水族館",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8215",
    item: "青少年教育施設",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8216",
    item: "社会通信教育",
  },
  {
    large: "O",
    middle: "82",
    minor: "821",
    sub: "8219",
    item: "その他の社会教育",
  },
  {
    large: "O",
    middle: "82",
    minor: "822",
    sub: "0000",
    item: "職業・教育支援施設",
  },
  {
    large: "O",
    middle: "82",
    minor: "822",
    sub: "8221",
    item: "職員教育施設・支援業",
  },
  {
    large: "O",
    middle: "82",
    minor: "822",
    sub: "8222",
    item: "職業訓練施設",
  },
  {
    large: "O",
    middle: "82",
    minor: "822",
    sub: "8229",
    item: "その他の職業・教育支援施設",
  },
  {
    large: "O",
    middle: "82",
    minor: "823",
    sub: "0000",
    item: "学習塾",
  },
  {
    large: "O",
    middle: "82",
    minor: "823",
    sub: "8231",
    item: "学習塾",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "0000",
    item: "教養・技能教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8241",
    item: "音楽教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8242",
    item: "書道教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8243",
    item: "生花・茶道教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8244",
    item: "そろばん教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8245",
    item: "外国語会話教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8246",
    item: "スポーツ・健康教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "824",
    sub: "8249",
    item: "その他の教養・技能教授業",
  },
  {
    large: "O",
    middle: "82",
    minor: "829",
    sub: "0000",
    item: "他に分類されない教育，学習支援業",
  },
  {
    large: "O",
    middle: "82",
    minor: "829",
    sub: "8299",
    item: "他に分類されない教育，学習支援業",
  },
  {
    large: "P",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "医療，福祉",
  },
  {
    large: "P",
    middle: "83",
    minor: "000",
    sub: "0000",
    item: "医療業",
  },
  {
    large: "P",
    middle: "83",
    minor: "830",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（83医療業）",
  },
  {
    large: "P",
    middle: "83",
    minor: "830",
    sub: "8300",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "P",
    middle: "83",
    minor: "830",
    sub: "8309",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "P",
    middle: "83",
    minor: "831",
    sub: "0000",
    item: "病院",
  },
  {
    large: "P",
    middle: "83",
    minor: "831",
    sub: "8311",
    item: "一般病院",
  },
  {
    large: "P",
    middle: "83",
    minor: "831",
    sub: "8312",
    item: "精神科病院",
  },
  {
    large: "P",
    middle: "83",
    minor: "832",
    sub: "0000",
    item: "一般診療所",
  },
  {
    large: "P",
    middle: "83",
    minor: "832",
    sub: "8321",
    item: "有床診療所",
  },
  {
    large: "P",
    middle: "83",
    minor: "832",
    sub: "8322",
    item: "無床診療所",
  },
  {
    large: "P",
    middle: "83",
    minor: "833",
    sub: "0000",
    item: "歯科診療所",
  },
  {
    large: "P",
    middle: "83",
    minor: "833",
    sub: "8331",
    item: "歯科診療所",
  },
  {
    large: "P",
    middle: "83",
    minor: "834",
    sub: "0000",
    item: "助産・看護業",
  },
  {
    large: "P",
    middle: "83",
    minor: "834",
    sub: "8341",
    item: "助産所",
  },
  {
    large: "P",
    middle: "83",
    minor: "834",
    sub: "8342",
    item: "看護業",
  },
  {
    large: "P",
    middle: "83",
    minor: "835",
    sub: "0000",
    item: "療術業",
  },
  {
    large: "P",
    middle: "83",
    minor: "835",
    sub: "8351",
    item: "あん摩マッサージ指圧師・はり師・きゅう師・柔道整復師の施術所",
  },
  {
    large: "P",
    middle: "83",
    minor: "835",
    sub: "8359",
    item: "その他の療術業",
  },
  {
    large: "P",
    middle: "83",
    minor: "836",
    sub: "0000",
    item: "医療に附帯するサービス業",
  },
  {
    large: "P",
    middle: "83",
    minor: "836",
    sub: "8361",
    item: "歯科技工所",
  },
  {
    large: "P",
    middle: "83",
    minor: "836",
    sub: "8369",
    item: "その他の医療に附帯するサービス業",
  },
  {
    large: "P",
    middle: "84",
    minor: "000",
    sub: "0000",
    item: "保健衛生",
  },
  {
    large: "P",
    middle: "84",
    minor: "840",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（84保健衛生）",
  },
  {
    large: "P",
    middle: "84",
    minor: "840",
    sub: "8400",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "P",
    middle: "84",
    minor: "840",
    sub: "8409",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "P",
    middle: "84",
    minor: "841",
    sub: "0000",
    item: "保健所",
  },
  {
    large: "P",
    middle: "84",
    minor: "841",
    sub: "8411",
    item: "保健所",
  },
  {
    large: "P",
    middle: "84",
    minor: "842",
    sub: "0000",
    item: "健康相談施設",
  },
  {
    large: "P",
    middle: "84",
    minor: "842",
    sub: "8421",
    item: "結核健康相談施設",
  },
  {
    large: "P",
    middle: "84",
    minor: "842",
    sub: "8422",
    item: "精神保健相談施設",
  },
  {
    large: "P",
    middle: "84",
    minor: "842",
    sub: "8423",
    item: "母子健康相談施設",
  },
  {
    large: "P",
    middle: "84",
    minor: "842",
    sub: "8429",
    item: "その他の健康相談施設",
  },
  {
    large: "P",
    middle: "84",
    minor: "849",
    sub: "0000",
    item: "その他の保健衛生",
  },
  {
    large: "P",
    middle: "84",
    minor: "849",
    sub: "8491",
    item: "検疫所（動物検疫所，植物防疫所を除く）",
  },
  {
    large: "P",
    middle: "84",
    minor: "849",
    sub: "8492",
    item: "検査業",
  },
  {
    large: "P",
    middle: "84",
    minor: "849",
    sub: "8493",
    item: "消毒業",
  },
  {
    large: "P",
    middle: "84",
    minor: "849",
    sub: "8499",
    item: "他に分類されない保健衛生",
  },
  {
    large: "P",
    middle: "85",
    minor: "000",
    sub: "0000",
    item: "社会保険・社会福祉・介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "850",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（85社会保険・社会福祉・介護事業）",
  },
  {
    large: "P",
    middle: "85",
    minor: "850",
    sub: "8500",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "P",
    middle: "85",
    minor: "850",
    sub: "8509",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "P",
    middle: "85",
    minor: "851",
    sub: "0000",
    item: "社会保険事業団体",
  },
  {
    large: "P",
    middle: "85",
    minor: "851",
    sub: "8511",
    item: "社会保険事業団体",
  },
  {
    large: "P",
    middle: "85",
    minor: "852",
    sub: "0000",
    item: "福祉事務所",
  },
  {
    large: "P",
    middle: "85",
    minor: "852",
    sub: "8521",
    item: "福祉事務所",
  },
  {
    large: "P",
    middle: "85",
    minor: "853",
    sub: "0000",
    item: "児童福祉事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "853",
    sub: "8531",
    item: "保育所",
  },
  {
    large: "P",
    middle: "85",
    minor: "853",
    sub: "8539",
    item: "その他の児童福祉事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "0000",
    item: "老人福祉・介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8541",
    item: "特別養護老人ホーム",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8542",
    item: "介護老人保健施設",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8543",
    item: "通所・短期入所介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8544",
    item: "訪問介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8545",
    item: "認知症老人グループホーム",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8546",
    item: "有料老人ホーム",
  },
  {
    large: "P",
    middle: "85",
    minor: "854",
    sub: "8549",
    item: "その他の老人福祉・介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "855",
    sub: "0000",
    item: "障害者福祉事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "855",
    sub: "8551",
    item: "居住支援事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "855",
    sub: "8559",
    item: "その他の障害者福祉事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "859",
    sub: "0000",
    item: "その他の社会保険・社会福祉・介護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "859",
    sub: "8591",
    item: "更生保護事業",
  },
  {
    large: "P",
    middle: "85",
    minor: "859",
    sub: "8599",
    item: "他に分類されない社会保険・社会福祉・介護事業",
  },
  {
    large: "Q",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "複合サービス事業",
  },
  {
    large: "Q",
    middle: "86",
    minor: "000",
    sub: "0000",
    item: "郵便局",
  },
  {
    large: "Q",
    middle: "86",
    minor: "860",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（86郵便局）",
  },
  {
    large: "Q",
    middle: "86",
    minor: "860",
    sub: "8601",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "Q",
    middle: "86",
    minor: "861",
    sub: "0000",
    item: "郵便局",
  },
  {
    large: "Q",
    middle: "86",
    minor: "861",
    sub: "8611",
    item: "郵便局",
  },
  {
    large: "Q",
    middle: "86",
    minor: "862",
    sub: "0000",
    item: "郵便局受託業",
  },
  {
    large: "Q",
    middle: "86",
    minor: "862",
    sub: "8621",
    item: "簡易郵便局",
  },
  {
    large: "Q",
    middle: "86",
    minor: "862",
    sub: "8629",
    item: "その他の郵便局受託業",
  },
  {
    large: "Q",
    middle: "87",
    minor: "000",
    sub: "0000",
    item: "協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "870",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（87協同組合）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "870",
    sub: "8701",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "Q",
    middle: "87",
    minor: "871",
    sub: "0000",
    item: "農林水産業協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "871",
    sub: "8711",
    item: "農業協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "871",
    sub: "8712",
    item: "漁業協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "871",
    sub: "8713",
    item: "水産加工業協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "871",
    sub: "8714",
    item: "森林組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "872",
    sub: "0000",
    item: "事業協同組合（他に分類されないもの）",
  },
  {
    large: "Q",
    middle: "87",
    minor: "872",
    sub: "8721",
    item: "事業協同組合（他に分類されないもの）",
  },
  {
    large: "R",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "サービス業（他に分類されないもの）",
  },
  {
    large: "R",
    middle: "88",
    minor: "000",
    sub: "0000",
    item: "廃棄物処理業",
  },
  {
    large: "R",
    middle: "88",
    minor: "880",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（88廃棄物処理業）",
  },
  {
    large: "R",
    middle: "88",
    minor: "880",
    sub: "8800",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "R",
    middle: "88",
    minor: "880",
    sub: "8809",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "0000",
    item: "一般廃棄物処理業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8811",
    item: "し尿収集運搬業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8812",
    item: "し尿処分業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8813",
    item: "浄化槽清掃業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8814",
    item: "浄化槽保守点検業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8815",
    item: "ごみ収集運搬業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8816",
    item: "ごみ処分業",
  },
  {
    large: "R",
    middle: "88",
    minor: "881",
    sub: "8817",
    item: "清掃事務所",
  },
  {
    large: "R",
    middle: "88",
    minor: "882",
    sub: "0000",
    item: "産業廃棄物処理業",
  },
  {
    large: "R",
    middle: "88",
    minor: "882",
    sub: "8821",
    item: "産業廃棄物収集運搬業",
  },
  {
    large: "R",
    middle: "88",
    minor: "882",
    sub: "8822",
    item: "産業廃棄物処分業",
  },
  {
    large: "R",
    middle: "88",
    minor: "882",
    sub: "8823",
    item: "特別管理産業廃棄物収集運搬業",
  },
  {
    large: "R",
    middle: "88",
    minor: "882",
    sub: "8824",
    item: "特別管理産業廃棄物処分業",
  },
  {
    large: "R",
    middle: "88",
    minor: "889",
    sub: "0000",
    item: "その他の廃棄物処理業",
  },
  {
    large: "R",
    middle: "88",
    minor: "889",
    sub: "8891",
    item: "死亡獣畜取扱業",
  },
  {
    large: "R",
    middle: "88",
    minor: "889",
    sub: "8899",
    item: "他に分類されない廃棄物処理業",
  },
  {
    large: "R",
    middle: "89",
    minor: "000",
    sub: "0000",
    item: "自動車整備業",
  },
  {
    large: "R",
    middle: "89",
    minor: "890",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（89自動車整備業）",
  },
  {
    large: "R",
    middle: "89",
    minor: "890",
    sub: "8901",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "89",
    minor: "891",
    sub: "0000",
    item: "自動車整備業",
  },
  {
    large: "R",
    middle: "89",
    minor: "891",
    sub: "8911",
    item: "自動車一般整備業",
  },
  {
    large: "R",
    middle: "89",
    minor: "891",
    sub: "8919",
    item: "その他の自動車整備業",
  },
  {
    large: "R",
    middle: "90",
    minor: "000",
    sub: "0000",
    item: "機械等修理業（別掲を除く）",
  },
  {
    large: "R",
    middle: "90",
    minor: "900",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（90機械等修理業）",
  },
  {
    large: "R",
    middle: "90",
    minor: "900",
    sub: "9000",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "R",
    middle: "90",
    minor: "900",
    sub: "9009",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "90",
    minor: "901",
    sub: "0000",
    item: "機械修理業（電気機械器具を除く）",
  },
  {
    large: "R",
    middle: "90",
    minor: "901",
    sub: "9011",
    item: "一般機械修理業（建設・鉱山機械を除く）",
  },
  {
    large: "R",
    middle: "90",
    minor: "901",
    sub: "9012",
    item: "建設・鉱山機械整備業",
  },
  {
    large: "R",
    middle: "90",
    minor: "902",
    sub: "0000",
    item: "電気機械器具修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "902",
    sub: "9021",
    item: "電気機械器具修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "903",
    sub: "0000",
    item: "表具業",
  },
  {
    large: "R",
    middle: "90",
    minor: "903",
    sub: "9031",
    item: "表具業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "0000",
    item: "その他の修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "9091",
    item: "家具修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "9092",
    item: "時計修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "9093",
    item: "履物修理業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "9094",
    item: "かじ業",
  },
  {
    large: "R",
    middle: "90",
    minor: "909",
    sub: "9099",
    item: "他に分類されない修理業",
  },
  {
    large: "R",
    middle: "91",
    minor: "000",
    sub: "0000",
    item: "職業紹介・労働者派遣業",
  },
  {
    large: "R",
    middle: "91",
    minor: "910",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（91職業紹介・労働者派遣業）",
  },
  {
    large: "R",
    middle: "91",
    minor: "910",
    sub: "9100",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "R",
    middle: "91",
    minor: "910",
    sub: "9109",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "91",
    minor: "911",
    sub: "0000",
    item: "職業紹介業",
  },
  {
    large: "R",
    middle: "91",
    minor: "911",
    sub: "9111",
    item: "職業紹介業",
  },
  {
    large: "R",
    middle: "91",
    minor: "912",
    sub: "0000",
    item: "労働者派遣業",
  },
  {
    large: "R",
    middle: "91",
    minor: "912",
    sub: "9121",
    item: "労働者派遣業",
  },
  {
    large: "R",
    middle: "92",
    minor: "000",
    sub: "0000",
    item: "その他の事業サービス業",
  },
  {
    large: "R",
    middle: "92",
    minor: "920",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（92その他の事業サービス業）",
  },
  {
    large: "R",
    middle: "92",
    minor: "920",
    sub: "9200",
    item: "主として管理事務を行う本社等",
  },
  {
    large: "R",
    middle: "92",
    minor: "920",
    sub: "9209",
    item: "その他の管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "92",
    minor: "921",
    sub: "0000",
    item: "速記・ワープロ入力・複写業",
  },
  {
    large: "R",
    middle: "92",
    minor: "921",
    sub: "9211",
    item: "速記・ワープロ入力業",
  },
  {
    large: "R",
    middle: "92",
    minor: "921",
    sub: "9212",
    item: "複写業",
  },
  {
    large: "R",
    middle: "92",
    minor: "922",
    sub: "0000",
    item: "建物サービス業",
  },
  {
    large: "R",
    middle: "92",
    minor: "922",
    sub: "9221",
    item: "ビルメンテナンス業",
  },
  {
    large: "R",
    middle: "92",
    minor: "922",
    sub: "9229",
    item: "その他の建物サービス業",
  },
  {
    large: "R",
    middle: "92",
    minor: "923",
    sub: "0000",
    item: "警備業",
  },
  {
    large: "R",
    middle: "92",
    minor: "923",
    sub: "9231",
    item: "警備業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "0000",
    item: "他に分類されない事業サービス業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "9291",
    item: "ディスプレイ業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "9292",
    item: "産業用設備洗浄業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "9293",
    item: "看板書き業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "9294",
    item: "コールセンター業",
  },
  {
    large: "R",
    middle: "92",
    minor: "929",
    sub: "9299",
    item: "他に分類されないその他の事業サービス業",
  },
  {
    large: "R",
    middle: "93",
    minor: "000",
    sub: "0000",
    item: "政治・経済・文化団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "931",
    sub: "0000",
    item: "経済団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "931",
    sub: "9311",
    item: "実業団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "931",
    sub: "9312",
    item: "同業団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "932",
    sub: "0000",
    item: "労働団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "932",
    sub: "9321",
    item: "労働団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "933",
    sub: "0000",
    item: "学術・文化団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "933",
    sub: "9331",
    item: "学術団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "933",
    sub: "9332",
    item: "文化団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "934",
    sub: "0000",
    item: "政治団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "934",
    sub: "9341",
    item: "政治団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "939",
    sub: "0000",
    item: "他に分類されない非営利的団体",
  },
  {
    large: "R",
    middle: "93",
    minor: "939",
    sub: "9399",
    item: "他に分類されない非営利的団体",
  },
  {
    large: "R",
    middle: "94",
    minor: "000",
    sub: "0000",
    item: "宗教",
  },
  {
    large: "R",
    middle: "94",
    minor: "941",
    sub: "0000",
    item: "神道系宗教",
  },
  {
    large: "R",
    middle: "94",
    minor: "941",
    sub: "9411",
    item: "神社，神道教会",
  },
  {
    large: "R",
    middle: "94",
    minor: "941",
    sub: "9412",
    item: "教派事務所",
  },
  {
    large: "R",
    middle: "94",
    minor: "942",
    sub: "0000",
    item: "仏教系宗教",
  },
  {
    large: "R",
    middle: "94",
    minor: "942",
    sub: "9421",
    item: "寺院，仏教教会",
  },
  {
    large: "R",
    middle: "94",
    minor: "942",
    sub: "9422",
    item: "宗派事務所",
  },
  {
    large: "R",
    middle: "94",
    minor: "943",
    sub: "0000",
    item: "キリスト教系宗教",
  },
  {
    large: "R",
    middle: "94",
    minor: "943",
    sub: "9431",
    item: "キリスト教教会，修道院",
  },
  {
    large: "R",
    middle: "94",
    minor: "943",
    sub: "9432",
    item: "教団事務所",
  },
  {
    large: "R",
    middle: "94",
    minor: "949",
    sub: "0000",
    item: "その他の宗教",
  },
  {
    large: "R",
    middle: "94",
    minor: "949",
    sub: "9491",
    item: "その他の宗教の教会",
  },
  {
    large: "R",
    middle: "94",
    minor: "949",
    sub: "9499",
    item: "その他の宗教の教団事務所",
  },
  {
    large: "R",
    middle: "95",
    minor: "000",
    sub: "0000",
    item: "その他のサービス業",
  },
  {
    large: "R",
    middle: "95",
    minor: "950",
    sub: "0000",
    item: "管理，補助的経済活動を行う事業所（95その他のサービス業）",
  },
  {
    large: "R",
    middle: "95",
    minor: "950",
    sub: "9501",
    item: "管理，補助的経済活動を行う事業所",
  },
  {
    large: "R",
    middle: "95",
    minor: "951",
    sub: "0000",
    item: "集会場",
  },
  {
    large: "R",
    middle: "95",
    minor: "951",
    sub: "9511",
    item: "集会場",
  },
  {
    large: "R",
    middle: "95",
    minor: "952",
    sub: "0000",
    item: "と畜場",
  },
  {
    large: "R",
    middle: "95",
    minor: "952",
    sub: "9521",
    item: "と畜場",
  },
  {
    large: "R",
    middle: "95",
    minor: "959",
    sub: "0000",
    item: "他に分類されないサービス業",
  },
  {
    large: "R",
    middle: "95",
    minor: "959",
    sub: "9599",
    item: "他に分類されないサービス業",
  },
  {
    large: "R",
    middle: "96",
    minor: "000",
    sub: "0000",
    item: "外国公務",
  },
  {
    large: "R",
    middle: "96",
    minor: "961",
    sub: "0000",
    item: "外国公館",
  },
  {
    large: "R",
    middle: "96",
    minor: "961",
    sub: "9611",
    item: "外国公館",
  },
  {
    large: "R",
    middle: "96",
    minor: "969",
    sub: "0000",
    item: "その他の外国公務",
  },
  {
    large: "R",
    middle: "96",
    minor: "969",
    sub: "9699",
    item: "その他の外国公務",
  },
  {
    large: "S",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "公務（他に分類されるものを除く）",
  },
  {
    large: "S",
    middle: "97",
    minor: "000",
    sub: "0000",
    item: "国家公務",
  },
  {
    large: "S",
    middle: "97",
    minor: "971",
    sub: "0000",
    item: "立法機関",
  },
  {
    large: "S",
    middle: "97",
    minor: "971",
    sub: "9711",
    item: "立法機関",
  },
  {
    large: "S",
    middle: "97",
    minor: "972",
    sub: "0000",
    item: "司法機関",
  },
  {
    large: "S",
    middle: "97",
    minor: "972",
    sub: "9721",
    item: "司法機関",
  },
  {
    large: "S",
    middle: "97",
    minor: "973",
    sub: "0000",
    item: "行政機関",
  },
  {
    large: "S",
    middle: "97",
    minor: "973",
    sub: "9731",
    item: "行政機関",
  },
  {
    large: "S",
    middle: "98",
    minor: "000",
    sub: "0000",
    item: "地方公務",
  },
  {
    large: "S",
    middle: "98",
    minor: "981",
    sub: "0000",
    item: "都道府県機関",
  },
  {
    large: "S",
    middle: "98",
    minor: "981",
    sub: "9811",
    item: "都道府県機関",
  },
  {
    large: "S",
    middle: "98",
    minor: "982",
    sub: "0000",
    item: "市町村機関",
  },
  {
    large: "S",
    middle: "98",
    minor: "982",
    sub: "9821",
    item: "市町村機関",
  },
  {
    large: "T",
    middle: "00",
    minor: "000",
    sub: "0000",
    item: "分類不能の産業",
  },
  {
    large: "T",
    middle: "99",
    minor: "000",
    sub: "0000",
    item: "分類不能の産業",
  },
  {
    large: "T",
    middle: "99",
    minor: "999",
    sub: "0000",
    item: "分類不能の産業",
  },
  {
    large: "T",
    middle: "99",
    minor: "999",
    sub: "9999",
    item: "分類不能の産業",
  },
];
