import { DocumentData } from "firebase/firestore";
import React from "react";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const CostBudgetControlBasisTotal = ({
  titleTotal,
  headers,
  bottomLine,
  sectionTotal,
  isWhite,
  noHeader,
  calcState,
  totalField,
  descriptions,
  unit,
}: {
  titleTotal: string;
  headers: DocumentData[];
  bottomLine?: boolean;
  crossheadTotal?: string;
  sectionTotal?: string;
  isCrosshead?: boolean;
  calcState: DocumentData;
  isWhite?: boolean;
  noHeader?: boolean;
  totalField: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const monthlyTotalData = singleOrCumsumToggle
    ? [
        calcState.current_monthly_total,
        calcState.previous_monthly_total,
        calcState.current_and_previous_difference_monthly_total,
        calcState.current_and_previous_difference_monthly_total_ratio,
        calcState.monthly_total,
        calcState.current_and_plan_difference_monthly_total,
        calcState.current_and_plan_difference_monthly_total_ratio,
      ]
    : [
        calcState.current_monthly_total_cumsum,
        calcState.previous_monthly_total_cumsum,
        calcState.current_and_previous_difference_monthly_total_cumsum,
        calcState.current_and_previous_difference_monthly_total_ratio_cumsum,
        calcState.plan_monthly_total_cumsum,
        calcState.current_and_plan_difference_monthly_total_cumsum,
        calcState.current_and_plan_difference_monthly_total_ratio_cumsum,
      ];

  const yearlyTotalData = [
    calcState.current_yearly_total,
    calcState.previous_yearly_total,
    calcState.current_and_previous_difference_yearly_total,
    calcState.current_and_previous_difference_yearly_total_ratio,
    calcState.plans_input_total,
    calcState.current_and_plan_difference_yearly_total,
    calcState.current_and_plan_difference_yearly_total_ratio,
  ];

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 w-80px min-w-[5rem] p-4px before:border-l   bg-white   ${
    bottomLine ? "before:border-b" : ""
  } ${noHeader && "before:border-t"} ${
    isWhite ? "before:bg-transparent" : "before:bg-cfffee2"
  } font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px w-140px max-w-[8.75rem] p-4px bg-white before:border-b ${
    noHeader && "before:border-t"
  }  ${isWhite ? "bg-transparent" : "before:bg-cfffee2"} ${
    sectionTotal && "before:border-l before:border-l-c3333334d"
  }`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-r before:border-r-c3333334d ${
    noHeader && "before:border-t"
  } ${isWhite ? "before:bg-transparent" : "before:bg-cfffee2"}`;

  const classCol4 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 ${
    noHeader && "before:border-t"
  }  text-right font-bold`;

  const classCol5 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 before:border-r  ${
    noHeader && "before:border-t"
  } ${
    isWhite ? "bg-transparent" : "before:bg-cfffee2"
  } text-left h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: sectionTotal,
      readOnly: true,
      className: `${classCol2} `,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} `,
    },
  ];

  headers?.map((header, headerIndex: number) => {
    monthlyTotalData.map((data: number[], dataIndex: number) => {
      gridRow.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null
            ? ShowTableData(data[headerIndex] / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data !== null
            ? data[headerIndex] / unit
            : "",
        readOnly: true,
        className: `${classCol4} before:border-b ${
          isWhite
            ? "bf-white"
            : !header.isTotal && isWhite
            ? "bg-transparent"
            : "before:bg-cfffee2"
        } ${dataIndex !== 0 && "before:border-l-c3333334d"}`,
      });
    });
  });

  // 合計
  singleOrCumsumToggle &&
    yearlyTotalData.map((data: number, dataIndex: number) => {
      gridRow.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? ShowTableData(data / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? data / unit
            : "",
        readOnly: true,
        className: `${classCol4} before:border-b ${
          isWhite ? "bf-white" : "before:bg-cfffee2"
        }  ${dataIndex !== 0 && "before:border-l-c3333334d"}`,
      });
    });

  gridRow.push({
    value: descriptions[totalField],
    readOnly: false,
    className: `${classCol5}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
