import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const CostProfitAndLossPlanGrandTotal = ({
  title,
  headers,
  row,
  states,
  descriptions,
  url,
  field,
  unit,
}: {
  title: string;
  headers: DocumentData[];
  row?: DocumentData;
  states: number[];
  descriptions: DocumentData;
  url: string;
  field: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-bold bg-white before:border-l before:border-b before:bg-c80b0f433  z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px before:border-b bg-white before:bg-c80b0f433`;

  const classCol3 = `${beforeWrapper} left-160px p-4px bg-white  before:border-b before:border-r before:border-r-c3333334d before:bg-c80b0f433 font-bold `;

  const classCol4 = `${borderSettings} p-4px         before:border-b before:border-l before:border-l-c3333334d bg-c80b0f433 text-right font-bold `;

  const classCol5 = `${borderSettings} p-4px before:border-b before:border-x before:border-l-c3333334d bg-c80b0f433 h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: (
        <div
          className={`absolute top-20px left-4px whitespace-nowrap top_absolute`}
        >
          {title}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}`,
    },
  ];

  headers?.map((header: DocumentData, tdIndex: number) => {
    gridRow.push({
      value: tdIndex > 1 && ShowTableData(states[tdIndex] / unit),
      expr: tdIndex > 1 && states[tdIndex] / unit,
      readOnly: tdIndex > 1 && row ? false : true,
      className: `${classCol4} before:border-l-c3333334d`,
      row: row,
      url: url,
      key: header.period,
    });
  });

  gridRow.push({
    value: descriptions[`${url}_${field}`],
    expr: descriptions[`${url}_${field}`],
    readOnly: false,
    className: classCol5,
    row: descriptions,
    url: url,
    field: `${url}_${field}`,
    key: "descriptions_calc",
    colSpan: 1,
  });
  grid.push(gridRow);

  return grid;
};
