import { DocumentData } from "firebase/firestore";
import { divide, sum } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PlCurrentResultMonthlyCostRatioForEstimate = ({
  headerTitle,
  title,
  rows,
  headers,
  field,
  byItemTotal,
  monthlyTotal,
  estimateCalc,
  totalField,
  resultsCount,
  descriptions,
}: {
  headerTitle?: string;
  title?: string;
  rows: DocumentData[];
  headers: DocumentData[];
  field: string;
  byItemTotal: number[];
  monthlyTotal: number[];
  estimateCalc: DocumentData;
  totalField: string;
  resultsCount: DocumentData;
  descriptions: DocumentData;
}) => {
  const byItemTotalData =
    resultsCount && resultsCount.length === 12
      ? byItemTotal
      : estimateCalc.monthly_total_by_item;

  const yearlyTotalData = sum(byItemTotalData) as number;

  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 2 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white  before:border-l align-top`;

    const classCol2 = `${beforeWrapper} ${
      title ? "left-80px" : "left-0"
    }  p-4px bg-white before:border-b before:border-x ${
      title && "before:border-l-c3333334d"
    } before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol3 = `${borderSettings} ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right `;

    const classCol4 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 w-120px  text-right  before:bg-cfffee2 ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    }`;

    const classCol5 = `${borderSettings}  before:border-x before:border-b before:border-l-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "bg-c2a4b770d"} h-50px description`;

    const gridRow: GridElement[] = [];

    gridRow.push({
      value: rowIndex === 0 && title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 2,
    });

    gridRow.push({
      value: row.item,
      readOnly: true,
      className: `${classCol2}`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header) => {
      // オブジェクトの中から該当する値を取得
      const resultsAndPlanTerm = row[field];

      type TypeOfResultObj = typeof resultsAndPlanTerm;
      const key: keyof TypeOfResultObj = header.period;

      gridRow.push({
        value:
          resultsAndPlanTerm === null ||
          resultsAndPlanTerm[key] === null ||
          byItemTotalData[rowIndex] === 0
            ? "0%"
            : `${showPercentage(
                divide(
                  resultsAndPlanTerm[key],
                  byItemTotalData[rowIndex]
                ) as number
              )}%`,
        expr:
          resultsAndPlanTerm === null ||
          resultsAndPlanTerm[key] === null ||
          byItemTotalData[rowIndex] === 0
            ? "0%"
            : `${showPercentage(
                divide(
                  resultsAndPlanTerm[key],
                  byItemTotalData[rowIndex]
                ) as number
              )}%`,
        readOnly: true,
        className: `${classCol3} ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"}`,
        row: row,
        url: `${field}_cost_ratio`,
        key: key as string,
      });
    });

    gridRow.push({
      value: `${showPercentage(
        divide(byItemTotalData[rowIndex], byItemTotalData[rowIndex]) as number
      )}%`,
      expr: showPercentage(
        divide(byItemTotalData[rowIndex], byItemTotalData[rowIndex]) as number
      ),
      readOnly: true,
      className: `${classCol4}`,
    });

    gridRow.push({
      value: descriptions[`${totalField}_${row?.field as string}${rowIndex}`],
      readOnly: false,
      className: `${classCol5}`,
      field: `${totalField}_${row?.field as string}${rowIndex}`,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow);
  });

  const gridRow2: GridElement[] = [];

  gridRow2.push(
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} top-0 left-0 pt-20px pl-4px bg-white before:border-l before:border-b`,
      colSpan: 2,
    },
    {
      value: "売上高合計",
      readOnly: true,
      className: `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-l-c3333334d 
         before:bg-c7fb28d33 font-bold text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-220px p-4px bg-white  before:border-r before:border-r-c3333334d before:border-b  before:bg-c7fb28d33`,
    }
  );

  headers?.map((_, tdIndex: number) => {
    gridRow2.push({
      value: `${showPercentage(
        (monthlyTotal[tdIndex] as number) / yearlyTotalData
      )}%`,
      expr: showPercentage((monthlyTotal[tdIndex] as number) / yearlyTotalData),
      readOnly: true,
      className: `${borderSettings}  text-right font-bold bg-c7fb28d33`,
    });
  });

  gridRow2.push({
    value: `${showPercentage(
      divide(yearlyTotalData, yearlyTotalData) as number
    )}%`,
    expr: showPercentage(divide(yearlyTotalData, yearlyTotalData) as number),
    readOnly: true,
    className: `relative p-4px before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10   text-right font-bold before:bg-cfffee2`,
  });

  gridRow2.push({
    value: descriptions[totalField],
    expr: descriptions[totalField],
    readOnly: false,
    className: `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow2);

  return grid;
};
