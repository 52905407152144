import { divide, sum } from "mathjs";

export const totalRatioCalcForPlansInputMonthly = (state: number[]) => {
  const ratioResult: number[] = [];

  state.map((data: number | number[]) => {
    if (sum(state) !== 0) {
      const ratio = divide(Number(data), Number(sum(state)));
      ratioResult.push(ratio);
    } else {
      ratioResult.push(0);
    }
  });

  return ratioResult;
};
