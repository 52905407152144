import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  BsResultsAndPlansBody,
  BsResultsAndPlansTotal,
  CostResultsInputRatioComponent,
  CostResultsInputRatioElement,
  ResultsAndPlanHeader,
  TableBlank,
} from "../../../lib/datasheets/resultsAndPlans";
import { ResultsInputFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: ResultsInputFieldsCalc;
}

const CostResultsAndPlansTable: React.FC<Props> = ({ url, calcState }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = BsResultsAndPlansTotal({
    titleTotal: "材料費",
    bottomLine: false,
    headers: resultsAndPlansDataState.headers[url],
    total: calcState.material_fee_total,
    totalField: `${url}_material_fee_total`,
    descriptions: resultsAndPlansDataState.descriptions,
    unit: unit,
  });
  grid.push(
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.material_fee,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansTotal({
      titleTotal: "労務費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.labor_costs_total,
      totalField: `${url}_labor_costs_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.labor_costs,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansTotal({
      titleTotal: "外注加工費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.external_injection_processing_fee_total,
      totalField: `${url}_external_injection_processing_fee_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansTotal({
      titleTotal: "経費",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.expenses_total,
      totalField: `${url}_expenses_total`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.expenses,
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansTotal({
      titleTotal: "当期製造費用",
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.gross_manufacturing_cost_for_the_period,
      totalField: `${url}_gross_manufacturing_cost_for_the_period`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      bottomLine: false,
      headers: resultsAndPlansDataState.headers[url],
      url: url,
      unit: unit,
    }),
    ...BsResultsAndPlansTotal({
      titleTotal: "当期製品製造原価費用",
      bottomLine: true,
      headers: resultsAndPlansDataState.headers[url],
      total: calcState.cost_of_products_manufactured,
      totalField: `${url}_cost_of_products_manufactured`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...TableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...CostResultsInputRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          value: calcState.gross_manufacturing_cost_for_the_period_ratio,
          field: `${url}_gross_manufacturing_cost_for_the_period_ratio`,
        },
        {
          title: "材料費",
          value: calcState.material_fee_total_ratio,
          field: `${url}_material_fee_total_ratio`,
        },
        {
          title: "労務費",
          value: calcState.labor_costs_total_ratio,
          field: `${url}_labor_costs_total_ratio`,
        },
        {
          title: "外注加工費",
          value: calcState.external_injection_processing_fee_total_ratio,
          field: `${url}_external_injection_processing_fee_total_ratio`,
        },
        {
          title: "経費",
          value: calcState.expenses_total_ratio,
          field: `${url}_expenses_total_ratio`,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...TableBlank({
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...CostResultsInputRatioElement({
      headerTitle: "要素別原価率",
      rows: [
        {
          title: "材料費",
          value: calcState.material_fee_total,
          field: `${url}_net_sales_material_fee_total_element`,
        },
        {
          title: "(売上高材料費比率)",
          value: calcState.net_sales_material_fee_total_ratio,
          field: `${url}_net_sales_material_fee_total_ratio`,
        },
        {
          title: "労務費",
          value: calcState.labor_costs_total,
          field: `${url}_net_sales_labor_costs_total_element`,
        },
        {
          title: "(売上高労務費比率)",
          value: calcState.net_sales_labor_costs_total_ratio,
          field: `${url}_net_sales_labor_costs_total_ratio`,
        },
        {
          title: "外注加工費",
          value: calcState.external_injection_processing_fee_total,
          field: `${url}_net_sales_external_injection_processing_fee_total_element`,
        },
        {
          title: "(売上高外注費比率)",
          value:
            calcState.net_sales_external_injection_processing_fee_total_ratio,
          field: `${url}_net_sales_external_injection_processing_fee_total_ratio`,
        },
        {
          title: "経費",
          value: calcState.expenses_total,
          field: `${url}_net_sales_expenses_total_element`,
        },
        {
          title: "(売上高経費比率)",
          value: calcState.net_sales_expenses_total_ratio,
          field: `${url}_net_sales_expenses_total_ratio`,
        },
        {
          title: "減価償却費",
          value: calcState.depreciation_cost_of_sales_t,
          field: `${url}_depreciation_cost_of_sales_element`,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
      descriptions: resultsAndPlansDataState.descriptions,
      unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <ResultsAndPlanHeader
            headers={resultsAndPlansDataState.headers[url]}
            title="■原価"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostResultsAndPlansTable);
