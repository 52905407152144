import { dotDivide, map } from "mathjs";

export const comparisonsValidData = ({
  numerator,
  denominator,
}: {
  numerator: number[];
  denominator: number[];
}) => {
  const divineValue = dotDivide(numerator, denominator);

  const newArray = map(divineValue as number[], function (value) {
    if (isNaN(value) === true || value === 0) {
      return 1;
    } else {
      return value;
    }
  });

  return newArray;
};
