import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../../hooks/initialData";
import { HandleCellChange } from "../../../../lib/datasheets";
import {
  BudgetControlBasisHeader,
  BudgetControlBasisTableBlank,
  CostBudgetControlBasisBody,
  CostBudgetControlBasisRatioComponent,
  CostBudgetControlBasisRatioElement,
  CostBudgetControlBasisTotal,
} from "../../../../lib/datasheets/budgetControlBasis";
import { PlansInputMonthlyFieldsCalc } from "../../../../types";
import { GridElement } from "../../../../types/gridElement";

interface Props {
  url: string;
  dataUrl: string;
  calcState: PlansInputMonthlyFieldsCalc;
}

const CostBudgetControlBasisTable: React.FC<Props> = ({
  url,
  calcState,
  dataUrl,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = CostBudgetControlBasisTotal({
    titleTotal: "材料費",
    headers: resultsAndPlansDataState.headers[dataUrl],
    calcState: calcState.material_fee_total,
    totalField: `${url}_material_fee_total`,
    bottomLine: false,
    descriptions: resultsAndPlansDataState.descriptions,
    unit: unit,
  });

  grid.push(
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.material_fee,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.material_fee_total,
      bottomLine: true,
      url: url,
      unit: unit,
    }),
    ...CostBudgetControlBasisTotal({
      titleTotal: "労務費",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.labor_costs_total,
      totalField: `${url}_labor_costs_total`,
      bottomLine: false,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.labor_costs,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.labor_costs_total,
      bottomLine: true,
      url: url,
      unit: unit,
    }),
    ...CostBudgetControlBasisTotal({
      titleTotal: "外注加工費",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.external_injection_processing_fee_total,
      totalField: `${url}_external_injection_processing_fee_total`,
      bottomLine: false,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.external_injection_processing_fee_total,
      bottomLine: true,
      url: url,
      unit: unit,
    }),
    ...CostBudgetControlBasisTotal({
      titleTotal: "経費",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.expenses_total,
      totalField: `${url}_expenses_total`,
      bottomLine: false,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.expenses,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.expenses_total,
      bottomLine: true,
      url: url,
      unit: unit,
    }),
    ...CostBudgetControlBasisTotal({
      titleTotal: "当期製造費用",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.gross_manufacturing_cost_for_the_period,
      totalField: `${url}_gross_manufacturing_cost_for_the_period`,
      bottomLine: false,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState:
        calcState.inventory_of_work_in_process_at_the_beginning_of_the_period_total,
      bottomLine: false,
      url: url,
      unit: unit,
    }),
    ...CostBudgetControlBasisBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState:
        calcState.inventory_of_work_in_process_at_the_end_of_the_period_total,
      bottomLine: false,
      url: url,
      isGrey: true,
      unit: unit,
    }),
    ...CostBudgetControlBasisTotal({
      titleTotal: "当期製品製造原価",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.cost_of_products_manufactured,
      totalField: `${url}_cost_of_products_manufactured`,
      bottomLine: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BudgetControlBasisTableBlank({
      headers: resultsAndPlansDataState.headers[dataUrl],
      isCost: true,
    }),
    ...CostBudgetControlBasisRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          calcState:
            calcState.gross_manufacturing_cost_for_the_period_plans_cost_composition_ratio,
        },
        {
          title: "材料費",
          calcState: calcState.material_fee_cost_composition_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_cost_composition_ratio,
        },
        {
          title: "外注加工費",
          calcState:
            calcState.external_injection_processing_fee_cost_composition_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_cost_composition_ratio,
        },
      ],
      headers: resultsAndPlansDataState.headers[dataUrl],
    }),
    ...BudgetControlBasisTableBlank({
      headers: resultsAndPlansDataState.headers[dataUrl],
      isCost: true,
    }),
    ...CostBudgetControlBasisRatioElement({
      headerTitle: "要素別原価率",
      rows: [
        {
          title: "材料費",
          calcState: calcState.material_fee_total,
        },
        {
          title: "(売上高材料費比率)",
          calcState: calcState.net_sales_material_fee_total_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_total,
        },
        {
          title: "(売上高労務費比率)",
          calcState: calcState.net_sales_labor_costs_total_ratio,
        },
        {
          title: "外注加工費",
          calcState: calcState.external_injection_processing_fee_total,
        },
        {
          title: "(売上高外注費比率)",
          calcState:
            calcState.net_sales_external_injection_processing_fee_total_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_total,
        },
        {
          title: "(売上高経費比率)",
          calcState: calcState.net_sales_expenses_total_ratio,
        },
        {
          title: "減価償却費",
          calcState: calcState.net_sales_depreciation_cost_of_sales,
        },
      ],
      headers: resultsAndPlansDataState.headers[dataUrl],
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <BudgetControlBasisHeader
            headers={resultsAndPlansDataState.headers[dataUrl]}
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostBudgetControlBasisTable);
