import { AxiosResponse } from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { ConfirmTr, DarkButton, LightButton } from "../../components/atoms";
import { pageConstant } from "../../constants/pageConstants";
import { industryClassification } from "../../data";
import { getStoredServerClientToken } from "../../sessionStorage/sessionStorage";
import { clientsTokenStore, signUpClientStore } from "../../store";

const ConfirmSignUpClient = () => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useRecoilState(clientsTokenStore);

  const signUpState = useRecoilValue(signUpClientStore);

  const token = getStoredServerClientToken();

  const largeClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === "00" &&
      data.minor === "000" &&
      data.sub === "0000"
  );

  const middleClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === signUpState.client_industry_step_2 &&
      data.minor === "000" &&
      data.sub === "0000"
  );

  const minorClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === signUpState.client_industry_step_2 &&
      data.minor === signUpState.client_industry_step_3 &&
      data.sub === "0000"
  );

  const subClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === signUpState.client_industry_step_2 &&
      data.minor === signUpState.client_industry_step_3 &&
      data.sub === signUpState.client_industry_step_4
  );

  const handleSubmit = async () => {
    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: apiUrl.CLIENTS,
        method: "POST",
        data: {
          client_first_name: signUpState.client_first_name,
          client_last_name: signUpState.client_last_name,
          client_first_kana_name: signUpState.client_first_kana_name,
          client_last_kana_name: signUpState.client_last_kana_name,
          client_company: signUpState.client_company,
          client_postal_code: signUpState.client_postal_code,
          client_province: signUpState.client_province,
          client_address: signUpState.client_address,
          client_address_detail: signUpState.client_address_detail,
          client_position: signUpState.client_position,
          client_industry_step_1: signUpState.client_industry_step_1,
          client_industry_step_2: signUpState.client_industry_step_2,
          client_industry_step_3: signUpState.client_industry_step_3,
          client_industry_step_4: signUpState.client_industry_step_4,
          client_phone: `${signUpState.client_phone1}${signUpState.client_phone2}${signUpState.client_phone3}`,
          password: signUpState.password,
        },
        headers: {
          accessToken: accessToken,
        },
      });
      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      toast.success("登録が完了しました");
      setTimeout(() => {
        navigate(`/${pageConstant.LOGIN}`);
      }, 3000);
    } catch (error) {
      toast.error("登録できませんでした");
    }
  };

  useEffect(() => {
    // signUpState.client_company.length === 0 &&
    //   navigate(pageConstant.SIGNUP_CLIENT);

    signInWithCustomToken(getAuth(), token)
      .then(async (result) => {
        const token = await result.user.getIdToken();

        setAccessToken(token);
      })
      .catch((error) => {
        console.log(error);
      });

    // ブラウザから離れようとすると、アラート表示
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-[840px] m-auto">
      <form>
        <div>
          <h2 className="font-bold text-center pt-10px pb-25px">
            内容確認後、登録ボタンを押してください。
          </h2>
          <table className="w-full my-20px">
            <ConfirmTr title={"氏名"}>
              {signUpState.client_last_name} {signUpState.client_first_name}
            </ConfirmTr>
            <ConfirmTr title={"氏名（カナ）"}>
              {signUpState.client_last_kana_name}{" "}
              {signUpState.client_first_kana_name}
            </ConfirmTr>
            <ConfirmTr title={"会社名・団体名"}>
              {signUpState.client_company}
            </ConfirmTr>
            <ConfirmTr title={"郵便番号"}>
              {signUpState.client_postal_code}
            </ConfirmTr>
            <ConfirmTr title={"都道府県"}>
              {signUpState.client_province}
            </ConfirmTr>
            <ConfirmTr title={"地名・番地"}>
              {signUpState.client_address}
            </ConfirmTr>
            <ConfirmTr title={"建物名・階・部屋番号"}>
              {signUpState.client_address_detail}
            </ConfirmTr>
            <ConfirmTr title={"役職"}>{signUpState.client_position}</ConfirmTr>
            <ConfirmTr title={"業種（大分類）"}>
              {largeClassification[0].item}
            </ConfirmTr>
            <ConfirmTr title={"業種（中分類）"}>
              {middleClassification[0].item}
            </ConfirmTr>
            <ConfirmTr title={"業種（小分類）"}>
              {minorClassification.length !== 0 && minorClassification[0].item}
            </ConfirmTr>
            <ConfirmTr title={"業種（細分類）"}>
              {subClassification.length !== 0 && subClassification[0].item}
            </ConfirmTr>
            <ConfirmTr title={"携帯電話番号"}>
              {signUpState.client_phone1}-{signUpState.client_phone2}-
              {signUpState.client_phone3}
            </ConfirmTr>
          </table>
        </div>
      </form>
      <div className="flex gap-20px w-[580px] mx-auto py-30px">
        <LightButton onClick={() => navigate(-1)}>前に戻る</LightButton>
        <DarkButton onClick={() => handleSubmit()}>
          上記の内容で登録する
        </DarkButton>
      </div>
    </div>
  );
};

export default memo(ConfirmSignUpClient);
