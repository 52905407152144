import { addDoc, collection, DocumentData } from "firebase/firestore";
import React, { createRef, Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { getStoredClientUid } from "../../../sessionStorage/sessionStorage";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CashFlowTableInputBody = ({
  title,
  subTitle,
  itemTitles,
  headers,
  rows,
  byItem,
  byItemTotal,
  dataUrl,
  field,
  key,
  descriptions,
  setDeleteIdState,
  setDeleteIsOpen,
  unit,
}: {
  title: string;
  subTitle: string;
  itemTitles?: string[];
  headers: DocumentData[];
  rows: DocumentData[];
  byItem: number[][];
  byItemTotal: number[];
  url: string;
  dataUrl: string;
  field: string;
  key: string;
  descriptions: DocumentData;
  setDeleteIdState: Dispatch<SetStateAction<string>>;
  setDeleteIsOpen: Dispatch<SetStateAction<boolean>>;
  unit: number;
}) => {
  const firestore = useFirestore();
  const inputRef = createRef<HTMLInputElement>();

  const uid = getStoredClientUid();

  const termObj: object = new Object();

  headers?.map((header: DocumentData) => {
    const term = header.period as string;
    Object.assign(termObj, { [term]: null });
  });

  const handleClick = async () => {
    const tablesRef = collection(firestore, "accounts", uid, "tables");

    await addDoc(tablesRef, {
      field: field,
      item: inputRef.current?.value,
      model_number: "",
      order: rows.length + 100,
      price: 0,
      [dataUrl]: termObj,
      descriptions: {},
    }).catch((error) => {
      toast.error("追加できませんでした");
      throw new Error(error.message);
    });
  };

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 p-4px bg-white font-bold before:border-l`;

  const classCol2 = `${beforeWrapper} left-120px p-4px bg-white font-bold before:border-l before:border-l-c3333334d`;

  const classCol3 = `${beforeWrapper} left-240px p-4px bg-white before:border-b`;

  const classCol4 = `${beforeWrapper} left-380px p-4px bg-white  before:border-l before:border-l-c3333334d before:border-b`;

  const classCol5 = `${beforeWrapper} left-425px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b`;

  const classCol6 = `${borderSettings} text-right font-bold bg-c2a4b770d`;

  const classCol7 = `${borderSettings} text-right font-bold`;

  const classCol8 = `relative p-4px bg-cfffee2 before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0  text-right font-bold focus:text-left`;

  const classCol9 = `description ${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px`;

  byItem?.map((items, itemsIndex) => {
    const gridRow: GridElement[] = [
      {
        value: itemsIndex === 0 && title,
        readOnly: true,
        className: `${classCol1} `,
      },
      {
        value: itemsIndex === 0 && subTitle,
        readOnly: true,
        className: `${classCol2}`,
      },
      {
        value: "",
        component: (
          <div className="flex justify-between items-center">
            <div>
              {itemTitles
                ? itemTitles[itemsIndex]
                : key === "selling_and_administrative_body" && itemsIndex === 0
                ? "人件費合計"
                : rows[itemsIndex].item}
            </div>
            <button
              className="w-40px h-30px text-14px text-white font-bold bg-red rounded mr-5px hover:opacity-80"
              onClick={() => {
                setDeleteIsOpen(true);
                setDeleteIdState(rows[itemsIndex].NO_ID_FIELD);
              }}
            >
              削除
            </button>
          </div>
        ),
        forceComponent: true,
        readOnly: true,
        className: `${classCol3} before:border-l before:border-l-c3333334d  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d datasheet-input`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol4}  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol5}  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
    ];

    // 前年度実績
    gridRow.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    items?.map((item: number, index: number) => {
      gridRow.push({
        value: ShowTableDataTriangle(item / unit),
        expr: item / unit,
        readOnly: false,
        className: `${classCol7} before:border-b  ${
          itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
        row: rows[itemsIndex],
        url: field,
        key: headers[index].period,
      });
    });

    gridRow.push({
      value: ShowTableDataTriangle(byItemTotal[itemsIndex] / unit),
      expr: byItemTotal[itemsIndex] / unit,
      readOnly: true,
      className: `${classCol8} before:border-b  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow.push({
      value: descriptions[
        itemTitles ? `${key}${itemsIndex}` : `${key}_${rows[itemsIndex].item}`
      ]
        ? descriptions[
            itemTitles
              ? `${key}${itemsIndex}`
              : `${key}_${rows[itemsIndex].item}`
          ]
        : "",
      expr: descriptions[
        itemTitles ? `${key}${itemsIndex}` : `${key}_${rows[itemsIndex].item}`
      ]
        ? descriptions[
            itemTitles
              ? `${key}${itemsIndex}`
              : `${key}_${rows[itemsIndex].item}`
          ]
        : "",
      readOnly: false,
      className: `${classCol9}  ${
        itemsIndex % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
      field: itemTitles
        ? `${key}${itemsIndex}`
        : `${key}_${rows[itemsIndex].item}`,
      key: "descriptions_calc",
      row: descriptions,
    });

    grid.push(gridRow);
  });

  // 新規追加用
  const gridRow2: GridElement[] = [
    {
      value: byItem && byItem.length === 0 && title,
      readOnly: true,
      className: `${classCol1} `,
    },
    {
      value: byItem && byItem.length === 0 && subTitle,
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: "",
      component: (
        <input
          className={
            "table_per_w shadow appearance-none before:border before:border-c3333334d rounded py-2px px-3px leading-tight focus:border-c1e88e5 focus:outline-none focus:shadow-outline text-right"
          }
          ref={inputRef}
          type="text"
        />
      ),
      forceComponent: true,
      readOnly: false,
      className: `${classCol3} datasheet-input before:border-l before:border-l-c3333334d  before:border-b-c3333334d`,
    },
    {
      value: "",
      component: (
        <button
          className="w-36px h-30px text-14px text-white font-bold bg-c427ed1 rounded hover:opacity-80"
          onClick={handleClick}
        >
          追加
        </button>
      ),
      forceComponent: true,
      readOnly: true,
      className: `${classCol4} datasheet-input before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5}   before:border-b-c3333334d`,
    },
  ];

  // 前年度実績
  gridRow2.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol6} before:border-b  before:border-b-c3333334d`,
  });

  gridRow2.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol6} before:border-b   before:border-b-c3333334d`,
  });

  headers?.map(() => {
    gridRow2.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol7} before:border-b   before:border-b-c3333334d`,
    });
  });

  gridRow2.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol8} before:border-b   before:border-b-c3333334d`,
  });

  gridRow2.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol9}  before:border-b-c3333334d`,
    field: "",
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow2);

  return grid;
};
