import React from "react";
import { Link } from "react-router-dom";

const RegisterHeader = () => {
  return (
    <div className="flex justify-start px-40px py-30px">
      <Link className="flex items-center space-x-2 text-2xl" to={"/"}>
        <span>
          <img
            alt="Cloud Planner"
            className="w-200px"
            src={`${window.location.origin}/img/CP_logo_bl.svg`}
          />
        </span>
      </Link>
    </div>
  );
};

export default RegisterHeader;
