import { DocumentData } from "firebase/firestore";
import React from "react";
import { bottomHeaderCss, topHeaderCss } from "../../../utils";

interface Props {
  headers: DocumentData[];
  previousHeaders: DocumentData[];
  resultsCount: DocumentData;
}

export const CashFlowTableHeader: React.FC<Props> = ({
  headers,
  previousHeaders,
  resultsCount,
}) => {
  return (
    <thead className="sticky top-0 px-6px py-3px bg-white pt-120px z-50">
      <tr>
        <th
          className={`${topHeaderCss} sticky left-0  bg-ce7effd z-50 before:border-t before:border-x before:border-r-c3333334d align-bottom`}
          colSpan={4}
          rowSpan={2}
        >
          <div className="text-center text-18px font-bold">
            {headers && headers[0].nengou}.
            {headers && headers[headers.length - 1].period.substring(5, 7)}期
          </div>
        </th>
        <th
          className={`sticky left-425px min-w-[7.5rem] w-120px bg-white
             ${topHeaderCss} before:border-x before:border-r-c3333334d  z-40`}
        ></th>
        <th
          className={`min-w-[7.5rem] w-120px ${topHeaderCss} bg-c2a4b770d before:border-t before:border-l`}
        >
          <div className="flex justify-center mr-40px">
            {previousHeaders &&
              previousHeaders[previousHeaders.length - 2].nengou}
          </div>
        </th>
        <th
          className={`min-w-[7.5rem] w-120px ${topHeaderCss} bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d`}
        >
          <div className="flex justify-center mr-40px">
            {previousHeaders &&
              previousHeaders[previousHeaders.length - 1].nengou}
          </div>
        </th>
        {headers?.map((header: DocumentData, index: number) => (
          <th
            className={`min-w-[7.5rem] w-120px ${topHeaderCss} bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d`}
            key={index}
          >
            <div className="flex justify-center mr-40px">{header.nengou}</div>
          </th>
        ))}
        <th
          className={`min-w-[7.5rem] w-120px ${topHeaderCss} bg-cfffee2 before:border-t before:border-b-2 before:border-l`}
          rowSpan={3}
        >
          <div className="text-center">合計</div>
        </th>
        <th
          className={`min-w-[37.5rem] w-600px align-middle bg-white ${topHeaderCss} before:border before:border-b-2`}
          rowSpan={3}
        >
          <div className="text-center">備考</div>
        </th>
      </tr>
      <tr>
        <th
          className={`sticky left-425px min-w-[7.5rem] w-120px
              ${topHeaderCss} bg-white before:border-x before:border-b before:border-r-c3333334d text-12px z-50`}
        >
          <div className="text-center">【1+消費税率】</div>
        </th>
        <th
          className={`min-w-[7.5rem] w-120px
              ${topHeaderCss} bg-c2a4b770d before:border-l before:border-b before:border-b-c3333334d`}
        >
          <div className="flex justify-center">
            {`${
              previousHeaders &&
              previousHeaders[previousHeaders.length - 2].period.substring(1, 5)
            }年${
              previousHeaders &&
              previousHeaders[previousHeaders.length - 2].period.substring(5, 7)
            }月`}
          </div>
        </th>
        <th
          className={`min-w-[7.5rem] w-120px
              ${topHeaderCss} bg-c2a4b770d before:border-l before:border-l-c3333334d before:border-b before:border-b-c3333334d`}
        >
          <div className="flex justify-center">
            {`${
              previousHeaders &&
              previousHeaders[previousHeaders.length - 1].period.substring(1, 5)
            }年${
              previousHeaders &&
              previousHeaders[previousHeaders.length - 1].period.substring(5, 7)
            }月`}
          </div>
        </th>
        {headers?.map((header: DocumentData, index: number) => (
          <th
            className={`min-w-[7.5rem] w-120px
              ${topHeaderCss} bg-c2a4b770d before:border-l before:border-b before:border-b-c3333334d before:border-l-c3333334d`}
            key={index}
          >
            <div className="flex justify-center">
              {`${header.period.substring(1, 5)}年${header.period.substring(
                5,
                7
              )}月`}
            </div>
          </th>
        ))}
      </tr>
      <tr>
        <th
          className={`${bottomHeaderCss} sticky left-0 min-w-[7.5rem] w-120px bg-ce7effd z-50 before:border-l`}
        ></th>
        <th
          className={`${bottomHeaderCss} sticky left-120px min-w-[7.5rem] w-120px bg-ce7effd z-50 `}
        ></th>
        <th
          className={`${bottomHeaderCss} sticky left-240px min-w-[8.75rem] w-140px bg-ce7effd z-50 `}
        ></th>
        <th
          className={`${bottomHeaderCss} sticky left-380px min-w-[2.8175rem] w-45px bg-ce7effd z-50 before:border-r`}
        ></th>
        <th
          className={`${bottomHeaderCss} sticky left-425px  min-w-[10rem] w-120px bg-c2a4b771a z-40 before:border-x before:bg-white before:-z-10 h-[44px]`}
        ></th>
        <th
          className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b771a before:border-l`}
        >
          <div className="text-center">前年度実績</div>
        </th>
        <th
          className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          <div className="text-center">前年度実績</div>
        </th>
        {headers?.map((header: DocumentData, index: number) => (
          <React.Fragment key={index}>
            <th
              className={`${bottomHeaderCss} min-w-[7.5rem] w-120px bg-c2a4b771a before:border-l before:border-l-c3333334d`}
            >
              <div className="text-center">
                {resultsCount[index] ? "実績" : "見込み"}
              </div>
            </th>
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};
