import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const StatementOfCashFlowsBody = ({
  headers,
  rows,
  descriptions,
  url,
  dataUrl,
  unit,
}: {
  headers: DocumentData[];
  rows: {
    title: string | null;
    state: number[] | DocumentData | null;
    field: string;
    isSpace: boolean;
    isFirstColor?: boolean;
    isSecondColor?: boolean;
    isGrandTotal?: boolean;
  }[];
  descriptions: DocumentData;
  url: string;
  dataUrl: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];
  {
    rows?.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white top-0 font-bold align-text-top mt-12px ${
        row.title !== null && "before:border-l before:border-b"
      } before:border-r before:border-r-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } before:h-full  ${
        row.isFirstColor
          ? "before:bg-c80b0f433 text-right"
          : row.isSecondColor
          ? "before:bg-c7fb28d33 text-right"
          : row.isGrandTotal
          ? "bg_table_total text-right"
          : rowIndex % 2 !== 0
          ? "before:bg-c2a4b770d"
          : ""
      }      `;

      const classCol2 = `${borderSettings}  ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } text-right before:border-l-c3333334d   ${
        row.isFirstColor
          ? "before:bg-c80b0f433"
          : row.isSecondColor
          ? "before:bg-c7fb28d33"
          : row.isGrandTotal
          ? "bg_table_total"
          : rowIndex % 2 !== 0
          ? "before:bg-c2a4b770d"
          : ""
      }  `;

      const classCol3 = `${borderSettings} before:border-r ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } text-left ${
        row.isFirstColor
          ? "before:bg-c80b0f433"
          : row.isSecondColor
          ? "before:bg-c7fb28d33"
          : row.isGrandTotal
          ? "bg_table_total "
          : rowIndex % 2 !== 0
          ? "before:bg-c2a4b770d"
          : ""
      }   h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: (
          <div className={`${row.isSpace && "table_indent"}`}>{row.title}</div>
        ),
        expr: row.title,
        readOnly: true,
        className: classCol1,
        row: row,
        url: url,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);

      headers?.map((header: DocumentData, tdIndex: number) => {
        tdIndex !== 0 &&
          gridRow.push({
            value:
              row.state === null ||
              row.state === undefined ||
              (row.state.results_input &&
                !row.state.results_input[header.period])
                ? ""
                : row.state.results_input
                ? ShowTableData(row.state.results_input[header.period] / unit)
                : ShowTableData(row.state[tdIndex] / unit),
            expr:
              row.state === null ||
              row.state === undefined ||
              (row.state.results_input &&
                !row.state.results_input[header.period])
                ? ""
                : row.state.results_input / unit
                ? row.state.results_input[header.period]
                : row.state[tdIndex] / unit,
            readOnly:
              row.state === null || row.state === undefined
                ? true
                : row.state.field
                ? false
                : true,
            className: classCol2,
            row: row.state,
            url: dataUrl,
            key: header.period,
            colSpan: 1,
          });
      });

      gridRow.push({
        value: descriptions[`${url}_${row.field}`],
        expr: descriptions[`${url}_${row.field}`],
        readOnly: false,
        className: classCol3,
        field: `${url}_${row.field}`,
        url: url,
        key: "descriptions_calc",
        row: descriptions,
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
