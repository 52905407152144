import { DocumentData } from "firebase/firestore";
import {
  add,
  dotDivide,
  map,
  round,
  subtract,
  sum,
  isNaN,
  dotMultiply,
  divide,
} from "mathjs";
import { useCallback, useMemo } from "react";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import {
  arrayRatioCalc,
  calcByItemForPlansResultMonthly,
  ratioCalc,
  totalCalc,
  totalCalcByItem,
} from "../../utils/calc";

export const usePlansInputInitialData = () => {
  // const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    cash_equivalent,
    accounts_receivable,
    current_assets,
    shed_unloading_assets,
    quotidian_gold,
    other_current_assets,
    tangible_fixed_assets,
    intangible_fixed_assets,
    investments_and_other_assets,
    deferred_asset,
    payables,
    short_term_borrowings,
    unpaids,
    current_liabilities,
    fixed_liabilities,
    capital,
    capital_surplus,
    retained_earnings,
    other_retained_earnings,
    treasury_stock,
    employees,
    depreciation_cost_data,
    raw_material_inventories_at_the_end_of_the_period,
    end_inventories_data,
    depreciation_cost_of_cost_of_sales,
    short_term_borrowings_data,
    fixed_liabilities_data,
    corporate_bond_data,
    material_fee_fluctuate_data,
    labor_costs_fluctuate_data,
    external_injection_processing_fee_fluctuate_data,
    expenses_fluctuate_data,
    beginning_inventories_data,
    cost_of_sales_except_beginning_and_end_data,
    personal_cost_fluctuate_data,
    selling_and_administrative_fluctuate_data,
    labor_costs_fixed_data,
    expenses_fixed_data,
    personal_cost_fixed_data,
    selling_and_administrative_fixed_data,
    interest_expenses,
    non_cash_out,
  } = useSubjectItemsInitialData();

  const field = pageConstant.PLANS_INPUT;
  const resultsInputField = pageConstant.RESULTS_INPUT;

  // ゼロの配列
  const zeroArray = useMemo(() => {
    return headers[field].map(() => 0);
  }, [headers, field]);

  const totalCalcByItemMemo = useCallback(
    (state: DocumentData[]) =>
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers, field]
  );

  const totalCalcMemo = useCallback(
    (state: DocumentData[]) =>
      totalCalc({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers, field]
  );

  // try {
  // 項目別売上高合計
  const netSalesTotalByItem = totalCalcByItemMemo(net_sales);

  // 項目別売上原価合計
  const costOfSalesTotalByItem = totalCalcByItemMemo(cost_of_sales);

  // 項目別人件費合計
  const sellingAndAdministrativeTotalByItem = totalCalcByItemMemo(
    selling_and_administrative
  );

  // 項目別販売費及び一般管理費合計
  const personalCostTotalByItem = totalCalcByItemMemo(personal_cost);

  // 項目別営業外収益合計
  const nonOperatingIncomeTotalByItem =
    totalCalcByItemMemo(non_operating_income);

  // 項目別営業外費用合計
  const nonOperatingExpensesTotalByItem = totalCalcByItemMemo(
    non_operating_expenses
  );

  // 項目別特別利益合計
  const specialBenefitsTotalByItem = totalCalcByItemMemo(special_benefits);

  // 項目別特別損失合計
  const specialLossesTotalByItem = totalCalcByItemMemo(special_losses);

  // 項目別法人税、住民税及び事業税合計
  const corporateInhabitantAndEnterpriseTaxesTotalByItem = totalCalcByItemMemo(
    special_corporate_inhabitant_and_enterprise_taxeslosses
  );

  // 項目別材料費合計
  const materialFeeTotalByItem = totalCalcByItemMemo(material_fee);

  // 項目別労務費合計
  const laborCostsTotalByItem = totalCalcByItemMemo(labor_costs);

  // 項目別外注加工費合計
  const externalInjectionProcessingFeeTotalByItem = totalCalcByItemMemo(
    external_injection_processing_fee
  );

  // 項目別経費合計
  const expensesTotalByItem = totalCalcByItemMemo(expenses);

  // 項目別期首仕掛品棚卸高合計
  const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem =
    totalCalcByItemMemo(
      inventory_of_work_in_process_at_the_beginning_of_the_period
    );

  // 項目別期末仕掛品棚卸高合計
  const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem =
    totalCalcByItemMemo(inventory_of_work_in_process_at_the_end_of_the_period);

  // 項目別現預金合計
  const cashEquivalentTotalByItem = totalCalcByItemMemo(cash_equivalent);

  // 項目別売上債権合計
  const accountsReceivableTotalByItem =
    totalCalcByItemMemo(accounts_receivable);

  // 項目別当座資産合計
  const currentAssetsTotalByItem = totalCalcByItemMemo(current_assets);

  // 項目別棚卸資産合計
  const shedUnloadingAssetsTotalByItem = totalCalcByItemMemo(
    shed_unloading_assets
  );

  // 項目別引当金合計
  const quotidianGoldTotalByItem = totalCalcByItemMemo(quotidian_gold);

  // 項目別その他流動資産合計
  const otherCurrentAssetsTotalByItem =
    totalCalcByItemMemo(other_current_assets);

  // 項目別有形固定資産合計
  const tangibleFixedAssetsTotalByItem = totalCalcByItemMemo(
    tangible_fixed_assets
  );

  // 項目別無形固定資産合計
  const intangibleFixedAssetsTotalByItem = totalCalcByItemMemo(
    intangible_fixed_assets
  );

  // 項目別投資その他の資産合計
  const investmentsAndOtherAssetsTotalByItem = totalCalcByItemMemo(
    investments_and_other_assets
  );

  // 項目別繰延資産合計
  const deferredAssetTotalByItem = totalCalcByItemMemo(deferred_asset);

  // 項目別支払債務合計
  const payablesTotalByItem = totalCalcByItemMemo(payables);

  // 項目別短期借入金合計
  const shortTermBorrowingsTotalByItem = totalCalcByItemMemo(
    short_term_borrowings
  );

  // 項目別未払合計
  const unpaidsTotalByItem = totalCalcByItemMemo(unpaids);

  // 項目別流動負債合計
  const currentLiabilitiesTotalByItem =
    totalCalcByItemMemo(current_liabilities);

  // 項目別固定負債合計
  const fixedLiabilitiesTotalByItem = totalCalcByItemMemo(fixed_liabilities);

  // 項目別資本金合計
  const capitalTotalByItem = totalCalcByItemMemo(capital);

  // 項目別資本剰余金合計
  const capitalSurplusTotalByItem = totalCalcByItemMemo(capital_surplus);

  // 項目別利益剰余金合計
  const retainedEarningsTotalByItem = totalCalcByItemMemo(retained_earnings);

  // 項目別その他の利益剰余金合計
  const otherRetainedEarningsTotalByItem = totalCalcByItemMemo(
    other_retained_earnings
  );

  // 項目別自己株式合計
  const treasuryStockTotalByItem = totalCalcByItemMemo(treasury_stock);

  // 項目別社員数合計
  const employeesTotalByItem = totalCalcByItemMemo(employees);

  // 販売費及び一般管理費と経費の減価償却費合計
  const depreciationCost: number[] = totalCalcMemo(depreciation_cost_data);

  // 法人税、住民税及び事業税合計
  const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalcMemo(
    special_corporate_inhabitant_and_enterprise_taxeslosses
  );

  // 期末材料棚卸高
  const rawMaterialInventoriesAtTheEndOfThePeriodT: number[] = totalCalcMemo(
    raw_material_inventories_at_the_end_of_the_period
  );

  // 期首仕掛品棚卸高
  const inventoryOfWorkInProcessAtTheBeginningOfThePeriodT: number[] =
    totalCalcMemo(inventory_of_work_in_process_at_the_beginning_of_the_period);

  // 期末仕掛品棚卸高
  const inventoryOfWorkInProcessAtTheEndOfThePeriodT: number[] = totalCalcMemo(
    inventory_of_work_in_process_at_the_end_of_the_period
  );

  // 売上高合計
  const netSalesTotal: number[] = totalCalcMemo(net_sales);

  // 売上高比率（一番初めは、直近の実績の比率）
  const netSalesPlanRatio: number[] = ratioCalc(netSalesTotal);

  // 直近の実績（年次）の売上高合計
  const netSalesResultsInputTotal: number[] = totalCalc({
    headers: headers[resultsInputField],
    field: resultsInputField,
    state: net_sales,
  });
  const netSalesResultsInputTotalLatest: number =
    netSalesResultsInputTotal[netSalesResultsInputTotal.length - 1];
  const netSalesResultsInputTotalLatestRatio: number =
    round(
      divide(
        Number(netSalesTotal[0]),
        Number(netSalesResultsInputTotalLatest)
      ) * 1000
    ) / 10;
  const netSalesRatio = netSalesPlanRatio.map((ratio, index) => {
    if (index === 0) {
      return isNaN(netSalesResultsInputTotalLatestRatio) ||
        !isFinite(netSalesResultsInputTotalLatestRatio)
        ? 0
        : netSalesResultsInputTotalLatestRatio;
    } else {
      return ratio;
    }
  });

  // 売上高 原価比率
  const netSalesCostRatioFunc = () => {
    const costRatioArray: number[][] = [];
    net_sales.map((netSale) => {
      const costRatioSingleArray: number[] = [];

      headers[field].map((header: DocumentData) => {
        const data =
          netSale.plans_input_cost_ratio &&
          netSale.plans_input_cost_ratio[header.period] &&
          netSale.plans_input_cost_ratio[header.period] !== null
            ? netSale.plans_input_cost_ratio[header.period]
            : 0;
        costRatioSingleArray.push(data);
      });

      costRatioArray.push(costRatioSingleArray);
    });
    return costRatioArray;
  };
  const netSalesCostRatio = netSalesCostRatioFunc();
  const NetSalesSinglesFunc = () => {
    const netSalesArray: number[][] = [];
    net_sales.map((netSale) => {
      const netSalesSingleArray: number[] = [];

      headers[field].map((header: DocumentData) => {
        const data =
          netSale.plans_input &&
          netSale.plans_input[header.period] &&
          netSale.plans_input[header.period] !== null
            ? netSale.plans_input[header.period]
            : 0;
        netSalesSingleArray.push(data);
      });

      netSalesArray.push(netSalesSingleArray);
    });
    return netSalesArray;
  };
  const netSalesSingles = NetSalesSinglesFunc();

  const netSalesMultipleCost =
    netSalesCostRatio[0][0] !== null
      ? (divide(
          dotMultiply(netSalesCostRatio, netSalesSingles),
          100
        ) as number[][])
      : null;

  const costOfPurchasedGoodsFunc = () => {
    let array: number[] = [];
    netSalesMultipleCost &&
      netSalesMultipleCost !== null &&
      netSalesMultipleCost.map((data, index) => {
        index === 0 ? (array = data) : (array = add(data, array));
      });
    return array;
  };
  const costOfPurchasedGoods = costOfPurchasedGoodsFunc();

  // 期末製品棚卸高合計
  const endInventoriesTotal: number[] = totalCalcMemo(end_inventories_data);

  // 原価合計
  const costOfSalesTotal: number[] = subtract(
    totalCalcMemo(cost_of_sales),
    endInventoriesTotal
  );

  const costOfSalesRatio: number[] = arrayRatioCalc({
    numerator: costOfSalesTotal,
    denominator: netSalesTotal,
  });

  // 売上総利益
  const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);
  const grossMarginRatio: number[] = arrayRatioCalc({
    numerator: grossMargin,
    denominator: netSalesTotal,
  });
  const personalCostTotal: number[] = totalCalcMemo(personal_cost);

  const personalCostRatio: number[] = arrayRatioCalc({
    numerator: personalCostTotal,
    denominator: netSalesTotal,
  });
  const sellingAndAdministrativeTotal: number[] = add(
    personalCostTotal,
    totalCalcMemo(selling_and_administrative)
  );
  const sellingAndAdministrativeRatio: number[] = arrayRatioCalc({
    numerator: sellingAndAdministrativeTotal,
    denominator: netSalesTotal,
  });
  // 営業利益
  const operatingIncome: number[] = subtract(
    grossMargin,
    sellingAndAdministrativeTotal
  );
  const operatingIncomeRatio: number[] = arrayRatioCalc({
    numerator: operatingIncome,
    denominator: netSalesTotal,
  });
  // 償却前営業利益
  const operatingProfitsBeforeAmortization: number[] = add(
    operatingIncome,
    depreciationCost
  );
  const operatingProfitsBeforeAmortizationRatio: number[] = arrayRatioCalc({
    numerator: operatingProfitsBeforeAmortization,
    denominator: netSalesTotal,
  });
  const nonOperatingIncomeTotal: number[] = totalCalcMemo(non_operating_income);
  const nonOperatingExpensesTotal: number[] = totalCalcMemo(
    non_operating_expenses
  );

  // 経常利益
  const currentEarnings: number[] = subtract(
    add(operatingIncome, nonOperatingIncomeTotal),
    nonOperatingExpensesTotal
  );
  const currentEarningsRatio: number[] = arrayRatioCalc({
    numerator: currentEarnings,
    denominator: netSalesTotal,
  });
  // 償却前経常利益
  const ebitda: number[] = add(currentEarnings, depreciationCost);
  const ebitdaRatio = arrayRatioCalc({
    numerator: ebitda,
    denominator: netSalesTotal,
  });
  const specialBenefitsTotal: number[] = totalCalcMemo(special_benefits);

  const specialLossesTotal: number[] = totalCalcMemo(special_losses);

  // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
  const ebit: number[] = subtract(
    add(currentEarnings, specialBenefitsTotal),
    specialLossesTotal
  );
  const ebitRatio: number[] = arrayRatioCalc({
    numerator: ebit,
    denominator: netSalesTotal,
  });
  // 当期純利益
  const netIncome: number[] = subtract(
    ebit,
    corporateInhabitantAndEnterpriseTaxesT
  );
  const netIncomeRatio: number[] = arrayRatioCalc({
    numerator: netIncome,
    denominator: netSalesTotal,
  });

  // 原価
  // 材料費合計 期首材料棚卸高+その他材料費-期末材料棚卸高
  const materialFeeTotal: number[] = subtract(
    totalCalc({
      headers: headers[field],
      field: field,
      state: material_fee,
    }),
    add(
      rawMaterialInventoriesAtTheEndOfThePeriodT,
      rawMaterialInventoriesAtTheEndOfThePeriodT
    )
  );
  // 労務費合計
  const laborCostsTotal: number[] = totalCalcMemo(labor_costs);
  // 外注加工費合計
  const externalInjectionProcessingFeeTotal: number[] = totalCalcMemo(
    external_injection_processing_fee
  );
  // 原価の経費にある減価償却費
  const depreciationCostOfSalesT: number[] = totalCalcMemo(
    depreciation_cost_of_cost_of_sales
  );
  // 経費合計
  const expensesTotal: number[] = totalCalcMemo(expenses);

  // 当期製造費用
  const grossManufacturingCostForThePeriod: number[] = add(
    materialFeeTotal,
    add(
      laborCostsTotal,
      add(externalInjectionProcessingFeeTotal, expensesTotal)
    )
  );
  // 当期製品製造原価
  const CostOfProductsManufactured: number[] = subtract(
    add(
      grossManufacturingCostForThePeriod,

      inventoryOfWorkInProcessAtTheBeginningOfThePeriodT
    ),
    inventoryOfWorkInProcessAtTheEndOfThePeriodT
  );

  // BS
  // 現預金計
  const cashEquivalentTotal: number[] = totalCalcMemo(cash_equivalent);

  // 売上債権計
  const accountsReceivableTotal: number[] = totalCalcMemo(accounts_receivable);
  // 当座資産 計
  const currentAssetsTotal: number[] = add(
    cashEquivalentTotal,
    add(
      accountsReceivableTotal,
      totalCalc({
        headers: headers[field],
        field: field,
        state: current_assets,
      })
    )
  );
  // 棚卸資産 計
  const shedUnloadingAssetsTotal: number[] = totalCalcMemo(
    shed_unloading_assets
  );
  // 引当金 計
  const quotidianGoldTotal: number[] = totalCalcMemo(quotidian_gold);

  // 流動資産
  const grossCurrentAssets: number[] = add(
    currentAssetsTotal,
    add(
      shedUnloadingAssetsTotal,
      add(
        quotidianGoldTotal,
        totalCalc({
          headers: headers[field],
          field: field,
          state: other_current_assets,
        })
      )
    )
  );
  // 有形固定資産
  const tangibleFixedAssetsTotal: number[] = totalCalcMemo(
    tangible_fixed_assets
  );
  // 無形固定資産
  const intangibleFixedAssetsTotal: number[] = totalCalcMemo(
    intangible_fixed_assets
  );
  // 投資その他の資産
  const investmentsAndOtherAssetsTotal: number[] = totalCalcMemo(
    investments_and_other_assets
  );
  // 固定資産合計
  const grossFixedAssets: number[] = add(
    tangibleFixedAssetsTotal,
    add(intangibleFixedAssetsTotal, investmentsAndOtherAssetsTotal)
  );
  // 繰越資産
  const deferredAssetTotal: number[] = totalCalcMemo(deferred_asset);
  // 総資産
  const netWorth: number[] = add(
    grossCurrentAssets,
    add(grossFixedAssets, deferredAssetTotal)
  );
  // 支払債務合計
  const payablesTotal: number[] = totalCalcMemo(payables);

  // 短期借入金合計
  const shortTermBorrowingsTotal: number[] = totalCalcMemo(
    short_term_borrowings
  );
  // 未払合計 支払い債務合計+短期借入金+未払合計+その他流動負債合計
  const unpaidsTotal: number[] = totalCalcMemo(unpaids);
  // 流動負債合計
  const currentLiabilitiesTotal: number[] = add(
    payablesTotal,
    add(
      shortTermBorrowingsTotal,
      add(
        unpaidsTotal,
        totalCalc({
          headers: headers[field],
          field: field,
          state: current_liabilities,
        })
      )
    )
  );
  // 固定負債合計
  const fixedLiabilitiesTotal: number[] = totalCalcMemo(fixed_liabilities);
  // 負債合計
  const liabilitiesTotal: number[] = add(
    currentLiabilitiesTotal,
    fixedLiabilitiesTotal
  );
  // 資本金
  const capitalTotal: number[] = totalCalcMemo(capital);
  // 資本剰余金
  const capitalSurplusTotal: number[] = totalCalcMemo(capital_surplus);
  // 利益剰余金
  const retainedEarningsTotal: number[] = totalCalcMemo([
    ...retained_earnings,
    ...other_retained_earnings,
  ]);
  // その他の利益剰余金
  const otherRetainedEarningsTotal: number[] = totalCalcMemo(
    other_retained_earnings
  );
  // 自己株式
  const treasuryStockTotal: number[] = totalCalcMemo(treasury_stock);
  // 株主資本合計 資本金合計+資本剰余金合計+利益剰余金合計+自己株式
  const shareholdersEquityTotal: number[] = add(
    capitalTotal,
    add(capitalSurplusTotal, add(retainedEarningsTotal, treasuryStockTotal))
  );

  // 負債・純資産合計
  const liabilitiesAndNetWorthTotal: number[] = add(
    shareholdersEquityTotal,
    liabilitiesTotal
  );

  // 短期借入金
  const shortTermBorrowingsSingle: number[] = totalCalcMemo(
    short_term_borrowings_data
  );
  // 長期借入金
  const fixedLiabilitiesSingle: number[] = totalCalcMemo(
    fixed_liabilities_data
  );
  // 社債
  const corporateBondSingle: number[] = totalCalcMemo(corporate_bond_data);
  // 長期短期借入金･社債計（役員借入不含）
  const longTermBorrowingsTotal: number[] = add(
    shortTermBorrowingsSingle,
    add(fixedLiabilitiesSingle, corporateBondSingle)
  );
  // 社員数合計
  const employeesTotal: number[] = totalCalcMemo(employees);
  // 材料費（変動のみ）
  const materialFeeFluctuateDataTotal: number[] = subtract(
    totalCalc({
      headers: headers[field],
      field: field,
      state: material_fee_fluctuate_data,
    }),
    add(
      rawMaterialInventoriesAtTheEndOfThePeriodT,
      rawMaterialInventoriesAtTheEndOfThePeriodT
    )
  );
  // 労務費（変動のみ）
  const laborCostsFluctuateTotal: number[] = totalCalcMemo(
    labor_costs_fluctuate_data
  );
  // 外注費（変動のみ）
  const externalInjectionProcessingFeeFluctuateTotal: number[] = totalCalcMemo(
    external_injection_processing_fee_fluctuate_data
  );
  // 経費（変動のみ）
  const expensesFluctuateTotal: number[] = totalCalcMemo(
    expenses_fluctuate_data
  );
  // 労務費（固定のみ）
  const laborCostsFixedDataTotal: number[] = totalCalcMemo(
    labor_costs_fixed_data
  );
  // 経費（固定のみ）
  const expensesFixedDataTotal: number[] = totalCalcMemo(expenses_fixed_data);
  // 固定原価合計
  const fixedCostTotal: number[] = add(
    laborCostsFixedDataTotal,
    expensesFixedDataTotal
  );
  // 固定販管費（人件費）
  const personalCostFixedTotal: number[] = totalCalcMemo(
    personal_cost_fixed_data
  );
  // 固定販管費（その他）
  const sellingAndAdministrativeFixedTotal: number[] = totalCalcMemo(
    selling_and_administrative_fixed_data
  );
  // 固定販管費合計
  const sgaFixedTotal: number[] = add(
    personalCostFixedTotal,
    sellingAndAdministrativeFixedTotal
  );
  // 固定費計
  const fixedCostGrandTotal: number[] = add(fixedCostTotal, sgaFixedTotal);
  // 期首製品棚卸高
  const beginningInventoriesTotal: number[] = totalCalcMemo(
    beginning_inventories_data
  );

  // 仕入合計
  const stockingTotal: number[] = add(
    materialFeeFluctuateDataTotal,
    add(
      personalCostFixedTotal,
      add(
        externalInjectionProcessingFeeFluctuateTotal,
        add(
          expensesFluctuateTotal,
          add(
            inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
            inventoryOfWorkInProcessAtTheEndOfThePeriodT
          )
        )
      )
    )
  );
  // 売上原価合計（期首期末以外）
  const costOfSalesExceptBeginningAndEndTotal: number[] = totalCalcMemo(
    cost_of_sales_except_beginning_and_end_data
  );
  // 変動原価合計 変動売上原価合計+期首仕掛品棚卸高合計-期末仕掛品棚卸高合計-期末製品棚卸高
  const variableCostsTotal: number[] = subtract(
    add(
      materialFeeFluctuateDataTotal,
      add(
        laborCostsFluctuateTotal,
        add(
          externalInjectionProcessingFeeFluctuateTotal,
          add(
            expensesFluctuateTotal,
            add(
              inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
              beginningInventoriesTotal
            )
          )
        )
      )
    ),
    add(inventoryOfWorkInProcessAtTheEndOfThePeriodT, endInventoriesTotal)
  );
  // 変動販管費（人件費）
  const personalCostFluctuateTotal: number[] = totalCalcMemo(
    personal_cost_fluctuate_data
  );
  // 変動販管費（その他）
  const sellingAndAdministrativeFluctuateTotal: number[] = totalCalcMemo(
    selling_and_administrative_fluctuate_data
  );
  // 変動販管費合計
  const sgaTotal: number[] = add(
    personalCostFluctuateTotal,
    sellingAndAdministrativeFluctuateTotal
  );
  // 変動費計
  const variableCostsGrandTotal: number[] = add(variableCostsTotal, sgaTotal);
  // 限界利益
  const marginalProfit: number[] = subtract(
    netSalesTotal,
    variableCostsGrandTotal
  );

  // 営業利益
  const operatingIncomeT: number[] = subtract(
    marginalProfit,
    fixedCostGrandTotal
  );
  // 変動費率
  const variableCostRatioT: number[] = arrayRatioCalc({
    numerator: variableCostsGrandTotal,
    denominator: netSalesTotal,
  });
  // 損益分岐点売上高
  const variableCostRatioNumber = dotDivide(
    variableCostsGrandTotal,
    netSalesTotal
  );
  const newVariableCostRatioNumber = map(
    variableCostRatioNumber as number[],
    function (value) {
      if (isNaN(value) === true) {
        value = 0;
      }
      return value;
    }
  );
  const beforeBepNetSalesTotal = dotDivide(
    fixedCostGrandTotal,
    subtract(1, newVariableCostRatioNumber) as number[]
  );
  const bepNetSalesTotal = map(
    beforeBepNetSalesTotal as number[],
    function (value) {
      if (isNaN(value) === true) {
        value = 0;
      }
      return round(value);
    }
  );

  // 損益分岐点比率
  const bepNetSalesRatio: number[] = arrayRatioCalc({
    numerator: bepNetSalesTotal,
    denominator: netSalesTotal,
  });
  // 当期製造費用比率
  const grossManufacturingCostForThePeriodRatio: number[] = arrayRatioCalc({
    numerator: grossManufacturingCostForThePeriod,
    denominator: grossManufacturingCostForThePeriod,
  });
  const grossManufacturingCostForThePeriodTotalRatio: number = divide(
    sum(grossManufacturingCostForThePeriod),
    sum(grossManufacturingCostForThePeriod)
  ) as number;
  // 材料費当期製造費用比率
  const materialFeeTotalRatio: number[] = arrayRatioCalc({
    numerator: materialFeeTotal,
    denominator: grossManufacturingCostForThePeriod,
  });
  const materialFeeTotalTotalRatio: number = divide(
    sum(materialFeeTotal),
    sum(grossManufacturingCostForThePeriod)
  ) as number;
  // 労務費当期製造費用比率
  const laborCostsTotalRatio: number[] = arrayRatioCalc({
    numerator: laborCostsTotal,
    denominator: grossManufacturingCostForThePeriod,
  });
  const laborCostsTotalTotalRatio: number = divide(
    sum(laborCostsTotal),
    sum(grossManufacturingCostForThePeriod)
  ) as number;
  // 外注加工費当期製造費用比率
  const externalInjectionProcessingFeeTotalRatio: number[] = arrayRatioCalc({
    numerator: externalInjectionProcessingFeeTotal,
    denominator: grossManufacturingCostForThePeriod,
  });
  const externalInjectionProcessingFeeTotalTotalRatio: number = divide(
    sum(externalInjectionProcessingFeeTotal),
    sum(grossManufacturingCostForThePeriod)
  ) as number;
  // 経費当期製造費用比率
  const expensesTotalRatio: number[] = arrayRatioCalc({
    numerator: expensesTotal,
    denominator: grossManufacturingCostForThePeriod,
  });
  const expensesTotalTotalRatio: number = divide(
    sum(expensesTotal),
    sum(grossManufacturingCostForThePeriod)
  ) as number;
  // 売上高材料費比率
  const netSalesMaterialFeeTotalRatio: number[] = arrayRatioCalc({
    numerator: materialFeeTotal,
    denominator: netSalesTotal,
  });
  const netSalesMaterialFeeYearlyTotalRatio: number = divide(
    sum(materialFeeTotal),
    sum(netSalesTotal)
  ) as number;
  // 売上高労務費比率
  const netSalesLaborCostsTotalRatio: number[] = arrayRatioCalc({
    numerator: laborCostsTotal,
    denominator: netSalesTotal,
  });
  const netSalesLaborCostsTotalYearlyTotalRatio: number = divide(
    sum(laborCostsTotal),
    sum(netSalesTotal)
  ) as number;
  // 売上高外注費比率
  const netSalesExternalInjectionProcessingFeeTotalRatio: number[] =
    arrayRatioCalc({
      numerator: externalInjectionProcessingFeeTotal,
      denominator: netSalesTotal,
    });
  const netSalesExternalInjectionProcessingFeeTotalYearlyTotalRatio: number =
    divide(
      sum(externalInjectionProcessingFeeTotal),
      sum(netSalesTotal)
    ) as number;
  // 売上高経費比率
  const netSalesExpensesTotalRatio: number[] = arrayRatioCalc({
    numerator: expensesTotal,
    denominator: netSalesTotal,
  });
  const netSalesExpensesTotalYearlyTotalRatio: number = divide(
    sum(expensesTotal),
    sum(netSalesTotal)
  ) as number;
  // 売上債権回転日数 売上債権/(売上高/長期短期借入金･社債計（役員借入不含 )
  const turnoverDenominator = dotDivide(netSalesTotal, 365);

  const receivablesTurnover = arrayRatioCalc({
    numerator: accountsReceivableTotal,
    denominator: turnoverDenominator as number[],
  });

  // 棚卸資産回転日数 棚卸資産/(売上高/長期短期借入金･社債計（役員借入不含 )
  const inventoryTurnover = arrayRatioCalc({
    numerator: shedUnloadingAssetsTotal,
    denominator: turnoverDenominator as number[],
  });

  // 今期直近までと前期の比較ページの売上高修正後
  const netSalesTotalFixed: number = totalCalc({
    headers: [{ results: "fixed" }],
    field: "current_and_previous_year_comparisons",
    state: net_sales,
  })[0];

  // 今期直近までと前期の比較ページの売上原価修正後
  const costOfSalesTotalFixed: number = subtract(
    subtract(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: cost_of_sales,
      }),
      sum(endInventoriesTotal)
    ),
    sum(endInventoriesTotal)
  )[0];

  // 今期直近までと前期の比較ページの売上総利益修正後
  const grossMarginFixed = netSalesTotalFixed - costOfSalesTotalFixed;
  // 今期直近までと前期の比較ページの売上総利益修正後
  const grossMarginRatioFixed = isNaN(
    netSalesTotal[netSalesTotal.length - 1] / grossMarginFixed
  )
    ? 0
    : netSalesTotal[netSalesTotal.length - 1] / grossMarginFixed;

  // 今期直近までと前期の比較ページの人件費修正後
  const personalCostTotalFixed: number = totalCalc({
    headers: [{ results: "fixed" }],
    field: "current_and_previous_year_comparisons",
    state: personal_cost,
  })[0];

  // 今期直近までと前期の比較ページの販管費修正後
  const sellingAndAdministrativeTotalFixed: number =
    personalCostTotalFixed +
    totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: selling_and_administrative,
    })[0];
  // 今期直近までと前期の比較ページの営業利益修正後
  const operatingIncomeFixed =
    grossMarginFixed - sellingAndAdministrativeTotalFixed;
  // 今期直近までと前期の比較ページの営業利益割合修正後
  const operatingIncomeRatioFixed = isNaN(
    operatingIncomeFixed / grossMarginFixed
  )
    ? 0
    : operatingIncomeFixed / grossMarginFixed;
  // 今期直近までと前期の比較ページの償却前営業利益修正後
  const operatingProfitsBeforeAmortizationFixed =
    operatingIncomeFixed +
    sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: depreciation_cost_data,
      })
    );
  // 今期直近までと前期の比較ページの売上高償却前営業利益率修正後
  const operatingProfitsBeforeAmortizationRatioFixed = isNaN(
    operatingProfitsBeforeAmortizationFixed / grossMarginFixed
  )
    ? 0
    : operatingProfitsBeforeAmortizationFixed / grossMarginFixed;

  // 売上構成比
  const netSalesTotalByItemRatio = calcByItemForPlansResultMonthly({
    headers: headers[field],
    field: field,
    state: net_sales,
  });

  // 支払利息
  const interestExpensesT: number[] = totalCalcMemo(interest_expenses);

  // キャッシュアウトしない費用
  const nonCashOutTotal: number[] = totalCalcMemo(non_cash_out);

  // 簡易CF
  const simpleCfRow1: number[] = add(
    currentEarnings,
    add(depreciationCost, nonCashOutTotal)
  );
  const simpleCfRow2: number[] = add(
    netIncome, // 当期純利益
    add(depreciationCost, nonCashOutTotal)
  );

  // 粗付加価値額
  const simpleCfRow3: number[] = add(
    ebit, // 税引き前当期純利益
    add(
      personalCostTotal, // 人件費合計
      add(
        laborCostsTotal, // 労務費合計
        add(
          depreciationCost, // 減価償却費
          add(
            nonCashOutTotal, // キャッシュアウトしない費用)
            interestExpensesT // 支払利息
          )
        )
      )
    )
  );

  const plansInputCalcState = {
    net_sales_total_by_item: netSalesTotalByItem,
    cost_of_sales_total_by_item: costOfSalesTotalByItem,
    selling_and_administrative_total_by_item:
      sellingAndAdministrativeTotalByItem,
    personal_cost_total_by_item: personalCostTotalByItem,
    non_operating_income_total_by_item: nonOperatingIncomeTotalByItem,
    non_operating_expenses_total_by_item: nonOperatingExpensesTotalByItem,
    special_benefits_total_by_item: specialBenefitsTotalByItem,
    special_losses_total_by_item: specialLossesTotalByItem,
    special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item:
      corporateInhabitantAndEnterpriseTaxesTotalByItem,
    material_fee_total_by_item: materialFeeTotalByItem,
    labor_costs_total_by_item: laborCostsTotalByItem,
    external_injection_processing_fee_total_by_item:
      externalInjectionProcessingFeeTotalByItem,
    expenses_total_by_item: expensesTotalByItem,
    inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item:
      inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem,
    inventory_of_work_in_process_at_the_end_of_the_period_total_by_item:
      inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem,
    cash_equivalent_total_by_item: cashEquivalentTotalByItem,
    accounts_receivable_total_by_item: accountsReceivableTotalByItem,
    current_assets_total_by_item: currentAssetsTotalByItem,
    shed_unloading_assets_total_by_item: shedUnloadingAssetsTotalByItem,
    quotidian_gold_total_by_item: quotidianGoldTotalByItem,
    other_current_assets_total_by_item: otherCurrentAssetsTotalByItem,
    tangible_fixed_assets_total_by_item: tangibleFixedAssetsTotalByItem,
    intangible_fixed_assets_total_by_item: intangibleFixedAssetsTotalByItem,
    investments_and_other_assets_total_by_item:
      investmentsAndOtherAssetsTotalByItem,
    deferred_asset_total_by_item: deferredAssetTotalByItem,
    payables_total_by_item: payablesTotalByItem,
    short_term_borrowings_total_by_item: shortTermBorrowingsTotalByItem,
    unpaids_total_by_item: unpaidsTotalByItem,
    current_liabilities_total_by_item: currentLiabilitiesTotalByItem,
    fixed_liabilities_total_by_item: fixedLiabilitiesTotalByItem,
    capital_total_by_item: capitalTotalByItem,
    capital_surplus_total_by_item: capitalSurplusTotalByItem,
    retained_earnings_total_by_item: retainedEarningsTotalByItem,
    other_retained_earnings_total_by_item: otherRetainedEarningsTotalByItem,
    treasury_stock_total_by_item: treasuryStockTotalByItem,
    employees_total_by_item: employeesTotalByItem,
    zero_array: zeroArray,
    net_sales_total: netSalesTotal,
    net_sales_ratio: netSalesRatio,
    cost_of_sales_total: costOfSalesTotal,
    cost_of_sales_ratio: costOfSalesRatio,
    gross_margin: grossMargin,
    gross_margin_ratio: grossMarginRatio,
    personal_cost_total: personalCostTotal,
    personal_cost_ratio: personalCostRatio,
    selling_and_administrative_total: sellingAndAdministrativeTotal,
    selling_and_administrative_ratio: sellingAndAdministrativeRatio,
    operating_income: operatingIncome,
    operating_income_ratio: operatingIncomeRatio,
    operating_profits_before_amortization: operatingProfitsBeforeAmortization,
    operating_profits_before_amortization_ratio:
      operatingProfitsBeforeAmortizationRatio,
    non_operating_income_total: nonOperatingIncomeTotal,
    non_operating_expenses_total: nonOperatingExpensesTotal,
    current_earnings: currentEarnings,
    current_earnings_ratio: currentEarningsRatio,
    ebitda: ebitda,
    ebitda_ratio: ebitdaRatio,
    special_benefits_total: specialBenefitsTotal,
    special_losses_total: specialLossesTotal,
    ebit: ebit,
    ebit_ratio: ebitRatio,
    net_income: netIncome,
    net_income_ratio: netIncomeRatio,
    simple_cf_row1: simpleCfRow1,
    simple_cf_row2: simpleCfRow2,
    simple_cf_row3: simpleCfRow3,
    material_fee_total: materialFeeTotal,
    labor_costs_total: laborCostsTotal,
    external_injection_processing_fee_total:
      externalInjectionProcessingFeeTotal,
    expenses_total: expensesTotal,
    gross_manufacturing_cost_for_the_period: grossManufacturingCostForThePeriod,
    cost_of_products_manufactured: CostOfProductsManufactured,
    cash_equivalent_total: cashEquivalentTotal,
    accounts_receivable_total: accountsReceivableTotal,
    current_assets_total: currentAssetsTotal,
    shed_unloading_assets_total: shedUnloadingAssetsTotal,
    quotidian_gold_total: quotidianGoldTotal,
    gross_current_assets: grossCurrentAssets,
    tangible_fixed_assets_total: tangibleFixedAssetsTotal,
    intangible_fixed_assets_total: intangibleFixedAssetsTotal,
    investments_and_other_assets_total: investmentsAndOtherAssetsTotal,
    gross_fixed_assets: grossFixedAssets,
    deferred_asset_total: deferredAssetTotal,
    net_worth: netWorth,
    payables_total: payablesTotal,
    short_term_borrowings_total: shortTermBorrowingsTotal,
    unpaids_total: unpaidsTotal,
    current_liabilities_total: currentLiabilitiesTotal,
    fixed_liabilities_total: fixedLiabilitiesTotal,
    liabilities_total: liabilitiesTotal,
    capital_total: capitalTotal,
    capital_surplus_total: capitalSurplusTotal,
    retained_earnings_total: retainedEarningsTotal,
    other_retained_earnings_total: otherRetainedEarningsTotal,
    treasury_stock_total: treasuryStockTotal,
    shareholders_equity_total: shareholdersEquityTotal,
    liabilities_and_net_worth_total: liabilitiesAndNetWorthTotal,
    long_term_borrowings_total: longTermBorrowingsTotal,
    employees_total: employeesTotal,
    material_fee_fluctuate_data_total: materialFeeFluctuateDataTotal,
    labor_costs_fluctuate_total: laborCostsFluctuateTotal,
    external_injection_processing_fee_fluctuate_total:
      externalInjectionProcessingFeeFluctuateTotal,
    expenses_fluctuate_total: expensesFluctuateTotal,
    inventory_of_work_in_process_at_the_beginning_of_the_period_t:
      inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
    inventory_of_work_in_process_at_the_end_of_the_period_t:
      inventoryOfWorkInProcessAtTheEndOfThePeriodT,
    beginning_inventories_total: beginningInventoriesTotal,
    end_inventories_total: endInventoriesTotal,
    stocking_total: stockingTotal,
    cost_of_sales_except_beginning_and_end_total:
      costOfSalesExceptBeginningAndEndTotal,
    variable_costs_total: variableCostsTotal,
    personal_cost_fluctuate_total: personalCostFluctuateTotal,
    selling_and_administrative_fluctuate_total:
      sellingAndAdministrativeFluctuateTotal,
    sga_total: sgaTotal,
    variable_costs_grand_total: variableCostsGrandTotal,
    marginal_profit: marginalProfit,
    labor_costs_fixed_data_total: laborCostsFixedDataTotal,
    expenses_fixed_data_total: expensesFixedDataTotal,
    fixed_cost_total: fixedCostTotal,
    personal_cost_fixed_total: personalCostFixedTotal,
    selling_and_administrative_fixed_total: sellingAndAdministrativeFixedTotal,
    sga_fixed_total: sgaFixedTotal,
    fixed_cost_grand_total: fixedCostGrandTotal,
    operating_income_t: operatingIncomeT,
    variable_cost_ratio_t: variableCostRatioT,
    bep_net_sales_total: bepNetSalesTotal,
    bep_net_sales_ratio: bepNetSalesRatio,
    depreciation_cost: depreciationCost,
    material_fee_total_ratio: materialFeeTotalRatio,
    labor_costs_total_ratio: laborCostsTotalRatio,
    external_injection_processing_fee_total_ratio:
      externalInjectionProcessingFeeTotalRatio,
    expenses_total_ratio: expensesTotalRatio,
    net_sales_material_fee_total_ratio: netSalesMaterialFeeTotalRatio,
    gross_manufacturing_cost_for_the_period_ratio:
      grossManufacturingCostForThePeriodRatio,
    gross_manufacturing_cost_for_the_period_total_ratio:
      grossManufacturingCostForThePeriodTotalRatio,
    material_fee_total_total_ratio: materialFeeTotalTotalRatio,
    labor_costs_total_total_ratio: laborCostsTotalTotalRatio,
    external_injection_processing_fee_total_total_ratio:
      externalInjectionProcessingFeeTotalTotalRatio,
    expenses_total_total_ratio: expensesTotalTotalRatio,
    net_sales_labor_costs_total_ratio: netSalesLaborCostsTotalRatio,
    net_sales_external_injection_processing_fee_total_ratio:
      netSalesExternalInjectionProcessingFeeTotalRatio,
    net_sales_expenses_total_ratio: netSalesExpensesTotalRatio,
    net_sales_material_fee_yearly_total_ratio:
      netSalesMaterialFeeYearlyTotalRatio,
    net_sales_labor_costs_total_yearly_total_ratio:
      netSalesLaborCostsTotalYearlyTotalRatio,
    net_sales_external_injection_processing_fee_total_yearly_total_ratio:
      netSalesExternalInjectionProcessingFeeTotalYearlyTotalRatio,
    net_sales_expenses_total_yearly_total_ratio:
      netSalesExpensesTotalYearlyTotalRatio,
    raw_material_inventories_at_the_end_of_the_period:
      rawMaterialInventoriesAtTheEndOfThePeriodT,
    depreciation_cost_of_sales_t: depreciationCostOfSalesT,
    receivables_turnover: receivablesTurnover,
    inventory_turnover: inventoryTurnover,
    net_sales_total_fixed: netSalesTotalFixed,
    cost_of_sales_total_fixed: costOfSalesTotalFixed,
    gross_margin_fixed: grossMarginFixed,
    gross_margin_ratio_fixed: grossMarginRatioFixed,
    personal_cost_total_fixed: personalCostTotalFixed,
    selling_and_administrative_total_fixed: sellingAndAdministrativeTotalFixed,
    operating_income_fixed: operatingIncomeFixed,
    operating_income_ratio_fixed: operatingIncomeRatioFixed,
    operating_profits_before_amortization_fixed:
      operatingProfitsBeforeAmortizationFixed,
    operating_profits_before_amortization_ratio_fixed:
      operatingProfitsBeforeAmortizationRatioFixed,
    net_sales_cost_ratio: netSalesCostRatio,
    cost_of_purchased_goods: costOfPurchasedGoods,
    net_sales_total_by_item_ratio: netSalesTotalByItemRatio,
  };

  return plansInputCalcState;
  // } catch (error) {
  //   // Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  // }
};
