import React, { useEffect } from "react";
import "../../css/react_datasheet.css";
import { useNavigate } from "react-router-dom";
import { SubHeader } from "../../components/header";
import { ManagementDashboardContainer } from "../../components/managementSimulation/container";
import { pageConstant } from "../../constants/pageConstants";
import { useGetFirebaseData } from "../../firebase";
import { useManagementDashboardInitialData } from "../../hooks/initialData";
import { sortData } from "../../utils";

export const ManagementDashboard = () => {
  useManagementDashboardInitialData();
  const navigate = useNavigate();
  const { tableData } = useGetFirebaseData();

  useEffect(() => {
    const netSales = sortData("net_sales", tableData);

    if (netSales.length === 0) {
      return navigate(`/${pageConstant.ACCOUNT_TITLES}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <SubHeader title="ダッシュボード" />
      <ManagementDashboardContainer />
    </React.Fragment>
  );
};
