import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const DepreciationCost = ({
  headers,
  rows,
  dataUrl,
  unit,
}: {
  headers: DocumentData[];
  rows: DocumentData;
  dataUrl: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-14px font-bold bg-white before:border-x before:border-r-c3333334d before:border-b before:bg-c2a4b770d`;

  const classCol2 = `${borderSettings} p-4px before:border-l before:border-l-c3333334d before:border-b text-14px  text-right before:bg-c2a4b770d`;

  const classCol3 = `${borderSettings} p-4px before:border-x before:border-l-c3333334d before:border-b before:bg-c2a4b770d text-14px h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: "⑤減価償却費",
      readOnly: true,
      className: `${classCol1}`,
    },
  ];

  headers?.map((header: DocumentData, tdIndex: number) => {
    if (tdIndex === 0) return;

    gridRow.push({
      value:
        rows && rows[dataUrl] && rows[dataUrl][header.period]
          ? ShowTableData(rows[dataUrl][header.period] / unit)
          : "",
      expr:
        rows && rows[dataUrl] && rows[dataUrl][header.period]
          ? rows[dataUrl][header.period] / unit
          : "",
      readOnly: false,
      className: `${classCol2}`,
      row: rows,
      url: dataUrl,
      key: header.period,
    });
  });

  gridRow.push({
    value: rows?.descriptions[`${dataUrl}_${rows?.field as string}`],
    expr: rows?.descriptions[`${dataUrl}_${rows?.field as string}`],
    readOnly: false,
    className: classCol3,
    row: rows,
    url: dataUrl,
    key: "descriptions",
    colSpan: 1,
  });
  grid.push(gridRow);

  return grid;
};
