import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DarkButton } from "../../components/atoms";
import { SubHeader } from "../../components/header";
import { pageConstant } from "../../constants/pageConstants";
import { signUpClientStore } from "../../store";

const EmailInvitation = () => {
  const navigate = useNavigate();

  // const [email, setEmail] = useState("");
  const [signUpState, setSignUpState] = useRecoilState(signUpClientStore);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // try {
    //   const { status }: AxiosResponse = await axiosInstance({
    //     url: apiUrl.USERS,
    //     method: "POST",
    //     data: { email, category: 2, specialist_uid: specialists.user.uid },
    //   });

    //   if (status === 500) {
    //     toast.error(`エラーが発生しました：${status}`);
    //     return;
    //   }

    //   navigate(`/${pageConstant.COMPLETE_EMAIL_INVITATION}`);
    // } catch (error: any) {
    //   if (error.response.status === 409) {
    //     toast.error("士業で登録されているメールアドレスです");
    //   } else {
    //     toast.error("送信できませんでした");
    //   }
    // }
    navigate(`/${pageConstant.CLIENT_INVITATION}`);
    // console.log(signUpState);
  };

  return (
    <React.Fragment>
      <SubHeader title="クライアント新規登録" />
      <div className="flex items-center justify-center w-full h-[calc(100%-100px)] mt-100px px-20px">
        <div className="flex items-center justify-center w-4/6 h-4/6 bg-c2a4b770d m-auto">
          <div className="w-[480px]">
            <p className="text-14px text-center leading-6 mb-12px">
              事業計画を作成したいクライアントのメールアドレスを入力して下さい。
              <br />
              この段階ではクライアントにはメールは送信されません。
            </p>
            <p className="text-14px text-center text-ce20d0d font-bold mb-30px">
              ※士業アカウントとは異なるメールアドレスを入力してください
            </p>
            <form
              className="px-8px pt-6px pb-8px"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="mb-30px">
                <label
                  className="block text-13px font-bold mb-8px"
                  htmlFor="email"
                >
                  クライアントのメールアドレスを入力
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  onChange={(e) =>
                    setSignUpState({
                      ...signUpState,
                      email: String(e.target.value),
                    })
                  }
                  placeholder="sample@cloudplanner.com"
                  // onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </div>
              <div className="m-auto">
                <DarkButton>登録する</DarkButton>
                <p className="text-14px pt-25px text-center">
                  <Link
                    className="inline-block align-baseline font-bold text-14px text-c427ed1 underline hover:opacity-80"
                    to={`/${pageConstant.CLIENTS}`}
                  >
                    クライアント一覧へ戻る
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(EmailInvitation);
