import React from "react";

interface Props {
  children: React.ReactNode;
  title: string;
}

const ConfirmTr: React.FC<Props> = ({ children, title }) => {
  return (
    <tr>
      <th className="w-4/12 bg-c3333330d px-30px py-20px border-y border-y-c33333333">
        {title}
      </th>
      <td className="relative w-8/12 bg-white px-30px py-20px border-y border-y-c33333333">
        {children}
      </td>
    </tr>
  );
};

export default ConfirmTr;
