import { DocumentData } from "firebase/firestore";
import { useSubjectItemsInitialData } from "../hooks/initialData";

export const AccountTitlesItems = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const profitAndLossStatementItems: DocumentData[] = [
    ...resultsAndPlansDataState.net_sales,
    ...resultsAndPlansDataState.cost_of_sales,
    ...resultsAndPlansDataState.personal_cost,
    ...resultsAndPlansDataState.selling_and_administrative,
    ...resultsAndPlansDataState.non_operating_income,
    ...resultsAndPlansDataState.non_operating_expenses,
    ...resultsAndPlansDataState.special_benefits,
    ...resultsAndPlansDataState.special_losses,
    ...resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
  ];

  const costReportItems: DocumentData[] = [
    ...resultsAndPlansDataState.material_fee,
    ...resultsAndPlansDataState.labor_costs,
    ...resultsAndPlansDataState.external_injection_processing_fee,
    ...resultsAndPlansDataState.expenses,
    ...resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
    ...resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
  ];

  const balanceSheetItems: DocumentData[] = [
    ...resultsAndPlansDataState.cash_equivalent,
    ...resultsAndPlansDataState.accounts_receivable,
    ...resultsAndPlansDataState.current_assets,
    ...resultsAndPlansDataState.shed_unloading_assets,
    ...resultsAndPlansDataState.quotidian_gold,
    ...resultsAndPlansDataState.other_current_assets,
    ...resultsAndPlansDataState.tangible_fixed_assets,
    ...resultsAndPlansDataState.intangible_fixed_assets,
    ...resultsAndPlansDataState.investments_and_other_assets,
    ...resultsAndPlansDataState.deferred_asset,
    ...resultsAndPlansDataState.payables,
    ...resultsAndPlansDataState.unpaids,
    ...resultsAndPlansDataState.short_term_borrowings,
    ...resultsAndPlansDataState.current_liabilities,
    ...resultsAndPlansDataState.fixed_liabilities,
    ...resultsAndPlansDataState.capital,
    ...resultsAndPlansDataState.capital_surplus,
    ...resultsAndPlansDataState.retained_earnings,
    ...resultsAndPlansDataState.other_retained_earnings,
    ...resultsAndPlansDataState.treasury_stock,
  ];

  return { profitAndLossStatementItems, costReportItems, balanceSheetItems };
};
