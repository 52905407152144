import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlEstimatedInThisTermWithTax = ({
  headers,
  rows,
  calcState,
  url,
  bodyField,
  unit,
  descriptions,
}: {
  headers: DocumentData[];
  rows: DocumentData[];
  calcState: DocumentData;
  url: string;
  bodyField?: string;
  unit: number;
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b z-[32]`;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white  before:border-b 
         font-bold`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-b  before:border-x-c3333334d`;

      const classCol4 = `${borderSettings} before:border-l before:border-l-c3333334d before:border-b  text-right before:border-x-c3333334d }`;

      const classCol5 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 w-120px  text-right  before:bg-cfffee2 ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }`;

      const classCol6 = `${borderSettings} before:border-x before:border-b before:border-l-c3333334d h-50px description`;

      // 行の値
      const gridRow = [];

      gridRow.push({
        value: (
          <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
            法人税、住民税及び事業税
          </div>
        ),
        readOnly: true,
        className: classCol1,
        colSpan: 2,
        row: row,
        url: url,
        key: "",
      });
      gridRow.push(
        { value: "", readOnly: true, className: classCol2 },
        { value: "", readOnly: true, className: classCol3 }
      );

      headers.map((_, tdIndex: number) => {
        gridRow.push({
          value: ShowTableDataTriangle(calcState.by_item[tdIndex] / unit),
          expr: calcState.by_item[tdIndex] / unit,
          readOnly: true,
          className: classCol4,
          colSpan: 1,
        });
      });

      // 見込み
      gridRow.push({
        value: ShowTableDataTriangle(calcState.by_item_total[0] / unit),
        expr: calcState.by_item_total[0] / unit,
        readOnly: true,
        className: classCol5,
        colSpan: 1,
      });

      gridRow.push({
        value: descriptions[`${bodyField}_${row?.field as string}`],
        expr: descriptions[`${bodyField}_${row?.field as string}`],
        readOnly: false,
        className: classCol6,
        row: descriptions,
        url: url,
        field: `${bodyField}_${row?.field as string}`,
        key: "descriptions_calc",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
