import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { LightButton, DarkButton } from "../../components/atoms";
import { SignUpLayout } from "../../components/layout";
import { BillingPlan } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { signUpSpecialistStore } from "../../store";

const SignUpPayment = () => {
  const navigate = useNavigate();

  const [signUpState, setSignUpState] = useRecoilState(signUpSpecialistStore);

  const [separateCardNumber, setSeparateCardNumber] = useState(
    signUpState.card_number
  );

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardNumber = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = cardNumber.replace(/\D/g, "");

    if (inputNumbersOnly.length > 16) {
      inputNumbersOnly = inputNumbersOnly.substring(0, 16);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }

    setSeparateCardNumber(spacedNumber);

    if (Number(cardNumber.slice(0, 1)) === 4) {
      setSignUpState({
        ...signUpState,
        card_kind: "VISA",
        card_number: String(e.target.value),
      });
    } else if (
      Number(cardNumber.slice(0, 4)) >= 3528 &&
      Number(cardNumber.slice(0, 4)) <= 3589
    ) {
      setSignUpState({
        ...signUpState,
        card_kind: "JCB",
        card_number: String(e.target.value),
      });
    } else if (Number(cardNumber.slice(0, 1)) === 5) {
      setSignUpState({
        ...signUpState,
        card_kind: "Mastercard",
        card_number: String(e.target.value),
      });
    } else if (
      Number(cardNumber.slice(0, 2)) === 34 ||
      Number(cardNumber.slice(0, 2)) === 37
    ) {
      setSignUpState({
        ...signUpState,
        card_kind: "AmericanExpress",
        card_number: String(e.target.value),
      });
    } else if (
      (Number(cardNumber.slice(0, 3)) >= 300 &&
        Number(cardNumber.slice(0, 3)) <= 305) ||
      Number(cardNumber.slice(0, 4)) === 3095 ||
      Number(cardNumber.slice(0, 2)) === 36 ||
      Number(cardNumber.slice(0, 2)) === 38 ||
      Number(cardNumber.slice(0, 2)) === 39
    ) {
      setSignUpState({
        ...signUpState,
        card_kind: "DinnersClub",
        card_number: String(e.target.value),
      });
    } else {
      setSignUpState({
        ...signUpState,
        card_kind: null,
        card_number: String(e.target.value),
      });
    }
  };

  const handleClick = () => {
    if (signUpState.plan_type === 1) {
      navigate(`/${pageConstant.CONFIRM_SIGNUP}`);
    }

    if (signUpState.card_number.length < 14) {
      toast.warn("正しいカード番号が入力されていません。");
      return;
    } else if (signUpState.card_kind === null) {
      toast.warn(
        "使用できない発行会社のカードもしくは、カード番号が間違っています。"
      );
      return;
    } else if (signUpState.card_name.length === 0) {
      toast.warn("カード名義が入力されていません。");
      return;
    } else if (
      signUpState.expire_month === 0 ||
      signUpState.expire_year === 0
    ) {
      toast.warn("有効期限が入力されていません。");
      return;
    } else if (signUpState.card_cvc_number.length === 0) {
      toast.warn("CVC番号が入力されていません。");
      return;
    } else if (signUpState.plan_type === null) {
      toast.warn("ご希望のプランを選択してください。");
      return;
    } else if (signUpState.company_name.length === 0) {
      toast.warn(
        "登録情報がリセットされました。お手数ですが、もう一度利用者情報の登録をお願いします。"
      );
      setTimeout(() => {
        navigate(`/${pageConstant.SIGNUP_SPECIALIST}`);
      }, 3000);
      return;
    }
    navigate(`/${pageConstant.CONFIRM_SIGNUP}`);
  };

  useEffect(() => {
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <SignUpLayout progress={2} title={"料金プラン選択・決済情報登録"}>
      <div className="max-w-[932px] m-auto">
        <form>
          <BillingPlan isPayment={true} planType={1} />
          {signUpState.plan_type != 1 && (
            <div className="bg-c3333330d mt-50px py-30px px-40px">
              <h3 className="font-bold">決済情報登録</h3>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12">ご利用可能カード</label>
                <div className="w-9/12">
                  <div className="flex justify-between w-300px">
                    <img
                      alt="VISA"
                      className="w-50px"
                      src={`${window.location.origin}/img/visa.svg`}
                    />
                    <img
                      alt="Mastercard"
                      className="w-50px"
                      src={`${window.location.origin}/img/mastercard.svg`}
                    />
                    <img
                      alt="JCB"
                      className="w-50px"
                      src={`${window.location.origin}/img/jcb.svg`}
                    />
                    <img
                      alt="AMERICAN EXPRESS"
                      className="w-50px"
                      src={`${window.location.origin}/img/american_express.svg`}
                    />
                    <img
                      alt="DinersClub"
                      className="w-50px"
                      src={`${window.location.origin}/img/diners_club.svg`}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12" htmlFor="cardNumber">
                  カード番号
                </label>
                <div className="w-9/12">
                  <input
                    autoComplete="cc-number"
                    className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="cardNumber"
                    inputMode="numeric"
                    maxLength={19}
                    minLength={15}
                    onChange={(e) => {
                      handleCardNumberChange(e);
                    }}
                    pattern="[0-9]*"
                    placeholder="0000000000000000"
                    required
                    type="text"
                    value={separateCardNumber}
                  />
                </div>
              </div>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12" htmlFor="cardNumber">
                  カード名義
                </label>
                <div className="w-9/12">
                  <input
                    autoComplete="cc-name"
                    className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="cardNumber"
                    inputMode="text"
                    onChange={(e) =>
                      setSignUpState({
                        ...signUpState,
                        card_name: String(e.target.value),
                      })
                    }
                    pattern="^[0-9A-Za-z]+$"
                    required
                    type="text"
                    value={signUpState.card_name}
                  />
                </div>
              </div>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12" htmlFor="cardNumber">
                  有効期限
                </label>
                <div className="w-9/12">
                  <input
                    autoComplete="cc-exp-month"
                    className="shadow appearance-none border border-c33333333 rounded w-1/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="cardNumber"
                    inputMode="numeric"
                    maxLength={2}
                    minLength={2}
                    onChange={(e) =>
                      setSignUpState({
                        ...signUpState,
                        expire_month: Number(e.target.value),
                      })
                    }
                    pattern="[0-9]*"
                    placeholder="00"
                    required
                    type="text"
                    value={signUpState.expire_month}
                  />
                  <span className="mx-8px">月/</span>
                  <input
                    autoComplete="cc-exp-year"
                    className="shadow appearance-none border border-c33333333 rounded w-1/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                    id="cardNumber"
                    inputMode="numeric"
                    maxLength={2}
                    minLength={2}
                    onChange={(e) =>
                      setSignUpState({
                        ...signUpState,
                        expire_year: Number(e.target.value),
                      })
                    }
                    pattern="[0-9]*"
                    placeholder="00"
                    required
                    type="text"
                    value={signUpState.expire_year}
                  />
                  <span className="mx-8px">年</span>
                </div>
              </div>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12" htmlFor="cardNumber">
                  CVC番号
                </label>
                <div className="w-9/12">
                  <input
                    autoComplete="cc-csc"
                    className="shadow appearance-none border border-c33333333 rounded w-2/12 py-12px mr-8px leading-tight focus:outline-none focus:shadow-outline"
                    id="cardNumber"
                    inputMode="text"
                    maxLength={4}
                    minLength={3}
                    onChange={(e) =>
                      setSignUpState({
                        ...signUpState,
                        card_cvc_number: String(e.target.value),
                      })
                    }
                    pattern="[0-9]*"
                    placeholder="123"
                    required
                    type="text"
                    value={signUpState.card_cvc_number}
                  />
                  ※通常カード裏面に刻印されています
                </div>
              </div>
              <div className="flex items-center my-15px">
                <label className="font-bold w-3/12" htmlFor="cardNumber">
                  お支払い回数
                </label>
                <div className="w-9/12">
                  <input checked id="cardNumber" required type="radio" />
                  <span className="ml-8px">一回払い</span>
                </div>
              </div>
            </div>
          )}
        </form>
        <div className="flex gap-20px w-[580px] mx-auto pt-20px">
          <LightButton onClick={() => navigate(-1)}>前に戻る</LightButton>
          <DarkButton onClick={() => handleClick()}>
            上記に同意して次へ
          </DarkButton>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default memo(SignUpPayment);
