import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { PlanMakingContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";
import { useResultsInputInitialData } from "../../hooks/initialData";

export const ResultsInput = () => {
  const resultsInputCalcState = useResultsInputInitialData();

  const url = pageConstant.RESULTS_INPUT;

  if (!resultsInputCalcState) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <SubHeader title="実績（年次）" />
      <UnitChange />
      <PlanMakingContainer calcState={resultsInputCalcState} url={url} />
    </React.Fragment>
  );
};
