import { DocumentData } from "firebase/firestore";
import { add, divide, subtract, sum } from "mathjs";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import {
  arrayRatioCalc,
  calcByItemForPlansResultMonthly,
  totalCalc,
  totalCalcByItem,
  totalRatioCalc,
} from "../../utils/calc";

export const useResultsOfTheCurrentTermInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    depreciation_cost_data,
    raw_material_inventories_at_the_end_of_the_period,
    end_inventories_data,
    depreciation_cost_of_cost_of_sales,
    beginning_inventories_data,
    cost_of_sales_except_beginning_and_end_data,
    interest_expenses,
    non_cash_out,
  } = useSubjectItemsInitialData();

  const field = pageConstant.PLANS_INPUT_MONTHLY;

  const totalCalcMemo = useCallback(
    (state: DocumentData[]) =>
      totalCalc({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers, field]
  );

  try {
    // 項目別売上高合計
    const netSalesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: net_sales,
    });

    // 項目別売上原価合計
    const costOfSalesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: cost_of_sales,
    });

    // 項目別人件費合計
    const sellingAndAdministrativeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: selling_and_administrative,
    });

    // 項目別販売費及び一般管理費合計
    const personalCostTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: personal_cost,
    });

    // 項目別営業外収益合計
    const nonOperatingIncomeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: non_operating_income,
    });

    // 項目別営業外費用合計
    const nonOperatingExpensesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: non_operating_expenses,
    });

    // 項目別特別利益合計
    const specialBenefitsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_benefits,
    });

    // 項目別特別損失合計
    const specialLossesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_losses,
    });

    // 項目別法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });

    // 項目別材料費合計
    const materialFeeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: material_fee,
    });

    // 項目別労務費合計
    const laborCostsTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: labor_costs,
    });

    // 項目別外注加工費合計
    const externalInjectionProcessingFeeTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: external_injection_processing_fee,
    });

    // 項目別経費合計
    const expensesTotalByItem = totalCalcByItem({
      headers: headers[field],
      field: field,
      state: expenses,
    });

    // 項目別期首仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem =
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });

    // 項目別期末仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem =
      totalCalcByItem({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });

    // ゼロの配列
    const zeroArray: number[] = [];
    headers[field].map(() => {
      zeroArray.push(0);
    });

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCost: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: depreciation_cost_data,
    });

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });
    // 期末材料棚卸高
    const rawMaterialInventoriesAtTheEndOfThePeriodT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: raw_material_inventories_at_the_end_of_the_period,
    });

    // 期首仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodT: number[] =
      totalCalc({
        headers: headers[field],
        field: field,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });
    // 期末仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheEndOfThePeriodT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: inventory_of_work_in_process_at_the_end_of_the_period,
    });

    // 売上高合計
    const netSalesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: net_sales,
    });

    const netSalesRatio: number[] = totalRatioCalc(netSalesTotal);
    // 期末製品棚卸高
    const endInventoriesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: end_inventories_data,
    });
    // 原価合計
    const costOfSalesTotal: number[] = subtract(
      subtract(
        totalCalc({
          headers: headers[field],
          field: field,
          state: cost_of_sales,
        }),
        endInventoriesTotal
      ),
      endInventoriesTotal
    );
    const costOfSalesRatio: number[] = arrayRatioCalc({
      numerator: costOfSalesTotal,
      denominator: netSalesTotal,
    });
    // 売上総利益
    const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);
    const grossMarginRatio: number[] = arrayRatioCalc({
      numerator: grossMargin,
      denominator: netSalesTotal,
    });
    const personalCostTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: personal_cost,
    });
    const personalCostRatio: number[] = arrayRatioCalc({
      numerator: personalCostTotal,
      denominator: netSalesTotal,
    });
    const sellingAndAdministrativeTotal: number[] = add(
      personalCostTotal,
      totalCalc({
        headers: headers[field],
        field: field,
        state: selling_and_administrative,
      })
    );
    const sellingAndAdministrativeRatio: number[] = arrayRatioCalc({
      numerator: sellingAndAdministrativeTotal,
      denominator: netSalesTotal,
    });
    // 営業利益
    const operatingIncome: number[] = subtract(
      grossMargin,
      sellingAndAdministrativeTotal
    );
    const operatingIncomeRatio: number[] = arrayRatioCalc({
      numerator: operatingIncome,
      denominator: netSalesTotal,
    });
    // 償却前営業利益
    const operatingProfitsBeforeAmortization: number[] = add(
      operatingIncome,
      depreciationCost
    );
    const operatingProfitsBeforeAmortizationRatio: number[] = arrayRatioCalc({
      numerator: operatingProfitsBeforeAmortization,
      denominator: netSalesTotal,
    });
    const nonOperatingIncomeTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_income,
    });
    const nonOperatingExpensesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_operating_expenses,
    });
    // 経常利益
    const currentEarnings: number[] = subtract(
      add(operatingIncome, nonOperatingIncomeTotal),
      nonOperatingExpensesTotal
    );
    const currentEarningsRatio: number[] = arrayRatioCalc({
      numerator: currentEarnings,
      denominator: netSalesTotal,
    });
    // 償却前経常利益
    const ebitda: number[] = add(currentEarnings, depreciationCost);
    const ebitdaRatio = arrayRatioCalc({
      numerator: ebitda,
      denominator: netSalesTotal,
    });
    const specialBenefitsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_benefits,
    });
    const specialLossesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: special_losses,
    });
    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebit: number[] = subtract(
      add(currentEarnings, specialBenefitsTotal),
      specialLossesTotal
    );
    const ebitRatio: number[] = arrayRatioCalc({
      numerator: ebit,
      denominator: netSalesTotal,
    });
    // 当期純利益
    const netIncome: number[] = subtract(
      ebit,
      corporateInhabitantAndEnterpriseTaxesT
    );
    const netIncomeRatio: number[] = arrayRatioCalc({
      numerator: netIncome,
      denominator: netSalesTotal,
    });
    const netIncomeCount = 12 - netIncome.filter((i) => i === 0).length;

    // 原価
    // 材料費合計 期首材料棚卸高+その他材料費-期末材料棚卸高
    const materialFeeTotal: number[] = subtract(
      totalCalc({
        headers: headers[field],
        field: field,
        state: material_fee,
      }),
      add(
        rawMaterialInventoriesAtTheEndOfThePeriodT,
        rawMaterialInventoriesAtTheEndOfThePeriodT
      )
    );
    // 労務費合計
    const laborCostsTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: labor_costs,
    });
    // 外注加工費合計
    const externalInjectionProcessingFeeTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: external_injection_processing_fee,
    });
    // 原価の経費にある減価償却費
    const depreciationCostOfSalesT: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: depreciation_cost_of_cost_of_sales,
    });
    // 経費合計
    const expensesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: expenses,
    });
    // 当期製造費用
    const grossManufacturingCostForThePeriod: number[] = add(
      materialFeeTotal,
      add(
        laborCostsTotal,
        add(externalInjectionProcessingFeeTotal, expensesTotal)
      )
    );
    // 当期製品製造原価
    const CostOfProductsManufactured: number[] = subtract(
      add(
        grossManufacturingCostForThePeriod,

        inventoryOfWorkInProcessAtTheBeginningOfThePeriodT
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodT
    );

    // 期首製品棚卸高
    const beginningInventoriesTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: beginning_inventories_data,
    });

    // 売上原価合計（期首期末以外）
    const costOfSalesExceptBeginningAndEndTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: cost_of_sales_except_beginning_and_end_data,
    });

    // 当期製造費用比率
    const grossManufacturingCostForThePeriodRatio: number[] = arrayRatioCalc({
      numerator: grossManufacturingCostForThePeriod,
      denominator: grossManufacturingCostForThePeriod,
    });
    const grossManufacturingCostForThePeriodTotalRatio: number = divide(
      sum(grossManufacturingCostForThePeriod),
      sum(grossManufacturingCostForThePeriod)
    ) as number;
    // 材料費当期製造費用比率
    const materialFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: materialFeeTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    const materialFeeTotalTotalRatio: number = divide(
      sum(materialFeeTotal),
      sum(grossManufacturingCostForThePeriod)
    ) as number;
    // 労務費当期製造費用比率
    const laborCostsTotalRatio: number[] = arrayRatioCalc({
      numerator: laborCostsTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    const laborCostsTotalTotalRatio: number = divide(
      sum(laborCostsTotal),
      sum(grossManufacturingCostForThePeriod)
    ) as number;
    // 外注加工費当期製造費用比率
    const externalInjectionProcessingFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: externalInjectionProcessingFeeTotal,
      denominator: grossManufacturingCostForThePeriod,
    });

    const externalInjectionProcessingFeeTotalTotalRatio: number = divide(
      sum(externalInjectionProcessingFeeTotal),
      sum(grossManufacturingCostForThePeriod)
    ) as number;
    // 経費当期製造費用比率
    const expensesTotalRatio: number[] = arrayRatioCalc({
      numerator: expensesTotal,
      denominator: grossManufacturingCostForThePeriod,
    });
    const expensesTotalTotalRatio: number = divide(
      sum(expensesTotal),
      sum(grossManufacturingCostForThePeriod)
    ) as number;
    // 売上高材料費比率
    const netSalesMaterialFeeTotalRatio: number[] = arrayRatioCalc({
      numerator: materialFeeTotal,
      denominator: netSalesTotal,
    });
    const netSalesMaterialFeeYearlyTotalRatio: number = divide(
      sum(materialFeeTotal),
      sum(netSalesTotal)
    ) as number;
    // 売上高労務費比率
    const netSalesLaborCostsTotalRatio: number[] = arrayRatioCalc({
      numerator: laborCostsTotal,
      denominator: netSalesTotal,
    });
    const netSalesLaborCostsTotalYearlyTotalRatio: number = divide(
      sum(laborCostsTotal),
      sum(netSalesTotal)
    ) as number;
    // 売上高外注費比率
    const netSalesExternalInjectionProcessingFeeTotalRatio: number[] =
      arrayRatioCalc({
        numerator: externalInjectionProcessingFeeTotal,
        denominator: netSalesTotal,
      });
    const netSalesExternalInjectionProcessingFeeTotalYearlyTotalRatio: number =
      divide(
        sum(externalInjectionProcessingFeeTotal),
        sum(netSalesTotal)
      ) as number;
    // 売上高経費比率
    const netSalesExpensesTotalRatio: number[] = arrayRatioCalc({
      numerator: expensesTotal,
      denominator: netSalesTotal,
    });
    const netSalesExpensesTotalYearlyTotalRatio: number = divide(
      sum(expensesTotal),
      sum(netSalesTotal)
    ) as number;

    // 支払利息
    const interestExpensesT: number[] = totalCalcMemo(interest_expenses);

    // キャッシュアウトしない費用
    const nonCashOutTotal: number[] = totalCalc({
      headers: headers[field],
      field: field,
      state: non_cash_out,
    });

    // 簡易CF
    const simpleCfRow1: number[] = add(
      currentEarnings,
      add(depreciationCost, nonCashOutTotal)
    );
    const simpleCfRow2: number[] = add(
      netIncome, // 当期純利益
      add(depreciationCost, nonCashOutTotal)
    );

    // 粗付加価値額
    const simpleCfRow3: number[] = add(
      ebit, // 税引き前当期純利益
      add(
        personalCostTotal, // 人件費合計
        add(
          laborCostsTotal, // 労務費合計
          add(
            depreciationCost, // 減価償却費
            add(
              nonCashOutTotal, // キャッシュアウトしない費用)
              interestExpensesT // 支払利息
            )
          )
        )
      )
    );

    // 売上構成比
    const netSalesTotalByItemRatio = calcByItemForPlansResultMonthly({
      headers: headers[field],
      field: field,
      state: net_sales,
      netSalesTotalByItem: netSalesTotalByItem,
    });

    // 売上原価（資金繰り予定表で使用）
    const paymentOnPurchaseCurrentTotal = totalCalc({
      headers: headers[field],
      field: field,
      state: cost_of_sales_except_beginning_and_end_data,
    });

    const resultsOfTheCurrentTermCalcState = {
      net_sales_total_by_item: netSalesTotalByItem,
      cost_of_sales_total_by_item: costOfSalesTotalByItem,
      selling_and_administrative_total_by_item:
        sellingAndAdministrativeTotalByItem,
      personal_cost_total_by_item: personalCostTotalByItem,
      non_operating_income_total_by_item: nonOperatingIncomeTotalByItem,
      non_operating_expenses_total_by_item: nonOperatingExpensesTotalByItem,
      special_benefits_total_by_item: specialBenefitsTotalByItem,
      special_losses_total_by_item: specialLossesTotalByItem,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item:
        corporateInhabitantAndEnterpriseTaxesTotalByItem,
      material_fee_total_by_item: materialFeeTotalByItem,
      labor_costs_total_by_item: laborCostsTotalByItem,
      external_injection_processing_fee_total_by_item:
        externalInjectionProcessingFeeTotalByItem,
      expenses_total_by_item: expensesTotalByItem,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItem,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItem,
      zero_array: zeroArray,
      net_sales_total: netSalesTotal,
      net_sales_ratio: netSalesRatio,
      cost_of_sales_total: costOfSalesTotal,
      cost_of_sales_ratio: costOfSalesRatio,
      gross_margin: grossMargin,
      gross_margin_ratio: grossMarginRatio,
      personal_cost_total: personalCostTotal,
      personal_cost_ratio: personalCostRatio,
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      selling_and_administrative_ratio: sellingAndAdministrativeRatio,
      operating_income: operatingIncome,
      operating_income_ratio: operatingIncomeRatio,
      operating_profits_before_amortization: operatingProfitsBeforeAmortization,
      operating_profits_before_amortization_ratio:
        operatingProfitsBeforeAmortizationRatio,
      non_operating_income_total: nonOperatingIncomeTotal,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      current_earnings: currentEarnings,
      current_earnings_ratio: currentEarningsRatio,
      ebitda: ebitda,
      ebitda_ratio: ebitdaRatio,
      special_benefits_total: specialBenefitsTotal,
      special_losses_total: specialLossesTotal,
      ebit: ebit,
      ebit_ratio: ebitRatio,
      net_income: netIncome,
      net_income_ratio: netIncomeRatio,
      simple_cf_row1: simpleCfRow1,
      simple_cf_row2: simpleCfRow2,
      simple_cf_row3: simpleCfRow3,
      material_fee_total: materialFeeTotal,
      labor_costs_total: laborCostsTotal,
      external_injection_processing_fee_total:
        externalInjectionProcessingFeeTotal,
      expenses_total: expensesTotal,
      gross_manufacturing_cost_for_the_period:
        grossManufacturingCostForThePeriod,
      cost_of_products_manufactured: CostOfProductsManufactured,
      inventory_of_work_in_process_at_the_beginning_of_the_period_t:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodT,
      inventory_of_work_in_process_at_the_end_of_the_period_t:
        inventoryOfWorkInProcessAtTheEndOfThePeriodT,
      beginning_inventories_total: beginningInventoriesTotal,
      end_inventories_total: endInventoriesTotal,
      cost_of_sales_except_beginning_and_end_total:
        costOfSalesExceptBeginningAndEndTotal,
      depreciation_cost: depreciationCost,
      material_fee_total_ratio: materialFeeTotalRatio,
      labor_costs_total_ratio: laborCostsTotalRatio,
      external_injection_processing_fee_total_ratio:
        externalInjectionProcessingFeeTotalRatio,
      expenses_total_ratio: expensesTotalRatio,
      net_sales_material_fee_total_ratio: netSalesMaterialFeeTotalRatio,
      gross_manufacturing_cost_for_the_period_ratio:
        grossManufacturingCostForThePeriodRatio,
      net_sales_labor_costs_total_ratio: netSalesLaborCostsTotalRatio,
      net_sales_external_injection_processing_fee_total_ratio:
        netSalesExternalInjectionProcessingFeeTotalRatio,
      net_sales_expenses_total_ratio: netSalesExpensesTotalRatio,
      raw_material_inventories_at_the_end_of_the_period:
        rawMaterialInventoriesAtTheEndOfThePeriodT,
      depreciation_cost_of_sales_t: depreciationCostOfSalesT,
      net_income_count: netIncomeCount,
      gross_manufacturing_cost_for_the_period_total_ratio:
        grossManufacturingCostForThePeriodTotalRatio,
      material_fee_total_total_ratio: materialFeeTotalTotalRatio,
      labor_costs_total_total_ratio: laborCostsTotalTotalRatio,
      external_injection_processing_fee_total_total_ratio:
        externalInjectionProcessingFeeTotalTotalRatio,
      expenses_total_total_ratio: expensesTotalTotalRatio,
      net_sales_material_fee_yearly_total_ratio:
        netSalesMaterialFeeYearlyTotalRatio,
      net_sales_labor_costs_total_yearly_total_ratio:
        netSalesLaborCostsTotalYearlyTotalRatio,
      net_sales_external_injection_processing_fee_total_yearly_total_ratio:
        netSalesExternalInjectionProcessingFeeTotalYearlyTotalRatio,
      net_sales_expenses_total_yearly_total_ratio:
        netSalesExpensesTotalYearlyTotalRatio,
      net_sales_total_by_item_ratio: netSalesTotalByItemRatio,
      payment_on_purchase_current_total: paymentOnPurchaseCurrentTotal,
    };

    return resultsOfTheCurrentTermCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
