import { DocumentData } from "firebase/firestore";
import { useMemo } from "react";
import { sortData } from "../utils";

// 対象のデータをソートするカスタムフック
export const useSortedData = (item: string, tableData: DocumentData[]) => {
  const sortedData = useMemo(() => {
    return sortData(item, tableData);
  }, [item, tableData]);
  return sortedData;
};
