import { add, dotDivide, dotMultiply, subtract, sum } from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import { comparisonsValidData, latestData } from "../../utils";
import {
  arrayRatioCalc,
  singleRatioCalc,
  totalCalc,
  totalCalcByItem,
  totalCalcByItemForComparisons,
  totalCalcForComparisons,
} from "../../utils/calc";

export const useCurrentAndPreviousYearComparisonsInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
    special_corporate_inhabitant_and_enterprise_taxeslosses,
    material_fee,
    labor_costs,
    external_injection_processing_fee,
    expenses,
    inventory_of_work_in_process_at_the_beginning_of_the_period,
    inventory_of_work_in_process_at_the_end_of_the_period,
    depreciation_cost_data,
    raw_material_inventories_at_the_end_of_the_period,
    end_inventories_data,
    depreciation_cost_of_cost_of_sales,
    beginning_inventories_data,
    interest_expenses,
    depreciation_cost_of_sales_t_fixed,
    non_cash_out,
  } = useSubjectItemsInitialData();

  const previousField = pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD;
  const currentField = pageConstant.PLANS_INPUT_MONTHLY;
  const resultsField = pageConstant.RESULTS_INPUT;

  try {
    // 項目別売上高合計
    const netSalesTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
      termCount: headers.results_of_the_current_term_count,
    });

    const netSalesTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: net_sales,
      termCount: headers.results_of_the_current_term_count,
    });
    const netSalesTotalByItemSubtract = subtract(
      netSalesTotalByItemForCurrent,
      netSalesTotalByItemForPrevious
    );
    const netSalesTotalByItemRatio = arrayRatioCalc({
      numerator: netSalesTotalByItemForCurrent,
      denominator: netSalesTotalByItemForPrevious,
    });

    const netSalesLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: net_sales,
    });
    const netSalesTotalByItemReference = dotMultiply(
      netSalesLatest,
      dotDivide(netSalesTotalByItemForCurrent, netSalesTotalByItemForPrevious)
    ) as number[];

    // 項目別売上原価合計
    const costOfSalesTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: cost_of_sales,
      termCount: headers.results_of_the_current_term_count,
    });
    const costOfSalesTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: cost_of_sales,
      termCount: headers.results_of_the_current_term_count,
      isCostOfSales: true,
    });
    const costOfSalesTotalByItemSubtract = subtract(
      costOfSalesTotalByItemForCurrent,
      costOfSalesTotalByItemForPrevious
    );
    const costOfSalesTotalByItemRatio = arrayRatioCalc({
      numerator: costOfSalesTotalByItemForCurrent,
      denominator: costOfSalesTotalByItemForPrevious,
    });

    const costOfSalesLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales,
    });

    costOfSalesLatest[costOfSalesLatest.length - 1] = costOfSalesLatest[0];

    const costOfSalesTotalByItemReference = dotMultiply(
      costOfSalesLatest,
      comparisonsValidData({
        numerator: costOfSalesTotalByItemForCurrent,
        denominator: costOfSalesTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別人件費合計
    const personalCostTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: personal_cost,
      termCount: headers.results_of_the_current_term_count,
    });
    const personalCostTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: personal_cost,
      termCount: headers.results_of_the_current_term_count,
    });
    const personalCostTotalByItemSubtract = subtract(
      personalCostTotalByItemForCurrent,
      personalCostTotalByItemForPrevious
    );
    const personalCostTotalByItemRatio = arrayRatioCalc({
      numerator: personalCostTotalByItemForCurrent,
      denominator: personalCostTotalByItemForPrevious,
    });
    const personalCostLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: personal_cost,
    });

    const personalCostTotalByItemReference = dotMultiply(
      personalCostLatest,
      comparisonsValidData({
        numerator: personalCostTotalByItemForCurrent,
        denominator: personalCostTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別販売費及び一般管理費合計
    const sellingAndAdministrativeTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: selling_and_administrative,
        termCount: headers.results_of_the_current_term_count,
      });
    const sellingAndAdministrativeTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: selling_and_administrative,
        termCount: headers.results_of_the_current_term_count,
      });
    const sellingAndAdministrativeTotalByItemSubtract = subtract(
      sellingAndAdministrativeTotalByItemForCurrent,
      sellingAndAdministrativeTotalByItemForPrevious
    );
    const sellingAndAdministrativeTotalByItemRatio = arrayRatioCalc({
      numerator: sellingAndAdministrativeTotalByItemForCurrent,
      denominator: sellingAndAdministrativeTotalByItemForPrevious,
    });
    const sellingAndAdministrativeLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: selling_and_administrative,
    });
    const sellingAndAdministrativeTotalByItemReference = dotMultiply(
      sellingAndAdministrativeLatest,
      comparisonsValidData({
        numerator: sellingAndAdministrativeTotalByItemForCurrent,
        denominator: sellingAndAdministrativeTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別営業外収益合計
    const nonOperatingIncomeTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: non_operating_income,
        termCount: headers.results_of_the_current_term_count,
      });
    const nonOperatingIncomeTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: non_operating_income,
        termCount: headers.results_of_the_current_term_count,
      });
    const nonOperatingIncomeTotalByItemSubtract = subtract(
      nonOperatingIncomeTotalByItemForCurrent,
      nonOperatingIncomeTotalByItemForPrevious
    );
    const nonOperatingIncomeTotalByItemRatio = arrayRatioCalc({
      numerator: nonOperatingIncomeTotalByItemForCurrent,
      denominator: nonOperatingIncomeTotalByItemForPrevious,
    });
    const nonOperatingIncomeTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_income,
    });
    const nonOperatingIncomeTotalByItemReference = dotMultiply(
      nonOperatingIncomeTotalLatest,
      comparisonsValidData({
        numerator: nonOperatingIncomeTotalByItemForCurrent,
        denominator: nonOperatingIncomeTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別営業外費用合計
    const nonOperatingExpensesTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: non_operating_expenses,
        termCount: headers.results_of_the_current_term_count,
      });
    const nonOperatingExpensesTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: non_operating_expenses,
        termCount: headers.results_of_the_current_term_count,
      });
    const nonOperatingExpensesTotalByItemSubtract = subtract(
      nonOperatingExpensesTotalByItemForCurrent,
      nonOperatingExpensesTotalByItemForPrevious
    );
    const nonOperatingExpensesTotalByItemRatio = arrayRatioCalc({
      numerator: nonOperatingExpensesTotalByItemForCurrent,
      denominator: nonOperatingExpensesTotalByItemForPrevious,
    });
    const nonOperatingExpensesTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_expenses,
    });
    const nonOperatingExpensesTotalByItemReference = dotMultiply(
      nonOperatingExpensesTotalLatest,
      comparisonsValidData({
        numerator: nonOperatingExpensesTotalByItemForCurrent,
        denominator: nonOperatingExpensesTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別特別利益合計
    const specialBenefitsTotalByItemForPrevious = totalCalcByItemForComparisons(
      {
        headers: headers[previousField],
        field: previousField,
        state: special_benefits,
        termCount: headers.results_of_the_current_term_count,
      }
    );
    const specialBenefitsTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: special_benefits,
      termCount: headers.results_of_the_current_term_count,
    });
    const specialBenefitsTotalByItemSubtract = subtract(
      specialBenefitsTotalByItemForCurrent,
      specialBenefitsTotalByItemForPrevious
    );
    const specialBenefitsTotalByItemRatio = arrayRatioCalc({
      numerator: specialBenefitsTotalByItemForCurrent,
      denominator: specialBenefitsTotalByItemForPrevious,
    });
    const specialBenefitsTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: special_benefits,
    });
    const specialBenefitsTotalByItemReference = dotMultiply(
      specialBenefitsTotalLatest,
      comparisonsValidData({
        numerator: specialBenefitsTotalByItemForCurrent,
        denominator: specialBenefitsTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別特別損失合計
    const specialLossesTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: special_losses,
      termCount: headers.results_of_the_current_term_count,
    });
    const specialLossesTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: special_losses,
      termCount: headers.results_of_the_current_term_count,
    });
    const specialLossesTotalByItemSubtract = subtract(
      specialLossesTotalByItemForCurrent,
      specialLossesTotalByItemForPrevious
    );
    const specialLossesTotalByItemRatio = arrayRatioCalc({
      numerator: specialLossesTotalByItemForCurrent,
      denominator: specialLossesTotalByItemForPrevious,
    });
    const specialLossesTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: special_losses,
    });
    const specialLossesTotalByItemReference = dotMultiply(
      specialLossesTotalLatest,
      comparisonsValidData({
        numerator: specialLossesTotalByItemForCurrent,
        denominator: specialLossesTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
        termCount: headers.results_of_the_current_term_count,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
        termCount: headers.results_of_the_current_term_count,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalByItemSubtract = subtract(
      corporateInhabitantAndEnterpriseTaxesTotalByItemForCurrent,
      corporateInhabitantAndEnterpriseTaxesTotalByItemForPrevious
    );
    const corporateInhabitantAndEnterpriseTaxesTotalByItemRatio =
      arrayRatioCalc({
        numerator: corporateInhabitantAndEnterpriseTaxesTotalByItemForCurrent,
        denominator:
          corporateInhabitantAndEnterpriseTaxesTotalByItemForPrevious,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalLatest: number[] =
      latestData({
        headers: headers[resultsField],
        field: resultsField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
      });
    const corporateInhabitantAndEnterpriseTaxesTotalByItemReference =
      dotMultiply(
        corporateInhabitantAndEnterpriseTaxesTotalLatest,
        comparisonsValidData({
          numerator: corporateInhabitantAndEnterpriseTaxesTotalByItemForCurrent,
          denominator:
            corporateInhabitantAndEnterpriseTaxesTotalByItemForPrevious,
        })
      ) as number[];

    // 項目別材料費合計
    const materialFeeTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: material_fee,
      termCount: headers.results_of_the_current_term_count,
      isPrevious: true,
    });

    const materialFeeTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: material_fee,
      termCount: headers.results_of_the_current_term_count,
    });
    materialFeeTotalByItemForCurrent[0] = 0;
    materialFeeTotalByItemForCurrent[
      materialFeeTotalByItemForCurrent.length - 1
    ] = 0;

    const materialFeeTotalByItemSubtract = subtract(
      materialFeeTotalByItemForCurrent,
      materialFeeTotalByItemForPrevious
    );
    const materialFeeTotalByItemRatio = arrayRatioCalc({
      numerator: materialFeeTotalByItemForCurrent,
      denominator: materialFeeTotalByItemForPrevious,
    });
    const materialFeeTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: material_fee,
    });
    const materialFeeTotalByItemReference = dotMultiply(
      materialFeeTotalLatest,
      comparisonsValidData({
        numerator: materialFeeTotalByItemForCurrent,
        denominator: materialFeeTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別労務費合計
    const laborCostsTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: labor_costs,
      termCount: headers.results_of_the_current_term_count,
    });
    const laborCostsTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: labor_costs,
      termCount: headers.results_of_the_current_term_count,
    });
    const laborCostsTotalByItemSubtract = subtract(
      laborCostsTotalByItemForCurrent,
      laborCostsTotalByItemForPrevious
    );
    const laborCostsTotalByItemRatio = arrayRatioCalc({
      numerator: laborCostsTotalByItemForCurrent,
      denominator: laborCostsTotalByItemForPrevious,
    });
    const laborCostsTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: labor_costs,
    });
    const laborCostsTotalByItemReference = dotMultiply(
      laborCostsTotalLatest,
      comparisonsValidData({
        numerator: laborCostsTotalByItemForCurrent,
        denominator: laborCostsTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別外注加工費合計
    const externalInjectionProcessingFeeTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: external_injection_processing_fee,
        termCount: headers.results_of_the_current_term_count,
      });
    const externalInjectionProcessingFeeTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: external_injection_processing_fee,
        termCount: headers.results_of_the_current_term_count,
      });
    const externalInjectionProcessingFeeTotalByItemSubtract = subtract(
      externalInjectionProcessingFeeTotalByItemForCurrent,
      externalInjectionProcessingFeeTotalByItemForPrevious
    );
    const externalInjectionProcessingFeeTotalByItemRatio = arrayRatioCalc({
      numerator: externalInjectionProcessingFeeTotalByItemForCurrent,
      denominator: externalInjectionProcessingFeeTotalByItemForPrevious,
    });
    const externalInjectionProcessingFeeTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: external_injection_processing_fee,
    });
    const externalInjectionProcessingFeeTotalByItemReference = dotMultiply(
      externalInjectionProcessingFeeTotalLatest,
      comparisonsValidData({
        numerator: externalInjectionProcessingFeeTotalByItemForCurrent,
        denominator: externalInjectionProcessingFeeTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別経費合計
    const expensesTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: expenses,
      termCount: headers.results_of_the_current_term_count,
    });
    const expensesTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: expenses,
      termCount: headers.results_of_the_current_term_count,
    });
    const expensesTotalByItemSubtract = subtract(
      expensesTotalByItemForCurrent,
      expensesTotalByItemForPrevious
    );
    const expensesTotalByItemRatio = arrayRatioCalc({
      numerator: expensesTotalByItemForCurrent,
      denominator: expensesTotalByItemForPrevious,
    });
    const expensesTotalLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: expenses,
    });
    const expensesTotalByItemReference = dotMultiply(
      expensesTotalLatest,
      comparisonsValidData({
        numerator: expensesTotalByItemForCurrent,
        denominator: expensesTotalByItemForPrevious,
      })
    ) as number[];

    // 項目別期首仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      });
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      });
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemSubtract =
      subtract(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForCurrent,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForPrevious
      );
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemRatio =
      arrayRatioCalc({
        numerator:
          inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForCurrent,
        denominator:
          inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForPrevious,
      });
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalLatest: number[] =
      latestData({
        headers: headers[resultsField],
        field: resultsField,
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      });
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemReference =
      dotMultiply(
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalLatest,
        comparisonsValidData({
          numerator:
            inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForCurrent,
          denominator:
            inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForPrevious,
        })
      ) as number[];

    // 項目別期末仕掛品棚卸高合計
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForPrevious =
      totalCalcByItemForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      });
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForCurrent =
      totalCalcByItemForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      });
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemSubtract =
      subtract(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForCurrent,
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForPrevious
      );
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemRatio =
      arrayRatioCalc({
        numerator:
          inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForCurrent,
        denominator:
          inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForPrevious,
      });
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalLatest: number[] =
      latestData({
        headers: headers[resultsField],
        field: resultsField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      });
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemReference =
      dotMultiply(
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalLatest,
        comparisonsValidData({
          numerator:
            inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForCurrent,
          denominator:
            inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForPrevious,
        })
      ) as number[];

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCost: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: depreciation_cost_data,
    });
    const depreciationCostForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: depreciation_cost_data,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const depreciationCostForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: depreciation_cost_data,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const depreciationCostReference = add(
      sellingAndAdministrativeTotalByItemReference[0],
      expensesTotalByItemReference[0]
    );

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: special_corporate_inhabitant_and_enterprise_taxeslosses,
    });
    const corporateInhabitantAndEnterpriseTaxesTForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const corporateInhabitantAndEnterpriseTaxesTForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
        termCount: headers.results_of_the_current_term_count,
      })
    );

    // 期末材料棚卸高
    const rawMaterialInventoriesAtTheEndOfThePeriodTForPrevious: number[] =
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: raw_material_inventories_at_the_end_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      });

    // 期首仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTForPrevious: number =
      sum(
        totalCalcForComparisons({
          headers: headers[previousField],
          field: previousField,
          state: inventory_of_work_in_process_at_the_beginning_of_the_period,
          termCount: headers.results_of_the_current_term_count,
        })
      );
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTForCurrent: number =
      sum(
        totalCalcForComparisons({
          headers: headers[currentField],
          field: currentField,
          state: inventory_of_work_in_process_at_the_beginning_of_the_period,
          termCount: headers.results_of_the_current_term_count,
        })
      );
    // 期末仕掛品棚卸高
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: inventory_of_work_in_process_at_the_end_of_the_period,
        termCount: headers.results_of_the_current_term_count,
      })
    );

    // 売上高合計
    const netSalesTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: net_sales,
    });
    const netSalesTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: net_sales,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const netSalesTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: net_sales,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const netSalesTotalSubtract = subtract(
      netSalesTotalForCurrent,
      netSalesTotalForPrevious
    );
    const netSalesTotalRatio = singleRatioCalc({
      numerator: netSalesTotalForCurrent,
      denominator: netSalesTotalForPrevious,
    });
    const netSalesLatestResultsTotal = netSalesTotal[netSalesTotal.length - 1];
    const netSalesTotalReference = sum(netSalesTotalByItemReference);
    const netSalesTotalReferenceRatio = singleRatioCalc({
      numerator: netSalesTotalReference,
      denominator: netSalesLatestResultsTotal,
    });

    // 期首製品棚卸高
    const beginningInventoriesTotalForCurrent: number[] =
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: beginning_inventories_data,
        termCount: headers.results_of_the_current_term_count,
      });

    // 期末製品棚卸高
    const endInventoriesTotalForPrevious: number[] = totalCalcForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: end_inventories_data,
      termCount: headers.results_of_the_current_term_count,
    });
    const endInventoriesTotalForCurrent: number[] = totalCalcForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: end_inventories_data,
      termCount: headers.results_of_the_current_term_count,
    });

    // 原価合計
    const costOfSalesTotal = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales,
    });
    const costOfSalesTotalForPrevious: number = sum(
      subtract(
        subtract(
          totalCalcForComparisons({
            headers: headers[previousField],
            field: previousField,
            state: cost_of_sales,
            termCount: headers.results_of_the_current_term_count,
          }),
          endInventoriesTotalForPrevious
        ),
        endInventoriesTotalForPrevious
      )
    );
    const costOfSalesTotalForCurrent: number = sum(
      subtract(
        subtract(
          totalCalcForComparisons({
            headers: headers[currentField],
            field: currentField,
            state: cost_of_sales,
            termCount: headers.results_of_the_current_term_count,
          }),
          endInventoriesTotalForCurrent
        ),
        beginningInventoriesTotalForCurrent
      )
    );
    const costOfSalesRatioForPrevious: number = singleRatioCalc({
      numerator: costOfSalesTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const costOfSalesRatioForCurrent: number = singleRatioCalc({
      numerator: costOfSalesTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const costOfSalesTotalSubtract = subtract(
      costOfSalesTotalForCurrent,
      costOfSalesTotalForPrevious
    );
    const costOfSalesTotalRatio = singleRatioCalc({
      numerator: costOfSalesTotalForCurrent,
      denominator: costOfSalesTotalForPrevious,
    });
    const costOfSalesLatestResultsTotal =
      costOfSalesTotal[costOfSalesTotal.length - 1];
    const costOfSalesTotalReference = subtract(
      subtract(
        sum(costOfSalesTotalByItemReference),
        costOfSalesTotalByItemReference[
          costOfSalesTotalByItemReference.length - 1
        ]
      ),
      costOfSalesTotalByItemReference[
        costOfSalesTotalByItemReference.length - 1
      ]
    );

    const costOfSalesTotalReferenceRatio = singleRatioCalc({
      numerator: costOfSalesTotalReference,
      denominator: netSalesTotalReference,
    });

    // 売上総利益
    const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);
    const grossMarginForPrevious: number = subtract(
      netSalesTotalForPrevious,
      costOfSalesTotalForPrevious
    );
    const grossMarginForCurrent: number = subtract(
      netSalesTotalForCurrent,
      costOfSalesTotalForCurrent
    );
    const grossMarginSubtract = subtract(
      grossMarginForCurrent,
      grossMarginForPrevious
    );
    const grossMarginRatio = singleRatioCalc({
      numerator: grossMarginForCurrent,
      denominator: grossMarginForPrevious,
    });
    const grossMarginReference = subtract(
      netSalesTotalReference,
      costOfSalesTotalReference
    );
    const grossMarginRatioForPrevious: number = singleRatioCalc({
      numerator: grossMarginForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const grossMarginRatioForCurrent: number = singleRatioCalc({
      numerator: grossMarginForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const grossMarginReferenceRatio: number = singleRatioCalc({
      numerator: grossMarginReference,
      denominator: netSalesTotalReference,
    });
    const grossMarginLatestResults = grossMargin[grossMargin.length - 1];

    // 人件費合計
    const personalCostTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: personal_cost,
    });
    const personalCostTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: personal_cost,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const personalCostTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: personal_cost,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const personalCostTotalSubtract = subtract(
      personalCostTotalForCurrent,
      personalCostTotalForPrevious
    );
    const personalCostTotalRatio = singleRatioCalc({
      numerator: personalCostTotalForCurrent,
      denominator: personalCostTotalForPrevious,
    });
    const personalCostTotalReference = sum(personalCostTotalByItemReference);
    const personalCostTotalReferenceRatio = singleRatioCalc({
      numerator: personalCostTotalReference,
      denominator: netSalesTotalReference,
    });
    const personalCostLatestResults =
      personalCostTotal[personalCostTotal.length - 1];
    const personalCostRatioForPrevious: number = singleRatioCalc({
      numerator: personalCostTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const personalCostRatioForCurrent: number = singleRatioCalc({
      numerator: personalCostTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });

    // 販管費合計
    const sellingAndAdministrativeTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: selling_and_administrative,
    });
    const sellingAndAdministrativeTotalForPrevious: number = add(
      sum(personalCostTotalForPrevious),
      sum(sellingAndAdministrativeTotalByItemForPrevious)
    );
    const sellingAndAdministrativeTotalForCurrent: number = add(
      sum(personalCostTotalForCurrent),
      sum(sellingAndAdministrativeTotalByItemForCurrent)
    );
    const sellingAndAdministrativeTotalSubtract = subtract(
      sellingAndAdministrativeTotalForCurrent,
      sellingAndAdministrativeTotalForPrevious
    );
    const sellingAndAdministrativeTotalRatio = singleRatioCalc({
      numerator: sellingAndAdministrativeTotalForCurrent,
      denominator: sellingAndAdministrativeTotalForPrevious,
    });
    const sellingAndAdministrativeTotalReference = add(
      sum(sellingAndAdministrativeTotalByItemReference),
      personalCostTotalReference
    );
    const sellingAndAdministrativeTotalReferenceRatio = singleRatioCalc({
      numerator: sellingAndAdministrativeTotalReference,
      denominator: netSalesTotalReference,
    });
    const sellingAndAdministrativeRatioForPrevious: number = singleRatioCalc({
      numerator: sellingAndAdministrativeTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const sellingAndAdministrativeRatioForCurrent: number = singleRatioCalc({
      numerator: sellingAndAdministrativeTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const sellingAndAdministrativeTotalLatestResults =
      sellingAndAdministrativeTotal[sellingAndAdministrativeTotal.length - 1];

    // 営業利益
    const operatingIncome: number[] = subtract(
      grossMargin,
      sellingAndAdministrativeTotal
    );
    const operatingIncomeForPrevious: number = sum(
      subtract(grossMarginForPrevious, sellingAndAdministrativeTotalForPrevious)
    );
    const operatingIncomeForCurrent: number = sum(
      subtract(grossMarginForCurrent, sellingAndAdministrativeTotalForCurrent)
    );
    const operatingIncomeSubtract = subtract(
      operatingIncomeForCurrent,
      operatingIncomeForPrevious
    );
    const operatingIncomeRatio = singleRatioCalc({
      numerator: operatingIncomeForCurrent,
      denominator: operatingIncomeForPrevious,
    });
    const operatingIncomeReference = subtract(
      grossMarginReference,
      sellingAndAdministrativeTotalReference
    );
    const operatingIncomeReferenceRatio = singleRatioCalc({
      numerator: operatingIncomeReference,
      denominator: netSalesTotalReference,
    });
    const operatingIncomeLatestResults =
      operatingIncome[operatingIncome.length - 1];
    const operatingIncomeRatioForPrevious: number = singleRatioCalc({
      numerator: operatingIncomeForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const operatingIncomeRatioForCurrent: number = singleRatioCalc({
      numerator: operatingIncomeForCurrent,
      denominator: netSalesTotalForCurrent,
    });

    // 償却前営業利益
    const operatingProfitsBeforeAmortization: number[] = add(
      operatingIncome,
      depreciationCost
    );
    const operatingProfitsBeforeAmortizationForPrevious: number = add(
      operatingIncomeForPrevious,
      depreciationCostForPrevious
    ) as number;
    const operatingProfitsBeforeAmortizationForCurrent: number = add(
      operatingIncomeForCurrent,
      depreciationCostForCurrent
    ) as number;
    const operatingProfitsBeforeAmortizationSubtract = subtract(
      operatingProfitsBeforeAmortizationForCurrent,
      operatingProfitsBeforeAmortizationForPrevious
    );
    const operatingProfitsBeforeAmortizationRatio = singleRatioCalc({
      numerator: operatingProfitsBeforeAmortizationForCurrent,
      denominator: operatingProfitsBeforeAmortizationForPrevious,
    });

    const operatingProfitsBeforeAmortizationReference = add(
      operatingIncomeReference,
      depreciationCostReference
    );
    const operatingProfitsBeforeAmortizationReferenceRatio = singleRatioCalc({
      numerator: operatingProfitsBeforeAmortizationReference,
      denominator: netSalesTotalReference,
    });
    const operatingProfitsBeforeAmortizationLatestResults =
      operatingProfitsBeforeAmortization[
        operatingProfitsBeforeAmortization.length - 1
      ];
    const operatingProfitsBeforeAmortizationRatioForPrevious: number =
      singleRatioCalc({
        numerator: operatingProfitsBeforeAmortizationForPrevious,
        denominator: netSalesTotalForPrevious,
      });
    const operatingProfitsBeforeAmortizationRatioForCurrent: number =
      singleRatioCalc({
        numerator: operatingProfitsBeforeAmortizationForCurrent,
        denominator: netSalesTotalForCurrent,
      });

    // 営業外収益
    const nonOperatingIncomeTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: non_operating_income,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonOperatingIncomeTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: non_operating_income,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonOperatingIncomeTotalSubtract = subtract(
      nonOperatingIncomeTotalForCurrent,
      nonOperatingIncomeTotalForPrevious
    );
    const nonOperatingIncomeTotalRatio = singleRatioCalc({
      numerator: nonOperatingIncomeTotalForCurrent,
      denominator: nonOperatingIncomeTotalForPrevious,
    });
    const nonOperatingIncomeTotalReference = sum(
      nonOperatingIncomeTotalByItemReference
    );
    // 営業外費用
    const nonOperatingExpensesTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: non_operating_expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonOperatingExpensesTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: non_operating_expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonOperatingExpensesTotalSubtract = subtract(
      nonOperatingExpensesTotalForCurrent,
      nonOperatingExpensesTotalForPrevious
    );
    const nonOperatingExpensesTotalRatio = singleRatioCalc({
      numerator: nonOperatingExpensesTotalForCurrent,
      denominator: nonOperatingExpensesTotalForPrevious,
    });
    const nonOperatingExpensesTotalReference = sum(
      nonOperatingExpensesTotalByItemReference
    );

    const nonOperatingIncomeTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_income,
    });
    const nonOperatingExpensesTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_expenses,
    });

    // 経常利益
    const currentEarnings: number[] = subtract(
      add(operatingIncome, nonOperatingIncomeTotal),
      nonOperatingExpensesTotal
    );
    const currentEarningsForPrevious: number = sum(
      subtract(
        add(operatingIncomeForPrevious, nonOperatingIncomeTotalForPrevious),
        nonOperatingExpensesTotalForPrevious
      )
    );
    const currentEarningsForCurrent: number = sum(
      subtract(
        add(operatingIncomeForCurrent, nonOperatingIncomeTotalForCurrent),
        nonOperatingExpensesTotalForCurrent
      )
    );
    const currentEarningsSubtract = subtract(
      currentEarningsForCurrent,
      currentEarningsForPrevious
    );
    const currentEarningsRatio = singleRatioCalc({
      numerator: currentEarningsForCurrent,
      denominator: currentEarningsForPrevious,
    });
    const currentEarningsReference = subtract(
      add(operatingIncomeReference, nonOperatingIncomeTotalReference),
      nonOperatingExpensesTotalReference
    );
    const currentEarningsReferenceRatio = singleRatioCalc({
      numerator: sellingAndAdministrativeTotalReference,
      denominator: netSalesTotalReference,
    });
    const currentEarningsLatestResults =
      currentEarnings[currentEarnings.length - 1];
    const currentEarningsRatioForPrevious: number = singleRatioCalc({
      numerator: currentEarningsForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const currentEarningsRatioForCurrent: number = singleRatioCalc({
      numerator: currentEarningsForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    // 償却前経常利益
    const ebitda = add(currentEarnings, depreciationCost);
    const ebitdaForPrevious: number = sum(
      add(currentEarningsForPrevious, depreciationCostForPrevious)
    );
    const ebitdaForCurrent: number = sum(
      add(currentEarningsForCurrent, depreciationCostForCurrent)
    );
    const ebitdaSubtract = subtract(ebitdaForCurrent, ebitdaForPrevious);
    const ebitdaRatio = singleRatioCalc({
      numerator: ebitdaForCurrent,
      denominator: ebitdaForPrevious,
    });
    const ebitdaReference = add(
      currentEarningsReference,
      depreciationCostReference
    );
    const ebitdaReferenceRatio = singleRatioCalc({
      numerator: ebitdaReference,
      denominator: netSalesTotalReference,
    });
    const ebitdaLatestResults = ebitda[ebitda.length - 1];
    const ebitdaRatioForPrevious = singleRatioCalc({
      numerator: ebitdaForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const ebitdaRatioForCurrent = singleRatioCalc({
      numerator: ebitdaForCurrent,
      denominator: netSalesTotalForCurrent,
    });

    // 特別利益合計
    const specialBenefitsTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: special_benefits,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const specialBenefitsTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: special_benefits,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const specialBenefitsTotalSubtract = subtract(
      specialBenefitsTotalForCurrent,
      specialBenefitsTotalForPrevious
    );
    const specialBenefitsTotalRatio = singleRatioCalc({
      numerator: specialBenefitsTotalForCurrent,
      denominator: specialBenefitsTotalForPrevious,
    });
    const specialBenefitsTotalReference = sum(
      specialBenefitsTotalByItemReference
    );
    // 特別損失合計
    const specialLossesTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: special_losses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const specialLossesTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: special_losses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const specialLossesTotalSubtract = subtract(
      specialLossesTotalForCurrent,
      specialLossesTotalForPrevious
    );
    const specialLossesTotalRatio = singleRatioCalc({
      numerator: specialLossesTotalForCurrent,
      denominator: specialLossesTotalForPrevious,
    });
    const specialLossesTotalReference = sum(specialLossesTotalByItemReference);
    const specialBenefitsTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: special_benefits,
    });
    const specialLossesTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: special_losses,
    });
    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebit: number[] = subtract(
      add(currentEarnings, specialBenefitsTotal),
      specialLossesTotal
    );
    const ebitForPrevious: number = sum(
      subtract(
        add(currentEarningsForPrevious, specialBenefitsTotalForPrevious),
        specialLossesTotalForPrevious
      )
    );
    const ebitForCurrent: number = sum(
      subtract(
        add(currentEarningsForCurrent, specialBenefitsTotalForCurrent),
        specialLossesTotalForCurrent
      )
    );
    const ebitSubtract = subtract(ebitForCurrent, ebitForPrevious);
    const ebitRatio = singleRatioCalc({
      numerator: ebitForCurrent,
      denominator: ebitForPrevious,
    });
    const ebitReference = subtract(
      add(currentEarningsReference, specialBenefitsTotalReference),
      specialLossesTotalReference
    );
    const ebitReferenceRatio = singleRatioCalc({
      numerator: ebitReference,
      denominator: netSalesTotalReference,
    });
    const ebitLatestResults = ebit[ebit.length - 1];
    const ebitRatioForPrevious: number = singleRatioCalc({
      numerator: ebitForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const ebitRatioForCurrent: number = singleRatioCalc({
      numerator: ebitForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    // 当期純利益
    const netIncome: number[] = subtract(
      ebit,
      corporateInhabitantAndEnterpriseTaxesT
    );
    const netIncomeForPrevious: number = subtract(
      ebitForPrevious,
      corporateInhabitantAndEnterpriseTaxesTForPrevious
    ) as number;
    const netIncomeForCurrent: number = subtract(
      ebitForCurrent,
      corporateInhabitantAndEnterpriseTaxesTForCurrent
    ) as number;
    const netIncomeSubtract = subtract(
      netIncomeForCurrent,
      netIncomeForPrevious
    );
    const netIncomeRatio = singleRatioCalc({
      numerator: netIncomeForCurrent,
      denominator: netIncomeForPrevious,
    });
    const netIncomeReference = add(
      ebitReference,
      corporateInhabitantAndEnterpriseTaxesTotalByItemReference
    );
    const netIncomeReferenceRatio = singleRatioCalc({
      numerator: netIncomeReference,
      denominator: netSalesTotalReference,
    });
    const netIncomeLatestResults = netIncome[netIncome.length - 1];
    const netIncomeRatioForPrevious: number = singleRatioCalc({
      numerator: netIncomeForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const netIncomeRatioForCurrent: number = singleRatioCalc({
      numerator: netIncomeForCurrent,
      denominator: netSalesTotalForCurrent,
    });

    // 支払利息
    const interestExpensesTForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: interest_expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const interestExpensesTForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: interest_expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );

    // 原価
    // 材料費合計 期首材料棚卸高+その他材料費-期末材料棚卸高
    const materialFeeTotalForPrevious: number = sum(
      subtract(
        totalCalcForComparisons({
          headers: headers[previousField],
          field: previousField,
          state: material_fee,
          termCount: headers.results_of_the_current_term_count,
        }),
        add(
          rawMaterialInventoriesAtTheEndOfThePeriodTForPrevious,
          rawMaterialInventoriesAtTheEndOfThePeriodTForPrevious
        )
      )
    );
    const materialFeeTotalForCurrentData: number[] = totalCalcForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: material_fee,
      termCount: headers.results_of_the_current_term_count,
      materialFee: true,
    });
    materialFeeTotalForCurrentData[0] = 0;
    materialFeeTotalForCurrentData[
      materialFeeTotalForCurrentData.length - 1
    ] = 0;

    const materialFeeTotalForCurrent: number = sum(
      materialFeeTotalForCurrentData
    );

    const materialFeeTotalSubtract = subtract(
      materialFeeTotalForCurrent,
      materialFeeTotalForPrevious
    );
    const materialFeeTotalRatio = singleRatioCalc({
      numerator: materialFeeTotalForCurrent,
      denominator: materialFeeTotalForPrevious,
    });
    const materialFeeTotalReference = subtract(
      subtract(
        sum(materialFeeTotalByItemReference),
        materialFeeTotalByItemReference[
          materialFeeTotalByItemReference.length - 1
        ]
      ),
      materialFeeTotalByItemReference[
        materialFeeTotalByItemReference.length - 1
      ]
    );

    // 労務費合計
    const laborCostsTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: labor_costs,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const laborCostsTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: labor_costs,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const laborCostsTotalSubtract = subtract(
      laborCostsTotalForCurrent,
      laborCostsTotalForPrevious
    );
    const laborCostsTotalRatio = singleRatioCalc({
      numerator: laborCostsTotalForCurrent,
      denominator: laborCostsTotalForPrevious,
    });
    const laborCostsTotalReference = sum(laborCostsTotalByItemReference);

    const externalInjectionProcessingFeeTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: external_injection_processing_fee,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const externalInjectionProcessingFeeTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: external_injection_processing_fee,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const externalInjectionProcessingFeeTotalSubtract = subtract(
      externalInjectionProcessingFeeTotalForCurrent,
      externalInjectionProcessingFeeTotalForPrevious
    );
    const externalInjectionProcessingFeeTotalRatio = singleRatioCalc({
      numerator: externalInjectionProcessingFeeTotalForCurrent,
      denominator: externalInjectionProcessingFeeTotalForPrevious,
    });
    const externalInjectionProcessingFeeTotalReference = sum(
      externalInjectionProcessingFeeTotalByItemReference
    );

    // 原価の経費にある減価償却費
    const depreciationCostOfSalesTForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: depreciation_cost_of_cost_of_sales,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const depreciationCostOfSalesTForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: depreciation_cost_of_cost_of_sales,
        termCount: headers.results_of_the_current_term_count,
      })
    );

    // 経費合計
    const expensesTotalForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const expensesTotalForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[currentField],
        field: currentField,
        state: expenses,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const expensesTotalSubtract = subtract(
      expensesTotalForCurrent,
      expensesTotalForPrevious
    );
    const expensesTotalRatio = singleRatioCalc({
      numerator: expensesTotalForCurrent,
      denominator: expensesTotalForPrevious,
    });
    const expensesTotalReference = sum(expensesTotalByItemReference);

    // 当期製造費用
    const grossManufacturingCostForThePeriodForPrevious: number = add(
      materialFeeTotalForPrevious,
      add(
        laborCostsTotalForPrevious,
        add(
          externalInjectionProcessingFeeTotalForPrevious,
          expensesTotalForPrevious
        )
      )
    );
    const grossManufacturingCostForThePeriodForCurrent: number = add(
      materialFeeTotalForCurrent,
      add(
        laborCostsTotalForCurrent,
        add(
          externalInjectionProcessingFeeTotalForCurrent,
          expensesTotalForCurrent
        )
      )
    );

    const grossManufacturingCostForThePeriodSubtract = subtract(
      grossManufacturingCostForThePeriodForCurrent,
      grossManufacturingCostForThePeriodForPrevious
    );
    const grossManufacturingCostForThePeriodRatio = singleRatioCalc({
      numerator: grossManufacturingCostForThePeriodForCurrent,
      denominator: grossManufacturingCostForThePeriodForPrevious,
    });
    const grossManufacturingCostForThePeriodReference = add(
      materialFeeTotalReference,
      add(
        laborCostsTotalReference,
        add(
          externalInjectionProcessingFeeTotalReference,
          expensesTotalReference
        )
      )
    );

    // 当期製品製造原価
    const costOfProductsManufacturedForPrevious: number = subtract(
      add(
        grossManufacturingCostForThePeriodForPrevious,

        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTForPrevious
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodTForPrevious
    ) as number;

    const costOfProductsManufacturedForCurrent: number = subtract(
      add(
        grossManufacturingCostForThePeriodForCurrent,

        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTForCurrent
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodTForCurrent
    ) as number;
    const costOfProductsManufacturedSubtract = subtract(
      costOfProductsManufacturedForCurrent,
      costOfProductsManufacturedForPrevious
    );

    const costOfProductsManufacturedRatio = singleRatioCalc({
      numerator: costOfProductsManufacturedForCurrent,
      denominator: costOfProductsManufacturedForPrevious,
    });
    const costOfProductsManufacturedReference = subtract(
      add(
        grossManufacturingCostForThePeriodReference,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemReference
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemReference
    );

    // キャッシュアウトしない費用
    const nonCashOutForPrevious: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: previousField,
        state: non_cash_out,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonCashOutForCurrent: number = sum(
      totalCalcForComparisons({
        headers: headers[previousField],
        field: currentField,
        state: non_cash_out,
        termCount: headers.results_of_the_current_term_count,
      })
    );
    const nonCashOutTotalByItemForPrevious = totalCalcByItemForComparisons({
      headers: headers[previousField],
      field: previousField,
      state: non_cash_out,
      termCount: headers.results_of_the_current_term_count,
    });
    const nonCashOutTotalByItemForCurrent = totalCalcByItemForComparisons({
      headers: headers[currentField],
      field: currentField,
      state: non_cash_out,
      termCount: headers.results_of_the_current_term_count,
    });
    const nonCashOutLatest: number[] = latestData({
      headers: headers[resultsField],
      field: resultsField,
      state: non_cash_out,
    });
    const nonCashOutTotalByItemReference = dotMultiply(
      nonCashOutLatest,
      dotDivide(
        nonCashOutTotalByItemForCurrent,
        nonCashOutTotalByItemForPrevious
      )
    ) as number[];
    const nonCashOutTotalReference = sum(nonCashOutTotalByItemReference);

    // 簡易CF
    const simpleCfRow1ForPrevious: number = add(
      currentEarningsForPrevious,
      add(depreciationCostForPrevious, nonCashOutForPrevious)
    );
    // simpleCfRow2（当期純利益）+（減価償却費）
    const simpleCfRow2ForPrevious: number = add(
      netIncomeForPrevious,
      add(depreciationCostForPrevious, nonCashOutForPrevious)
    );
    // simpleCfRow3 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息
    const simpleCfRow3ForPrevious: number = add(
      ebitForPrevious, // 税引き前当期純利益
      add(
        personalCostTotalForPrevious, // 人件費合計
        add(
          laborCostsTotalForPrevious, // 労務費合計
          add(
            depreciationCostForPrevious, // 減価償却費
            add(
              nonCashOutForPrevious, // キャッシュアウトしない費用
              interestExpensesTForPrevious // 支払利息
            )
          )
        )
      )
    ) as number;

    const simpleCfRow1ForCurrent: number = add(
      currentEarningsForCurrent,
      add(depreciationCostForCurrent, nonCashOutForCurrent)
    );
    // simpleCfRow2（当期純利益）+（減価償却費）
    const simpleCfRow2ForCurrent: number = add(
      netIncomeForCurrent,
      add(depreciationCostForCurrent, nonCashOutForCurrent)
    );
    // simpleCfRow3（税引き前当期純利益）+（減価償却費）+（人件費）+（労務費） +（支払利息）
    const simpleCfRow3ForCurrent: number = add(
      ebitForCurrent, // 税引き前当期純利益
      add(
        personalCostTotalForCurrent, // 人件費合計
        add(
          laborCostsTotalForCurrent, // 労務費合計
          add(
            depreciationCostForCurrent, // 減価償却費
            add(
              nonCashOutForCurrent, // キャッシュアウトしない費用
              interestExpensesTForCurrent // 支払利息
            )
          )
        )
      )
    ) as number;

    const simpleCfRow1Subtract = subtract(
      simpleCfRow1ForCurrent,
      simpleCfRow1ForPrevious
    );
    const simpleCfRow1Ratio = singleRatioCalc({
      numerator: simpleCfRow1ForCurrent,
      denominator: simpleCfRow1ForPrevious,
    });
    const simpleCfRow1Reference: number = add(
      currentEarningsReference,
      depreciationCostReference
    );
    const simpleCfRow2Subtract = subtract(
      simpleCfRow2ForCurrent,
      simpleCfRow2ForPrevious
    );
    const simpleCfRow2Ratio = singleRatioCalc({
      numerator: simpleCfRow2ForCurrent,
      denominator: simpleCfRow2ForPrevious,
    });
    const simpleCfRow2Reference: number = add(
      netIncomeReference,
      depreciationCostReference
    );
    const simpleCfRow3Subtract = subtract(
      simpleCfRow3ForCurrent,
      simpleCfRow3ForPrevious
    );
    const simpleCfRow3Ratio = singleRatioCalc({
      numerator: simpleCfRow3ForCurrent,
      denominator: simpleCfRow3ForPrevious,
    });
    const simpleCfRow3Reference: number = add(
      // 税引き前当期純利益
      ebitReference,
      add(
        // 人件費
        personalCostTotalReference,
        add(
          // 労務費
          laborCostsTotalReference,
          add(
            // 減価償却費
            depreciationCostReference,
            add(
              nonCashOutTotalReference, // キャッシュアウトしない費用
              nonOperatingExpensesTotalByItemReference[0] // 支払利息
            )
          )
        )
      )
    ) as number;

    // 原価構成比
    // 当期製造費用比率
    const grossManufacturingCostForThePeriodRatioForPrevious: number =
      singleRatioCalc({
        numerator: grossManufacturingCostForThePeriodForPrevious,
        denominator: grossManufacturingCostForThePeriodForPrevious,
      });
    const grossManufacturingCostForThePeriodRatioForCurrent: number =
      singleRatioCalc({
        numerator: grossManufacturingCostForThePeriodForCurrent,
        denominator: grossManufacturingCostForThePeriodForCurrent,
      });
    const grossManufacturingCostForThePeriodReferenceRatio: number =
      singleRatioCalc({
        numerator: grossManufacturingCostForThePeriodReference,
        denominator: grossManufacturingCostForThePeriodReference,
      });

    // 材料費当期製造費用比率
    const materialFeeTotalRatioForPrevious: number = singleRatioCalc({
      numerator: materialFeeTotalForPrevious,
      denominator: grossManufacturingCostForThePeriodForPrevious,
    });
    const materialFeeTotalRatioForCurrent: number = singleRatioCalc({
      numerator: materialFeeTotalForCurrent,
      denominator: grossManufacturingCostForThePeriodForCurrent,
    });
    const materialFeeTotalReferenceRatio: number = singleRatioCalc({
      numerator: materialFeeTotalReference,
      denominator: grossManufacturingCostForThePeriodReference,
    });
    // 労務費当期製造費用比率
    const laborCostsTotalRatioForPrevious: number = singleRatioCalc({
      numerator: laborCostsTotalForPrevious,
      denominator: grossManufacturingCostForThePeriodForPrevious,
    });
    const laborCostsTotalRatioForCurrent: number = singleRatioCalc({
      numerator: laborCostsTotalForCurrent,
      denominator: grossManufacturingCostForThePeriodForCurrent,
    });
    const laborCostsTotalReferenceRatio: number = singleRatioCalc({
      numerator: laborCostsTotalReference,
      denominator: grossManufacturingCostForThePeriodReference,
    });
    // 外注加工費当期製造費用比率
    const externalInjectionProcessingFeeTotalRatioForPrevious: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalForPrevious,
        denominator: grossManufacturingCostForThePeriodForPrevious,
      });
    const externalInjectionProcessingFeeTotalRatioForCurrent: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalForCurrent,
        denominator: grossManufacturingCostForThePeriodForCurrent,
      });
    const externalInjectionProcessingFeeTotalReferenceRatio: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalReference,
        denominator: grossManufacturingCostForThePeriodReference,
      });

    // 経費当期製造費用比率
    const expensesTotalRatioForPrevious: number = singleRatioCalc({
      numerator: expensesTotalForPrevious,
      denominator: grossManufacturingCostForThePeriodForPrevious,
    });
    const expensesTotalRatioForCurrent: number = singleRatioCalc({
      numerator: expensesTotalForCurrent,
      denominator: grossManufacturingCostForThePeriodForCurrent,
    });
    const expensesTotalReferenceRatio: number = singleRatioCalc({
      numerator: expensesTotalReference,
      denominator: grossManufacturingCostForThePeriodReference,
    });
    // 売上高材料費比率
    const netSalesMaterialFeeTotalRatioForPrevious: number = singleRatioCalc({
      numerator: materialFeeTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const netSalesMaterialFeeTotalRatioForCurrent: number = singleRatioCalc({
      numerator: materialFeeTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const netSalesMaterialFeeTotalReferenceRatio: number = singleRatioCalc({
      numerator: materialFeeTotalReference,
      denominator: netSalesTotalReference,
    });

    // 売上高労務費比率
    const netSalesLaborCostsTotalRatioForPrevious: number = singleRatioCalc({
      numerator: laborCostsTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const netSalesLaborCostsTotalRatioForCurrent: number = singleRatioCalc({
      numerator: laborCostsTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const netSalesLaborCostsTotalReferenceRatio: number = singleRatioCalc({
      numerator: laborCostsTotalReference,
      denominator: netSalesTotalReference,
    });
    // 売上高外注費比率
    const netSalesExternalInjectionProcessingFeeTotalRatioForPrevious: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalForPrevious,
        denominator: netSalesTotalForPrevious,
      });
    const netSalesExternalInjectionProcessingFeeTotalRatioForCurrent: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalForCurrent,
        denominator: netSalesTotalForCurrent,
      });
    const netSalesExternalInjectionProcessingFeeTotalReferenceRatio: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalReference,
        denominator: netSalesTotalReference,
      });
    // 売上高経費比率
    const netSalesExpensesTotalRatioForPrevious: number = singleRatioCalc({
      numerator: expensesTotalForPrevious,
      denominator: netSalesTotalForPrevious,
    });
    const netSalesExpensesTotalRatioForCurrent: number = singleRatioCalc({
      numerator: expensesTotalForCurrent,
      denominator: netSalesTotalForCurrent,
    });
    const netSalesExpensesTotalReferenceRatio: number = singleRatioCalc({
      numerator: expensesTotalReference,
      denominator: netSalesTotalReference,
    });

    // 今期直近までと前期の比較ページの売上高修正後
    const netSalesTotalFixed: number = totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: net_sales,
    })[0];

    const netSalesTotalRatioFixed = isNaN(netSalesTotalFixed)
      ? 0
      : singleRatioCalc({
          numerator: netSalesTotalFixed,
          denominator: netSalesLatestResultsTotal,
        });

    // 今期直近までと前期の比較ページの売上原価修正後
    const costOfSalesTotalFixedData = totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: cost_of_sales,
    })[0];

    const endInventoriesFixedData = totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: end_inventories_data,
    })[0];

    const costOfSalesTotalFixed: number = isNaN(endInventoriesFixedData)
      ? 0
      : subtract(
          subtract(costOfSalesTotalFixedData, endInventoriesFixedData),
          endInventoriesFixedData
        );
    const costOfSalesTotalRatioFixed = singleRatioCalc({
      numerator: costOfSalesTotalFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの売上総利益修正後
    const grossMarginFixed = subtract(
      netSalesTotalFixed,
      costOfSalesTotalFixed
    );
    const grossMarginRatioFixed = singleRatioCalc({
      numerator: grossMarginFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの人件費修正後
    const personalCostTotalFixed: number = totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: personal_cost,
    })[0];
    const personalCostTotalRatioFixed = isNaN(personalCostTotalFixed)
      ? 0
      : singleRatioCalc({
          numerator: personalCostTotalFixed,
          denominator: netSalesTotalFixed,
        });

    // 今期直近までと前期の比較ページの販管費修正後
    const sellingAndAdministrativeTotalFixed: number =
      personalCostTotalFixed +
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: selling_and_administrative,
      })[0];
    const sellingAndAdministrativeTotalRatioFixed = isNaN(
      sellingAndAdministrativeTotalFixed
    )
      ? 0
      : singleRatioCalc({
          numerator: sellingAndAdministrativeTotalFixed,
          denominator: netSalesTotalFixed,
        });

    // 今期直近までと前期の比較ページの営業利益修正後
    const operatingIncomeFixed =
      grossMarginFixed - sellingAndAdministrativeTotalFixed;
    const operatingIncomeRatioFixed = singleRatioCalc({
      numerator: operatingIncomeFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの償却前営業利益修正後
    const depreciationCostFixedData = totalCalc({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: depreciation_cost_data,
    });
    const operatingProfitsBeforeAmortizationFixed = isNaN(
      depreciationCostFixedData[0]
    )
      ? 0
      : operatingIncomeFixed + sum(depreciationCostFixedData);
    const operatingProfitsBeforeAmortizationRatioFixed = singleRatioCalc({
      numerator: operatingProfitsBeforeAmortizationFixed,
      denominator: netSalesTotalFixed,
    });
    // 今期直近までと前期の比較ページの営業外収益修正後
    const nonOperatingIncomeTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: non_operating_income,
      })
    );
    // 今期直近までと前期の比較ページの営業外費用修正後
    const nonOperatingExpensesTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: non_operating_expenses,
      })
    );
    // 今期直近までと前期の比較ページの経常利益修正後
    const currentEarningsFixed =
      isNaN(nonOperatingExpensesTotalFixed) ||
      isNaN(nonOperatingExpensesTotalFixed)
        ? 0
        : operatingIncomeFixed +
          nonOperatingIncomeTotalFixed -
          nonOperatingExpensesTotalFixed;
    const currentEarningsRatioFixed = singleRatioCalc({
      numerator: currentEarningsFixed,
      denominator: netSalesTotalFixed,
    });
    // 今期直近までと前期の比較ページの償却前経常修正後
    const ebitdaFixed =
      currentEarningsFixed +
      sum(
        totalCalc({
          headers: [{ results: "fixed" }],
          field: "current_and_previous_year_comparisons",
          state: depreciation_cost_data,
        })
      );
    const ebitdaRatioFixed = singleRatioCalc({
      numerator: ebitdaFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの特別利益修正後
    const specialBenefitsTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: special_benefits,
      })
    );
    // 今期直近までと前期の比較ページの特別損失修正後
    const specialLossesTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: special_losses,
      })
    );

    // 今期直近までと前期の比較ページの税引き前当期純利益修正後
    const ebitFixed =
      currentEarningsFixed +
      specialBenefitsTotalFixed -
      specialLossesTotalFixed;
    const ebitRatioFixed = singleRatioCalc({
      numerator: ebitFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの法人税住民税事業税修正後
    const corporateInhabitantAndEnterpriseTaxesTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: special_corporate_inhabitant_and_enterprise_taxeslosses,
      })
    );
    // 今期直近までと前期の比較ページの当期純利益修正後
    const netIncomeFixed =
      ebitFixed - corporateInhabitantAndEnterpriseTaxesTotalFixed;
    const netIncomeRatioFixed = singleRatioCalc({
      numerator: netIncomeFixed,
      denominator: netSalesTotalFixed,
    });

    // 今期直近までと前期の比較ページの材料費修正後
    const materialFeeTotalFixedData = totalCalcByItem({
      headers: [{ results: "fixed" }],
      field: "current_and_previous_year_comparisons",
      state: material_fee,
    });
    const materialFeeTotalFixed = subtract(
      subtract(
        sum(materialFeeTotalFixedData),
        materialFeeTotalFixedData[materialFeeTotalFixedData.length - 1]
      ),
      materialFeeTotalFixedData[materialFeeTotalFixedData.length - 1]
    );

    // 今期直近までと前期の比較ページの労務費修正後
    const laborCostsTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: labor_costs,
      })
    );
    // 今期直近までと前期の比較ページの外注加工費修正後
    const externalInjectionProcessingFeeTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: external_injection_processing_fee,
      })
    );
    // 今期直近までと前期の比較ページの経費修正後
    const expensesTotalFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: expenses,
      })
    );
    // 今期直近までと前期の比較ページの当期製造費用修正後
    const grossManufacturingCostForThePeriodTotalFixed = add(
      materialFeeTotalFixed,
      add(
        laborCostsTotalFixed,
        add(externalInjectionProcessingFeeTotalFixed, expensesTotalFixed)
      )
    );
    // 今期直近までと前期の比較ページの期首仕掛品棚卸高修正後
    const inventoryOfWorkInProcessAtTheBeginningOfThePeriodTFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: inventory_of_work_in_process_at_the_beginning_of_the_period,
      })
    );
    // 今期直近までと前期の比較ページの期末仕掛品棚卸高修正後
    const inventoryOfWorkInProcessAtTheEndOfThePeriodTFixed = sum(
      totalCalc({
        headers: [{ results: "fixed" }],
        field: "current_and_previous_year_comparisons",
        state: inventory_of_work_in_process_at_the_end_of_the_period,
      })
    );
    // 今期直近までと前期の比較ページの当期製品製造原価修正後
    const costOfProductsManufacturedFixed = subtract(
      add(
        grossManufacturingCostForThePeriodTotalFixed,
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTFixed
      ),
      inventoryOfWorkInProcessAtTheEndOfThePeriodTFixed
    );

    // 今期直近までと前期の比較ページの簡易CF修正後
    const simpleCfRow1Fixed: number = add(
      currentEarningsFixed,
      sum(
        totalCalc({
          headers: [{ results: "fixed" }],
          field: "current_and_previous_year_comparisons",
          state: depreciation_cost_data,
        })
      )
    );
    const simpleCfRow2Fixed: number = add(
      netIncomeFixed,
      sum(
        totalCalc({
          headers: [{ results: "fixed" }],
          field: "current_and_previous_year_comparisons",
          state: depreciation_cost_data,
        })
      )
    );

    const simpleCfRow3Fixed: number = add(
      // 税引き前当期純利益
      ebitFixed,
      add(
        // 減価償却費
        sum(
          totalCalc({
            headers: [{ results: "fixed" }],
            field: "current_and_previous_year_comparisons",
            state: depreciation_cost_data,
          })
        ),
        add(
          // 人件費
          personalCostTotalFixed,
          // 労務費 + 支払利息
          add(laborCostsTotalFixed, nonOperatingExpensesTotalFixed)
        )
      )
    ) as number;

    // 原価構成比
    // 当期製造費用 修正後比率
    const grossManufacturingCostForThePeriodRatioFixed: number =
      singleRatioCalc({
        numerator: grossManufacturingCostForThePeriodTotalFixed,
        denominator: grossManufacturingCostForThePeriodTotalFixed,
      });

    // 材料費 修正後比率
    const materialFeeRatioFixed: number = singleRatioCalc({
      numerator: materialFeeTotalFixed,
      denominator: grossManufacturingCostForThePeriodTotalFixed,
    });

    const netSalesMaterialFeeTotalRatioFixed = singleRatioCalc({
      numerator: materialFeeTotalFixed,
      denominator: netSalesTotalFixed,
    });

    // 材料費 修正後比率
    const laborCostsRatioFixed: number = singleRatioCalc({
      numerator: laborCostsTotalFixed,
      denominator: grossManufacturingCostForThePeriodTotalFixed,
    });

    const netSalesLaborCostsTotalRatioFixed: number = singleRatioCalc({
      numerator: laborCostsTotalFixed,
      denominator: netSalesTotalFixed,
    });

    // 外注加工費 修正後比率
    const externalInjectionProcessingFeeRatioFixed: number = singleRatioCalc({
      numerator: externalInjectionProcessingFeeTotalFixed,
      denominator: grossManufacturingCostForThePeriodTotalFixed,
    });

    const netSalesExternalInjectionProcessingFeeTotalRatioFixed: number =
      singleRatioCalc({
        numerator: externalInjectionProcessingFeeTotalFixed,
        denominator: netSalesTotalFixed,
      });

    // 経費 修正後比率
    const expensesRatioFixed: number = singleRatioCalc({
      numerator: expensesTotalFixed,
      denominator: grossManufacturingCostForThePeriodTotalFixed,
    });

    const netSalesExpensesTotalRatioFixed: number = singleRatioCalc({
      numerator: expensesTotalFixed,
      denominator: netSalesTotalFixed,
    });

    // 修正後の経費の減価償却費 修正後の経費の一番初めの値を抽出
    const depreciationCostOfSalesFixed =
      depreciation_cost_of_sales_t_fixed &&
      depreciation_cost_of_sales_t_fixed[0] &&
      depreciation_cost_of_sales_t_fixed[0][
        "current_and_previous_year_comparisons"
      ]
        ? depreciation_cost_of_sales_t_fixed[0][
            "current_and_previous_year_comparisons"
          ]["fixed"]
        : 0;

    const currentAndPreviousYearComparisonsCalcState = {
      net_sales_total_by_item_for_previous: netSalesTotalByItemForPrevious,
      net_sales_total_by_item_for_current: netSalesTotalByItemForCurrent,
      net_sales_total_by_item_subtract: netSalesTotalByItemSubtract,
      net_sales_total_by_item_ratio: netSalesTotalByItemRatio,
      net_sales_total_by_item_reference: netSalesTotalByItemReference,
      cost_of_sales_total_by_item_for_previous:
        costOfSalesTotalByItemForPrevious,
      cost_of_sales_total_by_item_for_current: costOfSalesTotalByItemForCurrent,
      selling_and_administrative_total_by_item_for_previous:
        sellingAndAdministrativeTotalByItemForPrevious,
      selling_and_administrative_total_by_item_for_current:
        sellingAndAdministrativeTotalByItemForCurrent,
      personal_cost_total_by_item_for_previous:
        personalCostTotalByItemForPrevious,
      personal_cost_total_by_item_for_current:
        personalCostTotalByItemForCurrent,
      non_operating_income_total_by_item_for_previous:
        nonOperatingIncomeTotalByItemForPrevious,
      non_operating_income_total_by_item_for_current:
        nonOperatingIncomeTotalByItemForCurrent,
      non_operating_expenses_total_by_item_for_previous:
        nonOperatingExpensesTotalByItemForPrevious,
      non_operating_expenses_total_by_item_for_current:
        nonOperatingExpensesTotalByItemForCurrent,
      special_benefits_total_by_item_for_previous:
        specialBenefitsTotalByItemForPrevious,
      special_benefits_total_by_item_for_current:
        specialBenefitsTotalByItemForCurrent,
      special_losses_total_by_item_for_previous:
        specialLossesTotalByItemForPrevious,
      special_losses_total_by_item_for_current:
        specialLossesTotalByItemForCurrent,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_for_previous:
        corporateInhabitantAndEnterpriseTaxesTotalByItemForPrevious,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_for_current:
        corporateInhabitantAndEnterpriseTaxesTotalByItemForCurrent,
      material_fee_total_by_item_for_previous:
        materialFeeTotalByItemForPrevious,
      material_fee_total_by_item_for_current: materialFeeTotalByItemForCurrent,
      labor_costs_total_by_item_for_previous: laborCostsTotalByItemForPrevious,
      labor_costs_total_by_item_for_current: laborCostsTotalByItemForCurrent,
      external_injection_processing_fee_total_by_item_for_previous:
        externalInjectionProcessingFeeTotalByItemForPrevious,
      external_injection_processing_fee_total_by_item_for_current:
        externalInjectionProcessingFeeTotalByItemForCurrent,
      expenses_total_by_item_for_previous: expensesTotalByItemForPrevious,
      expenses_total_by_item_for_current: expensesTotalByItemForCurrent,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_for_previous:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForPrevious,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_for_current:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemForCurrent,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_for_previous:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForPrevious,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_for_current:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemForCurrent,

      net_sales_total_for_previous: netSalesTotalForPrevious,
      net_sales_total_for_current: netSalesTotalForCurrent,
      cost_of_sales_total_for_previous: costOfSalesTotalForPrevious,
      cost_of_sales_total_for_current: costOfSalesTotalForCurrent,
      cost_of_sales_ratio_for_previous: costOfSalesRatioForPrevious,
      cost_of_sales_ratio_for_current: costOfSalesRatioForCurrent,
      gross_margin_for_previous: grossMarginForPrevious,
      gross_margin_for_current: grossMarginForCurrent,
      gross_margin_ratio_for_previous: grossMarginRatioForPrevious,
      gross_margin_ratio_for_current: grossMarginRatioForCurrent,
      personal_cost_total_for_previous: personalCostTotalForPrevious,
      personal_cost_total_for_current: personalCostTotalForCurrent,
      personal_cost_ratio_for_previous: personalCostRatioForPrevious,
      personal_cost_ratio_for_current: personalCostRatioForCurrent,
      selling_and_administrative_total_for_previous:
        sellingAndAdministrativeTotalForPrevious,
      selling_and_administrative_total_for_current:
        sellingAndAdministrativeTotalForCurrent,
      selling_and_administrative_ratio_for_previous:
        sellingAndAdministrativeRatioForPrevious,
      selling_and_administrative_ratio_for_current:
        sellingAndAdministrativeRatioForCurrent,
      operating_income_for_previous: operatingIncomeForPrevious,
      operating_income_for_current: operatingIncomeForCurrent,
      operating_income_ratio_for_previous: operatingIncomeRatioForPrevious,
      operating_income_ratio_for_current: operatingIncomeRatioForCurrent,
      operating_profits_before_amortization_for_previous:
        operatingProfitsBeforeAmortizationForPrevious,
      operating_profits_before_amortization_for_current:
        operatingProfitsBeforeAmortizationForCurrent,
      operating_profits_before_amortization_ratio_for_previous:
        operatingProfitsBeforeAmortizationRatioForPrevious,
      operating_profits_before_amortization_ratio_for_current:
        operatingProfitsBeforeAmortizationRatioForCurrent,
      non_operating_income_total_for_previous:
        nonOperatingIncomeTotalForPrevious,
      non_operating_income_total_for_current: nonOperatingIncomeTotalForCurrent,
      non_operating_expenses_total_for_previous:
        nonOperatingExpensesTotalForPrevious,
      non_operating_expenses_total_for_current:
        nonOperatingExpensesTotalForCurrent,
      current_earnings_for_previous: currentEarningsForPrevious,
      current_earnings_for_current: currentEarningsForCurrent,
      current_earnings_ratio_for_previous: currentEarningsRatioForPrevious,
      current_earnings_ratio_for_current: currentEarningsRatioForCurrent,
      ebitda_for_previous: ebitdaForPrevious,
      ebitda_for_current: ebitdaForCurrent,
      ebitda_ratio_for_previous: ebitdaRatioForPrevious,
      ebitda_ratio_for_current: ebitdaRatioForCurrent,
      special_benefits_total_for_previous: specialBenefitsTotalForPrevious,
      special_benefits_total_for_current: specialBenefitsTotalForCurrent,
      special_losses_total_for_previous: specialLossesTotalForPrevious,
      special_losses_total_for_current: specialLossesTotalForCurrent,
      ebit_for_previous: ebitForPrevious,
      ebit_for_current: ebitForCurrent,
      ebit_ratio_for_previous: ebitRatioForPrevious,
      ebit_ratio_for_current: ebitRatioForCurrent,
      net_income_for_previous: netIncomeForPrevious,
      net_income_for_current: netIncomeForCurrent,
      net_income_ratio_for_previous: netIncomeRatioForPrevious,
      net_income_ratio_for_current: netIncomeRatioForCurrent,
      simple_cf_row1_for_previous: simpleCfRow1ForPrevious,
      simple_cf_row1_for_current: simpleCfRow1ForCurrent,
      simple_cf_row2_for_previous: simpleCfRow2ForPrevious,
      simple_cf_row2_for_current: simpleCfRow2ForCurrent,
      simple_cf_row3_for_previous: simpleCfRow3ForPrevious,
      simple_cf_row3_for_current: simpleCfRow3ForCurrent,
      material_fee_total_for_previous: materialFeeTotalForPrevious,
      material_fee_total_for_current: materialFeeTotalForCurrent,
      labor_costs_total_for_previous: laborCostsTotalForPrevious,
      labor_costs_total_for_current: laborCostsTotalForCurrent,
      external_injection_processing_fee_total_for_previous:
        externalInjectionProcessingFeeTotalForPrevious,
      external_injection_processing_fee_total_for_current:
        externalInjectionProcessingFeeTotalForCurrent,
      expenses_total_for_previous: expensesTotalForPrevious,
      expenses_total_for_current: expensesTotalForCurrent,
      gross_manufacturing_cost_for_the_period_for_previous:
        grossManufacturingCostForThePeriodForPrevious,
      gross_manufacturing_cost_for_the_period_for_current:
        grossManufacturingCostForThePeriodForCurrent,
      cost_of_products_manufactured_for_previous:
        costOfProductsManufacturedForPrevious,
      cost_of_products_manufactured_for_current:
        costOfProductsManufacturedForCurrent,
      depreciation_cost_of_sales_t_for_previous:
        depreciationCostOfSalesTForPrevious,
      depreciation_cost_of_sales_t_for_current:
        depreciationCostOfSalesTForCurrent,

      cost_of_sales_total_by_item_subtract: costOfSalesTotalByItemSubtract,
      selling_and_administrative_total_by_item_subtract:
        sellingAndAdministrativeTotalByItemSubtract,
      personal_cost_total_by_item_subtract: personalCostTotalByItemSubtract,
      non_operating_income_total_by_item_subtract:
        nonOperatingIncomeTotalByItemSubtract,
      non_operating_expenses_total_by_item_subtract:
        nonOperatingExpensesTotalByItemSubtract,
      special_benefits_total_by_item_subtract:
        specialBenefitsTotalByItemSubtract,
      special_losses_total_by_item_subtract: specialLossesTotalByItemSubtract,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_subtract:
        corporateInhabitantAndEnterpriseTaxesTotalByItemSubtract,
      material_fee_total_by_item_subtract: materialFeeTotalByItemSubtract,
      labor_costs_total_by_item_subtract: laborCostsTotalByItemSubtract,
      external_injection_processing_fee_total_by_item_subtract:
        externalInjectionProcessingFeeTotalByItemSubtract,
      expenses_total_by_item_subtract: expensesTotalByItemSubtract,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_subtract:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemSubtract,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_subtract:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemSubtract,
      net_sales_total_subtract: netSalesTotalSubtract,
      gross_margin_subtract: grossMarginSubtract,
      personal_cost_total_subtract: personalCostTotalSubtract,
      selling_and_administrative_total_subtract:
        sellingAndAdministrativeTotalSubtract,
      operating_income_subtract: operatingIncomeSubtract,
      operating_profits_before_amortization_subtract:
        operatingProfitsBeforeAmortizationSubtract,
      non_operating_income_total_subtract: nonOperatingIncomeTotalSubtract,
      non_operating_expenses_total_subtract: nonOperatingExpensesTotalSubtract,
      current_earnings_subtract: currentEarningsSubtract,
      ebitda_subtract: ebitdaSubtract,
      special_benefits_total_subtract: specialBenefitsTotalSubtract,
      special_losses_total_subtract: specialLossesTotalSubtract,
      ebit_subtract: ebitSubtract,
      net_income_subtract: netIncomeSubtract,
      simple_cf_row1_subtract: simpleCfRow1Subtract,
      simple_cf_row2_subtract: simpleCfRow2Subtract,
      simple_cf_row3_subtract: simpleCfRow3Subtract,
      material_fee_total_subtract: materialFeeTotalSubtract,
      labor_costs_total_subtract: laborCostsTotalSubtract,
      external_injection_processing_fee_total_subtract:
        externalInjectionProcessingFeeTotalSubtract,
      expenses_total_subtract: expensesTotalSubtract,
      gross_manufacturing_cost_for_the_period_subtract:
        grossManufacturingCostForThePeriodSubtract,
      cost_of_products_manufactured_subtract:
        costOfProductsManufacturedSubtract,

      cost_of_sales_total_by_item_ratio: costOfSalesTotalByItemRatio,
      selling_and_administrative_total_by_item_ratio:
        sellingAndAdministrativeTotalByItemRatio,
      personal_cost_total_by_item_ratio: personalCostTotalByItemRatio,
      non_operating_income_total_by_item_ratio:
        nonOperatingIncomeTotalByItemRatio,
      non_operating_expenses_total_by_item_ratio:
        nonOperatingExpensesTotalByItemRatio,
      special_benefits_total_by_item_ratio: specialBenefitsTotalByItemRatio,
      special_losses_total_by_item_ratio: specialLossesTotalByItemRatio,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_ratio:
        corporateInhabitantAndEnterpriseTaxesTotalByItemRatio,
      material_fee_total_by_item_ratio: materialFeeTotalByItemRatio,
      labor_costs_total_by_item_ratio: laborCostsTotalByItemRatio,
      external_injection_processing_fee_total_by_item_ratio:
        externalInjectionProcessingFeeTotalByItemRatio,
      expenses_total_by_item_ratio: expensesTotalByItemRatio,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_ratio:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemRatio,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_ratio:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemRatio,
      net_sales_total_ratio: netSalesTotalRatio,
      gross_margin_ratio: grossMarginRatio,
      personal_cost_total_ratio: personalCostTotalRatio,
      selling_and_administrative_total_ratio:
        sellingAndAdministrativeTotalRatio,
      operating_income_ratio: operatingIncomeRatio,
      operating_profits_before_amortization_ratio:
        operatingProfitsBeforeAmortizationRatio,
      non_operating_income_total_ratio: nonOperatingIncomeTotalRatio,
      non_operating_expenses_total_ratio: nonOperatingExpensesTotalRatio,
      current_earnings_ratio: currentEarningsRatio,
      ebitda_ratio: ebitdaRatio,
      special_benefits_total_ratio: specialBenefitsTotalRatio,
      special_losses_total_ratio: specialLossesTotalRatio,
      ebit_ratio: ebitRatio,
      net_income_ratio: netIncomeRatio,
      simple_cf_row1_ratio: simpleCfRow1Ratio,
      simple_cf_row2_ratio: simpleCfRow2Ratio,
      simple_cf_row3_ratio: simpleCfRow3Ratio,
      material_fee_total_ratio: materialFeeTotalRatio,
      labor_costs_total_ratio: laborCostsTotalRatio,
      external_injection_processing_fee_total_ratio:
        externalInjectionProcessingFeeTotalRatio,
      expenses_total_ratio: expensesTotalRatio,
      gross_manufacturing_cost_for_the_period_ratio:
        grossManufacturingCostForThePeriodRatio,
      cost_of_products_manufactured_ratio: costOfProductsManufacturedRatio,

      cost_of_sales_total_by_item_reference: costOfSalesTotalByItemReference,
      selling_and_administrative_total_by_item_reference:
        sellingAndAdministrativeTotalByItemReference,
      personal_cost_total_by_item_reference: personalCostTotalByItemReference,
      non_operating_income_total_by_item_reference:
        nonOperatingIncomeTotalByItemReference,
      non_operating_expenses_total_by_item_reference:
        nonOperatingExpensesTotalByItemReference,
      special_benefits_total_by_item_reference:
        specialBenefitsTotalByItemReference,
      special_losses_total_by_item_reference: specialLossesTotalByItemReference,
      special_corporate_inhabitant_and_enterprise_taxeslosses_total_by_item_reference:
        corporateInhabitantAndEnterpriseTaxesTotalByItemReference,
      material_fee_total_by_item_reference: materialFeeTotalByItemReference,
      labor_costs_total_by_item_reference: laborCostsTotalByItemReference,
      external_injection_processing_fee_total_by_item_reference:
        externalInjectionProcessingFeeTotalByItemReference,
      expenses_total_by_item_reference: expensesTotalByItemReference,
      inventory_of_work_in_process_at_the_beginning_of_the_period_total_by_item_reference:
        inventoryOfWorkInProcessAtTheBeginningOfThePeriodTotalByItemReference,
      inventory_of_work_in_process_at_the_end_of_the_period_total_by_item_reference:
        inventoryOfWorkInProcessAtTheEndOfThePeriodTotalByItemReference,
      net_sales_total_reference: netSalesTotalReference,
      gross_margin_reference: grossMarginReference,
      personal_cost_total_reference: personalCostTotalReference,
      selling_and_administrative_total_reference:
        sellingAndAdministrativeTotalReference,
      operating_income_reference: operatingIncomeReference,
      operating_profits_before_amortization_reference:
        operatingProfitsBeforeAmortizationReference,
      non_operating_income_total_reference: nonOperatingIncomeTotalReference,
      non_operating_expenses_total_reference:
        nonOperatingExpensesTotalReference,
      current_earnings_reference: currentEarningsReference,
      ebitda_reference: ebitdaReference,
      special_benefits_total_reference: specialBenefitsTotalReference,
      special_losses_total_reference: specialLossesTotalReference,
      ebit_reference: ebitReference,
      net_income_reference: netIncomeReference,
      simple_cf_row1_reference: simpleCfRow1Reference,
      simple_cf_row2_reference: simpleCfRow2Reference,
      simple_cf_row3_reference: simpleCfRow3Reference,
      material_fee_total_reference: materialFeeTotalReference,
      labor_costs_total_reference: laborCostsTotalReference,
      external_injection_processing_fee_total_reference:
        externalInjectionProcessingFeeTotalReference,
      expenses_total_reference: expensesTotalReference,
      gross_manufacturing_cost_for_the_period_reference:
        grossManufacturingCostForThePeriodReference,
      cost_of_products_manufactured_reference:
        costOfProductsManufacturedReference,

      net_sales_total_fixed: netSalesTotalFixed,
      net_sales_total_ratio_fixed: netSalesTotalRatioFixed,
      cost_of_sales_total_fixed: costOfSalesTotalFixed,
      gross_margin_fixed: grossMarginFixed,
      gross_margin_ratio_fixed: grossMarginRatioFixed,
      personal_cost_total_fixed: personalCostTotalFixed,
      personal_cost_total_ratio_fixed: personalCostTotalRatioFixed,
      selling_and_administrative_total_fixed:
        sellingAndAdministrativeTotalFixed,
      selling_and_administrative_total_ratio_fixed:
        sellingAndAdministrativeTotalRatioFixed,
      operating_income_fixed: operatingIncomeFixed,
      operating_income_ratio_fixed: operatingIncomeRatioFixed,
      operating_profits_before_amortization_fixed:
        operatingProfitsBeforeAmortizationFixed,
      operating_profits_before_amortization_ratio_fixed:
        operatingProfitsBeforeAmortizationRatioFixed,
      non_operating_income_total_fixed: nonOperatingIncomeTotalFixed,
      non_operating_expenses_total_fixed: nonOperatingExpensesTotalFixed,
      special_benefits_total_fixed: specialBenefitsTotalFixed,
      special_losses_total_fixed: specialLossesTotalFixed,
      current_earnings_fixed: currentEarningsFixed,
      current_earnings_ratio_fixed: currentEarningsRatioFixed,
      ebitda_fixed: ebitdaFixed,
      ebitda_ratio_fixed: ebitdaRatioFixed,
      ebit_fixed: ebitFixed,
      ebit_ratio_fixed: ebitRatioFixed,
      net_income_fixed: netIncomeFixed,
      net_income_ratio_fixed: netIncomeRatioFixed,
      simple_cf_row1_fixed: simpleCfRow1Fixed,
      simple_cf_row2_fixed: simpleCfRow2Fixed,
      simple_cf_row3_fixed: simpleCfRow3Fixed,
      material_fee_total_fixed: materialFeeTotalFixed,
      labor_costs_total_fixed: laborCostsTotalFixed,
      external_injection_processing_fee_total_fixed:
        externalInjectionProcessingFeeTotalFixed,
      expenses_total_fixed: expensesTotalFixed,
      gross_manufacturing_cost_for_the_period_total_fixed:
        grossManufacturingCostForThePeriodTotalFixed,
      cost_of_products_manufactured_fixed: costOfProductsManufacturedFixed,

      net_sales_total_reference_ratio: netSalesTotalReferenceRatio,
      net_sales_latest_results_total: netSalesLatestResultsTotal,
      cost_of_sales_total_reference_ratio: costOfSalesTotalReferenceRatio,
      cost_of_sales_latest_results_total: costOfSalesLatestResultsTotal,
      gross_margin_reference_ratio: grossMarginReferenceRatio,
      gross_margin_latest_results: grossMarginLatestResults,
      personal_cost_total_reference_ratio: personalCostTotalReferenceRatio,
      personal_cost_latest_results: personalCostLatestResults,
      selling_and_administrative_total_reference_ratio:
        sellingAndAdministrativeTotalReferenceRatio,
      selling_and_administrative_total_latest_results:
        sellingAndAdministrativeTotalLatestResults,
      operating_income_reference_ratio: operatingIncomeReferenceRatio,
      operating_income_latest_results: operatingIncomeLatestResults,

      ebitda_reference_ratio: ebitdaReferenceRatio,
      ebitda_latest_results: ebitdaLatestResults,
      operating_profits_before_amortization_reference_ratio:
        operatingProfitsBeforeAmortizationReferenceRatio,
      operating_profits_before_amortization_latest_results:
        operatingProfitsBeforeAmortizationLatestResults,
      current_earnings_reference_ratio: currentEarningsReferenceRatio,
      current_earnings_latest_results: currentEarningsLatestResults,
      ebit_reference_ratio: ebitReferenceRatio,
      ebit_latest_results: ebitLatestResults,
      net_income_reference_ratio: netIncomeReferenceRatio,
      net_income_latest_results: netIncomeLatestResults,
      material_fee_total_ratio_for_previous: materialFeeTotalRatioForPrevious,
      material_fee_total_ratio_for_current: materialFeeTotalRatioForCurrent,
      material_fee_total_reference_ratio: materialFeeTotalReferenceRatio,
      gross_manufacturing_cost_for_the_period_ratio_for_previous:
        grossManufacturingCostForThePeriodRatioForPrevious,
      gross_manufacturing_cost_for_the_period_ratio_for_current:
        grossManufacturingCostForThePeriodRatioForCurrent,
      gross_manufacturing_cost_for_the_period_reference_ratio:
        grossManufacturingCostForThePeriodReferenceRatio,
      labor_costs_total_ratio_for_previous: laborCostsTotalRatioForPrevious,
      labor_costs_total_ratio_for_current: laborCostsTotalRatioForCurrent,
      labor_costs_total_reference_ratio: laborCostsTotalReferenceRatio,
      external_injection_processing_fee_total_ratio_for_previous:
        externalInjectionProcessingFeeTotalRatioForPrevious,
      external_injection_processing_fee_total_ratio_for_current:
        externalInjectionProcessingFeeTotalRatioForCurrent,
      external_injection_processing_fee_total_reference_ratio:
        externalInjectionProcessingFeeTotalReferenceRatio,
      expenses_total_ratio_for_previous: expensesTotalRatioForPrevious,
      expenses_total_ratio_for_current: expensesTotalRatioForCurrent,
      expenses_total_reference_ratio: expensesTotalReferenceRatio,
      net_sales_material_fee_total_ratio_for_previous:
        netSalesMaterialFeeTotalRatioForPrevious,
      net_sales_material_fee_total_ratio_for_current:
        netSalesMaterialFeeTotalRatioForCurrent,
      net_sales_material_fee_total_reference_ratio:
        netSalesMaterialFeeTotalReferenceRatio,

      net_sales_labor_costs_total_ratio_for_previous:
        netSalesLaborCostsTotalRatioForPrevious,
      net_sales_labor_costs_total_ratio_for_current:
        netSalesLaborCostsTotalRatioForCurrent,
      net_sales_labor_costs_total_reference_ratio:
        netSalesLaborCostsTotalReferenceRatio,
      net_sales_external_injection_processing_fee_total_ratio_for_previous:
        netSalesExternalInjectionProcessingFeeTotalRatioForPrevious,
      net_sales_external_injection_processing_fee_total_ratio_for_current:
        netSalesExternalInjectionProcessingFeeTotalRatioForCurrent,
      net_sales_external_injection_processing_fee_total_reference_ratio:
        netSalesExternalInjectionProcessingFeeTotalReferenceRatio,
      net_sales_expenses_total_ratio_for_previous:
        netSalesExpensesTotalRatioForPrevious,
      net_sales_expenses_total_ratio_for_current:
        netSalesExpensesTotalRatioForCurrent,
      net_sales_expenses_total_reference_ratio:
        netSalesExpensesTotalReferenceRatio,
      cost_of_sales_total_subtract: costOfSalesTotalSubtract,
      cost_of_sales_total_ratio: costOfSalesTotalRatio,
      cost_of_sales_total_reference: costOfSalesTotalReference,
      cost_of_sales_total_ratio_fixed: costOfSalesTotalRatioFixed,
      gross_manufacturing_cost_for_the_period_ratio_fixed:
        grossManufacturingCostForThePeriodRatioFixed,
      material_fee_ratio_fixed: materialFeeRatioFixed,
      labor_costs_ratio_fixed: laborCostsRatioFixed,
      external_injection_processing_fee_ratio_fixed:
        externalInjectionProcessingFeeRatioFixed,
      expenses_ratio_fixed: expensesRatioFixed,
      net_sales_material_fee_total_ratio_fixed:
        netSalesMaterialFeeTotalRatioFixed,
      net_sales_labor_costs_total_ratio_fixed:
        netSalesLaborCostsTotalRatioFixed,
      net_sales_external_injection_processing_fee_total_ratio_fixed:
        netSalesExternalInjectionProcessingFeeTotalRatioFixed,
      net_sales_expenses_total_ratio_fixed: netSalesExpensesTotalRatioFixed,
      depreciation_cost_of_sales_t_reference_ratio:
        expensesTotalByItemReference[0],
      depreciation_cost_of_sales_fixed: depreciationCostOfSalesFixed,
    };

    return currentAndPreviousYearComparisonsCalcState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
