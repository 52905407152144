import { DocumentData } from "firebase/firestore";
import { CellData } from "../../types";

type Props = {
  headers: string[];
  field: string;
  state: DocumentData[];
  target: "price" | "costPrice";
};

export const perValuesForSalesPlan = ({
  headers,
  state,
  field,
  target,
}: Props) => {
  const total: number[][] = [];

  // 個別に個数を抽出
  state.map((row) => {
    const itemCount: number[] = [];
    const cells: CellData = row[field];
    headers.map((header: string) => {
      const headerKey = header;
      let value = cells[headerKey];
      if (!cells[headerKey]) {
        value = 0;
      }
      itemCount.push(
        value *
          (target === "costPrice" && row.cost_price
            ? row.cost_price
            : row.price)
      );
    });
    total.push(itemCount);
  });

  return total;
};
