import { DocumentData } from "firebase/firestore";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
};

export const totalCalcByItemForPlansInputMonthly = ({
  headers,
  state,
  field,
}: Props) => {
  const totalData: number[] = [];

  state.map((byItem) => {
    const term = headers[0].period;
    const data =
      byItem[field] && byItem[field][term] ? byItem[field][term] : null;

    totalData.push(data === null || !data ? 0 : data);
  });

  if (totalData.length === 0) {
    totalData.push(0);
  }

  return totalData;
};
