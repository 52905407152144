import { DocumentData } from "firebase/firestore";
import { ResultsInputFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlAnalytics = ({
  headerTitle,
  headers,
  field,
  state,
  descriptions,
  unit,
}: {
  headerTitle?: string;
  headers: DocumentData[];
  field: string;
  state: ResultsInputFieldsCalc;
  descriptions: DocumentData;
  unit: number;
}) => {
  const rows1 = [
    {
      title: "変動売上原価（材料費）",
      value: state.material_fee_total,
      variable: false,
      field: `${field}_cost_of_sales_except_beginning_and_end_total`,
    },
    {
      title: "固定売上原価（労務費）",
      value: state.labor_costs_fluctuate_total,
      variable: false,
      field: `${field}_labor_costs_fluctuate_total`,
    },
    {
      title: "変動売上原価（外注費）",
      value: state.external_injection_processing_fee_fluctuate_total,
      variable: false,
      field: `${field}_external_injection_processing_fee_fluctuate_total`,
    },
    {
      title: "変動売上原価（経費）",
      value: state.expenses_fluctuate_total,
      variable: false,
      field: `${field}_expenses_fluctuate_total`,
    },
    {
      title: "期首仕掛品・半製品棚卸高",
      value:
        state.inventory_of_work_in_process_at_the_beginning_of_the_period_t,
      variable: false,
      field: `${field}_inventory_of_work_in_process_at_the_beginning_of_the_period_t`,
    },
    {
      title: "期末仕掛品・半製品棚卸高",
      value: state.inventory_of_work_in_process_at_the_end_of_the_period_t,
      variable: false,
      field: `${field}_inventory_of_work_in_process_at_the_end_of_the_period_t`,
    },
    {
      title: "期首製品棚卸高",
      value: state.beginning_inventories_total,
      variable: false,
      field: `${field}_beginning_inventories_total`,
    },
    {
      title: "仕入合計",
      value: state.stocking_total,
      variable: true,
      field: `${field}_stocking_total`,
    },
    {
      title: "期末製品棚卸高",
      value: state.end_inventories_total,
      variable: false,
      field: `${field}_end_inventories_total`,
    },
  ];
  const rows2 = [
    {
      title: "変動販管費（人件費）",
      value: state.personal_cost_fluctuate_total,
      field: `${field}_personal_cost_fluctuate_total`,
    },
    {
      title: "変動販管費（その他）",
      value: state.selling_and_administrative_fluctuate_total,
      field: `${field}_selling_and_administrative_fluctuate_total`,
    },
  ];
  const rows3 = [
    {
      title: "固定売上原価(労務費）",
      value: state.labor_costs_fixed_data_total,
      field: `${field}_labor_costs_fixed_data_total`,
    },
    {
      title: "固定売上原価（経費）",
      value: state.expenses_fixed_data_total,
      field: `${field}_expenses_fixed_data_total`,
    },
  ];
  const rows4 = [
    {
      title: "固定販管費(人件費）",
      value: state.personal_cost_fixed_total,
      field: `${field}_personal_cost_fixed_total`,
    },
    {
      title: "固定販管費（その他）",
      value: state.selling_and_administrative_fixed_total,
      field: `${field}_selling_and_administrative_fixed_total`,
    },
  ];

  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 1 + (headers?.length ?? 0),
    },
  ];
  grid.push(gridRow0);

  // className
  const classCol1_1 = `${beforeWrapper} left-0 p-4px bg-white before:bg-c80b0f433 before:border-l before:border-b font-bold z-[32]`;

  const classCol1_2 = `${beforeWrapper} left-30px bg-white before:border-b before:bg-c80b0f433`;

  const classCol1_3 = `${beforeWrapper} left-80px bg-white before:border-b before:bg-c80b0f433 before:border-r before:border-r-c3333334d`;

  const classCol1_5 = `${borderSettings} text-right`;

  const classCol1_6 = `${borderSettings} before:border-r bg-c80b0f433 h-50px description`;

  const gridRow1: GridElement[] = [
    {
      value: "売上高",
      readOnly: true,
      className: `${classCol1_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol1_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol1_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow1.push({
      value: String(state.net_sales_total[tdIndex]).replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        "$1,"
      ),
      readOnly: true,
      className: `${classCol1_5}  ${
        header.isTotal ? "bg-cfffee2" : "bg-c80b0f433"
      }`,
    });
  });
  gridRow1.push({
    value: descriptions[`${field}_1`],
    readOnly: false,
    className: `${classCol1_6}`,
    field: `${field}_1`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow1);

  rows1.map((row: DocumentData, index: number) => {
    // className
    const classCol2_1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l font-bold [writing-mode:vertical-lr]`;

    const classCol2_2 = `${beforeWrapper} left-30px p-4px bg-white before:border-l before:border-l-c3333334d ${
      row.length === index + 1 && "before:border-b before:border-b-c3333334d"
    }  font-bold`;

    const classCol2_3 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-b-c3333334d before:border-x before:border-x-c3333334d ${
      index % 2 !== 0 && "before:bg-c2a4b770d"
    }`;

    const classCol2_4 = `${borderSettings} before:border-b-c3333334d text-right ${
      index % 2 !== 0 && "bg-c2a4b770d"
    }`;

    const classCol2_5 = `${borderSettings} before:border-b-c3333334d  before:border-r ${
      index % 2 !== 0 && "bg-c2a4b770d"
    } h-50px description text-left`;

    const gridRow2: GridElement[] = [
      {
        value: index === 0 && "変動費",
        readOnly: true,
        className: `${classCol2_1}`,
      },
      {
        value: index === 0 && "原価",
        readOnly: true,
        className: `${classCol2_2}`,
      },
      {
        value: row.title,
        readOnly: true,
        className: `${classCol2_3}`,
        colSpan: 2,
      },
    ];

    headers?.map((header, tdIndex) => {
      gridRow2.push({
        value: row.variable
          ? row.value[tdIndex] === 0
            ? ShowTableData(
                state.cost_of_sales_except_beginning_and_end_total[tdIndex] /
                  unit
              )
            : 0
          : ShowTableData(row.value[tdIndex] / unit),
        expr: row.variable
          ? row.value[tdIndex] === 0
            ? state.cost_of_sales_except_beginning_and_end_total[tdIndex] / unit
            : 0
          : row.value[tdIndex] / unit,
        readOnly: true,
        className: `${classCol2_4} ${header.isTotal ? "bg-cfffee2" : ""} `,
      });
    });
    gridRow2.push({
      value: descriptions[row.field],
      readOnly: false,
      className: `${classCol2_5}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow2);
  });

  const classCol3_1 = `${beforeWrapper}  left-0 p-4px bg-white before:border-l font-bold`;

  const classCol3_2 = `${beforeWrapper} left-30px bg-white before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`;

  const classCol3_3 = `${beforeWrapper}  left-80px bg-white before:border-b before:border-b-c3333334d font-bold before:border-r before:border-r-c3333334d`;

  const classCol3_5 = `${borderSettings} before:border-b-c3333334d text-right`;

  const classCol3_6 = `${borderSettings} before:border-b-c3333334d  before:border-r h-50px description`;

  const gridRow3: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol3_1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3_2}`,
    },
    {
      value: "変動原価合計",
      readOnly: true,
      className: `${classCol3_3} whitespace-nowrap`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow3.push({
      value:
        state.stocking_total[tdIndex] === 0
          ? ShowTableData(state.cost_of_sales_total[tdIndex] / unit)
          : ShowTableData(state.variable_costs_total[tdIndex] / unit),
      expr:
        state.stocking_total[tdIndex] === 0
          ? state.cost_of_sales_total[tdIndex] / unit
          : state.variable_costs_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol3_5}  ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow3.push({
    value: descriptions[`${field}_3`],
    readOnly: false,
    className: `${classCol3_6}`,
    field: `${field}_3`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow3);

  const classCol4_1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l font-bold`;

  const classCol4_2 = `${beforeWrapper} left-30px p-4px bg-white before:border-l before:border-l-c3333334d`;

  const classCol4_3 = `${beforeWrapper} left-80px bg-white before:border-b before:border-b-c3333334d  before:border-x before:border-x-c3333334d  `;

  const classCol4_5 = `${borderSettings} before:border-b-c3333334d text-right`;

  const classCol4_6 = `${borderSettings} before:border-b-c3333334d  before:border-r h-50px description`;

  rows2.map((row: DocumentData, index: number) => {
    // className

    const gridRow4: GridElement[] = [
      {
        value: "",
        readOnly: true,
        className: `${classCol4_1}`,
      },
      {
        value: index === 0 && "販売費",
        readOnly: true,
        className: `${classCol4_2}`,
      },
      {
        value: row.title,
        readOnly: true,
        className: `${classCol4_3}${index % 2 !== 0 && "before:bg-c2a4b770d"}`,
        colSpan: 2,
      },
    ];

    headers?.map((header, tdIndex) => {
      gridRow4.push({
        value: ShowTableData(row.value[tdIndex] / unit),
        expr: row.value[tdIndex] / unit,
        readOnly: true,
        className: `${classCol4_5} ${header.isTotal ? "bg-cfffee2" : ""}  ${
          index % 2 !== 0 && "bg-c2a4b770d"
        }`,
      });
    });
    gridRow4.push({
      value: descriptions[row.field],
      readOnly: false,
      className: `${classCol4_6} ${index % 2 !== 0 && "bg-c2a4b770d"}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow4);
  });

  const classCol5_1 = `${beforeWrapper}  left-0 p-4px bg-white before:border-l font-bold`;

  const classCol5_2 = `${beforeWrapper} left-30px bg-white before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`;

  const classCol5_3 = `${beforeWrapper}  left-80px bg-white before:border-b before:border-b-c3333334d font-bold before:border-r before:border-r-c3333334d`;

  const classCol5_4 = `${borderSettings} text-right before:border-b-c3333334d `;

  const classCol5_5 = `${borderSettings} before:border-b-c3333334d  before:border-r  h-50px description`;

  const gridRow5: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol5_1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5_2}`,
    },
    {
      value: "変動販管費合計",
      readOnly: true,
      className: `${classCol5_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow5.push({
      value: ShowTableData(state.sga_total[tdIndex] / unit),
      expr: state.sga_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol5_4} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow5.push({
    value: descriptions[`${field}_5`],
    readOnly: false,
    className: `${classCol5_5}`,
    field: `${field}_5`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow5);

  const classCol6_1 = `${beforeWrapper} left-0 p-4px  p-4px bg-white before:border-l before:border-b`;

  const classCol6_2 = `${beforeWrapper} left-30px p-4px bg-white before:border-b font-bold z-[32]`;

  const classCol6_3 = `${beforeWrapper}  left-80px bg-white before:border-b font-bold before:border-r before:border-r-c3333334d`;

  const classCol6_4 = `${borderSettings}  text-right `;

  const classCol6_5 = `${borderSettings} before:border-r  h-50px description`;

  const gridRow6: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol6_1}`,
    },
    {
      value: "変動費計",
      readOnly: true,
      className: `${classCol6_2} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol6_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow6.push({
      value: ShowTableData(state.variable_costs_grand_total[tdIndex] / unit),
      expr: state.variable_costs_grand_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol6_4} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow6.push({
    value: descriptions[`${field}_6`],
    readOnly: false,
    className: `${classCol6_5}`,
    field: `${field}_6`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow6);

  const classCol7_1 = `${beforeWrapper} left-0 p-4px bg-white before:bg-c80b0f433 before:border-l before:border-b font-bold z-[32]`;

  const classCol7_2 = `${beforeWrapper} left-30px bg-white before:border-b before:bg-c80b0f433`;

  const classCol7_3 = `${beforeWrapper} left-80px bg-white before:border-b before:bg-c80b0f433 before:border-r before:border-r-c3333334d`;

  const classCol7_5 = `${borderSettings} text-right`;

  const classCol7_6 = `${borderSettings} before:border-r bg-c80b0f433 h-50px description`;

  const gridRow7: GridElement[] = [
    {
      value: "限界利益",
      readOnly: true,
      className: `${classCol7_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow7.push({
      value: ShowTableData(state.marginal_profit[tdIndex] / unit),
      expr: state.marginal_profit[tdIndex] / unit,
      readOnly: true,
      className: `${classCol7_5} ${
        header.isTotal ? "bg-cfffee2" : "bg-c80b0f433"
      }`,
    });
  });
  gridRow7.push({
    value: descriptions[`${field}_7`],
    readOnly: false,
    className: `${classCol7_6}`,
    field: `${field}_7`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow7);

  rows3.map((row: DocumentData, index: number) => {
    // className
    const classCol8_1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l font-bold [writing-mode:vertical-lr]`;

    const classCol8_2 = `${beforeWrapper} left-30px p-4px bg-white before:border-l before:border-l-c3333334d ${
      row.longth === index + 1 && "before:border-b before:border-b-c3333334d"
    }  font-bold`;

    const classCol8_3 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:border-b-c3333334d before:border-x before:border-x-c3333334d ${
      index % 2 !== 0 && "before:bg-c2a4b770d"
    }`;

    const classCol8_4 = `${borderSettings} before:border-b-c3333334d text-right ${
      index % 2 !== 0 && "bg-c2a4b770d"
    }`;

    const classCol8_5 = `${borderSettings} before:border-b-c3333334d  before:border-r ${
      index % 2 !== 0 && "bg-c2a4b770d"
    } h-50px description`;

    const gridRow8: GridElement[] = [
      {
        value: index === 0 && "固定費",
        readOnly: true,
        className: `${classCol8_1}`,
      },
      {
        value: index === 0 && "原価",
        readOnly: true,
        className: `${classCol8_2}`,
      },
      {
        value: row.title,
        readOnly: true,
        className: `${classCol8_3}`,
        colSpan: 2,
      },
    ];

    headers?.map((header, tdIndex) => {
      gridRow8.push({
        value: ShowTableData(row.value[tdIndex] / unit),
        expr: row.value[tdIndex] / unit,
        readOnly: true,
        className: `${classCol8_4} ${header.isTotal ? "bg-cfffee2" : ""} `,
      });
    });
    gridRow8.push({
      value: descriptions[row.field],
      readOnly: false,
      className: `${classCol8_5}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow8);
  });

  const gridRow9: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol3_1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3_2}`,
    },
    {
      value: "固定原価合計",
      readOnly: true,
      className: `${classCol3_3} whitespace-nowrap`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow9.push({
      value: ShowTableData(state.fixed_cost_total[tdIndex] / unit),
      expr: state.fixed_cost_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol3_5} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow9.push({
    value: descriptions[`${field}_9`],
    readOnly: false,
    className: `${classCol3_6}`,
    field: `${field}_9`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow9);

  rows4.map((row: DocumentData, index: number) => {
    const gridRow10: GridElement[] = [
      {
        value: "",
        readOnly: true,
        className: `${classCol4_1}`,
      },
      {
        value: index === 0 && "販売費",
        readOnly: true,
        className: `${classCol4_2}`,
      },
      {
        value: row.title,
        readOnly: true,
        className: `${classCol4_3} ${index % 2 !== 0 && "before:bg-c2a4b770d"}`,
        colSpan: 2,
      },
    ];

    headers?.map((header, tdIndex) => {
      gridRow10.push({
        value: ShowTableData(row.value[tdIndex] / unit),
        expr: row.value[tdIndex] / unit,
        readOnly: true,
        className: `${classCol4_5}  ${index % 2 !== 0 && "bg-c2a4b770d"}`,
      });
    });
    gridRow10.push({
      value: descriptions[row.field],
      readOnly: false,
      className: `${classCol4_6} ${index % 2 !== 0 && "bg-c2a4b770d"}`,
      field: row.field,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow10);
  });

  const gridRow11: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol5_1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5_2}`,
    },
    {
      value: "固定販管費合計",
      readOnly: true,
      className: `${classCol5_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow11.push({
      value: ShowTableData(state.sga_fixed_total[tdIndex] / unit),
      expr: state.sga_fixed_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol5_4} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow11.push({
    value: descriptions[`${field}_11`],
    readOnly: false,
    className: `${classCol5_5}`,
    field: `${field}_11`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow11);

  const gridRow12: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol6_1}`,
    },
    {
      value: "固定費計",
      readOnly: true,
      className: `${classCol6_2} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol6_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow12.push({
      value: ShowTableData(state.fixed_cost_grand_total[tdIndex] / unit),
      expr: state.fixed_cost_grand_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol6_4} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow12.push({
    value: descriptions[`${field}_12`],
    readOnly: false,
    className: `${classCol6_5}`,
    field: `${field}_12`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow12);

  const gridRow13: GridElement[] = [
    {
      value: "営業利益",
      readOnly: true,
      className: `${classCol7_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow13.push({
      value: ShowTableData(state.operating_income_t[tdIndex] / unit),
      expr: state.operating_income_t[tdIndex] / unit,
      readOnly: true,
      className: `${classCol7_5} ${
        header.isTotal ? "bg-cfffee2" : "bg-c80b0f433"
      }`,
    });
  });
  gridRow13.push({
    value: descriptions[`${field}_13`],
    readOnly: false,
    className: `${classCol7_6}`,
    field: `${field}_13`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow13);

  const gridRow14: GridElement[] = [
    {
      value: "損益分岐点売上高",
      readOnly: true,
      className: `${classCol7_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol7_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow14.push({
      value: ShowTableData(state.bep_net_sales_total[tdIndex] / unit),
      expr: state.bep_net_sales_total[tdIndex] / unit,
      readOnly: true,
      className: `${classCol7_5} ${
        header.isTotal ? "bg-cfffee2" : "bg-c80b0f433"
      }`,
    });
  });
  gridRow14.push({
    value: descriptions[`${field}_14`],
    readOnly: false,
    className: `${classCol7_6}`,
    field: `${field}_14`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow14);

  const classCol15_1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b  before:border-b-c3333334d z-[32]`;

  const classCol15_2 = `${beforeWrapper} left-30px p-4px bg-white  before:border-b  before:border-b-c3333334d`;

  const classCol15_3 = `${beforeWrapper} left-80px p-4px bg-white before:border-r before:border-b before:border-r-c3333334d before:border-b-c3333334d`;

  const classCol15_4 = `${borderSettings}   
          before:border-b-c3333334d text-right font-bold`;

  const classCol15_5 = `${borderSettings} before:border-b-c3333334d  before:border-r h-50px description`;

  const gridRow15: GridElement[] = [
    {
      value: "変動費率",
      readOnly: true,
      className: `${classCol15_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol15_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol15_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow15.push({
      value: `${state.variable_cost_ratio_t[tdIndex]}%`,
      readOnly: true,
      className: `${classCol15_4} ${header.isTotal ? "bg-cfffee2" : ""}`,
    });
  });
  gridRow15.push({
    value: descriptions[`${field}_15`],
    readOnly: false,
    className: `${classCol15_5}`,
    field: `${field}_15`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow15);

  const classCol16_1 = `${beforeWrapper} left-0 p-4px  bg-white before:bg-c2a4b770d before:border-l before:border-b z-[32]`;

  const classCol16_2 = `${beforeWrapper} left-30px p-4px  bg-white before:bg-c2a4b770d  before:border-b `;

  const classCol16_3 = `${beforeWrapper} left-80px p-4px  bg-white before:bg-c2a4b770d before:border-r before:border-b before:border-r-c3333334d`;

  const classCol16_4 = `${borderSettings}   
         bg-white before:bg-c2a4b770d text-right font-bold`;

  const classCol16_5 = `${borderSettings} bg-white before:bg-c2a4b770d  before:border-r z-10 h-50px description`;

  const gridRow16: GridElement[] = [
    {
      value: "損益分岐点比率",
      readOnly: true,
      className: `${classCol16_1} whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol16_2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol16_3}`,
      colSpan: 2,
    },
  ];

  headers?.map((header, tdIndex) => {
    gridRow16.push({
      value: `${state.bep_net_sales_ratio[tdIndex]}%`,
      readOnly: true,
      className: `${classCol16_4} ${
        header.isTotal ? "bg-cfffee2" : "bg-c2a4b770d"
      }`,
    });
  });
  gridRow16.push({
    value: descriptions[`${field}_16`],
    readOnly: false,
    className: classCol16_5,
    field: `${field}_16`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow16);

  return grid;
};
