import { doc, writeBatch } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import Accordion from "./Accordion";
import {
  noHeaderSidebarMenus,
  noNetSalesSidebarMenus,
  sidebarMenus,
} from "../../config";
import { pageConstant } from "../../constants/pageConstants";
import {
  plansInputHeader,
  plansInputMonthlyHeader,
  resultsInputHeader,
  salesPlanHeaders,
} from "../../data/firebaseModel";
import { profitAndLossPlanHeader } from "../../data/firebaseModel/profit_and_loss_plan";
import { useGetFirebaseData } from "../../firebase";
import { useHeaders, useSortedData } from "../../hooks";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import { fiscalPeriod } from "../../utils/date";
import { UpdateButton } from "../login";

const AccountingSidebar = () => {
  const navigate = useNavigate();
  const contentEl = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  const [clicked, setClicked] = useState(100);
  const [isUpdate, setIsUpdate] = useState(false);

  const firestore = useFirestore();

  const { tableData, tableStatus } = useGetFirebaseData();

  if (tableStatus === "error") {
    navigate(`/${pageConstant.LOGIN}`);
  }

  const headers = useHeaders(tableData);

  const uid = getStoredClientUid();

  // firestoreから更新日を取得
  const firebaseUpdateAt =
    headers?.updatedAt && headers?.updatedAt.toDate()
      ? headers?.updatedAt.toDate()
      : new Date(2024, 4, 13);

  const newFiscalYear = String(Number(headers.fiscal_year) + 1);

  const lastMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 0)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 1)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 2)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 3)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 4)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 5)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 6)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 7)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 8)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year) - 1, headers.fiscal_month, 9)]: 0,
    [fiscalPeriod(
      Number(headers.fiscal_year) - 1,
      headers.fiscal_month,
      10
    )]: 0,
    [fiscalPeriod(
      Number(headers.fiscal_year) - 1,
      headers.fiscal_month,
      11
    )]: 0,
  };

  const thisMonthlyData = {
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 0)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 1)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 2)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 3)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 4)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 5)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 6)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 7)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 8)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 9)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 10)]: 0,
    [fiscalPeriod(Number(headers.fiscal_year), headers.fiscal_month, 11)]: 0,
  };

  // 設定中の年月から1年後
  const settingDate =
    headers.fiscal_year &&
    headers.fiscal_month &&
    Number(
      String(Number(headers.fiscal_year) + 1) + String(headers.fiscal_month)
    );

  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  // 現在の年月
  const nowDate = Number(String(year) + String(month));

  const netSalesData = useSortedData("net_sales", tableData);

  const menus =
    headers && !headers.fiscal_year
      ? noHeaderSidebarMenus
      : netSalesData && netSalesData.length === 0
      ? noNetSalesSidebarMenus
      : headers && headers.fiscal_year
      ? sidebarMenus
      : noHeaderSidebarMenus;

  const handleToggle = (toggleIndex: number) => {
    if (clicked === toggleIndex) {
      return setClicked(0);
    }

    setClicked(toggleIndex);
  };

  useEffect(() => {
    // 最終更新日を取得
    const updateDate = new Date(2024, 5, 5);

    // 更新日を確認し、更新日が新しければ、更新する
    if (headers.fiscal_year && updateDate > firebaseUpdateAt) {
      setIsUpdate(true);
    }

    // 決算期が次年度になれば、決算期を更新する
    if (settingDate && settingDate <= nowDate) {
      // 決算期の更新
      const batch = writeBatch(firestore);

      // ヘッダーデータの更新
      const newHeaderDoc = doc(
        firestore,
        "accounts",
        uid,
        "tables",
        String(headers.NO_ID_FIELD)
      );

      batch.update(newHeaderDoc, {
        fiscal_year: newFiscalYear,
        fiscal_month: headers.fiscal_month,
        current_term: Number(headers.current_term) + 1,
        results_input: resultsInputHeader(
          newFiscalYear,
          headers.fiscal_month,
          headers.current_term
        ),
        plans_input: plansInputHeader(
          newFiscalYear,
          headers.fiscal_month,
          Number(headers.current_term) + 1
        ),
        plans_input_monthly: plansInputMonthlyHeader(
          newFiscalYear,
          headers.fiscal_month,
          Number(headers.current_term) + 1,
          headers
        ),
        results_of_the_current_term: plansInputMonthlyHeader(
          newFiscalYear,
          headers.fiscal_month,
          Number(headers.current_term) + 1,
          headers
        ),
        results_of_the_previous_period: plansInputMonthlyHeader(
          Number(newFiscalYear) - 1,
          headers.fiscal_month,
          Number(headers.current_term) + 1,
          headers
        ),
        sales_plan: salesPlanHeaders(
          Number(newFiscalYear),
          headers.fiscal_month
        ),
        profit_and_loss_plan: profitAndLossPlanHeader(
          newFiscalYear,
          headers.fiscal_month
        ),
        updatedAt: new Date(),
      });

      tableData.map((data) => {
        if (data.field === "headers" || data.field === "descriptions") return;

        const updateDoc = doc(
          firestore,
          "accounts",
          uid,
          "tables",
          data.NO_ID_FIELD
        );

        batch.set(updateDoc, {
          ...data,
          results_input: {
            [`y${String(Number(newFiscalYear) - 3)}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) - 2)}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) - 1)}${String(
              headers.fiscal_month
            )}`]: null,
            ...data.results_input,
          },
          plans_input: {
            [`y${String(Number(newFiscalYear))}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) + 1)}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) + 2)}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) + 3)}${String(
              headers.fiscal_month
            )}`]: null,
            [`y${String(Number(newFiscalYear) + 4)}${String(
              headers.fiscal_month
            )}`]: null,
            ...data.plans_input,
          },
          plans_input_monthly: {
            ...thisMonthlyData,
            ...data.plans_input_monthly,
          },
          results_of_the_current_term: {
            ...thisMonthlyData,
            ...data.results_of_the_current_term,
          },
          results_of_the_previous_period: {
            ...lastMonthlyData,
            ...data.results_of_the_previous_period,
          },
        });
      });

      batch
        .commit()
        .then(() => {
          toast.success("最新の決算期へ更新が完了しました");
        })
        .catch((error) => {
          toast.error("更新できませんでした");
          throw new Error(error.message);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {menus.map((sidebarMenu, index) => (
        <li
          className={`${
            sidebarMenus.length === index + 1
              ? "mb-30px pt-4px"
              : "mb-8px pt-4px"
          } ${sidebarMenu.path ? "" : "mt-20px text-c9dc6ff"} ${
            `/${sidebarMenu.path}` === location.pathname &&
            "bg-c9dc6ff text-c1b2e48"
          } px-12px
          }`}
          key={index}
        >
          {sidebarMenu.path ? (
            `/${sidebarMenu.path}` === location.pathname ? (
              <React.Fragment>
                <button
                  aria-controls="dropdown-example"
                  className={`flex items-center w-full rounded-lg transition duration-75 group hover:bg-gray-100`}
                  data-collapse-toggle="dropdown-example"
                  onClick={() => handleToggle(index)}
                  type="button"
                >
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    {sidebarMenu.title}
                  </span>
                  {sidebarMenu.accordion !== null &&
                    (clicked === 0 ? <FaAngleUp /> : <FaAngleDown />)}
                </button>
                <div
                  className="overflow-hidden transition-height ease-in-out duration-500 ml-12px pb-4px"
                  ref={contentEl}
                  style={
                    clicked !== index
                      ? { height: contentEl.current?.scrollHeight }
                      : { height: "0px" }
                  }
                >
                  <ul
                    className="pt-4px pb-4px space-y-4px"
                    id={`dropdown-${index}`}
                  >
                    {sidebarMenu.accordion !== null &&
                      sidebarMenu.accordion.map((accordion, index) => (
                        <Accordion
                          key={index}
                          keyIndex={index.toString()}
                          path={sidebarMenu.path + accordion.path}
                          title={accordion.title}
                        />
                      ))}
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              <Link to={sidebarMenu.path}>
                <span>{sidebarMenu.title}</span>
              </Link>
            )
          ) : (
            sidebarMenu.title
          )}
        </li>
      ))}
      {isUpdate && (
        <UpdateButton
          firebaseUpdateAt={firebaseUpdateAt}
          setIsUpdate={setIsUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default AccountingSidebar;
