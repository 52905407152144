import { DocumentData } from "firebase/firestore";
import React from "react";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlBudgetControlBasisGrandTotal = ({
  titleTotal,
  titleRatio,
  headers,
  calcState,
  totalField,
  ratioField,
  descriptions,
  unit,
}: {
  titleTotal: string;
  titleRatio: string | null;
  headers: DocumentData[];
  calcState: DocumentData;
  totalField: string;
  ratioField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const monthlyTotalData = singleOrCumsumToggle
    ? [
        calcState.current_monthly_total,
        calcState.previous_monthly_total,
        calcState.current_and_previous_difference_monthly_total,
        calcState.current_and_previous_difference_monthly_total_ratio,
        calcState.monthly_total,
        calcState.current_and_plan_difference_monthly_total,
        calcState.current_and_plan_difference_monthly_total_ratio,
      ]
    : [
        calcState.current_monthly_total_cumsum,
        calcState.previous_monthly_total_cumsum,
        calcState.current_and_previous_difference_monthly_total_cumsum,
        calcState.current_and_previous_difference_monthly_total_ratio_cumsum,
        calcState.plan_monthly_total_cumsum,
        calcState.current_and_plan_difference_monthly_total_cumsum,
        calcState.current_and_plan_difference_monthly_total_ratio_cumsum,
      ];

  const monthlyRatioData = singleOrCumsumToggle
    ? [
        calcState.current_monthly_ratio,
        calcState.previous_monthly_ratio,
        calcState.current_and_previous_difference_monthly_ratio,
        null,
        calcState.monthly_ratio,
        calcState.current_and_plan_difference_monthly_ratio,
        null,
      ]
    : [
        calcState.current_monthly_ratio_cumsum,
        calcState.previous_monthly_ratio_cumsum,
        calcState.current_and_previous_difference_monthly_ratio_cumsum,
        null,
        calcState.plan_monthly_ratio_cumsum,
        calcState.current_and_plan_difference_monthly_ratio_cumsum,
        null,
      ];

  const yearlyTotalData = [
    calcState.current_yearly_total,
    calcState.previous_yearly_total,
    calcState.current_and_previous_difference_yearly_total,
    calcState.current_and_previous_difference_yearly_total_ratio,
    calcState.plans_input_total,
    calcState.current_and_plan_difference_yearly_total,
    calcState.current_and_plan_difference_yearly_total_ratio,
  ];

  const yearlyRatioData = [
    calcState.current_yearly_ratio,
    calcState.previous_yearly_ratio,
    calcState.current_and_previous_difference_yearly_ratio,
    null,
    calcState.plans_input_ratio,
    calcState.current_and_plan_difference_yearly_ratio,
    null,
  ];

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b  before:bg-cfffee2 whitespace-nowrap font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-cfffee2 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-x-c3333334d before:border-b before:bg-cfffee2`;

  const classCol4 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10   before:border-b text-right font-bold before:bg-cfffee2`;

  const classCol5 = `relative p-4px before:border-b before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 before:border-x  before:bg-cfffee2 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];
  headers?.map((_, headerIndex: number) => {
    monthlyTotalData.map((data: number[], dataIndex: number) => {
      gridRow.push({
        value:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data === null
            ? 0
            : data !== null
            ? ShowTableData(data[headerIndex] / unit)
            : 0,
        expr:
          data !== null &&
          isNaN(data[headerIndex]) &&
          (dataIndex === 3 || dataIndex === 6)
            ? `0%`
            : data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data[headerIndex])}%`
            : data === null
            ? 0
            : data !== null
            ? data[headerIndex] / unit
            : 0,
        readOnly: true,
        className: `${classCol4} before:border-b-c3333334d ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });
  });

  // 合計
  singleOrCumsumToggle &&
    yearlyTotalData.map((data: number, dataIndex: number) => {
      gridRow.push({
        value:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? ShowTableData(data / unit)
            : "",
        expr:
          data !== null && (dataIndex === 3 || dataIndex === 6)
            ? `${showPercentage(data)}%`
            : data !== null
            ? data / unit
            : "",
        readOnly: true,
        className: `${classCol4} ${titleRatio && "before:border-b-c3333334d"} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });

  gridRow.push({
    value: descriptions[totalField],
    readOnly: false,
    className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          ({titleRatio})
        </div>
      ),
      readOnly: true,
      className: `${classCol1} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];
  headers?.map((_, headerIndex: number) => {
    monthlyRatioData.map((data: number[], dataIndex: number) => {
      gridRow2.push({
        value: data !== null ? `${showPercentage(data[headerIndex])}%` : "",
        expr: data !== null ? `${showPercentage(data[headerIndex])}%` : "",
        readOnly: true,
        className: `${classCol4} ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });
  });

  // 合計
  singleOrCumsumToggle &&
    yearlyRatioData.map((data: number, dataIndex: number) => {
      gridRow2.push({
        value: data !== null ? `${showPercentage(data)}%` : "",
        expr: data !== null ? `${showPercentage(data)}%` : "",
        readOnly: true,
        className: `${classCol4} before:border-t-c3333334d before:bg-cfffee2 ${
          dataIndex !== 0 && "before:border-l-c3333334d"
        }`,
      });
    });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol5}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
