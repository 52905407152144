import React from "react";
import { useRecoilState } from "recoil";
import { signUpSpecialistStore } from "../../store";

interface Props {
  plan: {
    title: string;
    subtitle: string;
    price: string;
    description: string;
    contents: string[];
    planType: number;
  };
  isPayment: boolean;
  isPlan: boolean;
}

const MenuPlan: React.FC<Props> = ({ plan, isPayment, isPlan }) => {
  const [signUpState, setSignUpState] = useRecoilState(signUpSpecialistStore);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    {
      e.target.checked
        ? setSignUpState({ ...signUpState, plan_type: plan.planType })
        : setSignUpState({ ...signUpState, plan_type: null });
    }
  };

  return (
    <div className="w-[19.5%] relative">
      {plan.planType === 4 ? <div className="bg-baseColor opacity-50 absolute before:content-[''] top-0 bottom-0 right-0 left-0 z-[100]"></div> : ""}
      {plan.planType === 4 ? <span className="w-full text-center text-18px font-bold leading-10 absolute top-[-40px]">※準備中</span> : ''}
      <div
        className={`text-center ${
          plan.planType === 1
            ? "bg-c7cbaef"
            : plan.planType === 2
            ? "bg-c117ae3 "
            : plan.planType === 3
            ? "bg-c194cc1"
            : plan.planType === 4
            ? "bg-c22227c"
            : "bg-c273658"
        } rounded-t h-80px flex flex-col justify-center items-center leading-5`}
      >
        <div className="text-white text-26px font-bold">
          {plan.title} 
        </div>
        <div className="text-white text-11px">{plan.subtitle}</div>
      </div>
      <div className="text-center">
        <div className={`${
                  plan.planType === 1
                    ? "bg-ceef7fe"
                    : plan.planType === 2
                    ? "bg-ce6f1fc "
                    : plan.planType === 3
                    ? "bg-ce7edf9"
                    : plan.planType === 4
                    ? "bg-ce8e8f2"
                    : "bg-ceef7fe"
                }  text-34px font-bold h-75px bg-ceef7fe mt-2px flex justify-center items-center`}>
          {plan.price}
          {plan.planType !== 1 && <span className="text-16px relative bottom-[-5px]"> 円/月</span>}
        </div>
        <ul className="text-13px">
          {plan.contents.map((content, index) => (
            <React.Fragment key={index}>
              <div className={`${index == 1 ? "text-30px font-bold" : "text-15px"}  h-75px ${
                  plan.planType === 1
                    ? "bg-ceef7fe"
                    : plan.planType === 2
                    ? "bg-ce6f1fc "
                    : plan.planType === 3
                    ? "bg-ce7edf9"
                    : plan.planType === 4
                    ? "bg-ce8e8f2"
                    : "bg-ceef7fe"
                } mt-2px flex justify-center items-center`}>
                {content == 'O' ? <span className="text-30px font-bold">O</span> : content}
                {index == 1 ? <span className="text-16px relative bottom-[-5px]">件</span> : ''}
              </div>
            </React.Fragment>
          ))}
        </ul>
      </div>
      {isPayment && (
        <div className="bg-c427ed133 py-20px px-12px align-middle">
          <div className="flex items-center justify-center text-c427ed1 hover:opacity-80 bg-white font-bold w-full h-60px py-2px px-4px rounded border border-c427ed1 focus:outline-none focus:shadow-outline">
            <input
              checked={
                signUpState.plan_type === plan.planType ||
                (signUpState.plan_type === null && isPlan)
                  ? true
                  : false
              }
              className="cursor-pointer mr-8px"
              id={`${plan.planType}`}
              onChange={(e) => handleChange(e)}
              type="checkbox"
            />
            <label style={{fontSize: "9px"}} htmlFor={`${plan.planType}`}>
              {plan.subtitle}プランを使う
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPlan;
