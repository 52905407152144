import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const CostPlansInputMonthlyRatioComponent = ({
  headerTitle,
  title,
  headers,
  rows,
}: {
  headerTitle?: string;
  title?: string;
  rows: { title: string; calcState: DocumentData }[];
  headers: DocumentData[];
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white before:border-b`,
      colSpan: (headers?.length ?? 0) + 1,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white`,
      colSpan: 5,
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-x ${
      title && "before:border-l-c3333334d"
    } before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol2 = `${borderSettings} ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right `;

    const classCol3 = `relative p-4px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
      rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
    } text-right   ${
      rowIndex % 2 !== 0
        ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
        : "before:bg-cfffee2 before:border-x"
    } h-50px`;

    const gridRow: GridElement[] = [];
    gridRow.push({
      value: row.title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      gridRow.push({
        value: `${showPercentage(row.calcState.by_item[tdIndex])}%`,
        expr: showPercentage(row.calcState.by_item[tdIndex]),
        readOnly: true,
        className: `${classCol2}  ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"}`,
      });
    });

    gridRow.push({
      value: `${showPercentage(row.calcState.current_term_ratio)}%`,
      expr: showPercentage(row.calcState.current_term_ratio),
      readOnly: true,
      className: `${classCol3}`,
    });

    grid.push(gridRow);
  });

  return grid;
};
