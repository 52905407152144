import { collection, doc, writeBatch } from "firebase/firestore";
import React, { memo, useState } from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useRecoilState } from "recoil";
import { SubHeader } from "../../components/header";
import { Spinner } from "../../components/molecules";
import {
  plansInputHeader,
  plansInputMonthlyHeader,
  resultsInputHeader,
  salesPlanHeaders,
} from "../../data/firebaseModel";
import { profitAndLossPlanHeader } from "../../data/firebaseModel/profit_and_loss_plan";
import {
  balanceSheetData,
  costReportData,
  profitAndLossStatementData,
} from "../../data/firebaseModel/result_input";
import { useGetFirebaseData } from "../../firebase";
import { useHeaders } from "../../hooks";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";
import { switchLoadingState } from "../../store";
import { fiscalPeriod } from "../../utils/date";

const AccountingPeriod = () => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const { tableData } = useGetFirebaseData();

  const headers = useHeaders(tableData);

  const [period, setPeriod] = useState({
    year: headers.fiscal_year ? headers.fiscal_year : "",
    month: headers.fiscal_month ? headers.fiscal_month : "03",
    currentTerm: 1,
  });

  const [isLoading, setIsLoading] = useRecoilState(switchLoadingState);

  const date = new Date();
  const thisYear = date.getFullYear();
  const selectedYears = [
    thisYear,
    thisYear - 1,
    thisYear - 2,
    thisYear - 3,
    thisYear - 4,
    thisYear - 5,
    thisYear - 6,
    thisYear - 7,
    thisYear - 8,
    thisYear - 9,
  ];

  const selectedMonths = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const lastMonthlyData = {
    [fiscalPeriod(Number(period.year) - 1, period.month, 0)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 1)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 2)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 3)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 4)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 5)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 6)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 7)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 8)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 9)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 10)]: null,
    [fiscalPeriod(Number(period.year) - 1, period.month, 11)]: null,
  };

  const thisMonthlyData = {
    [fiscalPeriod(Number(period.year), period.month, 0)]: null,
    [fiscalPeriod(Number(period.year), period.month, 1)]: null,
    [fiscalPeriod(Number(period.year), period.month, 2)]: null,
    [fiscalPeriod(Number(period.year), period.month, 3)]: null,
    [fiscalPeriod(Number(period.year), period.month, 4)]: null,
    [fiscalPeriod(Number(period.year), period.month, 5)]: null,
    [fiscalPeriod(Number(period.year), period.month, 6)]: null,
    [fiscalPeriod(Number(period.year), period.month, 7)]: null,
    [fiscalPeriod(Number(period.year), period.month, 8)]: null,
    [fiscalPeriod(Number(period.year), period.month, 9)]: null,
    [fiscalPeriod(Number(period.year), period.month, 10)]: null,
    [fiscalPeriod(Number(period.year), period.month, 11)]: null,
  };

  const selectClass =
    "form-select appearance-none block w-full px-12px py-8px text-15px font-normal  bg-white bg-clip-padding bg-no-repeat border border-solid border-c3333334d rounded transition ease-in-out m-0 focus:bg-white focus:border-c1e88e5 focus:outline-none";

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setIsLoading(true);

    if (period.year.length === 0) {
      toast.error("決算期を選択してください");
      return;
    }

    if (headers.fiscal_year) {
      // 決算期の更新
      const batch = writeBatch(firestore);

      // ヘッダーデータの更新
      const newHeaderDoc = doc(
        firestore,
        "accounts",
        uid,
        "tables",
        String(headers.NO_ID_FIELD)
      );

      batch.update(newHeaderDoc, {
        fiscal_year: period.year,
        fiscal_month: period.month,
        results_input: resultsInputHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        plans_input: plansInputHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        plans_input_monthly: plansInputMonthlyHeader(
          period.year,
          period.month,
          null,
          headers
        ),
        results_of_the_current_term: plansInputMonthlyHeader(
          period.year,
          period.month,
          null,
          headers
        ),
        results_of_the_previous_period: plansInputMonthlyHeader(
          Number(period.year) - 1,
          period.month,
          null,
          headers
        ),
        plans_input_estimated_in_this_term: plansInputMonthlyHeader(
          period.year,
          period.month,
          null,
          headers
        ),
        sales_plan: salesPlanHeaders(Number(period.year), period.month),
        profit_and_loss_plan: profitAndLossPlanHeader(
          period.year,
          period.month
        ),
        updatedAt: new Date(),
      });

      tableData.map((data) => {
        if (data.field === "headers" || data.field === "descriptions") return;

        const updateDoc = doc(
          firestore,
          "accounts",
          uid,
          "tables",
          data.NO_ID_FIELD
        );

        batch.set(updateDoc, {
          ...data,
          results_input: {
            // [`y${String(Number(period.year) - 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 1)}${String(period.month)}`]:
            //   null,
            ...data.results_input,
          },
          plans_input: {
            // [`y${String(Number(period.year))}${String(period.month)}`]: null,
            // [`y${String(Number(period.year) + 1)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 4)}${String(period.month)}`]:
            //   null,
            ...data.plans_input,
          },
          plans_input_monthly: {
            ...thisMonthlyData,
            ...data.plans_input_monthly,
          },
          results_of_the_current_term: {
            ...thisMonthlyData,
            ...data.results_of_the_current_term,
          },
          results_of_the_previous_period: {
            ...lastMonthlyData,
            ...data.results_of_the_previous_period,
          },
          plans_input_estimated_in_this_term: {
            ...thisMonthlyData,
            ...data.results_of_the_current_term,
          },
        });
      });

      await batch
        .commit()
        .then(() => {
          toast.success("更新が完了しました");
        })
        .catch((error) => {
          toast.error("更新できませんでした");
          throw new Error(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // 初期データ
      const tablesRef = collection(firestore, "accounts", uid, "tables");

      const batch = writeBatch(firestore);

      const newHeaderDoc = doc(
        firestore,
        "accounts",
        uid,
        "tables",
        String(headers.NO_ID_FIELD)
      );

      // ヘッダーデータの更新
      batch.update(newHeaderDoc, {
        fiscal_year: period.year,
        fiscal_month: period.month,
        current_term: period.currentTerm,
        results_input: resultsInputHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        plans_input: plansInputHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        plans_input_monthly: plansInputMonthlyHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        results_of_the_current_term: plansInputMonthlyHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        results_of_the_previous_period: plansInputMonthlyHeader(
          Number(period.year) - 1,
          period.month,
          Number(period.currentTerm) - 1
        ),
        plans_input_estimated_in_this_term: plansInputMonthlyHeader(
          period.year,
          period.month,
          period.currentTerm
        ),
        results_of_the_current_term_count: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        sales_plan: salesPlanHeaders(Number(period.year), period.month),
        profit_and_loss_plan: profitAndLossPlanHeader(
          period.year,
          period.month
        ),
        payment_on_purchase: {
          next_month: 100,
          this_month: 0,
          two_month_later: 0,
        },
        sales_receipt: {
          next_month: 100,
          this_month: 0,
          two_month_later: 0,
        },
        show_kpi: {
          kpi101: true,
          kpi102: true,
          kpi103: true,
          kpi104: true,
          kpi105: true,
          kpi106: true,
          kpi107: true,
          kpi201: false,
          kpi202: false,
          kpi203: false,
          kpi204: false,
          kpi301: false,
          kpi302: false,
          kpi303: false,
          kpi304: false,
          kpi305: false,
          kpi306: false,
          kpi401: false,
          kpi402: false,
          kpi403: false,
          kpi404: false,
          kpi501: false,
          kpi502: false,
          kpi503: false,
          kpi504: false,
          kpi505: false,
          kpi506: false,
        },
        business_plan_growth: [0, 0, 0, 0, 0],
        business_plan_up: 10,
        unit: 1000,
        tax: 1.1,
        isDashboard: true,
        updatedAt: new Date(),
      });

      // Descriptionsのデータ取得
      const newDescriptionId = doc(tablesRef).id;

      const descriptionDoc = doc(
        firestore,
        "accounts",
        uid,
        "tables",
        newDescriptionId
      );

      batch.set(descriptionDoc, {
        field: "descriptions",
      });

      // PLの初期データ取得
      profitAndLossStatementData.map((data) => {
        const newId = doc(tablesRef).id;

        const newDoc = doc(firestore, "accounts", uid, "tables", newId);

        batch.set(newDoc, {
          field: data.field,
          item: data.item,
          section: data.section,
          current_and_previous_year_comparisons: { fixed: null },
          results_input: {
            // [`y${String(Number(period.year) - 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 1)}${String(period.month)}`]:
            //   null,
          },
          plans_input: {
            // [`y${String(Number(period.year))}${String(period.month)}`]: null,
            // [`y${String(Number(period.year) + 1)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 4)}${String(period.month)}`]:
            //   null,
          },
          plans_input_monthly: thisMonthlyData,
          results_of_the_current_term: thisMonthlyData,
          results_of_the_previous_period: lastMonthlyData,
          plans_input_estimated_in_this_term: thisMonthlyData,
          order: data.order,
          descriptions: {},
          isDefault: data.field === "net_sales" ? false : true,
          createdAt: new Date(),
        });
      });

      // コストの初期データ取得
      costReportData.map((data) => {
        const newId = doc(tablesRef).id;

        const newDoc = doc(firestore, "accounts", uid, "tables", newId);

        batch.set(newDoc, {
          field: data.field,
          item: data.item,
          section: data.section,
          results_input: {
            // [`y${String(Number(period.year) - 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 1)}${String(period.month)}`]:
            //   null,
          },
          plans_input: {
            // [`y${String(Number(period.year))}${String(period.month)}`]: null,
            // [`y${String(Number(period.year) + 1)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 4)}${String(period.month)}`]:
            //   null,
          },
          plans_input_monthly: thisMonthlyData,
          results_of_the_current_term: thisMonthlyData,
          results_of_the_previous_period: lastMonthlyData,
          plans_input_estimated_in_this_term: thisMonthlyData,
          order: data.order,
          descriptions: {},
          isDefault: true,
          createdAt: new Date(),
        });
      });

      // BSの初期データ取得
      balanceSheetData.map((data) => {
        const newId = doc(tablesRef).id;

        const newDoc = doc(firestore, "accounts", uid, "tables", newId);

        batch.set(newDoc, {
          field: data.field,
          item: data.item,
          section: data.section,
          current_and_previous_year_comparisons: { fixed: null },
          results_input: {
            // [`y${String(Number(period.year) - 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) - 1)}${String(period.month)}`]:
            //   null,
          },
          plans_input: {
            // [`y${String(Number(period.year))}${String(period.month)}`]: null,
            // [`y${String(Number(period.year) + 1)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 2)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 3)}${String(period.month)}`]:
            //   null,
            // [`y${String(Number(period.year) + 4)}${String(period.month)}`]:
            //   null,
          },
          order: data.order,
          descriptions: {},
          isDefault: true,
          createdAt: new Date(),
        });
      });

      await batch
        .commit()
        .then(() => {
          toast.success("設定が完了しました");
          // navigate(`/${pageConstant.ACCOUNT_TITLES}`);
        })
        .catch((error) => {
          toast.error("設定できませんでした");
          throw new Error(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <SubHeader title="決算期の設定" />
      <div className="h-screen flex flex-col justify-center items-center">
        <div className="w-4/5 h-1/2 bg-c2a4b770d flex flex-col justify-center items-center">
          <p className="w-400px text-13px mb-8px font-bold">
            基準年度・決算月を選択
          </p>
          <div className="flex gap-12px w-400px">
            <select
              className={selectClass}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setPeriod({ ...period, year: e.target.value })
              }
              value={
                period.year && headers
                  ? period.year
                  : headers
                  ? headers.fiscal_year
                  : period.year
              }
            >
              <option selected>選択してください</option>
              {selectedYears.map((selectedYear, index) => (
                <React.Fragment key={index}>
                  <option value={selectedYear}>{`${selectedYear}年`}</option>
                </React.Fragment>
              ))}
            </select>
            <select
              className={selectClass}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setPeriod({ ...period, month: e.target.value })
              }
              value={
                period.month && headers
                  ? period.month
                  : headers
                  ? headers.fiscal_month
                  : period.month
              }
            >
              {selectedMonths.map((selectedMonth, index) =>
                index === 2 ? (
                  <React.Fragment key={index}>
                    <option selected value={selectedMonth}>
                      {`${selectedMonth}月期`}
                    </option>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <option
                      value={selectedMonth}
                    >{`${selectedMonth}月期`}</option>
                  </React.Fragment>
                )
              )}
            </select>
          </div>
          <button
            className="w-280px h-60px m-30px bg-c427ed1 text-white text-15px rounded hover:opacity-80"
            disabled={isLoading}
            onClick={(e) => handleClick(e)}
          >
            {!headers || headers.fiscal_year ? "更新" : "保存"}
          </button>
          <p className="text-12px text-left">
            基準年度にはシミュレーションに使う直近の決算期を入力してください
          </p>
        </div>
      </div>
      {isLoading && (
        <div className="w-screen h-screen fixed top-0 left-0 opacity-70 z-[99999] bg-white">
          <div className="flex justify-center items-center w-full h-full">
            <Spinner />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(AccountingPeriod);
