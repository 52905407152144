import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { getStoredClientUid } from "../../../sessionStorage/sessionStorage";
import { DarkButton } from "../../atoms";

export const TaxInput = ({ isInBorder }: { isInBorder?: boolean }) => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const [value, setValue] = useState(1);

  const preventMinus = (e: { code: string; preventDefault: () => void }) => {
    if (e.code === "Minus" || e.code === "NumpadSubtract") {
      e.preventDefault();
    }
  };

  const handleChange = async () => {
    const dataRef = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      String(resultsAndPlansDataState.headers.NO_ID_FIELD)
    );

    await updateDoc(dataRef, {
      tax: Number(value),
    })
      .then(() => {
        toast.success("消費税率を更新しました");
      })
      .catch((error) => {
        toast.error("消費税率を更新できませんでした");
        throw new Error(error.message);
      });
  };

  useEffect(() => {
    setValue(resultsAndPlansDataState.headers.tax);
  }, [resultsAndPlansDataState.headers.tax]);

  return (
    <div className="flex items-center m-4px">
      <input
        autoFocus={true}
        className={`px-8px w-full text-right ${
          isInBorder && "border-2 border-c1e88e5 rounded-md"
        }`}
        onChange={(e) => setValue(e.target.value as unknown as number)}
        onKeyDown={preventMinus}
        value={value}
      />
      <DarkButton className="h-auto ml-8px" onClick={() => handleChange()}>
        更新
      </DarkButton>
    </div>
  );
};
