import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { useEstimatedInThisTermInitialData } from "../../../hooks/initialData";
import {
  CostEstimatedInThisTermTable,
  PlEstimatedInThisTermTable,
} from "../../budgetControl/estimatedInThisTerm";
import { Spinner } from "../../molecules";

interface Props {
  url: string;
}

export const EstimatedInThisTermContainer: React.FC<Props> = ({ url }) => {
  const estimatedInThisTermCalcState = useEstimatedInThisTermInitialData();

  if (!estimatedInThisTermCalcState) {
    return <Spinner />;
  }

  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlEstimatedInThisTermTable
        calcState={estimatedInThisTermCalcState}
        url={url}
      />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostEstimatedInThisTermTable
        calcState={estimatedInThisTermCalcState}
        url={url}
      />
    </InputLayout>
  );
};
