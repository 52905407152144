import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CashFlowTableTotal = ({
  title,
  subTitle,
  itemTitle,
  monthlyTotal,
  monthlyTotalTotal,
  key,
  isPreGrandTotal,
  isGrandTotal,
  isSticky,
  descriptions,
  unit,
}: {
  title: string;
  subTitle: string;
  itemTitle: string;
  headers: DocumentData[];
  monthlyTotal: number[];
  monthlyTotalTotal: number;
  url: string;
  dataUrl: string;
  key: string;
  isPreGrandTotal?: boolean;
  isGrandTotal?: boolean;
  isSticky?: boolean;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `sticky before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full ${
    isSticky ? "top-[104px]" : "top-0"
  } left-0 p-4px bg-white font-bold ${
    title === "前月繰越金" ? "z-50" : "z-40"
  } before:border-l`;

  const classCol2 = `${beforeWrapper} ${isSticky && "top-[104px]"} ${
    title === "前月繰越金" ? "z-50" : "z-30"
  } left-120px p-4px bg-white font-bold before:border-l before:border-l-c3333334d`;

  const classCol3 = `${beforeWrapper} ${isSticky && "top-[104px]"} ${
    title === "前月繰越金" ? "z-50" : "z-30"
  } left-240px p-4px bg-white before:border-b`;

  const classCol4 = `${beforeWrapper} ${
    isSticky && "top-[104px] z-40"
  } left-380px p-4px bg-white  before:border-l before:border-l-c3333334d before:border-b`;

  const classCol5 = `${beforeWrapper} ${
    isSticky && "top-[104px] z-40"
  } left-425px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b`;

  const classCol6 = `${borderSettings} ${
    isSticky && "sticky top-[104px] bg-white z-30"
  } text-right font-bold before:bg-c2a4b770d`;

  const classCol7 = `${borderSettings} ${
    isSticky && "sticky top-[104px] bg-white z-30"
  } text-right font-bold`;

  const classCol8 = `relative ${
    isSticky && "sticky top-[104px] bg-white z-30"
  } p-4px bg-cfffee2 before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0  text-right font-bold focus:text-left`;

  const classCol9 = `description ${borderSettings} ${
    isSticky && "sticky top-[104px] bg-white z-30"
  } before:border-b before:border-x text-left font-bold before:bg-c7fb28d33 h-50px`;

  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {title}
        </div>
      ),
      readOnly: true,
      className: `${classCol1} ${
        (isGrandTotal || isPreGrandTotal) && "before:border-b"
      }`,
    },
    {
      value: subTitle,
      readOnly: true,
      className: `${classCol2} ${
        (isGrandTotal || isPreGrandTotal) && "before:border-l-0"
      } before:border-b ${
        !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
      } before:border-l-c3333334d`,
    },
    {
      value: itemTitle,
      readOnly: true,
      className: `${classCol3}  ${
        !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4}  ${
        !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5}  ${
        !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
      }`,
    },
  ];

  // 前年度実績
  gridRow.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol6} before:border-b   ${
      !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
    }`,
  });

  gridRow.push({
    value: "",
    expr: "",
    readOnly: true,
    className: `${classCol6} before:border-b  ${
      !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
    }`,
  });

  const totalData =
    monthlyTotal && monthlyTotal.length !== 0
      ? monthlyTotal
      : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  totalData.map((total: number) => {
    gridRow.push({
      value: isNaN(total) ? 0 : ShowTableDataTriangle(total / unit),
      expr: isNaN(total) ? 0 : total / unit,
      readOnly: true,
      className: `${classCol7} before:border-b   ${
        !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
      }`,
    });
  });

  gridRow.push({
    value: isNaN(monthlyTotalTotal)
      ? 0
      : ShowTableDataTriangle(monthlyTotalTotal / unit),
    expr: isNaN(monthlyTotalTotal) ? 0 : monthlyTotalTotal / unit,
    readOnly: true,
    className: `${classCol8} before:border-b   ${
      !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
    }`,
  });

  gridRow.push({
    value: descriptions[key] ? descriptions[key] : "",
    expr: descriptions[key] ? descriptions[key] : "",
    readOnly: false,
    className: `${classCol9} ${
      !(isGrandTotal || isPreGrandTotal) && "before:border-b-c3333334d"
    }`,
    field: key,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow);

  return grid;
};
