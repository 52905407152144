import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const PlPlansInputMonthlySimpleCf = ({
  headers,
  row1,
  row2,
  row3,
  field1,
  field2,
  field3,
  isCurrentPlanMonthly,
  unit,
  descriptions,
}: {
  headers: DocumentData[];
  row1: DocumentData;
  row2: DocumentData;
  row3: DocumentData;
  field1: string;
  field2: string;
  field3: string;
  isCurrentPlanMonthly?: boolean;
  unit: number;
  descriptions: DocumentData;
}) => {
  const totalByItemRow1 = sum(row1.monthly_total);
  const plansInputTotalRow1 = round(sum(row1.plans_input_total));
  const reminingNumberRow1 = round(sum(row1.remining_total));
  const yearlyTotalRow1 = round(sum(row1.yearly_total));
  const totalByItemRow2 = sum(row2.monthly_total);
  const plansInputTotalRow2 = round(sum(row2.plans_input_total));
  const reminingNumberRow2 = round(sum(row2.remining_total));
  const yearlyTotalRow2 = round(sum(row2.yearly_total));
  const totalByItemRow3 = sum(row3.monthly_total);
  const plansInputTotalRow3 = round(sum(row3.plans_input_total));
  const reminingNumberRow3 = round(sum(row3.remining_total));
  const yearlyTotalRow3 = round(sum(row3.yearly_total));

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white  before:bg-c80b0f433 before:border-l font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px  bg-white  before:bg-c80b0f433`;

  const classCol3 = `${beforeWrapper} left-220px p-4px  bg-white  before:bg-c80b0f433 `;

  const classCol4 = `${borderSettings}   text-right bg-c80b0f433`;

  const classCol5 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  w-120px  text-right  before:bg-cfffee2`;

  const classCol6 = `${borderSettings} bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "簡易CF",
      readOnly: true,
      className: `${classCol1} before:border-y before:border-b-c3333334d before:border-r-c3333334d`,
      colSpan: 2,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          経常利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-y   before:border-b-c3333334d before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-y   before:border-b-c3333334d before:border-r  before:border-r-c3333334d`,
    },
  ];

  headers?.map((_, tdIndex) => {
    gridRow.push({
      value: isNaN(row1.monthly_total[tdIndex])
        ? 0
        : isCurrentPlanMonthly
        ? ShowTableDataTriangle(row1.monthly_total[tdIndex] / unit)
        : ShowTableData(row1.monthly_total[tdIndex] / unit),
      expr: isNaN(row1.monthly_total[tdIndex])
        ? 0
        : row1.monthly_total[tdIndex],
      readOnly: true,
      className: `${classCol4} before:border-t before:border-b-c3333334d`,
    });
  });
  // 年度貼付
  gridRow.push({
    value: isCurrentPlanMonthly
      ? ShowTableDataTriangle(plansInputTotalRow1 / unit)
      : ShowTableData(plansInputTotalRow1 / unit),
    expr: plansInputTotalRow1 / unit,
    readOnly: true,
    className: `${classCol5} before:border-t before:border-b-c3333334d`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(totalByItemRow1) ? 0 : ShowTableData(totalByItemRow1 / unit),
      expr: isNaN(totalByItemRow1) ? 0 : totalByItemRow1 / unit,
      readOnly: true,
      className: `${classCol5} before:border-t before:border-b-c3333334d`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(row1.current_term_total / unit),
      expr: row1.current_term_total / unit,
      readOnly: true,
      className: `${classCol5} before:border-t before:border-b-c3333334d`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: ShowTableData(reminingNumberRow1 / unit),
      expr: reminingNumberRow1 / unit,
      readOnly: true,
      className: `${classCol5} before:border-t before:border-b-c3333334d`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow.push({
      value: isNaN(yearlyTotalRow1) ? 0 : ShowTableData(yearlyTotalRow1 / unit),
      expr: isNaN(yearlyTotalRow1) ? 0 : yearlyTotalRow1 / unit,
      readOnly: true,
      className: `${classCol5} before:border-t before:border-b-c3333334d`,
    });

  gridRow.push({
    value: field1 && descriptions[field1],
    expr: field1 && descriptions[field1],
    readOnly: false,
    className: `${classCol6} before:border-t before:border-r before:border-b-c3333334d`,
    field: field1,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} before:border-b`,
      colSpan: 2,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          当純利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-b before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r  before:border-r-c3333334d`,
    },
  ];
  headers?.map((_, tdIndex) => {
    gridRow2.push({
      value: isNaN(row2.monthly_total[tdIndex])
        ? 0
        : isCurrentPlanMonthly
        ? ShowTableDataTriangle(row2.monthly_total[tdIndex] / unit)
        : ShowTableData(row2.monthly_total[tdIndex] / unit),
      expr: isNaN(row2.monthly_total[tdIndex])
        ? 0
        : row2.monthly_total[tdIndex] / unit,
      readOnly: true,
      className: classCol4,
    });
  });
  // 年度貼付
  gridRow2.push({
    value: isCurrentPlanMonthly
      ? ShowTableDataTriangle(plansInputTotalRow2 / unit)
      : ShowTableData(plansInputTotalRow2 / unit),
    expr: plansInputTotalRow2 / unit,
    readOnly: true,
    className: `${classCol5}`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: isNaN(totalByItemRow2) ? 0 : ShowTableData(totalByItemRow2 / unit),
      expr: isNaN(totalByItemRow2) ? 0 : totalByItemRow2 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: ShowTableData(row2.current_term_total / unit),
      expr: row2.current_term_total / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: ShowTableData(reminingNumberRow2 / unit),
      expr: reminingNumberRow2 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: isNaN(yearlyTotalRow2) ? 0 : ShowTableData(yearlyTotalRow2 / unit),
      expr: isNaN(yearlyTotalRow2) ? 0 : yearlyTotalRow2 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });

  gridRow2.push({
    value: field2 && descriptions[field2],
    expr: field2 && descriptions[field2],
    readOnly: false,
    className: `${classCol6} before:border-x  before:border-l-c3333334d`,
    field: field2,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow2);

  // 3行目の値
  const gridRow3: GridElement[] = [
    {
      value: "粗付加価値額*1)",
      readOnly: true,
      className: `${classCol1} before:border-b before:border-l whitespace-nowrap`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r before:border-r-c3333334d whitespace-nowrap`,
    },
  ];
  headers?.map((_, tdIndex) => {
    gridRow3.push({
      value: isNaN(row3.monthly_total[tdIndex])
        ? 0
        : isCurrentPlanMonthly
        ? ShowTableDataTriangle(row3.monthly_total[tdIndex] / unit)
        : ShowTableData(row3.monthly_total[tdIndex] / unit),
      expr: isNaN(row3.monthly_total[tdIndex])
        ? 0
        : row3.monthly_total[tdIndex] / unit,
      readOnly: true,
      className: classCol4,
    });
  });
  // 年度貼付
  gridRow3.push({
    value: isCurrentPlanMonthly
      ? ShowTableDataTriangle(plansInputTotalRow3 / unit)
      : ShowTableData(plansInputTotalRow3 / unit),
    expr: plansInputTotalRow3 / unit,
    readOnly: true,
    className: `${classCol5}`,
  });
  // 月次貼付
  !isCurrentPlanMonthly &&
    gridRow3.push({
      value: isNaN(totalByItemRow3) ? 0 : ShowTableData(totalByItemRow3 / unit),
      expr: isNaN(totalByItemRow3) ? 0 : totalByItemRow3 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 実績
  !isCurrentPlanMonthly &&
    gridRow3.push({
      value: ShowTableData(row3.current_term_total / unit),
      expr: row3.current_term_total / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 残
  !isCurrentPlanMonthly &&
    gridRow3.push({
      value: ShowTableData(reminingNumberRow3 / unit),
      expr: reminingNumberRow3 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  // 年間計
  !isCurrentPlanMonthly &&
    gridRow3.push({
      value: isNaN(yearlyTotalRow3) ? 0 : ShowTableData(yearlyTotalRow3 / unit),
      expr: isNaN(yearlyTotalRow3) ? 0 : yearlyTotalRow3 / unit,
      readOnly: true,
      className: `${classCol5}`,
    });
  gridRow3.push({
    value: field3 && descriptions[field3],
    expr: field3 && descriptions[field3],
    readOnly: false,
    className: `${classCol6} before:border-x before:border-l-c3333334d`,
    field: field3,
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow3);

  // 注釈
  const gridRow4: GridElement[] = [
    {
      value:
        "*1) 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white`,
      colSpan: 10,
    },
  ];

  grid.push(gridRow4);

  return grid;
};
