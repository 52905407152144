import React from "react";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const DarkButton: React.FC<Props> = ({ children, onClick, disabled, className }) => {
  return (
    <button
      className={`hover:opacity-80  font-bold w-full h-60px py-2px px-4px rounded border focus:outline-none focus:shadow-outline ${className} ${
        disabled ? "bg-c33333333 border-c33333333 text-black" : "bg-c427ed1 border-c427ed1 text-white"
      }`}
      onClick={onClick}
      disabled={disabled}
      type="submit"
    >
      {children}
    </button>
  );
};

export default DarkButton;
