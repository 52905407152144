import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { pageConstant } from "../constants/pageConstants";
import { queryConstants } from "../constants/queryConstants";
import { clearStoredClientUid } from "../sessionStorage/sessionStorage";

// ログアウト
export const Logout = (
  queryClient: QueryClient,
  status: "success" | "error",
  message: string
) => {
  queryClient.setQueriesData(queryConstants.SPECIALISTS, undefined);
  queryClient.setQueriesData(queryConstants.CLIENTS, undefined);
  status === "success" ? toast.success(message) : toast.error(message);
  setTimeout(() => {
    clearStoredClientUid();
    window.location.assign(`/${pageConstant.LOGIN}`);
  }, 3000);
};
