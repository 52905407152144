import * as React from "react";
import { InputLayout } from "../../../components/planMaking/resultsInput";
import { usePlansInputMonthlyInitialData } from "../../../hooks/initialData";
import { Spinner } from "../../molecules";
import {
  CostPlansInputMonthlyTable,
  PlPlansInputMonthlyTable,
} from "../plansInputMonthly";

interface Props {
  url: string;
  descUrl?: string;
  isCurrentPlanMonthly?: boolean;
}

export const PlansInputMonthlyContainer: React.FC<Props> = ({
  url,
  descUrl,
  isCurrentPlanMonthly,
}) => {
  const plansInputMonthlyCalcState = usePlansInputMonthlyInitialData();

  if (!plansInputMonthlyCalcState) {
    return <Spinner />;
  }

  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlPlansInputMonthlyTable
        calcState={plansInputMonthlyCalcState}
        descUrl={descUrl}
        isCurrentPlanMonthly={isCurrentPlanMonthly}
        url={url}
      />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostPlansInputMonthlyTable
        calcState={plansInputMonthlyCalcState}
        descUrl={descUrl}
        isCurrentPlanMonthly={isCurrentPlanMonthly}
        url={url}
      />
    </InputLayout>
  );
};
