// eslint-disable-next-line import/order
import React from "react";
import "../../css/react_datasheet.css";

import { SubHeader } from "../../components/header";
import { CashFlowTableContainer } from "../../components/managementSimulation/container";
import { pageConstant } from "../../constants/pageConstants";

export const CashFlowTable = () => {
  const url = pageConstant.CASH_FLOW_TABLE;
  const dataUrl = pageConstant.PLANS_INPUT_MONTHLY;

  return (
    <React.Fragment>
      <SubHeader title="資金繰り予定表" />
      <CashFlowTableContainer dataUrl={dataUrl} url={url} />
    </React.Fragment>
  );
};
