import React from "react";

export const Spinner = () => {
  const circleCommonClasses = "h-8px w-8px bg-c1b2e48 rounded-full";

  return (
    <div className="flex">
      <div className={`${circleCommonClasses} mr-8px animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-8px animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};
