import React from "react";

const TermOfService = () => {
  return (
    <>
      <h1 className="my-60px pb-12px text-24px font-bold border-b-4 border-b-c1e88e5 leading-10">
        クラウドプランナー利用規約
      </h1>

      <section className="mb-30px w-full">
        <p className="leading-7">
          この利用規約（以下「本利用規約」といいます。）は、らくだシステム株式会社（以下「当社」といいます。）が提供するクラウド型財務分析予実管理サービス「クラウドプランナー」（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本利用規約は、本サービスを利用する全ての方（以下「利用者」といいます。）に適用されます。本サービスをご利用になる前に、本利用規約の内容をよくお読みください。本利用規約に同意されない場合は、本サービスを利用することはできません。
        </p>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第１条 （目的）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．本利用規約は、本サービスの利用（パソコン、スマートフォンその他の利用端末の種類を問わず、また、ウェブサイト及びアプリケーションを通じた利用を含みます。）に関する条件及び当社と利用者との間の権利義務関係を定めることを目的としています。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．利用者は、本サービスを利用するにあたり、本利用規約を遵守するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．利用者は、本利用契約の期間中、本サービスの通常想定される利用目的の範囲内で、本利用規約に従って本サービスを利用することができます。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第２条 （定義）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            本利用規約において使用する以下の用語は、以下に定める意味を有するものとします。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （１）「利用者」とは、第3条に基づき当社との間で本利用契約を締結した日本国内の法人及び団体並びに当社が本サービスの利用を許諾した者をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （２）「本利用契約」とは、当社と利用者との間で締結される本サービスの利用に関する契約をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （３）「当社サイト」とは、当社が運営するウェブサイト「クラウドプランナー」をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （４）「利用料金」とは、本サービスの利用の対価として当社が定める一切の料金及び費用をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （５）「コンテンツ」とは、利用者が本サービス又は当社サイト上でアクセスすることができる一切の情報（文章、画像、動画、音声その他のデータを含みますが、これらに限りません。）をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （６）「ユーザーコンテンツ」とは、利用者が本サービスにアップロード又は送信するコンテンツをいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （７）「知的財産権」とは、特許権、実用新案権、商標権、意匠権、著作権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む。）をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （８）「反社会的勢力等」とは、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （９）「法令等」とは、法律、政令、省令、通達、規則、条例、裁判所の判決、決定、命令又は強制力のある行政処分、ガイドラインその他の規制の総称をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （10）
            「利用期間」とは、利用者が当社サイトを相当程度の頻度で利用する期間をいいます。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （11）「個人情報」とは、個人に関する情報であって、個人情報の保護に関する法律第2条第1項により定義されたものをいいます。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第３条 （本サービスの申込）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本利用規約を遵守することに同意し、かつ当社の定める情報（以下「登録情報」といいます。）を当社指定の方法で当社に提供することにより、登録を申請することができます。なお、法人その他の団体に所属する個人が当該団体を代表して登録の申請を行った場合、当該個人は、当該団体を代表して申し込みを行う正当な権限を有するものとみなします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．登録希望者は登録の申請を行う時点で、本利用規約に同意したものとみなします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．当社は、当社の基準に従って登録の可否を判断し、その結果を登録希望者に通知します。当社が登録を認める旨の通知を行った時点で登録が完了し、本利用契約は成立します。
          </p>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              ４．登録希望者が以下の各号のいずれかに該当し又は該当するおそれがあると当社が判断した場合には、登録を拒否することができます。なお、登録申請が承認されなかったとしても、当社はその理由を開示する義務を負いません。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）「利用者」とは、第3条に基づき当社との間で本利用契約を締結した日本国内の法人及び団体並びに当社が本サービスの利用を許諾した者をいいます。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）反社会的勢力等に所属し若しくは過去に所属していた場合、その他反社会的勢力等と何らかの交流若しくは関与等の疑いがある場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）過去に、本サービスその他当社のサービスの利用登録の拒否若しくは取消等の措置を受けた場合、第10条に基づく利用停止の措置を受け若しくは本利用契約を解約された場合、又は当社との契約その他の合意に違反した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）その他当社が登録を不適当と認める場合
            </p>
          </div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ５．本利用契約の有効期間中に利用者が前項の各号のいずれかに該当した場合、当該利用者はただちに当社に通知しなければならないものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            利用者は、登録情報に誤り等があった場合又は変更が生じた場合、当社所定の方法により、当該内容を当社に通知し又は本サービス上で自ら修正、変更しなければなりません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第４条 （アカウント）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．利用者は、ID、パスワードその他自己のアカウントに関する情報を、自己の責任において安全に管理・保管し、第三者による不正使用を防止するために必要な措置を講じるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．利用者は、自己のアカウントを第三者に貸与、共有、譲渡、名義変更その他の方法により第三者に使用させてはなりません。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．当社は、IDとパスワードの一致を確認した場合、当該ID及びパスワードの保有者として登録された利用者が本サービスを利用したものとみなします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．利用者は、本サービスのアカウントの不正利用又は第三者による使用又はそれらのおそれが判明した場合には、ただちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第５条 （利用料金）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．利用者は当社に対し、利用料金として申込時に選択したプランに応じて決められたれた月額利用料金を支払うものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．利用者は利用料金を、本サービスが定めるクレジットカード決済サービス経由で支払うものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．利用者が、第1項に定める利用料金の支払いを遅滞した場合、年6％の割合による遅延損害金を当社に支払うものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．当社は、本サービスの利用に関する支払いの決済及び情報処理に、第三者決済処理サービスを利用する場合があります。当社は、当社の故意又は重過失に起因する場合を除き、第三者決済処理サービスの利用に起因又は関連する論争、クレーム、訴訟、権利侵害又は損害について一切の責任を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第６条 （禁止事項）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            利用者は、本サービスの利用に関して、自己又は第三者をして、以下の各号の行為を行わないものとします。
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （１）本利用規約に違反する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （２）法令等に違反する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （３）犯罪行為又は犯罪を助長する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （４）当社又は第三者に対する詐欺又は脅迫行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （５）公序良俗に反する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （６）反社会的勢力等に対する利益供与その他反社会的勢力等に関与する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （７）当社又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （８）当社又は第三者を誹謗中傷する行為
          </p>
          <div>
            <p className="pl-[64px] -indent-[48px] leading-7">
              （９）以下の情報を本サービスを通じて発信する行為
            </p>
            <p className="pl-[64px] leading-7">
              ① 当社又は第三者の名誉又は信用を毀損する表現を含む情報
            </p>
            <p className="pl-[64px] leading-7">
              ② 暴力的又は残虐な表現を含む情報
            </p>
            <p className="pl-[64px] leading-7">③ わいせつ な表現を含む情報</p>
            <p className="pl-[64px] leading-7">
              ④ 差別を助長する表現を含む情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑤ 自殺、自傷行為等又は薬物の不適切な利用を助長する表現を含む情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑥ フェイクニュース等の虚偽の情報又はその拡散を求める情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑦ スパム、チェーンメール等の情報の拡散を求める情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑧ 違法な勧誘、宣伝等を含む情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑨ 他人に不快感を与える表現を含む情報
            </p>
            <p className="pl-[64px] leading-7">
              ⑩ 適法かつ正当な権利又は許諾を得ていない個人情報その他の情報
            </p>
            <p className="pl-[64px] leading-7">⑪ 違法な方法で入手した情報</p>
            <p className="pl-[64px] leading-7">
              ⑫
              コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報
            </p>
            <p className="mb-12px pl-[64px] leading-7">
              ⑬ 宗教の勧誘を目的とする情報
            </p>
          </div>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （10）虚偽の登録情報を提供する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （11）第三者に成りすます行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （12）他の利用者のアカウントを利用する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （13）他の利用者のアカウント情報等を収集する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （14）本利用規約で明示的に認められる場合又は別途当社が認める場合を除き、本サービスを通じて入手した情報を、複製、販売、出版その他利用する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （15）本利用規約において明示的に認められる場合又は別途当社が認める場合を除き、第三者に利益を与える目的で利用する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （16）本サービスの一部を複製、頒布又は不正に開示する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （17）当社のネットワーク又はシステム等に過度な負荷をかける行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （18）当社のネットワーク又はシステム等へ不正にアクセスする行為又はそれを試みる行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （19）本サービス又は当社サイトに関連するプログラムを変更、削除、逆コンパイル、逆アセンブル又はリバースエンジニアリングする行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （20）本サービスの運営を妨害する行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （21）当社の信用を毀損し又はそのおそれのある行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （22）当社又は第三者に損害等を与える行為
          </p>
          <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
            （23）その他当社が不適当と認める場合
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第７条 （非保証）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．本サービスを利用するために必要な機器、通信回線その他の利用環境は、利用者が自らの責任と費用負担で用意するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．当社は、本サービスが、利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性・完全性・安全性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。ただし、本サービスにセキュリティ上の欠陥、エラー、バグその他の不具合が存在する場合、当社は実務上可能な限りこれを修正するよう努めるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．当社は、明示又は黙示を問わず、本サービスを通じて提供されるユーザーコンテンツその他の当社以外の第三者が提供する情報に関して何ら保証するものではありません。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．当社は、利用者が本サービスにアップロードするデータが、本サービスの利用に起因して消失、消滅、変化等しないことについて保証を行うものではありません。利用者は、自己の責任において、データの管理をし、適宜バックアップ等を行ってから本サービスを利用するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ５．当社は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。利用者は、自己の責任において、外部サービスを利用するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ６．利用者は、本サービスの利用に関連して第三者との間で生じた取引、連絡、紛争等については、自己の費用負担と責任において対応及び解決するものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第８条 （知的財産権等）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．本サービスに関する知的財産権は全て当社又は当社に使用を許諾する正当な権利者（以下本条において「当社等」といいます。）に帰属しており、本利用契約の成立又は本サービスの利用の許諾は、利用者に対して本サービスの利用を超える当社等の知的財産権の利用許諾を意味するものではありません。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．本利用規約に明示的に規定されている場合を除き、本サービス及び本利用規約に関連して当社が利用者に提供した文章、画像、映像、音声、プログラム等一切のコンテンツに関する知的財産権その他一切の権利及び権限は、当社等に帰属します。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．利用者は、ユーザーコンテンツについて、自らがデータ送信等を行う適法な権利を有していること、及び当該データが第三者の権利を侵害していないことについて、当社に表明し、保証するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．ユーザーコンテンツの知的財産権は利用者に帰属します。利用者は当社に対し、本サービス上にコンテンツをアップロードした時点で、本サービス提供上必要な範囲における複製、翻案、公衆送信、派生著作物の作成、表示及び実行に関するライセンスを無償にて付与するものとします。なお、当該コンテンツについて利用者は著作者人格権を行使しないものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第９条 （データの利用）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            当社は、登録データその他の利用者に関するデータ（ただし、個人情報に該当するものを除きます。）を、本サービスを含む当社が提供し又は提供しようとしているサービスの品質向上、これらのサービスに関する利用者へのご案内、その他当社がプライバシーポリシーに定める目的で利用することができるものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第10条 （利用停止及び解約）
        </h2>
        <div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              １．利用者が以下の各号のいずれかの事由に該当すると当社が判断した場合、当社は事前に通知することなく、当該利用者による本サービスへのアクセスを禁止し、又は当該利用者が本サービスに関して当社に提供した情報の全部若しくは一部を削除することができるものとします（以下、総称して「利用停止」といいます。）。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）本利用規約のいずれかの条項に違反した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）第3条第4項各号の登録拒否事由のいずれかに該当した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）利用料金その他本利用契約に基づく支払いを期日に行わず、当社の催促から7日以内に支払遅延が解消されない場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）本サービスに関する不正な支払いが判明した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （５）前各号に準ずる事態が生じた場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （６）その他本サービスの利用又は登録が不適当である場合
            </p>
          </div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              ２．利用者が以下の各号のいずれかの事由に該当する場合、当社は利用者に通知の上、ただちに本利用契約を解約することができます。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）前項各号のいずれかに該当した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）利用者が支払停止又は支払不能となった場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）利用者について破産、民事再生、会社更生、特別清算、又はこれらに類する手続の開始の申立てがあった場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）利用者について解散又は清算の手続開始が決議又は決定された場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （５）個人事業主である利用者が死亡した場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （６）当社が利用者の本サービスの利用、登録又は本利用契約の継続を適当でないと判断した場合
            </p>
          </div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．前二項に定める措置は、当社から当該利用者に対する損害賠償請求を行うことを妨げるものではありません。また、前二項に定める措置を行ったことについて、当社は一切の損害賠償義務、金銭返還義務を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第11条 （利用者による解約）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．利用者が所定の日時までに当社の定める本サービスの解約手続きを行った場合、本サービスの利用期間の満了により契約終了の効力が生じます。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．利用者は、本利用契約が終了した場合であっても、当社及び第三者に対して既に生じた本利用契約上の一切の義務及び債務（損害賠償義務を含みますが、これに限りません。）を免れるものではありません。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．利用者が所定の日時までに当社の定める本サービスの解約手続きを行わない場合、本サービスの利用期間の満了後に当社所定の条件に従って自動更新されます。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第12条 （本サービスの停止）
        </h2>
        <div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              １．当社は以下のいずれかに該当する場合には、利用者に事前に通知することにより、本サービスの全部又は一部の提供を停止できるものとします。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）本サービスに関わるハードウェア、ソフトウェア、通信機器その他関連する機器若しくはシステムの点検又は保守作業を定期的に又は緊急に行う場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）コンピューター若しくは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング、その他予期せぬ要因により本サービスの提供が困難となった場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）本サービスに関するセキュリティ上の問題が生じた場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）天災地変、戦争、戦争のおそれ、封鎖、通商停止、革命、暴動、伝染病若しくはその他の疫病、物資若しくは施設の破壊若しくは損傷、火災、台風、地震、洪水、その他当社の支配を超える事由
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （５）法令等又はこれらに基づく措置により本サービスの運営が困難となった場合
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （６）その他前各号に準じる事由により当社が必要と判断した場合
            </p>
          </div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．前項により当社が行った措置によって生じた損害について、一切の責任を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第13条 （本サービスの変更・追加）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．当社は、当社の都合により、本サービスの性質に重大な変更が生じない範囲で本サービスの内容の変更又は追加ができるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．前項により当社が行った措置によって生じた損害について、一切の責任を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第14条 （本サービスの終了）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．当社は、利用者へ事前に通知することにより、本サービスの全部又は一部の提供を終了できるものとします。ただし、緊急の場合その他事前の通知が困難な場合には、事後に通知するものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．本サービスの全部を終了する場合、当社は利用者に対して終了予定日の６か月前までに通知を行うものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．本サービス終了後の期間に対応する利用料金を支払い済の場合、当該期間にかかる利用料金の返金を行います。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．第1項及び第2項により当社が行った措置によって生じた損害について、一切の責任を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第15条 （損害賠償）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．利用者による本利用規約に違反する行為又は本サービスに関連して利用者の責めに帰すべき事由により当社又は第三者に損害が生じたときは、利用者はその一切の損害につき賠償する義務を負うものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．当社は、本サービス又は本利用規約に関連して利用者に生じた損害につき、当社の故意又は重過失に基づく場合を除き、一切の責任を負いません。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．当社の故意又は重過失により利用者に損害（現実に生じた直接かつ通常の損害に限る。）が生じた場合の当社の責任賠償額の総額は、直近６か月に当社が利用者から受領した利用料金の合計金額を超えないものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第16条 （個人情報の取扱い）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            当社は、利用者に関する個人情報を、当社の個人情報保護方針に従った当社プライバシーポリシー（https://www.rakudasystem.co.jp/privacypolicy/）に従って取扱います。利用者は、当社がこのプライバシーポリシーに従って個人情報を取り扱うことに同意するものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第17条 （反社会的勢力の排除）
        </h2>
        <div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              １．当社及び利用者は、自ら又はその役員（取締役、執行役、執行役員、監査役又はこれらに準ずる者をいう。）若しくは従業員において、反社会的勢力等に該当しないこと、及び次の各号のいずれにも該当せず、かつ将来にわたっても該当しないことを確約し、これを保証するものとします。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）反社会的勢力等が経営を支配していると認められる関係を有すること
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）反社会的勢力等が経営に実質的に関与していると認められる関係を有すること
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）反社会的勢力等に対して暴力団員等であることを知りながら資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （５）役員又は経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること
            </p>
          </div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              ２．当社及び利用者は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約し、これを保証します。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）暴力的な要求行為
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）法的な責任を超えた不当な要求行為
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （３）取引に関して、脅迫的な言動をし、又は暴力を用いる行為
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （４）風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （５）その他前各号に準ずる行為
            </p>
          </div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．当社及び利用者は、相手方が本条に違反した場合には、催告その他の手続きを要しないで、ただちに本利用契約を解除することができるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．当社及び利用者は、本条に基づく解除により相手方に損害が生じた場合であっても、当該損害の賠償義務を負いません。また、当該解除に起因して自己に生じた損害につき、相手方に対し損害賠償請求することができるものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第18条 （本利用規約の変更）
        </h2>
        <div>
          <div>
            <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
              １．当社は、以下の各号のいずれかに該当する場合、本利用規約を変更することができるものとします。
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （１）本利用規約の変更が、利用者の一般の利益に適合するとき
            </p>
            <p className="mb-12px pl-[64px] -indent-[48px] leading-7">
              （２）本利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものであるとき
            </p>
          </div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．前項の場合、当社は、変更後の本利用規約の効力発生日の30日前までに、本利用規約を変更する旨及び変更後の本利用規約の内容とその効力発生日を、当社が適切と判断する方法（当社サイト・本サービス内への掲示又は利用者に電子メールを送信する方法等）により通知します。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ３．本利用規約の変更に同意しない利用者は、前項に定める変更の効力発生日の前日までに、本利用契約を解約するものとします。効力発生日までに本利用契約の解約を行わず、本サービスの利用を継続した場合には、当該変更に同意したものとみなします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ４．第1項に定める他、当社は、利用者の同意を得ることにより本利用規約を変更することができるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ５．当社は、本条に基づいた本利用規約の変更により利用者に損害が生じたとしても、一切の責任を負いません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第19条 （権利義務の譲渡禁止）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            利用者は、当社の事前の書面による承諾なく、本利用契約上の地位又は本利用契約に基づく権利若しくは義務を、第三者に譲渡、移転（合併若しくは会社分割による場合を含みます。）、担保設定又はその他の処分をしてはなりません。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第20条 （分離可能性）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            本利用規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定又は部分は、継続して完全に効力を有するものとし、当社と利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第21条 （協議）
        </h2>
        <div>
          <p className="mb-12px leading-7">
            本利用規約の解釈に疑義が生じた場合、または本利用規約に定めのない事項については、当社と利用者は、誠意をもって協議の上これを解決するものとします。
          </p>
        </div>
      </section>

      <section className="mb-30px w-full">
        <h2 className="pb-12px text-20px font-bold text-c1e88e5 leading-10">
          第22条 （準拠法及び管轄）
        </h2>
        <div>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            １．本利用契約は、日本法を準拠法として、日本法に従い解釈されるものとします。
          </p>
          <p className="mb-12px pl-[32px] -indent-[32px] leading-7">
            ２．本利用契約に関する紛争については、訴額に応じ東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。なお、調停を行う場合についても同様とします。
          </p>
        </div>
      </section>
    </>
  );
};

export default TermOfService;
