import React from "react";
import { useRecoilState } from "recoil";
import { salesPlanLatestHeaderStore } from "../../../store";
import { bottomHeaderCss, topHeaderCss } from "../../../utils";

interface Props {
  headers: string[];
  latestHeader: string;
  headerTerms: string[];
}

const SalesPlanHeader: React.FC<Props> = ({
  headers,
  latestHeader,
  headerTerms,
}) => {
  const [headerState, setHeaderState] = useRecoilState(
    salesPlanLatestHeaderStore
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHeaderState(e.target.value);
  };

  return (
    <thead className="sticky top-0 px-6px py-3px z-40 bg-white pt-120px">
      <tr>
        <th
          className={`sticky left-0 align-bottom text-left font-bold text-18px z-40  px-6px py-3px top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full 
            before:border-b before:border-r before:bg-white before:-z-10 min-w-[20rem] w-380px `}
          colSpan={4}
        >
          <select
            name="term"
            onChange={(e) => handleChange(e)}
            value={headerState ? headerState : latestHeader}
          >
            {headerTerms.map((term) => {
              return (
                <option key={term} value={term}>
                  {term.slice(1, 5)}年 {term.slice(5, 7)}期
                </option>
              );
            })}
          </select>
        </th>
        {headers.map((header, index) => (
          <th
            className={`min-w-[3.75rem] w-60px  before:border-y before:border-b-c3333334d bg-c2a4b770d ${topHeaderCss}`}
            key={index}
          >
            {index === 0 && "販売数"}
          </th>
        ))}
        <th
          className={`min-w-[5.625rem] w-90px font-thin before:border-r before:border-y before:border-b-c3333334d bg-c2a4b770d ${topHeaderCss}`}
        >
          <div
            className={`absolute right-4px whitespace-nowrap  top-[4px] text-right`}
          ></div>
        </th>
        <th className="min-w-[1.875rem] w-25px"></th>
        {headers.map((header, index) => (
          <th
            className={`min-w-[3.75rem] w-60px ${
              index === 0 && "before:border-l"
            }  before:border-y before:border-b-c3333334d bg-c2a4b770d relative top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full ${topHeaderCss}`}
            key={index}
          >
            {index === 0 && "売上高"}
          </th>
        ))}
        <th
          className={`min-w-[5.625rem] w-90px font-thin before:border-r before:border-y before:border-b-c3333334d bg-c2a4b770d ${topHeaderCss}`}
        >
          <div
            className={`absolute right-4px whitespace-nowrap top-[4px] text-right`}
          >
            （単位：円）
          </div>
        </th>
        <th
          className={`min-w-[37.5rem] w-600px  before:border-collapse text-center align-middle bg-white z-30 relative px-6px py-3px top-0  before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:border-l before:border-l-c3333334d before:border-x before:border-t before:border-b-2`}
          rowSpan={2}
        >
          備考
        </th>
      </tr>
      <tr className="font-bold">
        <th
          className={`sticky min-w-[12.5rem] w-200px left-0 before:border-l before:bg-c2a4b771a before:-z-10 bg-white ${bottomHeaderCss} z-40`}
        >
          品名
        </th>
        <th
          className={`sticky min-w-[3.75rem] w-60px left-200px before:border-l before:border-l-c3333334d before:bg-c2a4b771a before:-z-10 bg-white  ${bottomHeaderCss} z-40`}
        >
          型番
        </th>
        <th
          className={`sticky min-w-[3.75rem] w-60px left-260px before:border-l before:border-l-c3333334d before:bg-c2a4b771a before:-z-10 bg-white  ${bottomHeaderCss} z-40`}
        >
          原価
        </th>
        <th
          className={`sticky min-w-[3.75rem] w-60px left-320px before:border-x before:border-x-c3333334d before:bg-c2a4b771a before:-z-10 bg-white  ${bottomHeaderCss} z-40`}
        >
          売価
        </th>
        {headers.map((header, index) => (
          <th
            className={`before:border-l before:border-l-c3333334d bg-c2a4b771a ${bottomHeaderCss}`}
            key={index}
          >
            {header.substring(5, 7)}月
          </th>
        ))}
        <th
          className={`before:border-x  before:border-l-c3333334d bg-c2a4b771a ${bottomHeaderCss}`}
        >
          年間合計
        </th>
        <th></th>
        {headers.map((header, index) => (
          <th
            className={`before:border-l   ${
              index !== 0 && "before:border-l-c3333334d"
            } bg-c2a4b771a ${bottomHeaderCss}`}
            key={index}
          >
            {header.substring(5, 7)}月
          </th>
        ))}
        <th
          className={`before:border-x before:border-l-c3333334d bg-c2a4b771a ${bottomHeaderCss}`}
        >
          年間合計
        </th>
      </tr>
    </thead>
  );
};

export default SalesPlanHeader;
