import { DocumentData } from "firebase/firestore";
import React from "react";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { ShowTableData } from "../../../lib";
import { Unit } from "../../atoms";

export const PercentageIncreaseInNetSales = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const managementDashboardState = useManagementDashboardInitialData();

  const states = managementDashboardState?.percentage_increase_in_net_sales;
  const unit = String(resultsAndPlansDataState.headers.unit);

  return (
    <div className="mb-20px p-30px bg-white rounded">
      <h2 className="font-bold">■売上高増加率</h2>
      <div className="flex">
        <div>
          <Unit unit={unit} />

          <table className="border">
            <thead className=" top-0 px-6px py-3px z-40 bg-c2a4b771a pt-120px border-b-2 text-center">
              <tr>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px`}
                >
                  決算期
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px border-l border-l-c3333334d`}
                >
                  売上高
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  売上高増加率
                </th>
              </tr>
            </thead>
            <tbody>
              {states.map((data: DocumentData, index: number) => (
                <tr
                  className={`border-b ${
                    states.length - 1 !== index && "border-b-c3333334d"
                  }`}
                  key={index}
                >
                  <th className={`p-4px text-right font-bold text-18px`}>
                    {data.name}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["売上高"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {data["売上高増加率"]}%
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
