import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CashFlowTableHeader } from "./CashFlowTableHeader";
import { TaxInput } from "./TaxInput";
import {
  useCashFlowTableInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CashFlowTableBody,
  CashFlowTableInputBody,
  CashFlowTableTotal,
} from "../../../lib/datasheets/cashFlowTable";
import { deleteIdStore, deleteModalIsOpenStore } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { Unit } from "../../atoms";
import { DeleteModal, Spinner } from "../../molecules";

interface Props {
  url: string;
  dataUrl: string;
}

const CashFlowTableTable: React.FC<Props> = ({ url, dataUrl }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const resultsCount =
    resultsAndPlansDataState.headers.results_of_the_current_term_count;

  const setDeleteIsOpen = useSetRecoilState(deleteModalIsOpenStore);
  const [deleteIdState, setDeleteIdState] = useRecoilState(deleteIdStore);

  const cashFlowTableCalcState = useCashFlowTableInitialData();

  if (!cashFlowTableCalcState) {
    return <Spinner />;
  }

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = CashFlowTableTotal({
    title: "前月繰越金",
    subTitle: "",
    itemTitle: "",
    headers: resultsAndPlansDataState.headers[dataUrl],
    monthlyTotal: cashFlowTableCalcState.net_sales_total.monthly_total,
    monthlyTotalTotal:
      cashFlowTableCalcState.net_sales_total.monthly_total_total,
    dataUrl: dataUrl,
    url: url,
    key: "carryover",
    isGrandTotal: true,
    isSticky: true,
    descriptions: resultsAndPlansDataState.descriptions,
    unit: unit,
  });

  grid.push(
    ...CashFlowTableBody({
      title: "経常収入",
      subTitle: "売上",
      itemTitles: ["現金売上", "翌月入金", "翌々月入金"],
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.net_sales,
      byItem: [
        cashFlowTableCalcState.net_sales_total.this_month_with_tax,
        cashFlowTableCalcState.net_sales_total.next_month_with_tax,
        cashFlowTableCalcState.net_sales_total.two_month_later_with_tax,
      ],
      byItemTotal: [
        cashFlowTableCalcState.net_sales_total.this_month_total_with_tax,
        cashFlowTableCalcState.net_sales_total.next_month_total_with_tax,
        cashFlowTableCalcState.net_sales_total.two_month_later_total_with_tax,
      ],
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_body",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "売上計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.net_sales_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.net_sales_total.monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableBody({
      title: "",
      subTitle: "営業外収入",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.non_operating_income,
      byItem: cashFlowTableCalcState.non_operating_income_total.byItem,
      byItemTotal:
        cashFlowTableCalcState.non_operating_income_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_non_operationg_income_body",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "営業外収入計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.non_operating_income_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.non_operating_income_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_non_operationg_income_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "経常収入計",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.ordinary_profit_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.ordinary_profit_total.monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_ordinary_profit_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
      isPreGrandTotal: true,
    }),
    ...CashFlowTableBody({
      title: "経常支出",
      subTitle: "仕入",
      itemTitles: ["当月支払", "翌月支払", "翌々月支払"],
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.cost_of_sales,
      byItem: [
        cashFlowTableCalcState.cost_of_sales_total.this_month_with_tax,
        cashFlowTableCalcState.cost_of_sales_total.next_month_with_tax,
        cashFlowTableCalcState.cost_of_sales_total.two_month_later_with_tax,
      ],
      byItemTotal: [
        cashFlowTableCalcState.cost_of_sales_total.this_month_total_with_tax,
        cashFlowTableCalcState.cost_of_sales_total.next_month_total_with_tax,
        cashFlowTableCalcState.cost_of_sales_total
          .two_month_later_total_with_tax,
      ],
      dataUrl: dataUrl,
      url: url,
      key: "payment_on_purchase_body",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "仕入計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.cost_of_sales_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.cost_of_sales_total.monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "payment_on_purchase_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableBody({
      title: "",
      subTitle: "販管費",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.selling_and_administrative,
      byItem: cashFlowTableCalcState.selling_and_administrative_total.by_item,
      byItemTotal:
        cashFlowTableCalcState.selling_and_administrative_total.by_item_total,
      dataUrl: dataUrl,
      url: url,
      key: "selling_and_administrative_body",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "販管費計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.selling_and_administrative_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.selling_and_administrative_total
          .monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "selling_and_administrative_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableBody({
      title: "",
      subTitle: "営業外費用",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.non_operating_expenses,
      byItem: cashFlowTableCalcState.non_operating_expenses_total.byItem,
      byItemTotal:
        cashFlowTableCalcState.non_operating_expenses_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_non_operationg_expenses_body",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "営外費計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.non_operating_expenses_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.non_operating_expenses_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "sales_receipt_non_operationg_expenses_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "経常支出計",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.ordinary_expenses_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.ordinary_expenses_total.monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "payment_on_purchase_ordinary_expenses_total",
      isPreGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "経常収支",
      subTitle: "",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.current_balance.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.current_balance.monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "current_balance_total",
      isGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableInputBody({
      title: "その他収入",
      subTitle: "特別収益",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.special_income,
      byItem: cashFlowTableCalcState.special_income_total.byItem,
      byItemTotal: cashFlowTableCalcState.special_income_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      field: "special_income",
      key: "special_income_body",
      setDeleteIdState: setDeleteIdState,
      setDeleteIsOpen: setDeleteIsOpen,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "その他収入計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.special_income_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.special_income_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "special_income_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableInputBody({
      title: "その他費用",
      subTitle: "特別費用他",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.other_special_expenses,
      byItem: cashFlowTableCalcState.other_special_expenses_total.byItem,
      byItemTotal:
        cashFlowTableCalcState.other_special_expenses_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      field: "other_special_expenses",
      key: "other_special_expenses_body",
      setDeleteIdState: setDeleteIdState,
      setDeleteIsOpen: setDeleteIsOpen,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "その他費用計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.other_special_expenses_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.other_special_expenses_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "other_special_expenses_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableInputBody({
      title: "",
      subTitle: "納税",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.tax_payment,
      byItem: cashFlowTableCalcState.tax_payment_total.byItem,
      byItemTotal: cashFlowTableCalcState.tax_payment_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      field: "tax_payment",
      key: "tax_payment_body",
      setDeleteIdState: setDeleteIdState,
      setDeleteIsOpen: setDeleteIsOpen,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "遅延・未払計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal: cashFlowTableCalcState.tax_payment_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.tax_payment_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "tax_payment_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "当月収支過不足（当月収支計、前月繰越金不含）",
      subTitle: "",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.underpayment_for_the_month_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.underpayment_for_the_month_total
          .monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "underpayment_for_the_month_total",
      isGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "差引収支過不足（当月収支計＋前月繰越金）",
      subTitle: "",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.insufficient_expenditures_total.monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.insufficient_expenditures_total
          .monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "insufficient_expenditures_total",
      isGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableInputBody({
      title: "借入",
      subTitle: "資金調達",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.financial_arrangements,
      byItem: cashFlowTableCalcState.financial_arrangements_total.byItem,
      byItemTotal:
        cashFlowTableCalcState.financial_arrangements_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      field: "financial_arrangements",
      key: "financial_arrangements_body",
      setDeleteIdState: setDeleteIdState,
      setDeleteIsOpen: setDeleteIsOpen,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "資金調達計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.financial_arrangements_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.financial_arrangements_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "financial_arrangements_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableInputBody({
      title: "借入返済",
      subTitle: "元金返済",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.principal_payments,
      byItem: cashFlowTableCalcState.principal_payments_total.byItem,
      byItemTotal: cashFlowTableCalcState.principal_payments_total.byItemTotal,
      dataUrl: dataUrl,
      url: url,
      field: "principal_payments",
      key: "principal_payments_body",
      setDeleteIdState: setDeleteIdState,
      setDeleteIsOpen: setDeleteIsOpen,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "",
      subTitle: "",
      itemTitle: "元金返済計",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.principal_payments_total.monthlyTotal,
      monthlyTotalTotal:
        cashFlowTableCalcState.principal_payments_total.monthlyTotalTotal,
      dataUrl: dataUrl,
      url: url,
      key: "principal_payments_total",
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "当月収支過不足（前月繰越金不含）",
      subTitle: "",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.underpayment_for_the_before_month_total
          .monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.underpayment_for_the_before_month_total
          .monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "underpayment_for_the_before_month_total",
      isGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...CashFlowTableTotal({
      title: "翌月繰越（当月収支過不足＋前月繰越）",
      subTitle: "",
      itemTitle: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      monthlyTotal:
        cashFlowTableCalcState.underpayment_for_the_before_month_grand_total
          .monthly_total,
      monthlyTotalTotal:
        cashFlowTableCalcState.underpayment_for_the_before_month_grand_total
          .monthly_total_total,
      dataUrl: dataUrl,
      url: url,
      key: "underpayment_for_the_before_month_grand_total",
      isGrandTotal: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
    // ...CashFlowTableTableBlank({
    //   headers: resultsAndPlansDataState.headers[dataUrl],
    //   title: "",
    //   bottomLine: false,
    // }),
    // ...CashFlowTableTableBlank({
    //   headers: resultsAndPlansDataState.headers[dataUrl],
    //   title: "【売上入金／仕入出金】 ※消費税不含",
    //   bottomLine: true,
    // }),
    // ...SalesReceiptAndPaymentOnPurchase({
    //   headers: resultsAndPlansDataState.headers[dataUrl],
    //   salesReceipt: cashFlowTableCalcState.net_sales_total,
    //   paymentOnPurchase: cashFlowTableCalcState.cost_of_sales_total,
    //   unit: Number(resultsAndPlansDataState.headers.unit),
    //   salesReceiptRate: resultsAndPlansDataState.headers.sales_receipt,
    //   paymentOnPurchaseRate:
    //     resultsAndPlansDataState.headers.payment_on_purchase,
    //   descriptions: resultsAndPlansDataState.descriptions,
    // })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <React.Fragment>
      <Unit unit={String(unit)} />
      <div className="relative w-auto">
        <div className="w-160px fixed top-[213px] left-[676px] z-[51]">
          <TaxInput isInBorder={true} />
        </div>
      </div>
      <ReactDataSheet
        data={grid}
        dataRenderer={(cell) => cell.expr}
        onCellsChanged={HandleCellChanged}
        sheetRenderer={(props: {
          className: string | undefined;
          children: React.ReactElement<
            DocumentData,
            string | React.JSXElementConstructor<DocumentData>
          >;
        }) => (
          <table className={`${props.className}`}>
            <CashFlowTableHeader
              headers={resultsAndPlansDataState.headers[dataUrl]}
              previousHeaders={
                resultsAndPlansDataState.headers.results_of_the_previous_period
              }
              resultsCount={resultsCount}
            />
            <tbody className="text-14px">{props.children}</tbody>
          </table>
        )}
        valueRenderer={(cell) => cell.value}
      />
      <DeleteModal id={deleteIdState} />
    </React.Fragment>
  );
};

export default memo(CashFlowTableTable);
