import { atom } from "recoil";

export const psSimulationTextStore = atom<{
  text: string;
  id: number;
  isWrite: boolean;
}>({
  key: "psSimulationText",
  default: { text: "", id: 0, isWrite: false },
});
