import { DocumentData } from "firebase/firestore";
import { dotDivide, sum, transpose } from "mathjs";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
  netSalesTotalByItem?: number[];
};

export const calcByItemForPlansResultMonthly = ({
  headers,
  state,
  field,
  netSalesTotalByItem,
}: Props) => {
  const byItem: number[][] = [];
  const byItemTotal: number[] = [];
  const monthlyTotal: number[] = [];
  const byItemRatio: number[][] = [];

  // 月別売上比率
  const netSalesByItemRatio: number[][] = [];

  state.map((stateData, stateIndex) => {
    const item: number[] = [];
    const netSalesItem: number[] = [];

    headers?.map((header: DocumentData) => {
      if (header.period === "total") {
        return;
      }

      const term = header.period;
      const data = stateData[field][term];

      item.push(data === null || !data ? 0 : data);
      netSalesItem.push(
        netSalesTotalByItem &&
          netSalesTotalByItem.length === 1 &&
          data &&
          data !== null
          ? 0
          : netSalesTotalByItem && data && data !== null
          ? data / netSalesTotalByItem[stateIndex]
          : 0
      );
    });

    byItem.push(item);
    byItemTotal.push(sum(item) as number);
    netSalesByItemRatio.push(netSalesItem);
  });

  const transposeByItem = transpose(
    (byItem[0] && byItem[0].length !== 0) || byItem.length !== 0
      ? byItem
      : [
          [0, 0],
          [0, 0],
        ]
  );
  transposeByItem.map((data) => {
    monthlyTotal.push(sum(data) as number);
  });

  byItem.map((byItemData) => {
    const ratioData =
      byItemData && byItemData.length !== 0
        ? (dotDivide(byItemData, monthlyTotal) as number[])
        : [0];
    byItemRatio.push(ratioData);
  });

  const byItemTotalRatio = dotDivide(
    byItemTotal,
    sum(byItemTotal) as number
  ) as number[];

  return {
    byItem,
    byItemTotal,
    monthlyTotal,
    byItemRatio,
    byItemTotalRatio,
    netSalesByItemRatio,
  };
};
