import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  ResultsAndPlanHeader,
  WindowDressing,
} from "../../../lib/datasheets/resultsAndPlans";
import { ResultsInputFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: ResultsInputFieldsCalc;
}

const WindowDressingTable: React.FC<Props> = ({ url, calcState }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);
  
  let grid: GridElement[][] = [];

  grid = grid.concat(
    WindowDressing({
      headers: resultsAndPlansDataState.headers[url],
      field: url,
      state: calcState,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url: url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <ResultsAndPlanHeader
            headers={resultsAndPlansDataState.headers[url]}
            title="■粉飾の確認"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(WindowDressingTable);
