import React from "react";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

const LightButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <button
      className="text-c427ed1 hover:opacity-80 bg-white font-bold w-full h-60px py-2px px-4px rounded border border-c427ed1 focus:outline-none focus:shadow-outline"
      onClick={onClick}
      type="submit"
    >
      {children}
    </button>
  );
};

export default LightButton;
