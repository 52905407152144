import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const BsResultsAndPlansBody = ({
  title,
  rows,
  bottomLine,
  isCrosshead,
  isWhite,
  headers,
  isSingle,
  url,
  unit,
}: {
  title?: string;
  rows: DocumentData[];
  bottomLine?: boolean;
  isCrosshead?: boolean;
  isWhite?: boolean;
  headers: DocumentData[];
  isSingle?: boolean;
  url: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    rows.map((row: DocumentData, rowIndex: number) => {
      // className
      const classCol1_1 = `${beforeWrapper} left-0 p-4px before:border-l ${
        rows.length === rowIndex + 1 && bottomLine && "before:border-b"
      } bg-white font-bold z-[32]`;

      const classCol1_2 = `${beforeWrapper} left-0 p-4px before:border-l ${
        rows.length === rowIndex + 1 && bottomLine && "before:border-b"
      } ${
        isWhite ? "before:border-b-c3333334d" : ""
      } bg-white before:bg-c80b0f433 font-bold  z-[32]`;

      const classCol2 = `${beforeWrapper} left-30px p-4px bg-white font-bold ${
        rows.length === rowIndex + 1 && bottomLine && "before:border-b"
      }`;

      const classCol3 = `${beforeWrapper} left-80px p-4px bg-white before:border-b ${
        rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
      } ${isWhite && "before:border-b-c3333334d"} ${
        rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
      }`;

      const classCol4 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-r before:border-r-c3333334d ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${isWhite ? "before:border-b-c3333334d" : ""}  ${
        rowIndex % 2 !== 0 && "before:bg-c2a4b770d"
      }`;

      const classCol5 = `${borderSettings} ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }  text-right`;

      const classCol7 = `${borderSettings} before:border-r ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${isWhite ? "before:border-b-c3333334d" : ""}  ${
        rowIndex % 2 !== 0 && "bg-c2a4b770d"
      } text-left h-50px description`;

      // 行の値
      const gridRow = [];
      if (isSingle) {
        gridRow.push({
          value: (
            <div
              className={`absolute top-20px left-4px whitespace-nowrap top_absolute`}
            >
              {row.item}
            </div>
          ),
          expr: row.item,
          readOnly: true,
          className: classCol1_1,
          row: row,
          url: url,
          key: "",
        });

        gridRow.push(
          { value: "", readOnly: true, className: classCol2 },
          {
            value: "",
            readOnly: true,
            className: `${classCol3} `,
          },
          {
            value: "",
            readOnly: true,
            className: `${classCol4} `,
          }
        );
      } else {
        gridRow.push({
          value: (
            <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
              {rowIndex === 0 && title}
            </div>
          ),
          expr: rowIndex === 0 && title,
          readOnly: true,
          className: classCol1_2,
          row: row,
          url: url,
          key: "",
          colSpan: 1,
        });

        gridRow.push(
          {
            value: "",
            readOnly: true,
            className: `${classCol2} ${
              isCrosshead && "before:border-l before:border-l-c3333334d"
            }   ${
              isCrosshead &&
              rows.length !== rowIndex + 1 &&
              "before:border-b-c3333334d"
            } ${
              isWhite ? "before:border-b-c3333334d" : ""
            } before:bg-c80b0f433 `,
          },
          {
            value: row.item,
            readOnly: true,
            className: `${classCol3}  before:border-l before:border-l-c3333334d`,
          },
          {
            value: row.section,
            readOnly: true,
            className: `${classCol4} before:border-l before:border-l-c3333334d`,
          }
        );
      }

      headers?.map((header) => {
        // オブジェクトの中から該当する値を取得
        const resultsAndPlanTerm = row[url];

        type TypeOfResultObj = typeof resultsAndPlanTerm;
        const key: keyof TypeOfResultObj = header.period;
        const resultValue = isNaN(row[url][key]) ? 0 : row[url][key];

        gridRow.push({
          value: ShowTableData(resultValue / unit),
          expr: resultValue / unit,
          readOnly: false,
          className: `${classCol5} ${
            !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
          } ${header.isTotal && "bg-cfffee2"}`,
          row: row,
          url: url,
          key: key as string,
          colSpan: 1,
        });
      });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol7,
        row: row,
        url: url,
        key: "descriptions",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
