import { addDoc, collection, DocumentData } from "firebase/firestore";
import React, { createRef } from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { getStoredClientUid } from "../../../sessionStorage/sessionStorage";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";

export const SalesPlanBodyBlank = ({
  headers,
  row,
  url,
}: {
  headers: string[];
  row: DocumentData;
  url: string;
}) => {
  const firestore = useFirestore();
  const inputRef = createRef<HTMLInputElement>();

  const uid = getStoredClientUid();

  const handleClick = async () => {
    const tablesRef = collection(firestore, "accounts", uid, "tables");

    if (inputRef.current?.value.length === 0) {
      toast.warning("品名を入力してください");
      return;
    }

    await addDoc(tablesRef, { ...row, item: inputRef.current?.value })
      .then(() => {
        toast.success("品名を追加しました");
      })
      .catch((error) => {
        toast.error("追加できませんでした");
        throw new Error(error.message);
      });
  };

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 bg-white text-right before:border-b before:border-l`;

  const classCol2 = `${beforeWrapper} p-4px left-200px bg-white text-right  before:border-b before:border-l before:border-l-c3333334d `;

  const classCol3 = `${beforeWrapper} p-4px left-260px bg-white text-right  before:border-b before:border-l before:border-l-c3333334d `;

  const classCol4 = `${beforeWrapper} p-4px left-320px bg-white text-right  before:border-b  before:border-x before:border-x-c3333334d`;

  const classCol5 = `sticky before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full p-4px before:border-b text-right before:border-l`;

  const classCol6 = `${borderSettings} p-4px before:border-b text-right before:border-x`;

  const classCol7 = `${borderSettings}  before:border-r text-left  h-50px description`;

  // 行の値
  const gridRow = [];

  gridRow.push({
    value: "",
    component: (
      <input
        className={
          "table_per_w shadow appearance-none before:border before:border-c3333334d rounded py-2px px-3px leading-tight focus:border-c1e88e5 focus:outline-none focus:shadow-outline text-right"
        }
        ref={inputRef}
        type="text"
      />
    ),
    forceComponent: true,
    readOnly: false,
    className: `${classCol1} datasheet-input`,
    row: row,
    url: url,
    colSpan: 1,
  });

  gridRow.push(
    {
      value: "",
      component: (
        <button
          className="w-40px h-30px text-14px text-white font-bold bg-c427ed1 rounded mr-5px hover:opacity-80"
          onClick={handleClick}
        >
          追加
        </button>
      ),
      forceComponent: true,
      readOnly: true,
      className: `${classCol2} datasheet-input`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} border-x-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} border-x-c3333334d`,
    }
  );

  headers.map(() => {
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${classCol5} before:border-l-c3333334d`,
      row: row,
      url: url,
      colSpan: 1,
    });
  });

  // 項目ごとの合計
  gridRow.push({
    value: "",
    readOnly: true,
    className: classCol6,
    colSpan: 1,
  });

  // 空欄
  gridRow.push({
    value: "",
    readOnly: true,
    className: "",
    colSpan: 1,
  });

  // 販売数X個数
  headers.map((header: string, tdIndex: number) => {
    // オブジェクトの中から該当する値を取得

    gridRow.push({
      value: "",
      readOnly: true,
      className:
        tdIndex === 0 ? classCol5 : `${classCol5} before:border-l-c3333334d`,
      row: row,
      url: url,
      colSpan: 1,
    });
  });
  // 項目ごとの合計
  gridRow.push({
    value: "",
    readOnly: true,
    className: classCol6,
    colSpan: 1,
  });

  gridRow.push({
    value: "",
    readOnly: true,
    className: classCol7,
    row: row,
    url: url,
    key: "descriptions",
    colSpan: 1,
  });
  grid.push(gridRow);

  return grid;
};
