import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlCurrentAndPreviousYearComparisonsWithTax = ({
  rows,
  previousTotalByItem,
  currentTotalByItem,
  subtract,
  ratio,
  reference,
  url,
  unit,
}: {
  rows: DocumentData[];
  previousTotalByItem: number[];
  currentTotalByItem: number[];
  subtract: number[];
  ratio: number[];
  reference: number[];
  url: string;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  {
    rows.map((row: DocumentData, index: number) => {
      // className
      const classCol1 = `${beforeWrapper} left-0 p-4px bg-white top-0 font-bold align-text-top mt-6px before:border-b before:border-l before:h-full z-[32]`;

      const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b`;

      const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-b before:border-x before:border-x-c3333334d`;

      const classCol4 = `${borderSettings} text-right`;

      const classCol5 = `${borderSettings} before:border-r text-left  h-50px description`;

      // 行の値
      const gridRow = [];
      gridRow.push({
        value: (
          <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
            法人税、住民税及び事業税
          </div>
        ),
        readOnly: true,
        className: classCol1,
        colSpan: 1,
        row: row,
        url: url,
        key: "",
      });

      gridRow.push(
        { value: "", readOnly: true, className: classCol2 },
        {
          value: row.section,
          readOnly: true,
          className: `${classCol3} border-x-c3333334d`,
        }
      );

      // 前期
      gridRow.push({
        value: ShowTableData(previousTotalByItem[index] / unit),
        expr: previousTotalByItem[index] / unit,
        readOnly: true,
        className: classCol4,
        row: row,
        url: url,
        key: "previous",
        colSpan: 1,
      });
      // 今期
      gridRow.push({
        value: ShowTableData(currentTotalByItem[index] / unit),
        expr: currentTotalByItem[index] / unit,
        readOnly: true,
        className: classCol4,
        row: row,
        url: url,
        key: "current",
        colSpan: 1,
      });
      // 今年-前年
      gridRow.push({
        value: ShowTableData(subtract[index] / unit),
        expr: subtract[index] / unit,
        readOnly: true,
        className: classCol4,
        row: row,
        url: url,
        key: "current",
        colSpan: 1,
      });
      // 今年/前年

      gridRow.push({
        value: `${String(ratio[index])}%`,
        readOnly: true,
        className: classCol4,
        row: row,
        url: url,
        key: "current",
        colSpan: 1,
      });

      gridRow.push({
        value: ShowTableData(round(reference[index]) / unit),
        expr: round(reference[index]) / unit,
        readOnly: true,
        className: classCol4,
        row: row,
        url: url,
        key: "auto",
        colSpan: 1,
      });
      // 修正後
      // 3桁ごとに変換
      const resultValue = row[url]["fixed"];
      const newValue = ShowTableData(resultValue / unit);
      gridRow.push({
        value: newValue,
        expr: resultValue / unit,
        readOnly: false,
        className: classCol4,
        row: row,
        url: url,
        key: "fixed",
        colSpan: 1,
      });

      gridRow.push({
        value: row?.descriptions[`${url}_${row?.field as string}`],
        expr: row?.descriptions[`${url}_${row?.field as string}`],
        readOnly: false,
        className: classCol5,
        row: row,
        url: url,
        key: "descriptions",
        colSpan: 1,
      });
      grid.push(gridRow);
    });
  }

  return grid;
};
