import React from "react";

type Props = {
  unit: string;
};

const Unit: React.FC<Props> = ({ unit }) => {
  return (
    <div className="fixed top-125px right-60px text-14px text-right font-bold mb-4px">
      単位：{unit === "1000" ? "千" : unit === "1000000" ? "百万" : ""}
      円、％
    </div>
  );
};

export default Unit;
