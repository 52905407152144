import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { pageConstant } from "../../../constants/pageConstants";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CostEstimatedInThisTermBody,
  CostEstimatedInThisTermRatioComponent,
  CostEstimatedInThisTermRatioElement,
  CostEstimatedInThisTermTotal,
  EstimatedInThisTermHeader,
  EstimatedInThisTermTableBlank,
} from "../../../lib/datasheets/estimatedInThisTerm";
import { PlansInputMonthlyFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: PlansInputMonthlyFieldsCalc;
}

const CostEstimatedInThisTermTable: React.FC<Props> = ({ url, calcState }) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const count =
    resultsAndPlansDataState.headers?.results_of_the_current_term_count?.filter(
      function (x: boolean) {
        return x;
      }
    ).length;

  const resultsUrl = pageConstant.PLANS_INPUT_MONTHLY;

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = CostEstimatedInThisTermTotal({
    titleTotal: "材料費",
    bottomLine: false,
    headers: resultsAndPlansDataState.headers[resultsUrl],
    calcState: calcState.material_fee_total,
    totalField: `${url}_material_fee_total`,
    unit: unit,
    descriptions: resultsAndPlansDataState.descriptions,
  });

  grid.push(
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.material_fee,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.material_fee_total,
      url: url,
      bottomLine: true,
      bodyField: `${url}_material_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermTotal({
      titleTotal: "労務費",
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.labor_costs_total,
      totalField: `${url}_labor_costs_total`,
      bottomLine: false,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.labor_costs,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.labor_costs_total,
      bottomLine: true,
      url: url,
      bodyField: `${url}_labor_costs_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermTotal({
      titleTotal: "外注加工費",
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.external_injection_processing_fee_total,
      totalField: `${url}_external_injection_processing_fee_total`,
      bottomLine: false,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.external_injection_processing_fee_total,
      bottomLine: true,
      url: url,
      bodyField: `${url}_external_injection_processing_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermTotal({
      titleTotal: "経費",
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.expenses_total,
      bottomLine: false,
      totalField: `${url}_expenses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.expenses,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.expenses_total,
      bottomLine: true,
      url: url,
      bodyField: `${url}_expenses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermTotal({
      titleTotal: "当期製造費用",
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.gross_manufacturing_cost_for_the_period,
      bottomLine: false,
      totalField: `${url}_gross_manufacturing_cost_for_the_period`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState:
        calcState.inventory_of_work_in_process_at_the_beginning_of_the_period_total,
      bottomLine: false,
      url: url,
      bodyField: `${url}_inventory_of_work_in_process_at_the_beginning_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState:
        calcState.inventory_of_work_in_process_at_the_end_of_the_period_total,
      bottomLine: false,
      url: url,
      bodyField: `${url}_inventory_of_work_in_process_at_the_end_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostEstimatedInThisTermTotal({
      titleTotal: "当期製品製造原価",
      headers: resultsAndPlansDataState.headers[resultsUrl],
      calcState: calcState.cost_of_products_manufactured,
      bottomLine: true,
      totalField: `${url}_cost_of_products_manufactured`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...EstimatedInThisTermTableBlank({
      headers: resultsAndPlansDataState.headers[resultsUrl],
    }),
    ...CostEstimatedInThisTermRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          calcState:
            calcState.gross_manufacturing_cost_for_the_period_plans_cost_composition_ratio,
        },
        {
          title: "材料費",
          calcState: calcState.material_fee_cost_composition_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_cost_composition_ratio,
        },
        {
          title: "外注加工費",
          calcState:
            calcState.external_injection_processing_fee_cost_composition_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_cost_composition_ratio,
        },
      ],
      headers: resultsAndPlansDataState.headers[resultsUrl],
    }),
    ...EstimatedInThisTermTableBlank({
      headers: resultsAndPlansDataState.headers[resultsUrl],
    }),
    ...CostEstimatedInThisTermRatioElement({
      headerTitle: "要素別原価率",
      rows: [
        {
          title: "材料費",
          calcState: calcState.material_fee_total,
        },
        {
          title: "(売上高材料費比率)",
          calcState: calcState.net_sales_material_fee_total_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_total,
        },
        {
          title: "(売上高労務費比率)",
          calcState: calcState.net_sales_labor_costs_total_ratio,
        },
        {
          title: "外注加工費",
          calcState: calcState.external_injection_processing_fee_total,
        },
        {
          title: "(売上高外注費比率)",
          calcState:
            calcState.net_sales_external_injection_processing_fee_total_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_total,
        },
        {
          title: "(売上高経費比率)",
          calcState: calcState.net_sales_expenses_total_ratio,
        },
        {
          title: "減価償却費",
          calcState: calcState.net_sales_depreciation_cost_of_sales,
        },
      ],
      headers: resultsAndPlansDataState.headers[resultsUrl],
      isCurrentPlanMonthly: true,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <EstimatedInThisTermHeader
            count={count}
            headers={resultsAndPlansDataState.headers[resultsUrl]}
            title="■原価"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostEstimatedInThisTermTable);
