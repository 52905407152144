import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";
import { HandleCellChange } from "../../../lib/datasheets";
import {
  CostPlansInputMonthlyBody,
  CostPlansInputMonthlyRatioComponent,
  CostPlansInputMonthlyTotal,
  PlansInputMonthlyHeader,
  PlansInputTableBlank,
} from "../../../lib/datasheets/plansInputMonthly";
import { CostPlansInputRatioElement } from "../../../lib/datasheets/plansInputMonthly/CostPlansInputRatioElement";
import { PlansInputMonthlyFieldsCalc } from "../../../types";
import { GridElement } from "../../../types/gridElement";

interface Props {
  url: string;
  calcState: PlansInputMonthlyFieldsCalc;
  descUrl?: string;
  isCurrentPlanMonthly?: boolean;
}

const CostPlansInputMonthlyTable: React.FC<Props> = ({
  url,
  calcState,
  descUrl,
  isCurrentPlanMonthly,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = CostPlansInputMonthlyTotal({
    titleTotal: "材料費",
    bottomLine: false,
    headers: resultsAndPlansDataState.headers[url],
    calcState: calcState.material_fee_total,
    isCurrentPlanMonthly: isCurrentPlanMonthly,
    totalField: `${isCurrentPlanMonthly ? descUrl : url}_material_fee_total`,
    unit: unit,
    descriptions: resultsAndPlansDataState.descriptions,
  });

  grid.push(
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.material_fee,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.material_fee_total,
      url: url,
      bottomLine: true,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_material_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyTotal({
      titleTotal: "労務費",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.labor_costs_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_labor_costs_total`,
      bottomLine: false,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.labor_costs,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.labor_costs_total,
      bottomLine: true,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_labor_costs_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyTotal({
      titleTotal: "外注加工費",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.external_injection_processing_fee_total,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_external_injection_processing_fee_total`,
      bottomLine: false,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.external_injection_processing_fee,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.external_injection_processing_fee_total,
      bottomLine: true,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_external_injection_processing_fee_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyTotal({
      titleTotal: "経費",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.expenses_total,
      bottomLine: false,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${isCurrentPlanMonthly ? descUrl : url}_expenses_total`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.expenses,
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.expenses_total,
      bottomLine: true,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_expenses_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyTotal({
      titleTotal: "当期製造費用",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.gross_manufacturing_cost_for_the_period,
      bottomLine: false,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_gross_manufacturing_cost_for_the_period`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_beginning_of_the_period,
      headers: resultsAndPlansDataState.headers[url],
      calcState:
        calcState.inventory_of_work_in_process_at_the_beginning_of_the_period_total,
      bottomLine: false,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_inventory_of_work_in_process_at_the_beginning_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyBody({
      rows: resultsAndPlansDataState.inventory_of_work_in_process_at_the_end_of_the_period,
      headers: resultsAndPlansDataState.headers[url],
      calcState:
        calcState.inventory_of_work_in_process_at_the_end_of_the_period_total,
      bottomLine: false,
      url: url,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      bodyField: `${descUrl}_inventory_of_work_in_process_at_the_end_of_the_period_body`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...CostPlansInputMonthlyTotal({
      titleTotal: "当期製品製造原価",
      headers: resultsAndPlansDataState.headers[url],
      calcState: calcState.cost_of_products_manufactured,
      bottomLine: true,
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      totalField: `${
        isCurrentPlanMonthly ? descUrl : url
      }_cost_of_products_manufactured`,
      unit: unit,
      descriptions: resultsAndPlansDataState.descriptions,
    }),
    ...PlansInputTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      isRatio: true,
    }),
    ...CostPlansInputMonthlyRatioComponent({
      headerTitle: "原価構成比",
      rows: [
        {
          title: "当期製造費用",
          calcState:
            calcState.gross_manufacturing_cost_for_the_period_plans_cost_composition_ratio,
        },
        {
          title: "材料費",
          calcState: calcState.material_fee_cost_composition_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_cost_composition_ratio,
        },
        {
          title: "外注加工費",
          calcState:
            calcState.external_injection_processing_fee_cost_composition_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_cost_composition_ratio,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
    }),
    ...PlansInputTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCurrentPlanMonthly: isCurrentPlanMonthly,
      isRatio: true,
    }),
    ...CostPlansInputRatioElement({
      headerTitle: "要素別原価率",
      rows: [
        {
          title: "材料費",
          calcState: calcState.material_fee_total,
        },
        {
          title: "(売上高材料費比率)",
          calcState: calcState.net_sales_material_fee_total_ratio,
        },
        {
          title: "労務費",
          calcState: calcState.labor_costs_total,
        },
        {
          title: "(売上高労務費比率)",
          calcState: calcState.net_sales_labor_costs_total_ratio,
        },
        {
          title: "外注加工費",
          calcState: calcState.external_injection_processing_fee_total,
        },
        {
          title: "(売上高外注費比率)",
          calcState:
            calcState.net_sales_external_injection_processing_fee_total_ratio,
        },
        {
          title: "経費",
          calcState: calcState.expenses_total,
        },
        {
          title: "(売上高経費比率)",
          calcState: calcState.net_sales_expenses_total_ratio,
        },
        {
          title: "減価償却費",
          calcState: calcState.net_sales_depreciation_cost_of_sales,
        },
      ],
      headers: resultsAndPlansDataState.headers[url],
      isCurrentPlanMonthly: true,
      unit: unit,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <PlansInputMonthlyHeader
            headers={resultsAndPlansDataState.headers[url]}
            isCurrentPlanMonthly={isCurrentPlanMonthly}
            title="■原価"
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(CostPlansInputMonthlyTable);
