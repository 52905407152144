import * as React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { EstimatedInThisTermContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

export const EstimatedInThisTerm = () => {
  const url = pageConstant.ESTIMATED_IN_THIS_TERM;

  return (
    <React.Fragment>
      <SubHeader title="今期見込み（月次）" />
      <UnitChange />
      <EstimatedInThisTermContainer url={url} />
    </React.Fragment>
  );
};
