import React from "react";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { BusinessPlanLayout } from "../../components/planMaking/businessPlan";
import { StatementOfCashFlowsContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";

const StatementOfCashFlows = () => {
  const url = pageConstant.STATEMENT_OF_CASH_FLOWS;
  const dataUrl = pageConstant.RESULTS_INPUT;

  return (
    <React.Fragment>
      <SubHeader title="キャッシュフロー計算書" />
      <UnitChange />
      <BusinessPlanLayout>
        <div className="relative flex gap-12px">
          <StatementOfCashFlowsContainer dataUrl={dataUrl} url={url} />
        </div>
      </BusinessPlanLayout>
    </React.Fragment>
  );
};

export default StatementOfCashFlows;
