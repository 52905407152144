import { DocumentData } from "firebase/firestore";
import { round, sum } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const SalesPlanTotal = ({
  headers,
  item,
  title,
  total,
  itemsTotal,
  descriptions,
  url,
  unit,
}: {
  headers: string[];
  item?: string;
  title?: string;
  total: number[];
  descriptions: DocumentData;
  url: string;
  itemsTotal: number[];
  unit: number;
}) => {
  const totalByItem = sum(total);

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 bg-white text-right font-bold before:border-l before:border-b  ${
    title ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }`;

  const classCol2 = `${beforeWrapper} p-4px left-200px bg-white before:border-b before:border-l before:border-l-c3333334d ${
    title ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }`;

  const classCol3 = `${beforeWrapper} p-4px left-260px bg-white before:border-b before:border-l before:border-l-c3333334d ${
    title ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }`;

  const classCol4 = `${beforeWrapper} p-4px left-320px bg-white before:border-b before:border-x before:border-x-c3333334d  ${
    title ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }`;

  const classCol5 = `relative p-4px before:border-b before:border-l before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 p-4px text-right before:border-b before:border-l  ${
    title ? "bg-c7fb28d33" : "bg-c80b0f433"
  }`;

  const classCol6 = `${borderSettings} p-4px text-right before:border-b before:border-x before:border-l-c3333334d ${
    title ? "bg-c7fb28d33" : "bg-c80b0f433"
  }`;

  const classCol7 = `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: title ? `${title} 合計` : `${item} 合計`,
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4}`,
    },
  ];

  headers.map((header: string, tdIndex: number) => {
    gridRow.push({
      value: String(round(total[tdIndex])).replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        "$1,"
      ),
      readOnly: true,
      className: `${classCol5} before:border-l-c3333334d`,
    });
  });

  gridRow.push({
    value: ShowTableData(totalByItem),
    expr: totalByItem / unit,
    readOnly: true,
    className: `${classCol6}`,
  });

  // 空欄
  gridRow.push({
    value: "",
    readOnly: true,
    className: "",
    colSpan: 1,
  });

  headers.map((header: string, tdIndex: number) => {
    gridRow.push({
      value: itemsTotal[tdIndex]
        ? String(round(itemsTotal[tdIndex]) / unit).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1,"
          )
        : 0,
      expr: itemsTotal[tdIndex]
        ? String(round(itemsTotal[tdIndex])).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1,"
          )
        : 0,
      readOnly: true,
      className:
        tdIndex === 0 ? classCol5 : `${classCol5} before:border-l-c3333334d`,
    });
  });

  gridRow.push({
    value: ShowTableData(sum(itemsTotal) / unit),
    expr: sum(itemsTotal) / unit,
    readOnly: true,
    className: `${classCol6}`,
  });

  gridRow.push({
    value: descriptions[`${url}_${item}`],
    readOnly: false,
    className: `${classCol7}`,
    field: `${url}_${item}`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
