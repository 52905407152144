import { DocumentData } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { singleOrCumsumState } from "../../../store";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const BudgetControlBasisTableBlank = ({
  headers,
  title,
  bottomLine,
  isCost,
  isRatioComponent,
}: {
  headers: DocumentData[];
  title?: string;
  bottomLine?: boolean;
  isCost: boolean;
  isRatioComponent?: boolean;
}) => {
  const singleOrCumsumToggle = useRecoilValue(singleOrCumsumState);

  const counts = [0, 0, 0, 0, 0, 0, 0];
  const grid: GridElement[][] = [];

  // 行の値
  const gridRow = [
    {
      value: title ? title : "",
      readOnly: true,
      className: `${beforeWrapper}  left-0 p-4px bg-white ${
        bottomLine && "before:border-b"
      } `,
      colSpan: 1,
    },
  ];
  isCost &&
    gridRow.push({
      value: title ? title : "",
      readOnly: true,
      className: `${beforeWrapper} left-80px  p-4px bg-white ${
        bottomLine && "before:border-b"
      } `,
      colSpan: 1,
    });
  gridRow.push(
    {
      value: title ? title : "",
      readOnly: true,
      className: `${beforeWrapper}  left-220px p-4px bg-white ${
        bottomLine && "before:border-b"
      } `,
      colSpan: 1,
    },
    {
      value: title ? title : "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white ${
        bottomLine && "before:border-b"
      } `,
      colSpan: 1,
    }
  );

  headers?.map(() => {
    counts.map(() => {
      gridRow.push({
        value: "",
        readOnly: true,
        className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
        colSpan: 1,
      });
    });
  });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  singleOrCumsumToggle &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"} h-30px`,
      colSpan: 1,
    });
  !isRatioComponent &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"} h-30px`,
      colSpan: 1,
    });

  grid.push(gridRow);

  return grid;
};
