import * as React from "react";
import { usePlansInputMonthlyInitialData } from "../../../hooks/initialData";
import { Spinner } from "../../molecules";
import { InputLayout } from "../../planMaking/resultsInput";
import {
  CostBudgetControlBasisTable,
  PlBudgetControlBasisTable,
} from "../budgetControl/budgetControlBasis";

interface Props {
  url: string;
  dataUrl: string;
}

export const BudgetControlBasisContainer: React.FC<Props> = ({
  url,
  dataUrl,
}) => {
  const plansInputMonthlyCalcState = usePlansInputMonthlyInitialData();

  if (!plansInputMonthlyCalcState) {
    return <Spinner />;
  }

  return (
    <InputLayout>
      {/* PL */}
      <div id="pl"></div>
      <PlBudgetControlBasisTable
        calcState={plansInputMonthlyCalcState}
        dataUrl={dataUrl}
        url={url}
      />

      {/* 原価 */}
      <div className="mt-50px" id="cost"></div>
      <CostBudgetControlBasisTable
        calcState={plansInputMonthlyCalcState}
        dataUrl={dataUrl}
        url={url}
      />
    </InputLayout>
  );
};
