import { DocumentData } from "firebase/firestore";
import { add, dotDivide, multiply, subtract } from "mathjs";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { useGetFirebaseData } from "../../firebase";
import { Logout } from "../../lib/Logout";
import { sortData } from "../../utils";
import { increaseAndDecreaseCalc, totalCalc } from "../../utils/calc";

export const useStatementOfCashFlowsInitialData = () => {
  const queryClient = useQueryClient();

  const { tableData } = useGetFirebaseData();

  const field = pageConstant.RESULTS_INPUT;

  const descriptionsData = tableData.filter(
    (inputData) => inputData.field === "descriptions"
  );
  const descriptions = descriptionsData[0];

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    selling_and_administrative,
    non_operating_income,
    non_operating_expenses,
    special_benefits,
    special_losses,
  } = useSubjectItemsInitialData();

  const totalCalcMemo = useCallback(
    (state: DocumentData[]) =>
      totalCalc({
        headers: headers[field],
        field: field,
        state: state,
      }),
    [headers, field]
  );

  try {
    // 固定資産除却損益
    const lossOnDisposalOfFixedAssets = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "固定資産除却損益"
    );

    // その他損益
    const otherLossOnDisposal = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "その他損益"
    );

    // その他の固定負債の増加減少額
    const otherNonCurrentLiabilities = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "その他の固定負債の増加減少額"
    );

    // 利益処分による役員賞与の支払額
    const bonusesPaidAsAnAppropriationOfProfits = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "利益処分による役員賞与の支払額"
    );

    // キャッシュフロー計算書画面のその他調整額
    const otherAdjustments = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "その他調整額"
    );

    // キャッシュフロー計算書画面の有価証券の購入売却額
    const securitiesPurchasedAndSold = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "有価証券の購入売却額"
    );

    // キャッシュフロー計算書画面の土地の購入売却額
    const landPurchaseSaleValue = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "土地の購入売却額"
    );

    // キャッシュフロー計算書画面の建設仮勘定の増加（－）・減少（＋）額
    const increaseOrDecreaseInConstructionInProgress = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "建設仮勘定の増加減少額"
    );

    // 繰延資産の増加減少額
    const increaseOrDecreaseInDeferredAssets = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "繰延資産の増加減少額"
    );

    // 剰余金の配当の支払額
    const paymentOfDividendsFromSurplus = tableData.filter(
      (resultInput) =>
        resultInput.field === "statement_of_cash_flows" &&
        resultInput.item === "剰余金の配当の支払額"
    );

    const statementOfCashFlowsState = {
      headers: headers,
      loss_on_disposal_of_fixed_assets: lossOnDisposalOfFixedAssets,
      other_loss_on_disposal: otherLossOnDisposal,
      other_non_current_liabilities: otherNonCurrentLiabilities,
      bonuses_paid_as_an_appropriation_of_profits:
        bonusesPaidAsAnAppropriationOfProfits,
      other_adjustments: otherAdjustments,
      securities_purchased_and_sold: securitiesPurchasedAndSold,
      land_purchase_sale_value: landPurchaseSaleValue,
      increase_or_decrease_in_construction_in_progress:
        increaseOrDecreaseInConstructionInProgress,
      increase_or_decrease_in_deferred_assets:
        increaseOrDecreaseInDeferredAssets,
      payment_of_dividends_from_surplus: paymentOfDividendsFromSurplus,
      descriptions: descriptions,
    };

    // ゼロの配列
    const zeroArray: number[] = [];
    headers[field].map(() => {
      zeroArray.push(0);
    });

    // 販売費及び一般管理費と経費の減価償却費合計
    const depreciationCostData = tableData.filter(
      (resultInput) => resultInput.item === "減価償却費"
    );

    const depreciationCost: number[] = totalCalcMemo(depreciationCostData);

    // 期末製品棚卸高
    const endInventoriesData = tableData.filter(
      (resultInput) =>
        resultInput.field === "cost_of_sales" &&
        resultInput.item === "期末商品棚卸高"
    );

    // 売上高合計
    const netSalesTotal: number[] = totalCalcMemo(net_sales);

    const endInventoriesTotal: number[] = totalCalcMemo(endInventoriesData);
    // 原価合計
    const costOfSalesTotal: number[] = subtract(
      subtract(totalCalcMemo(cost_of_sales), endInventoriesTotal),
      endInventoriesTotal
    );

    // 売上総利益
    const grossMargin: number[] = subtract(netSalesTotal, costOfSalesTotal);

    const personalCostTotal: number[] = totalCalcMemo(personal_cost);
    const sellingAndAdministrativeTotal: number[] = add(
      personalCostTotal,
      totalCalcMemo(selling_and_administrative)
    );
    // 営業利益
    const operatingIncome: number[] = subtract(
      grossMargin,
      sellingAndAdministrativeTotal
    );
    const nonOperatingIncomeTotal: number[] =
      totalCalcMemo(non_operating_income);
    const nonOperatingExpensesTotal: number[] = totalCalcMemo(
      non_operating_expenses
    );
    // 経常利益
    const currentEarnings: number[] = subtract(
      add(operatingIncome, nonOperatingIncomeTotal),
      nonOperatingExpensesTotal
    );
    const specialBenefitsTotal: number[] = totalCalcMemo(special_benefits);
    const specialLossesTotal: number[] = totalCalcMemo(special_losses);
    // 税引き前当期純利益 経常利益+特別利益合計-特別損失合計
    const ebit: number[] = subtract(
      add(currentEarnings, specialBenefitsTotal),
      specialLossesTotal
    );

    // 法人税、住民税及び事業税合計
    const corporateInhabitantAndEnterpriseTaxes = sortData(
      "special_corporate_inhabitant_and_enterprise_taxeslosses",
      tableData
    );
    const corporateInhabitantAndEnterpriseTaxesT: number[] = totalCalcMemo(
      corporateInhabitantAndEnterpriseTaxes
    );
    // 当期純利益
    const netIncome: number[] = subtract(
      ebit,
      corporateInhabitantAndEnterpriseTaxesT
    );

    // キャッシュフロー計算書画面の諸引当金の増加（＋）・減少（－）額
    const increaseAndDecreaseQuotidianGold = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "quotidian_gold",
      dataItem: null,
      headers: headers[field],
      field: field,
    }) as number[];

    // キャッシュフロー計算書画面の固定資産除却損益
    const lossOnDisposalOfFixedAssetsYearly = totalCalcMemo(
      lossOnDisposalOfFixedAssets
    );

    // キャッシュフロー計算書画面のその他損益
    const otherLossOnDisposalYearly = totalCalcMemo(otherLossOnDisposal);

    // キャッシュフロー計算書画面のその他の固定負債の増加減少額
    const otherNonCurrentLiabilitiesYearly = totalCalcMemo(
      otherNonCurrentLiabilities
    );

    // 利益処分による役員賞与の支払額
    const bonusesPaidAsAnAppropriationOfProfitsYearly = totalCalcMemo(
      bonusesPaidAsAnAppropriationOfProfits
    );

    // 有価証券の購入売却額
    const securitiesPurchasedAndSoldYearly = totalCalcMemo(
      securitiesPurchasedAndSold
    );

    // 土地の購入売却額
    const landPurchaseSaleValueYearly = totalCalcMemo(landPurchaseSaleValue);

    // 建設仮勘定の増加減少額
    const increaseOrDecreaseInConstructionInProgressYearly = totalCalcMemo(
      increaseOrDecreaseInConstructionInProgress
    );

    // 繰延資産の増加減少額
    const increaseOrDecreaseInDeferredAssetsYearly = totalCalcMemo(
      increaseOrDecreaseInDeferredAssets
    );

    // 剰余金の配当の支払額
    const paymentOfDividendsFromSurplusYearly = totalCalcMemo(
      paymentOfDividendsFromSurplus
    );

    // 受取手形の増加（－）・減少（＋）額
    const increaseOrDecreaseInNotesReceivable = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "accounts_receivable",
      dataItem: "受取手形",
      headers: headers[field],
      field: field,
    }) as number[];

    // 前渡金の増減
    const increaseOrDecreaseInAdvancePayment = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "other_current_assets",
      dataItem: "前渡金",
      headers: headers[field],
      field: field,
    }) as number[];

    // 立替金の増減
    const increaseOrDecreaseInAdvancesPaid = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "other_current_assets",
      dataItem: "立替金",
      headers: headers[field],
      field: field,
    }) as number[];

    // その他の流動資産の増加（－）・減少（＋）額
    const increaseOrDecreaseInCurrentAssets = add(
      increaseOrDecreaseInAdvancePayment,
      increaseOrDecreaseInAdvancesPaid
    );

    // 売掛金の増加（－）・減少（＋）額
    const increaseOrDecreaseInBalanceReceivable = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "accounts_receivable",
      dataItem: "売掛金",
      headers: headers[field],
      field: field,
    }) as number[];

    // 棚卸資産の増加（－）・減少（＋）額
    const increaseOrDecreaseInShedUnloadingAssets = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "shed_unloading_assets",
      dataItem: null,
      headers: headers[field],
      field: field,
    }) as number[];

    // 支払手形の増加（－）・減少（＋）額
    const increaseOrDecreaseInBillsPayables = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "payables",
        dataItem: "支払手形",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // 買掛金の増加（－）・減少（＋）額
    const increaseOrDecreaseInAccountsPayables = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "payables",
        dataItem: "買掛金",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // 前受金の増加（－）・減少（＋）額
    const increaseAndDecreaseAdvances = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "current_liabilities",
        dataItem: "前受金",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // その他の流動負債の増加（＋）・減少（－）額
    const increaseAndDecreaseDeposit = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "current_liabilities",
      dataItem: "預り金",
      headers: headers[field],
      field: field,
    }) as number[];
    const increaseAndDecreaseUnpaids = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "unpaids",
      dataItem: null,
      headers: headers[field],
      field: field,
    }) as number[];

    const increaseAndDecreaseOtherCurrentLiabilities = subtract(
      dotDivide(increaseAndDecreaseUnpaids, -1),
      increaseAndDecreaseDeposit
    ) as number[];

    // 短期貸付金の貸付（－）・回収（＋）額
    const increaseAndDecreaseShortTermLoansRecivable = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "other_current_assets",
      dataItem: "短期貸付金",
      headers: headers[field],
      field: field,
    }) as number[];

    // 特別損失内の固定資産除却損
    const lossOnDisposalOfFixedAssetsData = tableData.filter(
      (resultInput) =>
        resultInput.field === "special_losses" &&
        resultInput.item === "固定資産除却損"
    );
    const lossOnDisposalOfFixedAssetsTotal = totalCalcMemo(
      lossOnDisposalOfFixedAssetsData
    );

    // 有形固定資産の増加（－）・減少（＋）額
    const increaseAndDecreasetTangibleFixedAssets = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "tangible_fixed_assets",
      dataItem: null,
      headers: headers[field],
      field: field,
    }) as number[];

    const increaseAndDecreasetTangibleFixedAssetsTotal = subtract(
      increaseAndDecreasetTangibleFixedAssets,
      add(depreciationCost, lossOnDisposalOfFixedAssetsTotal)
    );

    // 無形固定資産の増加（－）・減少（＋）額
    const increaseAndDecreasetIntangibleFixedAssets = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "intangible_fixed_assets",
      dataItem: null,
      headers: headers[field],
      field: field,
    }) as number[];

    // 投資有価証券の購入（－）・売却（＋）額
    const increaseAndDecreasetInvestmentSecurities = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "investments_and_other_assets",
      dataItem: "出資金",
      headers: headers[field],
      field: field,
    }) as number[];

    // 長期貸付金の貸付（－）・回収（＋）額
    const increaseAndDecreasetLongTermLoan = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "investments_and_other_assets",
      dataItem: "長期貸付金",
      headers: headers[field],
      field: field,
    }) as number[];

    // 長期未収入金の増減
    const increaseAndDecreasetLongTermAccountsReceivable =
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "investments_and_other_assets",
        dataItem: "長期未収入金",
        headers: headers[field],
        field: field,
      }) as number[];
    // 保証金の増減
    const increaseAndDecreasetBondPayment = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "investments_and_other_assets",
      dataItem: "保証金",
      headers: headers[field],
      field: field,
    }) as number[];
    // 保険積立金の増減
    const increaseAndDecreasetInsuranceFunds = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "investments_and_other_assets",
      dataItem: "保険積立金",
      headers: headers[field],
      field: field,
    }) as number[];
    // 長期前払費用の増減
    const increaseAndDecreasetLongTermPrepaidExpenses = increaseAndDecreaseCalc(
      {
        tableData: tableData,
        dataField: "investments_and_other_assets",
        dataItem: "長期前払費用",
        headers: headers[field],
        field: field,
      }
    ) as number[];
    // 長期預金の増減
    const increaseAndDecreasetLongTermDeposit = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "investments_and_other_assets",
      dataItem: "長期預金",
      headers: headers[field],
      field: field,
    }) as number[];

    // その他の固定資産の増加（－）・減少（＋）額
    const increaseAndDecreasetOtherFixedAssets = add(
      increaseAndDecreasetLongTermAccountsReceivable,
      add(
        increaseAndDecreasetBondPayment,
        add(
          increaseAndDecreasetInsuranceFunds,
          add(
            increaseAndDecreasetLongTermPrepaidExpenses,
            increaseAndDecreasetLongTermDeposit
          )
        )
      )
    );

    // １．短期借入金の増加（＋）・減少（－）額
    const increaseAndDecreasetShortTermBorrowing = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "short_term_borrowings",
        dataItem: "短期借入金",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // ２．長期借入金の増加（＋）・減少（－）額
    const increaseAndDecreasetLongTermBorrowing = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "fixed_liabilities",
        dataItem: "長期借入金",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // ３．社債の増加（＋）・返済（－）額
    const increaseAndDecreasetCorporateBond = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "fixed_liabilities",
        dataItem: "社債",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // ４．増資（＋）額
    const increaseAndDecreasetCapitalIncrease = dotDivide(
      increaseAndDecreaseCalc({
        tableData: tableData,
        dataField: "capital",
        dataItem: "資本金",
        headers: headers[field],
        field: field,
      }) as number[],
      -1
    ) as number[];

    // ５．自己株式の取得（－）・処分（＋）額
    const increaseAndDecreasetTreasuryStock = increaseAndDecreaseCalc({
      tableData: tableData,
      dataField: "treasury_stock",
      dataItem: "自己株式",
      headers: headers[field],
      field: field,
    }) as number[];

    // 検算（貸借対照表の現金及び預金） BSの現預金
    const cashEquivalent = tableData.filter(
      (resultInput) => resultInput.field === "cash_equivalent"
    );

    const cashEquivalentYearly = totalCalcMemo(cashEquivalent);

    // Ⅰの計
    let totalFirst = add(
      netIncome,
      add(
        depreciationCost,
        add(
          increaseAndDecreaseQuotidianGold,
          add(
            lossOnDisposalOfFixedAssetsYearly,
            add(
              otherLossOnDisposalYearly,
              add(
                increaseOrDecreaseInNotesReceivable,
                add(
                  increaseOrDecreaseInBalanceReceivable,
                  add(
                    increaseOrDecreaseInShedUnloadingAssets,
                    add(
                      increaseOrDecreaseInCurrentAssets,
                      add(
                        increaseOrDecreaseInBillsPayables,
                        add(
                          increaseOrDecreaseInAccountsPayables,
                          add(
                            increaseAndDecreaseAdvances,
                            add(
                              increaseAndDecreaseOtherCurrentLiabilities,
                              add(
                                otherNonCurrentLiabilitiesYearly,
                                bonusesPaidAsAnAppropriationOfProfitsYearly
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    ) as number[];

    // Ⅱの計
    const totalSecond = add(
      securitiesPurchasedAndSoldYearly,
      add(
        increaseAndDecreaseShortTermLoansRecivable,
        add(
          landPurchaseSaleValueYearly,
          add(
            increaseAndDecreasetTangibleFixedAssetsTotal,
            add(
              increaseOrDecreaseInConstructionInProgressYearly,
              add(
                increaseAndDecreasetIntangibleFixedAssets,
                add(
                  increaseAndDecreasetInvestmentSecurities,
                  add(
                    increaseAndDecreasetLongTermLoan,
                    add(
                      increaseAndDecreasetOtherFixedAssets,
                      increaseOrDecreaseInDeferredAssetsYearly
                    )
                  )
                )
              )
            )
          )
        )
      )
    ) as number[];

    // Ⅲの計
    const totalThird = add(
      increaseAndDecreasetShortTermBorrowing,
      add(
        increaseAndDecreasetLongTermBorrowing,
        add(
          increaseAndDecreasetCorporateBond,
          add(
            increaseAndDecreasetCapitalIncrease,
            add(
              increaseAndDecreasetTreasuryStock,
              paymentOfDividendsFromSurplusYearly
            )
          )
        )
      )
    ) as number[];

    // Ⅴ キャッシュの期首残高
    const balanceCashEquivalentYearly: number[] = [];
    cashEquivalentYearly.map((_, index) => {
      if (index === 0) {
        balanceCashEquivalentYearly.push(1);
      } else if (cashEquivalentYearly.length === index + 1) {
        return;
      }
      balanceCashEquivalentYearly.push(cashEquivalentYearly[index]);
    });

    // 調整額
    const differenceData = subtract(
      add(
        add(add(totalFirst, totalSecond), totalThird),
        balanceCashEquivalentYearly
      ),
      cashEquivalentYearly
    );

    // その他調整額
    const otherAdjustmentsYearly = multiply(differenceData, -1) as number[];

    // 調整額を入れた計算
    totalFirst = add(totalFirst, otherAdjustmentsYearly);

    // ⅠとⅡの計
    const totalFirstAndSecond = add(totalFirst, totalSecond);

    // Ⅰ、Ⅱ、Ⅲの計
    const totalFirstAndSecondAndThird = add(
      add(totalFirst, totalSecond),
      totalThird
    ) as number[];

    // Ⅵ キャッシュの期末残高（Ⅳ＋Ⅴ）
    const balanceEndOfCashEquivalent = add(
      totalFirstAndSecondAndThird,
      balanceCashEquivalentYearly
    ) as number[];

    const statementOfCashFlowsCalcState = {
      gross_margin: grossMargin,
      depreciation_cost: depreciationCost,
      net_income: netIncome,
      increase_and_decrease_quotidian_gold: increaseAndDecreaseQuotidianGold,
      loss_on_disposal_of_fixed_assets_yearly:
        lossOnDisposalOfFixedAssetsYearly,
      other_loss_on_disposal_yearly: otherLossOnDisposalYearly,
      other_non_current_liabilities_yearly: otherNonCurrentLiabilitiesYearly,
      bonusesPaidAsAnAppropriationOfProfitsYearly:
        bonusesPaidAsAnAppropriationOfProfitsYearly,
      other_adjustments_yearly: otherAdjustmentsYearly,
      securities_purchased_and_sold_yearly: securitiesPurchasedAndSoldYearly,
      land_purchase_sale_value_yearly: landPurchaseSaleValueYearly,
      increase_or_decrease_in_construction_in_progress_yearly:
        increaseOrDecreaseInConstructionInProgressYearly,
      increase_or_decrease_in_deferred_assets_yearly:
        increaseOrDecreaseInDeferredAssetsYearly,
      payment_of_dividends_from_surplus_yearly:
        paymentOfDividendsFromSurplusYearly,
      increase_or_decrease_in_notes_receivable:
        increaseOrDecreaseInNotesReceivable,
      increase_or_decrease_in_current_assets: increaseOrDecreaseInCurrentAssets,
      increase_or_decrease_in_balance_receivable:
        increaseOrDecreaseInBalanceReceivable,
      increase_or_decrease_in_shed_unloading_assets:
        increaseOrDecreaseInShedUnloadingAssets,
      increase_or_decrease_in_bills_payables: increaseOrDecreaseInBillsPayables,
      increase_or_decrease_in_accounts_payables:
        increaseOrDecreaseInAccountsPayables,
      increase_and_decrease_advances: increaseAndDecreaseAdvances,
      increase_and_decrease_other_current_liabilities:
        increaseAndDecreaseOtherCurrentLiabilities,
      total_first: totalFirst,
      increase_and_decrease_short_term_loans_recivable:
        increaseAndDecreaseShortTermLoansRecivable,
      loss_on_disposal_of_fixed_assets_total: lossOnDisposalOfFixedAssetsTotal,
      increase_and_decreaset_tangible_fixed_assets_total:
        increaseAndDecreasetTangibleFixedAssetsTotal,
      increase_and_decreaset_intangible_fixed_assets:
        increaseAndDecreasetIntangibleFixedAssets,
      increase_and_decreaset_investment_securities:
        increaseAndDecreasetInvestmentSecurities,
      increase_and_decreaset_long_term_loan: increaseAndDecreasetLongTermLoan,
      increase_and_decreaset_other_fixed_assets:
        increaseAndDecreasetOtherFixedAssets,
      total_second: totalSecond,
      total_first_and_second: totalFirstAndSecond,
      increase_and_decreaset_short_term_borrowing:
        increaseAndDecreasetShortTermBorrowing,
      increase_and_decreaset_long_term_borrowing:
        increaseAndDecreasetLongTermBorrowing,
      increase_and_decreaset_corporate_bond: increaseAndDecreasetCorporateBond,
      increase_and_decreaset_capital_increase:
        increaseAndDecreasetCapitalIncrease,
      increase_and_decreaset_treasury_stock: increaseAndDecreasetTreasuryStock,
      total_third: totalThird,
      total_firstd_second_and_third: totalFirstAndSecondAndThird,
      balance_cash_equivalent_yearly: balanceCashEquivalentYearly,
      balance_end_of_cash_equivalent: balanceEndOfCashEquivalent,
      cash_equivalent_yearly: cashEquivalentYearly,
      difference_data: differenceData,
    };

    return { statementOfCashFlowsState, statementOfCashFlowsCalcState };
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
