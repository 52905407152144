import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AuthProvider,
  FirestoreProvider,
  useAuth,
  useFirebaseApp,
} from "reactfire";
import { RecoilRoot } from "recoil";
import { RegisterHeader } from "./components/header";
import LpWithLoginHeader from "./components/header/LpWithLoginHeader";
import { Dashboard } from "./components/layout";
import { Spinner } from "./components/molecules";
import ScrollTop from "./components/molecules/ScrollTop";
import { pageConstant } from "./constants/pageConstants";
import {
  BudgetControlBasis,
  CurrentPlanMonthly,
  CurrentResultMonthly,
  EstimatedInThisTerm,
} from "./pages/budgetControl";
import {
  Clients,
  EmailInvitation,
  ClientInvitation,
  CompleteClientInvitation,
} from "./pages/clients";
import Home from "./pages/home";
import { AccountingPeriod, AccountTitles, Kpi } from "./pages/initialSettings";
import {
  AccountsClients,
  AccountsSpecialists,
  PlanChange,
} from "./pages/login";
import Login from "./pages/login/login";
import {
  CashFlowTable,
  ManagementDashboard,
} from "./pages/managementSimulation";
import {
  PlansInput,
  PlansInputMonthly,
  CurrentAndPreviousYearComparisons,
  ResultsOfTheCurrentTerm,
  ResultsOfThePreviousPeriod,
  ResultsInput,
  SalesPlan,
  ProfitAndLossPlan,
  BusinessPlan,
  StatementOfCashFlows,
  PlansInputEstimatedInThisTerm,
} from "./pages/planMaking";
import {
  CheckMail,
  SignUp,
  SignUpPayment,
  ConfirmSignUp,
  PasswordReset,
  PasswordResetting,
  SignUpSpecialist,
  SignupClient,
  ConfirmSignUpClient,
  VerifySuccess,
} from "./pages/signUp";
import TermOfService from "./pages/signUp/termOfService";
import { CommercialTransaction } from "./pages/commercialTransaction";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const RegisterLayout = () => {
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <RegisterHeader />
      <Outlet />
    </FirestoreProvider>
  );
};

const LpWithLoginLayout = () => {
  return (
    <React.Fragment>
      <ScrollTop />
      <LpWithLoginHeader />
      <div className="flex flex-col justify-center items-center max-w-[1366px] px-100px mx-100px 2xl:mx-auto py-40px">
        <Outlet />
      </div>
    </React.Fragment>
  );
};

const LoginLayout = () => {
  const firestoreInstance = getFirestore(useFirebaseApp());
  // const { data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
  //   const db = initializeFirestore(firebaseApp, {});
  //   await enableMultiTabIndexedDbPersistence(db).catch((err) => {
  //     throw new Error(err.message);

  //     if (err.code == "failed-precondition") {
  //       // Multiple tabs open, persistence can only be enabled
  //       // in one tab at a a time.
  //       // ...
  //     } else if (err.code == "unimplemented") {
  //       // The current browser does not support all of the
  //       // features required to enable persistence
  //       // ...
  //     }
  //   });
  //   return db;
  // });

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        // ユーザーが認証されていない場合、ログイン画面にリダイレクト
        window.location.href = `/${pageConstant.LOGIN}`;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  return (
    <React.Suspense
      fallback={
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      }
    >
      <FirestoreProvider sdk={firestoreInstance}>
        <Dashboard>
          <Outlet />
        </Dashboard>
      </FirestoreProvider>
    </React.Suspense>
  );
};

function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AuthProvider sdk={auth}>
            <Routes>
              <Route>
                <Route element={<Home />} path="/" />
                <Route
                  element={<CommercialTransaction />}
                  path={pageConstant.COMMERCIAL_TRANSACTION}
                />
              </Route>
              <Route element={<RegisterLayout />}>
                <Route element={<SignUp />} path={pageConstant.SIGNUP} />
                <Route
                  element={<CheckMail />}
                  path={pageConstant.CHECK_EMAIL}
                />
                <Route
                  element={<CheckMail />}
                  path={pageConstant.CHECK_EMAIL}
                />
                <Route
                  element={<SignUpSpecialist />}
                  path={pageConstant.SIGNUP_SPECIALIST}
                />
                <Route
                  element={<SignUpPayment />}
                  path={pageConstant.SIGNUP_PAYMENT}
                />
                <Route
                  element={<ConfirmSignUp />}
                  path={pageConstant.CONFIRM_SIGNUP}
                />
                <Route element={<Login />} path={pageConstant.LOGIN} />
                <Route
                  element={<VerifySuccess />}
                  path={pageConstant.VERIFY_SUCCESS}
                />
                <Route
                  element={<PasswordReset />}
                  path={pageConstant.PASSWORD_RESET}
                />
                <Route
                  element={<PasswordResetting />}
                  path={pageConstant.PASSWORD_RESETTING}
                />
                <Route
                  element={<SignupClient />}
                  path={pageConstant.SIGNUP_CLIENT}
                />
                <Route
                  element={<ConfirmSignUpClient />}
                  path={pageConstant.CONFIRM_SIGNUP_CLIENT}
                />
              </Route>
              <Route element={<LpWithLoginLayout />}>
                <Route
                  element={<TermOfService />}
                  path={pageConstant.TERMS_OF_SERVICE}
                />
              </Route>
              <Route element={<LoginLayout />}>
                <Route element={<Clients />} path={pageConstant.CLIENTS} />
                <Route
                  element={<ClientInvitation />}
                  path={pageConstant.CLIENT_INVITATION}
                />
                <Route
                  element={<EmailInvitation />}
                  path={pageConstant.EMAIL_INVITATION}
                />
                <Route
                  element={<CompleteClientInvitation />}
                  path={pageConstant.COMPLETE_CLIENT_INVITATION}
                />
                <Route
                  element={<AccountingPeriod />}
                  path={pageConstant.ACCOUNTING_PERIOD}
                />
                <Route
                  element={<AccountTitles />}
                  path={pageConstant.ACCOUNT_TITLES}
                />
                <Route element={<Kpi />} path={pageConstant.KPI} />
                <Route
                  element={<ResultsInput />}
                  path={pageConstant.RESULTS_INPUT}
                />
                <Route
                  element={<ResultsOfThePreviousPeriod />}
                  path={pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD}
                />
                <Route
                  element={<ResultsOfTheCurrentTerm />}
                  path={pageConstant.RESULTS_OF_THE_CURRENT_TERM}
                />
                <Route
                  element={<CurrentAndPreviousYearComparisons />}
                  path={pageConstant.CURRENT_AND_PREVIOUS_YEAR_COMPARISONS}
                />
                <Route
                  element={<PlansInput />}
                  path={pageConstant.PLANS_INPUT}
                />
                <Route
                  element={<PlansInputMonthly />}
                  path={pageConstant.PLANS_INPUT_MONTHLY}
                />
                <Route
                  element={<PlansInputEstimatedInThisTerm />}
                  path={pageConstant.PLANS_INPUT_ESTIMATED_IN_THIS_TERM}
                />
                <Route element={<SalesPlan />} path={pageConstant.SALES_PLAN} />
                <Route
                  element={<ProfitAndLossPlan />}
                  path={pageConstant.PROFIT_AND_LOSS_PLAN}
                />
                <Route
                  element={<BusinessPlan />}
                  path={pageConstant.BUSINESS_PLAN}
                />
                <Route
                  element={<StatementOfCashFlows />}
                  path={pageConstant.STATEMENT_OF_CASH_FLOWS}
                />
                <Route
                  element={<BudgetControlBasis />}
                  path={pageConstant.BUDGET_CONTROL_BASIS}
                />
                <Route
                  element={<CurrentPlanMonthly />}
                  path={pageConstant.CURRENT_PLAN_MONTHLY}
                />
                <Route
                  element={<EstimatedInThisTerm />}
                  path={pageConstant.ESTIMATED_IN_THIS_TERM}
                />
                <Route
                  element={<CurrentResultMonthly />}
                  path={pageConstant.CURRENT_RESULT_MONTHLY}
                />
                {/* <Route
                  element={<PlSimulation />}
                  path={pageConstant.PL_SIMULATION}
                /> */}
                <Route
                  element={<CashFlowTable />}
                  path={pageConstant.CASH_FLOW_TABLE}
                />
                <Route
                  element={<ManagementDashboard />}
                  path={pageConstant.MANAGEMENT_DASHBOARD}
                />
                <Route
                  element={<AccountsSpecialists />}
                  path={pageConstant.ACCOUNTS_SPECIALISTS}
                />
                <Route
                  element={<AccountsClients />}
                  path={pageConstant.ACCOUNTS_CLIENTS}
                />
                <Route
                  element={<PlanChange />}
                  path={pageConstant.PLAN_CHANGE}
                />
                {/* その他のページは、決算期の設定画面へリダイレクト */}
                <Route
                  element={<Navigate replace to="/accounting_period" />}
                  path="*"
                />
              </Route>
            </Routes>
            <div className="z-50">
              <ToastContainer
                autoClose={3000}
                closeOnClick
                draggable
                hideProgressBar={false}
                newestOnTop={false}
                pauseOnFocusLoss
                pauseOnHover
                position="bottom-center"
                rtl={false}
                theme="colored"
              />
            </div>
          </AuthProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
