export const beforeWrapper =
  "sticky before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full z-30";

export const borderSettings =
  "relative p-4px before:border-b before:border-l before:border-l-c3333334d before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 ";

export const topHeaderCss =
  "relative px-6px py-3px top-0 text-right before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full";

export const bottomHeaderCss =
  "relative px-6px py-3px text-right before:content-[''] before:border-b-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-full";
