import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PersonalCostGrowthRate = ({
  headers,
  state,
  descriptions,
  url,
}: {
  headers: DocumentData[];
  state: DocumentData;
  descriptions: DocumentData;
  url: string;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-14px font-bold bg-white before:border-x before:border-r-c3333334d before:border-b`;

  const classCol2 = `${borderSettings} p-4px before:border-l before:border-l-c3333334d
        before:border-b text-14px  text-right`;

  const classCol3 = `${borderSettings} p-4px before:border-x before:border-l-c3333334d
        before:border-b text-14px h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: "伸び率（％）",
      readOnly: true,
      className: `${classCol1}`,
    },
  ];

  headers?.map((_, tdIndex: number) => {
    if (tdIndex === 0) return;

    gridRow.push({
      value: tdIndex === 0 ? "" : `${showPercentage(state[tdIndex])}%`,
      expr: tdIndex === 0 ? "" : `${showPercentage(state[tdIndex])}%`,
      readOnly: true,
      className: `${classCol2}`,
    });
  });

  gridRow.push({
    value: descriptions[`${url}_personal_cost_growth_rate`],
    readOnly: false,
    className: `${classCol3}`,
    field: `${url}_personal_cost_growth_rate`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
