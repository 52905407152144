// 現在と一つ前のデータを比較し、増減を取得
import { DocumentData } from "firebase/firestore";
import { subtract } from "mathjs";
import { totalCalc } from "./totalCalc";

export const increaseAndDecreaseCalc = ({
  tableData,
  dataField,
  dataItem,
  headers,
  field,
}: {
  tableData: DocumentData[];
  dataField: string;
  dataItem: string | null;
  headers: DocumentData[];
  field: string;
}) => {
  const data = tableData.filter((resultInput) =>
    dataItem !== null
      ? resultInput.field === dataField && resultInput.item === dataItem
      : resultInput.field === dataField
  );

  const total = totalCalc({
    headers: headers,
    field: field,
    state: data,
  });

  // 合計を一つずらす（配列の1番目には1を入れる）
  const nextTotal: number[] = [];
  total.map((_, index) => {
    if (index === 0) {
      nextTotal.push(1);
    } else if (total.length === index + 1) {
      return;
    }
    nextTotal.push(total[index]);
  });

  const results = subtract(nextTotal, total) as number[];

  return results;
};
