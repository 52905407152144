import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlCurrentAndPreviousYearComparisonsSimpleCf = ({
  previousTotal1,
  currentTotal1,
  totalSubtract1,
  totalRatio1,
  totalReference1,
  totalFixedValue1,
  previousTotal2,
  currentTotal2,
  totalSubtract2,
  totalRatio2,
  totalReference2,
  totalFixedValue2,
  previousTotal3,
  currentTotal3,
  totalSubtract3,
  totalRatio3,
  totalReference3,
  totalFixedValue3,
  field1,
  field2,
  field3,
  descriptions,
  unit,
}: {
  previousTotal1: number;
  currentTotal1: number;
  totalSubtract1: number;
  totalRatio1: number;
  totalReference1: number;
  totalFixedValue1: number;
  previousTotal2: number;
  currentTotal2: number;
  totalSubtract2: number;
  totalRatio2: number;
  totalReference2: number;
  totalFixedValue2: number;
  previousTotal3: number;
  currentTotal3: number;
  totalSubtract3: number;
  totalRatio3: number;
  totalReference3: number;
  totalFixedValue3: number;
  field1: string;
  field2: string;
  field3: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pl-4px bg-white before:bg-c80b0f433 before:border-l z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-c80b0f433 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-r before:border-r-c3333334d before:border-b  before:bg-c80b0f433`;

  const classCol4 = `${borderSettings}  text-right font-bold before:bg-c80b0f433`;

  const classCol5 = `${borderSettings} before:border-b before:border-x text-left font-bold before:bg-c80b0f433 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "簡易CF",
      readOnly: true,
      className: `${classCol1} before:border-y before:border-b-c3333334d before:border-r-c3333334d`,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          経常利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-y   before:border-b-c3333334d before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-y   before:border-b-c3333334d before:border-r  before:border-r-c3333334d`,
    },
  ];
  // 1行目前期
  gridRow.push({
    value: ShowTableData(previousTotal1 / unit),
    expr: previousTotal1 / unit,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  // 1行目今期
  gridRow.push({
    value: ShowTableData(currentTotal1 / unit),
    expr: currentTotal1 / unit,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  // 今年-前年
  gridRow.push({
    value: ShowTableData(totalSubtract1 / unit),
    expr: totalSubtract1 / unit,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  // 今年/前年
  gridRow.push({
    value: `${String(totalRatio1)}%`,
    expr: totalRatio1,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  // 自動計算
  gridRow.push({
    value: isNaN(totalReference1)
      ? 0
      : ShowTableData(round(totalReference1) / unit),
    expr: isNaN(totalReference1) ? 0 : totalReference1 / unit,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  // 修正後
  gridRow.push({
    value: isNaN(totalFixedValue1)
      ? 0
      : ShowTableData(round(totalFixedValue1) / unit),
    expr: isNaN(totalFixedValue1) ? 0 : totalFixedValue1 / unit,
    readOnly: true,
    className: `${classCol4} before:border-t before:border-b-c3333334d`,
  });
  gridRow.push({
    value: descriptions[field1],
    expr: descriptions[field1],
    readOnly: false,
    className: `${classCol5}  before:border-t before:border-r before:border-b-c3333334d`,
    field: field1,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} before:border-b`,
    },
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          当純利益+減価償却他
        </div>
      ),
      readOnly: true,
      className: `${classCol2} before:border-b before:border-l  before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r  before:border-r-c3333334d`,
    },
  ];

  // 2行目前期
  gridRow2.push({
    value: ShowTableData(previousTotal2 / unit),
    expr: previousTotal2 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 2行目今期
  gridRow2.push({
    value: ShowTableData(currentTotal2 / unit),
    expr: currentTotal2 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 今年-前年
  gridRow2.push({
    value: ShowTableData(totalSubtract2 / unit),
    expr: totalSubtract2 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 今年/前年
  gridRow2.push({
    value: `${String(totalRatio2)}%`,
    expr: totalRatio2,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 自動計算
  gridRow2.push({
    value: isNaN(totalReference2)
      ? 0
      : ShowTableData(round(totalReference2) / unit),
    expr: isNaN(totalReference2) ? 0 : totalReference2 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 修正後
  gridRow2.push({
    value: isNaN(totalFixedValue2)
      ? 0
      : ShowTableData(round(totalFixedValue2) / unit),
    expr: isNaN(totalFixedValue2) ? 0 : totalFixedValue2 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  gridRow2.push({
    value: descriptions[field2],
    expr: descriptions[field2],
    readOnly: false,
    className: `${classCol5}  before:border-r`,
    field: field2,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow2);

  // 3行目の値
  const gridRow3: GridElement[] = [
    {
      value: "粗付加価値額*1)",
      readOnly: true,
      className: `${classCol1} before:border-b before:border-l whitespace-nowrap`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b before:border-r before:border-r-c3333334d whitespace-nowrap`,
    },
  ];

  // 3行目前期
  gridRow3.push({
    value: ShowTableData(previousTotal3 / unit),
    expr: previousTotal3 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 3行目今期
  gridRow3.push({
    value: ShowTableData(currentTotal3 / unit),
    expr: currentTotal3 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 今年-前年
  gridRow3.push({
    value: ShowTableData(totalSubtract3 / unit),
    expr: totalSubtract3 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 今年/前年
  gridRow3.push({
    value: `${String(totalRatio3)}%`,
    expr: totalRatio3,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 自動計算
  gridRow3.push({
    value: isNaN(totalReference3)
      ? 0
      : ShowTableData(round(totalReference3) / unit),
    expr: isNaN(totalReference3) ? 0 : totalReference3 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  // 修正後
  gridRow3.push({
    value: isNaN(totalFixedValue3)
      ? 0
      : ShowTableData(round(totalFixedValue3) / unit),
    expr: isNaN(totalFixedValue3) ? 0 : totalFixedValue3 / unit,
    readOnly: true,
    className: `${classCol4}`,
  });
  gridRow3.push({
    value: descriptions[field3],
    expr: descriptions[field3],
    readOnly: false,
    className: `${classCol5} before:border-x before:border-l-c3333334d`,
    field: field3,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow3);

  // 注釈
  const gridRow4: GridElement[] = [
    {
      value:
        "*1) 粗付加価値額＝税引前純利益＋人件費＋減価償却費（他償却費）±引当金（＋繰入、－戻入）±前期修正損益＋支払利息",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white`,
      colSpan: 10,
    },
  ];

  grid.push(gridRow4);

  return grid;
};
