import { DocumentData } from "firebase/firestore";
import React from "react";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { ShowTableData } from "../../../lib";
import { Unit } from "../../atoms";

export const TotalCostPerPersonPerMonth = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const managementDashboardState = useManagementDashboardInitialData();

  const states = managementDashboardState?.total_cost_per_person_per_month;
  const unit = String(resultsAndPlansDataState.headers.unit);

  return (
    <div className="mb-20px p-30px bg-white rounded">
      <h2 className="font-bold">■1人1か月販管費</h2>
      <div className="flex">
        <div>
          <Unit unit={unit} />

          <table className="border">
            <thead className=" top-0 px-6px py-3px z-40 bg-c2a4b771a pt-120px border-b-2 text-center">
              <tr>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px`}
                >
                  決算期
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px border-l border-l-c3333334d`}
                >
                  売上原価
                  <br />
                  販売管理費
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  従業員数
                </th>
                <th
                  className={`w-160px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  1人1か月販管費
                </th>
              </tr>
            </thead>
            <tbody>
              {states.map((data: DocumentData, index: number) => (
                <tr
                  className={`border-b ${
                    states.length - 1 !== index && "border-b-c3333334d"
                  }`}
                  key={index}
                >
                  <th className={`p-4px text-right font-bold text-18px`}>
                    {data.name}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["売上原価販売管理費"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["従業員数"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {`${
                      states && isFinite(states[index].parcentage)
                        ? states[index].parcentage
                        : ""
                    }
                  `}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <ResponsiveContainer width={states.length * 150} height={300}>
          <ComposedChart
            width={states.length * 150}
            height={300}
            data={states}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ right: 30, left: 30 }}
            />
            <YAxis
              yAxisId={1}
              orientation="left"
              domain={[
                0,
                (dataMax: number) => round(dataMax * 1.1 ),
              ]}
              width={100}
            />
            <YAxis
              yAxisId={2}
              orientation="right"
              domain={[
                0,
                (dataMax: number) => round(dataMax * 1.1 ),
              ]}
              width={100}
            />
            <Tooltip />
            <Legend />
            <Bar
              yAxisId={1}
              dataKey="売上原価販売管理費"
              barSize={40}
              fill="#9dc6ff"
            />
            <Line
              yAxisId={2}
              type="linear"
              dataKey="従業員数"
              stroke="#295898"
            />
          </ComposedChart>
        </ResponsiveContainer> */}
      </div>
    </div>
  );
};
