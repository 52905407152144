import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PlPlansInputMonthlyCostRatio = ({
  headerTitle,
  title,
  rows,
  headers,
  calcState,
  isCurrentPlanMonthly,
}: {
  headerTitle?: string;
  title?: string;
  rows: DocumentData[];
  headers: DocumentData[];
  calcState: DocumentData;
  field: string;
  isCurrentPlanMonthly?: boolean;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 5 + (headers?.length ?? 0),
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white`,
      colSpan: 5,
    },
  ];
  grid.push(gridRow0);

  // className
  const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white before:border-l align-top`;

  const classCol2 = `${beforeWrapper} ${
    title ? "left-80px" : "left-0"
  }  p-4px bg-white before:border-b before:border-r before:border-r-c3333334d`;

  const classCol3 = `${borderSettings} text-right `;

  const classCol4 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 w-120px  text-right  before:bg-cfffee2 h-50px`;

  rows.map((row, rowIndex) => {
    const gridRow: GridElement[] = [];

    gridRow.push({
      value: rowIndex === 0 && title,
      readOnly: true,
      className: `${classCol1} `,
      colSpan: 2,
    });

    gridRow.push({
      value: row.item,
      readOnly: true,
      className: `${classCol2} ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"} ${
        title && "before:border-l-c3333334d"
      } before:border-l`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      gridRow.push({
        value: `${showPercentage(calcState.by_item_ratio[rowIndex][tdIndex])}%`,
        readOnly: true,
        className: `${classCol3} ${
          !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    });

    gridRow.push({
      value: `${showPercentage(calcState.by_item_ratio_total[rowIndex])}%`,
      readOnly: true,
      className: `${classCol4} ${
        rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
      }`,
    });
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: "",
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: `${showPercentage(calcState.current_term[rowIndex])}%`,
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: "",
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });
    !isCurrentPlanMonthly &&
      gridRow.push({
        value: "",
        readOnly: true,
        className: `${classCol4} ${
          rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
        }`,
      });

    // gridRow.push({
    //   value: resultsAndPlansDataState.descriptions[field],
    //   readOnly: false,
    //   className: `${classCol5} ${
    //     rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    //   } ${rowIndex % 2 !== 0 && "bg-c2a4b770d"}`,
    //   field: field,
    //   key: "descriptions_calc",
    //   row: resultsAndPlansDataState.descriptions,
    // });
    grid.push(gridRow);
  });

  // 売上高合計
  const gridRow2: GridElement[] = [];

  gridRow2.push({
    value: "",
    readOnly: true,
    className: `${classCol1} before:border-b`,
    colSpan: 2,
  });

  gridRow2.push({
    value: "売上高合計",
    readOnly: true,
    className: `${classCol2}`,
    colSpan: title ? 2 : 4,
  });

  headers?.map((_, tdIndex) => {
    gridRow2.push({
      value: `${showPercentage(calcState.monthly_ratio[tdIndex])}%`,
      readOnly: true,
      className: `${classCol3}`,
    });
  });

  gridRow2.push({
    value: `${showPercentage(calcState.monthly_total_ratio)}%`,
    readOnly: true,
    className: `${classCol4}`,
  });
  !isCurrentPlanMonthly &&
    calcState.monthly_total_ratio &&
    gridRow2.push({
      value: "",
      readOnly: true,
      className: `${classCol4}`,
    });

  !isCurrentPlanMonthly &&
    calcState.monthly_total_ratio &&
    gridRow2.push({
      value: `${showPercentage(calcState.current_term_total)}%`,
      readOnly: true,
      className: `${classCol4}`,
    });
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: "",
      readOnly: true,
      className: `${classCol4}`,
    });
  !isCurrentPlanMonthly &&
    gridRow2.push({
      value: "",
      readOnly: true,
      className: `${classCol4}`,
    });

  // gridRow2.push({
  //   value: resultsAndPlansDataState.descriptions[field],
  //   readOnly: false,
  //   className: `${classCol5}`,
  //   field: field,
  //   key: "descriptions_calc",
  //   row: resultsAndPlansDataState.descriptions,
  // });
  grid.push(gridRow2);

  return grid;
};
