import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlResultsOfThePreviousPeriodTotal = ({
  titleTotal,
  titleRatio,
  bottomLine,
  total,
  ratio,
  netSalesTotal,
  totalField,
  ratioField,
  descriptions,
  unit,
}: {
  headers: DocumentData[];
  titleTotal: string;
  titleRatio: string | null;
  bottomLine?: boolean;
  total: number[];
  ratio?: number[];
  netSalesTotal?: number;
  totalField: string;
  ratioField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const totalByItem = sum(total);

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pt-20px pl-4px bg-white before:border-l `;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-l-c3333334d 
  before:bg-c7fb28d33 before:-z-10 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b  before:bg-c7fb28d33`;

  const classCol4 = `${borderSettings}  text-right font-bold bg-c7fb28d33`;

  const classCol5 = `relative p-4px before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10   text-right font-bold before:bg-cfffee2`;

  const classCol6 = `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${
        bottomLine && titleRatio === null
          ? "before:border-b"
          : titleRatio
          ? "before:border-b-c3333334d"
          : ""
      }`,
      colSpan: 2,
    },
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];

  total.map((totalData: number) => {
    gridRow.push({
      value: isNaN(totalData) ? 0 : ShowTableData(totalData / unit),
      expr: isNaN(totalData) ? 0 : totalData / unit,
      readOnly: true,
      className: `${classCol4} before:border-b ${
        titleRatio && "before:border-b-c3333334d"
      }`,
    });
  });

  {
    gridRow.push({
      value: ShowTableData((totalByItem as number) / unit),
      expr: (totalByItem as number) / unit,
      readOnly: true,
      className: `${classCol5} ${titleRatio && "before:border-b-c3333334d"}`,
    });
  }

  gridRow.push({
    value: descriptions[totalField] ? descriptions[totalField] : "",
    expr: descriptions[totalField] ? descriptions[totalField] : "",
    readOnly: false,
    className: `${classCol6}  ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${bottomLine && "before:border-b"}`,
      colSpan: 2,
    },
    {
      value: titleRatio,
      readOnly: true,
      className: `${classCol2} before:border-b text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];

  ratio &&
    ratio.map((ratioData: number) => {
      gridRow2.push({
        value: `${ratioData}%`,
        expr: ratioData,
        readOnly: true,
        className: `${classCol4} before:border-t-c3333334d`,
      });
    });
  {
    gridRow2.push({
      value: netSalesTotal && `${showPercentage(totalByItem / netSalesTotal)}%`,
      readOnly: true,
      className: `${classCol5} before:border-t-c3333334d`,
    });
  }

  gridRow2.push({
    value:
      ratioField && descriptions[ratioField]
        ? ratioField && descriptions[ratioField]
        : "",
    expr:
      ratioField && descriptions[ratioField]
        ? ratioField && descriptions[ratioField]
        : "",
    readOnly: false,
    className: `${classCol6}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
