import React from "react";
import { useRecoilValue } from "recoil";
import { toggleStore } from "../../../store/switchState";

interface Props {
  children: React.ReactNode;
}

const SalesPlanLayout: React.FC<Props> = ({ children }) => {
  const toggle = useRecoilValue(toggleStore);

  return (
    <div
      className={`fixed ${
        toggle ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-35px)]"
      } h-[calc(100vh-190px)] mt-150px top-0 overflow-x-scroll m-16px z-30`}
    >
      <div className="flex flex-col">
        <div className="relative w-full flex-grow scroll-x">{children}</div>
      </div>
    </div>
  );
};

export default SalesPlanLayout;
