import { nengou } from "../../utils/date";

export const plansInputHeader = (
  year: string | number,
  month: string | number,
  currentTerm: number
) => [
  {
    nengou: nengou(Number(year) + 1, month),
    period: `y${String(Number(year) + 1)}${String(month)}`,
    term: String(currentTerm + 1),
  },
  {
    nengou: nengou(Number(year) + 2, month),
    period: `y${String(Number(year) + 2)}${String(month)}`,
    term: String(currentTerm + 2),
  },
  {
    nengou: nengou(Number(year) + 3, month),
    period: `y${String(Number(year) + 3)}${String(month)}`,
    term: String(currentTerm + 3),
  },
  {
    nengou: nengou(Number(year) + 4, month),
    period: `y${String(Number(year) + 4)}${String(month)}`,
    term: String(currentTerm + 4),
  },
  {
    nengou: nengou(Number(year) + 5, month),
    period: `y${String(Number(year) + 5)}${String(month)}`,
    term: String(currentTerm + 5),
  },
];
