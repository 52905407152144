import { DocumentData } from "firebase/firestore";
import React, { memo } from "react";
import ReactDataSheet from "react-datasheet";
import { useFirestore } from "reactfire";
import { useSubjectItemsInitialData } from "../../../../hooks/initialData";
import { HandleCellChange } from "../../../../lib/datasheets";
import {
  BudgetControlBasisHeader,
  BudgetControlBasisTableBlank,
  PlBudgetControlBasisBody,
  PlBudgetControlBasisBodyWithTax,
  PlBudgetControlBasisGrandTotal,
  PlBudgetControlBasisTotal,
} from "../../../../lib/datasheets/budgetControlBasis";
import { PlBudgetControlBasisSimplePl } from "../../../../lib/datasheets/budgetControlBasis/PlBudgetControlBasisSimplePl";
import { PlansInputMonthlyFieldsCalc } from "../../../../types";
import { GridElement } from "../../../../types/gridElement";

interface Props {
  url: string;
  dataUrl: string;
  calcState: PlansInputMonthlyFieldsCalc;
}

const PlBudgetControlBasisTable: React.FC<Props> = ({
  url,
  calcState,
  dataUrl,
}) => {
  const firestore = useFirestore();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const unit = Number(resultsAndPlansDataState.headers.unit ?? 1);

  const grid = PlBudgetControlBasisBody({
    title: "売上高",
    headers: resultsAndPlansDataState.headers[dataUrl],
    rows: resultsAndPlansDataState.net_sales,
    calcState: calcState.net_sales_total,
    url: url,
    unit: unit,
  });

  grid.push(
    ...PlBudgetControlBasisTotal({
      titleTotal: "売上高合計",
      titleRatio: "前年比、構成比",
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.net_sales_total,
      totalField: `${url}_net_sales_total`,
      ratioField: `${url}_net_sales_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "売上原価",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.cost_of_sales,
      calcState: calcState.cost_of_sales_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "原価合計",
      titleRatio: "原価率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.cost_of_sales_total,
      totalField: `${url}_cost_of_sales_total`,
      ratioField: `${url}_cost_of_sales_ratio`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "売上総利益",
      titleRatio: "粗利率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.gross_margin,
      totalField: `${url}_gross_margin`,
      ratioField: `${url}_gross_margin_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "販売費及び一般管理費",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.personal_cost,
      calcState: calcState.personal_cost_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "人件費合計",
      titleRatio: "売上高人件費比率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.personal_cost_total,
      totalField: `${url}_personal_cost_total`,
      ratioField: `${url}_personal_cost_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.selling_and_administrative,
      calcState: calcState.selling_and_administrative_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "販管費合計",
      titleRatio: "売上高販管費率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.selling_and_administrative_total,
      totalField: `${url}_selling_and_administrative_total`,
      ratioField: `${url}_selling_and_administrative_ratio`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "営業利益",
      titleRatio: "売上高営業利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.operating_income,
      totalField: `${url}_operating_income`,
      ratioField: `${url}_operating_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "償却前営業利益",
      titleRatio: "売上高償却前営業利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.operating_profits_before_amortization,
      totalField: `${url}_operating_profits_before_amortization`,
      ratioField: `${url}_operating_profits_before_amortization_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "営業外収益",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.non_operating_income,
      calcState: calcState.non_operating_income_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "営業外収益合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.non_operating_income_total,
      totalField: `${url}_non_operating_income_total`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "営業外費用",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.non_operating_expenses,
      calcState: calcState.non_operating_expenses_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "営業外費用合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.non_operating_expenses_total,
      totalField: `${url}_non_operating_expenses_total`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "経常利益",
      titleRatio: "売上高経常利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.current_earnings,
      totalField: `${url}_current_earnings`,
      ratioField: `${url}_current_earnings_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "償却前経常利益",
      titleRatio: "売上高償却前経常利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.ebitda,
      totalField: `${url}_ebitda`,
      ratioField: `${url}_ebitda_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "特別利益",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.special_benefits,
      calcState: calcState.special_benefits_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "特別利益合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.special_benefits_total,
      totalField: `${url}_special_benefits_total`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBody({
      title: "特別損失",
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.special_losses,
      calcState: calcState.special_losses_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisTotal({
      titleTotal: "特別損失合計",
      titleRatio: null,
      headers: resultsAndPlansDataState.headers[dataUrl],
      bottomLine: true,
      calcState: calcState.special_losses_total,
      totalField: `${url}_special_losses_total`,
      isBgWhite: true,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "税引き前当期純利益",
      titleRatio: "売上高取引前当期純利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.ebit,
      totalField: `${url}_ebit`,
      ratioField: `${url}_ebit_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...PlBudgetControlBasisBodyWithTax({
      headers: resultsAndPlansDataState.headers[dataUrl],
      rows: resultsAndPlansDataState.special_corporate_inhabitant_and_enterprise_taxeslosses,
      calcState:
        calcState.special_corporate_inhabitant_and_enterprise_taxeslosses_total,
      url: url,
      unit: unit,
    }),
    ...PlBudgetControlBasisGrandTotal({
      titleTotal: "当期純利益",
      titleRatio: "売上高当期純利益率",
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcState: calcState.net_income,
      totalField: `${url}_net_income`,
      ratioField: `${url}_net_income_ratio`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BudgetControlBasisTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCost: false,
    }),
    ...PlBudgetControlBasisSimplePl({
      headers: resultsAndPlansDataState.headers[dataUrl],
      calcStateRows1: calcState.simple_cf_row1_total,
      calcStateRows2: calcState.simple_cf_row2_total,
      calcStateRows3: calcState.simple_cf_row3_total,
      field1: `${url}_simple_cf_row1`,
      field2: `${url}_simple_cf_row2`,
      field3: `${url}_simple_cf_row3`,
      descriptions: resultsAndPlansDataState.descriptions,
      unit: unit,
    }),
    ...BudgetControlBasisTableBlank({
      headers: resultsAndPlansDataState.headers[url],
      isCost: false,
    })
  );

  // セルの値が変わると、Firebaseも更新する
  const HandleCellChanged = async (
    changes: ReactDataSheet.CellRenderer<GridElement, number | string>
  ) => {
    HandleCellChange({
      changes,
      url,
      firestore,
      grid,
      unit,
    });
  };

  return (
    <ReactDataSheet
      data={grid}
      dataRenderer={(cell) => cell.expr}
      onCellsChanged={HandleCellChanged}
      sheetRenderer={(props: {
        className: string | undefined;
        children: React.ReactElement<
          DocumentData,
          string | React.JSXElementConstructor<DocumentData>
        >;
      }) => (
        <table className={`${props.className}`}>
          <BudgetControlBasisHeader
            headers={resultsAndPlansDataState.headers[dataUrl]}
          />
          <tbody className="text-14px">{props.children}</tbody>
        </table>
      )}
      valueRenderer={(cell) => cell.value}
    />
  );
};

export default memo(PlBudgetControlBasisTable);
