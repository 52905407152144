import { atom } from "recoil";
import { SignUpClientInfo, SignUpSpecialistInfo } from "../types";

export const signUpSpecialistStore = atom<SignUpSpecialistInfo>({
  key: "signUpSpecialistStoreData",
  default: {
    company_name: "",
    company_kana_name: "",
    type_of_business: 0,
    department_name: "",
    position: "",
    first_name: "",
    last_name: "",
    first_kana_name: "",
    last_kana_name: "",
    phone1: "",
    phone2: "",
    phone3: "",
    plan_type: null,
    card_kind: null,
    card_number: "",
    card_name: "",
    expire_month: 0,
    expire_year: 0,
    card_cvc_number: "",
    payment_count: 1,
    password: "",
    password_confirmation: "",
    token: "",
    has_card: false,
    coupon_code: "",
  },
});

// export const signUpSpecialistStore = atom<SignUpSpecialistInfo>({
//   key: "signUpSpecialistStoreData",
//   default: {
//     company_name: "TARO TEST",
//     company_kana_name: "カブシキガイシャテスト",
//     type_of_business: 5,
//     department_name: "営業部",
//     position: "部長",
//     first_name: "手洲斗",
//     last_name: "太朗",
//     first_kana_name: "テスト",
//     last_kana_name: "タロウ",
//     phone1: "080",
//     phone2: "1234",
//     phone3: "5678",
//     plan_type: 2,
//     card_kind: "VISA",
//     card_number: "4242424242424242",
//     card_name: "TARO TEST",
//     expire_month: 11,
//     expire_year: 25,
//     card_cvc_number: "635",
//     payment_count: 1,
//     password: "aaaaaaaa",
//     password_confirmation: "aaaaaaaa",
//     token: "",
//   },
// });

export const signUpClientStore = atom<SignUpClientInfo>({
  key: "signUpClientStoreData",
  default: {
    client_first_name: "",
    client_last_name: "",
    client_first_kana_name: "",
    client_last_kana_name: "",
    client_company: "",
    client_postal_code: "",
    client_province: "",
    client_address: "",
    client_address_detail: "",
    client_position: "",
    client_industry_step_1: "",
    client_industry_step_2: "",
    client_industry_step_3: "",
    client_industry_step_4: "",
    client_phone1: "",
    client_phone2: "",
    client_phone3: "",
    password: "",
    password_confirmation: "",
    token: "",
    email: "",
    is_email_send: "false",
  },
});
