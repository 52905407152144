import { DocumentData } from "firebase/firestore";
import { add } from "mathjs";

type Props = {
  headers: DocumentData[];
  field: string;
  states: DocumentData[];
};

export const calcByItemForProfitAndLossPlanMonthly = ({
  headers,
  states,
  field,
}: Props) => {
  const byItem: number[][] = [];
  let totalData: number[] = [];
  let monthlyData: number[] = [0];

  states.length !== 0 &&
    states.map((state, stateIndex) => {
      const itemData: number[] = [];

      headers.map((header: DocumentData) => {
        const term = header.period;

        if (!state[field] || !state[field][term]) {
          return itemData.push(0);
        }
        const data = state[field][term];
        itemData.push(data);
      });
      if (stateIndex === 0) {
        monthlyData = itemData;
      } else {
        monthlyData = add(monthlyData, itemData);
      }

      byItem.push(itemData);

      if (states.length === stateIndex + 1) {
        totalData = monthlyData;
      }
    });

  return { byItem, totalData };
};
