import React from "react";

interface Props {
  children: React.ReactNode;
}

const GuestLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="fixed w-full h-[calc(100vh-200px)] flex justify-center items-center flex-col">
      {children}
    </div>
  );
};

export default GuestLayout;
