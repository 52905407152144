import React from "react";
import { HashLink } from "react-router-hash-link";

interface Props {
  title: string;
  path: string;
  keyIndex: string;
}

const Accordion: React.FC<Props> = ({ title, path, keyIndex }) => {
  return (
    <li key={keyIndex}>
      <HashLink to={path}>{title}</HashLink>
    </li>
  );
};

export default Accordion;
