import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const StatementOfCashFlowsTitle = ({
  headers,
  title,
  isSecondColor,
}: {
  headers: DocumentData[];
  title: string;
  isSecondColor?: boolean;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pl-4px bg-white font-bold before:border-x before:border-r-c3333334d before:border-b before:border-b-c3333334d ${
    isSecondColor ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }  z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-b-c3333334d  before:border-l-c3333334d ${
    isSecondColor ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }  font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-x before:border-l-c3333334d before:border-b before:border-b-c3333334d  ${
    isSecondColor ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  }  h-50px`;

  const gridRow: GridElement[] = [
    {
      value: title,
      readOnly: true,
      className: `${classCol1} `,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?.map((_: any, index: number) => {
    index !== 0 &&
      gridRow.push({
        value: "",
        readOnly: true,
        className: `${classCol2} `,
      });
  });
  gridRow.push({
    value: "",
    readOnly: true,
    className: `${classCol3} `,
  });
  grid.push(gridRow);

  return grid;
};
