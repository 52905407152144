import { AxiosResponse } from "axios";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { DarkButton, LightButton } from "../../components/atoms";
import { GuestLayout } from "../../components/layout";
import { pageConstant } from "../../constants/pageConstants";

const PasswordResetting = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { status }: AxiosResponse = await axiosInstance({
        url: apiUrl.PASSWORD_RESETS,
        method: "POST",
        data: { email },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      navigate(`/${pageConstant.CHECK_EMAIL}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.message === "unactivated email") {
        toast.error("ユーザー登録が未完了です");
      } else {
        toast.error("メールアドレスが存在しません");
      }
    }
  };

  return (
    <GuestLayout>
      <div className="w-[450px]">
        <h1 className="text-30px font-bold text-center mb-25px">
          パスワード再設定
        </h1>
        <p className="py-25px text-14px text-center">
          ご利用中のメールアドレスを入力してください。
          <br />
          パスワード再設定のメールをお送りします。
        </p>
        <form
          className="bg-white px-8px pb-8px"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="mb-4px">
            <label className="block text-13px font-bold mb-8px" htmlFor="email">
              メールアドレス
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sample@cloudplanner.com"
              type="email"
            />
          </div>
          <div className="m-auto py-30px">
            <DarkButton>再設定メールを送信</DarkButton>
          </div>
        </form>
        <div className="m-auto px-8px pt-10px">
          <LightButton
            onClick={() => {
              navigate(`/${pageConstant.LOGIN}`);
            }}
          >
            ログイン画面に戻る
          </LightButton>
        </div>
      </div>
    </GuestLayout>
  );
};

export default memo(PasswordResetting);
