import { DocumentData } from "firebase/firestore";
import React from "react";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlResultsAndPlansGrandTotal = ({
  titleTotal,
  titleRatio,
  total,
  ratio,
  totalField,
  ratioField,
  descriptions,
  unit,
}: {
  headers: DocumentData[];
  titleTotal: string;
  titleRatio: string | null;
  total: number[];
  ratio?: number[];
  totalField: string;
  ratioField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b  before:bg-c80b0f433 whitespace-nowrap font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-c80b0f433 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-x-c3333334d before:border-b before:bg-c80b0f433`;

  const classCol4 = `${borderSettings}  before:border-l before:border-l-c3333334d before:border-b text-right font-bold bg-c80b0f433`;

  const classCol6 = `${borderSettings} before:border-x  bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          {titleTotal}
        </div>
      ),
      readOnly: true,
      className: `${classCol1}  ${titleRatio && "before:border-b-c3333334d"}`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];
  total.map((totalData: number) => {
    gridRow.push({
      value: isNaN(totalData) ? 0 : ShowTableData(totalData / unit),
      expr: isNaN(totalData) ? 0 : totalData / unit,
      readOnly: true,
      className: `${classCol4} before:border-b-c3333334d`,
    });
  });

  gridRow.push({
    value: descriptions[totalField],
    expr: descriptions[totalField],
    readOnly: false,
    className: `${classCol6} ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: (
        <div className={`absolute left-4px whitespace-nowrap top_absolute`}>
          ({titleRatio})
        </div>
      ),
      readOnly: true,
      className: `${classCol1} before:border-b`,
      colSpan: 2,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
  ];

  ratio &&
    ratio.map((ratioData: number) => {
      gridRow2.push({
        value: `${ratioData}%`,
        expr: ratioData,
        readOnly: true,
        className: classCol4,
      });
    });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol6}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
