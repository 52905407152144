import { DocumentData } from "firebase/firestore";
import { round, isNaN } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlCurrentAndPreviousYearComparisonsGrandTotal = ({
  titleTotal,
  titleRatio,
  bottomLine,
  previousTotal,
  currentTotal,
  totalSubtract,
  totalRatio,
  totalReference,
  previousRatio,
  currentRatio,
  referenceRatio,
  fixedValue,
  fixedRatio,
  totalField,
  ratioField,
  descriptions,
  unit,
}: {
  titleTotal: string;
  titleRatio: string | null;
  bottomLine?: boolean;
  previousTotal: number;
  currentTotal: number;
  totalSubtract: number;
  totalRatio: number;
  totalReference: number;
  previousRatio: number;
  currentRatio: number;
  referenceRatio: number;
  fixedValue: number;
  fixedRatio: number;
  totalField: string;
  ratioField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l before:border-b  before:bg-c80b0f433 whitespace-nowrap font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-c80b0f433 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-x-c3333334d before:border-b before:bg-c80b0f433`;

  const classCol4 = `${borderSettings}  before:border-l before:border-l-c3333334d before:border-b text-right font-bold bg-c80b0f433`;

  const classCol5 = `${borderSettings} before:border-x  bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol1} ${
        bottomLine && titleRatio === null
          ? "before:border-b"
          : titleRatio
          ? "before:border-b-c3333334d"
          : ""
      }`,
      key: "",
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];
  // 1行目前期
  gridRow.push({
    value: ShowTableData(previousTotal / unit),
    expr: previousTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 1行目今期
  gridRow.push({
    value: ShowTableData(currentTotal / unit),
    expr: currentTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 今年-前年
  gridRow.push({
    value: ShowTableData(totalSubtract / unit),
    expr: totalSubtract / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 今年/前年
  gridRow.push({
    value: `${String(totalRatio)}%`,
    expr: totalRatio,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 自動計算
  gridRow.push({
    value:
      isNaN(totalReference) ||
      totalReference === null ||
      !isFinite(totalReference)
        ? 0
        : ShowTableData(round(totalReference) / unit),
    expr:
      isNaN(totalReference) ||
      totalReference === null ||
      !isFinite(totalReference)
        ? 0
        : round(totalReference) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 修正後
  gridRow.push({
    value: isNaN(fixedValue) ? 0 : ShowTableData(round(fixedValue) / unit),
    expr: isNaN(fixedValue) ? 0 : round(fixedValue) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  gridRow.push({
    value: descriptions[totalField],
    readOnly: false,
    className: `${classCol5}  ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: `(${titleTotal})`,
      readOnly: true,
      className: `${classCol1} ${bottomLine && "before:border-b"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2} before:border-b text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
    {
      value: previousRatio !== null ? `${String(previousRatio)}%` : "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: currentRatio !== null ? `${String(currentRatio)}%` : "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
  ];

  // 自動計算
  gridRow2.push({
    value:
      referenceRatio != null && !isFinite(referenceRatio)
        ? "0%"
        : `${String(referenceRatio)}%`,
    expr:
      referenceRatio != null && !isFinite(referenceRatio)
        ? "0%"
        : `${String(referenceRatio)}%`,
    readOnly: true,
    className: `${classCol4} before:border-b`,
  });

  // 修正後
  gridRow2.push({
    value: isNaN(fixedRatio) ? "0%" : `${fixedRatio}%`,
    expr: isNaN(fixedRatio) ? "0%" : `${fixedRatio}%`,
    readOnly: true,
    className: `${classCol4} before:border-b`,
  });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol5}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
