import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const CostCurrentAndPreviousYearComparisonsTotal = ({
  titleTotal,
  previousTotal,
  currentTotal,
  totalSubtract,
  totalRatio,
  totalReference,
  totalFixedValue,
  bottomLine,
  totalField,
  descriptions,
  unit,
}: {
  titleTotal: string;
  bottomLine?: boolean;
  previousTotal: number;
  currentTotal: number;
  totalSubtract: number;
  totalRatio: number;
  totalReference: number;
  totalFixedValue: number;
  totalField: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-l ${
    bottomLine && "before:border-b"
  }  before:bg-c80b0f433 whitespace-nowrap font-bold z-[32]`;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-b before:bg-c80b0f433 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white before:border-x before:border-x-c3333334d before:border-b before:bg-c80b0f433`;

  const classCol4 = `${borderSettings}  before:border-l before:border-l-c3333334d before:border-b text-right font-bold bg-c80b0f433`;

  const classCol5 = `${borderSettings} before:border-x  bg-c80b0f433 text-left h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol1}`,
      key: "",
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}  `,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  `,
    },
  ];
  // 1行目前期
  gridRow.push({
    value: ShowTableData(previousTotal / unit),
    expr: previousTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  // 1行目今期
  gridRow.push({
    value: ShowTableData(currentTotal / unit),
    expr: currentTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  // 今年-前年
  gridRow.push({
    value: ShowTableData(totalSubtract / unit),
    expr: totalSubtract / unit,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  // 今年/前年
  gridRow.push({
    value:
      isNaN(totalRatio) || !isFinite(totalRatio)
        ? ""
        : `${String(totalRatio)}%`,
    expr:
      isNaN(totalRatio) || !isFinite(totalRatio)
        ? ""
        : `${String(totalRatio)}%`,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  // 自動計算
  gridRow.push({
    value:
      isNaN(totalReference) || !isFinite(totalReference)
        ? 0
        : ShowTableData(round(totalReference) / unit),
    expr:
      isNaN(totalReference) || !isFinite(totalReference)
        ? 0
        : round(totalReference) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  // 修正後
  gridRow.push({
    value:
      isNaN(totalFixedValue) || !isFinite(totalFixedValue)
        ? 0
        : ShowTableData(round(totalFixedValue) / unit),
    expr:
      isNaN(totalFixedValue) || !isFinite(totalFixedValue)
        ? 0
        : round(totalFixedValue) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b `,
  });
  gridRow.push({
    value: descriptions[totalField],
    readOnly: false,
    className: `${classCol5}  `,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
