export let firebaseConfig = {
  apiKey: "AIzaSyB3lrjVzaSlOpexZ6-U0Xifwga3RLgrZQQ",
  authDomain: "cloudplanner-production.firebaseapp.com",
  projectId: "cloudplanner-production",
  storageBucket: "cloudplanner-production.appspot.com",
  messagingSenderId: "24338750486",
  appId: "1:24338750486:web:54cc9b0f7f48d54495e937",
};
if (process.env.REACT_APP_ENV !== "production") {
  firebaseConfig = {
    apiKey: "AIzaSyDZC3rznWUUSqnyHnAM9AaPfsjj2UhrS_4",
    authDomain: "cloudplanner-staging-52f02.firebaseapp.com",
    projectId: "cloudplanner-staging-52f02",
    storageBucket: "cloudplanner-staging-52f02.appspot.com",
    messagingSenderId: "505878954550",
    appId: "1:505878954550:web:8092f5ef812cd080978e97",
  };
}
