import React from "react";
import "../../css/react_datasheet.css";
import { BudgetControlBasisContainer } from "../../components/budgetControl/container";
import { SubHeader } from "../../components/header";
import { UnitChange } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";

const BudgetControlBasis = () => {
  const url = pageConstant.BUDGET_CONTROL_BASIS;
  const dataUrl = pageConstant.RESULTS_OF_THE_CURRENT_TERM;

  return (
    <React.Fragment>
      <SubHeader title="予実管理表（単月・累計）" />
      <UnitChange />
      <BudgetControlBasisContainer dataUrl={dataUrl} url={url} />
    </React.Fragment>
  );
};

export default BudgetControlBasis;
