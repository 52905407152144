import { DocumentData } from "firebase/firestore";
import { add, dotDivide, subtract, sum, dotMultiply, divide } from "mathjs";
import { useQueryClient } from "react-query";
import { useSubjectItemsInitialData } from "./useSubjectItemsInitialData";
import { pageConstant } from "../../constants/pageConstants";
import { Logout } from "../../lib/Logout";
import {
  netSalesCalcByItemForPlansInputMonthly,
  ratioCalcForPlansInputMonthly,
  totalCalc,
  totalCalcByItem,
  totalCalcForBudgetControlBasis,
  totalRatioCalcForPlansInputMonthly,
} from "../../utils/calc";
import { calcByItemForPlansInputMonthly } from "../../utils/calc/calcByItemForPlansInputMonthly";
import {
  cashFlowTableDataCalc,
  salesReceiptAndPaymentOnPurchaseCalc,
} from "../../utils/calc/cashFlowTable";
import { totalCalcByItemForPlansInputMonthly } from "../../utils/calc/totalCalcByItemForPlansInputMonthly";

export const useCashFlowTableInitialData = () => {
  const queryClient = useQueryClient();

  const {
    headers,
    net_sales,
    cost_of_sales,
    personal_cost,
    non_operating_income,
    non_operating_expenses,
    special_income,
    other_special_expenses,
    tax_payment,
    financial_arrangements,
    principal_payments,
    cost_of_sales_except_beginning_and_end_data,
    depreciation_cost_single_data,
  } = useSubjectItemsInitialData();

  const resultsField = pageConstant.PLANS_INPUT_MONTHLY;
  const plansField = pageConstant.PLANS_INPUT;
  const previousField = pageConstant.RESULTS_OF_THE_PREVIOUS_PERIOD;

  // 単位
  const unit = headers.unit;
  const tax = headers.tax;
  const salesReceipt = headers.sales_receipt;
  const paymentOnPurchase = headers.payment_on_purchase;
  const resultsCount = headers.results_of_the_current_term_count;

  // ゼロの配列
  const zeroArray: number[] = [];
  headers[previousField].map(() => {
    zeroArray.push(0);
  });

  try {
    // 売上高合計
    const netSalesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: net_sales,
    }) as number[];

    const netSalesTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly({
      headers: headers[plansField],
      field: plansField,
      state: net_sales,
    }) as number[];
    const netSalesTotalByItemRemining = subtract(
      netSalesTotalByItemPlansInput,
      netSalesTotalByItemCurrentTerm
    ) as number[];
    // 月別売上比率
    const netSalesTotalByItemPreviousRatio = ratioCalcForPlansInputMonthly({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
    });
    const netSalesPreviousTotal: number[] = totalCalc({
      headers: headers[previousField],
      field: previousField,
      state: net_sales,
    });

    // 売上高合計構成比率
    const netSalesTotalPreviousRatio: number[] =
      totalRatioCalcForPlansInputMonthly(netSalesPreviousTotal);
    const netSalesTotalByItemPlansInputMonthly =
      netSalesCalcByItemForPlansInputMonthly({
        total: netSalesTotalByItemRemining,
        ratio: netSalesTotalByItemPreviousRatio.ratioData,
      });

    const netSalesForBudgetControl = totalCalcForBudgetControlBasis({
      headers: headers,
      resultsField: resultsField,
      previousField: previousField,
      plansState: netSalesTotalByItemPlansInputMonthly.byItem,
      plansTotal: netSalesTotalByItemPlansInput,
      plansMonthlyTotal: netSalesTotalByItemPlansInputMonthly.monthlyTotal,
      plansByItemCumsum: netSalesTotalByItemPlansInputMonthly.byItemCumsum,
      plansMonthlyTotalCumsum:
        netSalesTotalByItemPlansInputMonthly.monthlyTotalCumsum,
      states: net_sales,
    });

    // 売上高合計
    const netSalesResultsOfTheCurrentTermTotal: number[] = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: net_sales,
    });

    // 売上入金 売上高
    const salesReceiptData: number[] = [
      netSalesForBudgetControl.previousMonthlyTotal &&
        netSalesForBudgetControl.previousMonthlyTotal[
          netSalesForBudgetControl.previousMonthlyTotal.length - 2
        ],
      netSalesForBudgetControl.previousMonthlyTotal &&
        netSalesForBudgetControl.previousMonthlyTotal[
          netSalesForBudgetControl.previousMonthlyTotal.length - 1
        ],
    ];

    netSalesResultsOfTheCurrentTermTotal.map((total, dataIndex) => {
      // 合計金額の計算
      const data =
        resultsCount.length > dataIndex + 1
          ? total
          : netSalesTotalByItemPlansInputMonthly.monthlyTotal !== null &&
            !isNaN(total)
          ? netSalesTotalByItemPlansInputMonthly.monthlyTotal[dataIndex]
          : 0;
      salesReceiptData.push(data);
    });

    // 売上入金（当月、翌月、翌々月）
    const salesReceiptByItem = salesReceiptAndPaymentOnPurchaseCalc({
      value: salesReceiptData,
      rate: salesReceipt,
      unit: unit,
      tax: tax,
    });

    const netSalesTotal = {
      sales_receipt_data: salesReceiptData,
      sales_receipt_data_total: sum(salesReceiptData) as number,
      this_month: salesReceiptByItem.thisMonth,
      next_month: salesReceiptByItem.nextMonth,
      two_month_later: salesReceiptByItem.twoMonthLater,
      this_month_total: salesReceiptByItem.thisMonthTotal,
      next_month_total: salesReceiptByItem.nextMonthTotal,
      two_month_later_total: salesReceiptByItem.twoMonthLaterTotal,
      this_month_with_tax: salesReceiptByItem.thisMonthWithTax,
      next_month_with_tax: salesReceiptByItem.nextMonthWithTax,
      two_month_later_with_tax: salesReceiptByItem.twoMonthLaterWithTax,
      this_month_total_with_tax: salesReceiptByItem.thisMonthTotalWithTax,
      next_month_total_with_tax: salesReceiptByItem.nextMonthTotalWithTax,
      two_month_later_total_with_tax: salesReceiptByItem.twoMonthLaterTotal,
      monthly_total: salesReceiptByItem.monthlyTotal,
      monthly_total_total: salesReceiptByItem.monthlyTotalTotal,
    };

    // 売上原価合計
    const costOfSalesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales,
    });
    costOfSalesTotalByItemCurrentTerm[0] = 0;
    costOfSalesTotalByItemCurrentTerm[
      costOfSalesTotalByItemCurrentTerm.length - 1
    ] = 0;

    // 計画（年次）の当期商品仕入れ高
    const netSalesCostRatioFunc = () => {
      const costRatioArray: number[][] = [];
      net_sales.map((netSale) => {
        const costRatioSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input_cost_ratio &&
            netSale.plans_input_cost_ratio[header.period] &&
            netSale.plans_input_cost_ratio[header.period] !== null
              ? netSale.plans_input_cost_ratio[header.period]
              : 0;

          costRatioSingleArray.push(data);
        });

        costRatioArray.push(costRatioSingleArray);
      });
      return costRatioArray;
    };
    const netSalesCostRatio = netSalesCostRatioFunc();

    const NetSalesSinglesFunc = () => {
      const netSalesArray: number[][] = [];
      net_sales.map((netSale) => {
        const netSalesSingleArray: number[] = [];

        headers[plansField].map((header: DocumentData) => {
          const data =
            netSale.plans_input &&
            netSale.plans_input[header.period] &&
            netSale.plans_input[header.period] !== null
              ? netSale.plans_input[header.period]
              : 0;
          netSalesSingleArray.push(data);
        });

        netSalesArray.push(netSalesSingleArray);
      });
      return netSalesArray;
    };
    const netSalesSingles = NetSalesSinglesFunc();

    const netSalesMultipleCost =
      netSalesCostRatio[0][0] !== null
        ? (divide(
            dotMultiply(netSalesCostRatio, netSalesSingles),
            100
          ) as number[][])
        : null;

    const costOfPurchasedGoodsFunc = () => {
      let array: number[] = [];
      netSalesMultipleCost &&
        netSalesMultipleCost !== null &&
        netSalesMultipleCost.map((data, index) => {
          index === 0 ? (array = data) : (array = add(data, array));
        });
      return array;
    };

    const costOfSalesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: cost_of_sales,
      });
    costOfSalesTotalByItemPlansInput[0] = 0;
    costOfSalesTotalByItemPlansInput[1] = costOfPurchasedGoodsFunc()[0]
      ? costOfPurchasedGoodsFunc()[0]
      : 0;
    costOfSalesTotalByItemPlansInput[
      costOfSalesTotalByItemPlansInput.length - 1
    ] = 0;

    const costOfSalesTotalByItemRemining = subtract(
      costOfSalesTotalByItemPlansInput,
      costOfSalesTotalByItemCurrentTerm
    );

    // 見込み
    const costOfSalesByItem = dotMultiply(
      sum(costOfSalesTotalByItemRemining),
      dotDivide(
        netSalesTotalByItemPlansInputMonthly.monthlyTotal,
        sum(netSalesTotalByItemRemining)
      )
    ) as number[];

    // 売上原価合計（期首期末以外）
    cost_of_sales_except_beginning_and_end_data.sort(function (prev, next) {
      return prev.order - next.order;
    });

    const paymentOnPurchasePreviousTotal = totalCalc({
      headers: headers[previousField],
      field: previousField,
      state: cost_of_sales_except_beginning_and_end_data,
    });
    const paymentOnPurchaseCurrentTotal = totalCalc({
      headers: headers[resultsField],
      field: resultsField,
      state: cost_of_sales_except_beginning_and_end_data,
    });

    // 仕入
    const paymentOnPurchaseDataList: number[] = [
      paymentOnPurchasePreviousTotal &&
        paymentOnPurchasePreviousTotal[
          paymentOnPurchasePreviousTotal.length - 2
        ],
      paymentOnPurchasePreviousTotal &&
        paymentOnPurchasePreviousTotal[
          paymentOnPurchasePreviousTotal.length - 1
        ],
    ];

    paymentOnPurchaseCurrentTotal.map((total, dataIndex) => {
      // 合計金額の計算
      const data =
        resultsCount.length > dataIndex + 1
          ? total
          : costOfSalesByItem !== null && !isNaN(total)
          ? costOfSalesByItem[dataIndex]
          : 0;
      paymentOnPurchaseDataList.push(data);
    });

    // 仕入れ支払い（当月、翌月、翌々月）
    const paymentOnPurchaseByItem = salesReceiptAndPaymentOnPurchaseCalc({
      value: paymentOnPurchaseDataList,
      rate: paymentOnPurchase,
      unit: unit,
      tax: tax,
    });

    const costOfSalesTotal = {
      payment_on_purchase_previous_total: paymentOnPurchasePreviousTotal,
      payment_on_purchase_current_total: paymentOnPurchaseCurrentTotal,
      payment_on_purchase_data_list: paymentOnPurchaseDataList,
      payment_on_purchase_data_list_total: sum(
        paymentOnPurchaseDataList
      ) as number,
      this_month: paymentOnPurchaseByItem.thisMonth,
      next_month: paymentOnPurchaseByItem.nextMonth,
      two_month_later: paymentOnPurchaseByItem.twoMonthLater,
      this_month_total: paymentOnPurchaseByItem.thisMonthTotal,
      next_month_total: paymentOnPurchaseByItem.nextMonthTotal,
      two_month_later_total: paymentOnPurchaseByItem.twoMonthLaterTotal,
      this_month_with_tax: paymentOnPurchaseByItem.thisMonthWithTax,
      next_month_with_tax: paymentOnPurchaseByItem.nextMonthWithTax,
      two_month_later_with_tax: paymentOnPurchaseByItem.twoMonthLaterWithTax,
      this_month_total_with_tax: paymentOnPurchaseByItem.thisMonthTotalWithTax,
      next_month_total_with_tax: paymentOnPurchaseByItem.nextMonthTotalWithTax,
      two_month_later_total_with_tax:
        paymentOnPurchaseByItem.twoMonthLaterTotal,
      monthly_total: paymentOnPurchaseByItem.monthlyTotal,
      monthly_total_total: paymentOnPurchaseByItem.monthlyTotalTotal,
    };

    //  営業外収益
    const nonOperatingIncomeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_income,
    });
    const nonOperatingIncomeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: non_operating_income,
      });
    const nonOperatingIncomeTotalByItemRemining = subtract(
      nonOperatingIncomeTotalByItemPlansInput,
      nonOperatingIncomeTotalByItemCurrentTerm
    );
    const nonOperatingIncomeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: non_operating_income,
        ratio: netSalesTotalPreviousRatio,
        remins: nonOperatingIncomeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    // 営業外収入
    const nonOperatingIncomeTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: non_operating_income,
      field: resultsField,
      prospectData: nonOperatingIncomeTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        nonOperatingIncomeTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 経常収入計
    const ordinaryProfitData = add(
      netSalesTotal.monthly_total,
      nonOperatingIncomeTotal.monthlyTotal
    ) as number[];
    const ordinaryProfitTotal = {
      monthly_total: ordinaryProfitData,
      monthly_total_total: sum(ordinaryProfitData) as number,
    };

    // 人件費合計
    const personalCostTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: personal_cost,
    });
    const personalCostTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: personal_cost,
      });
    const personalCostTotalByItemRemining = subtract(
      personalCostTotalByItemPlansInput,
      personalCostTotalByItemCurrentTerm
    );
    const personalCostTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: personal_cost,
        ratio: netSalesTotalPreviousRatio,
        remins: personalCostTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const personalCostTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: personal_cost,
      field: resultsField,
      prospectData: personalCostTotalByItemPlansInputMonthly.byItem,
      prospectTotalData: personalCostTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: 1,
    });

    // 販売費及び一般管理費合計（減価償却費除く）
    depreciation_cost_single_data.sort(function (prev, next) {
      return prev.order - next.order;
    });

    const sellingAndAdministrativeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: depreciation_cost_single_data,
    });
    const sellingAndAdministrativeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: depreciation_cost_single_data,
      });
    const sellingAndAdministrativeTotalByItemRemining = subtract(
      sellingAndAdministrativeTotalByItemPlansInput,
      sellingAndAdministrativeTotalByItemCurrentTerm
    );
    const sellingAndAdministrativeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: depreciation_cost_single_data,
        ratio: netSalesTotalPreviousRatio,
        remins: sellingAndAdministrativeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const sellingAndAdministrativeTotalData = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: depreciation_cost_single_data,
      field: resultsField,
      prospectData: sellingAndAdministrativeTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        sellingAndAdministrativeTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    const sellingAndAdministrativeMonthlyTotal = add(
      personalCostTotal.monthlyTotal,
      sellingAndAdministrativeTotalData.monthlyTotal
    ) as number[];

    const sellingAndAdministrativeTotal = {
      by_item: [personalCostTotal.monthlyTotal].concat(
        sellingAndAdministrativeTotalData.byItem as number[][]
      ),
      by_item_total: [personalCostTotal.monthlyTotalTotal].concat(
        sellingAndAdministrativeTotalData.byItemTotal as number[]
      ),
      monthly_total: sellingAndAdministrativeMonthlyTotal,
      monthly_total_total: sum(sellingAndAdministrativeMonthlyTotal) as number,
    };

    //  営業外費用
    const nonOperatingExpensesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: non_operating_expenses,
    });

    const nonOperatingExpensesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: non_operating_expenses,
      });
    const nonOperatingExpensesTotalByItemRemining = subtract(
      nonOperatingExpensesTotalByItemPlansInput,
      nonOperatingExpensesTotalByItemCurrentTerm
    );
    const nonOperatingExpensesTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: non_operating_expenses,
        ratio: netSalesTotalPreviousRatio,
        remins: nonOperatingExpensesTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    // 営業外収入
    const nonOperatingExpensesTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: non_operating_expenses,
      field: resultsField,
      prospectData: nonOperatingExpensesTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        nonOperatingExpensesTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 経常支出計
    const ordinaryExpensesData = add(
      costOfSalesTotal.monthly_total,
      add(
        sellingAndAdministrativeTotal.monthly_total,
        nonOperatingExpensesTotal.monthlyTotal
      )
    ) as number[];
    const ordinaryExpensesTotal = {
      monthly_total: ordinaryExpensesData,
      monthly_total_total: sum(ordinaryExpensesData) as number,
    };

    // 経常収支
    const currentBalance = {
      monthly_total: subtract(
        ordinaryProfitData,
        ordinaryExpensesData
      ) as number[],
      monthly_total_total: subtract(
        ordinaryProfitTotal.monthly_total_total,
        ordinaryExpensesTotal.monthly_total_total
      ) as number,
    };

    //  特別収益合計
    const specialIncomeTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: special_income,
    });

    const specialIncomeTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: special_income,
      });

    const specialIncomeTotalByItemRemining = subtract(
      specialIncomeTotalByItemPlansInput,
      specialIncomeTotalByItemCurrentTerm
    );
    const specialIncomeTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: special_income,
        ratio: netSalesTotalPreviousRatio,
        remins: specialIncomeTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const specialIncomeTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: special_income,
      field: resultsField,
      prospectData: specialIncomeTotalByItemPlansInputMonthly.byItem,
      prospectTotalData: specialIncomeTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 特別費用他合計
    const otherSpecialExpensesTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: other_special_expenses,
    });
    const otherSpecialExpensesTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: other_special_expenses,
      });
    const otherSpecialExpensesTotalByItemRemining = subtract(
      otherSpecialExpensesTotalByItemPlansInput,
      otherSpecialExpensesTotalByItemCurrentTerm
    );
    const otherSpecialExpensesTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: other_special_expenses,
        ratio: netSalesTotalPreviousRatio,
        remins: otherSpecialExpensesTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const otherSpecialExpensesTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: other_special_expenses,
      field: resultsField,
      prospectData: otherSpecialExpensesTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        otherSpecialExpensesTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 納税合計
    const taxPaymentTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: tax_payment,
    });
    const taxPaymentTotalByItemPlansInput = totalCalcByItemForPlansInputMonthly(
      {
        headers: headers[plansField],
        field: plansField,
        state: tax_payment,
      }
    );
    const taxPaymentTotalByItemRemining = subtract(
      taxPaymentTotalByItemPlansInput,
      taxPaymentTotalByItemCurrentTerm
    );
    const taxPaymentTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: tax_payment,
        ratio: netSalesTotalPreviousRatio,
        remins: taxPaymentTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const taxPaymentTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: tax_payment,
      field: resultsField,
      prospectData: taxPaymentTotalByItemPlansInputMonthly.byItem,
      prospectTotalData: taxPaymentTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 当月収支過不足（当月収支計、前月繰越金不含）
    const underpaymentForTheMonthData =
      specialIncomeTotal.monthlyTotal.length !== 0 &&
      otherSpecialExpensesTotal.monthlyTotal.length !== 0 &&
      taxPaymentTotal.monthlyTotal.length !== 0
        ? (subtract(
            subtract(
              add(
                ordinaryExpensesTotal.monthly_total,
                specialIncomeTotal.monthlyTotal
              ),
              otherSpecialExpensesTotal.monthlyTotal
            ),
            taxPaymentTotal.monthlyTotal
          ) as number[])
        : ordinaryExpensesTotal.monthly_total;
    const underpaymentForTheMonthTotal = {
      monthly_total: underpaymentForTheMonthData,
      monthly_total_total: sum(underpaymentForTheMonthData) as number,
    };

    // 資金調達合計
    const financialArrangementsTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: financial_arrangements,
    });
    const financialArrangementsTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: financial_arrangements,
      });
    const financialArrangementsTotalByItemRemining = subtract(
      financialArrangementsTotalByItemPlansInput,
      financialArrangementsTotalByItemCurrentTerm
    );
    const financialArrangementsTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: financial_arrangements,
        ratio: netSalesTotalPreviousRatio,
        remins: financialArrangementsTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const financialArrangementsTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: financial_arrangements,
      field: resultsField,
      prospectData: financialArrangementsTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        financialArrangementsTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 元金返済合計
    const principalPaymentsTotalByItemCurrentTerm = totalCalcByItem({
      headers: headers[resultsField],
      field: resultsField,
      state: principal_payments,
    });
    const principalPaymentsTotalByItemPlansInput =
      totalCalcByItemForPlansInputMonthly({
        headers: headers[plansField],
        field: plansField,
        state: principal_payments,
      });
    const principalPaymentsTotalByItemRemining = subtract(
      principalPaymentsTotalByItemPlansInput,
      principalPaymentsTotalByItemCurrentTerm
    );
    const principalPaymentsTotalByItemPlansInputMonthly =
      calcByItemForPlansInputMonthly({
        states: principal_payments,
        ratio: netSalesTotalPreviousRatio,
        remins: principalPaymentsTotalByItemRemining,
        counts: headers.results_of_the_current_term_count,
      });

    const principalPaymentsTotal = cashFlowTableDataCalc({
      headers: headers[resultsField],
      states: principal_payments,
      field: resultsField,
      prospectData: principalPaymentsTotalByItemPlansInputMonthly.byItem,
      prospectTotalData:
        principalPaymentsTotalByItemPlansInputMonthly.monthlyTotal,
      count: resultsCount,
      unit: unit,
      tax: tax,
    });

    // 当月収支過不足（前月繰越金不含）
    const underpaymentForTheMonthWithoutExMonthlyJourneysData =
      financialArrangementsTotal.monthlyTotal.length === 0 ||
      principalPaymentsTotal.monthlyTotal.length === 0
        ? underpaymentForTheMonthTotal.monthly_total
        : (subtract(
            add(
              underpaymentForTheMonthTotal.monthly_total,
              financialArrangementsTotal.monthlyTotal
            ),
            principalPaymentsTotal.monthlyTotal
          ) as number[]);
    const underpaymentForTheMonthWithoutExMonthlyJourneysTotal = {
      monthly_total: underpaymentForTheMonthWithoutExMonthlyJourneysData,
      monthly_total_total: sum(
        underpaymentForTheMonthWithoutExMonthlyJourneysData
      ) as number,
    };

    // 翌月繰越（当月収支過不足＋前月繰越）
    const underpaymentForTheNextMonthData =
      financialArrangementsTotal.monthlyTotal.length !== 0
        ? (add(
            underpaymentForTheMonthTotal.monthly_total,
            financialArrangementsTotal.monthlyTotal
          ) as number[])
        : underpaymentForTheMonthTotal.monthly_total;
    const underpaymentForTheNextMonthTotal = {
      monthly_total: underpaymentForTheNextMonthData,
      monthly_total_total: sum(underpaymentForTheNextMonthData) as number,
    };

    // 前月繰越金
    const underpaymentForTheBeforeMonthData = underpaymentForTheNextMonthData;
    underpaymentForTheBeforeMonthData.unshift(0);

    underpaymentForTheBeforeMonthData.splice(-1, 1);

    const underpaymentForTheBeforeMonthTotal = {
      monthly_total: underpaymentForTheBeforeMonthData,
      monthly_total_total: sum(underpaymentForTheBeforeMonthData) as number,
    };

    // 翌月繰越（当月収支過不足＋前月繰越）
    const underpaymentForTheBeforeMonthGrandData = add(
      underpaymentForTheNextMonthTotal.monthly_total,
      underpaymentForTheBeforeMonthTotal.monthly_total
    ) as number[];
    const underpaymentForTheBeforeMonthGrandTotal = {
      monthly_total: underpaymentForTheBeforeMonthGrandData,
      monthly_total_total: sum(
        underpaymentForTheBeforeMonthGrandData
      ) as number,
    };

    // 差引収支過不足（当月収支計＋前月繰越金）
    const insufficientExpendituresData = add(
      underpaymentForTheNextMonthTotal.monthly_total,
      underpaymentForTheMonthTotal.monthly_total
    ) as number[];
    const insufficientExpendituresTotal = {
      monthly_total: insufficientExpendituresData,
      monthly_total_total: sum(insufficientExpendituresData) as number,
    };

    const cashFlowTableState = {
      net_sales_total: netSalesTotal,
      cost_of_sales_total: costOfSalesTotal,
      non_operating_income_total: nonOperatingIncomeTotal,
      ordinary_profit_total: ordinaryProfitTotal,
      selling_and_administrative_total: sellingAndAdministrativeTotal,
      non_operating_expenses_total: nonOperatingExpensesTotal,
      ordinary_expenses_total: ordinaryExpensesTotal,
      current_balance: currentBalance,
      special_income_total: specialIncomeTotal,
      other_special_expenses_total: otherSpecialExpensesTotal,
      tax_payment_total: taxPaymentTotal,
      financial_arrangements_total: financialArrangementsTotal,
      principal_payments_total: principalPaymentsTotal,
      underpayment_for_the_month_total: underpaymentForTheMonthTotal,
      underpayment_for_the_month_without_ex_monthly_journeys_total:
        underpaymentForTheMonthWithoutExMonthlyJourneysTotal,
      underpayment_for_the_next_month_total: underpaymentForTheNextMonthTotal,
      underpayment_for_the_before_month_total:
        underpaymentForTheBeforeMonthTotal,
      underpayment_for_the_before_month_grand_total:
        underpaymentForTheBeforeMonthGrandTotal,
      insufficient_expenditures_total: insufficientExpendituresTotal,
    };

    return cashFlowTableState;
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
