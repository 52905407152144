import { DocumentData } from "firebase/firestore";
import { divide, sum } from "mathjs";

type Props = {
  headers: DocumentData[];
  field: string;
  state: DocumentData[];
};

export const ratioCalcForPlansInputMonthly = ({
  headers,
  state,
  field,
}: Props) => {
  const totalData: number[] = [];
  const ratioData: number[][] = [];
  const totalRatioData: number[] = [];

  state.map((byItem) => {
    const item: number[] = [];

    headers.map((header: DocumentData) => {
      if (header.period === "total") {
        return;
      }

      const term = header.period;
      const data =
        byItem[field][term] && byItem[field][term] !== null
          ? byItem[field][term]
          : 0;

      item.push(data);
    });

    const total = item.length !== 0 ? sum(item) : 0;

    totalData.push(total);
  });

  state.map((byItem, stateIndex) => {
    const item: number[] = [];

    headers.map((header: DocumentData) => {
      if (header.period === "total") {
        return;
      }

      const term = header.period;
      const data =
        byItem &&
        byItem[field] &&
        byItem[field][term] &&
        byItem[field][term] !== null
          ? (byItem[field][term] as number)
          : 0;

      const totalDataByTerm =
        totalData && totalData.length !== 0 ? totalData[stateIndex] : 1;

      item.push(divide(data, totalDataByTerm));
    });

    ratioData.push(item);
    totalRatioData.push(sum(item));
  });

  return { ratioData, totalRatioData };
};
