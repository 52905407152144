import { DocumentData } from "firebase/firestore";
import React from "react";
import { bottomHeaderCss, topHeaderCss } from "../../../utils";

interface Props {
  headers: DocumentData[];
}

const ProfitAndLossPlanHeader: React.FC<Props> = ({ headers }) => {
  return (
    <thead
      className="sticky top-0 px-6px py-3px bg-white z-40
      pt-120px"
    >
      <tr>
        <th
          className={`sticky w-30px min-w-[1.875rem] left-0 align-bottom text-left font-bold text-18px z-40 ${topHeaderCss} 
            before:border-r  before:bg-white before:-z-10`}
          colSpan={3}
        ></th>
        {headers?.map((header: DocumentData, index) => (
          <th
            className={`min-w-[7.5rem] w-120px  before:border-t before:border-l before:border-l-c3333334d bg-c2a4b770d  ${topHeaderCss}`}
            key={index}
          >
            {header.nengou}
          </th>
        ))}
        <th
          className={`relative px-6px py-3px top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full min-w-[37.5rem] w-600px font-bold text-center before:border-x before:border-t before:border-b-2 before:border-l-c3333334d `}
          rowSpan={2}
        >
          計画の根拠など
        </th>
      </tr>
      <tr>
        <th
          className={`sticky min-w-[5rem] w-80px left-0  before:-z-10 bg-white ${bottomHeaderCss} z-40`}
        ></th>
        <th
          className={`sticky min-w-[5rem] w-80px left-80px   before:-z-10 bg-white  ${bottomHeaderCss} z-40`}
        ></th>
        <th
          className={`sticky min-w-[8.75rem] w-140px left-160px before:border-r   before:-z-10 bg-white  ${bottomHeaderCss} z-40`}
        ></th>

        {headers?.map((header: DocumentData, index) => (
          <th
            className={`min-w-[8.75rem] w-140px  before:border-l before:border-l-c3333334d before:border-b-2 bg-c2a4b770d ${topHeaderCss}`}
            key={index}
          >
            {header.period.substring(1, 5)}年 {header.period.substring(5, 7)}
            月期
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ProfitAndLossPlanHeader;
