import * as React from "react";
import { SubHeader } from "../../components/header";
import { Spinner, UnitChange } from "../../components/molecules";
import { PlansInputEstimatedInThisTermContainer } from "../../components/planMaking/container";
import { pageConstant } from "../../constants/pageConstants";
import { usePlansInputEstimatedInThisTermInitialData } from "../../hooks/initialData";

export const PlansInputEstimatedInThisTerm = () => {
  const resultsOfTheCurrentTermCalcState =
    usePlansInputEstimatedInThisTermInitialData();

  const url = pageConstant.PLANS_INPUT_ESTIMATED_IN_THIS_TERM;

  if (!resultsOfTheCurrentTermCalcState) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <SubHeader title="今期見込み（月次）" />
      <UnitChange />
      <PlansInputEstimatedInThisTermContainer
        calcState={resultsOfTheCurrentTermCalcState}
        url={url}
      />
    </React.Fragment>
  );
};
