import { doc, DocumentData, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useFirestore } from "reactfire";
import { getStoredClientUid } from "../../../sessionStorage/sessionStorage";

export const BusinessPlanNote = ({
  descriptions,
}: {
  descriptions: DocumentData;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const firestore = useFirestore();
  const uid = getStoredClientUid();

  const [note, setNote] = useState(descriptions.business_plan_note || "");

  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    const saveNote = async () => {
      if (note !== descriptions.business_plan_note) {
        const dataRef = doc(
          firestore,
          "accounts",
          uid,
          "tables",
          String(descriptions.NO_ID_FIELD)
        );
        await updateDoc(dataRef, {
          business_plan_note: note,
        }).catch((error) => {
          throw new Error(error.message);
        });
      }
    };

    saveNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    setNote(descriptions.business_plan_note || "");
    inputRef.current?.focus();
  }, [descriptions.business_plan_note]);

  return (
    <div className="fixed max-w-[1360px] w-full mt-20px ">
      <h3 className="text-14px">メモ</h3>
      <div className="h-160px">
        <textarea
          className="w-full h-full text-14px leading-5"
          onChange={handleChange}
          value={descriptions.business_plan_note}
        />
      </div>
      <div className="w-full min-w-full table_pt text-11px">
        <p>
          注意１ 経常利益の計算には、営業外収益を含めない。営業外費用は含める
        </p>
        <p>
          注意２
          付加価値額の伸び率は、計画終了時に基準年度と比較して年率3％上昇していること
        </p>
        <p>注意３ 設備投資額は本文と一致しているか再確認</p>
        <p>注意４ 付加価値額や給与所得額の算出根拠をその3に記述すること</p>
        <p>
          注意５
          会社全体の事業計画（表）における「付加価値額」や「給与支給総額」等の算出については、算出根拠をその3のどこかに別途記載してください
        </p>
      </div>
    </div>
  );
};
