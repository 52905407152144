import axios, { AxiosRequestConfig } from "axios";
import { BASE_API_URL } from "./apiConfig";

const config: AxiosRequestConfig = {
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
export const axiosInstance = axios.create(config);
