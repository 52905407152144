import { AxiosResponse } from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { pageConstant } from "../../constants/pageConstants";
import { queryConstants } from "../../constants/queryConstants";
import { useSpecialists } from "../../hooks/useSpecialists";
import { getStoredSpecialistToken } from "../../sessionStorage/sessionStorage";
import { signUpSpecialistStore, updateModalIsOpenStore } from "../../store";
import { DarkButton, LightButton } from "../atoms";

export const ConfirmPlanModal = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const specialist = useSpecialists();
  // const signUpState = useRecoilValue(signUpSpecialistStore);
  const [signUpState, setSignUpState] = useRecoilState(signUpSpecialistStore);
  const [loadingState, setLoadingState] = useState({loading: false});

  const [updateModalIsOpen, setUpdateModalIsOpen] = useRecoilState(
    updateModalIsOpenStore
  );

  const accessToken = getStoredSpecialistToken();

  const plans = [
    "エントリープラン：月額無料",
    "ベーシックプラン：月額550円（税込）",
    "ビジネスプラン：月額1,375円（税込）",
    "プロプラン：月額3,300円（税込）",
  ];

  // 登録ボタン
  const handleClick = async () => {
    try {
      setLoadingState({ ...loadingState, loading: true})
      const { status }: AxiosResponse = await axiosInstance({
        url: `${apiUrl.SPECIALISTS}/${specialist.specialist.id}`,
        method: "PUT",
        data: {
          id: specialist.specialist.id,
          company_name: specialist.specialist.company_name,
          company_kana_name: specialist.specialist.company_kana_name,
          type_of_business: specialist.specialist.type_of_business,
          department_name: specialist.specialist.department_name,
          position: specialist.specialist.position,
          first_name: specialist.specialist.first_name,
          last_name: specialist.specialist.last_name,
          first_kana_name: specialist.specialist.first_kana_name,
          last_kana_name: specialist.specialist.last_kana_name,
          phone: specialist.specialist.phone,
          has_card: signUpState.has_card,
          plan_type: signUpState.plan_type
            ? signUpState.plan_type
            : specialist.specialist.priceplan.plan_type,
          card_kind: signUpState.card_kind,
          card_number: signUpState.card_number,
          card_name: signUpState.card_name,
          expire_month: signUpState.expire_month,
          expire_year: signUpState.expire_year,
          card_cvc_number: signUpState.card_cvc_number,
          payment_count: signUpState.payment_count,
          coupon_code: signUpState.coupon_code,
        },
        headers: {
          accessToken: accessToken,
        },
      });

      if (status === 500) {
        toast.error(`エラーが発生しました：${status}`);
        return;
      }

      try {
        const { data }: AxiosResponse = await axiosInstance.get(
          `${apiUrl.USERS}/${specialist.user.uid}/edit`,
          {
            headers: { accessToken: accessToken },
            data: { id: specialist.user.uid },
          }
        );
        queryClient.setQueriesData(queryConstants.SPECIALISTS, data);

        toast.success("更新しました");
        setTimeout(() => {
          setUpdateModalIsOpen(false);
          navigate(`/${pageConstant.CLIENTS}`);
        }, 3000);
        setSignUpState({
          ...signUpState,
          has_card: false,
          card_name: "",
          card_number: "",
          expire_month: Number(),
          expire_year: Number(),
          card_cvc_number: "",
          coupon_code: "",
        });
      } catch (error: unknown) {
        // console.log(error.response.data.message);
        setLoadingState({ ...loadingState, loading: false})
        toast.error("更新できませんでした");
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingState({ ...loadingState, loading: false})
      toast.error(error.response.data.message);
      // console.error(error);
    }
  };

  // キャンセルボタンの処理
  const handleCloseModal = () => {
    setUpdateModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={updateModalIsOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 49,
        },
        content: {
          position: "relative",
          width: "50%",
          maxWidth: "30rem",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >


      <button
        className="absolute right-20px top-20px font-bold hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset "
        onClick={handleCloseModal}
        type="button"
      >
        <span className="sr-only">Close menu</span>
        <svg
          aria-hidden="true"
          className="h-20px w-20px"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6l12 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </button>
      <div className="flex justify-center flex-col px-20px">
        <h3 className="text-24px font-bold text-center" id="modal-title">
          こちらのプランで登録します
        </h3>
        <div>
          <div className="my-20px text-center text-18px">
            {
              plans[
                signUpState.plan_type !== null
                  ? signUpState.plan_type - 1
                  : specialist.specialist.priceplan.plan_type
                  ? specialist.specialist.priceplan.plan_type - 1
                  : 0
              ]
            }
          </div>
        </div>
      </div>
      <div className="px-16px py-16px flex justify-center gap-50px">
        {!loadingState.loading && 
          <>
            <DarkButton onClick={handleClick}>
              更新する
            </DarkButton>
            <LightButton onClick={handleCloseModal}>戻る</LightButton>
          </>
        }
        {loadingState.loading && <img alt="" src={`${window.location.origin}/img/loading.gif`} width="200" /> }
      </div>
    </Modal>
  );
};
