import { round, divide, isNaN } from "mathjs";

export const ratioCalc = (state: number[]) => {
  const ratioResult: number[] = [0];

  state.map((data: number | number[], index: number) => {
    if (state[index + 1] !== undefined) {
      const ratioNext = state[index + 1];

      if (data !== 0 || !isFinite(data) || isNaN(data)) {
        const ratio =
          round(divide(Number(ratioNext), Number(data)) * 1000) / 10;
        ratioResult.push(ratio);
      } else {
        ratioResult.push(0);
      }
    } else {
      return;
    }
  });

  return ratioResult;
};
