import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { ShowTableData } from "../../../lib";
import { Unit } from "../../atoms";

export const NetProfitRatio = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const managementDashboardState = useManagementDashboardInitialData();

  const states = managementDashboardState?.net_profit_ratio;
  const unit = String(resultsAndPlansDataState.headers.unit);

  return (
    <div className="mb-20px p-30px bg-white rounded">
      <h2 className="font-bold">■純利益率</h2>
      <div className="flex">
        <div>
          <Unit unit={unit} />

          <table className="border">
            <thead className=" top-0 px-6px py-3px z-40 bg-c2a4b771a pt-120px border-b-2 text-center">
              <tr>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px`}
                >
                  決算期
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px border-l border-l-c3333334d`}
                >
                  純利益
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  売上高
                </th>
                <th
                  className={`w-120px min-w-[1.875rem] left-0 align-bottom font-bold text-18px  border-l border-l-c3333334d`}
                >
                  純利益率
                </th>
              </tr>
            </thead>
            <tbody>
              {states.map((data: DocumentData, index: number) => (
                <tr
                  className={`border-b ${
                    states.length - 1 !== index && "border-b-c3333334d"
                  }`}
                  key={index}
                >
                  <th className={`p-4px text-right font-bold text-18px`}>
                    {data.name}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["純利益"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {ShowTableData(data["売上高"])}
                  </th>
                  <th
                    className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                  >
                    {`${
                      states && isFinite(states[index]["純利益率"])
                        ? states[index]["純利益率"]
                        : ""
                    }
                  %`}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ResponsiveContainer height={300} width={states.length * 90 + 120}>
          <ComposedChart
            data={states}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              interval={0}
              padding={{ right: 30, left: 30 }}
              scale="point"
              tick={{ fontSize: 12 }}
            />
            <YAxis
              domain={[0, (dataMax: number) => round(dataMax * 1.1)]}
              orientation="left"
              scale={"linear"}
              width={100}
              yAxisId={1}
            />
            <YAxis
              orientation="right"
              scale={"linear"}
              tickFormatter={(tick) => {
                return `${tick}%`;
              }}
              width={100}
              yAxisId={2}
            />
            <Tooltip />
            <Legend />
            <Bar barSize={40} dataKey="純利益" fill="#9dc6ff" yAxisId={1} />
            <Line
              dataKey="純利益率"
              stroke="#295898"
              type="linear"
              yAxisId={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
