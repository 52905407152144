import { AxiosResponse } from "axios";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdSearch } from "react-icons/io";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import { useRecoilState } from "recoil";
import { apiUrl } from "../../api/apiConfig";
import { axiosInstance } from "../../api/axiosConfig";
import { SubHeader } from "../../components/header";
import { DeleteAccountingModal } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { queryConstants } from "../../constants/queryConstants";
import { firebaseFields } from "../../firebase";
import { useClientsLists, useSpecialists } from "../../hooks/useSpecialists";
import {
  getStoredSpecialistToken,
  setStoredClientUid,
} from "../../sessionStorage/sessionStorage";
import { deleteModalIsOpenStore } from "../../store";
import { ClientsData } from "../../types";

const Clients = () => {
  const firestore = useFirestore();

  const navigate = useNavigate();

  const [name, setName] = useState("");

  const specialists = useSpecialists();

  const clients = useClientsLists(name);

  const [isAccess, setIsAccess] = useState(true);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useRecoilState(
    deleteModalIsOpenStore
  );
  const [clientId, setClientId] = useState(0);

  const queryClient = useQueryClient();

  const mutation = useMutation((id: number) =>
    axiosInstance({
      url: `${apiUrl.CLIENTS}/${id}`,
      method: "DELETE",
      headers: {
        accessToken: accessToken,
      },
      data: { id: id },
    })
  );
  mutation.error && toast.error("削除できませんでした");

  const accessToken = getStoredSpecialistToken();

  const tableStyle = "py-4px px-8px font-normal border-l";

  const planType =
    specialists !== null &&
    specialists.specialist &&
    specialists.specialist.priceplan.plan_type;

  const handleClick = async (index: number) => {
    clients && clients !== null && setStoredClientUid(clients[index].uid);

    if (clients !== null) {
      const tableRef = doc(
        firestore,
        firebaseFields.accounts,
        clients[index].uid,
        firebaseFields.collections,
        firebaseFields.headersId
      );

      const docSnap = await getDoc(tableRef);

      if (!docSnap.exists()) {
        const batch = writeBatch(firestore);

        batch.set(tableRef, {
          field: "headers",
          userId: clients[index].user_id,
          updatedAt: new Date(),
        });

        batch
          .commit()
          .then(() => {
            console.log("register success!");
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } else if (docSnap.exists()) {
        const batch = writeBatch(firestore);

        batch.update(tableRef, {
          userId: clients[index].user_id,
        });

        batch
          .commit()
          .then(() => {
            console.log("register success!");
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      }
    }

    navigate(`/${pageConstant.ACCOUNTING_PERIOD}`, { replace: true });
  };

  const handleSearch = async () => {
    try {
      const { data }: AxiosResponse<{ clients: ClientsData[] }> =
        await axiosInstance.get(apiUrl.CLIENTS, {
          headers: { accessToken: accessToken },
          params: {
            page: 1,
            per_page: 10,
            keyword: name,
          },
        });

      queryClient.setQueriesData(queryConstants.CLIENTS_LIST, data.clients);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    setIsAccess(clients?.length !== 0);

    const specialistUid = specialists.user.uid;

    // Firebaseにログイン可能なUserデータがあるかチェックなければ登録
    clients?.map(async (client) => {
      const clientUid = client.uid;

      const specialistRef = doc(
        firestore,
        firebaseFields.accounts,
        clientUid,
        firebaseFields.users,
        specialistUid
      );

      const docSnap = await getDoc(specialistRef);

      if (docSnap.exists()) {
        return;
      } else {
        const batch = writeBatch(firestore);

        const clientRef = doc(
          firestore,
          firebaseFields.accounts,
          clientUid,
          firebaseFields.users,
          clientUid
        );

        batch.set(clientRef, {});

        batch.set(specialistRef, {});

        batch
          .commit()
          .then(() => {
            console.log("register success!");
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <SubHeader isSpecialist={true} title="クライアント一覧" />
      <div className="mt-100px px-20px">
        <div className="flex justify-between items-end py-25px">
          <div className="relative">
            {name.length === 0 && (
              <IoMdSearch
                className={"absolute text-c33333380 top-6px ml-12px"}
                size="1.5em"
              />
            )}
            <input
              className="shadow appearance-none w-[450px] h-36px border border-c33333380 rounded placeholder-c33333380 placeholder-left-8px leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setName(e.target.value)}
              placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;クライアントを検索"
              type="text"
              value={name}
            />
            <button
              className="absolute w-64px h-36px text-14px text-white font-bold bg-c427ed1 rounded-r right-0 hover:opacity-80"
              onClick={handleSearch}
            >
              検索
            </button>
          </div>
        </div>
        <div>
          <table className="border-2 border-c1b2e48 w-full">
            <thead className="bg-c1b2e48 text-white">
              <tr>
                <th className={`${tableStyle} w-1/12`}>No.</th>
                <th className={`${tableStyle} border-l-cffffff4d w-2/12`}>
                  クライアントID
                </th>
                <th className={`${tableStyle} border-l-cffffff4d w-3/12`}>
                  クライアント名
                </th>
                <th className={`${tableStyle} border-l-cffffff4d w-3/12`}>
                  メールアドレス
                </th>
                <th className={`${tableStyle} border-l-cffffff4d w-2/12`}>
                  担当者名
                </th>
                <th className={`${tableStyle} border-l-cffffff4d w-1/12`}>
                  削除
                </th>
              </tr>
            </thead>
            <tbody>
              {clients &&
                clients !== null &&
                clients.map((client, index) =>
                  planType === 1 && index >= 1 ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  ) : planType === 2 && index >= 2 ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  ) : planType === 3 && index >= 5 ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  ) : planType === 4 && index >= 10 ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  ) : (
                    <tr key={index}>
                      {[
                        index + 1,
                        client.user_id,
                        client.client_company,
                        client.email,
                        `${client.client_last_name} ${client.client_first_name}`,
                      ].map((clientData, clientIndex) =>
                        clientIndex === 2 ? (
                          <th
                            className={`${tableStyle} border-l-c3333334d border-b border-b-c3333334d text-c427ed1`}
                            key={clientIndex}
                          >
                            <a
                              className="hover:opacity-80"
                              href={`#`}
                              onClick={() => handleClick(index)}
                            >
                              {clientData}
                            </a>
                          </th>
                        ) : (
                          <th
                            className={`${tableStyle} border-l-c3333334d border-b border-b-c3333334d`}
                            key={clientIndex}
                          >
                            {clientData}
                          </th>
                        )
                      )}
                      <th
                        className={`${tableStyle} border-l-c3333334d border-b border-b-c3333334d`}
                      >
                        <button
                          className="bg-cee4b2b text-white py-4px px-8px rounded"
                          onClick={() => {
                            setDeleteModalIsOpen(true);
                            setClientId(client.user_id);
                          }}
                        >
                          削除
                        </button>
                      </th>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      {!isAccess && (
        <div className="fixed flex items-center justify-center  bg-text-87 w-screen h-screen top-0 left-0 z-[9999]">
          <div className="absolute top-50px right-4px  w-auto">
            <div className="bg-white p-6px rounded-lg border-4 border-c427ed1">
              <div className="inline-block bg-c427ed1 text-white text-16px font-bold w-full h-36px py-4px px-20px rounded border border-c427ed1">
                クライアント新規登録
              </div>
            </div>
            <img
              alt="clients"
              className="w-30px mt-6px mx-auto"
              src={`${window.location.origin}/img/arrow_up.svg`}
            />
          </div>
          <div className="absolute top-[126px] left-2px  w-auto">
            <div className="bg-c1b2e48 py-4px px-6px rounded-lg border-4 border-c427ed1">
              <div className="inline-block  text-white text-14px w-full">
                クライアント新規登録
              </div>
            </div>
            <img
              alt="clients"
              className="w-30px mt-6px mx-auto"
              src={`${window.location.origin}/img/arrow_up.svg`}
            />
          </div>
          <div className="relative flex items-center justify-center flex-col w-600px h-420px rounded-md bg-white">
            <button
              className="absolute top-12px right-12px w-25px h-25px"
              onClick={() => setIsAccess(true)}
            >
              <AiOutlineCloseCircle color="#888888" size={25} />
            </button>
            <img
              alt="clients"
              className="w-75px"
              src={`${window.location.origin}/img/client.svg`}
            />
            <h2 className="text-28px font-bold my-20px">クライアントの登録</h2>
            <p className="text-14px text-center">
              左メニュー、もしくは右上の「クライアント新規登録」、
              <br />
              ボタンから、クライアントの新規登録を行いましょう。
              <br />
              クライアント登録が完了すると、一覧ページから
              <br />
              各クライアントの詳細ページへ進むことができます。
            </p>
          </div>
        </div>
      )}
      {deleteModalIsOpen && <DeleteAccountingModal id={clientId} />}
    </React.Fragment>
  );
};

export default memo(Clients);
