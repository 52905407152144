import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const CurrentAndPreviousYearComparisonsTableBlank = ({
  bottomLine,
}: {
  bottomLine: boolean;
}) => {
  const grid: GridElement[][] = [];

  // 行の値
  const gridRow = [
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-80px  p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-220px  p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      }`,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} p-4px bg-white ${
        bottomLine && "before:border-b"
      } h-30px`,
    },
  ];

  grid.push(gridRow);

  return grid;
};
