import { dotDivide, map } from "mathjs";

export const arrayRatioCalcWithoutRound = ({
  numerator,
  denominator,
}: {
  numerator: number[];
  denominator: number[];
}) => {
  const divineValue = dotDivide(numerator, denominator);

  const newArray = map(divineValue as number[], function (value) {
    if (isNaN(value) === true) {
      value = 0;
    }
    return value;
  });
  return newArray;
};
