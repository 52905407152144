import { DocumentData } from "firebase/firestore";

// テーブルのヘッダー情報を取得するカスタムフック
export const useHeaders = (tableData: DocumentData[]) => {
  const headersData = tableData.filter(
    (inputData) => inputData.field === "headers"
  );
  const headers = headersData[0];

  return headers;
};
