import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { useFirestore } from "reactfire";
import { SubHeader } from "../../components/header";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../hooks/initialData";
import { getStoredClientUid } from "../../sessionStorage/sessionStorage";

const Kpi = () => {
  const firestore = useFirestore();

  const uid = getStoredClientUid();

  const resultsAndPlansDataState = useSubjectItemsInitialData();

  useManagementDashboardInitialData();

  const checkKpis = resultsAndPlansDataState.headers?.show_kpi;

  const kpis = [
    {
      title: "",
      items: [
        "売上高と借入金の推移",
        "売上高と借入金、有形固定資産の推移",
        "売上高と営業利益の推移",
        "財務基盤の健全性",
        "売上高と経常利益の推移",
        "売上高と償却前営業利益・経常利益の推移",
        "事業別売上推移",
      ],
      fields: [
        "kpi101",
        "kpi102",
        "kpi103",
        "kpi104",
        "kpi105",
        "kpi106",
        "kpi107",
      ],
    },
    {
      title: "安全性",
      items: ["流動比率", "当座比率", "固定比率", "有利子負債構成比率"],
      fields: ["kpi201", "kpi202", "kpi203", "kpi204"],
    },
    {
      title: "資産・資本効率",
      items: [
        "売上債権回転期間",
        "棚卸資産回転期間",
        "固定資産回転率",
        "総資本回転率",
        "仕入債務回転率",
      ],
      fields: ["kpi301", "kpi302", "kpi303", "kpi304", "kpi305"],
    },
    {
      title: "収益性",
      items: [
        "売上高総利益率",
        "純利益率",
        "自己資本利益率（ROE）",
        "総資本事業利益率（ROA）",
      ],
      fields: ["kpi401", "kpi402", "kpi403", "kpi404"],
    },
    {
      title: "成長性",
      items: [
        "売上高増加率",
        "経常利益増加率",
        "1人1か月売上高",
        "1人1か月販管費",
        "1人当たり売上高（月）",
        "1人当たり売上高（年）",
      ],
      fields: ["kpi501", "kpi502", "kpi503", "kpi504", "kpi505", "kpi506"],
    },
  ];

  const handleChange = async (value: string) => {
    const dataRef = doc(
      firestore,
      "accounts",
      uid,
      "tables",
      String(resultsAndPlansDataState.headers.NO_ID_FIELD)
    );

    await updateDoc(dataRef, {
      show_kpi: { ...checkKpis, [value]: !checkKpis[value] },
    }).catch((error) => {
      throw new Error(error.message);
    });
  };

  return (
    <React.Fragment>
      <SubHeader title="KPIの設定" />
      <div className="fixed w-[calc(100vw-265px)] h-[calc(100vh-125px)] mt-150px top-0 overflow-x-scroll m-16px z-30 grid grid-cols-3 gap-8px">
        {kpis.map((kpi, index) => (
          <div className="flex flex-col mb-12px" key={index}>
            <div className="w-full flex-grow">
              <table>
                <thead
                  className="top-0 px-6px py-3px border-b-2 border-x z-30
      pt-120px"
                >
                  <tr>
                    <th className="min-w-[1.375rem] w-22px px-16px py-8px bg-c1b2e48 text-white text-12px text-left"></th>
                    <th className="min-w-[6.25rem] w-100px px-16px py-8px bg-c1b2e48 text-white text-12px text-left"></th>
                    <th className="min-w-[1.375rem] w-22px px-16px py-8px bg-c1b2e48 text-white text-12px text-left"></th>
                    <th
                      className={`w-full px-16px py-8px border-collapse text-left  bg-c1b2e48 text-white text-12px`}
                    >
                      表示するKPI
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {kpi.items.map((item, itemIndex: number) => (
                    <tr className="relative border-l text-14px" key={itemIndex}>
                      <td
                        className={`p-4px border border-r-c3333334d  text-center ${
                          kpi.items.length !== itemIndex + 1 &&
                          "border-b-c3333334d"
                        } ${itemIndex % 2 !== 0 && "bg-c2a4b770d"}`}
                      >
                        {itemIndex + 1}
                      </td>
                      <td
                        className={`p-4px border border-r-c3333334d  text-left ${
                          kpi.items.length !== itemIndex + 1
                            ? "border-y-0"
                            : "border-t-0"
                        }`}
                      >
                        {itemIndex === 0 && kpi.title}
                      </td>
                      <td
                        className={`p-4px border border-r-c3333334d ${
                          kpi.items.length !== itemIndex + 1 &&
                          "border-b-c3333334d"
                        } text-left ${itemIndex % 2 !== 0 && "bg-c2a4b770d"}`}
                      >
                        <div className="flex justify-center items-center">
                          <input
                            checked={
                              checkKpis && checkKpis[`${kpi.fields[itemIndex]}`]
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={() => handleChange(kpi.fields[itemIndex])}
                            type="checkbox"
                          />
                        </div>
                      </td>
                      <td
                        className={`p-4px border  ${
                          kpi.items.length !== itemIndex + 1 &&
                          "border-b-c3333334d"
                        }  text-left ${itemIndex % 2 !== 0 && "bg-c2a4b770d"}`}
                      >
                        {item}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Kpi;
