import { DocumentData } from "firebase/firestore";
import { isNaN, round } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const PlCurrentAndPreviousYearComparisonsTotal = ({
  titleTotal,
  titleRatio,
  bottomLine,
  previousTotal,
  currentTotal,
  totalField,
  ratioField,
  totalSubtract,
  totalRatio,
  totalReference,
  previousRatio,
  currentRatio,
  totalFixedValue,
  referenceRatio,
  fixedRatio,
  descriptions,
  unit,
}: {
  titleTotal: string;
  titleRatio: string | null;
  bottomLine?: boolean;
  previousTotal: number;
  currentTotal: number;
  totalSubtract: number;
  totalRatio: number;
  totalReference: number;
  totalFixedValue: number;
  previousRatio: number | null;
  currentRatio: number | null;
  referenceRatio: number | null;
  fixedRatio: number | null;
  totalField: string;
  ratioField?: string;
  descriptions: DocumentData;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 pt-20px pl-4px bg-white before:border-l `;

  const classCol2 = `${beforeWrapper} left-80px p-4px bg-white before:border-l before:border-b before:border-l-c3333334d 
         before:bg-c7fb28d33 font-bold`;

  const classCol3 = `${beforeWrapper} left-220px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b  before:bg-c7fb28d33`;

  const classCol4 = `${borderSettings}  text-right font-bold bg-c7fb28d33`;

  const classCol5 = `${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px description`;

  // 1行目の値
  const gridRow: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${
        bottomLine && titleRatio === null
          ? "before:border-b"
          : titleRatio
          ? "before:border-b-c3333334d"
          : ""
      }`,
      key: "",
    },
    {
      value: titleTotal,
      readOnly: true,
      className: `${classCol2}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}  ${titleRatio && "before:border-b-c3333334d"}`,
    },
  ];
  // 1行目前期
  gridRow.push({
    value: ShowTableData(previousTotal / unit),
    expr: previousTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 1行目今期
  gridRow.push({
    value: ShowTableData(currentTotal / unit),
    expr: currentTotal / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 今年-前年
  gridRow.push({
    value: ShowTableData(totalSubtract / unit),
    expr: totalSubtract / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 今年/前年
  gridRow.push({
    value:
      isNaN(totalRatio) || !isFinite(totalRatio)
        ? "0%"
        : `${String(totalRatio)}%`,
    expr:
      isNaN(totalRatio) || !isFinite(totalRatio)
        ? "0%"
        : `${String(totalRatio)}%`,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });

  // 自動計算
  gridRow.push({
    value:
      isNaN(totalReference) ||
      totalReference === null ||
      !isFinite(totalReference)
        ? 0
        : ShowTableData(round(totalReference) / unit),
    expr:
      isNaN(totalReference) ||
      totalReference === null ||
      !isFinite(totalReference)
        ? 0
        : round(totalReference) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  // 修正後
  gridRow.push({
    value: isNaN(totalFixedValue)
      ? 0
      : ShowTableData(round(totalFixedValue) / unit),
    expr: isNaN(totalFixedValue) ? 0 : round(totalFixedValue) / unit,
    readOnly: true,
    className: `${classCol4} before:border-b ${
      titleRatio && "before:border-b-c3333334d"
    }`,
  });
  gridRow.push({
    value: descriptions[totalField],
    readOnly: false,
    className: `${classCol5}  ${titleRatio && "before:border-b-c3333334d"}`,
    field: totalField,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  // 2行目の値
  const gridRow2: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} ${bottomLine && "before:border-b"}`,
    },
    {
      value: `(${titleTotal})`,
      readOnly: true,
      className: `${classCol2} before:border-b text-14px`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b`,
    },
    {
      value: previousRatio === null ? "" : `${String(previousRatio)}%`,
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: currentRatio === null ? "" : `${String(currentRatio)}%`,
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b`,
    },
  ];

  // 自動計算
  gridRow2.push({
    value:
      referenceRatio !== null && !isFinite(referenceRatio)
        ? "0%"
        : `${String(referenceRatio)}%`,
    expr:
      referenceRatio !== null && !isFinite(referenceRatio)
        ? "0%"
        : `${String(referenceRatio)}%`,
    readOnly: true,
    className: `${classCol4} before:border-b`,
  });

  // 修正後
  gridRow2.push({
    value:
      fixedRatio && fixedRatio !== null && isFinite(fixedRatio)
        ? `${fixedRatio}%`
        : "0%",
    expr:
      fixedRatio && fixedRatio !== null && isFinite(fixedRatio)
        ? `${fixedRatio}%`
        : "0%",
    readOnly: true,
    className: `${classCol4} before:border-b`,
  });

  gridRow2.push({
    value: ratioField && descriptions[ratioField],
    expr: ratioField && descriptions[ratioField],
    readOnly: false,
    className: `${classCol5}`,
    field: ratioField,
    key: "descriptions_calc",
    row: descriptions,
  });

  titleRatio !== null && grid.push(gridRow2);

  return grid;
};
