import { atom } from "recoil";

export const specialistsTokenStore = atom<string>({
  key: "specialistsTokenStoreData",
  default: "",
});

export const specialistsMenuToggleStore = atom<boolean>({
  key: "specialistsMenuToggleStoreData",
  default: false,
});
