import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const CostEstimatedInThisTermRatioElement = ({
  headerTitle,
  headers,
  rows,
  isCurrentPlanMonthly,
  unit,
}: {
  headerTitle: string;
  rows: { title: string; calcState: DocumentData }[];
  headers: DocumentData[];
  isCurrentPlanMonthly?: boolean;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white before:border-b`,
      colSpan: 1 + (headers?.length ?? 0),
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper}  p-4px bg-white`,
      colSpan: 5,
    },
  ];
  grid.push(gridRow0);

  rows.map((row, rowIndex) => {
    // className
    const classCol1 = `${beforeWrapper} left-0 p-4px bg-white before:border-b before:border-x  before:border-r-c3333334d ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } ${rowIndex % 2 !== 0 && "before:bg-c2a4b770d"}`;

    const classCol2 = `${borderSettings} ${
      rows.length !== rowIndex + 1 && "before:border-b-c3333334d"
    } text-right `;

    const classCol3 = `relative p-4px h-50px before:border-b before:border-l  before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10  ${
      rows.length !== rowIndex + 1 && " before:border-b-c3333334d"
    } text-right   ${
      rowIndex % 2 !== 0
        ? "before:bg-cfffee2 bg-c2a4b770d before:border-x"
        : "before:bg-cfffee2 before:border-x"
    }`;

    const gridRow: GridElement[] = [];
    gridRow.push({
      value: row.title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 4,
    });

    headers?.map((header, tdIndex) => {
      rowIndex % 2 === 0
        ? gridRow.push({
            value:
              row.calcState.monthly_total && isCurrentPlanMonthly
                ? ShowTableDataTriangle(
                    row.calcState.monthly_total[tdIndex] / unit
                  )
                : row.calcState.monthly_total
                ? ShowTableData(
                    round(row.calcState.monthly_total[tdIndex]) / unit
                  )
                : "",
            expr:
              row.calcState.monthly_total && isCurrentPlanMonthly
                ? row.calcState.monthly_total[tdIndex] / unit
                : row.calcState.monthly_total
                ? round(row.calcState.monthly_total[tdIndex]) / unit
                : "",
            readOnly: true,
            className: `${classCol2}  ${
              !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
            } ${header.isTotal && "bg-cfffee2"}`,
          })
        : gridRow.push({
            value: `${showPercentage(row.calcState.monthly_ratio[tdIndex])}%`,
            expr: showPercentage(row.calcState.monthly_ratio[tdIndex]),
            readOnly: true,
            className: `${classCol2}  ${
              !header.isTotal && rowIndex % 2 !== 0 && "bg-c2a4b770d"
            } ${header.isTotal && "bg-cfffee2"}`,
          });
    });

    rowIndex % 2 === 0
      ? gridRow.push({
          value: isCurrentPlanMonthly
            ? row.calcState.yearly_total / unit
            : ShowTableData(row.calcState.yearly_total / unit),
          expr: row.calcState.yearly_total / unit,
          readOnly: true,
          className: `${classCol3}`,
        })
      : gridRow.push({
          value: `${showPercentage(row.calcState.yearly_ratio)}%`,
          expr: showPercentage(row.calcState.yearly_ratio),
          readOnly: true,
          className: `${classCol3}`,
        });

    grid.push(gridRow);
  });

  return grid;
};
