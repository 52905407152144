import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableDataTriangle } from "../../ShowTableDataTriangle";

export const SalesReceiptAndPaymentOnPurchase = ({
  headers,
  salesReceipt,
  paymentOnPurchase,
  descriptions,
  unit,
  salesReceiptRate,
  paymentOnPurchaseRate,
}: {
  headers: DocumentData[];
  salesReceipt: DocumentData;
  paymentOnPurchase: DocumentData;
  unit: number;
  descriptions: DocumentData;
  salesReceiptRate: DocumentData;
  paymentOnPurchaseRate: DocumentData;
}) => {
  const tempUpper = [
    {
      title: "",
      subTitle: "売上入金",
      item: "当月入金",
      rate: salesReceipt && salesReceipt.this_month,
    },
    {
      title: "",
      subTitle: "",
      item: "翌月入金",
      rate: salesReceipt && salesReceipt.next_month,
    },
    {
      title: "",
      subTitle: "",
      item: "翌々月入金",
      rate: salesReceipt && salesReceipt.two_month_later,
    },
  ];
  const tempMiddle = [
    {
      title: "",
      subTitle: "仕入支払",
      item: "当月支払",
      rate: paymentOnPurchase && paymentOnPurchase.this_month,
    },
    {
      title: "",
      subTitle: "",
      item: "翌月支払",
      rate: paymentOnPurchase && paymentOnPurchase.next_month,
    },
    {
      title: "",
      subTitle: "",
      item: "翌々月支払",
      rate: paymentOnPurchase && paymentOnPurchase.two_month_later,
    },
  ];
  const tempBottom = [
    {
      title: "比率",
      subTitle: "売上入金",
      item: "",
      rate:
        salesReceiptRate &&
        salesReceiptRate.this_month +
          salesReceiptRate.next_month +
          salesReceiptRate.two_month_later,
      url: "",
      key: "",
    },
    {
      title: "",
      subTitle: "",
      item: "当月入金",
      rate: salesReceiptRate && salesReceiptRate.this_month,
      url: "sales_receipt",
      key: "this_month",
    },
    {
      title: "",
      subTitle: "",
      item: "翌月入金",
      rate: salesReceiptRate && salesReceiptRate.next_month,
      url: "sales_receipt",
      key: "next_month",
    },
    {
      title: "",
      subTitle: "",
      item: "翌々月入金",
      rate: salesReceiptRate && salesReceiptRate.two_month_later,
      url: "sales_receipt",
      key: "two_month_later",
    },
    {
      title: "",
      subTitle: "仕入支払",
      item: "",
      rate:
        paymentOnPurchaseRate &&
        paymentOnPurchaseRate.this_month +
          paymentOnPurchaseRate.next_month +
          paymentOnPurchaseRate.two_month_later,
      url: "",
      key: "",
    },
    {
      title: "",
      subTitle: "",
      item: "当月支払",
      rate: paymentOnPurchaseRate && paymentOnPurchaseRate.this_month,
      url: "payment_on_purchase",
      key: "this_month",
    },
    {
      title: "",
      subTitle: "",
      item: "翌月支払",
      rate: paymentOnPurchaseRate && paymentOnPurchaseRate.next_month,
      url: "payment_on_purchase",
      key: "next_month",
    },
    {
      title: "",
      subTitle: "",
      item: "翌々月支払",
      rate: paymentOnPurchaseRate && paymentOnPurchaseRate.two_month_later,
      url: "payment_on_purchase",
      key: "two_month_later",
    },
  ];

  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} top-0 left-0 p-4px bg-white font-bold before:border-l`;

  const classCol2 = `${beforeWrapper} left-120px p-4px bg-white font-bold before:border-l before:border-l-c3333334d`;

  const classCol3 = `${beforeWrapper} left-240px p-4px bg-white before:border-b`;

  const classCol4 = `${beforeWrapper} left-380px p-4px bg-white  before:border-l before:border-l-c3333334d before:border-b`;

  const classCol5 = `${beforeWrapper} left-425px p-4px bg-white  before:border-x before:border-x-c3333334d before:border-b`;

  const classCol6 = `${borderSettings} text-right font-bold bg-c2a4b770d`;

  const classCol7 = `${borderSettings} text-right font-bold`;

  const classCol8 = `relative p-4px bg-cfffee2 before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0  text-right font-bold focus:text-left`;

  const classCol9 = `description ${borderSettings} before:border-b before:border-x text-left font-bold bg-c7fb28d33 h-50px`;

  // 1行目
  const gridRow: GridElement[] = [
    {
      value: "金額",
      readOnly: true,
      className: `${classCol1} `,
    },
    {
      value: "売上高",
      readOnly: true,
      className: `${classCol2} before:border-b before:border-b-c3333334d before:border-l-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5} before:border-b-c3333334d`,
    },
  ];

  // 前年度実績
  gridRow.push({
    value:
      salesReceipt &&
      salesReceipt.sales_receipt_data &&
      ShowTableDataTriangle(salesReceipt.sales_receipt_data[0] / unit),
    expr:
      salesReceipt &&
      salesReceipt.sales_receipt_data &&
      salesReceipt.sales_receipt_data[0] / unit,
    readOnly: true,
    className: `${classCol6} before:border-b  before:border-b-c3333334d`,
  });

  gridRow.push({
    value:
      salesReceipt &&
      salesReceipt.sales_receipt_data &&
      ShowTableDataTriangle(salesReceipt.sales_receipt_data[1] / unit),
    expr:
      salesReceipt &&
      salesReceipt.sales_receipt_data &&
      salesReceipt.sales_receipt_data[1] / unit,
    readOnly: true,
    className: `${classCol6} before:border-b  before:border-b-c3333334d`,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  salesReceipt?.sales_receipt_data?.map((_: any, index: number) => {
    if (salesReceipt?.sales_receipt_data.length <= index + 2) {
      return;
    }
    gridRow.push({
      value: isNaN(salesReceipt.sales_receipt_data[index + 2])
        ? 0
        : ShowTableDataTriangle(
            salesReceipt.sales_receipt_data[index + 2] / unit
          ),
      expr: isNaN(salesReceipt.sales_receipt_data[index + 2])
        ? 0
        : salesReceipt.sales_receipt_data[index + 2] / unit,
      readOnly: true,
      className: `${classCol7} before:border-b  before:border-b-c3333334d`,
    });
  });

  gridRow.push({
    value: isNaN(salesReceipt.sales_receipt_data_total)
      ? 0
      : ShowTableDataTriangle(salesReceipt.sales_receipt_data_total / unit),
    expr: isNaN(salesReceipt.sales_receipt_data_total)
      ? 0
      : salesReceipt.sales_receipt_data_total / unit,
    readOnly: true,
    className: `${classCol8} before:border-b  before:border-b-c3333334d`,
  });

  gridRow.push({
    value: descriptions.salesReceiptAndPaymentOnPurchase1 ?? "",
    expr: descriptions.salesReceiptAndPaymentOnPurchase1 ?? "",
    readOnly: false,
    className: `${classCol9} before:border-b-c3333334d`,
    field: "salesReceiptAndPaymentOnPurchase1",
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow);

  const salesReceiptLists: number[][] = [
    salesReceipt.this_month,
    salesReceipt.next_month,
    salesReceipt.two_month_later,
  ];

  const salesReceiptTotalLists: number[] = [
    salesReceipt.this_month_total,
    salesReceipt.next_month_total,
    salesReceipt.two_month_later_total,
  ];

  // 2行目〜4行目
  tempUpper.map((upper, index) => {
    const gridRow2: GridElement[] = [
      {
        value: "",
        readOnly: true,
        className: `${classCol1} `,
      },
      {
        value: upper.subTitle,
        readOnly: true,
        className: `${classCol2} ${
          tempUpper.length - 1 === index &&
          " before:border-b before:border-b-c3333334d"
        }`,
      },
      {
        value: upper.item,
        readOnly: true,
        className: `${classCol3} before:border-l before:border-l-c3333334d  ${
          index % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol4}  ${
          index % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol5}  ${
          index % 2 !== 0 && "before:bg-c2a4b770d"
        } before:border-b-c3333334d`,
      },
    ];

    // 前年度実績
    gridRow2.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow2.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    salesReceiptLists &&
      salesReceiptLists[0] &&
      salesReceiptLists[0].map((_: unknown, tdIndex: number) => {
        // 合計を算出
        gridRow2.push({
          value:
            salesReceiptLists &&
            salesReceiptLists[index] &&
            salesReceiptLists[index][tdIndex]
              ? isNaN(salesReceiptLists[index][tdIndex])
                ? 0
                : ShowTableDataTriangle(
                    salesReceiptLists[index][tdIndex] / unit
                  )
              : 0,
          expr:
            salesReceiptLists &&
            salesReceiptLists[index] &&
            salesReceiptLists[index][tdIndex]
              ? isNaN(salesReceiptLists[index][tdIndex])
                ? 0
                : salesReceiptLists[index][tdIndex] / unit
              : 0,
          readOnly: true,
          className: `${classCol7} before:border-b  ${
            index % 2 !== 0 && "before:bg-c2a4b770d"
          } before:border-b-c3333334d`,
        });
      });

    gridRow2.push({
      value:
        salesReceiptTotalLists[index] && isNaN(salesReceiptTotalLists[index])
          ? 0
          : salesReceiptTotalLists[index]
          ? ShowTableDataTriangle(salesReceiptTotalLists[index] / unit)
          : 0,
      expr:
        salesReceiptTotalLists[index] && isNaN(salesReceiptTotalLists[index])
          ? 0
          : salesReceiptTotalLists[index]
          ? salesReceiptTotalLists[index] &&
            salesReceiptTotalLists[index] / unit
          : 0,
      readOnly: true,
      className: `${classCol8} before:border-b  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
    });

    gridRow2.push({
      value: descriptions[`salesReceiptAndPaymentOnPurchase${index + 2}`] ?? "",
      expr: descriptions[`salesReceiptAndPaymentOnPurchase${index + 2}`] ?? "",
      readOnly: false,
      className: `${classCol9}  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } before:border-b-c3333334d`,
      field: `salesReceiptAndPaymentOnPurchase${index + 2}`,
      key: "descriptions_calc",
      row: descriptions,
    });

    grid.push(gridRow2);
  });

  // 5行目
  const gridRow5: GridElement[] = [
    {
      value: "",
      readOnly: true,
      className: `${classCol1} `,
    },
    {
      value: "仕入",
      readOnly: true,
      className: `${classCol2} before:border-b before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3} before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol4} before:border-b-c3333334d`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol5} before:border-b-c3333334d`,
    },
  ];

  // 前年度実績
  gridRow5.push({
    value:
      paymentOnPurchase.payment_on_purchase_data_list &&
      ShowTableDataTriangle(
        paymentOnPurchase.payment_on_purchase_data_list[0] / unit
      ),
    expr:
      paymentOnPurchase.payment_on_purchase_data_list &&
      paymentOnPurchase.payment_on_purchase_data_list[0] / unit,
    readOnly: true,
    className: `${classCol6} before:border-b before:border-b-c3333334d`,
  });

  gridRow5.push({
    value:
      paymentOnPurchase.payment_on_purchase_data_list &&
      ShowTableDataTriangle(
        paymentOnPurchase.payment_on_purchase_data_list[1] / unit
      ),
    expr:
      paymentOnPurchase.payment_on_purchase_data_list &&
      paymentOnPurchase.payment_on_purchase_data_list[1] / unit,
    readOnly: true,
    className: `${classCol6} before:border-b before:border-b-c3333334d`,
  });

  paymentOnPurchase?.payment_on_purchase_data_list?.map(
    (_: unknown, index: number) => {
      if (
        paymentOnPurchase?.payment_on_purchase_data_list.length <=
        index + 2
      ) {
        return;
      }
      gridRow5.push({
        value: isNaN(paymentOnPurchase.payment_on_purchase_data_list[index + 2])
          ? 0
          : ShowTableDataTriangle(
              paymentOnPurchase.payment_on_purchase_data_list[index + 2] / unit
            ),
        expr: isNaN(paymentOnPurchase.payment_on_purchase_data_list[index + 2])
          ? 0
          : paymentOnPurchase.payment_on_purchase_data_list[index + 2] / unit,
        readOnly: true,
        className: `${classCol7} before:border-b before:border-b-c3333334d`,
      });
    }
  );

  gridRow5.push({
    value: isNaN(paymentOnPurchase.payment_on_purchase_data_list_total)
      ? 0
      : ShowTableDataTriangle(
          paymentOnPurchase.payment_on_purchase_data_list_total / unit
        ),
    expr: isNaN(paymentOnPurchase.payment_on_purchase_data_list_total)
      ? 0
      : paymentOnPurchase.payment_on_purchase_data_list_total / unit,
    readOnly: true,
    className: `${classCol8} before:border-b before:border-b-c3333334d`,
  });

  gridRow5.push({
    value: descriptions.salesReceiptAndPaymentOnPurchase5
      ? descriptions.salesReceiptAndPaymentOnPurchase5
      : "",
    expr: descriptions.salesReceiptAndPaymentOnPurchase5
      ? descriptions.salesReceiptAndPaymentOnPurchase5
      : "",
    readOnly: false,
    className: `${classCol9} before:border-b-c3333334d`,
    field: "salesReceiptAndPaymentOnPurchase5",
    key: "descriptions_calc",
    row: descriptions,
  });

  grid.push(gridRow5);

  const paymentOnPurchaseLists = [
    paymentOnPurchase.this_month,
    paymentOnPurchase.next_month,
    paymentOnPurchase.two_month_later,
  ];

  const paymentOnPurchaseTotalLists = [
    paymentOnPurchase.this_month_total,
    paymentOnPurchase.next_month_total,
    paymentOnPurchase.two_month_later_total,
  ];

  // 6行目〜8行目
  tempMiddle.map((middle, index) => {
    const gridRow6: GridElement[] = [
      {
        value: "",
        readOnly: true,
        className: `${classCol1} ${
          tempMiddle.length - 1 === index && "before:border-b"
        }`,
      },
      {
        value: middle.subTitle,
        readOnly: true,
        className: `${classCol2} ${
          tempMiddle.length - 1 === index && "before:border-b"
        }`,
      },
      {
        value: middle.item,
        readOnly: true,
        className: `${classCol3} ${
          index % 2 !== 0 && "before:bg-c2a4b770d"
        }  before:border-l before:border-l-c3333334d ${
          tempMiddle.length - 1 !== index && "before:border-b-c3333334d"
        }`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol4} ${index % 2 !== 0 && "before:bg-c2a4b770d"} ${
          tempMiddle.length - 1 !== index && "before:border-b-c3333334d"
        }`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol5} ${index % 2 !== 0 && "before:bg-c2a4b770d"} ${
          tempMiddle.length - 1 !== index && "before:border-b-c3333334d"
        }`,
      },
    ];

    // 前年度実績
    gridRow6.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } ${tempMiddle.length - 1 !== index && "before:border-b-c3333334d"}`,
    });

    gridRow6.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } ${tempMiddle.length - 1 !== index && "before:border-b-c3333334d"}`,
    });

    paymentOnPurchaseLists &&
      paymentOnPurchaseLists[0] &&
      paymentOnPurchaseLists[0].map((_: unknown, tdIndex: number) => {
        gridRow6.push({
          value:
            paymentOnPurchaseLists &&
            paymentOnPurchaseLists[index] &&
            paymentOnPurchaseLists[index][tdIndex] &&
            isNaN(paymentOnPurchaseLists[index][tdIndex])
              ? 0
              : paymentOnPurchaseLists &&
                paymentOnPurchaseLists[index] &&
                paymentOnPurchaseLists[index][tdIndex]
              ? ShowTableDataTriangle(
                  paymentOnPurchaseLists[index][tdIndex] / unit
                )
              : 0,
          expr:
            paymentOnPurchaseLists &&
            paymentOnPurchaseLists[index] &&
            paymentOnPurchaseLists[index][tdIndex] &&
            isNaN(paymentOnPurchaseLists[index][tdIndex])
              ? 0
              : paymentOnPurchaseLists &&
                paymentOnPurchaseLists[index] &&
                paymentOnPurchaseLists[index][tdIndex]
              ? paymentOnPurchaseLists[index][tdIndex] / unit
              : 0,
          readOnly: true,
          className: `${classCol7} before:border-b ${
            index % 2 !== 0 && "before:bg-c2a4b770d"
          } ${tempMiddle.length - 1 !== index && "before:border-b-c3333334d"}`,
        });
      });

    gridRow6.push({
      value: isNaN(paymentOnPurchaseTotalLists[index])
        ? 0
        : ShowTableDataTriangle(paymentOnPurchaseTotalLists[index] / unit),
      expr: isNaN(paymentOnPurchaseTotalLists[index])
        ? 0
        : paymentOnPurchaseTotalLists[index] / unit,
      readOnly: true,
      className: `${classCol8} before:border-b ${
        index % 2 !== 0 && "before:bg-c2a4b770d"
      } ${tempMiddle.length - 1 !== index && "before:border-b-c3333334d"}`,
    });

    gridRow6.push({
      value: descriptions[`salesReceiptAndPaymentOnPurchase${index + 6}`] ?? "",
      expr: descriptions[`salesReceiptAndPaymentOnPurchase${index + 6}`] ?? "",
      readOnly: false,
      className: `${classCol9} ${index % 2 !== 0 && "before:bg-c2a4b770d"} ${
        tempMiddle.length - 1 !== index && "before:border-b-c3333334d"
      }`,
      field: `salesReceiptAndPaymentOnPurchase${index + 6}`,
      key: "descriptions_calc",
      row: descriptions,
    });

    grid.push(gridRow6);
  });

  // 9行目〜16行目
  tempBottom.map((bottom, index) => {
    const gridRow9: GridElement[] = [
      {
        value: bottom.title,
        readOnly: true,
        className: `${classCol1} ${
          tempBottom.length - 1 === index && "before:border-b"
        }`,
      },
      {
        value: bottom.subTitle,
        readOnly: true,
        className: `${classCol2} ${
          tempBottom.length - 1 === index && "before:border-b"
        } ${index === 3 && "before:border-b before:border-b-c3333334d"}`,
      },
      {
        value: bottom.item,
        readOnly: true,
        className: `${classCol3} ${
          index === 0 || index === 4
            ? ""
            : "before:border-l before:border-l-c3333334d"
        } ${tempBottom.length - 1 !== index && "before:border-b-c3333334d"}`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol4} ${
          tempBottom.length - 1 !== index && "before:border-b-c3333334d"
        }`,
      },
      {
        value: "",
        readOnly: true,
        className: `${classCol5} ${
          tempBottom.length - 1 !== index && "before:border-b-c3333334d"
        }`,
      },
    ];

    // 前年度実績
    gridRow9.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        tempBottom.length - 1 !== index && "before:border-b-c3333334d"
      }`,
    });

    gridRow9.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol6} before:border-b  ${
        tempBottom.length - 1 !== index && "before:border-b-c3333334d"
      }`,
    });

    paymentOnPurchase?.this_month?.map(() => {
      gridRow9.push({
        value: `${bottom.rate}%`,
        expr: bottom.rate,
        readOnly: index === 0 || index === 4 ? true : false,
        className: `${classCol7} before:border-b  ${
          tempBottom.length - 1 !== index && "before:border-b-c3333334d"
        }`,
        url: bottom.url,
        key: bottom.key,
        row: headers,
      });
    });

    gridRow9.push({
      value: "",
      expr: "",
      readOnly: true,
      className: `${classCol8} before:border-b  ${
        tempBottom.length - 1 !== index && "before:border-b-c3333334d"
      }`,
    });

    gridRow9.push({
      value: descriptions[`salesReceiptAndPaymentOnPurchase${index + 9}`] ?? "",
      expr: descriptions[`salesReceiptAndPaymentOnPurchase${index + 9}`] ?? "",
      readOnly: false,
      className: `${classCol9} ${
        tempBottom.length - 1 !== index && "before:border-b-c3333334d"
      }`,
      field: `salesReceiptAndPaymentOnPurchase${index + 9}`,
      key: "descriptions_calc",
      row: descriptions,
    });

    grid.push(gridRow9);
  });

  return grid;
};
