import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper } from "../../../utils";

export const PlansInputTableBlank = ({
  headers,
  title,
  bottomLine,
  isCurrentPlanMonthly,
  isRatio,
}: {
  headers: DocumentData[];
  title?: string;
  bottomLine?: boolean;
  isCurrentPlanMonthly?: boolean;
  isRatio?: boolean;
}) => {
  const grid: GridElement[][] = [];

  // 行の値
  const gridRow = [
    {
      value: title ? title : "",
      readOnly: true,
      className: `${beforeWrapper}  left-0 p-4px bg-white ${
        bottomLine && "before:border-b"
      } `,
      colSpan: 3,
    },
  ];
  headers?.map(() => {
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  });
  gridRow.push({
    value: "",
    readOnly: true,
    className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
    colSpan: 1,
  });
  (!isRatio || !isCurrentPlanMonthly) &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });

  (!isRatio || !isCurrentPlanMonthly) &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  (!isRatio || !isCurrentPlanMonthly) &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  (!isRatio || !isCurrentPlanMonthly) &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"}`,
      colSpan: 1,
    });
  !isRatio &&
    gridRow.push({
      value: "",
      readOnly: true,
      className: `${beforeWrapper} ${bottomLine && "before:border-b"} h-30px`,
      colSpan: 1,
    });

  grid.push(gridRow);

  return grid;
};
