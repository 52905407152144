import { AxiosResponse } from "axios";
import { getAuth, User } from "firebase/auth";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFirebaseApp } from "reactfire";
import { apiUrl } from "../api/apiConfig";
import { axiosInstance } from "../api/axiosConfig";
import { queryConstants } from "../constants/queryConstants";
import {
  getStoredCategory,
  getStoredSpecialistToken,
  getStoredUid,
  setStoredSpecialistToken,
} from "../sessionStorage/sessionStorage";
import { ClientsData } from "../types";

const GetSpecialists = async () => {
  try {
    const app = useFirebaseApp();

    const auth = getAuth(app);

    const uid = getStoredUid();

    const category = getStoredCategory();

    // Firebaseのトークンをチェック
    category === 1 &&
      auth.onAuthStateChanged((user: User | null): void => {
        if (user) {
          user
            .getIdToken(true)
            .then((latestToken: string) =>
              setStoredSpecialistToken(latestToken)
            )
            .catch((error) => {
              toast.error(`ログイン画面へ移動します: ${error}`);
              window.location.href = "/login";
            });
        } else {
          window.location.href = "/login";
        }
      });

    const accessToken = getStoredSpecialistToken();

    if (!accessToken || category === 2) return null;

    const { data }: AxiosResponse = await axiosInstance.get(
      `${apiUrl.USERS}/${uid}/edit`,
      {
        headers: { accessToken: accessToken },
        data: { id: uid },
      }
    );

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(`ログイン画面へ移動します: ${error}`);
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);

    return;
  }
};

export const useSpecialists = () => {
  const fallback = {};

  const { data: specialists = fallback } = useQuery(
    queryConstants.SPECIALISTS,
    GetSpecialists,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchInterval: 60 * 60 * 1000, // 60分後に再取得
    }
  );

  return specialists;
};

const GetClientsLists = async (keyword: string) => {
  try {
    const category = getStoredCategory();
    const accessToken = getStoredSpecialistToken();

    if (!accessToken || category === 2) return null;
    const { data }: AxiosResponse<{ clients: ClientsData[] }> =
      await axiosInstance.get(apiUrl.CLIENTS, {
        headers: { accessToken: accessToken },
        params: {
          page: 1,
          per_page: 100,
          keyword: keyword,
        },
      });

    return data.clients;
  } catch (error) {
    toast.warning("一定時間操作がなかったので、ログイン画面へ移動します");
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
    console.error(error);
    return;
  }
};

export const useClientsLists = (keyword: string) => {
  const fallback: ClientsData[] | null | undefined = [];

  const { data: clients = fallback } = useQuery(
    queryConstants.CLIENTS_LIST,
    () => GetClientsLists(keyword)
  );

  return clients;
};
