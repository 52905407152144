import { DocumentData } from "firebase/firestore";
import { sum } from "mathjs";
import React from "react";
import { useSubjectItemsInitialData } from "../../../hooks/initialData";

interface Props {
  title?: string;
  termCount: number[];
  previousHeaders: DocumentData;
  currentHeaders: DocumentData;
}

export const CurrentAndPreviousYearComparisonsHeader: React.FC<Props> = ({
  title,
  termCount,
  previousHeaders,
  currentHeaders,
}) => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();

  const topCss =
    "relative px-6px py-3px top-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full";

  const bottomCss =
    "relative before:content-[''] before:border-b-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-full";

  return (
    <thead className="sticky top-0 px-6px py-3px z-40 bg-white pt-120px">
      <tr>
        <th
          className={`sticky w-80px min-w-[5rem] left-0 align-bottom text-left font-bold text-18px z-40 ${topCss} 
            before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={3}
        >
          <div className="absolute bottom-0">{title}</div>
        </th>
        <th
          className={`sticky left-80px w-140px min-w-[8.75rem] align-bottom text-left font-bold text-18px z-40 ${topCss} 
            before:border-b-2 before:bg-white before:-z-10`}
          rowSpan={3}
        ></th>
        <th
          className={`sticky left-220px min-w-[3.125rem] w-35px ${topCss} before:border-t
            before:border-b-2 before:border-x
            before:border-r-c3333334d font-bold 
            z-40 before:bg-white before:-z-10`}
          rowSpan={3}
        >
          固定
          <br />
          変動
          <br />
          区分
        </th>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px top-0 text-left bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d`}
        >
          {previousHeaders &&
            previousHeaders[previousHeaders.length - 1].nengou}
        </th>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px top-0 text-left bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d`}
        >
          {currentHeaders && currentHeaders[currentHeaders.length - 1].nengou}
        </th>
        <th
          className={`${topCss} min-w-[15rem] w-240px top-0 text-center bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d before:border-b before:border-b-c3333334d`}
          colSpan={2}
        >
          比較
        </th>
        <th
          className={`${topCss} min-w-[15rem] w-240px top-0 text-center bg-c2a4b770d before:border-t before:border-l before:border-l-c3333334d before:border-b before:border-b-c3333334d`}
          colSpan={2}
        >
          {currentHeaders && currentHeaders[currentHeaders.length - 1].nengou}・
          {currentHeaders && currentHeaders[currentHeaders.length - 1].term}期
        </th>
        <th
          className={`min-w-[37.5rem] w-600px  before:border-collapse text-center align-middle bg-white z-30 ${topCss} before:border-l before:border-l-c3333334d before:border-x before:border-t before:border-b-2`}
          rowSpan={3}
        >
          備考
        </th>
      </tr>
      <tr>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px text-left bg-c2a4b770d before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
        >
          {previousHeaders &&
            previousHeaders[previousHeaders.length - 1].period.slice(1, 5)}
          年{String(resultsAndPlansDataState.headers.current_term)}期
        </th>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px text-left bg-c2a4b770d before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
        >
          {currentHeaders &&
            currentHeaders[currentHeaders.length - 1].period.slice(1, 5)}
          年{String(resultsAndPlansDataState.headers.current_term + 1)}期
        </th>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px text-center bg-c2a4b770d before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
        >
          今年 - 前年
        </th>
        <th
          className={`${topCss} min-w-[7.5rem] w-120px text-center bg-c2a4b770d before:border-b before:border-b-c3333334d before:border-l before:border-l-c3333334d`}
        >
          今年 / 前年
        </th>
        <th
          className={`${topCss} min-w-[15rem] w-240px top-0 text-center bg-c2a4b770d before:border-l before:border-l-c3333334d before:border-b before:border-b-c3333334d`}
          colSpan={2}
        >
          参考
        </th>
      </tr>
      <tr>
        <th
          className={`${bottomCss} bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          {sum(termCount)}ヶ月実績
        </th>
        <th
          className={`${bottomCss} bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          {sum(termCount)}ヶ月実績
        </th>
        <th
          className={`${bottomCss} bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          差
        </th>
        <th
          className={`${bottomCss} bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          比
        </th>
        <th
          className={`${bottomCss} min-w-[7.5rem] w-120px bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          （自動計算）
        </th>
        <th
          className={`${bottomCss} min-w-[7.5rem] w-120px bg-c2a4b771a before:border-l before:border-l-c3333334d`}
        >
          （修正後）
        </th>
      </tr>
    </thead>
  );
};
