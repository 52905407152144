import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings } from "../../../utils";
import { ShowTableData } from "../../ShowTableData";

export const ProfitAndLossPlanTotal = ({
  title,
  headers,
  rows,
  state,
  descriptions,
  url,
  crossheadTotal,
  isCrossheadTotalBottom,
  isEven,
  isWhite,
  noHeader,
  totalField,
  unit,
}: {
  title?: string;
  headers: DocumentData[];
  rows: DocumentData;
  state: DocumentData;
  descriptions: DocumentData;
  url: string;
  totalField: string;
  crossheadTotal?: string;
  isCrossheadTotalBottom?: boolean;
  isEven?: boolean; // 背景その2
  isWhite?: boolean; // 背景白
  noHeader?: boolean;
  unit: number;
}) => {
  const grid: GridElement[][] = [];

  // className
  const classCol1 = `${beforeWrapper} p-4px left-0 text-bold bg-white before:border-l ${
    isCrossheadTotalBottom && "before:border-b"
  } ${isCrossheadTotalBottom && !crossheadTotal && "before:border-b"} ${
    isEven && !crossheadTotal ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } ${noHeader && "before:border-t"} z-30`;

  const classCol2 = `${beforeWrapper} left-80px p-4px before:border-b bg-white ${
    isEven ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } ${crossheadTotal && "before:border-l before:border-l-c3333334d"} ${
    noHeader && "before:border-t"
  }`;

  const classCol3 = `${beforeWrapper} left-160px p-4px bg-white  before:border-b before:border-r before:border-r-c3333334d ${
    isEven ? "before:bg-c7fb28d33" : "before:bg-c80b0f433"
  } font-bold ${noHeader && "before:border-t"}`;

  const classCol4 = `${borderSettings} p-4px
            before:border-b  before:border-l before:border-l-c3333334d ${
              isWhite ? "bg-white" : isEven ? "bg-c7fb28d33" : "bg-c80b0f433"
            }
             text-right font-bold ${noHeader && "before:border-t"}`;

  const classCol5 = `${borderSettings} p-4px text-left before:border-b before:border-x before:border-l-c3333334d ${
    isWhite ? "bg-white" : isEven ? "bg-c7fb28d33" : "bg-c80b0f433"
  } ${noHeader && "before:border-t"} h-50px description`;

  const gridRow: GridElement[] = [
    {
      value: title ? title : "",
      readOnly: true,
      className: `${classCol1}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol2}`,
    },
    {
      value: "",
      readOnly: true,
      className: `${classCol3}`,
    },
  ];
  // TODO 計算結果が反映されていない気がする
  headers?.map((header: DocumentData, tdIndex: number) => {
    gridRow.push({
      value:
        tdIndex < 2
          ? ShowTableData(state.results_total[tdIndex] / unit)
          : ShowTableData(state.plans_total[tdIndex - 2] / unit),
      expr:
        tdIndex < 2
          ? state.results_total[tdIndex] / unit
          : state.plans_total[tdIndex - 2] / unit,
      readOnly: tdIndex < 2 ? false : true,
      className: `${classCol4} before:border-l-c3333334d`,
      row: rows[0],
      url: url,
      key: header.period,
    });
  });

  gridRow.push({
    value: descriptions[`${url}_${totalField}`],
    expr: descriptions[`${url}_${totalField}`],
    readOnly: false,
    className: `${classCol5}`,
    field: `${url}_${totalField}`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow);

  return grid;
};
