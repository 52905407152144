const TOKEN_KEY = "server_specialist_token";
const SERVER_CLIENT_TOKEN_KEY = "server_client_token";
const UID_KEY = "specialist_uid";
const CLIENT_UID_KEY = "client_uid";
const SPECIALIST_TOKEN_KEY = "specialist_token";
const CLIENT_TOKEN_KEY = "client_token";
const CATEGORY = "category";

// トークン情報
export function getStoredToken() {
  const storedToken = sessionStorage.getItem(TOKEN_KEY);
  return storedToken ? JSON.parse(storedToken) : null;
}

export function setStoredToken(token: string) {
  sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function clearStoredToken() {
  sessionStorage.removeItem(TOKEN_KEY);
}

// サーバーからくるクライアントトークン情報
export function getStoredServerClientToken() {
  const storedServerClientToken = sessionStorage.getItem(
    SERVER_CLIENT_TOKEN_KEY
  );
  return storedServerClientToken ? JSON.parse(storedServerClientToken) : null;
}

export function setStoredServerClientToken(token: string) {
  sessionStorage.setItem(SERVER_CLIENT_TOKEN_KEY, JSON.stringify(token));
}

export function clearStoredServerClientToken() {
  sessionStorage.removeItem(SERVER_CLIENT_TOKEN_KEY);
}

// Firebaseのuid
export function getStoredUid() {
  const storedUid = sessionStorage.getItem(UID_KEY);
  return storedUid ? JSON.parse(storedUid) : null;
}

export function setStoredUid(Uid: string) {
  sessionStorage.setItem(UID_KEY, JSON.stringify(Uid));
}

export function clearStoredUid() {
  sessionStorage.removeItem(UID_KEY);
}

// 士業のトークン情報
export function getStoredSpecialistToken() {
  const storedSpecialistToken = sessionStorage.getItem(SPECIALIST_TOKEN_KEY);
  return storedSpecialistToken ? JSON.parse(storedSpecialistToken) : null;
}

export function setStoredSpecialistToken(token: string) {
  sessionStorage.setItem(SPECIALIST_TOKEN_KEY, JSON.stringify(token));
}

export function clearStoredSpecialistToken() {
  sessionStorage.removeItem(SPECIALIST_TOKEN_KEY);
}

// クライアントのトークン情報
export function getStoredClientToken() {
  const storedClientToken = sessionStorage.getItem(CLIENT_TOKEN_KEY);
  return storedClientToken ? JSON.parse(storedClientToken) : null;
}

export function setStoredClientToken(token: string) {
  sessionStorage.setItem(CLIENT_TOKEN_KEY, JSON.stringify(token));
}

export function clearStoredClientToken() {
  sessionStorage.removeItem(CLIENT_TOKEN_KEY);
}

// Firebaseのクライアントuid
export function getStoredClientUid() {
  const storedClientUid = sessionStorage.getItem(CLIENT_UID_KEY);

  return storedClientUid ? JSON.parse(storedClientUid) : null;
}

export function setStoredClientUid(Uid: string) {
  sessionStorage.setItem(CLIENT_UID_KEY, JSON.stringify(Uid));
}

export function clearStoredClientUid() {
  sessionStorage.removeItem(CLIENT_UID_KEY);
}

// 士業ユーザーかクライアントユーザーか判別
export function getStoredCategory() {
  const storedCategory = sessionStorage.getItem(CATEGORY);
  return storedCategory ? JSON.parse(storedCategory) : null;
}

export function setStoredCategory(category: string) {
  sessionStorage.setItem(CATEGORY, JSON.stringify(category));
}

export function clearStoredCategory() {
  sessionStorage.removeItem(CATEGORY);
}
