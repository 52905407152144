import React from "react";
import { ProgressBar } from "../molecules";

interface Props {
  children: React.ReactNode;
  title: string;
  progress: number;
}

const SignUpLayout: React.FC<Props> = ({ children, title, progress }) => {
  return (
    <div className="p-40px">
      <h2 className="text-30px text-center font-bold">{title}</h2>
      <ProgressBar progress={progress} />
      {children}
    </div>
  );
};

export default SignUpLayout;
