import { DocumentData } from "firebase/firestore";
import { GridElement } from "../../../types/gridElement";
import { beforeWrapper, borderSettings, showPercentage } from "../../../utils";

export const PlResultsOfThePreviousPeriodCostRatio = ({
  headerTitle,
  title,
  rows,
  headers,
  field,
  byItemRatio,
  monthlyTotalRatio,
  descriptions,
}: {
  headerTitle?: string;
  title?: string;
  rows: DocumentData[];
  headers: DocumentData[];
  field: string;
  byItemRatio: number[][];
  monthlyTotalRatio: number[];
  descriptions: DocumentData;
}) => {
  const grid: GridElement[][] = [];

  const gridRow0: GridElement[] = [
    {
      value: headerTitle,
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b text-16px font-bold z-[32]`,
      colSpan: 4,
    },
    {
      value: "",
      readOnly: true,
      className: `${beforeWrapper} left-0 p-4px bg-white before:border-b`,
      colSpan: 2 + (headers?.length ?? 0),
    },
  ];

  // className
  const classCol1 = `${beforeWrapper} left-0 pl-4px bg-white  before:border-l align-top`;

  const classCol2 = `${beforeWrapper} ${
    title ? "left-80px" : "left-0"
  }  p-4px bg-white before:border-b  before:border-r-c3333334d `;

  const classCol3 = `${borderSettings} text-right `;

  const classCol4 = `relative p-4px font-bold before:border-b before:border-x before:absolute before:w-full before:h-full  before:top-0 before:left-0 before:-z-10 w-120px  text-right  before:bg-cfffee2`;

  const classCol5 = `${borderSettings} before:border-x before:border-b before:border-l-c3333334dtext-right h-50px description`;

  grid.push(gridRow0);

  rows.map((row, index) => {
    const gridRow: GridElement[] = [];

    gridRow.push({
      value: index === 0 && title,
      readOnly: true,
      className: `${classCol1}`,
      colSpan: 2,
    });

    gridRow.push({
      value: row.item,
      readOnly: true,
      className: `${classCol2} before:border-b-c3333334d
      } ${index % 2 !== 0 && "before:bg-c2a4b770d"} before:border-x ${
        title && "before:border-l-c3333334d"
      }`,
      colSpan: title ? 2 : 4,
    });

    headers?.map((header, tdIndex) => {
      // オブジェクトの中から該当する値を取得
      const resultsAndPlanTerm = row[field];

      type TypeOfResultObj = typeof resultsAndPlanTerm;
      const key: keyof TypeOfResultObj = header.period;

      gridRow.push({
        value: byItemRatio[index]
          ? `${showPercentage(byItemRatio[index][tdIndex])}%`
          : "0%",
        expr: byItemRatio[index]
          ? showPercentage(byItemRatio[index][tdIndex])
          : 0,
        readOnly: true,
        className: `${classCol3} ${
          !header.isTotal && index % 2 !== 0 && "bg-c2a4b770d"
        } ${header.isTotal && "bg-cfffee2"} before:border-b-c3333334d`,
        row: row,
        url: `${field}_cost_ratio`,
        key: key as string,
      });
    });

    gridRow.push({
      value: "-",
      readOnly: true,
      className: `${classCol4} before:border-b-c3333334d`,
    });

    gridRow.push({
      value: descriptions[`${field}${index}`],
      readOnly: false,
      className: `${classCol5} before:border-b-c3333334d ${
        index % 2 !== 0 && "bg-c2a4b770d"
      }`,
      field: `${field}${index}`,
      key: "descriptions_calc",
      row: descriptions,
    });
    grid.push(gridRow);
  });

  // 合計
  const gridRow2: GridElement[] = [];

  gridRow2.push({
    value: "",
    readOnly: true,
    className: `${classCol1} before:border-b`,
    colSpan: 2,
  });

  gridRow2.push({
    value: "売上高合計",
    readOnly: true,
    className: `${classCol2} font-bold before:border-r`,
    colSpan: title ? 2 : 4,
  });

  headers?.map((header, tdIndex) => {
    gridRow2.push({
      value: monthlyTotalRatio ? `${monthlyTotalRatio[tdIndex]}%` : "0%",
      expr: monthlyTotalRatio ? monthlyTotalRatio[tdIndex] : 0,
      readOnly: true,
      className: `${classCol3}`,
    });
  });

  gridRow2.push({
    value: "-",
    readOnly: true,
    className: `${classCol4}`,
  });

  gridRow2.push({
    value: descriptions[`${field}_net_sales_cost_ratio_total`],
    readOnly: false,
    className: `${classCol5}    `,
    field: `${field}_net_sales_cost_ratio_total`,
    key: "descriptions_calc",
    row: descriptions,
  });
  grid.push(gridRow2);

  return grid;
};
