import { atom } from "recoil";

export const salesPlanLatestHeaderStore = atom<string>({
  key: "salesPlanLatestHeader",
  default: "",
});

export const salesPlanNewValueStore = atom<string>({
  key: "salesPlanNewValue",
  default: "",
});
