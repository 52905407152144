import { DocumentData } from "firebase/firestore";

// Firebaseの中から必要なデータをとり出し、order順に並べ替える
export const sortData = (item: string, tableData: DocumentData[]) => {
  const data = tableData.filter((inputData) => inputData.field === item);
  data.sort(function (prev, next) {
    return prev.order - next.order;
  });

  return data;
};
