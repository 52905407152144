import React, { memo, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { usePostalJp } from "use-postal-jp";
import { DarkButton } from "../../components/atoms";
import { FormContents } from "../../components/molecules";
import { pageConstant } from "../../constants/pageConstants";
import { industryClassification } from "../../data";
import { setStoredServerClientToken } from "../../sessionStorage/sessionStorage";
import { signUpClientStore } from "../../store";

const SignUpClient = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [signUpState, setSignUpState] = useRecoilState(signUpClientStore);

  const [address] = usePostalJp(
    signUpState.client_postal_code,
    signUpState.client_postal_code.length >= 7
  );

  const [state, setState] = useState({
    isShowPassword: false,
    isShowPasswordConfirmation: false,
  });

  const largeClassification = industryClassification.filter(
    (data) => data.middle === "00"
  );

  const middleClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle !== "00" &&
      data.minor === "000"
  );

  const minorClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === signUpState.client_industry_step_2 &&
      data.minor !== "000" &&
      data.sub === "0000"
  );

  const subClassification = industryClassification.filter(
    (data) =>
      data.large === signUpState.client_industry_step_1 &&
      data.middle === signUpState.client_industry_step_2 &&
      data.minor === signUpState.client_industry_step_3 &&
      data.sub !== "0000"
  );

  const handleClick = () => {
    address !== null &&
      setSignUpState({
        ...signUpState,
        client_province: address.prefecture,
        client_address: `${address.address1}${address.address2}`,
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (signUpState.password !== signUpState.password_confirmation) {
      toast.error("パスワードが一致していません");
      return;
    }
    navigate(`/${pageConstant.CONFIRM_SIGNUP_CLIENT}`);
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      setStoredServerClientToken(String(searchParams.get("token")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div className="px-40px py-25px flex flex-col max-w-[932px] m-auto">
          <h2 className="text-center font-bold mb-30px">
            クライアントの詳細項目を入力してください。
          </h2>
          <FormContents htmlFor={"name"} required={true} title={"氏名"}>
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_last_name: String(e.target.value),
                  })
                }
                placeholder="姓"
                required
                type="text"
                value={signUpState.client_last_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_first_name: String(e.target.value),
                  })
                }
                placeholder="名"
                required
                type="text"
                value={signUpState.client_first_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"kanaName"}
            required={true}
            title={"氏名（カナ）"}
          >
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="lastKanaName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_last_kana_name: String(e.target.value),
                  })
                }
                placeholder="セイ"
                required
                type="text"
                value={signUpState.client_last_kana_name}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[49%] py-12px float-right leading-tight focus:outline-none focus:shadow-outline"
                id="firstKanaName"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_first_kana_name: String(e.target.value),
                  })
                }
                placeholder="メイ"
                required
                type="text"
                value={signUpState.client_first_kana_name}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"companyName"}
            required={true}
            title={"会社名・団体名"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="companyName"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_company: String(e.target.value),
                })
              }
              placeholder="株式会社〇〇〇〇"
              required
              type="text"
              value={signUpState.client_company}
            />
          </FormContents>
          <FormContents
            htmlFor={"postalCode"}
            required={true}
            title={"郵便番号"}
          >
            <span className="w-8/12 flex items-center gap-20px">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-4/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="postalCode"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_postal_code: String(e.target.value),
                  })
                }
                placeholder="1234567"
                required
                type="text"
                value={signUpState.client_postal_code}
              />
              <div>
                <a
                  className="text-14px text-c427ed1 underline"
                  href={"javascript:void(0)"}
                  onClick={() => handleClick()}
                >
                  郵便番号から住所を入力する
                </a>
              </div>
            </span>
          </FormContents>
          <FormContents htmlFor={"province"} required={true} title={"都道府県"}>
            <span className="w-8/12">
              <input
                className="shadow appearance-none border border-c33333333 rounded w-4/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="postalCode"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_province: String(e.target.value),
                  })
                }
                placeholder=""
                required
                type="text"
                value={signUpState.client_province}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"clientAddress"}
            required={true}
            title={"地名・番地"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientAddress"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_address: String(e.target.value),
                })
              }
              placeholder=""
              required
              type="text"
              value={signUpState.client_address}
            />
          </FormContents>
          <FormContents
            htmlFor={"clientAddressDetail"}
            required={false}
            title={"建物名・階・部屋番号"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientAddressDetail"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_address_detail: String(e.target.value),
                })
              }
              placeholder=""
              type="text"
              value={signUpState.client_address_detail}
            />
          </FormContents>
          <FormContents htmlFor={"position"} required={true} title={"役職"}>
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="position"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_position: String(e.target.value),
                })
              }
              placeholder="部長"
              required
              type="text"
              value={signUpState.client_position}
            />
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep1"}
            required={true}
            title={"業種（大分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep1"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_industry_step_1: String(e.target.value),
                })
              }
              placeholder="選択してください"
              required
              value={signUpState.client_industry_step_1}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {largeClassification.map((select, index) => (
                <option key={index} value={select.large}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep2"}
            required={true}
            title={"業種（中分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep2"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_industry_step_2: String(e.target.value),
                })
              }
              placeholder="選択してください"
              required
              value={signUpState.client_industry_step_2}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {middleClassification.map((select, index) => (
                <option key={index} value={select.middle}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep3"}
            required={false}
            title={"業種（小分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep3"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_industry_step_3: String(e.target.value),
                })
              }
              placeholder="選択してください"
              value={signUpState.client_industry_step_3}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {minorClassification.map((select, index) => (
                <option key={index} value={select.minor}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"clientIndustryStep4"}
            required={false}
            title={"業種（細分類）"}
          >
            <select
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="clientIndustryStep4"
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  client_industry_step_4: String(e.target.value),
                })
              }
              placeholder="選択してください"
              value={signUpState.client_industry_step_4}
            >
              <option disabled selected value="">
                選択してください
              </option>
              {subClassification.map((select, index) => (
                <option key={index} value={select.sub}>
                  {select.item}
                </option>
              ))}
            </select>
          </FormContents>
          <FormContents
            htmlFor={"phone"}
            required={true}
            title={"携帯電話番号"}
          >
            <span className="w-[32.666%] flex justify-between mr-auto">
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] float-left py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone1"
                inputMode="text"
                maxLength={3}
                minLength={3}
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_phone1: String(e.target.value),
                  })
                }
                pattern="0[5789]0"
                placeholder="000"
                required
                type="text"
                value={signUpState.client_phone1}
              />
              <input
                autoComplete="off"
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone2"
                inputMode="text"
                maxLength={4}
                minLength={4}
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_phone2: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={signUpState.client_phone2}
              />
              <input
                className="shadow appearance-none border border-c33333333 rounded w-[32%] py-12px leading-tight focus:outline-none focus:shadow-outline"
                id="phone3"
                inputMode="text"
                maxLength={4}
                minLength={4}
                name="phone3"
                onChange={(e) =>
                  setSignUpState({
                    ...signUpState,
                    client_phone3: String(e.target.value),
                  })
                }
                pattern="[0-9]*"
                placeholder="0000"
                required
                type="text"
                value={signUpState.client_phone3}
              />
            </span>
          </FormContents>
          <FormContents
            htmlFor={"password"}
            required={true}
            title={"パスワード"}
          >
            <input
              autoComplete="new-password"
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              minLength={8}
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  password: String(e.target.value),
                })
              }
              placeholder="半角英数字8文字以上"
              required
              type={state.isShowPassword ? "text" : "password"}
              value={signUpState.password}
            />
            <span
              className={`absolute top-[16px] right-16px ${
                signUpState.password.length === 0 && "text-c33333333"
              }`}
              onClick={() =>
                setState({ ...state, isShowPassword: !state.isShowPassword })
              }
              role="presentation"
            >
              {state.isShowPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </FormContents>
          <FormContents
            htmlFor={"passwordConfirmation"}
            required={true}
            title={"パスワード（確認）"}
          >
            <input
              className="shadow appearance-none border border-c33333333 rounded w-8/12 py-12px leading-tight focus:outline-none focus:shadow-outline"
              id="passwordConfirmation"
              minLength={8}
              onChange={(e) =>
                setSignUpState({
                  ...signUpState,
                  password_confirmation: String(e.target.value),
                })
              }
              placeholder="パスワード再入力"
              required
              type={state.isShowPasswordConfirmation ? "text" : "password"}
              value={signUpState.password_confirmation}
            />
            <span
              className={`absolute top-[16px] right-16px ${
                signUpState.password_confirmation.length === 0 &&
                "text-c33333333"
              }`}
              onClick={() =>
                setState({
                  ...state,
                  isShowPasswordConfirmation: !state.isShowPasswordConfirmation,
                })
              }
              role="presentation"
            >
              {state.isShowPasswordConfirmation ? <FaEye /> : <FaEyeSlash />}
            </span>
          </FormContents>
        </div>
        <div className="w-[450px] m-auto py-30px">
          <DarkButton>登録内容の確認に進む</DarkButton>
        </div>
      </form>
    </div>
  );
};

export default memo(SignUpClient);
