import { DocumentData } from "firebase/firestore";
import { round } from "mathjs";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  useManagementDashboardInitialData,
  useSubjectItemsInitialData,
} from "../../../hooks/initialData";
import { ShowTableData } from "../../../lib";
import { Unit } from "../../atoms";

export const SalesByBusinessSegment = () => {
  const resultsAndPlansDataState = useSubjectItemsInitialData();
  const managementDashboardState = useManagementDashboardInitialData();

  const headers = resultsAndPlansDataState.headers.results_input;
  const rows = resultsAndPlansDataState.net_sales;
  const states = managementDashboardState?.sales_by_business_segment;

  const unit = String(resultsAndPlansDataState.headers.unit);

  return (
    <div className="w-full mb-20px p-30px bg-white rounded">
      <h2 className="font-bold">■事業別売上推移</h2>
      <div className="flex flex-col">
        <div>
          <Unit unit={unit} />

          <table className="border-r">
            <thead className=" top-0 px-6px py-3px z-40 pt-120px border-b-2 text-center">
              <tr>
                <th className={`w-64px min-w-[4rem]`} rowSpan={2}></th>
                <th className={`w-100px min-w-[6.25rem]`} rowSpan={2}></th>
                {headers?.map((header: DocumentData, index: number) => (
                  <th
                    className={`w-100px min-w-100px left-0 align-bottom bg-c2a4b771a font-bold text-16px border-t border-l ${
                      index !== 0 && "border-l-c3333334d"
                    }`}
                    key={index}
                  >
                    {header.nengou}
                  </th>
                ))}
              </tr>
              <tr>
                {headers?.map((header: DocumentData, index: number) => (
                  <th
                    className={`w-100px min-w-100px left-0 align-bottom bg-c2a4b771a font-bold text-16px border-l ${
                      index !== 0 && "border-l-c3333334d"
                    }`}
                    key={index}
                  >
                    {`${header.period.substring(1, 5)}年${header.term}期`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((row: DocumentData, rowIndex: number) => (
                <tr
                  className={`border-b ${
                    rows.length - 1 !== rowIndex && "border-b-c3333334d"
                  } ${rowIndex % 2 !== 0 && "bg-c2a4b770d"}`}
                  key={rowIndex}
                >
                  {rowIndex === 0 && (
                    <th
                      className={`p-4px text-left font-bold text-18px border-l`}
                      rowSpan={rows.length + 1}
                    >
                      売上高
                    </th>
                  )}
                  <th
                    className={`p-4px text-left text-18px border-l border-l-c3333334d`}
                  >
                    {row.item}
                  </th>
                  {headers.map((header: DocumentData, headerIndex: number) => (
                    <th
                      className={`p-4px  text-right text-18px border-l border-l-c3333334d`}
                      key={headerIndex}
                    >
                      {ShowTableData(states[headerIndex][`${row.item}`] ?? 0)}
                    </th>
                  ))}
                </tr>
              ))}
              <tr className={`border-b`}>
                <th
                  className={`p-4px text-left font-bold text-18px border-t border-l border-l-c3333334d bg-c7fb28d33`}
                >
                  売上高合計
                </th>
                {headers?.map((header: DocumentData, headerIndex: number) => (
                  <th
                    className={`p-4px  text-right text-18px border-t border-l border-l-c3333334d bg-c7fb28d33`}
                    key={headerIndex}
                  >
                    {states[headerIndex]["売上高合計"] &&
                      ShowTableData(states[headerIndex]["売上高合計"])}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <ResponsiveContainer height={300} width={states.length * 120 + 160}>
          <ComposedChart
            data={states}
            height={300}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 0,
            }}
            width={states.length * 120 + 120}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              padding={{ right: 30, left: 30 }}
              scale="point"
            />
            <YAxis
              domain={[0, (dataMax: number) => round(dataMax * 1.1)]}
              orientation="left"
              scale={"linear"}
              width={100}
              yAxisId={1}
            />
            <YAxis
              domain={[0, (dataMax: number) => round(dataMax * 1.1)]}
              orientation="right"
              scale={"linear"}
              width={100}
              yAxisId={2}
            />
            <Tooltip />
            <Legend />
            <Bar barSize={40} dataKey="売上高合計" fill="#9dc6ff" yAxisId={1} />
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <Line
                  dataKey={row.item}
                  stroke={`#${295898 + 3333 * index}`}
                  type="linear"
                  yAxisId={2}
                />
              </React.Fragment>
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
