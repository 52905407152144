import { DocumentData } from "firebase/firestore";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { pageConstant } from "../../constants/pageConstants";
import { useGetFirebaseData } from "../../firebase";
import { Logout } from "../../lib/Logout";
import { salesPlanLatestHeaderStore } from "../../store";
import { sortData } from "../../utils";
import {
  perValuesForSalesPlan,
  totalCalcByItemForSalesPlan,
  totalCalcForSalesPlan,
} from "../../utils/calc";

export const useSalesPlanInitialData = () => {
  const queryClient = useQueryClient();

  const headerState = useRecoilValue(salesPlanLatestHeaderStore);

  const { tableData } = useGetFirebaseData();

  const field = pageConstant.SALES_PLAN;

  try {
    const headersData = tableData.filter(
      (inputData) => inputData.field === "headers"
    );
    const headers = headersData[0];

    const salesPlanHeadlinesData = sortData("net_sales", tableData);

    const salesPlanHeadlines: string[] = [];
    salesPlanHeadlinesData.map((headlineData) => {
      salesPlanHeadlines.push(headlineData.item);
    });

    const salesPlanHeaders = headers.sales_plan;

    // 年度リスト
    const salesPlanTerms = salesPlanHeaders.years;
    // 直近の年度
    const latestHeader =
      headerState.length === 0
        ? salesPlanHeaders.years[0]
        : salesPlanHeaders.years[salesPlanHeaders.years.indexOf(headerState)];
    // 直近の年度の月リスト
    const latestHeaderyear =
      headerState.length === 0
        ? salesPlanHeaders.terms[0]
        : salesPlanHeaders.years &&
          salesPlanHeaders.years.indexOf(headerState) !== -1
        ? salesPlanHeaders.terms[salesPlanHeaders.years.indexOf(headerState)]
        : null;
    const latestHeaderTerms = latestHeaderyear[latestHeader];

    // 項目の詳細リスト
    const salesPlanItems: DocumentData[] = [];
    // 項目ごとの合計
    const salesPlanTotalByItem: number[][] = [];
    // 毎月の合計
    const salesPlanTotal: number[][] = [];
    // 販売数X売価
    const priceMultipleSales: number[][][] = [];

    salesPlanHeadlines &&
      salesPlanHeadlines.map((item: string) => {
        // データの抽出およびorder順に並べ替え
        const data = tableData.filter((inputData) => inputData.field === item);
        const arrayRows = [...data];
        const newData = arrayRows.sort(function (
          prev: DocumentData,
          next: DocumentData
        ) {
          return prev.order - next.order;
        });
        salesPlanItems.push(newData);

        // 項目別合計
        const totalByItem = totalCalcByItemForSalesPlan({
          headers: latestHeaderTerms,
          field: field,
          state: newData,
        });
        salesPlanTotalByItem.push(totalByItem);

        // 毎月の合計
        const total = totalCalcForSalesPlan({
          headers: latestHeaderTerms,
          field: field,
          state: newData,
        });
        salesPlanTotal.push(total);

        // 販売数X価格
        const multipleData = perValuesForSalesPlan({
          headers: latestHeaderTerms,
          field: field,
          state: newData,
          target: "price",
        });

        priceMultipleSales.push(multipleData);
      });

    const descriptionsData = tableData.filter(
      (inputData) => inputData.field === "descriptions"
    );
    const descriptions = descriptionsData[0];

    const salesPlanState = {
      headers: headers,
      sales_plan_items: salesPlanItems,
      sales_plan_headlines: salesPlanHeadlines,
      descriptions: descriptions,
    };

    const salesPlanCalcState = {
      sales_plan_items: salesPlanItems,
      sales_plan_terms: salesPlanTerms,
      latest_header: latestHeader,
      latest_header_terms: latestHeaderTerms,
      sales_plan_total_by_item: salesPlanTotalByItem,
      sales_plan_total: salesPlanTotal,
      price_multiple_sales: priceMultipleSales,
    };

    return { salesPlanState, salesPlanCalcState };
  } catch (error) {
    Logout(queryClient, "error", `予期せぬエラーが発生しました: ${error}`);
  }
};
