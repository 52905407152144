import * as React from "react";
import { CashFlowTableTable, DepositsAndWithdrawTable } from "../cashFlowTable";
import { PlSimulationLayout } from "../plSimulation";

interface Props {
  url: string;
  dataUrl: string;
}

export const CashFlowTableContainer: React.FC<Props> = ({ url, dataUrl }) => {
  return (
    <PlSimulationLayout>
      <div id="pl"></div>
      <CashFlowTableTable dataUrl={dataUrl} url={url} />
      <DepositsAndWithdrawTable dataUrl={dataUrl} url={url} />
    </PlSimulationLayout>
  );
};
